import React, { Component } from "react";
import "./security.css";
import { Row, Col, Space, Typography, Divider, message, Switch } from "antd";
import COLOR from "../../const/colors";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { currentToken } from "../../redux/actions/tokenActions";
import { signinSuccess } from "../../redux/actions/userActions";
import approvalsServices from "../../services/approvals";
import {
  activateTwoFactor,
  confirmTwoFactor,
} from "../../redux/actions/twoFactorActions";
import ModalTwoFactor from "../twoFactorLoggedIn/twoFactorLoggedIn";
import ModalTransactionValidation from "./modalTransactionValidation";
const { Text, Title } = Typography;
class Security extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activateSignin:
        this.props.infoUser.sign_in.status === "INACTIVE" ? false : true,
      activateTransactionValidation: false,
      modalActivate: false,
      modalValidation: false,
      verificationCode: "",
      disableResend: false,
      loading: false,
    };
  }
  componentDidMount() {
    let transactionAuthorisation =
      this.props.dataUser.userData?.data?.data?.user.transaction_authorisation;
    if (transactionAuthorisation !== undefined) {
      this.setState({
        activateTransactionValidation: transactionAuthorisation,
      });
    }
  }
  openModalActivate() {
    this.setState({ modalActivate: true });
  }
  closeModalActivate() {
    this.setState({ modalActivate: false, verificationCode: "" });
  }
  openModalValidation() {
    this.setState({ modalValidation: true });
  }
  closeModalValidation() {
    this.setState({ modalValidation: false });
  }
  handleCode(e) {
    const reg = /^[0-9]+$/;
    if (reg.test(e.target.value)) {
      this.setState({ verificationCode: e.target.value });
    }
  }
  async verificationCode() {
    try {
      this.setState({ loading: true });
      let clientId = this.props.token.clientId;
      let accessToken = this.props.token.current;
      let body = {
        otp: this.state.verificationCode.toString(),
      };
      await this.props.confirmTwoFactor(clientId, accessToken, body);
      let response = this.props.twoFactor.confirm;
      let newAccessToken = response.headers["x-new-access-token"];
      this.props.currentToken(newAccessToken);
      if (response.data.success) {
        this.setState({ loading: false });
        message.success(this.props.t("twofactor.messages.successChange"), 10);
        await this.getUser();
        this.setState({ activateSignin: !this.state.activateSignin });
        this.closeModalActivate();
      } else {
        this.setState({ loading: false });
        if (response.status === 403) {
          message.error(this.props.t("twofactor.messages.codeExpired"));
        } else if (response.status === 400) {
          message.error(this.props.t("twofactor.messages.dataError"));
        } else if (response.status === 401) {
          message.error(this.props.t("twofactor.messages.incorrectCode"));
        } else if (response.status === 404) {
          message.error(this.props.t("twofactor.messages.userInactive"));
        } else {
          message.error(this.props.t("twofactor.messages.errorServer"));
        }
      }
    } catch (error) {
      this.setState({ loading: false });
      message.error(this.props.t("twofactor.messages.errorServer"));
    }
  }
  async updateTwoFactorSignin(resend) {
    try {
      if (!resend) {
        this.openModalActivate();
      } else {
        this.setState({ disableResend: true });
      }

      let clientId = this.props.token.clientId;
      let accessToken = this.props.token.current;
      let body = { enable: !this.state.activateSignin };
      await this.props.activateTwoFactor(clientId, accessToken, body);
      let response = this.props.twoFactor.twoFactor;
      let newAccessToken = response.headers["x-new-access-token"];
      this.props.currentToken(newAccessToken);
      if (response.data.success) {
        if (resend) {
          message.success(this.props.t("twofactor.messages.resend"));
          setTimeout(() => {
            this.setState({ disableResend: false });
          }, 5000);
        }
      } else {
        this.setState({ disableResend: false });
        if (response.status === 403) {
          message.error(this.props.t("twofactor.messages.errorServer"));
        } else if (response.status === 400) {
          message.error(this.props.t("twofactor.messages.dataError"));
        } else if (response.status === 401) {
          message.error(this.props.t("twofactor.messages.errorServer"));
        } else if (response.status === 404) {
          message.error(this.props.t("twofactor.messages.userInactive"));
        } else {
          message.error(this.props.t("twofactor.messages.errorServer"));
        }
      }
    } catch (error) {
      message.error(this.props.t("twofactor.messages.errorServer"));
    }
  }
  async getUser() {
    try {
      await this.props.signinSuccess(
        this.props.token.clientId,
        this.props.token.current
      );
      let response = this.props.dataUser.userData;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 401) {
        this.setState({ loading: false });
        message.error(this.props.t("signin.messages.formError"));
      } else if (response.status === 403) {
        this.setState({ loading: false });
        message.error(this.props.t("signin.messages.formError"));
      } else if (response.status === 404) {
        this.setState({ loading: false });
        message.error(this.props.t("signin.messages.formError"));
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }
  async activateTransactionValidation() {
    try {
      this.setState({
        loading: true,
      });
      let clientId = this.props.token.clientId;
      let accessToken = this.props.token.current;
      let respActivate = await approvalsServices.manageTransactionAuthorisation(
        clientId,
        accessToken,
        !this.state.activateTransactionValidation
      );
      if (respActivate.headers !== undefined) {
        let newAccessToken = respActivate.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (respActivate.status === 200) {
        await this.getUser();
        this.setState(
          {
            loading: true,
            activateTransactionValidation:
              !this.state.activateTransactionValidation,
          },
          () => {
            this.setState({ loading: false, modalValidation: false });
          }
        );
      }
    } catch (error) {
      this.setState({ loading: false });
      if (error.response.status === 400) {
        if (error.response.data.error_details?.validation_error !== undefined) {
          let messageError = error.response.data.error_details.validation_error;
          if (messageError === "USER_CAN_NOT_DEACTIVATE") {
            message.error(this.props.t("approvals.error.USER_CAN_NOT_DEACTIVATE"), 10);
          }
        } else if (
          error.response.data.error_details?.payload_error !== undefined
        ) {
          let messageError = error.response.data.error_details.payload_error;
          if (messageError === "USER_DOES_NOT_HAVE_PERMISSION") {
            message.error(this.props.t("approvals.error.USER_DOES_NOT_HAVE_PERMISSION"), 10);
          }
        } else {
          message.error(error.response.data.message, 10);
        }
      } else {
        message.error(this.props.t("approvals.error.internal"), 10);
      }
    }
  }
  render() {
    let role = this.props.dataUser.userData?.data?.data?.user.role;
    return (
      <div>
        <Row gutter={[48, { xs: 8, sm: 16, md: 8, lg: 24, xl: 24 }]}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
          >
            <Title level={4} style={{ color: COLOR.PRIMARY_TEXT }}>
              {this.props.t("twofactor.titleSecurity")}
            </Title>
            <Divider
              style={{
                background: COLOR.PRIMARY_TEXT,
                margin: "10px 0 30px 0",
              }}
            ></Divider>
            <Row
              gutter={[8, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}
              align="middle"
            >
              <Col
                xs={{ span: 22, offset: 0 }}
                sm={{ span: 22, offset: 0 }}
                md={{ span: 20, offset: 0 }}
                lg={{ span: 18, offset: 0 }}
                xl={{ span: 8, offset: 0 }}
              >
                <Space direction="vertical">
                  <Title level={4} style={{ color: COLOR.PRIMARY_TEXT }}>
                    {this.props.t("twofactor.signin")}
                  </Title>
                  <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                    {this.props.t("twofactor.authentication")}
                  </Text>
                  <Text
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      fontWeight: "normal",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("twofactor.textSignin")}
                  </Text>
                </Space>
              </Col>
              <Col
                xs={{ span: 2, offset: 0 }}
                sm={{ span: 2, offset: 0 }}
                md={{ span: 4, offset: 0 }}
                lg={{ span: 5, offset: 0 }}
                xl={{ span: 9, offset: 0 }}
              >
                <Space direction="vertical" align="center">
                  <Switch
                    style={{
                      backgroundColor: this.state.activateSignin
                        ? COLOR.GREEN
                        : COLOR.GRAY,
                    }}
                    defaultChecked={false}
                    checked={this.state.activateSignin}
                    onClick={() => this.updateTwoFactorSignin(false)}
                  />
                </Space>
              </Col>
              <Col
                xs={{ span: 0, offset: 0 }}
                sm={{ span: 0, offset: 0 }}
                md={{ span: 0, offset: 0 }}
                lg={{ span: 1, offset: 0 }}
                xl={{ span: 7, offset: 0 }}
              ></Col>
            </Row>
            <Divider style={{ background: COLOR.PRIMARY }}></Divider>
            <Row
              gutter={[8, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}
              align="middle"
            >
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 10, offset: 0 }}
                xl={{ span: 10, offset: 0 }}
              >
                <Space direction="vertical">
                  <Title level={4} style={{ color: COLOR.PRIMARY_TEXT }}>
                    {this.props.t("twofactor.funding")}
                  </Title>
                  <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                    {this.props.t("twofactor.authentication")}
                  </Text>
                  <Text
                    style={{ color: COLOR.PRIMARY_TEXT, fontWeight: "normal" }}
                  >
                    {this.props.t("twofactor.textFunding")}
                  </Text>
                  <Text style={{ color: COLOR.GREEN, fontWeight: "normal" }}>
                    {this.props.t("twofactor.required")}
                  </Text>
                </Space>
              </Col>
              {role === "MASTER" && this.props.usersChildren.length >= 1 && (
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 12, offset: 0 }}
                  lg={{ span: 14, offset: 0 }}
                  xl={{ span: 14, offset: 0 }}
                >
                  <Row
                    gutter={[8, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}
                    align="middle"
                  >
                    <Col
                      xs={{ span: 22, offset: 0 }}
                      sm={{ span: 22, offset: 0 }}
                      md={{ span: 22, offset: 0 }}
                      lg={{ span: 12, offset: 0 }}
                      xl={{ span: 12, offset: 0 }}
                    >
                      <Space direction="vertical">
                        <br />
                        <Text
                          style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}
                        >
                          {this.props.t("twofactor.transactionValidation")}
                        </Text>
                        <Text
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            fontWeight: "normal",
                          }}
                        >
                          {this.props.t("twofactor.textValidation")}
                        </Text>
                      </Space>
                    </Col>
                    <Col
                      xs={{ span: 2, offset: 0 }}
                      sm={{ span: 2, offset: 0 }}
                      md={{ span: 2, offset: 0 }}
                      lg={{ span: 4, offset: 0 }}
                      xl={{ span: 4, offset: 0 }}
                    >
                      <Space direction="vertical" align="center">
                        <Switch
                          style={{
                            backgroundColor: this.state
                              .activateTransactionValidation
                              ? COLOR.GREEN
                              : COLOR.GRAY,
                          }}
                          defaultChecked={false}
                          checked={this.state.activateTransactionValidation}
                          onClick={() =>
                            this.setState({ modalValidation: true })
                          }
                        />
                      </Space>
                    </Col>
                    <Col
                      xs={{ span: 0, offset: 0 }}
                      sm={{ span: 0, offset: 0 }}
                      md={{ span: 0, offset: 0 }}
                      lg={{ span: 8, offset: 0 }}
                      xl={{ span: 8, offset: 0 }}
                    ></Col>
                  </Row>
                </Col>
              )}
            </Row>
            <Divider style={{ background: COLOR.PRIMARY }}></Divider>
          </Col>
        </Row>

        <ModalTwoFactor
          openModal={this.openModalActivate.bind(this)}
          closeModal={this.closeModalActivate.bind(this)}
          verificationCodeReceived={this.verificationCode.bind(this)}
          resend={this.updateTwoFactorSignin.bind(this)}
          modalActivate={this.state.modalActivate}
          verificationCode={this.state.verificationCode}
          handleCode={this.handleCode.bind(this)}
          disableResend={this.state.disableResend}
          loading={this.state.loading}
        />
        <ModalTransactionValidation
          openModal={this.openModalValidation.bind(this)}
          closeModal={this.closeModalValidation.bind(this)}
          modalActivate={this.state.modalValidation}
          loading={this.state.loading}
          activateValidation={this.activateTransactionValidation.bind(this)}
          transactionValidation={this.state.activateTransactionValidation}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser,
    token: state.tokenAccess,
    twoFactor: state.twoFactor,
  };
};

const mapDispatchToProps = {
  activateTwoFactor,
  confirmTwoFactor,
  currentToken,
  signinSuccess,
};

const SecurityTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Security)
);

export default SecurityTranslated;
