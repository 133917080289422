import servicesTimeline from "../../services/timeline";
export const GET_TIMELINE = "GET_TIMELINE";
export function getTimeline(id, token, period) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesTimeline.getTimeline(
        id,
        token,
        period
      );
      dispatch({
        type: GET_TIMELINE,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_TIMELINE,
        payload: error.response,
      });
    }
  };
}
