import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from "./reducers/reducers";
import thunk from "redux-thunk";
import createDebounce from "redux-debounced";

const persistConfig = {
  key: "authUser",
  storage: storage,
  whitelist: [
    "authUser",
    "siderNavigate",
    "tokenAccess",
    "banking",
    "loadNotifications",
    "beneficiaries",
    "transactions",
    "exchange",
    "preferences",
    "timeline",
    "audit",
    "userChildren",
    "approvals",
  ], // which reducer want to store
};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [thunk, createDebounce()];
const pReducer = persistReducer(persistConfig, reducers);
//const store = createStore(pReducer, applyMiddleware(thunk));
const store = createStore(
  pReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);
const persistor = persistStore(store);
export { persistor, store };
