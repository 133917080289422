import React, { Component } from "react";
import "./twoFactor.css";
import { withTranslation } from "react-i18next";
import ReactCodeInput from "react-verification-code-input";
import COLOR from "../../const/colors";
import { Modal, Space, Typography, Button, Row, Spin, message } from "antd";
import { Redirect } from "react-router-dom";
import ShieldLockOutline from "@2fd/ant-design-icons/lib/ShieldLockOutline";
import { LoadingOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { getAccounts } from "../../redux/actions/bankingActions";
import {
  authUser,
  signinSuccess,
  onlyFiat,
} from "../../redux/actions/userActions";
import { siderNavigate } from "../../redux/actions/navigateActions";
import { getNotifications } from "../../redux/actions/notificationsActions";
import {
  currentToken,
  currentClientId,
} from "../../redux/actions/tokenActions";
import { loginTwoFactor } from "../../redux/actions/twoFactorActions";
import { isMobileOnly } from "react-device-detect";
import jwt_decode from "jwt-decode";
const { Title, Text } = Typography;

class TwoFactor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: false,
      verificationCode: "",
      disableCode: true,
      loading: false,
      disableResend: false,
    };
    this.input = React.createRef();
  }
  handleCodeChange(vals) {
    this.setState({ disableCode: true, verificationCode: vals });
  }
  resendCode() {
    this.setState({ disableResend: true });
    this.props.login(true);

    setTimeout(() => {
      this.setState({ disableResend: false });
    }, 5000);
  }
  async loginTwoFactor() {
    try {
      this.setState({ loading: true });
      let body = {
        email: this.props.email,
        otp: this.state.verificationCode,
      };
      await this.props.loginTwoFactor(this.props.tokenId, body);
      let response = this.props.twoFactor.loginTwoFactor;
      if (response.data.success) {
        let accessToken = response.data.data.access_token;
        this.props.currentToken(accessToken);
        this.props.currentClientId(this.props.tokenId);
        this.props.siderNavigate("Accounts");

        await this.getUser(this.props.tokenId, accessToken);
      } else {
        this.setState({ loading: false });
        if (response.status === 400) {
          message.error(this.props.t("twofactor.messages.dataError"));
        } else if (response.status === 401) {
          message.error(this.props.t("twofactor.messages.incorrectCode"));
        } else if (response.status === 403) {
          message.error(this.props.t("twofactor.messages.codeExpired"));
        } else if (response.status === 404) {
          message.error(this.props.t("twofactor.messages.userInactive"));
        } else {
          message.error(this.props.t("twofactor.messages.errorServer"));
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
      message.error(this.props.t("twofactor.messages.errorServer"));
    }
  }
  async getUser(id, token) {
    try {
      await this.props.signinSuccess(id, token);
      let response = this.props.authUserResult.userData;
      let newAccessToken = response.headers["x-new-access-token"];
      await this.props.currentToken(newAccessToken);

      if (response.status === 200) {
        await this.closeModal();
        let profile = response.data?.data?.user;
        let agreeTerms = profile.tc_agreement;
        let role = profile.role;
        let fiatOnly =
          profile.only_fiat_accounts !== undefined
            ? profile.only_fiat_accounts
            : false;
        this.props.onlyFiat(fiatOnly);
        if (agreeTerms === true) {
          await this.getAccounts(id, newAccessToken);
        } else if (agreeTerms === false && role === "MASTER") {
          this.props.openModalTerms();
        } else {
          message.error(this.props.t("signin.messages.agreeTerms"), 10);
          this.setState({ loading: false });
        }
      } else if (response.status === 401) {
        this.setState({ loading: false });
        message.error(this.props.t("signin.messages.formError"));
      } else if (response.status === 403) {
        this.setState({ loading: false });
        message.error(this.props.t("signin.messages.formError"));
      } else if (response.status === 404) {
        this.setState({ loading: false });
        message.error(this.props.t("signin.messages.formError"));
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }
  async getAccounts(id, token) {
    try {
      await this.props.getAccounts(id, token);
      let response = this.props.banking.accounts;
      let newAccessToken = "";
      if (response.headers !== undefined) {
        newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        await this.getNotifications(id, newAccessToken);
      } else if (
        response.status === 401 ||
        response.status === 422 ||
        response.status === 403 ||
        response.status === 404
      ) {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }
  async getNotifications(id, token) {
    try {
      await this.props.getNotifications(id, token);
      let resp = this.props.notification;
      if (resp.headers !== undefined) {
        let newAccessToken = resp.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
        var decoded = jwt_decode(newAccessToken);
        let time = decoded.exp - decoded.iat;
        window.localStorage.setItem("timeToken", time * 1000);
      }
      if (resp.status === 200) {
        setTimeout(() => {
          this.setState({ auth: true, loading: false });
        }, 1000);
      } else if (
        resp.status === 401 ||
        resp.status === 422 ||
        resp.status === 403 ||
        resp.status === 404 ||
        resp.status === 500
      ) {
        console.log("error", resp.status);
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }
  closeModal() {
    this.props.closeModalTwoFactor();
    this.input.current.__clearvalues__();
    this.setState({
      auth: false,
      verificationCode: "",
      disableCode: true,
      loading: false,
    });
  }
  render() {
    if (this.state.auth) {
      return <Redirect to="/account" />;
    }
    return (
      <Modal
        footer={null}
        className="container-twofactor"
        open={this.props.modalTwoFactor}
        onOk={() => this.props.openModalTwoFactor()}
        onCancel={() => this.closeModal()}
        centered={true}
        maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
      >
        <Spin
          spinning={this.state.loading}
          size="large"
          indicator={<LoadingOutlined style={{ color: COLOR.GREEN }} />}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <Row
              gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}
              justify="center"
            >
              <Space direction="vertical">
                <ShieldLockOutline
                  style={{ color: COLOR.GREEN, fontSize: 80 }}
                />
                <Title level={3} style={{ color: COLOR.PRIMARY }}>
                  {this.props.t("twofactor.title")}
                </Title>
                <Text style={{ color: COLOR.PRIMARY }}>
                  {this.props.t("twofactor.text")}
                </Text>
                <Row
                  gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}
                  justify="center"
                >
                  <ReactCodeInput
                    ref={this.input}
                    type="number"
                    onComplete={() => this.setState({ disableCode: false })}
                    onChange={(vals) => this.handleCodeChange(vals)}
                    fieldWidth={isMobileOnly ? 40 : 50}
                    fieldHeight={isMobileOnly ? 40 : 50}
                  />
                  <div>
                    <Text
                      style={{
                        color: COLOR.PRIMARY,
                        padding: "0px 10px 0px 0px",
                      }}
                    >
                      {this.props.t("twofactor.noReceived")}
                    </Text>
                    <Button
                      type="link"
                      disabled={this.state.disableResend}
                      style={{
                        color: COLOR.GREEN_1,
                        fontWeight: "bold",
                        padding: 0,
                      }}
                      onClick={() => this.resendCode()}
                    >
                      {this.props.t("twofactor.resend")}
                    </Button>
                  </div>
                </Row>
              </Space>
            </Row>
            <br />
            <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]} justify="end">
              <Button
                size="large"
                className="btn-previous"
                onClick={() => this.closeModal()}
              >
                {this.props.t("twofactor.buttonCancel")}
              </Button>
              <Button
                size="large"
                type="primary"
                disabled={this.state.disableCode}
                onClick={() => this.loginTwoFactor()}
              >
                {this.props.t("twofactor.buttonConfirm")}
              </Button>
            </Row>
          </Space>
        </Spin>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authUserResult: state.authUser,
    result: state.siderNavigate,
    signinSuccess: state.signinSuccess,
    twoFactor: state.twoFactor,
    banking: state.banking,
    notification: state.loadNotifications.notifications,
  };
};

const mapDispatchToProps = {
  authUser,
  siderNavigate,
  signinSuccess,
  currentToken,
  loginTwoFactor,
  currentClientId,
  getAccounts,
  getNotifications,
  onlyFiat,
};

const TwoFactorTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(TwoFactor)
);

export default TwoFactorTranslated;
