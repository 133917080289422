import React, { Component } from "react";
import Send from "../send/send";
import Receive from "../receive/receive";
import "./containerSendOrReceive.css";
import { Tabs, message } from "antd";
import ConfirmSend from "../send/confirmSend";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { asset } from "../../common/assets";
import {
  getFees,
  getLimits,
  sendPay,
} from "../../redux/actions/bankingActions";
import { getGeneralRate } from "../../redux/actions/exchangeActions";
import { typeBeneficiary } from "../../redux/actions/beneficiariesActions";
import { selectedAsset } from "../../redux/actions/navigateActions";
import { currentToken } from "../../redux/actions/tokenActions";
import TwoFactorSend from "../send/twoFactorSend";
import CAN from "../../casl/can";

class ContainerSendOrReceive extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      tabActive:
        this.props.dataUser.data?.data?.user?.role === "VIEWER" ? "2" : "1",
      current: 0,
      assetActive: "",
      labelActive: "",
      amountSend: null,
      amountSendFloat: null,
      amountSendFiat: null,
      amountSendFiatFloat: null,
      note: "",
      beneficiary: "",
      assetActiveBalance: "",
      assetSelected: "",
      fees: null,
      limitByCurrency: {},
      disableResend: false,
      loading: false,
      nativeCurrency: "",
      nativeSymbol: "",
      nativeBalance: "",
      rateCurrent: "",
      status: "",
      showMessageLimit: false,
      messageLimit: "",
      beneficiarySelected: "",
      payments: "",
      networks: [],
      selectedNetwork: "",
      loadingLimits: false,
      loadingFees: false,
      assetSelectedId: "",
    };
  }
  componentDidMount() {
    this._isMounted = true;
    if (this.props.source === "wallet") {
      this.setState({ current: 0 });
    }
    if (this.props.beneficiaryInfo) {
      let info = this.props.beneficiaryInfo;
      if (Object.hasOwn(info, "network")) {
        this.setState({ selectedNetwork: info.network });
      } else if (Object.hasOwn(info, "networks")) {
        let findNetwork = info.networks.find(
          (element) => element.network === "BASE_ASSET"
        );
        if (findNetwork) {
          this.setState({ selectedNetwork: "BASE_ASSET " });
        }
      }
    }
  }
  showAlertMessage() {
    let maxDay = this.state.limitByCurrency.max_daily_withdrawal;
    let maxMonth = this.state.limitByCurrency.max_monthly_withdrawal;
    if (maxDay !== "-1") {
      let limitDaily = 0;
      if (this.state.volumes) {
        limitDaily =
          parseFloat(maxDay) -
          parseFloat(this.state.volumes.current_day_volume);
      }

      if (
        this.state.amountSendFiatFloat > limitDaily ||
        this.state.amountSendFloat > limitDaily
      ) {
        this.setState({
          showMessageLimit: true,
          messageLimit: this.props.t(
            "limits.messages.warningTrasactionPendingSend"
          ),
        });
      }
    } else if (maxMonth !== "-1") {
      let limitMonthly =
        parseFloat(maxMonth) -
        parseFloat(this.state.volumes.current_month_volume);
      if (
        this.state.amountSendFiatFloat > limitMonthly ||
        this.state.amountSendFloat > limitMonthly
      ) {
        this.setState({
          showMessageLimit: true,
          messageLimit: this.props.t(
            "limits.messages.warningTrasactionPendingSend"
          ),
        });
      }
    }
  }
  nextStep() {
    let validate = true;
    let maxDay = this.state.limitByCurrency.max_daily_withdrawal;
    let maxMonth = this.state.limitByCurrency.max_monthly_withdrawal;
    let messageWarning = "";
    if (this.state.assetActive.type !== "CRYPTO") {
      if (maxDay !== "-1") {
        let limitDaily = 0;
        if (this.state.volumes) {
          limitDaily =
            parseFloat(maxDay) -
            parseFloat(this.state.volumes.current_day_volume);
        }

        if (
          this.state.amountSendFiatFloat > limitDaily ||
          this.state.amountSendFloat > limitDaily
        ) {
          messageWarning = this.props.t("limits.messages.limitDailySend", {
            currency: this.state.assetActive.value,
            max: maxDay,
            volume: this.state.volumes.current_day_volume,
          });
          validate = false;
          this.setState({
            showMessageLimit: true,
            messageLimit: messageWarning,
          });
          setTimeout(() => {
            this.setState({ showMessageLimit: false, messageLimit: "" });
          }, 30000);
        } else {
          validate = true;
        }
      } else if (maxMonth !== "-1") {
        let limitMonthly =
          parseFloat(maxMonth) -
          parseFloat(this.state.volumes.current_month_volume);
        if (
          this.state.amountSendFiatFloat > limitMonthly ||
          this.state.amountSendFloat > limitMonthly
        ) {
          messageWarning = this.props.t("limits.messages.limitMonthlySend", {
            currency: this.state.assetActive.value,
            max: maxMonth,
            volume: this.state.volumes.current_month_volume,
          });
          validate = false;
          this.setState({
            showMessageLimit: true,
            messageLimit: messageWarning,
          });
          setTimeout(() => {
            this.setState({ showMessageLimit: false, messageLimit: "" });
          }, 30000);
        } else {
          validate = true;
        }
      } else {
        validate = true;
      }
    }

    if (validate) {
      this.setState({ current: this.state.current + 1 }, () => {
        this.showAlertMessage();
      });
    }
  }
  prevStep() {
    this.clearAmounts();
    this.setState({
      showMessageLimit: false,
      messageLimit: "",
    });
    this.setState({ current: this.state.current - 1 });
  }
  resetCurrent = () => {
    this.setState({
      current: 0,
      amountSend: 0,
      amountSendFloat: 0,
      amountSendFiat: 0,
      amountSendFiatFloat: 0,
    });
  };
  handleTab(key) {
    this.setState({ tabActive: key });
    this.clearAmounts();
  }
  componentDidUpdate(prevProps) {
    let role = this.props.dataUser.data?.data?.user?.role;
    if (this.props.source === "wallet") {
      if (prevProps.asset !== this.props.asset) {
        this.setState({ current: 0 });
        this.handleAsset(this.props.asset.key, this.props.asset.id);
      }
    }
    if (role !== "VIEWER") {
      if (prevProps.banking !== this.props.banking) {
        this.handleAsset(this.props.asset.key, this.props.asset.id);
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleAsset(value, key) {
    if (this.props.source !== "wallet") {
      this.handleAssetSelect(value, key);
    }
    this.setState({
      amountSend: 0,
      amountSendFloat: 0,
      amountSendFiat: 0,
      amountSendFiatFloat: 0,
      beneficiary: "",
      note: "",
      beneficiarySelected: "",
      payments: "",
      selectedNetwork: "",
    });
    let data = asset.assets;
    let assetSelected = data.find((element) => {
      return element.value === value;
    });

    let accounts = this.props.banking.data.data.accounts;
    accounts = accounts.filter((acc) => {
      return acc.status === "ACTIVE" && value === acc.currency;
    });
    let nativeBalanceInfo = this.props.banking.data.data.native_balances;
    let findBalanceNative = {};
    let multiple = false;
    findBalanceNative = nativeBalanceInfo.currencies.find(
      (element) => element.currency === value
    );
    if (findBalanceNative) {
      if (accounts?.length > 1) {
        multiple = true;
      }
    }

    if (findBalanceNative.accounts !== undefined && key) {
      findBalanceNative = findBalanceNative.accounts.find(
        (element) => element._id === key
      );
    }
    let findBalance = accounts.find((element) => {
      return key !== undefined && key !== null
        ? element._id === key
        : element.currency === value;
    });

    if (findBalance?.account_data !== undefined) {
      if (findBalance.type === "CRYPTO") {
        this.setState({
          networks: findBalance.account_data.networks,
          selectedNetwork: this.props.beneficiaryInfo.network
            ? this.props.beneficiaryInfo.network
            : findBalance.account_data.networks[0].network,
        });
        for (let value of findBalance.account_data.networks) {
          assetSelected.network = value.network;
        }
      }
    }
    if (nativeBalanceInfo.native_currency !== assetSelected.value) {
      this.getRate(assetSelected.value);
    }

    this.setState({
      assetActiveBalance: findBalance.available_balance,
      assetActive: assetSelected,
      labelActive: multiple ? findBalance.alias : findBalance.currency_name,
      nativeCurrency: nativeBalanceInfo.native_currency,
      nativeSymbol: nativeBalanceInfo.native_symbol,
      nativeBalance: findBalanceNative.native_balance,
      status: findBalance.status,
      fees: null,
      limitByCurrency: {},
      beneficiary: "",
      assetSelectedId: key,
    });
  }
  handleAmount = (values) => {
    this.setState({
      amountSend: values.formattedValue,
      amountSendFloat: values.floatValue,
    });
  };
  handleAmountFiat = (values) => {
    this.setState(
      {
        amountSendFiat:
          values.formattedValue === undefined ? 0 : values.formattedValue,
        amountSendFiatFloat:
          values.floatValue === undefined ? 0 : values.floatValue,
      },
      () => {
        this.showAlertMessage();
      }
    );
  };
  handleBeneficiary = (value, option) => {
    this.setState({ beneficiary: option, beneficiarySelected: "" }, () => {
      this.getFees();
      this.getLimits(this.state.assetActive, this.state.selectedNetwork);
    });
  };
  handleNote = (e) => {
    this.setState({ note: e.target.value });
  };
  clearAmounts() {
    this.setState({
      amountSendFloat: 0,
      amountSendFiatFloat: 0,
      amountSendFiat: 0,
      amountSend: 0,
    });
  }
  handleAssetSelect(value, key) {
    // console.log(value, key);
    let info = {
      id: key,
      key: value,
    };
    this.setState({ assetSelected: info, assetSelectedId: key });
  }
  async getFees() {
    try {
      this.setState({ loadingFees: true });
      let network = "";
      if (this.state.assetActive.type === "CRYPTO") {
        if (
          this.state.assetActive.value === "BTC" ||
          this.state.assetActive.value === "ETH"
        ) {
          network = "BASE_ASSET";
        } else {
          network = this.state.selectedNetwork;
        }
      }
      await this.props.getFees(
        this.props.token.clientId,
        this.props.token.current,
        this.state.assetActive.value,
        network
      );
      let resp = this.props.respFees;
      if (resp.headers !== undefined) {
        let newAccessToken = resp.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (resp.status === 200) {
        let userType = this.props.dataUser.data?.data?.user?.type;
        let dataFees = resp.data.data.fees;
        let beneficiariesData = "";
        let findBeneficiary = "";
        let findFee = "";
        if (
          userType.includes("LITE") &&
          this.state.assetActive.type === "FIAT"
        ) {
          beneficiariesData =
            this.props.beneficiariesList.data.data.external_account_whitelist;
          findBeneficiary = beneficiariesData.find(
            (element) => element._id === this.state.beneficiary.key
          );
          if (findBeneficiary) {
            findFee = dataFees.find((element) => element.type === "EXTERNAL");
          }
        } else {
          beneficiariesData =
            this.props.beneficiariesList.data.data.beneficiaries;
          findBeneficiary = beneficiariesData.find(
            (element) => element.beneficiary_id === this.state.beneficiary.key
          );
          if (findBeneficiary) {
            findFee = dataFees.find(
              (element) => element.type === findBeneficiary.type
            );
          }
        }
        if (findFee !== undefined) {
          this.setState({ fees: findFee, loadingFees: false });
        }
      } else if (
        resp.status === 401 ||
        resp.status === 422 ||
        resp.status === 403 ||
        resp.status === 404 ||
        resp.status === 500
      ) {
        this.setState({ fees: null });
        this.setState({ loadingFees: true });
      }
    } catch (error) {
      this.setState({ fees: null });
      this.setState({ loadingFees: true });
    }
  }
  async getLimits(asset, network) {
    try {
      this.setState({ loadingLimits: true });
      await this.props.getLimits(
        this.props.token.clientId,
        this.props.token.current,
        asset.value,
        network
      );
      let resp = this.props.respLimits;
      if (resp.headers !== undefined) {
        let newAccessToken = resp.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (resp.status === 200) {
        let limitsResponse = resp.data.data.limits;
        let findBeneficiary = "";
        let findLimitBeneficiary = " ";
        let findCurrentVolume = "";
        let dataAccounts = this.props.banking.data.data.accounts;
        let findAccount = dataAccounts.find(
          (element) => element.currency === asset.value
        );

        let userType = this.props.dataUser.data?.data?.user?.type;
        let beneficiariesData = this.props.beneficiariesList.data.data;
        if (userType.includes("LITE") && asset.type === "FIAT") {
          let bankAccounts = beneficiariesData.external_account_whitelist;
          findBeneficiary = bankAccounts.find(
            (element) => element._id === this.state.beneficiary.key
          );
          for (let limit of limitsResponse) {
            let limitList = limit.limits;
            findLimitBeneficiary = limitList.find(
              (element) => element.type === "EXTERNAL"
            );
          }
        } else {
          let beneficiariesResp = beneficiariesData.beneficiaries;
          findBeneficiary = beneficiariesResp.find(
            (element) => element.beneficiary_id === this.state.beneficiary.key
          );
          for (let limit of limitsResponse) {
            let limitList = limit.limits;
            findLimitBeneficiary = limitList.find(
              (element) => element.type === findBeneficiary.type
            );
          }
        }

        if (findBeneficiary !== undefined) {
          this.setState({ beneficiarySelected: findBeneficiary });
          if (!this.props.modalState) {
            this.props.typeBeneficiary(findBeneficiary);
          }
          if (findBeneficiary.type !== undefined) {
            this.findPaymentsForType(findBeneficiary.type, findAccount);
            if (findBeneficiary.type === "EXTERNAL") {
              if (findAccount.type === "CRYPTO") {
                findCurrentVolume = findAccount.volumes.find(
                  (element) =>
                    element.type === "WITHDRAWAL" &&
                    element.network === findBeneficiary.account_data.network
                );
              } else {
                findCurrentVolume = findAccount.volumes.find(
                  (element) => element.type === "WITHDRAWAL"
                );
              }
            } else {
              findCurrentVolume = findAccount.volumes.find(
                (element) => element.type === "TRANSFER_OUT"
              );
            }
          } else {
            this.findPaymentsForType("EXTERNAL", findAccount);
            findCurrentVolume = findAccount.volumes.find(
              (element) => element.type === "WITHDRAWAL"
            );
          }
          if (findCurrentVolume !== undefined) {
            this.setState({ volumes: findCurrentVolume });
          }

          if (findLimitBeneficiary !== undefined) {
            this.setState({
              limitByCurrency: findLimitBeneficiary,
              loadingLimits: false,
            });
          }
        }
      } else if (
        resp.status === 401 ||
        resp.status === 422 ||
        resp.status === 403 ||
        resp.status === 404 ||
        resp.status === 500
      ) {
        this.setState({ loadingLimits: false });
      }
    } catch (error) {
      console.log(error);
    }
  }
  async sendPayment(resend) {
    try {
      this.setState({ disableResend: true });
      let accountsInfo = this.props.banking.data.data.accounts;
      accountsInfo = accountsInfo.filter((account) => {
        return account.status === "ACTIVE";
      });
      //  console.log(this.state.assetActive, this.state.assetSelectedId);
      let sourceAccount = accountsInfo.find(
        (element) => element._id === this.state.assetSelectedId
      );
      let amountSend = 0;
      let calculatedFee = 0;
      if (this.state.assetActive.type === "CRYPTO") {
        amountSend = this.state.amountSendFloat;
      } else {
        let amount = parseFloat(this.state.amountSendFiatFloat);
        let withdrawalFee = parseFloat(this.state.fees.withdrawal_amount);
        calculatedFee = (
          (amount * withdrawalFee) /
          (100 - withdrawalFee)
        ).toFixed(2);
        amountSend = amount + parseFloat(calculatedFee);
        if (this.state.fees.value_type === "PERCENTAGE") {
          let minFee = parseFloat(this.state.fees.min_withdrawal_amount);
          if (parseFloat(calculatedFee) <= minFee) {
            amountSend = amount + minFee;
          }
        }
      }

      let paymentReference = this.state.note.trim();
      let body = {
        beneficiary_id: this.state.beneficiary.key,
        source_account_id: sourceAccount._id,
        amount: amountSend.toString(),
        reference: paymentReference,
      };
      if (amountSend <= this.state.assetActiveBalance) {
        this.setState({ loading: true });
        await this.props.sendPay(
          this.props.token.clientId,
          this.props.token.current,
          body
        );
        let resp = this.props.send;
        if (resp.headers !== undefined) {
          let newAccessToken = resp.headers["x-new-access-token"];
          await this.props.currentToken(newAccessToken);
        }
        if (resp.status === 200) {
          this.setState({ loading: false });
          if (!resend) {
            this.nextStep();
          }
          setTimeout(() => {
            this.setState({ disableResend: false });
          }, 5000);
        } else if (resp.status === 400) {
          this.setState({ loading: false, disableResend: false });
          if (resp.data.error_details.payload_error !== undefined) {
            let messageError = resp.data.error_details.payload_error;
            message.error(this.props.t("send.messages." + messageError), 10);
          }
          if (resp.data.error_details.validation_error !== undefined) {
            let messageError = resp.data.error_details.validation_error;
            message.error(this.props.t("send.messages." + messageError), 10);
          }
        } else if (resp.status === 403) {
          message.error(this.props.t("send.messages.accessError"), 10);
          this.setState({ loading: false, disableResend: false });
        } else if (
          resp.status === 401 ||
          resp.status === 422 ||
          resp.status === 404 ||
          resp.status === 500
        ) {
          message.error(this.props.t("send.messages.errorServer"), 10);
          this.setState({ loading: false, disableResend: false });
        }
      } else {
        message.error(
          this.props.t("send.messages.enoughBalance", {
            key: this.state.assetActive.value,
          })
        );
      }
    } catch (error) {
      this.setState({ loading: false, disableResend: false });
    }
  }
  async getRate(currency) {
    if (this._isMounted) {
      try {
        await this.props.getGeneralRate(
          this.props.token.clientId,
          this.props.token.current,
          currency
        );
        let response = this.props.respRate;
        if (response.headers !== undefined) {
          let newAccessToken = response.headers["x-new-access-token"];
          await this.props.currentToken(newAccessToken);
        }

        if (response.status === 200) {
          let info = response.data.data;
          this.setState({
            rateCurrent: info,
            loading: false,
          });
        } else if (response.status === 400) {
          this.setState({ loading: false });
          if (response.data.error_details.validation_error !== undefined) {
            let messageError = response.data.error_details.validation_error;

            message.error(this.props.t("trade.error." + messageError), 10);
          } else {
            message.error("Currency not supported");
          }
        } else if (response.status === 403) {
          message.error(
            "You do not have the necessary permissions to consult the current rate",
            10
          );
        } else if (response.status === 404) {
          this.setState({ loading: false });
          if (response.data.error_details.validation_error !== undefined) {
            let messageError = response.data.error_details.validation_error;

            message.error(this.props.t("trade.error." + messageError), 10);
          } else {
            message.error("Currency not supported");
          }
        } else if (
          response.status === 401 ||
          response.status === 422 ||
          response.status === 500
        ) {
          message.error("An error has occurred, try again", 10);
          this.setState({ loading: false });
        }
      } catch (error) {
        message.error("An error has occurred, try again", 10);

        this.setState({ loading: false });
      }
    }
  }
  findPaymentsForType(beneficiary, account) {
    let dataAccounts = account.payments;
    let findPayment = dataAccounts.find(
      (element) =>
        element.type ===
        (beneficiary.type !== undefined ? beneficiary.type : beneficiary)
    );
    if (account.type === "CRYPTO" && findPayment.type === "EXTERNAL") {
      let network = this.state.selectedNetwork;
      let paymentObj = {};
      let findNetwork = findPayment.networks.find(
        (element) => element.network === network
      );
      paymentObj = findNetwork;
      paymentObj.type = findPayment.type;

      this.setState({ payments: paymentObj });
    } else {
      this.setState({ payments: findPayment });
    }
  }
  handleNetwork = (value, option) => {
    this.setState({
      selectedNetwork: value,
      beneficiarySelected: "",
      beneficiary: "",
      payments: "",
    });
  };

  render() {
    let tabsSendReceive = (
      <div
        id="custom-tab"
        className="ant-tabs ant-tabs-tab ant-tabs-nav ant-tabs-card ant-tabs-tab-active ant-tabs-tab-btn ant-tabs-top"
      >
        <Tabs
          type="card"
          centered
          size="large"
          tabBarGutter={2}
          tabBarStyle={{ width: "100%", color: "#ccc" }}
          activeKey={this.state.tabActive}
          onTabClick={(key) => this.handleTab(key)}
          items={[
            CAN("CREATE", "TRANSACTIONS") && {
              label: this.props.t("send.title"),
              style: { width: "100%", textAlign: "center" },
              key: "1",
              children: this.state.tabActive === "1" && (
                <Send
                  next={this.nextStep.bind(this)}
                  source={this.props.source}
                  handleSelect={
                    this.props.source !== "wallet"
                      ? this.handleAssetSelect.bind(this)
                      : ""
                  }
                  assetModal={this.state.assetSelected}
                  closeModal={
                    this.props.source !== "wallet"
                      ? this.props.closeModal.bind(this)
                      : ""
                  }
                  assetActive={this.state.assetActive}
                  labelActive={this.state.labelActive}
                  amountSend={this.state.amountSend}
                  amountSendFiatFloat={this.state.amountSendFiatFloat}
                  amountSendFloat={this.state.amountSendFloat}
                  amountSendFiat={this.state.amountSendFiat}
                  beneficiary={this.state.beneficiary}
                  note={this.state.note}
                  handleTab={
                    this.props.source === "wallet"
                      ? (key) => this.props.handleTab(key)
                      : ""
                  }
                  assetActiveBalance={this.state.assetActiveBalance}
                  handleAsset={this.handleAsset.bind(this)}
                  handleAmount={this.handleAmount.bind(this)}
                  handleAmountFiat={this.handleAmountFiat.bind(this)}
                  handleBeneficiary={this.handleBeneficiary.bind(this)}
                  handleNote={this.handleNote.bind(this)}
                  clearAmounts={this.clearAmounts.bind(this)}
                  limits={this.state.limitByCurrency}
                  fees={this.state.fees}
                  nativeBalance={this.state.nativeBalance}
                  nativeSymbol={this.state.nativeSymbol}
                  nativeCurrency={this.state.nativeCurrency}
                  rateCurrent={this.state.rateCurrent}
                  status={this.state.status}
                  showMessageLimit={this.state.showMessageLimit}
                  messageLimit={this.state.messageLimit}
                  beneficiarySelected={this.state.beneficiarySelected}
                  payments={this.state.payments}
                  networks={this.state.networks}
                  selectedNetwork={this.state.selectedNetwork}
                  handleNetwork={this.handleNetwork.bind(this)}
                  loadingFees={this.state.loadingFees}
                  loadingLimits={this.state.loadingLimits}
                />
              ),
            },
            {
              style: { width: "100%", textAlign: "center" },
              label: this.props.t("receive.title"),
              key: "2",
              children: this.state.tabActive === "2" && (
                <Receive
                  source={this.props.source}
                  handleSelect={this.handleAssetSelect.bind(this)}
                  assetModal={this.state.assetSelected}
                  limits={this.state.limitByCurrency}
                />
              ),
            },
          ]}
        />
      </div>
    );
    const steps = [
      {
        content: tabsSendReceive,
      },
      {
        content: (
          <ConfirmSend
            source={this.props.source}
            next={this.nextStep.bind(this)}
            prev={this.prevStep.bind(this)}
            assetActive={this.state.assetActive}
            labelActive={this.state.labelActive}
            amountSend={this.state.amountSend}
            amountSendFloat={this.state.amountSendFloat}
            amountSendFiat={this.state.amountSendFiat}
            amountSendFiatFloat={this.state.amountSendFiatFloat}
            beneficiary={this.state.beneficiary}
            note={this.state.note}
            assetActiveBalance={this.state.assetActiveBalance}
            fees={this.state.fees}
            sendPayment={this.sendPayment.bind(this)}
            loading={this.state.loading}
            nativeBalance={this.state.nativeBalance}
            nativeSymbol={this.state.nativeSymbol}
            nativeCurrency={this.state.nativeCurrency}
            rateCurrent={this.state.rateCurrent}
            selectedNetwork={this.state.selectedNetwork}
            showMessageLimit={this.state.showMessageLimit}
            messageLimit={this.state.messageLimit}
          />
        ),
      },
      {
        content: (
          <TwoFactorSend
            prev={this.prevStep.bind(this)}
            source={this.props.source}
            closeModal={
              this.props.source !== "wallet"
                ? this.props.closeModal.bind(this)
                : ""
            }
            resetCurrent={this.resetCurrent.bind(this)}
            disableResend={this.state.disableResend}
            sendPayment={this.sendPayment.bind(this)}
          />
        ),
      },
    ];

    return <div>{steps[this.state.current].content}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    banking: state.banking.accounts,
    asset: state.siderNavigate.asset,
    token: state.tokenAccess,
    respFees: state.banking.fees,
    beneficiariesList: state.beneficiaries.resultBeneficiaries,
    respLimits: state.banking.limits,
    send: state.banking.sendResp,
    respRate: state.exchange.resultGeneralRate,
    modalState: state.siderNavigate.modal,
    dataUser: state.authUser.userData,
    beneficiaryInfo: state.siderNavigate.infoToSend,
  };
};

const mapDispatchToProps = {
  getFees,
  selectedAsset,
  currentToken,
  getLimits,
  sendPay,
  getGeneralRate,
  typeBeneficiary,
};
const ContainerSendOrReceiveTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ContainerSendOrReceive)
);
export default ContainerSendOrReceiveTranslated;
