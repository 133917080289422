const config = {
  //ENUM: "649f7b0b9e3dba93aeefa2c48b0d2d2c22af19dd078ae905c62849035e86b0f8",
  ENUM: process.env.REACT_APP_ENUM,
  //URL: "https://business-test-api.damex.io/api/v1",
  URL: process.env.REACT_APP_API_ENDPOINT,

  //URL: "https://dampay.damcore.damkee.io/api/v1",
  //--- Request
  requestAccount: "/applicants",
  //--- Access
  login: "/auth",
  logout: "/auth/logout",
  acceptTerms: "/profile/accept-terms-and-conditions",
  //--- Password recovery
  sendEmailRecovery: "/password-recovery",
  confirmEmail: "/password-recovery/confirm-email",
  newPassword: "/password-recovery/new-password",
  //--- Refresh token
  refreshToken: "/auth/refresh",
  //--- Two factor
  activateTwoFactor: "/two-factor",
  confirmTwoFactor: "/two-factor/confirm",
  authTwoFactor: "/auth/two-factor",
  //---Profile
  manageUser: "/profile",
  confirmUpdate: "/profile/confirm-update",
  //---Preferences
  preferences: "/preferences",
  //---Banking
  getAccounts: "/accounts",
  //---Send
  sendPayments: "/transactions/create-payment",
  confirmPayment: "/transactions/confirm-payment",
  //---Notifications
  getNotifications: "/notifications",
  //---Beneficiaries
  beneficiaries: "/beneficiaries",
  //---Fees
  fees: "/fees",
  limits: "/limits",
  //---Transactions
  transactions: "/transactions",
  exchangeOrders: "/transactions/exchange-orders",
  //---Accounts
  transactionsPerAccount: "/accounts/",
  //---Exchange
  exchangeRate: "/exchange/",
  sell: "/exchange/sell",
  buy: "/exchange/buy",
  swap: "/exchange/swap",
  fx: "/exchange/fx",
  rate: "/rates/",
  market: "/market?",
  orders: "/exchange/orders",
  supportedPairs: "/exchange/supported-pairs",
  spreads: "/exchange/spreads",
  //---Timeline
  timelineBalance: "/accounts/timeline?",
  //---Audit log
  auditLogs: "/audit-logs",
  //---Manage Users
  userChildren: "/users-children",
  //---Rebalance
  rebalance: "/transactions/rebalance",
  // ---Approvals
  txAuthorization: "/transactions_authorisation",
  txPendingConfirmPayment: "/transactions/confirm-payment",
};
export default config;
