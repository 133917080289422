import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;
const transactions = {
  async getTransactionsByCurrency(idToken, accessToken, accountId, lastId) {
    let url =
      BASE_URL + config.transactionsPerAccount + accountId + "/transactions";
    if (lastId !== undefined) {
      url = url + "?last_id=" + lastId;
    }

    const resp = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async getAllTransactions(idToken, accessToken, lastId, status) {
    let url = BASE_URL + config.transactions;
    let urlFilter = BASE_URL + config.transactions;
    if (lastId !== undefined) {
      urlFilter = url + "?last_id=" + lastId;
    }
    if (status !== undefined) {
      urlFilter = url + "?status=" + status;
    }
    if (lastId !== undefined && status !== undefined) {
      urlFilter = url + "?status=" + status + "&last_id=" + lastId;
    }
    const resp = await axios.get(urlFilter, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async getAllTransactionsAndOrders(idToken, accessToken) {
    let url = BASE_URL + config.exchangeOrders;
    const resp = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },

  async getAllTransactionsByFilter(
    idToken,
    accessToken,
    currency,
    type,
    minDate,
    maxDate,
    lastId,
    transactionId
  ) {
    let timezone = new Date().getTimezoneOffset();
    let baseUrl = BASE_URL + config.transactions + "?status=COMPLETED";

    const resp = await axios.get(baseUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
      params: {
        account_id: currency,
        min_date: minDate,
        max_date: maxDate,
        timezone_offset: timezone,
        type: type,
        last_id: lastId,
        id: transactionId
      },
    });
    return resp;
  },
  async transactionCancelation(idToken, accessToken, tx_id) {
    let url = BASE_URL + config.transactionsCancelation + tx_id;
    const resp = await axios.post(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CLIENT-ID": idToken,
        },
      }
    );
    return resp;
  },
};
export default transactions;
