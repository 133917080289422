import React, { Component } from "react";
import "./approvals.css";
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  Table,
  Space,
  DatePicker,
  Skeleton,
  Select,
  message,
  Popconfirm,
} from "antd";
import COLOR from "../../const/colors";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { currentToken } from "../../redux/actions/tokenActions";
import { getAllTransactionsByFilter } from "../../redux/actions/approvalsActions";
import moment from "moment";
import { asset } from "../../common/assets";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import approvalsSevices from "../../services/approvals";
import usersChildren from "../../services/usersChildren";
import CAN from "../../casl/can";
const { Text } = Typography;
const { Option } = Select;
class Approvals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: "1",
      loading: false,
      listCurrencies: [],
      transactionsList: [],
      loadingFilter: false,
      lastId: null,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      minDateFilter: null,
      maxDateFilter: null,
      dateStartFilter: "",
      dateEndFilter: "",
      currencySelected: "",
      currencyLabel: null,
      modalApprove: false,
      modalDecline: false,
      currencyValue: null,
      userChildrenId: null,
      currentLastIdChildren: "",
      listUserChildrens: [],
      emailUserChildren: null,
    };
  }
  componentDidMount() {
    this.setState({ loadingFilter: true });
    this.loadCurrencies();
    // this.loadPendingTx();
    this.loadUserChildrens();
  }
  loadCurrencies() {
    let dataAccounts = this.props.accounts.data.data.accounts;
    let array = [];
    for (let account of dataAccounts) {
      let objCurrency = {};
      let findCurrency = asset.assets.find(
        (element) => element.value === account.currency
      );
      objCurrency.name = findCurrency.name;
      objCurrency.value = account.currency;
      objCurrency.key = account._id;
      objCurrency.alias = account.alias !== undefined ? account.alias : null;
      array.push(objCurrency);
    }

    array.push({ name: "All", value: "ALL", key: "ALL_1" });
    this.setState({ listCurrencies: array });
  }
  async loadPendingTx() {
    try {
      let token = this.props.accessToken;
      let userId = this.props.clientId;
      this.setState({ loadingFilter: true });
      await this.props.getAllTransactionsByFilter(
        userId,
        token,
        this.state.currencyValue,
        this.state.initiatedBySelected,
        this.state.minDateFilter,
        this.state.maxDateFilter,
        this.state.lastId
      );

      this.setState({ loadingFilter: false });
    } catch (error) {
      console.log("error", error);
    }
  }
  async loadUserChildrens() {
    try {
      let token = this.props.accessToken;
      let userId = this.props.clientId;
      //  console.log("last", this.state, this.props);
      const response = await usersChildren.getUsersChildren(userId, token);
      //  console.log("loadUserChildrens", response);
      const { current_last_id, users_children } = response.data.data;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      this.setState({
        listUserChildrens: users_children,
        currentLastIdChildren: current_last_id,
      });
      this.loadPendingTx();
    } catch (error) {
      this.loadPendingTx();
      console.log("error", error);
    }
  }
  formatDateView(dateLog) {
    let dateMoment = moment(dateLog).format("YYYY/MM/DD HH:mm:ss");
    let date = new Date(dateMoment + " UTC");
    let dateEvent =
      moment(date).format("DD/MM/YYYY") + " " + this.formatAmPm(date);
    return dateEvent;
  }
  formatAmPm(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  handleCurrency(value, option) {
    this.setState({
      currencyLabel: option.children,
      currencySelected: option.key,
      currencyValue: option.value,
    });
  }
  handleDateStart = (date) => {
    let minDate = date.format("YYYY-MM-DD");
    this.setState({
      dateStartFilter: date,
      minDateFilter: minDate,
    });
    if (this.state.maxDateFilter !== "" && this.state.maxDateFilter !== null) {
      if (date !== null) {
        if (minDate > this.state.maxDateFilter) {
          message.error(this.props.t("audit.error.dateError"));
          this.setState({ dateStartFilter: "", minDateFilter: null });
        }
      }
    }
  };
  handleDateEnd = (date) => {
    let maxDate = date.format("YYYY-MM-DD");
    this.setState({
      dateEndFilter: date,
      maxDateFilter: maxDate,
    });
    if (this.state.minDateFilter !== "" && this.state.minDateFilter !== null) {
      if (date !== null) {
        if (this.state.minDateFilter > maxDate) {
          message.error(this.props.t("audit.error.dateError"));
          this.setState({ dateEndFilter: "", maxDateFilter: null });
        }
      }
    }
  };
  hasMultipleAccounts() {
    let userAccounts =
      this.props.accounts.data?.data.native_balances.currencies;
    let findMultiple = userAccounts.find(
      (element) => element.accounts !== undefined && element.accounts.length > 1
    );
    if (findMultiple !== undefined) {
      return true;
    } else {
      return false;
    }
  }
  async openModalApprove(id) {
    let token = this.props.accessToken;
    let userId = this.props.clientId;
    this.setState({ loadingFilter: true });
    try {
      const response = await approvalsSevices.changeStatusPendingTransaction(
        userId,
        token,
        "APPROVED",
        id
      );

      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }

      message.success(
        this.props.t("approvals.messages.succesMessageChangeStatus")
      );
      this.loadPendingTx();
    } catch (error) {
      if (error.response.data.error_details?.validation_error !== undefined) {
        let messageError = error.response.data.error_details.validation_error;
        message.error(this.props.t("approvals.error." + messageError), 10);
      } else if (
        error.response.data.error_details?.payload_error !== undefined
      ) {
        let messageError = error.response.data.error_details.payload_error;
        message.error(this.props.t("approvals.error." + messageError), 10);
      } else {
        message.error(error.response.data.message);
      }
      this.setState({ loadingFilter: false });
      this.setState({ dateStartFilter: null, minDateFilter: null });
    }
  }

  async openModalDecline(id) {
    this.setState({ loadingFilter: true });
    let token = this.props.accessToken;
    let userId = this.props.clientId;
    try {
      const response = await approvalsSevices.changeStatusPendingTransaction(
        userId,
        token,
        "REJECTED",
        id
      );

      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }

      message.success(
        this.props.t("approvals.messages.succesMessageChangeStatus")
      );
      this.loadPendingTx();
    } catch (error) {
      if (error.response.data.error_details.validation_error !== undefined) {
        let messageError = error.response.data.error_details.validation_error;
        message.error(this.props.t("approvals.error." + messageError), 10);
      } else if (
        error.response.data.error_details.payload_error !== undefined
      ) {
        let messageError = error.response.data.error_details.payload_error;
        message.error(this.props.t("approvals.error." + messageError), 10);
      } else {
        message.error(error.response.data.message);
      }
      this.setState({ loadingFilter: false });
      this.setState({ dateStartFilter: null, minDateFilter: null });
    }
  }

  getNameCurrency(currency) {
    let findCurrency = asset.assets.find(
      (element) => element.value === currency
    );
    if (findCurrency) {
      return findCurrency.name;
    }
    return currency;
  }
  formatBeneficiary(destination) {
    let formatLabel = "";
    Object.entries(destination).forEach(([innerKey, innerValue]) => {
      if (innerKey === "account_holder") {
        formatLabel =
          formatLabel +
          this.props.t("approvals.pendingTable." + innerKey) +
          ": " +
          innerValue +
          " \n";
      }
      if (innerKey === "account_number") {
        formatLabel =
          formatLabel +
          this.props.t("approvals.pendingTable." + innerKey) +
          ": " +
          innerValue +
          " \n";
      }
      if (innerKey === "sort_code") {
        formatLabel =
          formatLabel +
          this.props.t("approvals.pendingTable." + innerKey) +
          ": " +
          innerValue +
          "\n";
      }
      if (innerKey === "iban") {
        formatLabel =
          formatLabel +
          this.props.t("approvals.pendingTable." + innerKey).toUpperCase() +
          ": " +
          innerValue +
          "\n";
      }
      if (innerKey === "bic") {
        formatLabel =
          formatLabel +
          this.props.t("approvals.pendingTable." + innerKey).toUpperCase() +
          ": " +
          innerValue +
          "\n";
      }
      if (innerKey === "address") {
        formatLabel =
          formatLabel +
          this.props.t("approvals.pendingTable." + innerKey) +
          ": " +
          innerValue +
          "\n";
      }
    });
    //console.log(formatLabel);
    return formatLabel;
  }
  render() {
    let multiple = this.hasMultipleAccounts();
    let emailUserSession = this.props.dataUser.userData?.data?.data?.user.email;

    const columns = [
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            ID
          </Text>
        ),
        dataIndex: "_id",
        key: "_id",
        width: 150,
        render: (id) => (
          <div>
            <Text
              className="id-column"
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {id}
            </Text>
          </div>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("approvals.pendingTable.date")}
          </Text>
        ),
        dataIndex: "created_date",
        key: "created_date",
        width: 120,
        render: (date) => (
          <div>
            <Text
              className="id-column"
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {this.formatDateView(date)}
            </Text>
          </div>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("approvals.pendingTable.currency")}
          </Text>
        ),
        dataIndex: "currency",
        key: "currency",
        width: 150,
        render: (currency) => (
          <div>
            <Text
              className="id-column"
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
                whiteSpace: "break-spaces",
              }}
            >
              {this.getNameCurrency(currency)}
              <br />
              {currency}
            </Text>
          </div>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("approvals.pendingTable.source")}
          </Text>
        ),
        dataIndex: "destination",
        key: "destination",
        width: 130,
        render: (destination) => {
          // console.log(destination);
          const { description } = destination;
          return (
            <div>
              <Text
                className="id-column"
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 16,
                  fontWeight: 400,
                }}
              >
                {description}
              </Text>
            </div>
          );
        },
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("approvals.pendingTable.reference")}
          </Text>
        ),
        dataIndex: "reference",
        key: "reference",
        render: (reference) => (
          <div>
            <Text
              className="id-column"
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {reference}
            </Text>
          </div>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("approvals.pendingTable.beneficiary")}
          </Text>
        ),
        dataIndex: "destination",
        key: "destination",
        width: 280,
        render: (destination) => {
          return (
            <div>
              <Text
                className="id-column"
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 16,
                  fontWeight: 400,
                  whiteSpace: "pre-line",
                }}
              >
                {this.formatBeneficiary(destination)}
              </Text>
            </div>
          );
        },
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("approvals.pendingTable.initiatedBy")}
          </Text>
        ),
        dataIndex: "initiated_by",
        key: "initiated_by",
        render: (initiated_by) => {
          const { email } = initiated_by;
          return (
            <div>
              <Text
                className="id-column"
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 16,
                  fontWeight: 400,
                }}
              >
                {email}
              </Text>
            </div>
          );
        },
      },

      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("approvals.pendingTable.amount")}
          </Text>
        ),
        dataIndex: "amount",
        key: "amount",
        render: (amount, originNode) => {
          const { currency } = originNode;
          return (
            <div>
              <Text
                className="id-column"
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 16,
                  fontWeight: 400,
                }}
              >
                {amount + " " + currency}
              </Text>
            </div>
          );
        },
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("approvals.pendingTable.fee")}
          </Text>
        ),
        dataIndex: "fees",
        key: "fees",
        render: (fees, originNode) => {
          const { currency } = originNode;
          return (
            <div>
              <Text
                className="id-column"
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 16,
                  fontWeight: 400,
                }}
              >
                {fees + " " + currency}
              </Text>
            </div>
          );
        },
      },

      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("approvals.pendingTable.actions")}
          </Text>
        ),
        dataIndex: "actions",
        key: "actions",
        className: CAN("SET", "STATUS_TRANSACTIONS")
          ? "column-actions"
          : "hidden-column",
        render: (record, originNode) => {
          const { _id, initiated_by } = originNode;
          const { email } = initiated_by;

          if (email !== emailUserSession) {
            return (
              <>
                <Space direction="horizontal">
                  <Popconfirm
                    title="Are you sure you want to approve this transaction?"
                    okText="Confirm"
                    cancelText="Cancel"
                    onConfirm={() => this.openModalApprove(_id)}
                    cancelButtonProps={{ className: "btn-cancel-pop" }}
                  >
                    <Button
                      type="link"
                      icon={<CheckCircleOutlined style={{ fontSize: 30 }} />}
                    />
                  </Popconfirm>
                  <Popconfirm
                    title="Are you sure you want to decline this transaction?"
                    okText="Confirm"
                    cancelText="Cancel"
                    cancelButtonProps={{ className: "btn-cancel-pop" }}
                    onConfirm={() => this.openModalDecline(_id)}
                  >
                    <Button
                      type="link"
                      icon={
                        <CloseCircleOutlined
                          style={{ fontSize: 30, color: COLOR.RED }}
                        />
                      }
                    />
                  </Popconfirm>
                </Space>
              </>
            );
          } else {
            return null;
          }
        },
      },
    ];

    return (
      <>
        <Card
          bordered={false}
          title={
            <Row
              gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
                xxl={{ span: 12, offset: 0 }}
              >
                <Text
                  style={{
                    fontSize: 18,
                    color: COLOR.PRIMARY_TEXT,
                    fontWeight: "bold",
                  }}
                >
                  {this.props.t("approvals.pending")}
                </Text>
              </Col>
            </Row>
          }
          size="default"
          style={{
            width: "100%",
            borderRadius: "5px",
            background: COLOR.PRIMARY,
            color: COLOR.SECONDARY_TEXT,
          }}
          bodyStyle={{ padding: "10px 0px 10px 0px" }}
        >
          <Row
            gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
            style={{ padding: "15px 15px" }}
          >
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 5, offset: 0 }}
              xxl={{ span: 5, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                id="select-trade"
                className="ant-select-selector ant-select"
                style={{ width: "100%" }}
              >
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  placeholder={this.props.t(
                    "approvals.pendingTable.allCurrencies"
                  )}
                  value={this.state.currencyLabel}
                  onChange={(value, option) =>
                    this.handleCurrency(value, option)
                  }
                  optionLabelProp="option"
                >
                  {this.state.listCurrencies.map((item, i) => {
                    return (
                      <Option key={item.key} value={item.value}>
                        {multiple
                          ? item.alias !== null && item.value !== "ALL"
                            ? item.name + ": " + item.alias
                            : item.name
                          : item.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Col>

            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 5, offset: 0 }}
              xxl={{ span: 5, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                id="select-trade"
                className="ant-select-selector ant-select"
                style={{ width: "100%" }}
              >
                <Select
                  style={{ width: "100%" }}
                  size="large"
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option.value.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    );
                  }}
                  //loading={this.state.loadingUsers}
                  value={this.state.emailUserChildren}
                  placeholder={this.props.t(
                    "approvals.pendingTable.initiatedBy"
                  )}
                  onChange={(value, option) => {
                    const { key } = option;
                    //  console.log("option", option);
                    this.setState({
                      initiatedBySelected: key,
                      emailUserChildren: value,
                    });
                  }}
                  optionLabelProp="option"
                >
                  {this.state.listUserChildrens.map((item, i) => {
                    return (
                      <Option key={item._id} value={item.email}>
                        {item.email}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 5, offset: 0 }}
              xxl={{ span: 5, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <DatePicker
                placeholder={this.props.t("approvals.pendingTable.startDate")}
                size="large"
                format="YYYY-MM-DD"
                style={{ width: "100%" }}
                autoFocus={false}
                showTime={false}
                value={this.state.dateStartFilter}
                onChange={(date) => this.handleDateStart(date)}
              />
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 5, offset: 0 }}
              xxl={{ span: 5, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <DatePicker
                placeholder={this.props.t("approvals.pendingTable.endDate")}
                size="large"
                format="YYYY-MM-DD"
                style={{ width: "100%" }}
                autoFocus={false}
                showTime={false}
                value={this.state.dateEndFilter}
                onChange={(date) => this.handleDateEnd(date)}
              />
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 2, offset: 0 }}
              xl={{ span: 2, offset: 0 }}
              xxl={{ span: 2, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Button
                type="primary"
                size="large"
                style={{ width: "100%" }}
                onClick={() => this.loadPendingTx()}
              >
                {this.props.t("approvals.pendingTable.filter")}
              </Button>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 2, offset: 0 }}
              xl={{ span: 2, offset: 0 }}
              xxl={{ span: 2, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Button
                className="disabledStyle"
                style={{ borderColor: COLOR.SECONDARY, width: "100%" }}
                size="large"
                onClick={() => {
                  this.setState({});
                  this.setState({
                    dateEndFilter: null,
                    maxDateFilter: null,
                    dateStartFilter: null,
                    minDateFilter: null,
                    initiatedBySelected: null,
                    emailUserChildren: null,
                    currencyLabel: null,
                    currencySelected: null,
                    currencyValue: null,
                  });
                }}
              >
                {this.props.t("approvals.pendingTable.clear")}
              </Button>
            </Col>
          </Row>

          {(this.state.loading || this.state.loadingFilter) && (
            <Skeleton active />
          )}
          {!this.state.loading && !this.state.loadingFilter && (
            <>
              <div
                id="table-history"
                className="ant-table ant-table-tbody ant-table-thead text-table-cell ant-table-cell"
                style={{ marginTop: 10 }}
              >
                <Table
                  rowKey={"id"}
                  locale={{
                    emptyText: (
                      <Space direction="vertical">
                        <br />
                        <Text
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                        >
                          {this.props.t("approvals.pendingTable.msgEmpty")}
                        </Text>
                        <Text
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            fontSize: 16,
                            fontWeight: "normal",
                          }}
                        >
                          {this.props.t("approvals.pendingTable.msgEmpty1")}
                        </Text>
                        <br />
                      </Space>
                    ),
                  }}
                  loading={this.state.loading}
                  columns={columns}
                  dataSource={this.props.resultTransactionsPendings}
                  //   pagination={this.state.pagination}
                  //   onChange={this.handleTableChange}
                />
              </div>
            </>
          )}
        </Card>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser,
    accessToken: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
    accounts: state.banking.accounts,
    current_last_id: state.approvals.current_last_id,
    resultTransactionsPendings: state.approvals.resultTransactionsPendings,
  };
};

const mapDispatchToProps = {
  currentToken,
  getAllTransactionsByFilter,
};

const ApprovalsTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Approvals)
);

export default ApprovalsTranslated;
