export const countrys= {
  country: [
    {
      nombre: "Afganistan",
      text: "Afghanistan",
      nom: "Afghanistan",
      iso2: "AF",
      iso3: "AFG",
      value: "93",
    },
    {
      nombre: "Albania",
      text: "Albania",
      nom: "Albanie",
      iso2: "AL",
      iso3: "ALB",
      value: "355",
    },
    {
      nombre: "Alemania",
      text: "Germany",
      nom: "Allemagne",
      iso2: "DE",
      iso3: "DEU",
      value: "49",
    },
    {
      nombre: "Algeria",
      text: "Algeria",
      nom: "AlgÃ©rie",
      iso2: "DZ",
      iso3: "DZA",
      value: "213",
    },
    {
      nombre: "Andorra",
      text: "Andorra",
      nom: "Andorra",
      iso2: "AD",
      iso3: "AND",
      value: "376",
    },
    {
      nombre: "Angola",
      text: "Angola",
      nom: "Angola",
      iso2: "AO",
      iso3: "AGO",
      value: "244",
    },
    {
      nombre: "Anguila",
      text: "Anguilla",
      nom: "Anguilla",
      iso2: "AI",
      iso3: "AIA",
      value: "1 264",
    },
    {
      nombre: "Antartida",
      text: "Antarctica",
      nom: "L'Antarctique",
      iso2: "AQ",
      iso3: "ATA",
      value: "672",
    },
    {
      nombre: "Antigua y Barbuda",
      text: "Antigua and Barbuda",
      nom: "Antigua et Barbuda",
      iso2: "AG",
      iso3: "ATG",
      value: "1 268",
    },
    {
      nombre: "Antillas Neerlandesas",
      text: "Netherlands Antilles",
      nom: "Antilles NÃ©erlandaises",
      iso2: "AN",
      iso3: "ANT",
      value: "599",
    },
    {
      nombre: "Arabia Saudita",
      text: "Saudi Arabia",
      nom: "Arabie Saoudite",
      iso2: "SA",
      iso3: "SAU",
      value: "966",
    },
    {
      nombre: "Argentina",
      text: "Argentina",
      nom: "Argentine",
      iso2: "AR",
      iso3: "ARG",
      value: "54",
    },
    {
      nombre: "Armenia",
      text: "Armenia",
      nom: "L'ArmÃ©nie",
      iso2: "AM",
      iso3: "ARM",
      value: "374",
    },
    {
      nombre: "Aruba",
      text: "Aruba",
      nom: "Aruba",
      iso2: "AW",
      iso3: "ABW",
      value: "297",
    },
    {
      nombre: "Australia",
      text: "Australia",
      nom: "Australie",
      iso2: "AU",
      iso3: "AUS",
      value: "61",
    },
    {
      nombre: "Austria",
      text: "Austria",
      nom: "Autriche",
      iso2: "AT",
      iso3: "AUT",
      value: "43",
    },
    {
      nombre: "Azerbaijan",
      text: "Azerbaijan",
      nom: "L'AzerbaÃ¯djan",
      iso2: "AZ",
      iso3: "AZE",
      value: "994",
    },
    {
      nombre: "Belgica",
      text: "Belgium",
      nom: "Belgique",
      iso2: "BE",
      iso3: "BEL",
      value: "32",
    },
    {
      nombre: "Bahamas",
      text: "Bahamas",
      nom: "Bahamas",
      iso2: "BS",
      iso3: "BHS",
      value: "1 242",
    },
    {
      nombre: "Bahrein",
      text: "Bahrain",
      nom: "BahreÃ¯n",
      iso2: "BH",
      iso3: "BHR",
      value: "973",
    },
    {
      nombre: "Bangladesh",
      text: "Bangladesh",
      nom: "Bangladesh",
      iso2: "BD",
      iso3: "BGD",
      value: "880",
    },
    {
      nombre: "Barbados",
      text: "Barbados",
      nom: "Barbade",
      iso2: "BB",
      iso3: "BRB",
      value: "1 246",
    },
    {
      nombre: "Belice",
      text: "Belize",
      nom: "Belize",
      iso2: "BZ",
      iso3: "BLZ",
      value: "501",
    },
    {
      nombre: "Beni­n",
      text: "Benin",
      nom: "BÃ©nin",
      iso2: "BJ",
      iso3: "BEN",
      value: "229",
    },
    {
      nombre: "Bhutan",
      text: "Bhutan",
      nom: "Le Bhoutan",
      iso2: "BT",
      iso3: "BTN",
      value: "975",
    },
    {
      nombre: "Bielorrusia",
      text: "Bielorrusia",
      nom: "BiÃ©lorussie",
      iso2: "BY",
      iso3: "BLR",
      value: "375",
    },
    {
      nombre: "Birmania",
      text: "Birmania",
      nom: "Myanmar",
      iso2: "MM",
      iso3: "MMR",
      value: "95",
    },
    {
      nombre: "Bolivia",
      text: "Bolivia",
      nom: "Bolivie",
      iso2: "BO",
      iso3: "BOL",
      value: "591",
    },
    {
      nombre: "Bosnia y Herzegovina",
      text: "Bosnia and Herzegovina",
      nom: "Bosnie-HerzÃ©govine",
      iso2: "BA",
      iso3: "BIH",
      value: "387",
    },
    {
      nombre: "Botsuana",
      text: "Botswana",
      nom: "Botswana",
      iso2: "BW",
      iso3: "BWA",
      value: "267",
    },
    {
      nombre: "Brasil",
      text: "Brazil",
      nom: "BrÃ©sil",
      iso2: "BR",
      iso3: "BRA",
      value: "55",
    },
    {
      nombre: "Brunei",
      text: "Brunei",
      nom: "Brunei",
      iso2: "BN",
      iso3: "BRN",
      value: "673",
    },
    {
      nombre: "Bulgaria",
      text: "Bulgaria",
      nom: "Bulgarie",
      iso2: "BG",
      iso3: "BGR",
      value: "359",
    },
    {
      nombre: "Burkina Faso",
      text: "Burkina Faso",
      nom: "Burkina Faso",
      iso2: "BF",
      iso3: "BFA",
      value: "226",
    },
    {
      nombre: "Burundi",
      text: "Burundi",
      nom: "Burundi",
      iso2: "BI",
      iso3: "BDI",
      value: "257",
    },
    {
      nombre: "Cabo Verde",
      text: "Cabo Verde",
      nom: "Cap-Vert",
      iso2: "CV",
      iso3: "CPV",
      value: "238",
    },
    {
      nombre: "Camboya",
      text: "Camboya",
      nom: "Cambodge",
      iso2: "KH",
      iso3: "KHM",
      value: "855",
    },
    {
      nombre: "Camerun",
      text: "Cameroon",
      nom: "Cameroun",
      iso2: "CM",
      iso3: "CMR",
      value: "237",
    },
    {
      nombre: "Canada",
      text: "Canada",
      nom: "Canada",
      iso2: "CA",
      iso3: "CAN",
      value: "1",
    },
    {
      nombre: "Chad",
      text: "Chad",
      nom: "Tchad",
      iso2: "TD",
      iso3: "TCD",
      value: "235",
    },
    {
      nombre: "Chile",
      text: "Chile",
      nom: "Chili",
      iso2: "CL",
      iso3: "CHL",
      value: "56",
    },
    {
      nombre: "China",
      text: "China",
      nom: "Chine",
      iso2: "CN",
      iso3: "CHN",
      value: "86",
    },
    {
      nombre: "Chipre",
      text: "Cyprus",
      nom: "Chypre",
      iso2: "CY",
      iso3: "CYP",
      value: "357",
    },
    {
      nombre: "Ciudad del Vaticano",
      text: "Vatican City State",
      nom: "CitÃ© du Vatican",
      iso2: "VA",
      iso3: "VAT",
      value: "39",
    },
    {
      nombre: "Colombia",
      text: "Colombia",
      nom: "Colombie",
      iso2: "CO",
      iso3: "COL",
      value: "57",
    },
    {
      nombre: "Comoras",
      text: "Comoros",
      nom: "Comores",
      iso2: "KM",
      iso3: "COM",
      value: "269",
    },
    {
      nombre: "Congo",
      text: "Congo",
      nom: "Congo",
      iso2: "CG",
      iso3: "COG",
      value: "242",
    },
    {
      nombre: "Congo",
      text: "Congo",
      nom: "Congo",
      iso2: "CD",
      iso3: "COD",
      value: "243",
    },
    {
      nombre: "Corea del Norte",
      text: "North Korea",
      nom: "CorÃ©e du Nord",
      iso2: "KP",
      iso3: "PRK",
      value: "850",
    },
    {
      nombre: "Corea del Sur",
      text: "South Korea",
      nom: "CorÃ©e du Sud",
      iso2: "KR",
      iso3: "KOR",
      value: "82",
    },
    {
      nombre: "Costa de Marfil",
      text: "Ivory Coast",
      nom: "CÃ´te-d'Ivoire",
      iso2: "CI",
      iso3: "CIV",
      value: "225",
    },
    {
      nombre: "Costa Rica",
      text: "Costa Rica",
      nom: "Costa Rica",
      iso2: "CR",
      iso3: "CRI",
      value: "506",
    },
    {
      nombre: "Croacia",
      text: "Croatia",
      nom: "Croatie",
      iso2: "HR",
      iso3: "HRV",
      value: "385",
    },
    {
      nombre: "Cuba",
      text: "Cuba",
      nom: "Cuba",
      iso2: "CU",
      iso3: "CUB",
      value: "53",
    },
    {
      nombre: "Dinamarca",
      text: "Denmark",
      nom: "Danemark",
      iso2: "DK",
      iso3: "DNK",
      value: "45",
    },
    {
      nombre: "Dominica",
      text: "Dominica",
      nom: "Dominique",
      iso2: "DM",
      iso3: "DMA",
      value: "1 767",
    },
    {
      nombre: "Ecuador",
      text: "Ecuador",
      nom: "Equateur",
      iso2: "EC",
      iso3: "ECU",
      value: "593",
    },
    {
      nombre: "Egipto",
      text: "Egypt",
      nom: "Egypte",
      iso2: "EG",
      iso3: "EGY",
      value: "20",
    },
    {
      nombre: "El Salvador",
      text: "El Salvador",
      nom: "El Salvador",
      iso2: "SV",
      iso3: "SLV",
      value: "503",
    },
    {
      nombre: "Emiratos Arabes Unidos",
      text: "United Arab Emirates",
      nom: "Emirats Arabes Unis",
      iso2: "AE",
      iso3: "ARE",
      value: "971",
    },
    {
      nombre: "Eritrea",
      text: "Eritrea",
      nom: "ErythrÃ©e",
      iso2: "ER",
      iso3: "ERI",
      value: "291",
    },
    {
      nombre: "Eslovaquia",
      text: "Slovakia",
      nom: "Slovaquie",
      iso2: "SK",
      iso3: "SVK",
      value: "421",
    },
    {
      nombre: "Eslovenia",
      text: "Slovenia",
      nom: "SlovÃ©nie",
      iso2: "SI",
      iso3: "SVN",
      value: "386",
    },
    {
      nombre: "España",
      text: "Spain",
      nom: "Espagne",
      iso2: "ES",
      iso3: "ESP",
      value: "34",
    },
    {
      nombre: "Estados Unidos de América",
      text: "United States of America",
      nom: "Ã‰tats-Unis d'AmÃ©rique",
      iso2: "US",
      iso3: "USA",
      value: "1",
    },
    {
      nombre: "Estonia",
      text: "Estonia",
      nom: "L'Estonie",
      iso2: "EE",
      iso3: "EST",
      value: "372",
    },
    {
      nombre: "Etiopía",
      text: "Ethiopia",
      nom: "Ethiopie",
      iso2: "ET",
      iso3: "ETH",
      value: "251",
    },
    {
      nombre: "Filipinas",
      text: "Philippines",
      nom: "Philippines",
      iso2: "PH",
      iso3: "PHL",
      value: "63",
    },
    {
      nombre: "Finlandia",
      text: "Finland",
      nom: "Finlande",
      iso2: "FI",
      iso3: "FIN",
      value: "358",
    },
    {
      nombre: "Fiyi",
      text: "Fiji",
      nom: "Fidji",
      iso2: "FJ",
      iso3: "FJI",
      value: "679",
    },
    {
      nombre: "Francia",
      text: "France",
      nom: "France",
      iso2: "FR",
      iso3: "FRA",
      value: "33",
    },
    {
      nombre: "Gabon",
      text: "Gabon",
      nom: "Gabon",
      iso2: "GA",
      iso3: "GAB",
      value: "241",
    },
    {
      nombre: "Gambia",
      text: "Gambia",
      nom: "Gambie",
      iso2: "GM",
      iso3: "GMB",
      value: "220",
    },
    {
      nombre: "Georgia",
      text: "Georgia",
      nom: "GÃ©orgie",
      iso2: "GE",
      iso3: "GEO",
      value: "995",
    },
    {
      nombre: "Ghana",
      text: "Ghana",
      nom: "Ghana",
      iso2: "GH",
      iso3: "GHA",
      value: "233",
    },
    {
      nombre: "Gibraltar",
      text: "Gibraltar",
      nom: "Gibraltar",
      iso2: "GI",
      iso3: "GIB",
      value: "350",
    },
    {
      nombre: "Granada",
      text: "Grenada",
      nom: "Grenade",
      iso2: "GD",
      iso3: "GRD",
      value: "1 473",
    },
    {
      nombre: "Grecia",
      text: "Greece",
      nom: "GrÃ¨ce",
      iso2: "GR",
      iso3: "GRC",
      value: "30",
    },
    {
      nombre: "Groenlandia",
      text: "Greenland",
      nom: "Groenland",
      iso2: "GL",
      iso3: "GRL",
      value: "299",
    },
    {
      nombre: "Guadalupe",
      text: "Guadalupe",
      nom: "Guadeloupe",
      iso2: "GP",
      iso3: "GLP",
      value: "",
    },
    {
      nombre: "Guam",
      text: "Guam",
      nom: "Guam",
      iso2: "GU",
      iso3: "GUM",
      value: "1 671",
    },
    {
      nombre: "Guatemala",
      text: "Guatemala",
      nom: "Guatemala",
      iso2: "GT",
      iso3: "GTM",
      value: "502",
    },
    {
      nombre: "Guayana Francesa",
      text: "French Guiana",
      nom: "Guyane franÃ§aise",
      iso2: "GF",
      iso3: "GUF",
      value: "",
    },
    {
      nombre: "Guernsey",
      text: "Guernsey",
      nom: "Guernesey",
      iso2: "GG",
      iso3: "GGY",
      value: "",
    },
    {
      nombre: "Guinea",
      text: "Guinea",
      nom: "GuinÃ©e",
      iso2: "GN",
      iso3: "GIN",
      value: "224",
    },
    {
      nombre: "Guinea Ecuatorial",
      text: "Equatorial Guinea",
      nom: "GuinÃ©e Equatoriale",
      iso2: "GQ",
      iso3: "GNQ",
      value: "240",
    },
    {
      nombre: "Guinea-Bissau",
      text: "Guinea-Bissau",
      nom: "GuinÃ©e-Bissau",
      iso2: "GW",
      iso3: "GNB",
      value: "245",
    },
    {
      nombre: "Guyana",
      text: "Guyana",
      nom: "Guyane",
      iso2: "GY",
      iso3: "GUY",
      value: "592",
    },
    {
      nombre: "Haití­",
      text: "Haiti",
      nom: "HaÃ¯ti",
      iso2: "HT",
      iso3: "HTI",
      value: "509",
    },
    {
      nombre: "Honduras",
      text: "Honduras",
      nom: "Honduras",
      iso2: "HN",
      iso3: "HND",
      value: "504",
    },
    {
      nombre: "Hong kong",
      text: "Hong Kong",
      nom: "Hong Kong",
      iso2: "HK",
      iso3: "HKG",
      value: "852",
    },
    {
      nombre: "Hungrí­a",
      text: "Hungary",
      nom: "Hongrie",
      iso2: "HU",
      iso3: "HUN",
      value: "36",
    },
    {
      nombre: "India",
      text: "India",
      nom: "Inde",
      iso2: "IN",
      iso3: "IND",
      value: "91",
    },
    {
      nombre: "Indonesia",
      text: "Indonesia",
      nom: "IndonÃ©sie",
      iso2: "ID",
      iso3: "IDN",
      value: "62",
    },
    {
      nombre: "Irán",
      text: "Iran",
      nom: "Iran",
      iso2: "IR",
      iso3: "IRN",
      value: "98",
    },
    {
      nombre: "Irak",
      text: "Iraq",
      nom: "Irak",
      iso2: "IQ",
      iso3: "IRQ",
      value: "964",
    },
    {
      nombre: "Irlanda",
      text: "Ireland",
      nom: "Irlande",
      iso2: "IE",
      iso3: "IRL",
      value: "353",
    },
    {
      nombre: "Isla Bouvet",
      text: "Bouvet Island",
      nom: "Bouvet Island",
      iso2: "BV",
      iso3: "BVT",
      value: "",
    },
    {
      nombre: "Isla de Man",
      text: "Isle of Man",
      nom: "Ile de Man",
      iso2: "IM",
      iso3: "IMN",
      value: "44",
    },
    {
      nombre: "Isla de Navidad",
      text: "Christmas Island",
      nom: "Christmas Island",
      iso2: "CX",
      iso3: "CXR",
      value: "61",
    },
    {
      nombre: "Isla Norfolk",
      text: "Norfolk Island",
      nom: "ÃŽle de Norfolk",
      iso2: "NF",
      iso3: "NFK",
      value: "",
    },
    {
      nombre: "Islandia",
      text: "Iceland",
      nom: "Islande",
      iso2: "IS",
      iso3: "ISL",
      value: "354",
    },
    {
      nombre: "Islas Bermudas",
      text: "Bermuda Islands",
      nom: "Bermudes",
      iso2: "BM",
      iso3: "BMU",
      value: "1 441",
    },
    {
      nombre: "Islas Caimán",
      text: "Cayman Islands",
      nom: "Iles CaÃ¯mans",
      iso2: "KY",
      iso3: "CYM",
      value: "1 345",
    },
    {
      nombre: "Islas Cocos (Keeling)",
      text: "Cocos (Keeling) Islands",
      nom: "Cocos (Keeling",
      iso2: "CC",
      iso3: "CCK",
      value: "61",
    },
    {
      nombre: "Islas Cook",
      text: "Cook Islands",
      nom: "Iles Cook",
      iso2: "CK",
      iso3: "COK",
      value: "682",
    },
    {
      nombre: "Islas de Áland",
      text: "Aland Islands",
      nom: "ÃŽles Ã…land",
      iso2: "AX",
      iso3: "ALA",
      value: "",
    },
    {
      nombre: "Islas Feroe",
      text: "Faroe Islands",
      nom: "Iles FÃ©ro",
      iso2: "FO",
      iso3: "FRO",
      value: "298",
    },
    {
      nombre: "Islas Georgias del Sur y Sandwich del Sur",
      text: "South Georgia and the South Sandwich Islands",
      nom: "GÃ©orgie du Sud et les ÃŽles Sandwich du Sud",
      iso2: "GS",
      iso3: "SGS",
      value: "",
    },
    {
      nombre: "Islas Heard y McDonald",
      text: "Heard Island and McDonald Islands",
      nom: "Les Ã®les Heard et McDonald",
      iso2: "HM",
      iso3: "HMD",
      value: "",
    },
    {
      nombre: "Islas Maldivas",
      text: "Maldives",
      nom: "Maldives",
      iso2: "MV",
      iso3: "MDV",
      value: "960",
    },
    {
      nombre: "Islas Malvinas",
      text: "Falkland Islands (Malvinas)",
      nom: "Iles Falkland (Malvinas",
      iso2: "FK",
      iso3: "FLK",
      value: "500",
    },
    {
      nombre: "Islas Marianas del Norte",
      text: "Northern Mariana Islands",
      nom: "Iles Mariannes du Nord",
      iso2: "MP",
      iso3: "MNP",
      value: "1 670",
    },
    {
      nombre: "Islas Marshall",
      text: "Marshall Islands",
      nom: "Iles Marshall",
      iso2: "MH",
      iso3: "MHL",
      value: "692",
    },
    {
      nombre: "Islas Pitcairn",
      text: "Pitcairn Islands",
      nom: "Iles Pitcairn",
      iso2: "PN",
      iso3: "PCN",
      value: "870",
    },
    {
      nombre: "Islas Salomón",
      text: "Solomon Islands",
      nom: "Iles Salomon",
      iso2: "SB",
      iso3: "SLB",
      value: "677",
    },
    {
      nombre: "Islas Turcas y Caicos",
      text: "Turks and Caicos Islands",
      nom: "Iles Turques et CaÃ¯ques",
      iso2: "TC",
      iso3: "TCA",
      value: "1 649",
    },
    {
      nombre: "Islas Ultramarinas Menores de Estados Unidos",
      text: "United States Minor Outlying Islands",
      nom: "Ã‰tats-Unis ÃŽles mineures Ã©loignÃ©es",
      iso2: "UM",
      iso3: "UMI",
      value: "",
    },
    {
      nombre: "Islas Ví­rgenes Británicas",
      text: "Virgin Islands",
      nom: "Iles Vierges",
      iso2: "VG",
      iso3: "VG",
      value: "1 284",
    },
    {
      nombre: "Islas Vírgenes de los Estados Unidos",
      text: "United States Virgin Islands",
      nom: "ÃŽles Vierges amÃ©ricaines",
      iso2: "VI",
      iso3: "VIR",
      value: "1 340",
    },
    {
      nombre: "Israel",
      text: "Israel",
      nom: "IsraÃ«l",
      iso2: "IL",
      iso3: "ISR",
      value: "972",
    },
    {
      nombre: "Italia",
      text: "Italy",
      nom: "Italie",
      iso2: "IT",
      iso3: "ITA",
      value: "39",
    },
    {
      nombre: "Jamaica",
      text: "Jamaica",
      nom: "JamaÃ¯que",
      iso2: "JM",
      iso3: "JAM",
      value: "1 876",
    },
    {
      nombre: "Japón",
      text: "Japan",
      nom: "Japon",
      iso2: "JP",
      iso3: "JPN",
      value: "81",
    },
    {
      nombre: "Jersey",
      text: "Jersey",
      nom: "Maillot",
      iso2: "JE",
      iso3: "JEY",
      value: "",
    },
    {
      nombre: "Jordania",
      text: "Jordan",
      nom: "Jordan",
      iso2: "JO",
      iso3: "JOR",
      value: "962",
    },
    {
      nombre: "Kazajistán",
      text: "Kazakhstan",
      nom: "Le Kazakhstan",
      iso2: "KZ",
      iso3: "KAZ",
      value: "7",
    },
    {
      nombre: "Kenia",
      text: "Kenya",
      nom: "Kenya",
      iso2: "KE",
      iso3: "KEN",
      value: "254",
    },
    {
      nombre: "Kirgizstán",
      text: "Kyrgyzstan",
      nom: "Kirghizstan",
      iso2: "KG",
      iso3: "KGZ",
      value: "996",
    },
    {
      nombre: "Kiribati",
      text: "Kiribati",
      nom: "Kiribati",
      iso2: "KI",
      iso3: "KIR",
      value: "686",
    },
    {
      nombre: "Kuwait",
      text: "Kuwait",
      nom: "KoweÃ¯t",
      iso2: "KW",
      iso3: "KWT",
      value: "965",
    },
    {
      nombre: "Lí­bano",
      text: "Lebanon",
      nom: "Liban",
      iso2: "LB",
      iso3: "LBN",
      value: "961",
    },
    {
      nombre: "Laos",
      text: "Laos",
      nom: "Laos",
      iso2: "LA",
      iso3: "LAO",
      value: "856",
    },
    {
      nombre: "Lesoto",
      text: "Lesotho",
      nom: "Lesotho",
      iso2: "LS",
      iso3: "LSO",
      value: "266",
    },
    {
      nombre: "Letonia",
      text: "Latvia",
      nom: "La Lettonie",
      iso2: "LV",
      iso3: "LVA",
      value: "371",
    },
    {
      nombre: "Liberia",
      text: "Liberia",
      nom: "Liberia",
      iso2: "LR",
      iso3: "LBR",
      value: "231",
    },
    {
      nombre: "Libia",
      text: "Libya",
      nom: "Libye",
      iso2: "LY",
      iso3: "LBY",
      value: "218",
    },
    {
      nombre: "Liechtenstein",
      text: "Liechtenstein",
      nom: "Liechtenstein",
      iso2: "LI",
      iso3: "LIE",
      value: "423",
    },
    {
      nombre: "Lituania",
      text: "Lithuania",
      nom: "La Lituanie",
      iso2: "LT",
      iso3: "LTU",
      value: "370",
    },
    {
      nombre: "Luxemburgo",
      text: "Luxembourg",
      nom: "Luxembourg",
      iso2: "LU",
      iso3: "LUX",
      value: "352",
    },
    {
      nombre: "México",
      text: "Mexico",
      nom: "Mexique",
      iso2: "MX",
      iso3: "MEX",
      value: "52",
    },
    {
      nombre: "Monaco",
      text: "Monaco",
      nom: "Monaco",
      iso2: "MC",
      iso3: "MCO",
      value: "377",
    },
    {
      nombre: "Macao",
      text: "Macao",
      nom: "Macao",
      iso2: "MO",
      iso3: "MAC",
      value: "853",
    },
    {
      nombre: "Macedónia",
      text: "Macedonia",
      nom: "MacÃ©doine",
      iso2: "MK",
      iso3: "MKD",
      value: "389",
    },
    {
      nombre: "Madagascar",
      text: "Madagascar",
      nom: "Madagascar",
      iso2: "MG",
      iso3: "MDG",
      value: "261",
    },
    {
      nombre: "Malasia",
      text: "Malaysia",
      nom: "Malaisie",
      iso2: "MY",
      iso3: "MYS",
      value: "60",
    },
    {
      nombre: "Malawi",
      text: "Malawi",
      nom: "Malawi",
      iso2: "MW",
      iso3: "MWI",
      value: "265",
    },
    {
      nombre: "Mali",
      text: "Mali",
      nom: "Mali",
      iso2: "ML",
      iso3: "MLI",
      value: "223",
    },
    {
      nombre: "Malta",
      text: "Malta",
      nom: "Malte",
      iso2: "MT",
      iso3: "MLT",
      value: "356",
    },
    {
      nombre: "Marruecos",
      text: "Morocco",
      nom: "Maroc",
      iso2: "MA",
      iso3: "MAR",
      value: "212",
    },
    {
      nombre: "Martinica",
      text: "Martinique",
      nom: "Martinique",
      iso2: "MQ",
      iso3: "MTQ",
      value: "",
    },
    {
      nombre: "Mauricio",
      text: "Mauritius",
      nom: "Iles Maurice",
      iso2: "MU",
      iso3: "MUS",
      value: "230",
    },
    {
      nombre: "Mauritania",
      text: "Mauritania",
      nom: "Mauritanie",
      iso2: "MR",
      iso3: "MRT",
      value: "222",
    },
    {
      nombre: "Mayotte",
      text: "Mayotte",
      nom: "Mayotte",
      iso2: "YT",
      iso3: "MYT",
      value: "262",
    },
    {
      nombre: "Micronesia",
      text: "Federated States of Micronesia",
      nom: "Federados Estados de",
      iso2: "FM",
      iso3: "FSM",
      value: "691",
    },
    {
      nombre: "Moldavia",
      text: "Moldova",
      nom: "Moldavie",
      iso2: "MD",
      iso3: "MDA",
      value: "373",
    },
    {
      nombre: "Mongolia",
      text: "Mongolia",
      nom: "Mongolie",
      iso2: "MN",
      iso3: "MNG",
      value: "976",
    },
    {
      nombre: "Montenegro",
      text: "Montenegro",
      nom: "MontÃ©nÃ©gro",
      iso2: "ME",
      iso3: "MNE",
      value: "382",
    },
    {
      nombre: "Montserrat",
      text: "Montserrat",
      nom: "Montserrat",
      iso2: "MS",
      iso3: "MSR",
      value: "1 664",
    },
    {
      nombre: "Mozambique",
      text: "Mozambique",
      nom: "Mozambique",
      iso2: "MZ",
      iso3: "MOZ",
      value: "258",
    },
    {
      nombre: "Namibia",
      text: "Namibia",
      nom: "Namibie",
      iso2: "NA",
      iso3: "NAM",
      value: "264",
    },
    {
      nombre: "Nauru",
      text: "Nauru",
      nom: "Nauru",
      iso2: "NR",
      iso3: "NRU",
      value: "674",
    },
    {
      nombre: "Nepal",
      text: "Nepal",
      nom: "NÃ©pal",
      iso2: "NP",
      iso3: "NPL",
      value: "977",
    },
    {
      nombre: "Nicaragua",
      text: "Nicaragua",
      nom: "Nicaragua",
      iso2: "NI",
      iso3: "NIC",
      value: "505",
    },
    {
      nombre: "Niger",
      text: "Niger",
      nom: "Niger",
      iso2: "NE",
      iso3: "NER",
      value: "227",
    },
    {
      nombre: "Nigeria",
      text: "Nigeria",
      nom: "Nigeria",
      iso2: "NG",
      iso3: "NGA",
      value: "234",
    },
    {
      nombre: "Niue",
      text: "Niue",
      nom: "Niou",
      iso2: "NU",
      iso3: "NIU",
      value: "683",
    },
    {
      nombre: "Noruega",
      text: "Norway",
      nom: "NorvÃ¨ge",
      iso2: "NO",
      iso3: "NOR",
      value: "47",
    },
    {
      nombre: "Nueva Caledonia",
      text: "New Caledonia",
      nom: "Nouvelle-CalÃ©donie",
      iso2: "NC",
      iso3: "NCL",
      value: "687",
    },
    {
      nombre: "Nueva Zelanda",
      text: "New Zealand",
      nom: "Nouvelle-ZÃ©lande",
      iso2: "NZ",
      iso3: "NZL",
      value: "64",
    },
    {
      nombre: "Omán",
      text: "Oman",
      nom: "Oman",
      iso2: "OM",
      iso3: "OMN",
      value: "968",
    },
    {
      nombre: "Paí­ses Bajos",
      text: "Netherlands",
      nom: "Pays-Bas",
      iso2: "NL",
      iso3: "NLD",
      value: "31",
    },
    {
      nombre: "Pakistán",
      text: "Pakistan",
      nom: "Pakistan",
      iso2: "PK",
      iso3: "PAK",
      value: "92",
    },
    {
      nombre: "Palau",
      text: "Palau",
      nom: "Palau",
      iso2: "PW",
      iso3: "PLW",
      value: "680",
    },
    {
      nombre: "Palestina",
      text: "Palestine",
      nom: "La Palestine",
      iso2: "PS",
      iso3: "PSE",
      value: "",
    },
    {
      nombre: "Panamá",
      text: "Panama",
      nom: "Panama",
      iso2: "PA",
      iso3: "PAN",
      value: "507",
    },
    {
      nombre: "Papúa Nueva Guinea",
      text: "Papua New Guinea",
      nom: "Papouasie-Nouvelle-GuinÃ©e",
      iso2: "PG",
      iso3: "PNG",
      value: "675",
    },
    {
      nombre: "Paraguay",
      text: "Paraguay",
      nom: "Paraguay",
      iso2: "PY",
      iso3: "PRY",
      value: "595",
    },
    {
      nombre: "Perú",
      text: "Peru",
      nom: "PÃ©rou",
      iso2: "PE",
      iso3: "PER",
      value: "51",
    },
    {
      nombre: "Polinesia Francesa",
      text: "French Polynesia",
      nom: "PolynÃ©sie franÃ§aise",
      iso2: "PF",
      iso3: "PYF",
      value: "689",
    },
    {
      nombre: "Polonia",
      text: "Poland",
      nom: "Pologne",
      iso2: "PL",
      iso3: "POL",
      value: "48",
    },
    {
      nombre: "Portugal",
      text: "Portugal",
      nom: "Portugal",
      iso2: "PT",
      iso3: "PRT",
      value: "351",
    },
    {
      nombre: "Puerto Rico",
      text: "Puerto Rico",
      nom: "Porto Rico",
      iso2: "PR",
      iso3: "PRI",
      value: "1",
    },
    {
      nombre: "Qatar",
      text: "Qatar",
      nom: "Qatar",
      iso2: "QA",
      iso3: "QAT",
      value: "974",
    },
    {
      nombre: "Reino Unido",
      text: "United Kingdom",
      nom: "Royaume-Uni",
      iso2: "GB",
      iso3: "GBR",
      value: "44",
    },
    {
      nombre: "República Centroafricana",
      text: "Central African Republic",
      nom: "RÃ©publique Centrafricaine",
      iso2: "CF",
      iso3: "CAF",
      value: "236",
    },
    {
      nombre: "República Checa",
      text: "Czech Republic",
      nom: "RÃ©publique TchÃ¨que",
      iso2: "CZ",
      iso3: "CZE",
      value: "420",
    },
    {
      nombre: "República Dominicana 1",
      text: "Dominican Republic 1",
      nom: "RÃ©publique Dominicaine",
      iso2: "DO1",
      iso3: "DOM1",
      value: "1 809",
    },
    {
      nombre: "República Dominicana 2",
      text: "Dominican Republic 2",
      nom: "RÃ©publique Dominicaine 2",
      iso2: "DO2",
      iso3: "DOM2",
      value: "1 829",
    },
    {
      nombre: "República Dominicana 3",
      text: "Dominican Republic 3",
      nom: "RÃ©publique Dominicaine 3",
      iso2: "DO3",
      iso3: "DOM3",
      value: "1 849",
    },
    {
      nombre: "Reunión",
      text: "Réunion",
      nom: "RÃ©union",
      iso2: "RE",
      iso3: "REU",
      value: "",
    },
    {
      nombre: "Ruanda",
      text: "Rwanda",
      nom: "Rwanda",
      iso2: "RW",
      iso3: "RWA",
      value: "250",
    },
    {
      nombre: "Rumanía",
      text: "Romania",
      nom: "Roumanie",
      iso2: "RO",
      iso3: "ROU",
      value: "40",
    },
    {
      nombre: "Rusia",
      text: "Russia",
      nom: "La Russie",
      iso2: "RU",
      iso3: "RUS",
      value: "7",
    },
    {
      nombre: "Sahara Occidental",
      text: "Western Sahara",
      nom: "Sahara Occidental",
      iso2: "EH",
      iso3: "ESH",
      value: "",
    },
    {
      nombre: "Samoa",
      text: "Samoa",
      nom: "Samoa",
      iso2: "WS",
      iso3: "WSM",
      value: "685",
    },
    {
      nombre: "Samoa Americana",
      text: "American Samoa",
      nom: "Les Samoa amÃ©ricaines",
      iso2: "AS",
      iso3: "ASM",
      value: "1 684",
    },
    {
      nombre: "San Bartolomé",
      text: "Saint Barth",
      nom: "Saint-BarthÃ©lemy",
      iso2: "BL",
      iso3: "BLM",
      value: "590",
    },
    {
      nombre: "San Cristóbal y Nieves",
      text: "Saint Kitts and Nevis",
      nom: "Saint Kitts et Nevis",
      iso2: "KN",
      iso3: "KNA",
      value: "1 869",
    },
    {
      nombre: "San Marino",
      text: "Saint Marino",
      nom: "San Marino",
      iso2: "SM",
      iso3: "SMR",
      value: "378",
    },
    {
      nombre: "San Martí­n (Francia)",
      text: "Saint Martin (French part)",
      nom: "Saint-Martin (partie franÃ§aise)",
      iso2: "MF",
      iso3: "MAF",
      value: "1 599",
    },
    {
      nombre: "San Pedro y Miquelón",
      text: "Saint Pierre and Miquelon",
      nom: "Saint-Pierre-et-Miquelon",
      iso2: "PM",
      iso3: "SPM",
      value: "508",
    },
    {
      nombre: "San Vicente y las Granadinas",
      text: "Saint Vincent and the Grenadines",
      nom: "Saint-Vincent et Grenadines",
      iso2: "VC",
      iso3: "VCT",
      value: "1 784",
    },
    {
      nombre: "Santa Elena, Ascensión y Tristán de Acuña",
      text: "Saint Helena, Ascension and Tristan da Cunha",
      nom: "AscensiÃ³n y Tristan de AcuÃ±a",
      iso2: "SH",
      iso3: "SHN",
      value: "290",
    },
    {
      nombre: "Santa Lucí­a",
      text: "Saint Lucia",
      nom: "Sainte-Lucie",
      iso2: "LC",
      iso3: "LCA",
      value: "1 758",
    },
    {
      nombre: "Santo Tomás y Prí­ncipe",
      text: "Sao Tome and Principe",
      nom: "Sao TomÃ© et Principe",
      iso2: "ST",
      iso3: "STP",
      value: "239",
    },
    {
      nombre: "Senegal",
      text: "Senegal",
      nom: "SÃ©nÃ©gal",
      iso2: "SN",
      iso3: "SEN",
      value: "221",
    },
    {
      nombre: "Serbia",
      text: "Serbia",
      nom: "Serbie",
      iso2: "RS",
      iso3: "SRB",
      value: "381",
    },
    {
      nombre: "Seychelles",
      text: "Seychelles",
      nom: "Les Seychelles",
      iso2: "SC",
      iso3: "SYC",
      value: "248",
    },
    {
      nombre: "Sierra Leona",
      text: "Sierra Leone",
      nom: "Sierra Leone",
      iso2: "SL",
      iso3: "SLE",
      value: "232",
    },
    {
      nombre: "Singapur",
      text: "Singapore",
      nom: "Singapour",
      iso2: "SG",
      iso3: "SGP",
      value: "65",
    },
    {
      nombre: "Siria",
      text: "Syria",
      nom: "Syrie",
      iso2: "SY",
      iso3: "SYR",
      value: "963",
    },
    {
      nombre: "Somalia",
      text: "Somalia",
      nom: "Somalie",
      iso2: "SO",
      iso3: "SOM",
      value: "252",
    },
    {
      nombre: "Sri lanka",
      text: "Sri Lanka",
      nom: "Sri Lanka",
      iso2: "LK",
      iso3: "LKA",
      value: "94",
    },
    {
      nombre: "Sudáfrica",
      text: "South Africa",
      nom: "Afrique du Sud",
      iso2: "ZA",
      iso3: "ZAF",
      value: "27",
    },
    {
      nombre: "Sudan",
      text: "Sudan",
      nom: "Soudan",
      iso2: "SD",
      iso3: "SDN",
      value: "249",
    },
    {
      nombre: "Suecia",
      text: "Sweden",
      nom: "SuÃ¨de",
      iso2: "SE",
      iso3: "SWE",
      value: "46",
    },
    {
      nombre: "Suiza",
      text: "Switzerland",
      nom: "Suisse",
      iso2: "CH",
      iso3: "CHE",
      value: "41",
    },
    {
      nombre: "Surinám",
      text: "Suritext",
      nom: "Surinam",
      iso2: "SR",
      iso3: "SUR",
      value: "597",
    },
    {
      nombre: "Svalbard y Jan Mayen",
      text: "Svalbard and Jan Mayen",
      nom: "Svalbard et Jan Mayen",
      iso2: "SJ",
      iso3: "SJM",
      value: "",
    },
    {
      nombre: "Swazilandia",
      text: "Swaziland",
      nom: "Swaziland",
      iso2: "SZ",
      iso3: "SWZ",
      value: "268",
    },
    {
      nombre: "Tadjikistán",
      text: "Tajikistan",
      nom: "Le Tadjikistan",
      iso2: "TJ",
      iso3: "TJK",
      value: "992",
    },
    {
      nombre: "Tailandia",
      text: "Thailand",
      nom: "ThaÃ¯lande",
      iso2: "TH",
      iso3: "THA",
      value: "66",
    },
    {
      nombre: "Taiwán",
      text: "Taiwan",
      nom: "Taiwan",
      iso2: "TW",
      iso3: "TWN",
      value: "886",
    },
    {
      nombre: "Tanzania",
      text: "Tanzania",
      nom: "Tanzanie",
      iso2: "TZ",
      iso3: "TZA",
      value: "255",
    },
    {
      nombre: "Territorio Británico del Océano Índico",
      text: "British Indian Ocean Territory",
      nom: "Territoire britannique de l'ocÃ©an Indien",
      iso2: "IO",
      iso3: "IOT",
      value: "",
    },
    {
      nombre: "Territorios Australes y Antárticas Franceses",
      text: "French Southern Territories",
      nom: "Terres australes franÃ§aises",
      iso2: "TF",
      iso3: "ATF",
      value: "",
    },
    {
      nombre: "Timor Oriental",
      text: "East Timor",
      nom: "Timor-Oriental",
      iso2: "TL",
      iso3: "TLS",
      value: "670",
    },
    {
      nombre: "Togo",
      text: "Togo",
      nom: "Togo",
      iso2: "TG",
      iso3: "TGO",
      value: "228",
    },
    {
      nombre: "Tokelau",
      text: "Tokelau",
      nom: "TokÃ©laou",
      iso2: "TK",
      iso3: "TKL",
      value: "690",
    },
    {
      nombre: "Tonga",
      text: "Tonga",
      nom: "Tonga",
      iso2: "TO",
      iso3: "TON",
      value: "676",
    },
    {
      nombre: "Trinidad y Tobago",
      text: "Trinidad and Tobago",
      nom: "Trinidad et Tobago",
      iso2: "TT",
      iso3: "TTO",
      value: "1 868",
    },
    {
      nombre: "Tunez",
      text: "Tunisia",
      nom: "Tunisie",
      iso2: "TN",
      iso3: "TUN",
      value: "216",
    },
    {
      nombre: "Turkmenistán",
      text: "Turkmenistan",
      nom: "Le TurkmÃ©nistan",
      iso2: "TM",
      iso3: "TKM",
      value: "993",
    },
    {
      nombre: "Turquía",
      text: "Turkey",
      nom: "Turquie",
      iso2: "TR",
      iso3: "TUR",
      value: "90",
    },
    {
      nombre: "Tuvalu",
      text: "Tuvalu",
      nom: "Tuvalu",
      iso2: "TV",
      iso3: "TUV",
      value: "688",
    },
    {
      nombre: "Ucrania",
      text: "Ukraine",
      nom: "L'Ukraine",
      iso2: "UA",
      iso3: "UKR",
      value: "380",
    },
    {
      nombre: "Uganda",
      text: "Uganda",
      nom: "Ouganda",
      iso2: "UG",
      iso3: "UGA",
      value: "256",
    },
    {
      nombre: "Uruguay",
      text: "Uruguay",
      nom: "Uruguay",
      iso2: "UY",
      iso3: "URY",
      value: "598",
    },
    {
      nombre: "Uzbekistán",
      text: "Uzbekistan",
      nom: "L'OuzbÃ©kistan",
      iso2: "UZ",
      iso3: "UZB",
      value: "998",
    },
    {
      nombre: "Vanuatu",
      text: "Vanuatu",
      nom: "Vanuatu",
      iso2: "VU",
      iso3: "VUT",
      value: "678",
    },
    {
      nombre: "Venezuela",
      text: "Venezuela",
      nom: "Venezuela",
      iso2: "VE",
      iso3: "VEN",
      value: "58",
    },
    {
      nombre: "Vietnam",
      text: "Vietnam",
      nom: "Vietnam",
      iso2: "VN",
      iso3: "VNM",
      value: "84",
    },
    {
      nombre: "Wallis y Futuna",
      text: "Wallis and Futuna",
      nom: "Wallis et Futuna",
      iso2: "WF",
      iso3: "WLF",
      value: "681",
    },
    {
      nombre: "Yemen",
      text: "Yemen",
      nom: "YÃ©men",
      iso2: "YE",
      iso3: "YEM",
      value: "967",
    },
    {
      nombre: "Yibuti",
      text: "Djibouti",
      nom: "Djibouti",
      iso2: "DJ",
      iso3: "DJI",
      value: "253",
    },
    {
      nombre: "Zambia",
      text: "Zambia",
      nom: "Zambie",
      iso2: "ZM",
      iso3: "ZMB",
      value: "260",
    },
    {
      nombre: "Zimbabue",
      text: "Zimbabwe",
      nom: "Zimbabwe",
      iso2: "ZW",
      iso3: "ZWE",
      value: "263",
    },
  ],
};
