import {
  UPDATE_PROFILE,CONFIRM_UPDATE
  } from "../actions/userActions";
  const initialState = {
    update:{},
    confirm:{}
    
  };
  export function updateProfile(state = initialState, action) {
    switch (action.type) {
      case UPDATE_PROFILE:
        return Object.assign({}, state, { update: action.payload });
      case CONFIRM_UPDATE:
        return Object.assign({}, state, { confirm: action.payload });
    
      default:
        return state;
    }
  }