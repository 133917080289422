import React, { Component } from "react";
import "./users.css";
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  Table,
  Space,
  message,
  Tag,
  Skeleton,
} from "antd";
import COLOR from "../../const/colors";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { currentToken } from "../../redux/actions/tokenActions";
import { getUsersChildren } from "../../redux/actions/usersChildrenActions";
import ModalCreateUser from "./createUser";
import ModalManageUser from "./manageUser";
const { Text } = Typography;
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      lastId: null,
      previousId: null,
      modalCreate: false,
      usersList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      status: "",
      modalManageUser: false,
      selectedUser: {
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        status: "",
      },
    };
  }
  componentDidMount() {
    this.getUsersChildrenList();
  }

  async getUsersChildrenList(fromManage) {
    try {
      let lastID = null;
      if (this.state.lastId === null || fromManage === true) {
        lastID = undefined;
      } else {
        lastID = this.state.lastId;
      }
      this.setState({ loading: true });
      await this.props.getUsersChildren(
        this.props.clientId,
        this.props.accessToken,
        lastID
      );
      let response = this.props.usersChildrenList;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        let data = response.data.data;
        this.setState({
          lastId: data.current_last_id !== null ? data.current_last_id : null,
          previousId: data.previous_id !== null ? data.previous_id : null,
        });
        this.userListFormat(data, fromManage);
      } else if (response.status === 400) {
        this.setState({ loading: false });
        if (response.data.error_details.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;
          message.error(
            this.props.t("usersChildren.error." + messageError),
            10
          );
        } else if (response.data.error_details.payload_error !== undefined) {
          let messageError = response.data.error_details.payload_error;
          message.error(
            this.props.t("usersChildren.error." + messageError),
            10
          );
        } else {
          message.error(response.data.message);
        }
      } else if (response.status === 403) {
        message.error(this.props.t("usersChildren.error.accessError"), 10);
        this.setState({ loading: false });
      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404 ||
        response.status === 422 ||
        response.status === 500
      ) {
        this.setState({ loading: false });
        if (response.data.error_details?.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;
          message.error(
            this.props.t("usersChildren.error." + messageError),
            10
          );
        } else {
          message.error(this.props.t("usersChildren.error.errorServer"), 10);
        }
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  }
  handleTableChange = (pagination) => {
    let newPagination = {};
    if (this.state.pagination.current < pagination.current) {
      newPagination = {
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total + 1,
      };
      if (pagination.current % 2 !== 0) {
        this.getUsersChildrenList();
      }
    } else {
      newPagination = {
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
      };
    }
    this.setState({ pagination: newPagination });
  };
  userListFormat(data, edit) {
    let page = {
      current: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      total: this.state.usersList.length + data.users_children.length,
    };
    this.setState({
      usersList: edit
        ? data.users_children
        : [...this.state.usersList, ...data.users_children],
      pagination: page,
      loading: false,
    });
  }
  createUser() {
    this.setState({ modalCreate: true });
  }
  closeModalCreate() {
    this.setState({ modalCreate: false });
  }
  selectedUser(record) {
    this.setState({ selectedUser: record });
    this.openManageUser();
  }
  openManageUser() {
    this.setState({ modalManageUser: true });
  }
  closeManageUser() {
    this.setState({ modalManageUser: false });
  }

  render() {
    const columnsUsers = [
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("usersChildren.name")}
          </Text>
        ),
        dataIndex: "name",
        key: "name",
        width: 20,
        render: (record, originNode) => (
          <>
            <Text
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {originNode.name + " " + originNode.last_name}
            </Text>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("usersChildren.email")}
          </Text>
        ),
        dataIndex: "email",
        key: "email",
        width: 30,
        render: (email) => (
          <>
            <Text
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {email}
            </Text>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("usersChildren.role")}
          </Text>
        ),
        dataIndex: "role",
        key: "role",
        width: 50,
        render: (role) => (
          <>
            <Text
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {this.props.t("usersChildren.rol." + role)}
            </Text>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("usersChildren.userStatus")}
          </Text>
        ),
        dataIndex: "status",
        key: "status",
        width: 30,
        render: (status) => (
          <>
            <Tag color={status === "ACTIVE" ? "#87d068" : "#cd201f"}>
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {this.props.t("usersChildren.status." + status)}
              </Text>
            </Tag>
          </>
        ),
      },
    ];

    return (
      <>
        <Row
          gutter={[16, { xs: 8, sm: 16, md: 8, lg: 8, xl: 24 }]}
          style={{ marginBottom: 10 }}
        >
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: 22, offset: 1 }}
            xl={{ span: 22, offset: 1 }}
          >
            <Card
              bordered={false}
              size="default"
              style={{
                width: "100%",
                borderRadius: "5px",
                background: COLOR.PRIMARY,
                color: COLOR.SECONDARY_TEXT,
                // border: "1px solid #000",
              }}
              bodyStyle={{ padding: "10px 0px 10px 0px" }}
            >
              <Button
                type="primary"
                size="large"
                style={{ margin: "10px 20px" }}
                onClick={() => this.createUser()}
              >
                {this.props.t("usersChildren.create")}
              </Button>

              {this.state.loading && <Skeleton active />}
              {!this.state.loading && (
                <>
                  <div
                    id="table-users"
                    className="ant-table ant-table-tbody ant-table-thead text-table-cell ant-table-cell"
                    style={{ marginTop: 10 }}
                  >
                    <Table
                      locale={{
                        emptyText: (
                          <Space direction="vertical">
                            <br />
                            <Text
                              style={{
                                color: COLOR.PRIMARY_TEXT,
                                fontSize: 16,
                                fontWeight: "bold",
                              }}
                            >
                              {this.props.t("usersChildren.noUsers")}
                            </Text>

                            <br />
                          </Space>
                        ),
                      }}
                      loading={this.state.loading}
                      columns={columnsUsers}
                      dataSource={this.state.usersList}
                      onRow={(record) => {
                        return {
                          onClick: (event) => {
                            this.selectedUser(record);
                          }, // click row
                        };
                      }}
                      rowKey="_id"
                      pagination={this.state.pagination}
                      onChange={this.handleTableChange}
                    />
                  </div>
                </>
              )}
            </Card>
          </Col>
        </Row>

        {this.state.modalCreate && (
          <ModalCreateUser
            modalCreate={this.state.modalCreate}
            createUser={this.createUser.bind(this)}
            closeModalCreate={this.closeModalCreate.bind(this)}
            getUsers={this.getUsersChildrenList.bind(this)}
          />
        )}
        {this.state.modalManageUser && (
          <ModalManageUser
            modalManageUser={this.state.modalManageUser}
            openManageUser={this.openManageUser.bind(this)}
            closeManageUser={this.closeManageUser.bind(this)}
            selectedUser={this.state.selectedUser}
            getUsers={this.getUsersChildrenList.bind(this)}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser,
    accessToken: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
    usersChildrenList: state.userChildren.resultUsers,
  };
};

const mapDispatchToProps = {
  currentToken,
  getUsersChildren,
};

const UsersTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Users)
);

export default UsersTranslated;
