import React, { Component } from "react";
import "./forgotPassword.css";
import COLOR from "../../const/colors";
import ReactCodeInput from "react-verification-code-input";
import { Link } from "react-router-dom";
import {
  Button,
  Space,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Result,
  message,
  Spin,
} from "antd";
import { withTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";
import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import {
  sendEmailRecovery,
  confirmEmail,
  setNewPassword,
} from "../../redux/actions/passwordRecoveryActions";
import { authUser,resetStore } from "../../redux/actions/userActions";
import config from "../../services/config";
import { v4 as uuidv4 } from "uuid";
const { Title, Text } = Typography;
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      email: "",
      accessToken: "",
      clientId: "",
      loading: false,
      disableResend: false,
      disableCode:true
    };
  }

  //--Functions
  async sendEmail() {
    try {
      this.setState({ loading: true });
      let body = {
        email: this.state.email,
      };
      await this.props.sendEmailRecovery(body);
      let response = this.props.recovery.send;
      if (response !== undefined) {
        if (response.success) {
          message.success(
            this.props.t("forgotPassword.messages.successEmail", {
              key: this.state.email,
            }), [15]
          );
          this.setState({ loading: false, current: this.state.current + 1 });
        } else {
         
          this.setState({ loading: false });
          if (response.status === 404) {
            message.error(
              this.props.t("forgotPassword.messages.emailInvalid")
            );
          } else if (response.status === 400) {
            message.error(
              this.props.t("forgotPassword.messages.dataError")
            );
          } else { 
            message.error(
              this.props.t("forgotPassword.messages.errorServer")
            );
          }
        }
      }
    } catch (error) {
      this.setState({ loading: false });
      message.error(
        this.props.t("forgotPassword.messages.errorServer")
      );
    }
  }
  async resendEmail() {
    try {
      this.setState({ disableResend: true });
      let body = {
        email: this.state.email,
      };
      await this.props.sendEmailRecovery(body);
      let response = this.props.recovery.send;
      if (response !== undefined) {
        if (response.success) {
          message.success(
            this.props.t("forgotPassword.messages.resend")
          );
          setTimeout(() => {
            this.setState({ disableResend: false });
          }, 5000);
         
        } else {
          this.setState({ disableResend: false });
         
          if (response.status === 404) {
            message.error(
              this.props.t("forgotPassword.messages.emailInvalid")
            );
          } else if (response.status === 400) {
            message.error(
              this.props.t("forgotPassword.messages.dataError")
            );
          } else {
            message.error(
              this.props.t("forgotPassword.messages.errorServer")
            );
          }
        }
      }
    } catch (error) {
      this.setState({ disableResend: false });
      message.error(
        this.props.t("forgotPassword.messages.errorServer")
      );
    }
  }
  async confirmEmail(value) {
    try {
      this.setState({ loading: true });
      let tokenId = uuidv4();
      let body = {
        email: this.state.email,
        otp: value.code,
      };
      await this.props.confirmEmail(tokenId, body);
     
      let response = this.props.recovery.confirm;
      if (response !== undefined) {
        if (response.success) {
          message.success(
            this.props.t("forgotPassword.messages.emailSuccess")
          );
          let tokenData = response.data.access_token;
          this.setState({
            current: this.state.current + 1,
            clientId: tokenId,
            accessToken: tokenData,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          
          if (response.status === 403) {
            message.error(
              this.props.t("forgotPassword.messages.codeExpired")
            );
          } else if (response.status === 400) {
            message.error(
              this.props.t("forgotPassword.messages.dataError")
            );
          } else if (response.status === 401) {
            message.error(
              this.props.t("forgotPassword.messages.incorrectCode")
            );
          } else {
            message.error(
              this.props.t("forgotPassword.messages.errorServer")
            );
          }
        }
      }
    } catch (error) {
      this.setState({ loading: false });
      message.error(
        this.props.t("forgotPassword.messages.errorServer")
      );
      
    
    }
  }

  async newPassword(values) {
    try {
      let tokenId = uuidv4();
      let enumData = config.ENUM;
      let dataNewPassword = values.newPassword;
      this.setState({ loading: true });
        let pass = new Buffer(dataNewPassword + enumData + tokenId);
        let newPasswordToBase64 = pass.toString("base64");
        let body = {
          password: newPasswordToBase64,
        };
        await this.props.setNewPassword(
          this.state.clientId,
          this.state.accessToken,
          body
        );
        let response = this.props.recovery.setPassword;
        if (response !== undefined) {
          if (response.success) {
            this.setState({ current: this.state.current + 1, loading: false });
          } else {
            this.setState({ loading: false });
            if (response.status === 403) {
              message.error(
                this.props.t("forgotPassword.messages.errorServer")
              );
            } else if (response.status === 400) {
              message.error(
                this.props.t("forgotPassword.messages.dataError")
              );
            } else if (response.status === 401) {
              message.error(
                this.props.t("forgotPassword.messages.codeExpired")
              );
            } else {
              message.error(
                this.props.t("forgotPassword.messages.errorServer")
              );
            }
          
          }
        } else {
          this.setState({ loading: false });
          message.error(
            this.props.t("forgotPassword.messages.errorServer")
          );
        }
     
    } catch (error) {
      this.setState({ loading: false });
      message.error(
        this.props.t("forgotPassword.messages.errorServer")
      );
    }
  }
  render() {
    let passwordMatch= this.props.t("forgotPassword.messages.passwordMatch")
    let formEmail = (
      <div>
        <Title
          level={4}
          style={{
            color: COLOR.PRIMARY,
            textAlign: "center",
            fontWeight: "bold",
            marginTop: 20,
          }}
        >
          {this.props.t("forgotPassword.title")}
        </Title>
        <br />

        <Form
          name="reset-password"
          initialValues={{ remember: true }}
          onFinish={this.sendEmail.bind(this)}
        >
          <Form.Item>
            <Text
              style={{
                color: COLOR.PRIMARY,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {this.props.t("forgotPassword.textFirst")}
            </Text>
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: this.props.t("forgotPassword.messages.input", {
                  key: this.props.t("forgotPassword.email"),
                }),
              },
              {
                type: "email",
                message: this.props.t("signin.messages.emailError"),
              },
            ]}
          >
            <Input
              size="large"
              placeholder={this.props.t("forgotPassword.email")}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </Form.Item>

          <Form.Item style={{ textAlign: "right" }}>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className="password-button"
            >
              {this.props.t("forgotPassword.buttonContinue")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
    let formCode = (
      <div>
        <Title
          level={4}
          style={{
            color: COLOR.PRIMARY,
            textAlign: "center",
            fontWeight: "bold",
            marginTop: 20,
          }}
        >
          {this.props.t("forgotPassword.code")}
        </Title>
        <br />

        <Form
          name="code-verification"
          initialValues={{ remember: true }}
          onFinish={this.confirmEmail.bind(this)}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item>
            <Text
              style={{
                color: COLOR.PRIMARY,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {this.props.t("forgotPassword.textTwo")}
            </Text>
          </Form.Item>

          <Form.Item
            name="code"
            style={{
              textAlign: "center",
              justifyContent: "center",
              display: "grid",
            }}
            rules={[
              {
                required: true,
                message: this.props.t("forgotPassword.messages.input", {
                  key: this.props.t("forgotPassword.code"),
                }),
              },
            ]}
          >
            <ReactCodeInput
              type="number"
              onComplete={()=>this.setState({disableCode:false})}
              onChange={()=> this.setState({ disableCode:true})}
              fieldWidth={isMobileOnly ? 40 : 50}
              fieldHeight={isMobileOnly ? 40 : 50}
            />
          </Form.Item>
          <Form.Item
            style={{
              textAlign: "center",
            }}
          >
            <Text
              style={{
                color: COLOR.PRIMARY,
                textAlign: "center",
                fontWeight: "bold",
                marginRight: 5,
              }}
            >
              {this.props.t("forgotPassword.noReceived")}
            </Text>
            <Button
              type="link"
              disabled={this.state.disableResend}
              style={{
                color: COLOR.GREEN_1,
                fontWeight: "bold",
                padding: 0,
              }}
              onClick={() => this.resendEmail()}
            >
              {this.props.t("forgotPassword.resend")}
            </Button>
          </Form.Item>

          <Form.Item style={{ textAlign: "right" }}>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className="password-button"
              disabled={this.state.disableCode}
            >
              {this.props.t("forgotPassword.buttonContinue")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
    let formSetPassword = (
      <div>
        <Title
          level={4}
          style={{
            color: COLOR.PRIMARY,
            textAlign: "center",
            fontWeight: "bold",
            marginTop: 20,
          }}
        >
          {this.props.t("forgotPassword.setPassword")}
        </Title>
        <br />

        <Form
          name="set-password"
          initialValues={{ remember: true }}
          onFinish={this.newPassword.bind(this)}
        >
          <Form.Item>
            <Text
              style={{
                color: COLOR.PRIMARY,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {this.props.t("forgotPassword.textThree")}
            </Text>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: this.props.t("forgotPassword.messages.input", {
                  key: this.props.t("forgotPassword.password"),
                }),
              },{
                min: 8,
                message: this.props.t(
                  "changePassword.messages.minimumCharacters"
                ),
              }
            ]}
          >
            <Input.Password
              size="large"
              placeholder={this.props.t("forgotPassword.password")}
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: this.props.t("forgotPassword.messages.input", {
                  key: this.props.t("forgotPassword.confirmPassword"),
                }),
              }, 
                {
                  min: 8,
                  message: this.props.t(
                    "changePassword.messages.minimumCharacters"
                  ),
                },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      passwordMatch
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              size="large"
              placeholder={this.props.t("forgotPassword.confirmPassword")}
            />
          </Form.Item>

          <Form.Item style={{ textAlign: "right" }}>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className="password-button"
            >
              {this.props.t("forgotPassword.buttonConfirm")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
    let result = (
      <Result
        icon={<CheckCircleOutlined style={{ color: COLOR.SECONDARY }} />}
        status="success"
        title={
          <Title
            level={5}
            style={{
              color: COLOR.PRIMARY,
              textAlign: "center",
              fontWeight: "bold",
              marginTop: 20,
            }}
          >
            {this.props.t("forgotPassword.finish")}
          </Title>
        }
        extra={[
          <Button size="large" type="primary" key="finish" onClick={()=> this.props.resetStore()}>
            <Link to="/signin">{this.props.t("forgotPassword.backLogin")}</Link>
          </Button>,
        ]}
      />
    );
    const steps = [
      {
        content: formEmail,
      },
      {
        content: formCode,
      },
      {
        content: formSetPassword,
      },
      {
        content: result,
      },
    ];
    return (
      <div>
        <Row gutter={[0, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col
            className="gutter-row"
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 16, offset: 4 }}
            md={{ span: 14, offset: 6 }}
            lg={{ span: 14, offset: 6 }}
            xl={{ span: 6, offset: 9 }}
          >
            <Row justify="center" align="middle">
              <Col span={24}>
                <Space direction="vertical" className="container-password">
                  <Spin
                    spinning={this.state.loading}
                    size="large"
                    indicator={<LoadingOutlined style={{ color: "#00ff6a" }} />}
                  >
                    {steps[this.state.current].content}
                    
                  </Spin>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    recovery: state.passwordRecovery,
    confirm: state.confirmEmail,
    password: state.setNewPassword,
    dataUser: state.authUser,
    store: state,
  };
};

const mapDispatchToProps = {
  sendEmailRecovery,
  confirmEmail,
  setNewPassword,
  authUser,
  resetStore
};

const ForgotPasswordTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);

export default ForgotPasswordTranslated;
