import React, { Component } from "react";
import "./nav.css";
import COLOR from "../../const/colors";
import logo from "../../images/logoDam.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { siderNavigate } from "../../redux/actions/navigateActions";
import { store } from "../../redux/store";
import { Layout, Button, Space, Image } from "antd";
import { withTranslation } from "react-i18next";
const { Header } = Layout;

class NavPublic extends Component {
  componentDidMount() {}
  render() {
    return (
      <div>
        <Header
          className="header-style-public"
          style={{
            background: COLOR.PRIMARY,
            padding: 0,
          }}
        >
          <Space align="start">
            <Link to="/signin">
              <Image
                preview={false}
                width={55}
                src={logo}
                onClick={() => {
                  this.props.siderNavigate("signin");
                }}
              />
            </Link>
          </Space>
          <Space align="end" className="space-align-block">
            <Space>
              {/* <Link
                to="/signin"
                style={{
                  color: COLOR.SECONDARY_TEXT,
                  padding: 0,
                  fontSize: 16,
                }}
              >
                {this.props.t("nav.contact")}
              </Link> */}
              <Link to="#">
                <Button
                  size="large"
                  style={{
                    color: COLOR.SECONDARY_TEXT,
                    borderColor: COLOR.SECONDARY,
                  }}
                  onClick={(e) => {
                    window.location = "mailto:info@damex.io";
                    e.preventDefault();
                  }}
                >
                  {this.props.t("nav.contact")}
                </Button>
              </Link>
              {/*      {store.getState().siderNavigate.result !== "openAccount" && (
                <Link to="/requestAccount">
                  <Button
                  
                    size="large"
                    style={{
                      color: COLOR.SECONDARY_TEXT,
                      borderColor: COLOR.SECONDARY,
                    }}
                    onClick={() => {
                      this.props.siderNavigate("openAccount");
                    }}
                  >
                    {this.props.t("nav.openAccount")}
                  </Button>
                </Link>
              )} */}
              {store.getState().siderNavigate.result === "openAccount" && (
                <Link to="/signin">
                  <Button
                    size="large"
                    style={{
                      color: COLOR.SECONDARY_TEXT,
                      borderColor: COLOR.SECONDARY,
                    }}
                    onClick={() => {
                      this.props.siderNavigate("signin");
                    }}
                  >
                    {this.props.t("nav.signin")}
                  </Button>
                </Link>
              )}
            </Space>
          </Space>
        </Header>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    result: state.siderNavigate,
  };
};

const mapDispatchToProps = {
  siderNavigate,
};
const Nav = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(NavPublic)
);

export default Nav;
