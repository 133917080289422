import React, { Component } from "react";
import "./ledger.css";
import {
  Row,
  Col,
  Card,
  Typography,
  DatePicker,
  Select,
  Button,
  Table,
  Space,
  message,
  Skeleton,
} from "antd";
import {
  FileExcelOutlined,
  FilePdfOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import COLOR from "../../const/colors";
import { withTranslation } from "react-i18next";
import { asset } from "../../common/assets";
import { connect } from "react-redux";
import { pdf } from "@react-pdf/renderer";
import PDFHistory from "./pdfHistory";
import { saveAs } from "file-saver";
import { CSVLink } from "react-csv";
import NumberFormat from "react-number-format";
import {
  getAllTransactions,
  getAllTransactionsByFilter,
  currentFilters,
} from "../../redux/actions/transactionsActions";
import { currentToken } from "../../redux/actions/tokenActions";
import moment from "moment";
import { generateWithdrawalPDF } from "./withdrawalPDF";
const { Text } = Typography;
const { Option } = Select;

class Ledger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: "1",
      notificationsListActivity: [],
      notificationsListGeneral: [],
      loading: false,
      dateStartFilter: "",
      dateEndFilter: "",
      listCurrencies: [],
      lastId: null,
      lastIdPDF: null,
      previousId: null,
      transactionList: [],
      transactionsToPrint: [],
      currencyFilter: null,
      typeFilter: null,
      minDateFilter: null,
      maxDateFilter: null,
      loadingPdf: false,
      loadingFilter: false,
      loadingCsv: false,
      clear: true,
      loadingClean: false,
      currencyLabel: null,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    };
  }
  componentDidMount() {
    let filters = {
      currency: null,
      type: null,
      minDate: null,
      maxDateFilter: null,
    };
    this.props.currentFilters(filters);
    this.loadCurrencies();
    this.getTransactions();
  }
  async componentDidUpdate(prevProps) {
    if (this.props.accounts.data !== prevProps.accounts.data) {
      let page = {
        current: 1,
        pageSize: 10,
        total: 0,
      };
      await this.setState({
        transactionList: [],
        transactionsToPrint: [],
        pagination: page,
        lastId: null,
      });
      this.getTransactions();
    }
  }
  loadCurrencies() {
    let dataAccounts = this.props.accounts.data.data.accounts;
    let array = [];
    for (let account of dataAccounts) {
      let objCurrency = {};
      let findCurrency = asset.assets.find(
        (element) => element.value === account.currency
      );
      objCurrency.name = findCurrency.name;
      objCurrency.value = account.currency;
      objCurrency.key = account._id;
      objCurrency.alias = account.alias !== undefined ? account.alias : null;
      array.push(objCurrency);
    }

    array.push({ name: "All", value: "ALL", key: "ALL_1" });
    this.setState({ listCurrencies: array });
  }
  async getTransactions() {
    try {
      this.setState({ loading: true });
      if (this.state.lastId === null) {
        await this.props.getAllTransactions(
          this.props.clientId,
          this.props.accessToken,
          undefined,
          "COMPLETED"
        );
      } else {
        await this.props.getAllTransactions(
          this.props.clientId,
          this.props.accessToken,
          this.state.lastId,
          "COMPLETED"
        );
      }

      let response = this.props.respTransactions;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        let info = response.data.data;
        this.setState({
          lastId: info.current_last_id !== null ? info.current_last_id : null,
          previousId: info.previous_id !== null ? info.previous_id : null,
        });
        this.formatTransactions(info.transactions);
      } else if (response.status === 403) {
        message.error(this.props.t("transactions.error.accessError"));
        this.setState({ loading: false });
      } else if (
        response.status === 400 ||
        response.status === 401 ||
        response.status === 404 ||
        response.status === 422
      ) {
        message.error(this.props.t("transactions.error.queryError"));
        this.setState({ loading: false });
      } else if (response.status === 500) {
        message.error(this.props.t("transactions.error.errorServer"));
        this.setState({ loading: false });
      } else {
        message.error(this.props.t("transactions.error.errorServer"));
        this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  }
  formatTransactions(data) {
    let array = [];
    for (let transaction of data) {
      let objTransaction = {};

      Object.entries(transaction).forEach(([key, value]) => {
        objTransaction[key] = value;
        if (key === "account") {
          objTransaction.account = value;
          objTransaction.currency = value.currency;
          objTransaction.type_currency = value.type;
        }

        if (key === "created_date") {
          objTransaction.date = this.formatDateView(value);
        }

        if (key === "balance_state") {
          objTransaction.balance = value;
        }
        if (key === "_id") {
          objTransaction.id_transaction = value;
        }
        if (key === "destination") {
          let objDestination = {};
          Object.entries(value).forEach(([innerKey, innerValue]) => {
            objDestination[innerKey] = innerValue;
          });
          objTransaction.destination = objDestination;
          objTransaction.beneficiary =
            value.description !== undefined ? value.description : "";
        }
        if (key === "source") {
          let objSource = {};
          Object.entries(value).forEach(([innerKey, innerValue]) => {
            objSource[innerKey] = innerValue;
          });
          objTransaction.source = objSource;
        }
      });

      if (
        transaction.type === "WITHDRAWAL" ||
        transaction.type === "TRANSFER_OUT" ||
        transaction.type === "EXCHANGE_OUT"
      ) {
        objTransaction.amountTotal =
          parseFloat(transaction.amount) + parseFloat(transaction.fees);
        objTransaction.amount = transaction.amount;
      } else {
        objTransaction.amount = objTransaction.total_amount;
        objTransaction.amountTotal = transaction.amount;
      }
      if (objTransaction.type === "WITHDRAWAL") {
        if (objTransaction.destination?.type === "EXTERNAL_CIRCLE") {
          objTransaction.amount = objTransaction.total_amount;
        }
      }
      array.push(objTransaction);
    }
    let page = {
      current: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      total: this.state.transactionList.length + array.length,
    };

    this.setState({
      transactionList: [...this.state.transactionList, ...array],
      transactionsToPrint: [...this.state.transactionList, ...array],
      pagination: page,
      loading: false,
      loadingFilter: false,
      currencyFilter: null,
      currencyLabel: null,
      typeFilter: null,
      minDateFilter: null,
      maxDateFilter: null,
      dateStartFilter: "",
      dateEndFilter: "",
      loadingClean: false,
    });
  }

  async getTransactionsFiltered() {
    try {
      this.setState({ loadingFilter: true });
      let currency =
        this.state.currencyFilter === "ALL" ? null : this.state.currencyFilter;
      let type = this.state.typeFilter === "ALL" ? null : this.state.typeFilter;

      await this.props.getAllTransactionsByFilter(
        this.props.clientId,
        this.props.accessToken,
        currency,
        type,
        this.state.minDateFilter,
        this.state.maxDateFilter,
        this.state.lastId
      );

      let response = this.props.transactionsByCurrency;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        let info = response.data.data;
        this.setState({
          lastId: info.current_last_id !== null ? info.current_last_id : null,
          previousId: info.previous_id !== null ? info.previous_id : null,
        });
        let filters = {
          currency: this.state.currencyFilter,
          type: this.state.typeFilter,
          minDate: this.state.minDateFilter,
          maxDateFilter: this.state.maxDateFilter,
        };
        this.props.currentFilters(filters);
        this.formatTransactions(info.transactions);
      } else if (response.status === 400) {
        this.setState({ loading: false });
        if (response.data.error_details.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;

          message.error(this.props.t("transactions.error." + messageError), 10);
        } else if (response.data.error_details.payload_error !== undefined) {
          let messageError = response.data.error_details.payload_error;
          message.error(this.props.t("transactions.error." + messageError), 10);
        } else {
          message.error(response.data.message);
        }
      } else if (response.status === 403) {
        message.error(this.props.t("transactions.error.accessError"));
        this.setState({ loading: false });
      } else if (
        response.status === 401 ||
        response.status === 404 ||
        response.status === 422
      ) {
        message.error(this.props.t("transactions.error.queryError"));
        this.setState({ loading: false, loadingFilter: false });
      } else if (response.status === 500) {
        message.error(this.props.t("transactions.error.errorServer"));
        this.setState({ loading: false, loadingFilter: false });
      } else {
        message.error(this.props.t("transactions.error.errorServer"));
        this.setState({ loading: false, loadingFilter: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false, loadingFilter: false });
    }
  }
  formatDateView(dateTransaction) {
    let dateMoment = moment(dateTransaction).format("YYYY/MM/DD HH:mm:ss");
    let date = new Date(dateMoment + " UTC");
    let dateCreated =
      moment(date).format("DD/MM/YYYY") + " " + this.formatAmPm(date);
    return dateCreated;
  }

  formatAmPm(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  disabledStartDate(current) {
    return current && current > moment().endOf("day");
  }
  disabledEndDate(current) {
    return current && current > moment().endOf("day");
  }
  disabledDateTime() {
    return {
      disabledHours: () => this.range(0, 24).splice(4, 20),
      disabledMinutes: () => this.range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }
  range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }
  getAsset(currency) {
    let findCurrency = asset.assets.find(
      (element) => element.value === currency
    );
    return findCurrency;
  }
  handleDateStart = (date) => {
    let minDate = date.format("YYYY-MM-DD");
    this.setState({
      dateStartFilter: date,
      minDateFilter: minDate,
    });
    if (this.state.maxDateFilter !== "" && this.state.maxDateFilter !== null) {
      if (date !== null) {
        if (minDate > this.state.maxDateFilter) {
          message.error(this.props.t("transactions.error.dateError"));
          this.setState({ dateStartFilter: "", minDateFilter: null });
        }
      }
    }
  };
  handleDateEnd = (date) => {
    let maxDate = date.format("YYYY-MM-DD");
    this.setState({
      dateEndFilter: date,
      maxDateFilter: maxDate,
    });
    if (this.state.minDateFilter !== "" && this.state.minDateFilter !== null) {
      if (date !== null) {
        if (this.state.minDateFilter > maxDate) {
          message.error(this.props.t("transactions.error.dateError"));
          this.setState({ dateEndFilter: "", maxDateFilter: null });
        }
      }
    }
  };
  handleTableChange = (pagination) => {
    let newPagination = {};
    if (this.state.pagination.current < pagination.current) {
      let filterSaved = this.props.filters;
      this.setState({
        currencyFilter: filterSaved.currency,
        typeFilter: filterSaved.type,
        minDateFilter: filterSaved.minDate,
        maxDateFilter: filterSaved.maxDateFilter,
      });
      newPagination = {
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total + 1,
      };
      if (pagination.current % 2 !== 0) {
        if (
          this.state.currencyFilter === null &&
          this.state.typeFilter === null &&
          this.state.minDateFilter === null &&
          this.state.maxDateFilter === null
        ) {
          this.getTransactions();
        } else {
          this.getTransactionsFiltered();
        }
      }
    } else {
      newPagination = {
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
      };
    }
    this.setState({ pagination: newPagination });
  };
  handleCurrency(value, option) {
    this.setState({
      currencyLabel: option.children,
      currencyFilter: option.key,
      lastId: null,
    });
  }
  handleTypeTransaction(value) {
    this.setState({ typeFilter: value });
  }
  async filterData() {
    await this.setState({
      transactionList: [],
      lastId: null,
      lastIdPDF: null,
      clear: false,
    });
    let filters = {
      currency: null,
      type: null,
      minDate: null,
      maxDateFilter: null,
    };
    this.props.currentFilters(filters);
    await this.getTransactionsFiltered();
  }
  async pdfHistoryUpload(type) {
    let typeFile = type;
    if (type === "PDF") {
      this.setState({ loadingPdf: true, transactionsToPrint: [] });
    } else {
      this.setState({ loadingCsv: true, transactionsToPrint: [] });
    }
    let emptyData = false;
    let filterSaved = this.props.filters;
    let currencyFilter = filterSaved.currency;
    let typeFilter = filterSaved.type;
    let minDateFilter = filterSaved.minDate;
    let maxDateFilter = filterSaved.maxDateFilter;
    let multiple = this.hasMultipleAccounts();
    do {
      try {
        let currency = currencyFilter === "ALL" ? null : currencyFilter;
        let type = typeFilter === "ALL" ? null : typeFilter;
        await this.props.getAllTransactionsByFilter(
          this.props.clientId,
          this.props.accessToken,
          currency,
          type,
          minDateFilter,
          maxDateFilter,
          this.state.lastIdPDF
        );

        let response = this.props.transactionsByCurrency;
        if (response.headers !== undefined) {
          let newAccessToken = response.headers["x-new-access-token"];
          await this.props.currentToken(newAccessToken);
        }
        if (response.status === 200) {
          let info = response.data.data;
          this.setState({
            lastIdPDF:
              info.current_last_id !== null ? info.current_last_id : null,
          });
          let array = [];
          for (let transaction of info.transactions) {
            let objTransaction = {};
            Object.entries(transaction).forEach(([key, value]) => {
              objTransaction[key] = value;
              if (key === "_id") {
                objTransaction.id_transaction = value;
              }
              if (key === "account") {
                objTransaction.account = value;
                objTransaction.currency = value.currency;
                objTransaction.type_currency = value.type;
                if (multiple) {
                  objTransaction.accountAlias =
                    value.alias !== undefined ? value.alias : "";
                }
              }
              if (key === "created_date") {
                objTransaction.date = this.formatDateView(value);
              }

              if (key === "balance_state") {
                objTransaction.balance = value;
              }
              if (key === "type") {
                if (typeFile === "CSV") {
                  objTransaction.type = this.props.t(
                    "transactions.type." + value
                  );
                  objTransaction.reference =
                    value === "WITHDRAWAL" ||
                    value === "TRANSFER_OUT" ||
                    value === "EXCHANGE_OUT"
                      ? objTransaction.reference
                      : "";
                  if (value.includes("REBALANCE")) {
                    objTransaction.reference =
                      "Rebalance " + objTransaction.currency;
                  }
                } else {
                  objTransaction.type = value;
                }
              }
              if (key === "destination") {
                let objDestination = {};
                Object.entries(value).forEach(([innerKey, innerValue]) => {
                  if (typeFile === "CSV") {
                    if (transaction.type.includes("REBALANCE")) {
                      if (innerKey === "alias") {
                        objTransaction.source = innerValue;
                      }
                    }
                  } else {
                    objDestination[innerKey] = innerValue;
                  }
                });
                objTransaction.beneficiary =
                  value.description !== undefined ? value.description : "";
                objTransaction.destination = objDestination;
              }
              if (typeFile === "PDF") {
                if (key === "source") {
                  let objSource = {};
                  Object.entries(value).forEach(([innerKey, innerValue]) => {
                    objSource[innerKey] = innerValue;
                  });
                  objTransaction.source = objSource;
                }
              }
              if (typeFile === "CSV") {
                if (key === "source" || key === "destination") {
                  if (value.type) {
                    if (value.type === "EXTERNAL_CIRCLE") {
                      objTransaction.fees = "";
                    }
                  }

                  Object.entries(value).forEach(([keySource, valueSource]) => {
                    if (transaction.type.includes("REBALANCE")) {
                      if (keySource === "alias") {
                        objTransaction.source = valueSource;
                      }
                    }

                    if (value.type !== undefined) {
                      if (!value.type.includes("INTERNAL")) {
                        if (keySource === "account_number") {
                          objTransaction.source =
                            "Account number: " + valueSource;
                        }
                        if (keySource === "sort_code") {
                          objTransaction.source =
                            objTransaction.source +
                            " / Sort code: " +
                            valueSource;
                        }
                        if (keySource === "iban") {
                          objTransaction.source = "IBAN: " + valueSource;
                        }
                        if (keySource === "bic") {
                          objTransaction.source =
                            objTransaction.source + " / BIC: " + valueSource;
                        }

                        if (keySource === "address") {
                          if (valueSource !== "") {
                            objTransaction.source = "Address: " + valueSource;
                          } else {
                            objTransaction.source = value.description;
                          }
                        }
                      }

                      if (
                        transaction.type === "DEPOSIT" ||
                        transaction.type === "TRANSFER_IN"
                      ) {
                        if (value.type.includes("INTERNAL")) {
                          if (keySource === "description") {
                            objTransaction.source = valueSource;
                          }
                        }
                      }
                      if (transaction.type === "TRANSFER_OUT") {
                        objTransaction.source = "";
                      }
                    }
                    if (transaction.type.includes("EXCHANGE")) {
                      objTransaction.source = objTransaction.reference;
                    }
                  });
                }
              }
            });

            if (
              transaction.type === "WITHDRAWAL" ||
              transaction.type === "TRANSFER_OUT" ||
              transaction.type === "EXCHANGE_OUT"
            ) {
              objTransaction.amountTotal =
                parseFloat(transaction.amount) + parseFloat(transaction.fees);
              objTransaction.amount = transaction.amount;
            } else {
              objTransaction.amount = objTransaction.total_amount;
              objTransaction.amountTotal = transaction.amount;
            }
            if (objTransaction.type === "WITHDRAWAL") {
              if (objTransaction.destination?.type === "EXTERNAL_CIRCLE") {
                objTransaction.amount = objTransaction.total_amount;
              }
            }
            if (objTransaction.destination?.type) {
              if (objTransaction.destination?.type === "EXTERNAL_CIRCLE") {
                objTransaction.fees = "";
              }
            }
            array.push(objTransaction);
          }

          if (info.transactions.length > 0) {
            emptyData = false;
          } else {
            if (typeFile === "CSV") {
              this.csvLink.link.click();
              this.setState({ loadingCsv: false });
            }
            emptyData = true;
          }

          this.setState({
            transactionsToPrint: [...this.state.transactionsToPrint, ...array],
          });
        } else if (response.status === 400) {
          this.setState({ loadingPdf: false, loadingCsv: false });
          if (response.data.error_details.validation_error !== undefined) {
            let messageError = response.data.error_details.validation_error;

            message.error(
              this.props.t("transactions.error." + messageError),
              10
            );
          } else if (response.data.error_details.payload_error !== undefined) {
            let messageError = response.data.error_details.payload_error;
            message.error(
              this.props.t("transactions.error." + messageError),
              10
            );
          } else {
            message.error(response.data.message);
          }
        } else if (
          response.status === 401 ||
          response.status === 403 ||
          response.status === 404 ||
          response.status === 422
        ) {
          emptyData = true;
          message.error(this.props.t("transactions.error.queryError"));
          this.setState({ loadingPdf: false, loadingCsv: false });
        } else if (response.status === 500) {
          emptyData = true;
          message.error(this.props.t("transactions.error.errorServer"));
          this.setState({ loadingPdf: false, loadingCsv: false });
        } else {
          emptyData = true;
          message.error(this.props.t("transactions.error.errorServer"));
          this.setState({ loadingPdf: false, loadingCsv: false });
        }
      } catch (error) {
        console.log(error);
        emptyData = true;
        this.setState({ loadingPdf: false, loadingCsv: false });
      }
    } while (!emptyData);
  }
  generatePdfDocument = async () => {
    try {
      let username = this.props.dataUser.data.data.user.email;
      let dateDownload = new Date()
        .toLocaleString()
        .replaceAll("/", "-")
        .replace(" ", "T");
      await this.pdfHistoryUpload("PDF");
      let filterSaved = this.props.filters;
      let currencyFilter = filterSaved.currency;
      let typeFilter = filterSaved.type;
      let minDateFilter = filterSaved.minDate;
      let maxDateFilter = filterSaved.maxDateFilter;
      const blob = await pdf(
        <PDFHistory
          key="history-transactions"
          title={dateDownload}
          arrayHistory={this.state.transactionsToPrint}
          dateStart={minDateFilter}
          dateEnd={maxDateFilter}
          currency={currencyFilter}
          type={typeFilter}
        />
      ).toBlob();
      saveAs(blob, username + "_" + dateDownload + ".pdf");
      this.setState({ loadingPdf: false });
    } catch (error) {
      console.log(error);
      this.setState({ loadingPdf: false });
    }
  };
  async generateCsvDocument() {
    this.setState({ loadingCsv: true });
    await this.pdfHistoryUpload("CSV");
  }
  async cleanTable() {
    await this.setState({
      transactionList: [],
      lastId: null,
      lastIdPDF: null,
      loadingClean: true,
      clear: true,
    });
    let filters = {
      currency: "",
      type: "",
      minDate: this.state.minDateFilter,
      maxDateFilter: this.state.maxDateFilter,
    };
    this.props.currentFilters(filters);
    await this.getTransactions();
  }
  hasMultipleAccounts() {
    let userAccounts =
      this.props.accounts.data?.data.native_balances.currencies;
    let findMultiple = userAccounts.find(
      (element) => element.accounts !== undefined && element.accounts.length > 1
    );
    if (findMultiple !== undefined) {
      return true;
    } else {
      return false;
    }
  }
  openPDFPreview = async (transaction) => {
    const { t, dataUser, accounts } = this.props;
    try {
      const user = dataUser.data?.data?.user;
      const sourceAccount = accounts.data.data.accounts.find(
        (acc) => acc._id === transaction.account._id
      );

      const transactionWithAccount = {
        ...transaction,
        sourceAccount: sourceAccount?.account_data,
      };

      const pdfUrl = await generateWithdrawalPDF(transactionWithAccount, user);
      window.open(pdfUrl, "_blank", "noopener,noreferrer");
    } catch (error) {
      console.error("Error generating PDF:", error);
      message.error(t("ledger.pdfError"));
    }
  };
  render() {
    let dataUser = this.props.dataUser.data?.data?.user;
    let username = dataUser.email;
    let feeType = dataUser?.trading_fee_type;
    let multiple = this.hasMultipleAccounts();
    let dateDownload = new Date()
      .toLocaleString()
      .replaceAll("/", "-")
      .replace(" ", "T");
    const columnsHistory = [
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            ID
          </Text>
        ),
        dataIndex: "id_transaction",
        key: "id_transaction",
        render: (id_transaction) => (
          <div>
            <Text
              className="id-column"
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {id_transaction}
            </Text>
          </div>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("transactions.history.date")}
          </Text>
        ),
        dataIndex: "date",
        key: "date",
        width: 125,
        render: (date) => (
          <>
            <div style={{ flexDirection: "column", display: "flex" }}>
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 16,
                  fontWeight: 400,
                }}
              >
                {date.split(" ")[0]}
              </Text>
              <Text
                style={{
                  color: COLOR.SECONDARY_TEXT,
                  fontSize: 16,
                  fontWeight: 400,
                }}
              >
                {date.split(" ")[1] + " " + date.split(" ")[2]}
              </Text>
            </div>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("transactions.history.type")}
          </Text>
        ),
        dataIndex: "type",
        key: "type",
        render: (type) => (
          <>
            <Text
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {this.props.t("transactions.type." + type)}
            </Text>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("transactions.history.currency")}
          </Text>
        ),
        dataIndex: "currency",
        key: "currency",
        render: (currency) => (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <Text
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {this.getAsset(currency).name}
            </Text>
            <Text
              style={{
                color: COLOR.SECONDARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {currency}
            </Text>
          </div>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("transactions.history.account")}
          </Text>
        ),
        className: multiple ? "" : "hidden-column",
        dataIndex: "account",
        key: "account",
        render: (record, originNode) => (
          <>
            <Text
              className="id-column"
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {originNode.account.alias !== undefined
                ? originNode.account.alias
                : ""}
            </Text>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("transactions.history.source")}
          </Text>
        ),
        dataIndex: "source",
        key: "source",
        render: (record, originNode) => (
          <>
            <Text
              className="id-column"
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {(originNode.type === "DEPOSIT" ||
                originNode.type === "TRANSFER_IN") && (
                <>
                  {originNode.source.type.includes("INTERNAL") && (
                    <>{originNode.source.description}</>
                  )}
                  {originNode.source.type.includes("EXTERNAL") && (
                    <>
                      {originNode.type_currency === "CRYPTO" &&
                        originNode.source.address !== undefined &&
                        originNode.source.address}
                      {originNode.type_currency === "FIAT" && (
                        <>
                          {originNode.currency === "GBP" && (
                            <>
                              Account Holder: {originNode.source.account_holder}
                              <br />
                              Account number: {originNode.source.account_number}
                              <br />
                              Sort code: {originNode.source.sort_code}
                            </>
                          )}
                          {originNode.currency === "EUR" && (
                            <>
                              Account Holder: {originNode.source.account_holder}
                              <br />
                              IBAN: {originNode.source.iban}
                              <br />
                              {originNode.source.bic !== undefined
                                ? "BIC: " + originNode.source.bic
                                : ""}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {originNode.type === "EXCHANGE_IN" && originNode.reference}
              {originNode.type === "WITHDRAWAL" && (
                <>
                  {originNode.destination.type.includes("EXTERNAL_CIRCLE") && (
                    <>
                      Account number: {originNode.destination.account_number}
                      <br />
                      Bank name: {originNode.destination.bank_name}
                      <br />
                      Billing name: {originNode.destination.billing_name}
                    </>
                  )}
                  {originNode.destination.type.includes("EXTERNAL") && (
                    <>
                      {originNode.type_currency === "CRYPTO" &&
                      originNode.destination.address !== undefined &&
                      originNode.destination.address === ""
                        ? originNode.destination.description
                        : originNode.destination.address}
                      {originNode.type_currency === "FIAT" && (
                        <>
                          {originNode.currency === "GBP" && (
                            <>
                              {originNode.destination.account_holder !==
                              undefined
                                ? "Account Holder: " +
                                  originNode.destination.account_holder
                                : ""}
                              <br />
                              Account number:{" "}
                              {originNode.destination.account_number}
                              <br />
                              Sort code: {originNode.destination.sort_code}
                            </>
                          )}
                          {originNode.currency === "EUR" && (
                            <>
                              {originNode.destination.account_holder !==
                              undefined
                                ? "Account Holder: " +
                                  originNode.destination.account_holder
                                : ""}
                              <br />
                              IBAN: {originNode.destination.iban}
                              <br />
                              BIC: {originNode.destination.bic}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {originNode.type === "REBALANCE_IN" && originNode.source.alias}
              {originNode.type === "REBALANCE_OUT" &&
                originNode.destination.alias}
            </Text>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("transactions.history.reference")}
          </Text>
        ),
        dataIndex: "reference",
        key: "reference",
        render: (record, originNode) => (
          <>
            <Text
              className="id-column"
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {(originNode.type === "WITHDRAWAL" ||
                originNode.type === "TRANSFER_OUT" ||
                originNode.type === "EXCHANGE_OUT") &&
                originNode.reference}
              {(originNode.type === "REBALANCE_OUT" ||
                originNode.type === "REBALANCE_IN") &&
                "Rebalance " + originNode.currency}
            </Text>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("transactions.history.beneficiary")}
          </Text>
        ),
        dataIndex: "beneficiary",
        key: "beneficiary",
        render: (record, originNode) => (
          <>
            <Text
              className="id-column"
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {(originNode.type === "WITHDRAWAL" ||
                originNode.type === "TRANSFER_OUT") &&
                originNode.beneficiary}
            </Text>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("transactions.history.price")}
          </Text>
        ),
        dataIndex: "cost",
        key: "cost",
        align: "right",
        width: 100,
        render: (record, originNode) => (
          <>
            {originNode.type === "EXCHANGE_OUT" && (
              <div style={{ flexDirection: "column", display: "flex" }}>
                <NumberFormat
                  allowNegative={true}
                  thousandSeparator={true}
                  displayType="text"
                  style={{
                    color: COLOR.PRIMARY_TEXT,
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                  decimalScale={originNode.account.type === "FIAT" ? 8 : 2}
                  value={originNode.exchange_rate}
                />
                <Text
                  style={{
                    color: COLOR.SECONDARY_TEXT,
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  {originNode.reference.split(" ")[1]
                    ? originNode.reference.split(" ")[1].split("-")[1]
                    : originNode.reference}
                </Text>
              </div>
            )}
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("transactions.history.amount")}
          </Text>
        ),
        dataIndex: "amount",
        key: "amount",
        width: 100,
        align: "right",
        render: (record, originNode) => (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <NumberFormat
              allowNegative={true}
              prefix={
                originNode.type === "WITHDRAWAL" ||
                originNode.type === "TRANSFER_OUT" ||
                originNode.type === "EXCHANGE_OUT" ||
                originNode.type === "REBALANCE_OUT"
                  ? "- "
                  : ""
              }
              thousandSeparator={true}
              displayType="text"
              style={{
                color:
                  originNode.total_amount > 0 ? COLOR.PRIMARY_TEXT : "#CF202F",
                fontSize: 16,
                fontWeight: 400,
              }}
              decimalScale={originNode.type_currency === "CRYPTO" ? 8 : 2}
              value={
                originNode.type === "WITHDRAWAL" ||
                originNode.type === "TRANSFER_OUT" ||
                originNode.type === "EXCHANGE_OUT" ||
                originNode.type === "REBALANCE_IN"
                  ? originNode.amount
                  : originNode.total_amount
              }
            />
            <Text
              style={{
                color: COLOR.SECONDARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {this.getAsset(originNode.currency).value}
            </Text>
          </div>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("transactions.history.fee")}
          </Text>
        ),
        dataIndex: "fees",
        key: "fees",
        width: 100,
        align: "right",
        render: (record, originNode) => (
          <div style={{ flexDirection: "column", display: "flex" }}>
            {originNode.type === "EXCHANGE_IN" && feeType === "TIERS" && (
              <>
                <NumberFormat
                  thousandSeparator={true}
                  displayType="text"
                  style={{
                    color: COLOR.PRIMARY_TEXT,
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                  decimalScale={originNode.type_currency === "CRYPTO" ? 8 : 2}
                  value={originNode.fees}
                />
                <Text
                  style={{
                    color: COLOR.SECONDARY_TEXT,
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  {this.getAsset(originNode.currency).value}
                </Text>
              </>
            )}
            {originNode.type !== "EXCHANGE_IN" &&
              originNode.type !== "EXCHANGE_OUT" &&
              originNode.destination?.type !== "EXTERNAL_CIRCLE" &&
              originNode.source?.type !== "EXTERNAL_CIRCLE" && (
                <>
                  <NumberFormat
                    thousandSeparator={true}
                    displayType="text"
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                    decimalScale={originNode.type_currency === "CRYPTO" ? 8 : 2}
                    value={originNode.fees}
                  />
                  <Text
                    style={{
                      color: COLOR.SECONDARY_TEXT,
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    {this.getAsset(originNode.currency).value}
                  </Text>
                </>
              )}
          </div>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("transactions.history.amountFee")}
          </Text>
        ),
        dataIndex: "total_amount",
        key: "total_amount",
        width: 135,
        align: "right",
        render: (record, originNode) => (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <NumberFormat
              thousandSeparator={true}
              displayType="text"
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
              decimalScale={originNode.type_currency === "CRYPTO" ? 8 : 2}
              value={originNode.amountTotal}
            />
            <Text
              style={{
                color: COLOR.SECONDARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {this.getAsset(originNode.currency).value}
            </Text>
          </div>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            Balance
          </Text>
        ),
        dataIndex: "balance",
        key: "balance",
        width: 100,
        align: "right",
        render: (record, originNode) => (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <NumberFormat
              thousandSeparator={true}
              displayType="text"
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
              decimalScale={originNode.type_currency === "CRYPTO" ? 8 : 2}
              value={originNode.balance}
            />
            <Text
              style={{
                color: COLOR.SECONDARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {this.getAsset(originNode.currency).value}
            </Text>
          </div>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {/* Receipt */}
            {this.props.t("orders.receipt")}
          </Text>
        ),
        key: "receipt",
        align: "center",
        render: (record) =>
          record.type === "WITHDRAWAL" && (
            <FileTextOutlined
              onClick={(e) => {
                e.stopPropagation();
                this.openPDFPreview(record);
              }}
              style={{
                color: COLOR.GREEN,
                fontSize: "18px",
                cursor: "pointer",
              }}
            />
          ),
      },
    ];
    const headers = [
      { label: "ID", key: "id_transaction" },
      { label: this.props.t("transactions.history.date"), key: "date" },
      { label: this.props.t("transactions.history.type"), key: "type" },
      { label: this.props.t("transactions.history.currency"), key: "currency" },
      {
        label: this.props.t("transactions.history.account"),
        key: "accountAlias",
      },
      {
        label: this.props.t("transactions.history.source"),
        key: "source",
      },
      {
        label: this.props.t("transactions.history.reference"),
        key: "reference",
      },
      {
        label: this.props.t("transactions.history.beneficiary"),
        key: "beneficiary",
      },
      {
        label: this.props.t("transactions.history.price"),
        key: "exchange_rate",
      },
      { label: this.props.t("transactions.history.amount"), key: "amount" },
      { label: this.props.t("transactions.history.fee"), key: "fees" },
      {
        label: this.props.t("transactions.history.amountFee"),
        key: "amountTotal",
      },
      { label: "Balance", key: "balance" },
    ];
    const transactions = [
      {
        text: this.props.t("transactions.type.DEPOSIT"),
        value: "DEPOSIT",
        key: "DEPOSIT",
      },
      {
        text: this.props.t("transactions.type.WITHDRAWAL"),
        value: "WITHDRAWAL",
        key: "WITHDRAWAL",
      },
      {
        text: this.props.t("transactions.type.EXCHANGE_IN"),
        value: "EXCHANGE_IN",
        key: "EXCHANGE_IN",
      },
      {
        text: this.props.t("transactions.type.EXCHANGE_OUT"),
        value: "EXCHANGE_OUT",
        key: "EXCHANGE_OUT",
      },
      {
        text: this.props.t("transactions.type.TRANSFER_IN"),
        value: "TRANSFER_IN",
        key: "TRANSFER_IN",
      },
      {
        text: this.props.t("transactions.type.TRANSFER_OUT"),
        value: "TRANSFER_OUT",
        key: "TRANSFER_OUT",
      },
      {
        text: this.props.t("transactions.type.REBALANCE_IN"),
        value: "REBALANCE_IN",
        key: "REBALANCE_IN",
      },
      {
        text: this.props.t("transactions.type.REBALANCE_OUT"),
        value: "REBALANCE_OUT",
        key: "REBALANCE_OUT",
      },
      { text: this.props.t("transactions.type.ALL"), value: "ALL", key: "ALL" },
    ];
    return (
      <>
        <Card
          title={
            <Row
              gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
                xxl={{ span: 12, offset: 0 }}
              >
                <Text
                  style={{
                    fontSize: 18,
                    color: COLOR.PRIMARY_TEXT,
                    fontWeight: "bold",
                  }}
                >
                  {this.props.t("transactions.history.title")}
                </Text>
              </Col>
              <Col
                xs={{ span: 24, offset: 1 }}
                sm={{ span: 24, offset: 1 }}
                md={{ span: 24, offset: 1 }}
                lg={{ span: 11, offset: 1 }}
                xl={{ span: 11, offset: 1 }}
                xxl={{ span: 11, offset: 1 }}
                style={{
                  justifyContent: "right",
                  display: "flex",
                  textAlign: "right",
                }}
              >
                <Space direction="horizontal">
                  <Button
                    type="primary"
                    size="large"
                    loading={this.state.loadingCsv}
                    onClick={() => this.generateCsvDocument()}
                  >
                    {this.props.t("transactions.history.csv")}
                    <FileExcelOutlined style={{ fontSize: 20 }} />
                  </Button>
                  <CSVLink
                    data={this.state.transactionsToPrint}
                    headers={headers}
                    filename={username + "_" + dateDownload + ".csv"}
                    ref={(r) => (this.csvLink = r)}
                  ></CSVLink>

                  <Button
                    loading={this.state.loadingPdf}
                    type="primary"
                    size="large"
                    onClick={() => this.generatePdfDocument()}
                  >
                    {this.props.t("transactions.history.pdf")}
                    <FilePdfOutlined style={{ fontSize: 20 }} />
                  </Button>
                </Space>
              </Col>
            </Row>
          }
          bordered={false}
          size="default"
          style={{
            width: "100%",
            borderRadius: "5px",
            background: COLOR.PRIMARY,
            color: COLOR.SECONDARY_TEXT,
            border: "1px solid #000",
          }}
          bodyStyle={{ padding: "10px 0px 10px 0px" }}
        >
          <Row
            gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 5, offset: 0 }}
              xxl={{ span: 5, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                id="select-trade"
                className="ant-select-selector ant-select"
                style={{ width: "100%" }}
              >
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  placeholder={this.props.t(
                    "transactions.history.allCurrencies"
                  )}
                  value={this.state.currencyLabel}
                  onChange={(value, option) =>
                    this.handleCurrency(value, option)
                  }
                  optionLabelProp="option"
                >
                  {this.state.listCurrencies.map((item, i) => {
                    return (
                      <Option key={item.key} value={item.value}>
                        {multiple
                          ? item.alias !== null && item.value !== "ALL"
                            ? item.name + ": " + item.alias
                            : item.name
                          : item.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 5, offset: 0 }}
              xxl={{ span: 5, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                id="select-trade"
                className="ant-select-selector ant-select"
                style={{ width: "100%" }}
              >
                <Select
                  style={{ width: "100%" }}
                  size="large"
                  placeholder={this.props.t(
                    "transactions.history.allTransactions"
                  )}
                  value={this.state.typeFilter}
                  onChange={(value) => this.handleTypeTransaction(value)}
                >
                  {transactions.map((item, i) => {
                    return (
                      <Option key={item.key} value={item.value}>
                        {item.text}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 5, offset: 0 }}
              xxl={{ span: 5, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <DatePicker
                placeholder={this.props.t("transactions.history.startDate")}
                size="large"
                format="YYYY-MM-DD"
                style={{ width: "100%" }}
                autoFocus={true}
                value={this.state.dateStartFilter}
                onChange={(date) => this.handleDateStart(date)}
                showTime={false}
                showToday={false}
              />
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              xl={{ span: 5, offset: 0 }}
              xxl={{ span: 5, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <DatePicker
                placeholder={this.props.t("transactions.history.endDate")}
                size="large"
                format="YYYY-MM-DD"
                style={{ width: "100%" }}
                autoFocus={true}
                value={this.state.dateEndFilter}
                onChange={(date) => this.handleDateEnd(date)}
                showTime={false}
              />
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 2, offset: 0 }}
              xl={{ span: 2, offset: 0 }}
              xxl={{ span: 2, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Button
                type="primary"
                size="large"
                style={{ width: "100%" }}
                loading={this.state.loadingFilter}
                disabled={
                  this.state.currencyFilter === null &&
                  this.state.typeFilter === null &&
                  (this.state.minDateFilter === null ||
                    this.state.maxDateFilter === null)
                }
                onClick={() => this.filterData()}
              >
                {this.props.t("transactions.history.filter")}
              </Button>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 2, offset: 0 }}
              xl={{ span: 2, offset: 0 }}
              xxl={{ span: 2, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Button
                className="disabledStyle"
                style={{ borderColor: COLOR.SECONDARY, width: "100%" }}
                size="large"
                loading={this.state.loadingClean}
                disabled={this.state.clear || this.state.loadingFilter}
                onClick={() => this.cleanTable()}
              >
                {this.props.t("transactions.history.clear")}
              </Button>
            </Col>
          </Row>
          {(this.state.loading || this.state.loadingFilter) && (
            <Skeleton active />
          )}
          {!this.state.loading && !this.state.loadingFilter && (
            <>
              <div
                id="table-history"
                className="ant-table ant-table-tbody ant-table-thead text-table-cell ant-table-cell"
                style={{ marginTop: 10 }}
              >
                <Table
                  rowKey="id_transaction"
                  locale={{
                    emptyText: (
                      <Space direction="vertical">
                        <br />
                        <Text
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                        >
                          {this.props.t("transactions.msgEmpty")}
                        </Text>
                        <Text
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            fontSize: 16,
                            fontWeight: "normal",
                          }}
                        >
                          {this.props.t("transactions.msgEmpty1")}
                        </Text>
                        <br />
                      </Space>
                    ),
                  }}
                  loading={this.state.loading}
                  columns={columnsHistory}
                  dataSource={this.state.transactionList}
                  scroll={{ x: 1500 }}
                  pagination={this.state.pagination}
                  onChange={this.handleTableChange}
                />
              </div>
            </>
          )}
        </Card>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser.userData,
    accounts: state.banking.accounts,
    respTransactions: state.transactions.allResultTx,
    accessToken: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
    transactionsByCurrency: state.transactions.resultCurrency,
    filters: state.transactions.currentFilter,
  };
};

const mapDispatchToProps = {
  getAllTransactions,
  currentToken,
  getAllTransactionsByFilter,
  currentFilters,
};

const LedgerTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Ledger)
);
export default LedgerTranslated;
