import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    initImmediate: false ,
    supportedLngs: [ "en" , "es" ] ,
    fallbackLng: 'en',
    debug: false,
    lng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: { useSuspense:false }
  });
 
export default i18n;