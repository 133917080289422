import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;
const exchange = {
  async getRate(idToken, accessToken, baseCurrency, quoteCurrency) {
    const resp = await axios.get(
      BASE_URL + config.exchangeRate + baseCurrency + "/" + quoteCurrency,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CLIENT-ID": idToken,
        },
      }
    );
    return resp;
  },
  async sellAsset(idToken, accessToken, body) {
    const resp = await axios.post(BASE_URL + config.sell, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async buyAsset(idToken, accessToken, body) {
    const resp = await axios.post(BASE_URL + config.buy, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async exchangeAsset(idToken, accessToken, body) {
    const resp = await axios.post(BASE_URL + config.swap, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async exchangeFx(idToken, accessToken, body) {
    const resp = await axios.post(BASE_URL + config.fx, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async getGeneralRate(idToken, accessToken, currency) {
    const resp = await axios.get(BASE_URL + config.rate + currency, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async getMarket(idToken, accessToken, period) {
    const resp = await axios.get(
      BASE_URL + config.market + "period=" + period,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CLIENT-ID": idToken,
        },
      }
    );
    return resp;
  },
  async getOrdersExchange(idToken, accessToken, lastId) {
    let url = BASE_URL + config.orders;
    if (lastId !== undefined) {
      url = url + "?last_id=" + lastId;
    }

    const resp = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async getOrdersByFilters(
    idToken,
    accessToken,
    status,
    minDate,
    maxDate,
    lastId
  ) {
    let baseUrl = BASE_URL + config.orders;
    let url = "";
    let timezone = new Date().getTimezoneOffset();

    if (status === null && minDate === null && maxDate === null) {
      url = baseUrl;
    }
    if (status !== null) {
      url = baseUrl + "?status=" + status;
    }
    if (minDate !== null && maxDate !== null) {
      url =
        baseUrl +
        "?min_date=" +
        minDate +
        "&max_date=" +
        maxDate +
        "&timezone_offset=" +
        timezone;
    }

    if (status !== null && minDate !== null && maxDate !== null) {
      url =
        baseUrl +
        "?status=" +
        status +
        "&min_date=" +
        minDate +
        "&max_date=" +
        maxDate +
        "&timezone_offset=" +
        timezone;
    }

    if (lastId !== null) {
      url = url + "&last_id=" + lastId;
    }
    const resp = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async getExchangePairs(idToken, accessToken, baseCurrency, quoteCurrency) {
    const resp = await axios.get(
      BASE_URL +
        config.supportedPairs +
        "?base_currency=" +
        baseCurrency +
        "&quote_currency=" +
        quoteCurrency,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CLIENT-ID": idToken,
        },
      }
    );
    return resp;
  },
  async getSpreads(idToken, accessToken) {
    const resp = await axios.get(BASE_URL + config.spreads, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
};
export default exchange;
