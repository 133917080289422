import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;
const notifications = {
  async getNotifications(idToken, accessToken) {
    const resp = await axios.get(BASE_URL + config.getNotifications, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async markReadNotifications(idToken, accessToken, ids) {
    const resp = await axios.patch(BASE_URL + config.getNotifications, ids, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
};
export default notifications;
