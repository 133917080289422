import React, { Component } from "react";
import "./more.css";
import { Row, Col, Typography, Input, Form, List } from "antd";
import COLOR from "../../const/colors";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
const { Text } = Typography;
class More extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  findRange(range) {
    let volume = parseFloat(this.props.infoTiers.current_month_volume_in_gbp);
    let min = parseFloat(range.min);
    let max = parseFloat(range.max);
    if (
      (volume >= min && max !== null && volume < max) ||
      (volume > min && !max)
    ) {
      return "Current";
    } else {
      return "";
    }
  }
  render() {
    let infoUser = this.props.dataUser.data?.data?.user;
    let typeUser = infoUser.trading_fee_type;

    return (
      <div>
        <Row gutter={[48, { xs: 8, sm: 16, md: 8, lg: 24, xl: 24 }]}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
          >
            <Row
              gutter={[8, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}
              align="middle"
            >
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 18, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 10, offset: 0 }}
              >
                <Form
                  name="more"
                  labelAlign="left"
                  labelCol={{ span: 24, offset: 0 }}
                  wrapperCol={{ span: 24, offset: 0 }}
                >
                  {typeUser === "TIERS" && !this.props.onlyFiat && (
                    <>
                      <Form.Item
                        label={
                          <Text style={{ color: "#fff", fontSize: 16 }}>
                            {this.props.t("more.tradingFees")}
                          </Text>
                        }
                      >
                        <Text style={{ color: "#53ff84", fontSize: 16 }}>
                          {"Your monthly volume (GBP): "}
                        </Text>
                        <Text style={{ color: "#fff", fontSize: 16 }}>
                          {this.props.infoTiers.current_month_volume_in_gbp}
                        </Text>
                      </Form.Item>
                      <Form.Item style={{ marginBottom: "10px" }}>
                        {this.props.infoTiers.range?.length !== 0 && (
                          <>
                            <Form.Item>
                              <Row style={{ marginBottom: 12 }} align="middle">
                                <Col span={12}>
                                  <Text style={{ color: "#fff", fontSize: 16 }}>
                                    {"Monthly Volume (GBP)"}
                                  </Text>
                                </Col>
                                <Col span={12}>
                                  <Text style={{ color: "#fff", fontSize: 16 }}>
                                    {"Fee %"}
                                  </Text>
                                </Col>
                              </Row>

                              <List
                                bordered
                                dataSource={this.props.infoTiers.range}
                                renderItem={(item) => (
                                  <List.Item style={{ paddingLeft: "14px" }}>
                                    <Col span={24}>
                                      <Row>
                                        <Col span={12}>
                                          <Text
                                            style={{
                                              color: "#fff",
                                              fontSize: 16,
                                            }}
                                          >
                                            {item.min}
                                            {item.max === null
                                              ? "+"
                                              : " - " + item.max}
                                          </Text>
                                        </Col>
                                        <Col span={12}>
                                          <Row>
                                            <Col span={6}>
                                              <Text
                                                style={{
                                                  color: "#fff",
                                                  fontSize: 16,
                                                }}
                                              >
                                                {item.percentage}
                                              </Text>
                                            </Col>
                                            <Col span={7}>
                                              <Text
                                                style={{
                                                  color: "#53ff84",
                                                  fontSize: 16,
                                                }}
                                              >
                                                {this.findRange(item)}
                                              </Text>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </List.Item>
                                )}
                              />
                            </Form.Item>
                          </>
                        )}
                      </Form.Item>
                    </>
                  )}
                  {(typeUser !== "TIERS" || this.props.onlyFiat) && (
                    <Form.Item
                      label={
                        <Text style={{ color: "#fff", fontSize: 16 }}>
                          {this.props.t("more.tradingFees")}
                        </Text>
                      }
                    >
                      <Input
                        className="input-disabled-profile"
                        size="large"
                        disabled
                        style={{
                          color: COLOR.PRIMARY_TEXT,
                          backgroundColor: COLOR.PRIMARY,
                        }}
                        value={this.props.spreads.spread + "%"}
                      />
                    </Form.Item>
                  )}
                  {infoUser.customer_success_manager !== undefined && (
                    <Form.Item
                      label={
                        <Text style={{ color: "#fff", fontSize: 16 }}>
                          {this.props.t("more.successManager")}
                        </Text>
                      }
                    >
                      <div className="style-input">
                        <Text style={{ color: "#fff", fontSize: 16 }}>
                          {infoUser.customer_success_manager.name + " - "}
                        </Text>
                        <Text style={{ color: "#fff", fontSize: 16 }}>
                          {
                            <a
                              href={
                                "mailto:" +
                                infoUser.customer_success_manager.email +
                                "?subject=Damex Direct Question"
                              }
                            >
                              {infoUser.customer_success_manager.email}
                            </a>
                          }
                        </Text>
                      </div>
                    </Form.Item>
                  )}
                  <Form.Item>
                    <Text style={{ color: "#fff", fontSize: 16 }}>
                      {
                        <a
                          href={
                            this.props.onlyFiat
                              ? "https://assets.damex.io/direct-t&cs-EMI"
                              : "https://assets.damex.io/direct-t&cs"
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {this.props.t("more.terms")}
                        </a>
                      }
                    </Text>
                  </Form.Item>
                </Form>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 6, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 14, offset: 0 }}
              ></Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser.userData,
    token: state.tokenAccess,
    onlyFiat: state.authUser.isFiatOnly,
  };
};

const mapDispatchToProps = {};

const MoreTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(More)
);

export default MoreTranslated;
