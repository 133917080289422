import React, { Component } from "react";
import COLOR from "../../const/colors";
import "./detailByAsset.css";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Wallet from "./wallet";
import Beneficiaries from "../beneficiaries/beneficiaries";
import RebalanceDetail from "../rebalanceDetail/rebalanceDetail";
import { RightOutlined } from "@ant-design/icons";
import { getAccounts, getLimits } from "../../redux/actions/bankingActions";
import { typeBeneficiary } from "../../redux/actions/beneficiariesActions";
import { selectedAsset } from "../../redux/actions/navigateActions";
import { currentToken } from "../../redux/actions/tokenActions";
import {
  Image,
  Typography,
  Tabs,
  Space,
  Row,
  Col,
  Card,
  Radio,
  Progress,
  Skeleton,
  Select,
} from "antd";
import { asset } from "../../common/assets";
import { Redirect } from "react-router-dom";
const { Text } = Typography;
const { Option } = Select;
class DetailByAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetActive: "",
      icon: "",
      nameAsset: "",
      loading: false,
      tabActive: "1",
      limitType: "daily",
      limitExternal: "",
      limitInternal: "",
      currentVolume: "",
      loadingLimits: false,
      accountsList: [],
      accountSelected: "",
    };
  }
  componentDidMount() {
    this.props.typeBeneficiary("");
    let assetSelect = this.props.asset.key;
    this.getInfoAsset(assetSelect);
    this.getAccounts();
    this.getLimits(assetSelect);
    if (
      this.props.asset.source !== undefined ||
      this.props.asset.source !== null
    ) {
      if (this.props.asset.source === "BENEFICIARY") {
        this.handleTab("2");
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.asset !== this.props.asset) {
      if (
        this.props.asset.source !== undefined ||
        this.props.asset.source !== null
      ) {
        if (this.props.asset.source === "BENEFICIARY") {
          let data = asset.assets;
          let assetActual = data.find(
            (element) => element.value === this.props.asset.key
          );
          if (assetActual !== undefined) {
            this.setState({ assetActive: assetActual });
          }
          this.handleTab("2");
        }
      }
    }
    if (prevProps.currentBeneficiary !== this.props.currentBeneficiary) {
      if (!this.props.modalState) {
        if (this.props.currentBeneficiary !== "") {
          let findCurrentVolume = "";
          let dataAccounts = this.props.banking.accounts.data.data.accounts;
          let findAccount = dataAccounts.find(
            (element) => element.currency === this.state.assetActive.value
          );
          if (this.props.currentBeneficiary.type === "EXTERNAL") {
            findCurrentVolume = findAccount.volumes.find(
              (element) => element.type === "WITHDRAWAL"
            );
          } else {
            findCurrentVolume = findAccount.volumes.find(
              (element) => element.type === "TRANSFER_OUT"
            );
          }

          this.setState({ currentVolume: findCurrentVolume });
        }
      }
    }
  }

  async getLimits(assetSelect) {
    try {
      this.setState({ loadingLimits: true });
      await this.props.getLimits(
        this.props.token.clientId,
        this.props.token.current
      );
      let resp = this.props.limitList;
      if (resp.headers !== undefined) {
        let newAccessToken = resp.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (resp.status === 200) {
        let limits = this.props.limitList.data.data.limits;
        let findLimits = limits.find(
          (element) => element.currency === assetSelect
        );
        let findExternalLimit = findLimits.limits.find(
          (element) => element.type === "EXTERNAL"
        );
        let findInternalLimit = findLimits.limits.find(
          (element) => element.type === "INTERNAL"
        );
        if (findExternalLimit !== undefined) {
          this.setState({ limitExternal: findExternalLimit });
        }
        if (findInternalLimit !== undefined) {
          this.setState({ limitInternal: findInternalLimit });
        }
        this.setState({ loadingLimits: false });
      } else if (
        resp.status === 401 ||
        resp.status === 422 ||
        resp.status === 403 ||
        resp.status === 404 ||
        resp.status === 500
      ) {
        this.setState({ loadingLimits: false });
      }
    } catch (error) {
      this.setState({ loadingLimits: false });
    }
  }

  async getInfoAsset(assetSelect) {
    let data = asset.assets;
    let assetActual = data.find((element) => element.value === assetSelect);
    let multipleAccounts =
      this.props.banking.accounts.data?.data?.native_balances.currencies;
    let dataAccounts = this.props.banking.accounts.data.data.accounts;
    if (this.props.asset.id !== null) {
      let findMultipleAccount = multipleAccounts.find(
        (element) => element.currency === assetSelect
      );

      if (findMultipleAccount !== undefined) {
        let array = [];
        if (findMultipleAccount.accounts) {
          for (let account of findMultipleAccount.accounts) {
            let objAccount = {};
            let findInListAllAccount = dataAccounts.find((acc) => {
              return acc._id === account._id;
            });
            if (findInListAllAccount) {
              if (findInListAllAccount.status === "ACTIVE") {
                objAccount.label = account.alias;
                objAccount.key = account._id;
                array.push(objAccount);
              }
            }
          }
        }

        let findActive = dataAccounts.find(
          (element) => element._id === this.props.asset.id
        );
        //  console.log(findActive);
        this.setState({
          accountsList: array,
          accountSelected: findActive._id,
        });
      }
    }
    if (assetActual !== undefined) {
      this.setState({ assetActive: assetActual });
    }
  }
  async getAccounts() {
    try {
      await this.props.getAccounts(
        this.props.token.clientId,
        this.props.token.current
      );
      let response = this.props.banking.accounts;
      let newAccessToken = "";
      if (response.headers !== undefined) {
        newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
    } catch (error) {
      console.log(error);
    }
  }
  handleTab(key) {
    this.setState({ tabActive: key });
  }

  handleChangeLimit = (e) => {
    this.setState({ limitType: e.target.value });
  };
  handleAccount = (value) => {
    let info = {
      id: value,
      key: this.props.asset.key,
      multiple: this.props.asset.multiple,
    };
    this.setState({ accountSelected: value });
    this.props.selectedAsset(info);
  };

  render() {
    let userType = this.props.dataUser.data?.data?.user?.type;
    if (this.props.asset === "") {
      return <Redirect to="/" />;
    }
    let limit = "";
    let currentVolume = "";
    let maxLimit = "";
    let percentage = 0;
    if (this.props.currentBeneficiary.type === "EXTERNAL") {
      limit = this.state.limitExternal;
    } else {
      limit = this.state.limitInternal;
    }
    currentVolume =
      this.state.limitType === "daily"
        ? this.state.currentVolume.current_day_volume
        : this.state.currentVolume.current_month_volume;
    maxLimit =
      this.state.limitType === "daily"
        ? limit.max_daily_withdrawal
        : limit.max_monthly_withdrawal;
    percentage = (Number(currentVolume) / Number(maxLimit)) * 100;
    return (
      <Row
        gutter={[8, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}
        style={{ marginBottom: 10 }}
      >
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          md={{ span: 22, offset: 1 }}
          lg={{ span: 22, offset: 1 }}
          xl={{ span: 22, offset: 1 }}
        >
          <Row
            gutter={[8, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}
            align="middle"
          >
            <Col
              xs={{ span: 15, offset: 0 }}
              sm={{ span: 15, offset: 0 }}
              md={{ span: 15, offset: 0 }}
              lg={{ span: 15, offset: 0 }}
              xl={{ span: 15, offset: 0 }}
            >
              <Space size="middle" align="center" style={{ marginTop: 30 }}>
                <Image
                  preview={false}
                  width={40}
                  src={
                    this.state.assetActive.icon !== undefined
                      ? this.state.assetActive.icon
                      : ""
                  }
                />
                <Text
                  style={{
                    color: COLOR.PRIMARY_TEXT,
                    fontSize: 32,
                    marginBottom: 5,
                    fontWeight: "bold",
                  }}
                >
                  {this.state.assetActive.name !== undefined
                    ? this.state.assetActive.name
                    : ""}
                </Text>
                <Text
                  style={{
                    color: COLOR.SECONDARY_TEXT,
                    fontSize: 32,
                    marginBottom: 5,
                    fontWeight: "bold",
                  }}
                >
                  {this.state.assetActive.value !== undefined
                    ? this.state.assetActive.value
                    : ""}
                </Text>
              </Space>
            </Col>

            <Col
              xs={{ span: 9, offset: 0 }}
              sm={{ span: 9, offset: 0 }}
              md={{ span: 9, offset: 0 }}
              lg={{ span: 9, offset: 0 }}
              xl={{ span: 9, offset: 0 }}
            >
              {this.props.currentBeneficiary !== "" &&
                this.state.tabActive !== "3" && (
                  <Card
                    title={
                      <Row
                        gutter={[8, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}
                        align="middle"
                        justify="space-between"
                      >
                        <Space size="middle" align="center">
                          <Text
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              fontSize: 15,
                            }}
                          >
                            {this.props.currentBeneficiary.type !==
                              undefined && (
                              <>
                                {this.props.t("limits.title", {
                                  key: this.props.t(
                                    "beneficiaries." +
                                      this.props.currentBeneficiary.type.toLowerCase()
                                  ),
                                })}
                                {this.props.currentBeneficiary.type ===
                                "EXTERNAL"
                                  ? this.props.currentBeneficiary.account_data
                                      .network === "BASE_ASSET"
                                    ? "" + this.props.t("sell.message.instant")
                                    : this.props.currentBeneficiary.account_data
                                        .network
                                    ? this.props.currentBeneficiary.account_data
                                        .network +
                                      this.props.t("sell.message.instant")
                                    : this.props.t("sell.message.instant")
                                  : ""}
                              </>
                            )}
                            {this.props.currentBeneficiary.type ===
                              undefined && (
                              <>
                                {this.props.t("limits.title", {
                                  key: this.props.t("beneficiaries.external"),
                                })}
                              </>
                            )}
                          </Text>
                        </Space>
                        <Space size="middle" align="center">
                          <Radio.Group
                            value={this.state.limitType}
                            onChange={this.handleChangeLimit}
                          >
                            <Radio.Button
                              style={{
                                borderColor: COLOR.GREEN,
                                background:
                                  this.state.limitType === "daily"
                                    ? COLOR.GREEN
                                    : COLOR.SECONDARY_GRAY,
                                color:
                                  this.state.limitType === "daily"
                                    ? COLOR.SECONDARY_GRAY
                                    : COLOR.PRIMARY_TEXT,
                                fontSize: 14,
                              }}
                              value="daily"
                            >
                              {this.props.t("limits.daily")}
                            </Radio.Button>
                            <Radio.Button
                              style={{
                                borderColor: COLOR.GREEN,
                                background:
                                  this.state.limitType === "monthly"
                                    ? COLOR.GREEN
                                    : COLOR.SECONDARY_GRAY,
                                color:
                                  this.state.limitType === "monthly"
                                    ? COLOR.SECONDARY_GRAY
                                    : COLOR.PRIMARY_TEXT,
                                fontSize: 14,
                              }}
                              value="monthly"
                            >
                              {this.props.t("limits.monthly")}
                            </Radio.Button>
                          </Radio.Group>
                        </Space>
                      </Row>
                    }
                    bordered={false}
                    size="default"
                    style={{
                      borderRadius: "5px",
                      background: COLOR.SECONDARY_GRAY,
                      color: COLOR.PRIMARY_TEXT,
                      border: "1px solid #000",
                      width: "98%",
                    }}
                    bodyStyle={{ color: COLOR.PRIMARY_TEXT, fontSize: 14 }}
                  >
                    {this.state.loadingLimits && <Skeleton active />}
                    {!this.state.loadingLimits && (
                      <div>
                        <Row
                          gutter={[8, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}
                          align="middle"
                          justify="space-between"
                          style={{ marginTop: 15 }}
                        >
                          <Text
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              textAlign: "left",
                              fontWeight: "normal",
                            }}
                          >
                            {this.props.t("limits.withdrawnFunds")}
                          </Text>

                          <Text
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              fontSize: 14,
                              fontWeight: "normal",
                            }}
                          >
                            {this.state.assetActive.value + " "}
                            {this.state.limitType === "daily"
                              ? this.state.currentVolume.current_day_volume
                              : this.state.currentVolume.current_month_volume}
                            {" of " + this.state.assetActive.value + " "}
                            {maxLimit === "-1"
                              ? this.props.t("limits.unlimited")
                              : maxLimit}
                          </Text>
                        </Row>
                        {maxLimit !== "-1" && (
                          <Row
                            gutter={[
                              8,
                              { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 },
                            ]}
                            align="middle"
                            justify="space-between"
                          >
                            <Progress
                              trailColor="#808080"
                              strokeColor={COLOR.GREEN}
                              percent={percentage}
                              showInfo={false}
                            />
                          </Row>
                        )}

                        <Row
                          gutter={[8, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}
                          align="middle"
                          justify="space-between"
                        >
                          <Text
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              textAlign: "left",
                              fontWeight: "normal",
                            }}
                          >
                            {this.props.t("limits.withdrawLimit", {
                              key: this.props.t(
                                "limits." + this.state.limitType
                              ),
                            })}
                          </Text>

                          <Text
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              textAlign: "left",
                              fontWeight: "normal",
                            }}
                          >
                            {this.state.assetActive.value + " "}
                            {maxLimit === "-1"
                              ? this.props.t("limits.unlimited")
                              : maxLimit}
                          </Text>
                        </Row>
                      </div>
                    )}
                  </Card>
                )}
            </Col>
          </Row>
          {this.props.asset.id !== null &&
            this.state.accountsList.length > 1 && (
              <Row
                style={{ marginTop: 12 }}
                gutter={[8, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}
                align="middle"
              >
                <Col
                  xs={{ span: 8, offset: 0 }}
                  sm={{ span: 8, offset: 0 }}
                  md={{ span: 8, offset: 0 }}
                  lg={{ span: 2, offset: 0 }}
                  xl={{ span: 2, offset: 0 }}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      color: COLOR.PRIMARY_TEXT,
                    }}
                  >
                    Account
                  </Text>
                </Col>
                <Col
                  xs={{ span: 16, offset: 0 }}
                  sm={{ span: 16, offset: 0 }}
                  md={{ span: 16, offset: 0 }}
                  lg={{ span: 8, offset: 0 }}
                  xl={{ span: 8, offset: 0 }}
                >
                  <div
                    id="select-dark-accounts"
                    className="ant-select-selector ant-select"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Select
                      placeholder="Accounts"
                      optionLabelProp="children"
                      value={this.state.accountSelected}
                      style={{ width: "100%" }}
                      suffixIcon={
                        <RightOutlined
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                          }}
                        />
                      }
                      onChange={(value) => this.handleAccount(value)}
                    >
                      {this.state.accountsList.map((item, i) => {
                        return (
                          <Option key={item.key} value={item.key}>
                            {item.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Col>
              </Row>
            )}
          <Row
            gutter={[8, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}
            align="middle"
          >
            <div
              id="custom-tab-detail"
              className="ant-tabs ant-tabs-tab ant-tabs-tab-active ant-tabs-tab-btn ant-tabs-nav ant-tabs-nav-list"
            >
              <Tabs
                type="line"
                activeKey={this.state.tabActive}
                tabBarStyle={{
                  color: COLOR.PRIMARY_TEXT,
                  width: "100%",
                  paddingLeft: "10px",
                }}
                items={[
                  {
                    key: "1",
                    label: (
                      <Text
                        style={{
                          fontSize: 18,
                          color: COLOR.PRIMARY_TEXT,
                        }}
                      >
                        {this.props.t("wallet.title")}
                      </Text>
                    ),
                    children: this.state.tabActive === "1" && (
                      <Wallet
                        tabActive={this.state.tabActive}
                        handleTab={this.handleTab.bind(this)}
                        accountSelected={this.state.accountSelected}
                      />
                    ),
                  },
                  this.state.assetActive !== "" &&
                    ((this.state.assetActive.type !== undefined &&
                      this.state.assetActive.type === "CRYPTO" &&
                      userType.includes("LITE")) ||
                      !userType.includes("LITE")) && {
                      label: (
                        <Text
                          style={{
                            fontSize: 18,
                            color: COLOR.PRIMARY_TEXT,
                          }}
                        >
                          {userType.includes("LITE") &&
                          this.state.assetActive.type === "FIAT"
                            ? this.props.t("beneficiaries.account")
                            : this.props.t("beneficiaries.title")}
                        </Text>
                      ),
                      key: "2",
                      children: this.state.tabActive === "2" && (
                        <Beneficiaries />
                      ),
                    },
                  this.state.assetActive !== "" &&
                    this.props.asset.multiple !== undefined &&
                    this.props.asset.multiple &&
                    this.state.assetActive.type !== undefined &&
                    this.state.assetActive.type === "FIAT" && {
                      label: (
                        <Text
                          style={{
                            fontSize: 18,
                            color: COLOR.PRIMARY_TEXT,
                          }}
                        >
                          {"Rebalance"}
                        </Text>
                      ),
                      key: "3",
                      children: this.state.tabActive === "3" && (
                        <RebalanceDetail
                          source="rebalance"
                          tabActive={this.state.tabActive}
                          accountSelected={this.state.accountSelected}
                        />
                      ),
                    },
                ]}
                onTabClick={(key) => this.handleTab(key)}
              />
            </div>
          </Row>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser.userData,
    asset: state.siderNavigate.asset,
    limitList: state.banking.limits,
    banking: state.banking,
    token: state.tokenAccess,
    currentBeneficiary: state.beneficiaries.beneficiarySelected,
    modalState: state.siderNavigate.modal,
  };
};

const mapDispatchToProps = {
  currentToken,
  getAccounts,
  typeBeneficiary,
  getLimits,
  selectedAsset,
};

const DetailByAssetTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DetailByAsset)
);

export default DetailByAssetTranslated;
