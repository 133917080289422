import React, { Component } from "react";
import "./login.css";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Space,
  Spin,
  message,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import COLOR from "../../const/colors";
import { connect } from "react-redux";
import {
  authUser,
  signinSuccess,
  acceptTerms,
  onlyFiat,
} from "../../redux/actions/userActions";
import { siderNavigate } from "../../redux/actions/navigateActions";
import {
  currentToken,
  currentClientId,
} from "../../redux/actions/tokenActions";
import { getAccounts } from "../../redux/actions/bankingActions";
import { loginTwoFactor } from "../../redux/actions/twoFactorActions";
import { getNotifications } from "../../redux/actions/notificationsActions";
import { withTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { osName, fullBrowserVersion, browserName } from "react-device-detect";
import platform from "platform";
import ModalTwoFactor from "../twoFactor/twoFactor";
import ModalTerms from "../../common/modalTerms/modalTerms";
import config from "../../services/config";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import jwt_decode from "jwt-decode";
import { getAllTransactionsByFilter } from "../../redux/actions/approvalsActions";
const fpPromise = FingerprintJS.load();
const { Title } = Typography;
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: false,
      email: "",
      password: "",
      modalTwoFactor: false,
      loading: false,
      idClient: "",
      tokenFb: "",
      showModalTerms: false,
      disableButtonAgree: true,
    };
  }
  openModalTwoFactor() {
    this.setState({ modalTwoFactor: true });
  }
  closeModalTwoFactor() {
    this.setState({ modalTwoFactor: false, loading: false });
  }
  componentDidMount() {
    this.get();
    if (window.location.pathname === "/signin") {
      this.props.siderNavigate("signin");
    }
  }
  async get() {
    const fp = await fpPromise;
    const result = await fp.get();
    const visitorId = result.visitorId;
    this.setState({ idClient: visitorId });
  }
  async login(resend) {
    try {
      this.setState({ loading: true });
      let tokenId = uuidv4();
      let data = this.state.password;
      let enumData = config.ENUM;
      let pass = new Buffer(data + enumData + tokenId);
      let passwordToBase64 = pass.toString("base64");
      let osInfo =
        platform.description.split("on").length > 0
          ? platform.description.split("on")[1]
          : osName;
      let browser = browserName + " " + fullBrowserVersion;
      let tokenFireBase = window.localStorage.getItem("token");
      let body = {
        email: this.state.email.toLowerCase(),
        password: passwordToBase64,
      };
      await this.props.authUser(
        body,
        this.state.idClient,
        osInfo,
        browser,
        tokenFireBase
      );

      let auth = this.props.authUserResult.authResult;
      if (auth.data !== undefined) {
        let twoFactor = auth.data.two_factor;
        if (twoFactor) {
          if (!resend) {
            this.setState({
              modalTwoFactor: true,
              email: this.state.email,
            });
          } else {
            message.success(this.props.t("signin.messages.resend"));
          }
        } else {
          if (auth.success) {
            let accessToken = auth.data.access_token;
            this.props.currentClientId(this.state.idClient);
            this.props.siderNavigate("accounts");
            await this.getUser(this.state.idClient, accessToken);
          } else if (auth.data.error) {
            if (auth.status === 404) {
              message.error(this.props.t("signin.messages.invalidEmailorPass"));
              this.setState({ loading: false });
            } else {
              message.error(this.props.t("signin.messages.formError"));
              this.setState({ loading: false });
            }
          }
        }
      } else {
        message.error(this.props.t("signin.messages.formError"));
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
      message.error(this.props.t("signin.messages.formError"));
      this.setState({ loading: false });
    }
  }
  async getUser(id, token) {
    try {
      await this.props.signinSuccess(id, token);

      let response = this.props.authUserResult.userData;
      let newAccessToken = response.headers["x-new-access-token"];
      await this.props.currentToken(newAccessToken);
      this.loadPendingTx(id, token);
      if (response.status === 200) {
        let profile = response.data?.data?.user;
        let agreeTerms = profile.tc_agreement;
        let role = profile.role;
        let fiatOnly =
          profile.only_fiat_accounts !== undefined
            ? profile.only_fiat_accounts
            : false;
        this.props.onlyFiat(fiatOnly);
        if (agreeTerms === true) {
          await this.getAccounts(id, newAccessToken);
        } else if (agreeTerms === false && role === "MASTER") {
          this.openModalTerms();
        } else {
          message.error(this.props.t("signin.messages.agreeTerms"), 10);
          this.setState({ loading: false });
        }
      } else if (response.status === 401) {
        this.setState({ loading: false });
        message.error(this.props.t("signin.messages.formError"));
      } else if (response.status === 403) {
        this.setState({ loading: false });
        message.error(this.props.t("signin.messages.accessError"));
      } else if (response.status === 404) {
        this.setState({ loading: false });
        message.error(this.props.t("signin.messages.formError"));
      }
    } catch (error) {
      console.log(error);
      this.loadPendingTx(id, token);
      this.setState({ loading: false });
    }
  }
  async loadPendingTx(id, token) {
    try {
      await this.props.getAllTransactionsByFilter(
        id,
        token,
        null,
        null,
        null,
        null,
        null
      );
    } catch (error) {}
  }
  async getAccounts(id, token) {
    try {
      await this.props.getAccounts(id, token);
      let response = this.props.banking.accounts;
      let newAccessToken = "";
      if (response.headers !== undefined) {
        newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        await this.getNotifications(id, newAccessToken);
      } else if (
        response.status === 401 ||
        response.status === 422 ||
        response.status === 403 ||
        response.status === 404
      ) {
        this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  }
  async getNotifications(id, token) {
    try {
      await this.props.getNotifications(id, token);
      let resp = this.props.notification;
      if (resp.headers !== undefined) {
        let newAccessToken = resp.headers["x-new-access-token"];
        var decoded = jwt_decode(newAccessToken);
        let time = decoded.exp - decoded.iat;
        window.localStorage.setItem("timeToken", time * 1000);
        await this.props.currentToken(newAccessToken);
      }
      if (resp.status === 200) {
        setTimeout(() => {
          this.setState({ auth: true, loading: false, loadingTerms: false });
          this.closeModalTerms();
        }, 1000);
      } else if (
        resp.status === 401 ||
        resp.status === 422 ||
        resp.status === 403 ||
        resp.status === 404 ||
        resp.status === 500
      ) {
        this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  }
  openModalTerms = () => {
    this.setState({ showModalTerms: true });
  };
  onChangeTerms = (e) => {
    this.setState({ disableButtonAgree: !e.target.checked });
  };
  closeModalTerms = () => {
    this.setState({ showModalTerms: false, loading: false });
  };
  async acceptTermsAndConditions() {
    this.setState({ loadingTerms: true });
    try {
      await this.props.acceptTerms(
        this.state.idClient,
        this.props.token.current
      );
      let response = this.props.termsAndConditions;
      let newAccessToken = response.headers["x-new-access-token"];
      await this.props.currentToken(newAccessToken);
      if (response.status === 200) {
        await this.props.signinSuccess(this.state.idClient, newAccessToken);
        await this.getAccounts(this.state.idClient, newAccessToken);
      } else if (response.status === 401) {
        this.setState({ loadingTerms: false });
        message.error(this.props.t("signin.messages.formError"));
      } else if (response.status === 403) {
        this.setState({ loadingTerms: false });
        message.error(this.props.t("signin.messages.accessError"));
      } else if (response.status === 404) {
        this.setState({ loadingTerms: false });
        message.error(this.props.t("signin.messages.formError"));
      }
    } catch (error) {
      console.log(error);
      this.setState({ loadingTerms: false });
    }
  }
  render() {
    if (this.state.auth) {
      return <Redirect to="/account" />;
    }
    return (
      <div>
        <Row gutter={[0, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
          <Col
            className="gutter-row"
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 16, offset: 4 }}
            md={{ span: 14, offset: 6 }}
            lg={{ span: 14, offset: 6 }}
            xl={{ span: 6, offset: 9 }}
          >
            <Row justify="center" align="middle">
              <Col span={24}>
                <Space direction="vertical" className="container-login">
                  <Title
                    level={4}
                    style={{
                      color: COLOR.PRIMARY,
                      textAlign: "center",
                      fontWeight: "bold",
                      marginTop: 20,
                    }}
                  >
                    {this.props.t("signin.title")}
                  </Title>
                  <br />
                  <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={() => this.login(false)}
                  >
                    <Spin
                      spinning={this.state.loading}
                      size="large"
                      indicator={
                        <LoadingOutlined style={{ color: "#00ff6a" }} />
                      }
                    >
                      <Form.Item
                        name="username"
                        rules={[
                          {
                            required: true,
                            message: this.props.t(
                              "signin.messages.usernameError"
                            ),
                          },
                          {
                            type: "email",
                            message: this.props.t("signin.messages.emailError"),
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder={this.props.t("signin.username")}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: this.props.t(
                              "signin.messages.passwordError"
                            ),
                          },
                        ]}
                      >
                        <Input.Password
                          size="large"
                          placeholder={this.props.t("signin.password")}
                          onChange={(e) =>
                            this.setState({ password: e.target.value })
                          }
                        />
                      </Form.Item>

                      <Form.Item style={{ textAlign: "right" }}>
                        <Button
                          size="large"
                          type="primary"
                          htmlType="submit"
                          className="login-form-button"
                        >
                          {this.props.t("signin.signin").toUpperCase()}
                        </Button>
                      </Form.Item>
                    </Spin>
                  </Form>
                </Space>
              </Col>
            </Row>
            <br />
            <Row justify="center" align="middle">
              <Button type="link" className="link-login">
                <Link to="/forgotPassword">
                  {this.props.t("signin.forgotPassword")}
                </Link>
              </Button>
              <span>{"‧"}</span>
              <Button type="link" className="link-login">
                <Link
                  to="#"
                  onClick={(e) => {
                    window.location = "mailto:info@damex.io";
                    e.preventDefault();
                  }}
                >
                  {this.props.t("signin.haveAccount")}
                </Link>
              </Button>
              <span>{"‧"}</span>
              <a
                href="https://www.damex.io/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button type="link" className="link-login">
                  {this.props.t("signin.policy")}
                </Button>
              </a>
            </Row>
            <Row justify="center" align="middle">
              <a href="mailto:support@damex.io?subject=Issue with 2FA (Damex Direct)">
                <Button type="link" className="link-login">
                  {this.props.t("signin.issueTwoFactor")}
                </Button>
              </a>
            </Row>
          </Col>
        </Row>
        <ModalTwoFactor
          modalTwoFactor={this.state.modalTwoFactor}
          tokenId={this.state.idClient}
          email={this.state.email}
          openModalTwoFactor={this.openModalTwoFactor.bind(this)}
          closeModalTwoFactor={this.closeModalTwoFactor.bind(this)}
          login={this.login.bind(this)}
          openModalTerms={this.openModalTerms.bind(this)}
        />
        <ModalTerms
          onCancel={this.closeModalTerms.bind(this)}
          onChangeTerms={this.onChangeTerms.bind(this)}
          showModal={this.state.showModalTerms}
          buttonAgree={this.state.disableButtonAgree}
          acceptTerms={this.acceptTermsAndConditions.bind(this)}
          loading={this.state.loadingTerms}
          fiatOnly={this.props.fiatOnly}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authUserResult: state.authUser,
    result: state.siderNavigate,
    signinSuccess: state.signinSuccess,
    twoFactor: state.twoFactor,
    banking: state.banking,
    notification: state.loadNotifications.notifications,
    termsAndConditions: state.authUser.terms,
    token: state.tokenAccess,
    fiatOnly: state.authUser.isFiatOnly,
  };
};

const mapDispatchToProps = {
  authUser,
  siderNavigate,
  signinSuccess,
  currentToken,
  currentClientId,
  loginTwoFactor,
  getAccounts,
  getNotifications,
  acceptTerms,
  onlyFiat,
  getAllTransactionsByFilter,
};
const Signin = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Login)
);

export default Signin;
