import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;
const user = {
  async userAuth(body, idToken, os, browser, token) {
    const resp = await axios.post(BASE_URL + config.login, body, {
      headers: {
        "X-CLIENT-ID": idToken,
        "X-CLIENT-OS": os,
        "X-CLIENT-NAME": browser,
        "X-CLIENT-TOKEN": token,
      },
    });
    return resp;
  },
  async userLogout(idToken, accessToken) {
    const resp = await axios.delete(BASE_URL + config.logout, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async sendEmailRecovery(body) {
    const resp = await axios.post(BASE_URL + config.sendEmailRecovery, body);
    return resp;
  },
  async confirmEmail(idToken, body) {
    const resp = await axios.post(BASE_URL + config.confirmEmail, body, {
      headers: {
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async setNewPassword(idToken, accessToken, body) {
    const resp = await axios.post(BASE_URL + config.newPassword, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async refreshToken(idToken, accessToken) {
    const resp = await axios.put(
      BASE_URL + config.refreshToken,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CLIENT-ID": idToken,
        },
      }
    );
    return resp;
  },
  async getUser(idToken, accessToken) {
    const resp = await axios.get(BASE_URL + config.manageUser, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async updateUser(idToken, accessToken, body) {
    const resp = await axios.post(BASE_URL + config.manageUser, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async confirmUpdate(idToken, accessToken, body) {
    const resp = await axios.post(BASE_URL + config.confirmUpdate, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async acceptTermsAndConditions(idToken, accessToken) {
    const resp = await axios.post(
      BASE_URL + config.acceptTerms,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CLIENT-ID": idToken,
        },
      }
    );
    return resp;
  },
};
export default user;
