import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;
const approvals = {
  async getAllTransactionsPendingByFilter(
    idToken,
    accessToken,
    currency,
    userChildrenId,
    startDate,
    endDate,
    lastId
  ) {
    let baseUrl = BASE_URL + config.txAuthorization;
    const resp = await axios.get(baseUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
      params: {
        currency_code: currency,
        user_children_id: userChildrenId,
        start_date: startDate,
        end_date: endDate,
        last_id: lastId,
      },
    });
    return resp;
  },

  async manageTransactionAuthorisation(idToken, accessToken, option) {
    const resp = await axios.put(
      BASE_URL + config.txAuthorization,
      { activate: option },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CLIENT-ID": idToken,
        },
      }
    );
    return resp;
  },
  async changeStatusPendingTransaction(idToken, accessToken, option, txId) {
    const resp = await axios.post(
      BASE_URL + config.txAuthorization + "/" + txId,
      { status: option },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CLIENT-ID": idToken,
        },
      }
    );
    return resp;
  },
};
export default approvals;
