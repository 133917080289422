import React, { Component } from "react";
import COLOR from "../../const/colors";
import "./rebalanceDetail.css";
import { currentToken } from "../../redux/actions/tokenActions";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Row, Col, Card } from "antd";
import ContainerRebalance from "../rebalance/containerRebalance";
import TransactionsWallet from "../detailByAsset/transactionsWallet";
import { asset } from "../../common/assets";

class RebalanceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetActive: "",
      listToFilter: [],
      loading: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getInfoAsset();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.asset !== this.props.asset) {
      this.getInfoAsset();
    }
  }
  getInfoAsset() {
    let assetSelect = this.props.asset.key;
    let accountsList = this.props.accounts.data.data.accounts;
    let accountAsset = accountsList.find(
      (element) => element.currency === assetSelect
    );

    let assetData = {};
    if (accountAsset !== undefined) {
      assetData.accountData = accountAsset.account_data;
      assetData.currency = accountAsset.currency;
      assetData.id = accountAsset._id;
      assetData.type = accountAsset.type;
      let currency = asset.assets.find(
        (element) => assetSelect === element.value
      );
      if (currency !== undefined) {
        assetData.icon = currency.icon;
        assetData.name = currency.name;
        assetData.symbol = currency.symbol;
      }
    }
    this.setState({
      assetActive: assetData,
    });
  }

  render() {
    let userType = this.props.dataUser.data?.data?.user?.type;
    if (userType === undefined) {
      userType = "DAMPAY_USER_CHILDREN";
    }
    return (
      <div>
        <Row gutter={[8, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: window.screen.width <= 1024 ? 24 : 16, offset: 0 }}
            xl={{ span: 16, offset: 0 }}
            xxl={{ span: 18, offset: 0 }}
          >
            <TransactionsWallet accountSelected={this.props.accountSelected} tabActive={this.props.tabActive}/>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: window.screen.width <= 1024 ? 24 : 8, offset: 0 }}
            xl={{ span: 8, offset: 0 }}
            xxl={{ span: 6, offset: 0 }}
          >
            <Card
              bordered={false}
              size="small"
              style={{
                borderRadius: "5px",
                background: COLOR.PRIMARY,
                color: COLOR.SECONDARY_TEXT,
                border: "1px solid #000",
                width: "98%",
              }}
              bodyStyle={{ padding: "0px 0px 15px 0px" }}
            >
              <ContainerRebalance />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser.userData,
    asset: state.siderNavigate.asset,
    accounts: state.banking.accounts,
    beneficiariesList: state.beneficiaries.resultBeneficiaries,
    token: state.tokenAccess,
  };
};

const mapDispatchToProps = {
  currentToken,
};

const RebalanceDetailTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(RebalanceDetail)
);

export default RebalanceDetailTranslated;
