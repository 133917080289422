import React, { Component } from "react";
import COLOR from "../../const/colors";
import { withTranslation } from "react-i18next";
import { Modal } from "antd";
import { connect } from "react-redux";
import { CloseOutlined, RightOutlined } from "@ant-design/icons";
import { Typography, Form, Select, Button, Input, Image, message } from "antd";
import { editBeneficiary } from "../../redux/actions/beneficiariesActions";
import { currentToken } from "../../redux/actions/tokenActions";
import * as WAValidator from "multicoin-address-validator";
import * as WAValidator2 from "wallet-validator";
const { Text } = Typography;
const { Option } = Select;
const formRef = React.createRef();
class ModalEditBeneficiary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: "1",
      loading: false,
      disableEdit: true,
      network:
        this.props.currencySelect.type === "CRYPTO"
          ? this.props.networkSelect === undefined
            ? this.props.beneficiary.networks[0].network
            : this.props.beneficiary.network
          : "",
      currentAddress:
        this.props.currencySelect.type === "CRYPTO"
          ? this.props.beneficiary.address !== undefined
            ? this.props.beneficiary.address
            : this.props.beneficiary.networks[0].address
          : "",
    };
  }
  componentDidMount() {}
  async editBeneficiary(values) {
    try {
      this.setState({ loading: true });
      let body = "";
      if (this.props.beneficiaryEdit.type === "EXTERNAL") {
        if (this.props.currencySelect.type === "FIAT") {
          if (this.props.beneficiary.currency === "EUR") {
            body = {
              description: values.description,
              account_holder: values.accountHolder,
              iban: values.iban.replace(/\s+/g, ""),
              bic: values.bic.replace(/\s+/g, ""),
            };
          } else if (this.props.beneficiary.currency === "GBP") {
            const reg = /^[0-9]+$/;
            const regSortNumber = /^[0-9-]+$/;
            const regSort = /^(?:\d{6}|\d\d-\d\d-\d\d)$/;
            if (!reg.test(values.accountNumber)) {
              message.error(
                this.props.t("beneficiaries.messages.errorAccountNumber")
              );
              this.setState({ loading: false });
            } else if (
              !regSortNumber.test(values.sortCode) ||
              !regSort.test(values.sortCode)
            ) {
              message.error(
                this.props.t("beneficiaries.messages.errorSortCode")
              );
              this.setState({ loading: false });
            } else {
              body = {
                description: values.description,
                account_holder: values.accountHolder,
                account_number: values.accountNumber.replace(/\s+/g, ""),
                sort_code: values.sortCode.replace(/[\s-]+/g, ""),
              };
            }
          }
        } else if (this.props.currencySelect.type === "CRYPTO") {
          let valid = "";
          if (this.props.beneficiaryEdit.currency === "BTC") {
            valid = WAValidator2.validate(values.address, "BTC");
          } else if (this.state.network === "TRC20") {
            valid = WAValidator.validate(values.address, "TRX");
          } else {
            valid = WAValidator.validate(values.address, "ETH");
          }
          if (valid) {
            body = {
              description: values.description,
              address: values.address,
            };
          } else {
            message.error(
              this.props.t("beneficiaries.messages.invalidAddress")
            );
            this.setState({ loading: false });
          }
        }
      } else {
        body = {
          description: values.description,
        };
      }

      if (Object.keys(body).length !== 0) {
        let id = this.props.beneficiaryEdit.id;
        await this.props.editBeneficiary(
          this.props.token.clientId,
          this.props.token.current,
          id,
          body
        );
        let resp = this.props.editResp;
        if (resp.headers !== undefined) {
          let newAccessToken = resp.headers["x-new-access-token"];
          await this.props.currentToken(newAccessToken);
        }
        if (resp.status === 200) {
          message.success(
            this.props.t("beneficiaries.messages.beneficiaryUpdated")
          );
          this.setState({ loading: false });
          this.props.closeModalEditBeneficiary();
          let currency = this.props.beneficiaryEdit.currency;
          let network =
            this.props.beneficiary.network !== undefined
              ? this.props.beneficiary.network
              : "";
          this.props.getBeneficiaries(currency, network);
        } else if (resp.status === 400) {
          this.setState({ loading: false });
          if (resp.data.error_details.validation_error !== undefined) {
            let messageError = resp.data.error_details.validation_error;
            if (
              resp.data.error_details.validation_error ===
              "THIRD_PARTY_VALIDATION_ERROR"
            ) {
              if (resp.data.message.includes("Invalid iban")) {
                message.error(
                  this.props.t("beneficiaries.messages.INVALID_VALUE_IBAN"),
                  10
                );
              } else if (resp.data.message.includes("Invalid bic")) {
                message.error(
                  this.props.t("beneficiaries.messages.INVALID_VALUE_BIC"),
                  10
                );
              } else if (resp.data.message.includes("Invalid sort code")) {
                message.error(
                  this.props.t(
                    "beneficiaries.messages.INVALID_VALUE_SORT_CODE"
                  ),
                  10
                );
              } else if (resp.data.message.includes("Invalid account number")) {
                message.error(
                  this.props.t(
                    "beneficiaries.messages.INVALID_VALUE_ACCOUNT_NUMBER"
                  ),
                  10
                );
              } else {
                message.error(this.props.t(resp.data.message), 10);
              }
            } else if (
              resp.data.error_details.validation_error === "AML_CHECK_REJECTED"
            ) {
              message.error(
                this.props.t("beneficiaries.messages.AML_CHECK_REJECTED"),
                10
              );
            } else {
              message.error(
                this.props.t("beneficiaries.messages." + messageError),
                10
              );
            }
          } else if (resp.data.error_details.payload_error !== undefined) {
            let messageError = resp.data.error_details.payload_error;
            message.error(
              this.props.t("beneficiaries.messages." + messageError),
              10
            );
          } else {
            message.error(resp.data.message);
          }
        } else if (resp.status === 403) {
          this.setState({ loading: false });
          message.error(this.props.t("beneficiaries.messages.accessError"));
        } else if (
          resp.status === 401 ||
          resp.status === 422 ||
          resp.status === 404 ||
          resp.status === 500
        ) {
          this.setState({ loading: false });
          message.error(this.props.t("beneficiaries.messages.errorServer"));
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }
  handleNetwork = (value, option) => {
    if (this.props.beneficiary.networks !== undefined) {
      let findAddress = this.props.beneficiary.networks.find(
        (element) => element.network === value
      );
      this.setState({ currentAddress: findAddress.address });
    }
    this.setState({ network: value });
  };
  render() {
    let title = "";
    if (this.props.currencySelect.type === "FIAT") {
      title = this.props.t("beneficiaries.editButtonAccount");
    } else {
      if (
        this.props.currencySelect.value !== "BTC" &&
        this.props.currencySelect.value !== "ETH"
      ) {
        title = this.props.t("beneficiaries.titleEditNetwork", {
          name: this.props.currencySelect.name,
          network: this.state.network,
        });
      } else {
        title = this.props.t("beneficiaries.titleEdit", {
          name: this.props.currencySelect.name,
        });
      }
    }
    return (
      <Modal
        title={
          <Text
            style={{
              color: COLOR.PRIMARY_TEXT,
              fontSize: 18,
              fontWeight: 400,
              textAlign: "left",
            }}
          >
            {title}
          </Text>
        }
        closable={true}
        footer={null}
        width={500}
        bodyStyle={{
          margin: 0,
          padding: "24px",
          background: "#00001e",
          width: "100%",
          top: 80,
        }}
        maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
        closeIcon={<CloseOutlined style={{ color: COLOR.PRIMARY_TEXT }} />}
        open={this.props.modalEditBeneficiary}
        onOk={() => this.props.openModalEditBeneficiary()}
        onCancel={() => this.props.closeModalEditBeneficiary()}
      >
        {this.props.currencySelect.type === "FIAT" && (
          <div style={{ width: "100%" }}>
            {this.props.currencySelect.value === "EUR" && (
              <Form
                ref={formRef}
                name="editBeneficiaryEur"
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 24 },
                  xl: { span: 24 },
                  xxl: { span: 24 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 24 },
                  xl: { span: 24 },
                  xxl: { span: 24 },
                }}
                layout="vertical"
                style={{ width: "100%" }}
                onFinish={(values) => this.editBeneficiary(values)}
              >
                <Form.Item
                  name="description"
                  label={
                    <Text className="label-dark">
                      * {this.props.t("beneficiaries.description")}
                    </Text>
                  }
                  initialValue={this.props.beneficiary.description}
                >
                  <Input
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      background: COLOR.PRIMARY,
                    }}
                    size="large"
                    placeholder={this.props.t("beneficiaries.description")}
                    onChange={() =>
                      this.setState({
                        disableEdit:
                          formRef.current.getFieldValue("description") ===
                            this.props.beneficiary.description ||
                          formRef.current.getFieldValue("description") === "",
                      })
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="accountHolder"
                  label={
                    <Text className="label-dark">
                      * {this.props.t("beneficiaries.accountHolder")}
                    </Text>
                  }
                  initialValue={this.props.beneficiary.accountHolder}
                >
                  <Input
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      background: COLOR.PRIMARY,
                    }}
                    size="large"
                    disabled={this.props.beneficiary.type === "INTERNAL"}
                    placeholder={this.props.t("beneficiaries.accountHolder")}
                    onChange={() =>
                      this.setState({
                        disableEdit:
                          formRef.current.getFieldValue("accountHolder") ===
                            this.props.beneficiary.accountHolder ||
                          formRef.current.getFieldValue("accountHolder") === "",
                      })
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="iban"
                  label={<Text className="label-dark">* IBAN</Text>}
                  initialValue={this.props.beneficiary.iban}
                >
                  <Input
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      background: COLOR.PRIMARY,
                    }}
                    size="large"
                    placeholder="IBAN"
                    disabled={this.props.beneficiary.type === "INTERNAL"}
                    onChange={() =>
                      this.setState({
                        disableEdit:
                          formRef.current.getFieldValue("iban") ===
                            this.props.beneficiary.iban ||
                          formRef.current.getFieldValue("iban") === "",
                      })
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="bic"
                  label={<Text className="label-dark">* BIC</Text>}
                  initialValue={this.props.beneficiary.bic}
                >
                  <Input
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      background: COLOR.PRIMARY,
                    }}
                    size="large"
                    placeholder="BIC"
                    disabled={this.props.beneficiary.type === "INTERNAL"}
                    onChange={() =>
                      this.setState({
                        disableEdit:
                          formRef.current.getFieldValue("bic") ===
                            this.props.beneficiary.bic ||
                          formRef.current.getFieldValue("bic") === "",
                      })
                    }
                  />
                </Form.Item>
                <Button
                  block
                  type="primary"
                  style={{
                    height: "60px",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  htmlType="submit"
                  loading={this.state.loading}
                  disabled={this.state.disableEdit}
                >
                  {this.props.t("beneficiaries.editButtonAccount")}
                </Button>
              </Form>
            )}
            {this.props.currencySelect.value === "GBP" && (
              <Form
                ref={formRef}
                name="editBeneficiaryGbp"
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 24 },
                  xl: { span: 24 },
                  xxl: { span: 24 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 24 },
                  xl: { span: 24 },
                  xxl: { span: 24 },
                }}
                layout="vertical"
                style={{ width: "100%" }}
                onFinish={(values) => this.editBeneficiary(values)}
              >
                <Form.Item
                  name="description"
                  label={
                    <Text className="label-dark">
                      * {this.props.t("beneficiaries.description")}
                    </Text>
                  }
                  initialValue={this.props.beneficiary.description}
                >
                  <Input
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      background: COLOR.PRIMARY,
                    }}
                    size="large"
                    placeholder={this.props.t("beneficiaries.description")}
                    onChange={() =>
                      this.setState({
                        disableEdit:
                          formRef.current.getFieldValue("description") ===
                            this.props.beneficiary.description ||
                          formRef.current.getFieldValue("description") === "",
                      })
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="accountHolder"
                  label={
                    <Text className="label-dark">
                      * {this.props.t("beneficiaries.accountHolder")}
                    </Text>
                  }
                  initialValue={this.props.beneficiary.accountHolder}
                >
                  <Input
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      background: COLOR.PRIMARY,
                    }}
                    size="large"
                    placeholder={this.props.t("beneficiaries.accountHolder")}
                    disabled={this.props.beneficiary.type === "INTERNAL"}
                    onChange={() =>
                      this.setState({
                        disableEdit:
                          formRef.current.getFieldValue("accountHolder") ===
                            this.props.beneficiary.accountHolder ||
                          formRef.current.getFieldValue("accountHolder") === "",
                      })
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="sortCode"
                  label={<Text className="label-dark">* Sort Code</Text>}
                  initialValue={this.props.beneficiary.sortCode}
                  rules={[
                    {
                      required: false,
                      message: "The sort code must contain 6 numeric digits",
                      pattern: new RegExp(/^[0-9-]+$/),
                    },
                  ]}
                >
                  <Input
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      background: COLOR.PRIMARY,
                    }}
                    size="large"
                    placeholder="*Sort Code"
                    maxLength={8}
                    disabled={this.props.beneficiary.type === "INTERNAL"}
                    onChange={() =>
                      this.setState({
                        disableEdit:
                          formRef.current.getFieldValue("sortCode") ===
                            this.props.beneficiary.sortCode ||
                          formRef.current.getFieldValue("sortCode") === "",
                      })
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="accountNumber"
                  label={
                    <Text className="label-dark">
                      * {this.props.t("beneficiaries.accountNumber")}
                    </Text>
                  }
                  initialValue={this.props.beneficiary.account}
                  rules={[
                    {
                      required: false,
                      message:
                        "The account number must contain 8 numeric digits",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                >
                  <Input
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      background: COLOR.PRIMARY,
                    }}
                    maxLength={8}
                    size="large"
                    placeholder={this.props.t("beneficiaries.accountNumber")}
                    onChange={() =>
                      this.setState({
                        disableEdit:
                          formRef.current.getFieldValue("accountNumber") ===
                            this.props.beneficiary.account ||
                          formRef.current.getFieldValue("accountNumber") === "",
                      })
                    }
                    disabled={this.props.beneficiary.type === "INTERNAL"}
                  />
                </Form.Item>
                <Button
                  loading={this.state.loading}
                  disabled={this.state.disableEdit}
                  block
                  type="primary"
                  htmlType="submit"
                  style={{
                    height: "60px",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {this.props.t("beneficiaries.editButtonAccount")}
                </Button>
              </Form>
            )}
          </div>
        )}
        {this.props.currencySelect.type === "CRYPTO" && (
          <Form
            ref={formRef}
            name="editBeneficiaryCrypto"
            labelCol={{
              xs: { span: 24 },
              sm: { span: 24 },
              md: { span: 24 },
              lg: { span: 24 },
              xl: { span: 24 },
              xxl: { span: 24 },
            }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 24 },
              md: { span: 24 },
              lg: { span: 24 },
              xl: { span: 24 },
              xxl: { span: 24 },
            }}
            layout="vertical"
            style={{ width: "100%" }}
            onFinish={(values) => this.editBeneficiary(values)}
          >
            <Form.Item
              name="description"
              label={
                <Text className="label-dark">
                  * {this.props.t("beneficiaries.description")}
                </Text>
              }
              initialValue={this.props.beneficiary.description}
            >
              <Input
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  background: COLOR.PRIMARY,
                }}
                size="large"
                placeholder={this.props.t("beneficiaries.description")}
                onChange={() =>
                  this.setState({
                    disableEdit:
                      formRef.current.getFieldValue("description") ===
                        this.props.beneficiary.description ||
                      formRef.current.getFieldValue("description") === "",
                  })
                }
              />
            </Form.Item>
            {this.props.currencySelect.value !== "BTC" &&
              this.props.currencySelect.value !== "ETH" && (
                <Form.Item
                  label={
                    <Text className="label-dark">
                      * {this.props.t("beneficiaries.withdrawalNetwork")}
                    </Text>
                  }
                  name="network"
                  initialValue={this.props.networkSelect}
                >
                  <div
                    id="select-dark-add"
                    className="ant-select-selector ant-select"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Select
                      suffixIcon={<RightOutlined style={{ color: "#ccc" }} />}
                      size="large"
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        background: COLOR.PRIMARY,
                      }}
                      defaultValue={
                        this.props.networkSelect === undefined
                          ? this.props.beneficiary.networks[0].network
                          : this.props.networkSelect
                      }
                      disabled={
                        this.props.beneficiary.type !== "INTERNAL" ||
                        this.props.beneficiary.networks === undefined ||
                        this.props.beneficiary.networks?.length <= 1
                      }
                      value={this.state.network}
                      onChange={(value, option) =>
                        this.handleNetwork(value, option)
                      }
                    >
                      {this.props.assetActive.accountData.networks !==
                        undefined &&
                        this.props.assetActive.accountData.networks.length >
                          1 &&
                        this.props.assetActive.accountData.networks.map(
                          (item, i) => {
                            return (
                              <Option key={item.asset_id} value={item.network}>
                                {item.network}
                              </Option>
                            );
                          }
                        )}
                    </Select>
                  </div>
                </Form.Item>
              )}
            <Form.Item
              name="address"
              label={
                <Text className="label-dark">
                  * {this.props.t("beneficiaries.address")}
                </Text>
              }
              valuePropName={this.state.currentAddress}
            >
              <Input
                prefix={
                  <Image
                    src={this.props.currencySelect.icon}
                    width={20}
                    preview={false}
                  />
                }
                id="input-prefix-address"
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  background: COLOR.PRIMARY,
                }}
                size="large"
                placeholder={this.props.t("beneficiaries.address")}
                value={this.state.currentAddress}
                onChange={() =>
                  this.setState({
                    disableEdit:
                      formRef.current.getFieldValue("address") ===
                        this.props.beneficiary.address ||
                      formRef.current.getFieldValue("address") === "",
                  })
                }
                disabled={this.props.beneficiary.type === "INTERNAL"}
              />
            </Form.Item>
            <Button
              block
              disabled={this.state.disableEdit}
              type="primary"
              htmlType="submit"
              style={{
                height: "60px",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              loading={this.state.loading}
            >
              {this.props.t("beneficiaries.editButtonAddress")}
            </Button>
          </Form>
        )}
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.tokenAccess,
    editResp: state.beneficiaries.editedBeneficiary,
  };
};
const mapDispatchToProps = {
  editBeneficiary,
  currentToken,
};
const ModalEditBeneficiaryTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ModalEditBeneficiary)
);

export default ModalEditBeneficiaryTranslated;
