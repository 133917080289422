import {
  SEND_EMAIL,
  CONFIRM_EMAIL,
  NEW_PASSWORD,
} from "../actions/passwordRecoveryActions";
const initialState = {
  send: {},
  confirm: {},
  setPassword: {},
};
export function passwordRecovery(state = initialState, action) {
  switch (action.type) {
    case SEND_EMAIL:
      return Object.assign({}, state, { send: action.payload });
    case CONFIRM_EMAIL:
      return Object.assign({}, state, { confirm: action.payload });
    case NEW_PASSWORD:
      return Object.assign({}, state, { setPassword: action.payload });
    default:
      return state;
  }
}
