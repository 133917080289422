import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class RedirectLogout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div>{this.props.redirect && <Redirect to="/signin" />}</div>;
  }
}
export default RedirectLogout;
