import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;
const banking = {
  async getAccounts(idToken, accessToken) {
    const resp = await axios.get(BASE_URL + config.getAccounts, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async getFees(idToken, accessToken, currency, network) {
    const resp = await axios.get(
      BASE_URL +
        config.fees +
        "?currency_code=" +
        currency +
        "&network=" +
        network,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CLIENT-ID": idToken,
        },
      }
    );
    return resp;
  },
  async getLimits(idToken, accessToken, currency, network) {
    let url = BASE_URL + config.limits;
    if (currency !== undefined && network !== undefined) {
      url = url + "?currency_code=" + currency + "&network=" + network;
    }
    const resp = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async sendPayments(idToken, accessToken, body) {
    const resp = await axios.post(BASE_URL + config.sendPayments, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async confirmPayment(idToken, accessToken, body) {
    const resp = await axios.post(BASE_URL + config.confirmPayment, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async confirmRebalance(idToken, accessToken, body) {
    const resp = await axios.post(BASE_URL + config.rebalance, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
};
export default banking;
