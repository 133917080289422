import { Ability, AbilityBuilder } from "@casl/ability";
import { store } from "../redux/store";

const ability = new Ability();
// eslint-disable-next-line
export default (action, subject) => {
  return ability.can(action, subject);
};

store.subscribe(() => {
  let auth = store.getState().authUser.userData?.data?.data?.user;
  ability.update(defineRulesFor(auth));
});

const defineRulesFor = (user) => {
  const role = user?.role;
  const permissions = user?.role_permissions;
  const { can, rules } = new AbilityBuilder();

  if (permissions) {
    permissions.forEach((p) => {
      let per = p.split("_");
      let per2 = per[2] !== undefined ? "_" + per[2] : "";
      let per3 = per[3] !== undefined ? "_" + per[3] : "";
      can(per[0], per[1] + per2 + per3);
    });
  }
  if (role === "MASTER") {
    let arrayPermissions = [
      "ADD_BENEFICIARIES",
      "EDIT_BENEFICIARIES",
      "DELETE_BENEFICIARIES",
      "EXCHANGE_SELL",
      "EXCHANGE_BUY",
      "EXCHANGE_SWAP",
      "EXCHANGE_FX",
      "CREATE_TRANSACTIONS",
      "MANAGE_USERS",
      "EDIT_PREFERENCES",
      "EDIT_PROFILE",
      "GET_AUDIT_LOGS",
      "GET_MARKET_INFO",
      "GET_TRANSACTIONS",
      "GET_TRANSACTIONS_EXCHANGE_ORDERS",
      "GET_BALANCE_TIMELINE",
      "EDIT_USERS",
      "DELETE_USERS",
      "GET_USERS",
      "CREATE_USERS",
      "SET_STATUS_TRANSACTIONS",
    ];

    arrayPermissions.forEach((p) => {
      let per = p.split("_");
      let per2 = per[2] !== undefined ? "_" + per[2] : "";
      let per3 = per[3] !== undefined ? "_" + per[3] : "";
      can(per[0], per[1] + per2 + per3);
    });
  }
  return rules;
};
