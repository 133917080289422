import transactions from "../../services/transactions";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";
export const GET_ALL_TRANSACTIONS_FILTER = "GET_ALL_TRANSACTIONS_FILTER";
export const CURRENT_FILTERS = "CURRENT_FILTERS";
export const GET_TRANSACTIONS_EXCHANGE_ORDERS =
  "GET_TRANSACTIONS_EXCHANGE_ORDERS";
export const CANCEL_TRANSACTION_ACTION = "CANCEL_TRANSACTION_ACTION";
export function getTransactionsCurrency(id, token, currency, lastId) {
  return async (dispatch, getState) => {
    try {
      const response = await transactions.getTransactionsByCurrency(
        id,
        token,
        currency,
        lastId
      );

      dispatch({
        type: GET_TRANSACTIONS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_TRANSACTIONS,
        payload: error.response,
      });
    }
  };
}
export function getAllTransactions(id, token, lastId, status) {
  return async (dispatch, getState) => {
    try {
      const response = await transactions.getAllTransactions(
        id,
        token,
        lastId,
        status
      );
      dispatch({
        type: GET_ALL_TRANSACTIONS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_TRANSACTIONS,
        payload: error.response,
      });
    }
  };
}
export function getAllTransactionsOrders(id, token) {
  return async (dispatch, getState) => {
    try {
      const response = await transactions.getAllTransactionsAndOrders(
        id,
        token
      );
      //   console.log("trasactions response", response);
      dispatch({
        type: GET_TRANSACTIONS_EXCHANGE_ORDERS,
        payload: response,
      });
    } catch (error) {
      //    console.log("trasactions", error);
      dispatch({
        type: GET_TRANSACTIONS_EXCHANGE_ORDERS,
        payload: error.response,
      });
    }
  };
}
export function getAllTransactionsByFilter(
  id,
  token,
  currency,
  type,
  minDate,
  maxDate,
  lastId,
  transactionId
) {
  return async (dispatch, getState) => {
    try {
      const response = await transactions.getAllTransactionsByFilter(
        id,
        token,
        currency,
        type,
        minDate,
        maxDate,
        lastId,
        transactionId
      );
      dispatch({
        type: GET_ALL_TRANSACTIONS_FILTER,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_TRANSACTIONS_FILTER,
        payload: error.response,
      });
    }
  };
}
export function currentFilters(filters) {
  return (dispatch, getState) => {
    dispatch({
      type: CURRENT_FILTERS,
      payload: filters,
    });
  };
}
export function transactionCancelation(id, token, tx_id) {
  return async (dispatch, getState) => {
    try {
      const response = await transactions.transactionCancelation(
        id,
        token,
        tx_id
      );
      //   console.log("trasactions response", response);
      dispatch({
        type: CANCEL_TRANSACTION_ACTION,
        payload: response,
      });
    } catch (error) {
      //    console.log("trasactions", error);
      dispatch({
        type: CANCEL_TRANSACTION_ACTION,
        payload: error.response,
      });
    }
  };
}
