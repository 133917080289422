import React, { Component } from "react";
import {
  Space,
  Typography,
  Row,
  Col,
  Card,
  Divider,
  Form,
  Select,
  Image,
  Button,
  message,
  Spin,
} from "antd";
import { withTranslation } from "react-i18next";
import "./receive.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import COLOR from "../../const/colors";
import {
  CopyOutlined,
  RightOutlined,
  CheckOutlined,
  InfoCircleOutlined,
  GlobalOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { asset } from "../../common/assets";
import { connect } from "react-redux";
import { getAccounts, getFees } from "../../redux/actions/bankingActions";
import { currentToken } from "../../redux/actions/tokenActions";
import QRCode from "react-qr-code";
import NumberFormat from "react-number-format";
const { Option, OptGroup } = Select;
const { Text, Paragraph } = Typography;

class Receive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listAssetFiat: [],
      listAssetCrypto: [],
      listAsset: [],
      assetActive: "",
      accounts: [],
      accountSelected: "",
      accountHolder: "",
      successCopy: false,
      labelActive: "",
      nativeCurrency: "",
      nativeSymbol: "",
      nativeBalance: "",
      networkSelected: "",
      loadingFees: false,
      fee: "",
    };
  }
  componentDidMount() {
    this.bankingData();
    this.filterCurrencyAvailable();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.assetModal !== this.props.assetModal) {
      // console.log("aqui did update assetmodal");
      // let assetSelect =
      //   this.props.assetModal.key + "_" + this.props.assetModal.id;
      // this.handleAsset(assetSelect);
    }
    if (this.props.source === "wallet") {
      if (prevProps.asset !== this.props.asset) {
        let assetSelect = this.props.asset.key + "_" + this.props.asset.id;
        this.handleAsset(assetSelect);
      }
    }
  }
  handleAsset(value) {
    let currency = value.split("_")[0];
    let id = value.split("_")[1];
    let multiple = false;
    if (this.props.source !== "wallet") {
      this.props.handleSelect(currency, id);
    }
    let data = asset.assets;
    let assetSelected = data.find((element) => {
      return element.value === currency;
    });
    let account = this.state.accounts.find((element) => {
      return id === "null" ? element.currency === currency : element.id === id;
    });
    let findBalanceNative = {};
    let nativeBalanceInfo = this.props.banking.data.data.native_balances;
    findBalanceNative = nativeBalanceInfo.currencies.find(
      (element) => element.currency === currency
    );
    if (findBalanceNative.accounts?.length > 1) {
      multiple = true;
    }
    if (findBalanceNative.accounts !== undefined && id && id !== "null") {
      findBalanceNative = findBalanceNative.accounts.find(
        (element) => element._id === id
      );
    }
    if (assetSelected.type === "CRYPTO") {
      this.handleNetwork(account.networks[0]);
    }
    this.setState(
      {
        networkSelected:
          assetSelected.type === "CRYPTO" ? account.networks[0] : "",
        assetActive: assetSelected,
        accountSelected: account,
        labelActive: multiple ? account.alias : account.name,
        nativeCurrency: nativeBalanceInfo.native_currency,
        nativeSymbol: nativeBalanceInfo.native_symbol,
        nativeBalance: findBalanceNative?.native_balance,
      },
      () => {
        this.getFees();
      }
    );
  }
  filterCurrencyAvailable() {
    let accountsInfo = this.props.banking.data.data.accounts;
    accountsInfo = accountsInfo.filter((account) => {
      return account.status === "ACTIVE";
    });

    let arrayCurrencies = [];
    for (let objCurrency of accountsInfo) {
      let objCurrencies = {};
      let data = asset.assets.find(
        (element) => element.value === objCurrency.currency
      );
      objCurrencies.icon = data.icon;
      objCurrencies.symbol = data.symbol;
      objCurrencies.name = data.name;
      objCurrencies.value = objCurrency.currency;
      objCurrencies.type = objCurrency.type;
      objCurrencies.id = objCurrency._id;
      let findLengthAccounts = accountsInfo.filter(
        (element) => element.currency === objCurrency.currency
      );
      objCurrencies.alias =
        findLengthAccounts !== undefined && findLengthAccounts?.length > 1
          ? objCurrency.alias
          : null;

      arrayCurrencies.push(objCurrencies);
    }

    let fiat = arrayCurrencies.filter((element) => element.type === "FIAT");
    let crypto = arrayCurrencies.filter((element) => element.type === "CRYPTO");
    this.setState({
      listAssetFiat: fiat,
      listAssetCrypto: crypto,
      listAsset: arrayCurrencies,
    });
  }
  bankingData() {
    let accountsInfo = this.props.banking.data.data;
    let userType = this.props.dataUser.data?.data?.user?.type;
    let holder = "";
    if (
      userType === "DAMPAY_INDIVIDUAL_LITE" ||
      userType === "DAMPAY_BUSINESS_LITE"
    ) {
      holder = "Digital Asset Management LTD";
    } else {
      if (accountsInfo.account_holder.last_name !== undefined) {
        holder =
          accountsInfo.account_holder.name +
          " " +
          accountsInfo.account_holder.last_name;
      } else {
        holder = accountsInfo.account_holder.name;
      }
    }

    let arrayAccounts = [];
    for (let obj of accountsInfo.accounts) {
      if (obj.status === "ACTIVE") {
        let dataAccounts = {};
        dataAccounts.type = obj.type;
        dataAccounts.id = obj._id;
        dataAccounts.currency = obj.currency;
        dataAccounts.balance = obj.balance;
        dataAccounts.available_balance = obj.available_balance;
        dataAccounts.alias =
          accountsInfo.accounts.filter((acc) => {
            return acc.currency === obj.currency && acc.status === "ACTIVE";
          }).length > 1
            ? obj.alias
            : obj.currency_name;
        dataAccounts.name = obj.currency_name;
        let data = obj.account_data;
        if (obj.type === "CRYPTO") {
          dataAccounts.networks = data.networks;
          if (data.networks.length === 1) {
            for (let dataCrypto of data.networks) {
              dataAccounts.address = dataCrypto.address;
              dataAccounts.network = dataCrypto.network;
            }
          }
        } else {
          if (data.account_number !== undefined) {
            dataAccounts.accountNumber = data.account_number;
            dataAccounts.sortCode = data.sort_code;
            dataAccounts.trackingReference = data.tracking_ref_escrow;
          }
          if (data.bic !== undefined) {
            dataAccounts.bic = data.bic;
            dataAccounts.iban = data.iban;
            dataAccounts.trackingReference = data.tracking_ref_escrow;
          }
        }

        arrayAccounts.push(dataAccounts);
      }
    }
    this.setState(
      {
        accounts: arrayAccounts,
        accountSelected: arrayAccounts[0],
        accountHolder: holder,
      },
      () => {
        if (this.props.source === "wallet") {
          let assetSelect = this.props.asset.key + "_" + this.props.asset.id;
          this.handleAsset(assetSelect);
        } else {
          if (this.props.assetModal) {
            let assetSelect =
              this.props.assetModal.key + "_" + this.props.assetModal.id;
            this.handleAsset(assetSelect);
          } else {
            let value = arrayAccounts[0].currency + "_" + arrayAccounts[0].id;
            this.props.handleSelect(
              arrayAccounts[0].currency,
              arrayAccounts[0].id
            );
            this.handleAsset(value);
          }
        }
      }
    );
  }
  async getFees() {
    try {
      this.setState({ loadingFees: true });
      let network = "";
      if (this.state.assetActive.type === "CRYPTO") {
        if (
          this.state.assetActive.value === "BTC" ||
          this.state.assetActive.value === "ETH"
        ) {
          network = "BASE_ASSET";
        } else {
          network = this.state.networkSelected.network;
        }
      }
      await this.props.getFees(
        this.props.token.clientId,
        this.props.token.current,
        this.state.assetActive.value,
        network
      );
      let resp = this.props.respFees;
      if (resp.headers !== undefined) {
        let newAccessToken = resp.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (resp.status === 200) {
        let dataFees = resp.data.data.fees;
        let resultFee = "";
        resultFee = dataFees.find((element) => element.type === "EXTERNAL");
        this.findFee(resultFee, network);
      } else if (
        resp.status === 401 ||
        resp.status === 422 ||
        resp.status === 403 ||
        resp.status === 404 ||
        resp.status === 500
      ) {
        this.setState({ fees: null });
        this.setState({ loadingFees: true });
      }
    } catch (error) {
      this.setState({ fees: null });
      this.setState({ loadingFees: true });
    }
  }
  handleNetwork = (value, option, info) => {
    let networkFound = value;
    if (info === true) {
      networkFound = this.state.accountSelected.networks.find(
        (element) => element.network === value
      );
    }
    this.setState({ networkSelected: networkFound });
  };
  copyAccount() {
    message.success(this.props.t("receive.copied"));
  }
  findFee(fee, network) {
    let dataUser = this.props.dataUser.data?.data?.user;
    let feeType = dataUser?.trading_fee_type;
    let networkSelected = network;
    let showFee = "";
    if (feeType === "TIERS") {
      if (fee.value_type === "PERCENTAGE") {
        if (fee.deposit_amount !== "0") {
          showFee =
            fee.deposit_amount +
            " % " +
            "(min " +
            this.state.assetActive.symbol +
            " " +
            fee.min_deposit_amount +
            ")";
        } else {
          showFee = this.state.assetActive.symbol + " " + fee.deposit_amount;
        }
      } else if (fee.value_type === "FIXED") {
        if (this.state.assetActive.type === "CRYPTO") {
          if (fee.type === "INTERNAL") {
            showFee = fee.deposit_amount;
          } else {
            let findFee = fee.networks.find(
              (element) => element.network === networkSelected
            );
            if (findFee !== undefined) {
              showFee =
                this.state.assetActive.symbol + " " + findFee.deposit_amount;
            }
          }
        } else {
          showFee = this.state.assetActive.symbol + " " + fee.deposit_amount;
        }
      }
      this.setState({ fee: showFee, loadingFees: false });
    } else if (feeType === "FIXED") {
      if (this.state.assetActive.type === "CRYPTO") {
        let findFee = fee.networks.find(
          (element) => element.network === networkSelected
        );
        showFee = findFee.deposit_amount;
      } else {
        showFee = fee.deposit_amount;
      }
      this.setState({ fee: showFee, loadingFees: false });
    }
  }
  render() {
    let dataUser = this.props.dataUser.data?.data?.user;
    let feeType = dataUser?.trading_fee_type;
    let userType = this.props.dataUser.data?.data?.user?.type;
    let minDeposit = parseFloat(this.props.limits.min_deposit);
    let valueCopy = "";
    if (this.state.accountSelected.currency === "GBP") {
      valueCopy =
        this.props.t("receive.holder") +
        ": " +
        this.state.accountHolder +
        "\n" +
        this.props.t("receive.accountNumber") +
        ": " +
        this.state.accountSelected.accountNumber +
        "\nSort code: " +
        this.state.accountSelected.sortCode;
    } else if (this.state.accountSelected.currency === "EUR") {
      valueCopy =
        this.props.t("receive.holder") +
        ": " +
        this.state.accountHolder +
        "\nIBAN: " +
        this.state.accountSelected.iban +
        "\nBIC: " +
        this.state.accountSelected.bic;
    }
    if (
      userType === "DAMPAY_INDIVIDUAL_LITE" ||
      userType === "DAMPAY_BUSINESS_LITE"
    ) {
      valueCopy =
        valueCopy +
        "\nTracking reference: " +
        this.state.accountSelected.trackingReference;
    }
    let formSelect = (
      <Form.Item
        className="item-select-dark"
        label={
          <Text className="label-dark">{this.props.t("receive.asset")}</Text>
        }
      >
        <div
          style={{
            display: "inline-flex",
            float: "left",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Image src={this.state.assetActive.icon} width={30} preview={false} />
          <div
            id="select-dark-crypto"
            className={
              this.props.asset !== "" && this.props.source === "wallet"
                ? "ant-select-selector ant-select ant-select-disabled"
                : "ant-select-selector ant-select"
            }
          >
            <Select
              showSearch
              filterOption={(input, option) => {
                if (option.value) {
                  let val = option.value.split("_")[2];
                  return val.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
              }}
              suffixIcon={<RightOutlined style={{ color: "#ccc" }} />}
              size="large"
              value={this.state.labelActive}
              bordered={false}
              optionLabelProp="label"
              disabled={this.props.source === "wallet" ? true : false}
              onChange={(value) => this.handleAsset(value)}
              dropdownStyle={{
                width: this.props.source !== "wallet" ? "170px" : "",
                minWidth: this.props.source !== "wallet" ? "170px" : "",
              }}
            >
              {this.state.listAssetFiat.length !== 0 && (
                <OptGroup label="Fiat">
                  {this.state.listAssetFiat.map((item, i) => {
                    return (
                      <Option
                        key={item.id}
                        value={
                          item.alias !== null
                            ? item.value + "_" + item.id + "_" + item.alias
                            : item.value + "_" + item.id + "_" + item.name
                        }
                      >
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            style={{ paddingRight: 5 }}
                            src={item.icon}
                            width={30}
                            preview={false}
                          />
                          {item.alias !== null ? item.alias : item.name}
                        </div>
                      </Option>
                    );
                  })}
                </OptGroup>
              )}
              {this.state.listAssetCrypto.length !== 0 && (
                <OptGroup label="Crypto">
                  {this.state.listAssetCrypto.map((item, i) => {
                    return (
                      <Option
                        key={item.id}
                        value={item.value + "_" + item.id + "_" + item.name}
                      >
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            style={{ paddingRight: 5 }}
                            src={item.icon}
                            width={30}
                            preview={false}
                          />
                          {item.name}
                        </div>
                      </Option>
                    );
                  })}
                </OptGroup>
              )}
            </Select>
          </div>
        </div>
      </Form.Item>
    );
    return (
      <div>
        <Row
          gutter={[48, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}
          justify="center"
        >
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              <Row justify="center">
                <br />
                {this.state.accountSelected.type !== undefined &&
                  this.state.accountSelected.type === "CRYPTO" && (
                    <Card
                      className="card-form-receive"
                      bodyStyle={{ padding: 0 }}
                    >
                      <Space
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                        className="image-receive"
                      >
                        <QRCode
                          value={
                            this.state.networkSelected.address !== undefined
                              ? this.state.networkSelected.address
                              : ""
                          }
                          className="qr-style"
                          size={230}
                        />
                      </Space>

                      <Divider
                        style={{ background: COLOR.PRIMARY_TEXT, margin: 0 }}
                      />
                      <Form
                        labelAlign="left"
                        labelCol={{
                          xs: { span: 24 },
                          sm: { span: 24 },
                          md: { span: 8 },
                          lg: { span: 8 },
                          xl: { span: 10 },
                          xxl: { span: 12 },
                        }}
                        wrapperCol={{
                          xs: { span: 24 },
                          sm: { span: 24 },
                          md: { span: 16 },
                          lg: { span: 16 },
                          xl: { span: 14 },
                          xxl: { span: 12 },
                        }}
                        layout="horizontal"
                      >
                        <Form.Item
                          className="item-send-dark"
                          label={
                            <Text className="label-dark">
                              {this.props.t("receive.network")}
                            </Text>
                          }
                          tooltip={{
                            title: this.props.t("receive.messageNetwork", {
                              key:
                                this.state.networkSelected.network !== undefined
                                  ? this.state.networkSelected.network ===
                                    "BASE_ASSET"
                                    ? this.state.assetActive.value
                                    : this.state.assetActive.value +
                                      " (" +
                                      this.state.networkSelected.network +
                                      ") "
                                  : "",
                            }),
                            icon: (
                              <InfoCircleOutlined
                                style={{
                                  color: COLOR.SECONDARY_TEXT,
                                  fontSize: 18,
                                }}
                              />
                            ),
                          }}
                        >
                          {this.state.accountSelected.networks.length > 1 ? (
                            <div
                              id="select-dark-crypto"
                              className={
                                this.props.asset !== ""
                                  ? "ant-select-selector ant-select"
                                  : "ant-select-selector ant-select ant-typography"
                              }
                            >
                              {" "}
                              <Row>
                                <Col
                                  span={2}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <GlobalOutlined
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                    }}
                                  />
                                </Col>
                                <Col
                                  span={22}
                                  //offset={1}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Select
                                    suffixIcon={
                                      <RightOutlined
                                        style={{ color: "#ccc" }}
                                      />
                                    }
                                    size="large"
                                    bordered={false}
                                    style={{
                                      color: "#ccc",
                                      fontWeight: "bold",
                                    }}
                                    placeholder={this.props.t("send.network")}
                                    onChange={(value, option) =>
                                      this.handleNetwork(value, option, true)
                                    }
                                    defaultValue={
                                      this.state.networkSelected.network ===
                                      "BASE_ASSET"
                                        ? this.state.assetActive.value
                                        : this.state.networkSelected.network
                                    }
                                  >
                                    {this.state.accountSelected.networks
                                      .length !== 0 &&
                                      this.state.accountSelected.networks.map(
                                        (item, i) => {
                                          return (
                                            <Option
                                              key={item.asset_id}
                                              value={item.network}
                                            >
                                              <div>
                                                <Text
                                                  style={{
                                                    color: "#75787C",
                                                    fontSize: 15,
                                                  }}
                                                >
                                                  {item.network === "BASE_ASSET"
                                                    ? this.state.assetActive
                                                        .value
                                                    : item.network}
                                                </Text>
                                              </div>
                                            </Option>
                                          );
                                        }
                                      )}
                                  </Select>
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            <Paragraph className="input-dark" ellipsis={true}>
                              {this.state.networkSelected.network !== undefined
                                ? this.state.networkSelected.network ===
                                  "BASE_ASSET"
                                  ? this.state.assetActive.value
                                  : this.state.networkSelected.network
                                : ""}
                            </Paragraph>
                          )}
                        </Form.Item>
                        <Divider
                          style={{ background: COLOR.PRIMARY_TEXT, margin: 0 }}
                        />
                        <Form.Item
                          className="item-send-dark"
                          label={
                            <Text className="label-dark">
                              {this.props.t("receive.address")}
                            </Text>
                          }
                        >
                          <Paragraph
                            className="input-dark"
                            style={{ whiteSpace: "break-spaces" }}
                            ellipsis={false}
                            copyable={{
                              icon: [
                                <CopyOutlined className="icon-copy-address" />,
                                <CheckOutlined className="icon-copied-address" />,
                              ],
                              tooltips: false,
                            }}
                          >
                            {this.state.networkSelected.address !== undefined &&
                              this.state.networkSelected.address}
                          </Paragraph>
                        </Form.Item>

                        <Divider
                          style={{ background: COLOR.PRIMARY_TEXT, margin: 0 }}
                        />
                        {formSelect}
                        {/* <Form.Item
                          className="item-select-dark"
                          label={
                            <Text className="label-dark">
                              {this.props.t("receive.asset")}
                            </Text>
                          }
                        >
                          <div
                            style={{
                              display: "inline-flex",
                              float: "left",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              src={this.state.assetActive.icon}
                              width={30}
                              preview={false}
                            />
                            <div
                              id="select-dark-crypto"
                              className={
                                this.props.asset !== "" &&
                                this.props.source === "wallet"
                                  ? "ant-select-selector ant-select ant-select-disabled"
                                  : "ant-select-selector ant-select"
                              }
                            >
                              <Select
                                showSearch
                                filterOption={(input, option) => {
                                  if (option.value) {
                                    let val = option.value.split("_")[2];
                                    return (
                                      val
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    );
                                  }
                                }}
                                suffixIcon={
                                  <RightOutlined style={{ color: "#ccc" }} />
                                }
                                size="large"
                                value={this.state.labelActive}
                                bordered={false}
                                disabled={
                                  this.props.source === "wallet" ? true : false
                                }
                                onChange={this.handleAsset.bind(this)}
                                dropdownStyle={{
                                  width:
                                    this.props.source !== "wallet"
                                      ? "170px"
                                      : "",
                                  minWidth:
                                    this.props.source !== "wallet"
                                      ? "170px"
                                      : "",
                                }}
                              >
                                {this.state.listAssetFiat.length !== 0 && (
                                  <OptGroup label="Fiat">
                                    {this.state.listAssetFiat.map((item, i) => {
                                      return (
                                        <Option
                                          key={item.id}
                                          value={
                                            item.alias !== null
                                              ? item.value +
                                                "_" +
                                                item.id +
                                                "_" +
                                                item.alias
                                              : item.value +
                                                "_" +
                                                item.id +
                                                "_" +
                                                item.name
                                          }
                                        >
                                          <div
                                            style={{
                                              display: "inline-flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Image
                                              style={{ paddingRight: 5 }}
                                              src={item.icon}
                                              width={30}
                                              preview={false}
                                            />
                                            {item.alias !== null
                                              ? item.alias
                                              : item.name}
                                          </div>
                                        </Option>
                                      );
                                    })}
                                  </OptGroup>
                                )}
                                {this.state.listAssetCrypto.length !== 0 && (
                                  <OptGroup label="Crypto">
                                    {this.state.listAssetCrypto.map(
                                      (item, i) => {
                                        return (
                                          <Option
                                            key={item.id}
                                            value={
                                              item.value +
                                              "_" +
                                              item.id +
                                              "_" +
                                              item.name
                                            }
                                          >
                                            <div
                                              style={{
                                                display: "inline-flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Image
                                                style={{ paddingRight: 5 }}
                                                src={item.icon}
                                                width={30}
                                                preview={false}
                                              />
                                              {item.name}
                                            </div>
                                          </Option>
                                        );
                                      }
                                    )}
                                  </OptGroup>
                                )}
                              </Select>
                            </div>
                          </div>
                        </Form.Item> */}
                      </Form>
                    </Card>
                  )}
                {this.state.accountSelected.type !== undefined &&
                  this.state.accountSelected.type === "FIAT" && (
                    <Card
                      className="card-form-receive"
                      bodyStyle={{ padding: 0 }}
                    >
                      <Form
                        labelAlign="left"
                        labelCol={{
                          xs: { span: 24 },
                          sm: { span: 24 },
                          md: { span: 8 },
                          lg: { span: 8 },
                          xl: { span: 10 },
                          xxl: { span: 12 },
                        }}
                        wrapperCol={{
                          xs: { span: 24 },
                          sm: { span: 24 },
                          md: { span: 16 },
                          lg: { span: 16 },
                          xl: { span: 14 },
                          xxl: { span: 12 },
                        }}
                        layout="horizontal"
                      >
                        <Form.Item
                          className="item-send-dark"
                          label={
                            <Text
                              className="label-dark"
                              style={
                                this.props.t("receive.holder").length > 15 ||
                                window.screen.width <= 1440
                                  ? {
                                      width: "100%",
                                      height: 55,
                                      whiteSpace: "break-spaces",
                                    }
                                  : this.state.accountHolder.length > 20
                                  ? {
                                      width: "100%",
                                      height: 25,
                                      whiteSpace: "break-spaces",
                                      lineHeight: "40px",
                                    }
                                  : ""
                              }
                            >
                              {this.props.t("receive.holder")}
                            </Text>
                          }
                        >
                          <Text
                            className="input-dark-fiat"
                            style={
                              this.state.accountHolder.length > 20
                                ? {
                                    width: 100,
                                    height: 55,
                                    whiteSpace: "break-spaces",
                                  }
                                : undefined
                            }
                          >
                            {this.state.accountHolder}
                          </Text>
                        </Form.Item>
                        <Divider
                          style={{
                            background: COLOR.PRIMARY_TEXT,
                            margin: 0,
                          }}
                        />
                        {this.state.accountSelected.currency === "GBP" && (
                          <div>
                            <Form.Item
                              className="item-send-dark"
                              style={{ overflow: "inherit" }}
                              label={
                                <Paragraph
                                  className="label-dark"
                                  style={
                                    this.props.t("receive.accountNumber")
                                      .length > 15 ||
                                    window.screen.width <= 1440
                                      ? {
                                          width: 100,
                                          height: 55,
                                          marginBottom: 0,
                                          whiteSpace: "normal",
                                        }
                                      : undefined
                                  }
                                >
                                  {this.props.t("receive.accountNumber")}
                                </Paragraph>
                              }
                            >
                              <Paragraph className="input-dark-fiat">
                                {this.state.accountSelected.accountNumber !==
                                undefined
                                  ? this.state.accountSelected.accountNumber
                                  : ""}
                              </Paragraph>
                            </Form.Item>
                            <Divider
                              style={{
                                background: COLOR.PRIMARY_TEXT,
                                margin: 0,
                              }}
                            />
                            <Form.Item
                              className="item-send-dark"
                              label={
                                <Text className="label-dark">
                                  {this.props.t("receive.sortCode")}
                                </Text>
                              }
                            >
                              <Paragraph className="input-dark-fiat">
                                {this.state.accountSelected.sortCode !==
                                undefined
                                  ? this.state.accountSelected.sortCode
                                  : ""}
                              </Paragraph>
                            </Form.Item>
                            {(userType === "DAMPAY_INDIVIDUAL_LITE" ||
                              userType === "DAMPAY_BUSINESS_LITE") && (
                              <>
                                <Divider
                                  style={{
                                    background: COLOR.PRIMARY_TEXT,
                                    margin: 0,
                                  }}
                                />
                                <Form.Item
                                  className="item-send-dark"
                                  style={{ overflow: "inherit" }}
                                  label={
                                    <Text className="label-dark">
                                      Tracking Reference
                                    </Text>
                                  }
                                >
                                  <Paragraph className="input-dark-fiat">
                                    {this.state.accountSelected
                                      .trackingReference !== undefined
                                      ? this.state.accountSelected
                                          .trackingReference
                                      : ""}
                                  </Paragraph>
                                </Form.Item>
                              </>
                            )}
                          </div>
                        )}
                        {this.state.accountSelected.currency === "EUR" && (
                          <div>
                            <Form.Item
                              className="item-send-dark"
                              label={<Text className="label-dark">BIC</Text>}
                            >
                              <Paragraph className="input-dark-fiat">
                                {this.state.accountSelected.bic !== undefined
                                  ? this.state.accountSelected.bic
                                  : ""}
                              </Paragraph>
                            </Form.Item>
                            <Divider
                              style={{
                                background: COLOR.PRIMARY_TEXT,
                                margin: 0,
                              }}
                            />
                            <Form.Item
                              className="item-send-dark"
                              label={<Text className="label-dark">IBAN</Text>}
                            >
                              <Paragraph className="input-dark-fiat">
                                {this.state.accountSelected.iban !== undefined
                                  ? this.state.accountSelected.iban
                                  : ""}
                              </Paragraph>
                            </Form.Item>
                            {(userType === "DAMPAY_INDIVIDUAL_LITE" ||
                              userType === "DAMPAY_BUSINESS_LITE") && (
                              <>
                                <Divider
                                  style={{
                                    background: COLOR.PRIMARY_TEXT,
                                    margin: 0,
                                  }}
                                />
                                <Form.Item
                                  className="item-send-dark"
                                  label={
                                    <Text className="label-dark">
                                      Tracking Reference
                                    </Text>
                                  }
                                >
                                  <Paragraph className="input-dark-fiat">
                                    {this.state.accountSelected
                                      .trackingReference !== undefined
                                      ? this.state.accountSelected
                                          .trackingReference
                                      : ""}
                                  </Paragraph>
                                </Form.Item>
                              </>
                            )}
                          </div>
                        )}

                        <Divider
                          style={{ background: COLOR.PRIMARY_TEXT, margin: 0 }}
                        />
                        {formSelect}
                      </Form>
                    </Card>
                  )}
              </Row>
              {(userType === "DAMPAY_INDIVIDUAL_LITE" ||
                userType === "DAMPAY_BUSINESS_LITE") &&
                this.state.accountSelected.type !== undefined &&
                this.state.accountSelected.type !== "CRYPTO" && (
                  <Row justify="center" style={{ marginTop: "1rem" }}>
                    <Text style={{ color: "#898996", fontSize: 14 }}>
                      Please include the tracking reference on the reference/
                      memo/ note section of your bank's payment instruction.
                      Otherwise, your funds could be lost.
                    </Text>
                  </Row>
                )}
              {this.state.accountSelected.type !== undefined &&
                this.state.accountSelected.type !== "CRYPTO" && (
                  <Row justify="center" style={{ marginTop: "1rem" }}>
                    <CopyToClipboard
                      text={valueCopy}
                      onCopy={() => this.copyAccount()}
                    >
                      <Button
                        block
                        type="primary"
                        style={{
                          height: "60px",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        {this.props.t("receive.copy")}
                      </Button>
                    </CopyToClipboard>
                  </Row>
                )}

              {this.props.limits.min_deposit !== undefined && (
                <Row justify="space-between">
                  <Text
                    style={{
                      color: "#898996",

                      fontSize: 14,
                    }}
                  >
                    {this.props.t("receive.minDeposit")}
                  </Text>
                  <Text
                    style={{
                      color: "#898996",
                      marginLeft: 10,
                      fontSize: 14,
                    }}
                  >
                    <NumberFormat
                      thousandSeparator={true}
                      displayType="text"
                      prefix={this.state.assetActive.symbol + " "}
                      decimalScale={
                        this.state.assetActive.type === "CRYPTO" ? 8 : 2
                      }
                      value={minDeposit}
                    />
                  </Text>
                </Row>
              )}
              <Row justify="space-between">
                <Text
                  style={{
                    color: "#898996",
                    fontSize: 14,
                    textAlign: "left",
                  }}
                >
                  {this.state.assetActive.value !== undefined
                    ? this.state.assetActive.value
                    : ""}{" "}
                  balance
                </Text>
                <Text style={{ color: "#898996", fontSize: 14 }}>
                  {this.state.assetActive.type === "CRYPTO" ? (
                    <NumberFormat
                      thousandSeparator={true}
                      displayType="text"
                      suffix={" " + this.state.assetActive.symbol}
                      decimalScale={8}
                      value={this.state.accountSelected.balance}
                    />
                  ) : (
                    <NumberFormat
                      thousandSeparator={true}
                      displayType="text"
                      prefix={this.state.assetActive.symbol + " "}
                      decimalScale={2}
                      value={this.state.accountSelected.balance}
                    />
                  )}{" "}
                  {this.state.assetActive.value !==
                    this.state.nativeCurrency && (
                    <NumberFormat
                      thousandSeparator={true}
                      displayType="text"
                      prefix={"≈ " + this.state.nativeSymbol + " "}
                      decimalScale={2}
                      value={parseFloat(this.state.nativeBalance)}
                    />
                  )}
                </Text>
              </Row>
              {feeType === "TIERS" && (
                <>
                  <Row justify="space-between">
                    <Text
                      style={{
                        color: "#898996",
                        fontSize: 14,
                        textAlign: "left",
                      }}
                    >
                      Deposit fee
                    </Text>
                    {this.state.loadingFees && (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ color: "#898996", fontSize: 14 }}
                            spin
                          />
                        }
                      />
                    )}
                    {!this.state.loadingFees && (
                      <Text style={{ color: "#898996", fontSize: 14 }}>
                        {this.state.fee}
                      </Text>
                    )}
                  </Row>
                </>
              )}
            </Space>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    banking: state.banking.accounts,
    asset: state.siderNavigate.asset,
    dataUser: state.authUser.userData,
    respFees: state.banking.fees,
    token: state.tokenAccess,
  };
};

const mapDispatchToProps = {
  getAccounts,
  getFees,
  currentToken,
};
const ReceiveTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Receive)
);

export default ReceiveTranslated;
