import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Modal } from "antd";
import ContainerSendOrReceive from "../../components/containerSendOrReceive/containerSendOrReceive";
import { CloseOutlined } from "@ant-design/icons";
class ModalSendOrReceive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetSelected: "",
    };
  }
  render() {
    return (
      <Modal
        closable={true}
        footer={null}
        closeIcon={
          <div id="icon-close-modal" className="anticon-close ant-modal-close">
            <CloseOutlined />
          </div>
        }
        width={430}
        bodyStyle={{
          margin: 0,
          padding: "0px 0px 24px 0px",
          background: "#00001e",
          width: "100%",
          top: 80,
        }}
        open={this.props.modalSendOrReceive}
        onOk={() => this.props.open()}
        onCancel={() => this.props.close()}
        maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
      >
        <ContainerSendOrReceive
          source={"header"}
          closeModal={this.props.close.bind(this)}
        />
      </Modal>
    );
  }
}
const ModalSendOrReceiveTranslated = withTranslation()(ModalSendOrReceive);

export default ModalSendOrReceiveTranslated;
