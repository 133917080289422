import {
  GET_NOTIFICATIONS,
  MARK_READ_NOTIFICATIONS,
} from "../actions/notificationsActions";
const initialState = {
  notifications: "",
  markRead: "",
};

export function loadNotifications(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return Object.assign({}, state, { notifications: action.payload });
    case MARK_READ_NOTIFICATIONS:
      return Object.assign({}, state, { markRead: action.payload });

    default:
      return state;
  }
}
