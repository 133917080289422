import servicesAudit from "../../services/audit";
export const GET_AUDIT_LOGS = "GET_AUDIT_LOGS";
export function getAuditLogs(id, token, lastId, type, email, minDate, maxDate) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesAudit.getAuditLogs(
        id,
        token,
        lastId,
        type,
        email,
        minDate,
        maxDate
      );
      dispatch({
        type: GET_AUDIT_LOGS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_AUDIT_LOGS,
        payload: error.response,
      });
    }
  };
}
