import React, { Component } from "react";
import "./notifications.css";
import {
  Row,
  Col,
  Card,
  Typography,
  List,
  Tabs,
  message,
  Skeleton,
} from "antd";
import COLOR from "../../const/colors";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  markReadNotifications,
  getNotifications,
} from "../../redux/actions/notificationsActions";
import { currentToken } from "../../redux/actions/tokenActions";
import moment from "moment";
const { Text } = Typography;
const { TabPane } = Tabs;
class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: "1",
      notificationsListActivity: [],
      notificationsListGeneral: [],
      listIdsActivities: [],
      listIdsGeneral: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.loadNotifications();
  }
  componentDidUpdate(prevProps) {
    if (this.props.notification.data !== prevProps.notification.data) {
      this.loadNotifications();
    }
  }
  formatAmPm(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  loadNotifications() {
    if (this.props.notification.data !== undefined) {
      let dataNotifications = this.props.notification.data.data;
      let arrayNotificationsActivity = [];
      let arrayNotificationsGeneral = [];
      let idsActivities = [];
      let idsGeneral = [];
      const dateNow = new Date();
      let today = dateNow.getDate();
      let monthActual = dateNow.getMonth() + 1;
      if (dataNotifications !== undefined) {
        this.setState({ loading: true });
        Object.entries(dataNotifications).forEach(([key, value]) => {
          if (key === "activities") {
            for (let data of value) {
              let objActivities = {};
              let dateMoment = moment(data.created_date).format(
                "YYYY/MM/DD HH:mm:ss"
              );
              let date = new Date(Date.parse(dateMoment + " UTC"));
              let dateCreated =
                date.toString().split(" ")[1] +
                " " +
                date.toString().split(" ")[2];
              objActivities.id = data._id;
              objActivities.date = data.created_date;
              let monthNotification = date.getMonth() + 1;
              if (
                date.getDate() === today &&
                monthNotification === monthActual
              ) {
                objActivities.dateFormat = this.formatAmPm(date);
              } else {
                objActivities.dateFormat = dateCreated;
              }
              objActivities.description = data.description;
              objActivities.readDate = data.read_date;
              objActivities.status = data.status;
              objActivities.title = data.title;
              if (data.status === "UNREAD") {
                idsActivities.push(data._id);
              }

              arrayNotificationsActivity.push(objActivities);
            }
          } else if (key === "notifications") {
            for (let data of value) {
              let objGeneral = {};
              let dateMoment = moment(data.created_date).format(
                "YYYY/MM/DD HH:mm:ss"
              );
              let date = new Date(Date.parse(dateMoment + " UTC"));
              let dateCreated =
                date.toString().split(" ")[1] +
                " " +
                date.toString().split(" ")[2];
              objGeneral.id = data._id;
              objGeneral.date = data.created_date;
              let monthNotification = date.getMonth() + 1;
              if (
                date.getDate() === today &&
                monthNotification === monthActual
              ) {
                objGeneral.dateFormat = this.formatAmPm(date);
              } else {
                objGeneral.dateFormat = dateCreated;
              }
              objGeneral.description = data.description;
              objGeneral.readDate = data.read_date;
              objGeneral.status = data.status;
              objGeneral.title = data.title;
              if (data.status === "UNREAD") {
                idsGeneral.push(data._id);
              }
              arrayNotificationsGeneral.push(objGeneral);
            }
          }
        });
        if (idsActivities.length > 0 && this.state.tabActive === "1") {
          this.markRead(idsActivities, "activities");
        } else if (idsGeneral.length > 0 && this.state.tabActive === "2") {
          this.markRead(idsGeneral, "notifications");
        }
        this.setState({
          notificationsListActivity: arrayNotificationsActivity,
          notificationsListGeneral: arrayNotificationsGeneral,
          listIdsActivities: idsActivities,
          listIdsGeneral: idsGeneral,
          loading: false,
        });
      }
    }
  }
  handleTab(key) {
    if (key === "1") {
      if (this.state.listIdsActivities.length > 0) {
        this.markRead(this.state.listIdsActivities, "activities");
      }
    } else {
      if (this.state.listIdsGeneral.length > 0) {
        this.markRead(this.state.listIdsGeneral, "notifications");
      }
    }

    this.setState({ tabActive: key });
  }
  async markRead(ids, type) {
    try {
      let body = {
        notifications: ids,
      };
      await this.props.markReadNotifications(
        this.props.token.clientId,
        this.props.token.current,
        body
      );
      let resp = this.props.markRead;
      let newAccessToken = "";
      if (resp.headers !== undefined) {
        newAccessToken = resp.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (resp.status === 200) {
        if (type === "activities") {
          this.setState({
            listIdsActivities: [],
          });
        } else {
          this.setState({
            listIdsGeneral: [],
          });
        }

        await this.getNotifications(newAccessToken);
      } else if (resp.status === 400) {
        if (resp.data.error_details !== undefined) {
          let errorRead = resp.data.error_details.find(
            (element) =>
              element.reason === "Notification status was already set to 'READ'"
          );
          if (errorRead !== undefined) {
            await this.getNotifications(newAccessToken);
          }
        }
      } else if (
        resp.status === 401 ||
        resp.status === 422 ||
        resp.status === 403 ||
        resp.status === 404 ||
        resp.status === 500
      ) {
      }
    } catch (error) {}
  }
  async getNotifications(accessToken) {
    try {
      this.setState({ loading: true });
      await this.props.getNotifications(this.props.token.clientId, accessToken);
      let resp = this.props.notification;
      if (resp.headers !== undefined) {
        let newAccessToken = resp.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (resp.status === 200) {
        this.setState({ loading: false });
      } else if (resp.status === 403) {
        this.setState({ loading: false });
        message.error(this.props.t("notifications.accessError"));
      } else if (
        resp.status === 401 ||
        resp.status === 422 ||
        resp.status === 404 ||
        resp.status === 500
      ) {
        this.setState({
          loading: false,
          listIdsActivities: [],
          listIdsGeneral: [],
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  }
  render() {
    return (
      <>
        <Row gutter={[16, { xs: 8, sm: 16, md: 8, lg: 8, xl: 24 }]}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 12, offset: 6 }}
            xl={{ span: 12, offset: 6 }}
          >
            <div
              id="custom-tab-detail"
              className="ant-tabs ant-tabs-tab ant-tabs-tab-active ant-tabs-tab-btn ant-tabs-nav ant-tabs-nav-list"
            >
              <Tabs
                type="line"
                onTabClick={(key) => this.handleTab(key)}
                activeKey={this.state.tabActive}
                tabBarStyle={{
                  color: COLOR.PRIMARY_TEXT,
                  width: "100%",
                  paddingLeft: "10px",
                }}
              >
                <TabPane
                  style={{ width: "100%", textAlign: "center" }}
                  tab={
                    <Text
                      style={{
                        fontSize: 18,
                        color: COLOR.PRIMARY_TEXT,
                      }}
                    >
                      {this.props.t("notifications.activities")}
                    </Text>
                  }
                  key="1"
                >
                  <Card
                    bordered={false}
                    size="default"
                    style={{
                      width: "100%",
                      borderRadius: "5px",
                      background: COLOR.PRIMARY,
                      color: COLOR.SECONDARY_TEXT,
                      border: "1px solid #000",
                    }}
                    bodyStyle={{ padding: "0px 0px 10px 0px" }}
                  >
                    {this.state.loading && <Skeleton active />}
                    {!this.state.loading && (
                      <List
                        locale={{
                          emptyText: (
                            <Text
                              style={{
                                color: COLOR.PRIMARY_TEXT,
                                fontSize: 16,
                                fontWeight: "bold",
                              }}
                            >
                              {this.props.t("notifications.noNotifications")}
                            </Text>
                          ),
                        }}
                        dataSource={this.state.notificationsListActivity}
                        style={{
                          background: COLOR.PRIMARY,
                          color: COLOR.SECONDARY_TEXT,
                          padding: 20,
                        }}
                        renderItem={(item) => (
                          <div
                            id="list-notifications"
                            className="ant-list-split ant-list-item ant-list-item-meta-description ant-list-item-meta-title"
                          >
                            <List.Item key={item.id}>
                              <List.Item.Meta
                                title={
                                  <Text
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 18,
                                      marginBottom: 4,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item.title}
                                  </Text>
                                }
                                description={
                                  <div>
                                    <Text
                                      style={{
                                        color: COLOR.PRIMARY_TEXT,
                                        fontSize: 16,
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {item.description}
                                    </Text>
                                    <br />
                                    <Text
                                      style={{
                                        color: COLOR.SECONDARY_TEXT,
                                        fontSize: 14,
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {item.dateFormat}
                                    </Text>
                                  </div>
                                }
                              />
                            </List.Item>
                          </div>
                        )}
                      ></List>
                    )}
                  </Card>
                </TabPane>
                <TabPane
                  style={{ width: "100%", textAlign: "center" }}
                  tab={
                    <Text
                      style={{
                        fontSize: 18,
                        color: COLOR.PRIMARY_TEXT,
                      }}
                    >
                      {this.props.t("notifications.notifications")}
                    </Text>
                  }
                  key="2"
                >
                  {" "}
                  <Card
                    bordered={false}
                    size="default"
                    style={{
                      width: "100%",
                      borderRadius: "5px",
                      background: COLOR.PRIMARY,
                      color: COLOR.SECONDARY_TEXT,
                      border: "1px solid #000",
                    }}
                    bodyStyle={{ padding: "0px 0px 10px 0px" }}
                  >
                    {this.state.loading && <Skeleton active />}
                    {!this.state.loading && (
                      <List
                        locale={{
                          emptyText: (
                            <Text
                              style={{
                                color: COLOR.PRIMARY_TEXT,
                                fontSize: 16,
                                fontWeight: "bold",
                              }}
                            >
                              {this.props.t("notifications.noNotifications")}
                            </Text>
                          ),
                        }}
                        dataSource={this.state.notificationsListGeneral}
                        style={{
                          background: COLOR.PRIMARY,
                          color: COLOR.SECONDARY_TEXT,
                          padding: 20,
                        }}
                        renderItem={(item) => (
                          <div
                            id="list-notifications"
                            className="ant-list-split ant-list-item ant-list-item-meta-description ant-list-item-meta-title"
                          >
                            <List.Item key={item.id}>
                              <List.Item.Meta
                                title={
                                  <Text
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 18,
                                      marginBottom: 4,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item.title}
                                  </Text>
                                }
                                description={
                                  <div>
                                    <Text
                                      style={{
                                        color: COLOR.PRIMARY_TEXT,
                                        fontSize: 16,
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {item.description}
                                    </Text>
                                    <br />
                                    <Text
                                      style={{
                                        color: COLOR.SECONDARY_TEXT,
                                        fontSize: 14,
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {item.dateFormat}
                                    </Text>
                                  </div>
                                }
                              />
                            </List.Item>
                          </div>
                        )}
                      ></List>
                    )}
                  </Card>
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    notification: state.loadNotifications.notifications,
    markRead: state.loadNotifications.markRead,
    token: state.tokenAccess,
  };
};

const mapDispatchToProps = {
  markReadNotifications,
  currentToken,
  getNotifications,
};

const NotificationsTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Notifications)
);

export default NotificationsTranslated;
