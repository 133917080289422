import React, { Component } from "react";
import { Layout } from "antd";
import "antd/dist/antd.min.css";
import "./sidebar.css";
import logo from "../../images/logoDam.png";
import COLOR from "../../const/colors";
import { connect } from "react-redux";
import { siderNavigate } from "../../redux/actions/navigateActions";
import { getAllTransactionsByFilter } from "../../redux/actions/approvalsActions";
import { Menu, Image, Badge } from "antd";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import CAN from "../../casl/can";
import TextBoxCheckOutline from "@2fd/ant-design-icons/lib/TextBoxCheckOutline";
import {
  PieChartFilled,
  FundFilled,
  //BellFilled,
  LayoutFilled,
  //GlobalOutlined,
  BellOutlined,
  TeamOutlined,
  ContainerOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;
class Sidebar extends Component {
  state = {
    collapsed: false,
    optionSelected: this.props.result.result,
    countNotifications: 0,
  };
  componentDidMount() {
    this.notificationCounter();
    let token = this.props.accessToken;
    let userId = this.props.clientId;
    this.props.getAllTransactionsByFilter(
      userId,
      token,
      null,
      null,
      null,
      null,
      null
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.result.result !== prevProps.result.result) {
      this.setState({
        optionSelected: this.props.result.result,
      });
    }
    if (this.props.notification.data !== prevProps.notification.data) {
      this.notificationCounter();
    }
  }
  notificationCounter() {
    let data = this.props.notification.data;
    let count = 0;
    if (data !== undefined) {
      if (data.data !== undefined) {
        Object.entries(data.data).forEach(([key, value]) => {
          if (key === "activities") {
            for (let data of value) {
              if (data.status === "UNREAD") {
                count++;
              }
            }
          }
          if (key === "notifications") {
            for (let data of value) {
              if (data.status === "UNREAD") {
                count++;
              }
            }
          }
        });
        this.setState({ countNotifications: count });
      }
    }
  }
  render() {
    let transaction_authorisation =
      this.props.dataUser.userData?.data?.data?.user.transaction_authorisation;

    return (
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        style={{ background: COLOR.PRIMARY }}
      >
        <div style={{ paddingTop: 10, textAlign: "center" }}>
          <NavLink to="/account" className="nav-link">
            <Image
              preview={false}
              width={55}
              src={logo}
              onClick={() => {
                this.props.siderNavigate("accounts");
              }}
            />
          </NavLink>
        </div>
        <Menu
          selectedKeys={this.state.optionSelected}
          mode="inline"
          theme="dark"
          className="menu-style"
          style={{ background: COLOR.PRIMARY }}
          defaultSelectedKeys={this.state.optionSelected}
        >
          <Menu.Item
            key="accounts"
            icon={<PieChartFilled className="circle-icon-style" />}
            onClick={() => {
              this.props.siderNavigate("accounts");
            }}
          >
            <NavLink to="/account" className="nav-link">
              {this.props.t("menu.accounts")}
            </NavLink>
          </Menu.Item>
          {CAN("GET", "MARKET_INFO") && (
            <Menu.Item
              key="trade"
              icon={<FundFilled className="circle-icon-style" />}
              onClick={() => {
                this.props.siderNavigate("trade");
              }}
            >
              <NavLink to="/trade" className="nav-link">
                {this.props.t("menu.trade")}
              </NavLink>
            </Menu.Item>
          )}
          {CAN("GET", "TRANSACTIONS") && (
            <Menu.Item
              key="history"
              style={{ paddingRight: 2 }}
              icon={<LayoutFilled className="circle-icon-style" />}
              onClick={() => {
                this.props.siderNavigate("history");
              }}
            >
              <NavLink to="/history" className="nav-link">
                {this.props.t("menu.history")}
              </NavLink>
            </Menu.Item>
          )}

          {/* <Menu.Item
            disabled
            key="invoices"
            icon={<BellFilled className="circle-icon-style" />}
            onClick={() => {
              this.props.siderNavigate("invoices");
            }}
          >
            {this.props.t("menu.invoices")}
          </Menu.Item>
          <Menu.Item
            key="cryptoprocessing"
            icon={<GlobalOutlined className="circle-icon-style" />}
            onClick={() => {
              this.props.siderNavigate("cryptoprocessing");
            }}
          >
            {this.props.t("menu.cryptoprocessing")}
          </Menu.Item> */}
          <Menu.Item
            key="notifications"
            icon={<BellOutlined className="circle-icon-style" />}
            onClick={() => {
              this.props.siderNavigate("notifications");
            }}
          >
            <NavLink
              to="/notifications"
              className="nav-link"
              style={{ alignContent: "space-between", width: "100%" }}
            >
              {this.props.t("menu.notifications")}{" "}
              <Badge
                style={{ boxShadow: "0 0 0 1px #ff4d4f", marginLeft: 10 }}
                count={
                  this.state.countNotifications > 9
                    ? "+9"
                    : this.state.countNotifications
                }
              />
            </NavLink>
          </Menu.Item>
          {CAN("DELETE", "USERS") &&
            CAN("GET", "USERS") &&
            CAN("EDIT", "USERS") &&
            CAN("CREATE", "USERS") && (
              <Menu.Item
                key="user"
                disabled={false}
                icon={<TeamOutlined className="circle-icon-style" />}
                onClick={() => {
                  this.props.siderNavigate("user");
                }}
              >
                <NavLink
                  to="/users"
                  className="nav-link"
                  style={{ alignContent: "space-between", width: "100%" }}
                >
                  {this.props.t("menu.user")}{" "}
                </NavLink>
              </Menu.Item>
            )}
          {CAN("GET", "AUDIT_LOGS") && (
            <Menu.Item
              key="audit"
              icon={<ContainerOutlined className="circle-icon-style" />}
              onClick={() => {
                this.props.siderNavigate("audit");
              }}
            >
              <NavLink
                to="/audit"
                className="nav-link"
                style={{ alignContent: "space-between", width: "100%" }}
              >
                {this.props.t("menu.audit")}{" "}
              </NavLink>
            </Menu.Item>
          )}
          {(this.props.listPendingNotification?.length > 0 ||
            transaction_authorisation) && (
            <Menu.Item
              key="approvals"
              icon={<TextBoxCheckOutline className="circle-icon-style" />}
              onClick={() => {
                this.props.siderNavigate("approvals");
              }}
            >
              <NavLink
                to="/approvals"
                className="nav-link"
                style={{ alignContent: "space-between", width: "100%" }}
              >
                {this.props.t("menu.approvals")}
                <Badge
                  style={{ boxShadow: "0 0 0 1px #ff4d4f", marginLeft: 10 }}
                  count={
                    this.props.listPendingNotification?.length > 9
                      ? "+9"
                      : this.props.listPendingNotification?.length
                  }
                />
              </NavLink>
            </Menu.Item>
          )}
        </Menu>
      </Sider>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    result: state.siderNavigate,
    notification: state.loadNotifications.notifications,
    listPendingNotification: state.approvals.resultTransactionsPendings,
    dataUser: state.authUser,
    accessToken: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
  };
};

const mapDispatchToProps = {
  siderNavigate,
  getAllTransactionsByFilter

};
const SidebarTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);

export default SidebarTranslated;
