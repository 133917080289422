import {
  AUTH_USER,
  SIGN_IN_SUCCESS,
  REQUEST_ACCOUNT,
  UPLOAD_DOCUMENT,
  SEND_REQUEST_ACCOUNT,
  CREATE_SHAREHOLDER,
  LOGOUT,
  ACCEPT_TERMS,
  ONLY_FIAT,
} from "../actions/userActions";
const initialState = {
  authResult: {},
  userData: {},
  request: {},
  document: {},
  commit: {},
  shareholder: {},
  logout: {},
  terms: {},
  isFiatOnly: {},
};
export function authUser(state = initialState, action) {
  switch (action.type) {
    case AUTH_USER:
      return Object.assign({}, state, { authResult: action.payload });
    case LOGOUT:
      return Object.assign({}, state, { logout: action.payload });
    case SIGN_IN_SUCCESS:
      return Object.assign({}, state, { userData: action.payload });
    case ACCEPT_TERMS:
      return Object.assign({}, state, { terms: action.payload });
    case ONLY_FIAT:
      return Object.assign({}, state, { isFiatOnly: action.payload });
    default:
      return state;
  }
}
export function requestAccount(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ACCOUNT:
      return Object.assign({}, state, { request: action.payload });
    case UPLOAD_DOCUMENT:
      return Object.assign({}, state, { document: action.payload });
    case SEND_REQUEST_ACCOUNT:
      return Object.assign({}, state, { commit: action.payload });
    case CREATE_SHAREHOLDER:
      return Object.assign({}, state, { shareholder: action.payload });
    default:
      return state;
  }
}
