import React from "react";
import "antd/dist/antd.min.css";
import { Layout } from "antd";
import Footer from "../components/footer/footer";
import Header from "../components/nav/navPublic";
const { Content } = Layout;
const LayoutPublic = ({ children }) => (
  <Layout style={{ minHeight: "100vh" }}>
    <Header />
    <Content style={{ background: "#00001E", width: "100%", }}>
      <div >{children}</div>
    </Content>
    <Footer />
  </Layout>
);
export default LayoutPublic;
