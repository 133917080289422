import React, { Component } from "react";
import {
  Row,
  Col,
  Space,
  Avatar,
  Typography,
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
} from "antd";
import { isMobileOnly } from "react-device-detect";
import { UserOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  authUser,
  updateProfile,
  confirmUpdate,
  signinSuccess,
} from "../../redux/actions/userActions";
import { currentToken } from "../../redux/actions/tokenActions";
import COLOR from "../../const/colors";
import "./profile.css";
import { countrys } from "../../common/countrysData";
import ModalChangePassword from "../../common/modalChangePassword/modalChangePassword";
import ModalChangeAvatar from "../../common/modalChangeAvatar/modalChangeAvatar";
import ModalTwoFactor from "../twoFactorLoggedIn/twoFactorLoggedIn";
import ModalChangeEmail from "../changeEmail/changeEmail";
import data from "country-region-data";
import config from "../../services/config";
import { v4 as uuidv4 } from "uuid";
import CAN from "../../casl/can";
const { Text, Title } = Typography;
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumberChange: true,
      phone: this.props.dataUser.userData.data.data.user.profile.phone_number,
      newPhone:
        this.props.dataUser.userData.data.data.user.profile.phone_number,
      listCountryCode: [],
      modalPassword: false,
      codeSelected:
        this.props.dataUser.userData.data.data.user.profile.phone_prefix,
      modalTwoFactor: false,
      verificationCode: "",
      disableResend: false,
      modalChangeEmail: false,
      newEmail: this.props.dataUser.userData.data.data.user.email,
      oldEmail: this.props.dataUser.userData.data.data.user.email,
      infoUser: "",
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      oldPasswordFormatted: "",
      newPasswordFormatted: "",
      loading: false,
      loadingPhone: false,
      changeAvatar: false,
      loadingRequestEmail: false,
      loadingRequestPhone: false,
    };
  }
  componentDidMount() {
    this.filterCodeCountry();
  }
  componentDidUpdate(prevProps) {
    if (this.props.refreshForm !== prevProps.refreshForm) {
      let data = this.props.dataUser.userData.data.data.user;
      this.setState({
        newEmail: data.email,
        newPhone: data.profile.phone_number,
      });
    }
  }
  findCountry(country) {
    let find = data.find((element) => element.countryShortCode === country);
    return find.countryName;
  }
  filterCodeCountry() {
    let arrayCode = [];
    let countrysArray = countrys.country;
    for (let i = 0; i < countrysArray.length; i++) {
      if (countrysArray[i].value !== "") {
        arrayCode.push(countrysArray[i].value);
      }
    }
    let uniqueCodes = [...new Set(arrayCode)];
    this.setState({ listCountryCode: uniqueCodes });
  }
  handlePhoneNumber(value) {
    this.setState({ phoneNumberChange: false, newPhone: "+" + value });
  }
  handleEmail(e) {
    this.setState({ newEmail: e.target.value });
  }
  handleCode(value) {
    this.setState({ codeSelected: value });
  }
  openModalChangeAvatar() {
    this.setState({ changeAvatar: true });
  }
  closeModalChangeAvatar() {
    this.setState({ changeAvatar: false });
  }
  openModalChangePassword() {
    this.setState({ modalPassword: true });
  }
  closeModalPassword() {
    this.setState({
      modalPassword: false,
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    });
  }
  beforeUpload(file) {
    let sizeFile = file.size / 1024 / 1024;
    if (sizeFile > 10) {
      message.error(this.props.t("requestAccount.messagesError.fileSize"));
    }
    return false;
  }
  async uploadAvatar(info) {
    try {
      this.setState({ loading: true, avatar: info });
      let dataAvatar = new FormData();
      dataAvatar.append("file", info.file);
      await this.props.updateProfile(
        this.props.token.clientId,
        this.props.token.current,
        dataAvatar
      );
      let response = this.props.result.update;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      
      if (response.data.success) {
        this.setState({ loading: false });
        if (!response.data.data.confirmation_required) {
          await this.getUser();
          message.success(this.props.t("profile.messages.successAvatar"));
          this.setState({ loading: false });
        }
      } else {
        this.setState({ loading: false });
        if (response.status === 400) {
          message.error(this.props.t("profile.messages.dataError"));
        } else if (response.status === 404) {
          message.error(this.props.t("profile.messages.userInactive"));
        } else if (
          response.status === 401 ||
          response.status === 403 ||
          response.status === 500
        ) {
          message.error(this.props.t("profile.messages.errorServer"));
        }
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
      message.error(this.props.t("profile.messages.errorServer"));
    }
  }
  closeModalTwoFactorPhone() {
    this.setState({
      modalTwoFactor: false,
      verificationCode: "",
      phoneNumberChange: true,
      newPhone:
        this.props.dataUser.userData.data.data.user.profile.phone_number,
    });
  }
  handleOldPassword(e) {
    let random = uuidv4();
    let enumData = config.ENUM;
    let pass = new Buffer(e.target.value + enumData + random);
    let convertPassword = pass.toString("base64");
    this.setState({
      oldPassword: e.target.value,
      oldPasswordFormatted: convertPassword,
    });
  }
  handleNewPassword(e) {
    let random = uuidv4();
    let enumData = config.ENUM;
    let pass = new Buffer(e.target.value + enumData + random);
    let convertPassword = pass.toString("base64");
    this.setState({
      newPassword: e.target.value,
      newPasswordFormatted: convertPassword,
    });
  }
  handleConfirmNewPassword(e) {
    this.setState({ confirmNewPassword: e.target.value });
  }
  closeModalChangeEmail() {
    this.setState({
      modalChangeEmail: false,
      newEmail: this.props.dataUser.userData.data.data.user.email,
    });
  }
  verificationCodeChangePhone() {
    this.setState({ modalTwoFactor: false });
  }
  handleVerificationCode(e) {
    const reg = /^[0-9]+$/;
    if (reg.test(e.target.value)) {
      this.setState({ verificationCode: e.target.value });
    }
  }
  async resendCode() {
    this.setState({ disableResend: true });
    await this.requestChangeEmail();
    setTimeout(() => {
      this.setState({ disableResend: false });
    }, 5000);
  }
  async requestChangeEmail(resend) {
    try {
      this.setState({ loadingRequestEmail: true });
      let body = {
        field: "EMAIL",
        email: this.state.newEmail,
      };
      await this.props.updateProfile(
        this.props.token.clientId,
        this.props.token.current,
        body
      );
      let response = this.props.result.update;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.data.success) {
        if (response.data.data.confirmation_required) {
          if (resend) {
            message.success(this.props.t("profile.messages.resend"));
          } else {
            this.setState({
              modalChangeEmail: true,
              loadingRequestEmail: false,
            });
          }
        }
      } else {
        console.log(response.status);
        this.setState({ loadingRequestEmail: false });
        if (response.status === 400) {
          message.error(this.props.t("profile.messages.dataError"));
        } else if (response.status === 401) {
          message.error(this.props.t("profile.messages.errorServer"));
        } else if (response.status === 403) {
          message.error(this.props.t("profile.messages.emailInUse"));
        } else if (response.status === 404) {
          message.error(this.props.t("profile.messages.userInactive"));
        } else {
          message.error(this.props.t("profile.messages.errorServer"));
        }
      }
    } catch (error) {
      this.setState({ loadingRequestEmail: false });
      console.log(error);
      message.error(this.props.t("profile.messages.errorServer"));
    }
  }
  async requestChangePhone(resend) {
    try {
      if (resend) {
        this.setState({ disableResend: true });
      } else {
        this.setState({ loadingRequestPhone: true });
      }
      let body = {
        field: "PHONE_NUMBER",
        phone_number: this.state.newPhone.toString(),
      };
      await this.props.updateProfile(
        this.props.token.clientId,
        this.props.token.current,
        body
      );
      let response = this.props.result.update;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.data.success) {
        if (response.data.data.confirmation_required) {
          if (resend) {
            message.success(this.props.t("profile.messages.resend"));
            setTimeout(() => {
              this.setState({ disableResend: false });
            }, 5000);
          } else {
            this.setState({ modalTwoFactor: true, loadingRequestPhone: false });
          }
        }
      } else {
        console.log(response.status);
        this.setState({ loadingRequestPhone: false });
        if (response.status === 400) {
          message.error(this.props.t("profile.messages.dataError"));
        } else if (response.status === 401) {
          message.error(this.props.t("profile.messages.errorServer"));
        } else if (response.status === 403) {
          message.error(this.props.t("profile.messages.errorServer"));
        } else if (response.status === 404) {
          message.error(this.props.t("profile.messages.userInactive"));
        } else {
          message.error(this.props.t("profile.messages.errorServer"));
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ loadingRequestPhone: false });
      message.error(this.props.t("profile.messages.errorServer"));
    }
  }
  async confirmChangePhone() {
    try {
      this.setState({ loadingPhone: true });
      let body = {
        otp: this.state.verificationCode,
      };
      await this.props.confirmUpdate(
        this.props.token.clientId,
        this.props.token.current,
        body
      );
      let response = this.props.result.confirm;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.data.success) {
        this.setState({ loadingPhone: false });
        message.success(this.props.t("profile.messages.successPhone"));
        await this.getUser();
        this.closeModalTwoFactorPhone();
      } else {
        this.setState({ loadingPhone: false });
        if (response.status === 400) {
          message.error(this.props.t("profile.messages.dataError"));
        } else if (response.status === 401) {
          message.error(this.props.t("profile.messages.incorrectCode"));
        } else if (response.status === 403) {
          message.error(this.props.t("profile.messages.codeExpired"));
        } else if (response.status === 404) {
          message.error(this.props.t("profile.messages.userInactive"));
        } else {
          message.error(this.props.t("profile.messages.errorServer"));
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ loadingPhone: false });
      message.error(this.props.t("profile.messages.errorServer"));
    }
  }
  async getUser() {
    try {
      await this.props.signinSuccess(
        this.props.token.clientId,
        this.props.token.current
      );
      let response = this.props.dataUser.userData;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }

      if (response.status === 401) {
        message.error(this.props.t("signin.messages.formError"));
      } else if (response.status === 403) {
        message.error(this.props.t("signin.messages.formError"));
      } else if (response.status === 404) {
        message.error(this.props.t("signin.messages.formError"));
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    let userInfo,
      name,
      avatar,
      address = "";
    if (this.props.dataUser.userData.status !== undefined) {
      if (
        this.props.dataUser.userData.status !== 401 &&
        this.props.dataUser.userData.status !== 422 &&
        this.props.dataUser.userData.status !== 403 &&
        this.props.dataUser.userData.status !== 404
      ) {
        userInfo = this.props.dataUser.userData.data.data.user;
        avatar =
          userInfo.profile.avatar !== undefined ? userInfo.profile.avatar : "";

        if (userInfo.type === "DAMPAY_INDIVIDUAL") {
          name = userInfo.profile.name + " " + userInfo.profile.last_name;
          if (userInfo.profile.physical_address !== undefined) {
            address = userInfo.profile.physical_address;
          }
        } else {
          name = userInfo.profile.name;
          if (userInfo.profile.physical_address !== undefined) {
            address = userInfo.profile.physical_address;
          }
        }
      }
    }

    return (
      <div>
        <Row
          gutter={[
            isMobileOnly ? 16 : 48,
            { xs: 24, sm: 24, md: 24, lg: 16, xl: 8 },
          ]}
          align="middle"
          justify="start"
        >
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 1 }}
            lg={{ span: 10, offset: 1 }}
            xl={{ span: 8, offset: 0 }}
            style={{ marginLeft: window.screen.width <= 1440 ? 16 : "" }}
          >
            <Row
              gutter={[8, { xs: 16, sm: 8, md: 8, lg: 8, xl: 8 }]}
              align="middle"
            >
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 16, offset: 0 }}
                lg={{ span: 15, offset: 0 }}
                xl={{ span: 15, offset: 0 }}
              >
                <Space direction="horizontal" align="center" size="middle">
                  <Avatar size="large" src={avatar} icon={<UserOutlined />} />
                  <div>
                    <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                      {this.props.t("profile.changePicture")}
                    </Text>
                    <br />
                    <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 14 }}>
                      {this.props.t("profile.sizePicture")}
                    </Text>
                  </div>
                </Space>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 8, offset: 0 }}
                lg={{ span: 9, offset: 0 }}
                xl={{ span: 9, offset: 0 }}
              >
                <Button
                  type="primary"
                  size="large"
                  onClick={() => this.openModalChangeAvatar()}
                >
                  {this.props.t("profile.changeAvatar")}
                </Button>
                {/* <Upload
                  showUploadList={false}
                  beforeUpload={this.beforeUpload.bind(this)}
                  onChange={this.uploadAvatar.bind(this)}
                  maxCount={1}
                  accept=".png, .jpg, .jpeg, .jfif, .pjpeg, .pjp"
                >
                  <Button
                    type="primary"
                    size="large"
                    loading={this.state.loading}
                  >
                    {this.props.t("profile.changeAvatar")}
                  </Button>
                </Upload> */}
              </Col>
            </Row>
          </Col>
          {!isMobileOnly && (
            <Divider
              style={{ background: COLOR.PRIMARY_TEXT, height: 55 }}
              type="vertical"
            ></Divider>
          )}
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xl={{ span: 12, offset: 0 }}
            style={{
              marginLeft:
                window.screen.width <= 768
                  ? 16
                  : window.screen.width <= 1024
                  ? 20
                  : "",
            }}
          >
            <Row
              gutter={[8, { xs: 16, sm: 8, md: 8, lg: 8, xl: 8 }]}
              align="middle"
            >
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 16, offset: 0 }}
                lg={{ span: 13, offset: 0 }}
                xl={{ span: 16, offset: 0 }}
              >
                <div>
                  <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                    {this.props.t("profile.changePassword")}
                  </Text>
                  <br />
                  <Text
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      fontSize: 14,
                      marginRight: "2px",
                    }}
                  >
                    {this.props.t("profile.instructions")}
                  </Text>
                  <Button
                    type="link"
                    style={{
                      color: COLOR.GREEN,
                      fontWeight: "bold",
                      fontSize: 14,
                      paddingLeft: "2px",
                    }}
                    onClick={() => this.props.handleTab("3")}
                  >
                    {this.props.t("profile.security")}
                  </Button>
                </div>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 8, offset: 0 }}
                lg={{ span: 11, offset: 0 }}
                xl={{ span: 8, offset: 0 }}
              >
                <Button
                  type="primary"
                  size="large"
                  onClick={() => this.openModalChangePassword()}
                >
                  {this.props.t("profile.changePassword")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row gutter={[48, { xs: 16, sm: 16, md: 8, lg: 8, xl: 8 }]}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
          >
            <Title level={4} style={{ color: COLOR.PRIMARY_TEXT }}>
              {this.props.t("profile.myProfile")}
            </Title>
            <Divider
              style={{ background: COLOR.PRIMARY_TEXT, margin: "10px 0" }}
            ></Divider>
            <Row gutter={[8, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 16, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 8, offset: 0 }}
              >
                <Form
                  name="email-address"
                  labelAlign="left"
                  labelCol={{ span: 24, offset: 0 }}
                  wrapperCol={{ span: 24, offset: 0 }}
                >
                  <Form.Item
                    label={
                      <Text style={{ color: "#fff", fontSize: 16 }}>
                        {this.props.t("profile.email")}
                      </Text>
                    }
                    name="email"
                    initialValue={this.state.newEmail}
                    valuePropName="defaultValue"
                  >
                    <Input
                      size="large"
                      onChange={(e) => this.handleEmail(e)}
                      readOnly={CAN("EDIT", "PROFILE") === false}
                      value={this.state.newEmail}
                    />
                  </Form.Item>
                  {CAN("EDIT", "PROFILE") && (
                    <Form.Item>
                      <Button
                        type="primary"
                        size="large"
                        disabled={
                          this.state.newEmail === "" ||
                          this.state.newEmail === userInfo.email
                        }
                        loading={this.state.loadingRequestEmail}
                        onClick={() => this.requestChangeEmail(false)}
                      >
                        {this.props.t("profile.buttonEmail")}
                      </Button>
                    </Form.Item>
                  )}
                </Form>
              </Col>
              <Col
                xs={{ span: 0, offset: 0 }}
                sm={{ span: 0, offset: 0 }}
                md={{ span: 8, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 16, offset: 0 }}
              ></Col>
            </Row>
            {CAN("EDIT", "PROFILE") && (
              <Row gutter={[8, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 16, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  xl={{ span: 8, offset: 0 }}
                >
                  <Form
                    name="email-address"
                    labelAlign="left"
                    labelCol={{ span: 24, offset: 0 }}
                    wrapperCol={{ span: 24, offset: 0 }}
                  >
                    <Form.Item
                      label={
                        <Text style={{ color: "#fff", fontSize: 16 }}>
                          {this.props.t("profile.phone")}
                        </Text>
                      }
                    >
                      {/* <Input.Group compact size="large">
                      <div
                        id="select-option"
                        className="ant-select-selector ant-select"
                        style={{ width: "30%" }}
                      >
                        <Form.Item
                          name="code"
                          noStyle
                          valuePropName="defaultValue"
                        >
                          <Select
                            size="large"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: "100%" }}
                            onChange={(value) => this.handleCode(value)}
                            value={this.state.codeSelected}
                          >
                            {this.state.listCountryCode.map((item, i) => (
                              <Option value={"+" + item} key={item}>
                                {"+" + item}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div> */}
                      <Form.Item
                        name="phone"
                        noStyle
                        valuePropName="defaultValue"
                      >
                        <InputNumber
                          maxLength={14}
                          size="large"
                          style={{ width: "70%" }}
                          onChange={(value) => this.handlePhoneNumber(value)}
                          value={this.state.newPhone}
                        />
                      </Form.Item>
                      {/* </Input.Group>*/}
                    </Form.Item>

                    <Form.Item>
                      <Button
                        type="primary"
                        size="large"
                        loading={this.state.loadingRequestPhone}
                        disabled={
                          this.state.phone === this.state.newPhone ||
                          this.state.phoneNumberChange
                        }
                        onClick={() => this.requestChangePhone(false)}
                      >
                        {this.props.t("profile.buttonPhone")}
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
                <Col
                  xs={{ span: 0, offset: 0 }}
                  sm={{ span: 0, offset: 0 }}
                  md={{ span: 8, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  xl={{ span: 16, offset: 0 }}
                ></Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row gutter={[48, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
          >
            <Title level={4} style={{ color: COLOR.PRIMARY_TEXT }}>
              {this.props.t("profile.details")}
            </Title>
            <Divider
              style={{ background: COLOR.PRIMARY_TEXT, margin: "10px 0" }}
            ></Divider>
            <Row gutter={[8, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 16, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 10, offset: 0 }}
              >
                <Form
                  name="details"
                  labelAlign="left"
                  labelCol={{ span: 24, offset: 0 }}
                  wrapperCol={{ span: 24, offset: 0 }}
                >
                  <Form.Item
                    label={
                      <Text style={{ color: "#fff", fontSize: 16 }}>
                        {this.props.t("profile.name")}
                      </Text>
                    }
                    name="email"
                    initialValue={name}
                  >
                    <Input
                      className="input-disabled-profile"
                      size="large"
                      disabled
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        backgroundColor: COLOR.PRIMARY,
                      }}
                    />
                  </Form.Item>
                  {userInfo.type === "DAMPAY_INDIVIDUAL" &&
                    userInfo.profile?.date_of_birth !==
                      undefined && (
                        <Form.Item
                          label={
                            <Text style={{ color: "#fff", fontSize: 16 }}>
                              {this.props.t("profile.dateOfBirth")}
                            </Text>
                          }
                        >
                          <Input.Group size="large">
                            <Row gutter={8}>
                              <Col span={8} style={{ paddingLeft: 0 }}>
                                <Input
                                  className="input-disabled-profile"
                                  size="large"
                                  disabled
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    backgroundColor: COLOR.PRIMARY,
                                  }}
                                  defaultValue={
                                    userInfo.profile?.date_of_birth?.split(
                                      "-"
                                    )[1]
                                  }
                                />
                              </Col>
                              <Col span={8}>
                                <Input
                                  className="input-disabled-profile"
                                  size="large"
                                  disabled
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    backgroundColor: COLOR.PRIMARY,
                                  }}
                                  defaultValue={
                                    userInfo.profile?.date_of_birth
                                      ?.split("-")[2]
                                      .split(" ")[0]
                                  }
                                />
                              </Col>
                              <Col span={8} style={{ paddingRight: 0 }}>
                                <Input
                                  className="input-disabled-profile"
                                  size="large"
                                  disabled
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    backgroundColor: COLOR.PRIMARY,
                                  }}
                                  defaultValue={
                                    userInfo.profile.date_of_birth?.split(
                                      "-"
                                    )[0]
                                  }
                                />
                              </Col>
                            </Row>
                          </Input.Group>
                        </Form.Item>
                      )}
                  {userInfo.type !== "DAMPAY_INDIVIDUAL" && (
                    <Divider
                      orientation="left"
                      plain
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        margin: "10px 0",
                        borderTopColor: "#fff",
                      }}
                    >
                      {" "}
                      <Text style={{ color: "#fff", fontSize: 16 }}>
                        {this.props.t("profile.physicalAddress")}
                      </Text>
                    </Divider>
                  )}
                  <Form.Item
                    label={
                      <Text style={{ color: "#fff", fontSize: 16 }}>
                        {this.props.t("profile.street1")}
                      </Text>
                    }
                    name="street"
                    initialValue={address.line_1}
                  >
                    <Input
                      className="input-disabled-profile"
                      size="large"
                      disabled
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        backgroundColor: COLOR.PRIMARY,
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <Text style={{ color: "#fff", fontSize: 16 }}>
                        {this.props.t("profile.street2")}
                      </Text>
                    }
                    name="street2"
                    initialValue={address.line_2 !== null ? address.line_2 : ""}
                  >
                    <Input
                      className="input-disabled-profile"
                      size="large"
                      disabled
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        backgroundColor: COLOR.PRIMARY,
                      }}
                    />
                  </Form.Item>

                  <Row gutter={8}>
                    <Col span={14} style={{ paddingLeft: 0 }}>
                      <Form.Item
                        name="city"
                        label={
                          <Text style={{ color: "#fff", fontSize: 16 }}>
                            {this.props.t("profile.city")}
                          </Text>
                        }
                        initialValue={address.city}
                      >
                        <Input
                          className="input-disabled-profile"
                          size="large"
                          disabled
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            backgroundColor: COLOR.PRIMARY,
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10} style={{ paddingRight: 0 }}>
                      <Form.Item
                        name="region"
                        label={
                          <Text style={{ color: "#fff", fontSize: 16 }}>
                            {this.props.t("profile.region")}
                          </Text>
                        }
                        initialValue={address.region}
                      >
                        <Input
                          className="input-disabled-profile"
                          size="large"
                          disabled
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            backgroundColor: COLOR.PRIMARY,
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={8}>
                    <Col span={10} style={{ paddingLeft: 0 }}>
                      <Form.Item
                        label={
                          <Text style={{ color: "#fff", fontSize: 16 }}>
                            {this.props.t("profile.postalCode")}
                          </Text>
                        }
                        name="postcode"
                        initialValue={address.postal_code}
                      >
                        <Input
                          className="input-disabled-profile"
                          size="large"
                          disabled
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            backgroundColor: COLOR.PRIMARY,
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={14} style={{ paddingRight: 0 }}>
                      <Form.Item
                        name="country"
                        label={
                          <Text style={{ color: "#fff", fontSize: 16 }}>
                            {this.props.t("profile.country")}
                          </Text>
                        }
                        initialValue={this.findCountry(address.country)}
                      >
                        <Input
                          className="input-disabled-profile"
                          size="large"
                          disabled
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            backgroundColor: COLOR.PRIMARY,
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {userInfo.type !== "DAMPAY_INDIVIDUAL" &&
                    userInfo.profile.legal_address !== undefined && (
                      <div>
                        <Divider
                          orientation="left"
                          plain
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            margin: "10px 0",
                            borderTopColor: "#fff",
                          }}
                        >
                          {" "}
                          <Text style={{ color: "#fff", fontSize: 16 }}>
                            {this.props.t("profile.legalAddress")}
                          </Text>
                        </Divider>

                        <Form.Item
                          label={
                            <Text style={{ color: "#fff", fontSize: 16 }}>
                              {this.props.t("profile.street1")}
                            </Text>
                          }
                          name="streetLegal"
                          initialValue={userInfo.profile.legal_address.line_1}
                        >
                          <Input
                            className="input-disabled-profile"
                            size="large"
                            disabled
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              backgroundColor: COLOR.PRIMARY,
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label={
                            <Text style={{ color: "#fff", fontSize: 16 }}>
                              {this.props.t("profile.street2")}
                            </Text>
                          }
                          name="street2Legal"
                          initialValue={
                            userInfo.profile.legal_address.line_2 !== null
                              ? userInfo.profile.legal_address.line_2
                              : ""
                          }
                        >
                          <Input
                            className="input-disabled-profile"
                            size="large"
                            disabled
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              backgroundColor: COLOR.PRIMARY,
                            }}
                          />
                        </Form.Item>

                        <Row gutter={8}>
                          <Col span={14} style={{ paddingLeft: 0 }}>
                            <Form.Item
                              name="cityLegal"
                              label={
                                <Text style={{ color: "#fff", fontSize: 16 }}>
                                  {this.props.t("profile.city")}
                                </Text>
                              }
                              initialValue={userInfo.profile.legal_address.city}
                            >
                              <Input
                                className="input-disabled-profile"
                                size="large"
                                disabled
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  backgroundColor: COLOR.PRIMARY,
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={10} style={{ paddingRight: 0 }}>
                            <Form.Item
                              name="regionLegal"
                              label={
                                <Text style={{ color: "#fff", fontSize: 16 }}>
                                  {this.props.t("profile.region")}
                                </Text>
                              }
                              initialValue={
                                userInfo.profile.legal_address.region
                              }
                            >
                              <Input
                                className="input-disabled-profile"
                                size="large"
                                disabled
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  backgroundColor: COLOR.PRIMARY,
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={8}>
                          <Col span={10} style={{ paddingLeft: 0 }}>
                            <Form.Item
                              label={
                                <Text style={{ color: "#fff", fontSize: 16 }}>
                                  {this.props.t("profile.postalCode")}
                                </Text>
                              }
                              name="postcodeLegal"
                              initialValue={
                                userInfo.profile.legal_address.postal_code
                              }
                            >
                              <Input
                                className="input-disabled-profile"
                                size="large"
                                disabled
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  backgroundColor: COLOR.PRIMARY,
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={14} style={{ paddingRight: 0 }}>
                            <Form.Item
                              name="countryLegal"
                              label={
                                <Text style={{ color: "#fff", fontSize: 16 }}>
                                  {this.props.t("profile.country")}
                                </Text>
                              }
                              initialValue={this.findCountry(
                                userInfo.profile.legal_address.country
                              )}
                            >
                              <Input
                                className="input-disabled-profile"
                                size="large"
                                disabled
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  backgroundColor: COLOR.PRIMARY,
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    )}
                </Form>
              </Col>
              <Col
                xs={{ span: 0, offset: 0 }}
                sm={{ span: 0, offset: 0 }}
                md={{ span: 8, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 14, offset: 0 }}
              ></Col>
            </Row>
          </Col>
        </Row>
        <ModalChangePassword
          modalPassword={this.state.modalPassword}
          openModalChangePassword={this.openModalChangePassword.bind(this)}
          closeModalPassword={this.closeModalPassword.bind(this)}
          oldPassword={this.state.oldPassword}
          newPassword={this.state.newPassword}
          confirmNewPassword={this.state.confirmNewPassword}
          oldPasswordFormatted={this.state.oldPasswordFormatted}
          newPasswordFormatted={this.state.newPasswordFormatted}
          handleOldPassword={this.handleOldPassword.bind(this)}
          handleNewPassword={this.handleNewPassword.bind(this)}
          handleConfirmNewPassword={this.handleConfirmNewPassword.bind(this)}
        />
        <ModalTwoFactor
          openModal={this.requestChangePhone.bind(this)}
          closeModal={this.closeModalTwoFactorPhone.bind(this)}
          verificationCodeReceived={this.confirmChangePhone.bind(this)}
          resend={this.requestChangePhone.bind(this)}
          modalActivate={this.state.modalTwoFactor}
          verificationCode={this.state.verificationCode}
          handleCode={this.handleVerificationCode.bind(this)}
          disableResend={this.state.disableResend}
          loading={this.state.loadingPhone}
        />
        <ModalChangeEmail
          openModal={this.requestChangeEmail.bind(this)}
          closeModal={this.closeModalChangeEmail.bind(this)}
          modalChangeEmail={this.state.modalChangeEmail}
          oldEmail={this.props.dataUser.userData.data.data.user.email}
          newEmail={this.state.newEmail}
        />
        {this.state.changeAvatar && (
          <ModalChangeAvatar
            openModalChangeAvatar={this.openModalChangeAvatar.bind(this)}
            closeModalChangeAvatar={this.closeModalChangeAvatar.bind(this)}
            changeAvatar={this.state.changeAvatar}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser,
    result: state.updateProfile,
    token: state.tokenAccess,
  };
};

const mapDispatchToProps = {
  authUser,
  updateProfile,
  confirmUpdate,
  currentToken,
  signinSuccess,
};

const ProfileTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);

export default ProfileTranslated;
