import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import Login from "../components/login/login";
import Account from "../components/accounts/accounts";
import RequestAccount from "../components/requestAccount/requestAccount";
import ForgotPassword from "../components/forgotPassword/forgotPassword";
import Settings from "../components/settings/settings";
import TransactionByAsset from "../components/detailByAsset/detailByAsset";
import Notifications from "../components/notifications/notifications";
import LayoutAuthenticated from "../layout/layoutAuthenticated";
import LayoutPublic from "../layout/layoutPublic";
import ModalSession from "../common/modalSession/modalSession";
import TradeDetail from "../components/tradeDetail/tradeDetail";
import History from "../components/history/history";
import Users from "../components/users/users";
import AuditLogs from "../components/audit/audit";
import Approvals from "../components/approvals/approvals";
import { refreshToken, currentToken } from "../redux/actions/tokenActions";
import { resetStore, logoutUser } from "../redux/actions/userActions";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import IdleTimer from "react-idle-timer";
import RedirectLogout from "./redirect";
import CAN from "../casl/can";
const customHistory = createBrowserHistory();
class Routes extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      viewModal: false,
      timeSession: 480000,
    };
    this.timer = null;
    this.timeLogout = null;
    this.idleTimer = null;
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }
  componentDidMount() {
    this._isMounted = true;
    try {
      const token = this.props.token;
      var decoded = jwt_decode(token);
      if (Date.now() >= decoded.exp * 1000) {
        this.logout();
        this.props.resetStore();
        this.props.openNotification();
        this.setState({ redirect: true }, () => {
          this.setState({ redirect: null });
        });
      }
    } catch (error) {
      if (error.message !== undefined) {
        if (error.message === "Invalid token specified") {
          this.props.resetStore();
          this.setState({ redirect: true }, () => {
            this.setState({ redirect: null });
          });
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      if (window.localStorage.getItem("timeToken") !== null) {
        this.setState({
          timeSession: parseInt(window.localStorage.getItem("timeToken")),
        });
      }
    }
  }
  validateRoute(Component) {
    try {
      const token = this.props.token;
      var decoded = jwt_decode(token);
      if (Date.now() >= decoded.exp * 1000) {
        this.props.resetStore();
        this.setState({ redirect: true }, () => {
          this.setState({ redirect: null });
        });
      } else {
        let auth = Object.keys(this.props.dataUser.userData).length !== 0;
        let infoUser = this.props.dataUser.userData.data?.data?.user;
        if (auth) {
          if (infoUser.tc_agreement) {
            return <Component />;
          } else {
            this.setState({ redirect: true }, () => {
              this.setState({ redirect: null });
            });
          }
        } else {
          this.setState({ redirect: true }, () => {
            this.setState({ redirect: null });
          });
        }
      }
    } catch (error) {
      if (error.message !== undefined) {
        if (error.message === "Invalid token specified") {
          this.props.resetStore();
          this.setState({ redirect: true }, () => {
            this.setState({ redirect: null });
          });
        }
      }
    }
  }
  session() {
    this.timeLogout = setTimeout(() => {
      this.setState({ viewModal: false });
      let auth = Object.keys(this.props.dataUser.userData).length !== 0;
      if (auth) {
        this.logout();
      }
      this.props.resetStore();
      if (this._isMounted) {
        this.setState({ redirect: true }, () => {
          this.props.openNotification();
          this.setState({ redirect: null });
        });
        window.clearTimeout(this.timeLogout);
        window.clearTimeout(this.timer);
      }
    }, 60000);
  }
  timeOut(time) {
    let timeToken = parseInt(time * 1000) - 60000;
    //if (this.timer !== null) {
    // console.log("limpiar timeout");
    window.clearTimeout(this.timer);
    //}
    this.timer = setTimeout(() => {
      let auth = Object.keys(this.props.dataUser.userData).length !== 0;
      if (auth) {
        this.refreshToken();
      }
    }, timeToken);
  }

  async refreshToken() {
    try {
      await this.props.refreshToken(this.props.clientId, this.props.token);
      let resp = this.props.refresh;
      if (resp.headers !== undefined) {
        let newAccessToken = resp.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (resp.status !== 200) {
        this.props.resetStore();
        this.setState({ redirect: true }, () => {
          this.props.openNotification();
          this.setState({ redirect: null });
        });
        if (this.timeLogout !== null) {
          window.clearTimeout(this.timeLogout);
        }
        if (this.timer !== null) {
          window.clearTimeout(this.timer);
        }
      }
    } catch (error) {
      this.props.resetStore();
      this.setState({ redirect: true }, () => {
        this.props.openNotification();
        this.setState({ redirect: null });
      });
      if (this.timeLogout !== null) {
        window.clearTimeout(this.timeLogout);
      }
    }
  }
  continueSession() {
    if (this.idleTimer !== null) {
      this.setState({ viewModal: false });
      this.idleTimer.reset();
      if (this.timeLogout !== null) {
        window.clearTimeout(this.timeLogout);
      }
    }
  }

  handleOnIdle() {
    this.setState({ viewModal: true });
    this.session();
  }
  logout() {
    let clientId = this.props.clientId;
    let tokenAccess = this.props.token;
    this.props.logoutUser(clientId, tokenAccess);
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    let auth = Object.keys(this.props.dataUser.userData).length !== 0;

    return (
      <div>
        {auth && (
          <div>
            <IdleTimer
              ref={(ref) => {
                this.idleTimer = ref;
              }}
              timeout={this.state.timeSession}
              onIdle={this.handleOnIdle}
            />

            <ModalSession
              showModal={this.state.viewModal}
              continueSession={this.continueSession.bind(this)}
            />
          </div>
        )}
        <Router history={customHistory}>
          <RedirectLogout redirect={this.state.redirect} />
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                return auth ? (
                  <Redirect to="/account" />
                ) : (
                  <Redirect to="/signin" />
                );
              }}
            />
            <Route
              exact
              path={["/signin", "/requestAccount", "/forgotPassword"]}
            >
              <LayoutPublic>
                <Switch>
                  <Route exact path="/signin" component={Login} />
                  <Route
                    exact
                    path="/requestAccount"
                    component={RequestAccount}
                  />
                  <Route
                    exact
                    path="/forgotPassword"
                    component={ForgotPassword}
                  />
                </Switch>
              </LayoutPublic>
            </Route>
            <Route
              exact
              path={[
                "/account",
                "/settings",
                "/detail",
                "/notifications",
                "/trade",
                "/history",
                "/users",
                "/audit",
                "/approvals"
              ]}
            >
              <LayoutAuthenticated>
                <Switch>
                  <Route
                    exact
                    path="/account"
                    render={() => {
                      return this.validateRoute(Account);
                    }}
                  />
                  <Route
                    exact
                    path="/settings"
                    render={() => {
                      return this.validateRoute(Settings);
                    }}
                  />
                  <Route
                    exact
                    path="/detail"
                    render={() => {
                      return this.validateRoute(TransactionByAsset);
                    }}
                  />
                  <Route
                    exact
                    path="/notifications"
                    render={() => {
                      return this.validateRoute(Notifications);
                    }}
                  />
                  <Route
                    exact
                    path="/trade"
                    render={() => {
                      return this.validateRoute(TradeDetail);
                    }}
                  />
                  <Route
                    exact
                    path="/history"
                    render={() => {
                      return this.validateRoute(History);
                    }}
                  />
                  {CAN("DELETE", "USERS") &&
                    CAN("GET", "USERS") &&
                    CAN("EDIT", "USERS") &&
                    CAN("CREATE", "USERS") && (
                      <Route
                        exact
                        path="/users"
                        render={() => {
                          return this.validateRoute(Users);
                        }}
                      />
                    )}
                  <Route
                    exact
                    path="/audit"
                    render={() => {
                      return this.validateRoute(AuditLogs);
                    }}
                  />
                  <Route
                    exact
                    path="/approvals"
                    render={() => {
                      return this.validateRoute(Approvals);
                    }}
                  />
                </Switch>
              </LayoutAuthenticated>
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser,
    token: state.tokenAccess.current,
    refresh: state.tokenAccess.tokenResponse,
    clientId: state.tokenAccess.clientId,
  };
};
const mapDispatchToProps = {
  resetStore,
  refreshToken,
  currentToken,
  logoutUser,
};
//const RouterLocation = withRouter(Routes);
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
