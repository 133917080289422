import servicesBeneficiaries from "../../services/beneficiaries";
export const ADD_BENEFICIARY = "ADD_BENEFICIARY";
export const GET_BENEFICIARY = "GET_BENEFICIARY";
export const DELETE_BENEFICIARY = "DELETE_BENEFICIARY";
export const EDIT_BENEFICIARY = "EDIT_BENEFICIARY";
export const TYPE_BENEFICIARY = "TYPE_BENEFICIARY"
export function getBeneficiaries(id, token, currency, network) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesBeneficiaries.getBeneficiaries(
        id,
        token,
        currency,
        network
      );
      dispatch({
        type: GET_BENEFICIARY,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_BENEFICIARY,
        payload: error.response,
      });
    }
  };
}
export function addBeneficiary(id, token, body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesBeneficiaries.addBeneficiary(
        id,
        token,
        body
      );
      dispatch({
        type: ADD_BENEFICIARY,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: ADD_BENEFICIARY,
        payload: error.response,
      });
    }
  };
}
export function deleteBeneficiary(id, token, idBeneficiary) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesBeneficiaries.deleteBeneficiary(
        id,
        token,
        idBeneficiary
      );
      dispatch({
        type: DELETE_BENEFICIARY,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: DELETE_BENEFICIARY,
        payload: error.response,
      });
    }
  };
}
export function editBeneficiary(id, token, idBeneficiary, body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesBeneficiaries.editBeneficiary(
        id,
        token,
        idBeneficiary,
        body
      );
      dispatch({
        type: EDIT_BENEFICIARY,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: EDIT_BENEFICIARY,
        payload: error.response,
      });
    }
  };
}
export function typeBeneficiary(type) {
  return (dispatch, getState) => {
      dispatch({
        type: TYPE_BENEFICIARY,
        payload: type,
      });
  };
}

