import servicesUsersChildren from "../../services/usersChildren";
export const CREATE_USER_CHILDREN = "CREATE_USER_CHILDREN";
export const UPDATE_USER_CHILDREN = "UPDATE_USER_CHILDREN";
export const GET_USER_CHILDREN = "GET_USER_CHILDREN";
export const DELETE_USER_CHILDREN = "DELETE_USER_CHILDREN";
export const CHANGE_STATUS = "CHANGE_STATUS";
export function createUser(id, token, body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesUsersChildren.createUsersChildren(
        id,
        token,
        body
      );
      dispatch({
        type: CREATE_USER_CHILDREN,
        payload: response,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: CREATE_USER_CHILDREN,
          payload: error.response,
        });
      }
    }
  };
}
export function updateUser(id, token, userId, body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesUsersChildren.updateUsersChildren(
        id,
        token,
        userId,
        body
      );
      dispatch({
        type: UPDATE_USER_CHILDREN,
        payload: response,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: UPDATE_USER_CHILDREN,
          payload: error.response,
        });
      }
    }
  };
}
export function getUsersChildren(id, token, lastId) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesUsersChildren.getUsersChildren(
        id,
        token,
        lastId
      );
      dispatch({
        type: GET_USER_CHILDREN,
        payload: response,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: GET_USER_CHILDREN,
          payload: error.response,
        });
      }
    }
  };
}
export function changeStatusUsersChildren(id, token, userId, status) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesUsersChildren.changeStatusUsersChildren(
        id,
        token,
        userId,
        status
      );
      dispatch({
        type: CHANGE_STATUS,
        payload: response,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: CHANGE_STATUS,
          payload: error.response,
        });
      }
    }
  };
}
export function deleteUsersChildren(id, token, userId) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesUsersChildren.deleteUsersChildren(
        id,
        token,
        userId
      );
      dispatch({
        type: DELETE_USER_CHILDREN,
        payload: response,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: DELETE_USER_CHILDREN,
          payload: error.response,
        });
      }
    }
  };
}
