import {
  CREATE_USER_CHILDREN,
  UPDATE_USER_CHILDREN,
  GET_USER_CHILDREN,
  DELETE_USER_CHILDREN,
  CHANGE_STATUS,
} from "../actions/usersChildrenActions";
const initialState = {
  respCreate: {},
  resultUsers: {},
  respUpdate: {},
  respDelete: {},
  changeStatus: {},
};

export function userChildren(state = initialState, action) {
  switch (action.type) {
    case CREATE_USER_CHILDREN:
      return Object.assign({}, state, { respCreate: action.payload });
    case UPDATE_USER_CHILDREN:
      return Object.assign({}, state, { respUpdate: action.payload });
    case GET_USER_CHILDREN:
      return Object.assign({}, state, { resultUsers: action.payload });
    case DELETE_USER_CHILDREN:
      return Object.assign({}, state, { respDelete: action.payload });
    case CHANGE_STATUS:
      return Object.assign({}, state, { changeStatus: action.payload });
    default:
      return state;
  }
}
