import React, { Component } from "react";
import "./approvals.css";
import { Row, Col, Typography, Tabs } from "antd";
import COLOR from "../../const/colors";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Pending from "./pending.js";
const { Text } = Typography;
class Approvals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: "1",
      loading: false,
    };
  }
  handleTab(key) {
    this.setState({ tabActive: key });
  }
  render() {
    let items = [
      {
        label: (
          <Text
            style={{
              fontSize: 18,
              color: COLOR.PRIMARY_TEXT,
            }}
          >
            {this.props.t("approvals.pending")}
          </Text>
        ),
        key: "1",
        children: <Pending />,
      },
    ];
    return (
      <>
        <Row gutter={[16, { xs: 8, sm: 16, md: 8, lg: 8, xl: 24 }]}>
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: 22, offset: 1 }}
            xl={{ span: 22, offset: 1 }}
          >
            <div
              id="custom-tab-detail"
              className="ant-tabs ant-tabs-tab ant-tabs-tab-active ant-tabs-tab-btn ant-tabs-nav ant-tabs-nav-list"
            >
              <Tabs
                type="line"
                onTabClick={(key) => this.handleTab(key)}
                activeKey={this.state.tabActive}
                items={items}
                tabBarStyle={{
                  color: COLOR.PRIMARY_TEXT,
                  width: "100%",
                  paddingLeft: "10px",
                  textAlign: "left",
                }}
              ></Tabs>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser,
  };
};

const mapDispatchToProps = {};

const ApprovalsTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Approvals)
);

export default ApprovalsTranslated;
