import React, { Component } from "react";
import Chart from "react-apexcharts";
import "./chartsAccounts.css";
import COLOR from "../../const/colors";
import { Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const { Text } = Typography;

class ChartAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        colors: [COLOR.GREEN],
        chart: {
          events: {
            mounted: (chartContext, config) => {
              setTimeout(() => {
                // force responsive first time
                if (chartContext) chartContext.windowResizeHandler();
              }, 50);
            },
          },
          width: "100%",
          stacked: false,
          background: COLOR.PRIMARY,
          type: "line",
          zoom: {
            enabled: false,
            autoScaleYaxis: true,
          },
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
          dropShadow: {
            enabled: false,
            enabledOnSeries: undefined,
            top: 0,
            left: 0,
            blur: 3,
            color: "#000",
            opacity: 0.35,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          curve: ["smooth", "straight", "stepline"],
          lineCap: "butt",
          colors: undefined,
          width: 2,
          dashArray: 0,
        },
        title: {
          align: "left",
          style: {
            fontSize: "12px",
            fontWeight: "bold",
            fontFamily: "sofia-pro",
            color: COLOR.SECONDARY_TEXT,
          },
        },
        grid: {
          show: false,
          borderColor: "#90A4AE",
          strokeDashArray: 0,
          position: "front",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          row: {
            colors: undefined,
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },

        xaxis: {
          type: "datetime",
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: true,
            trim: true,
            maxHeight: 120,
            style: {
              colors: "#ccc",
              fontSize: "12px",
              fontFamily: "sofia-pro",
              fontWeight: 600,
              cssClass: "apexcharts-xaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
            datetimeUTC: false,
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
          axisBorder: {
            show: false,
            color: "#78909C",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: false,
            borderType: "solid",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
          tickAmount: undefined,
          tickPlacement: "between",
          min: undefined,
          max: undefined,
          range: undefined,
          floating: false,
          position: "bottom",
          title: {
            text: undefined,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "sofia-pro, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-xaxis-title",
            },
          },
          crosshairs: {
            show: true,
            width: 1,
            position: "back",
            opacity: 0.9,
            stroke: {
              color: "#b6b6b6",
              width: 0,
              dashArray: 0,
            },
            fill: {
              type: "solid",
              color: "#B1B9C4",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 1,
              opacity: 0.4,
            },
          },
          tooltip: {
            enabled: false,
            formatter: undefined,
            offsetY: 0,
            style: {
              fontSize: 0,
              fontFamily: 0,
              background: "#00001e",
              color: "#ccc",
            },
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
            borderType: "solid",
            color: "#00001e",
            height: 5,
            offsetX: 0,
            offsetY: 0,
          },
        },
        responsive: [
          {
            breakpoint: 1400,
            options: {
              plotOptions: {
                line: {
                  horizontal: false,
                },
              },
            },
          },
        ],
        tooltip: {
          theme: false,
          x: {
            show: true,
            format: "dd MMM HH:mm",
          },
          y: {
            title: {
              formatter: "",
            },
            formatter: function (value) {
              let balance = value
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,");

              return balance;
            },
          },
        },
      },
    };
  }

  render() {
    return (
      <div>
        {this.props.loading && (
          <div
            style={{
              height: 250,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LoadingOutlined
              style={{
                fontSize: 24,
              }}
              spin
            />
          </div>
        )}
        {!this.props.loading && (
          <>
            {this.props.data.length === 0 ? (
              <div
                style={{
                  height: 250,
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    color: COLOR.GREEN,
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Hmm, you don't have any balance
                </Text>

                <Text
                  style={{
                    color: COLOR.GREEN,
                    fontSize: 16,
                    opacity: 0.5,
                    fontWeight: "bold",
                  }}
                >
                  Deposit some funds and get ready to trade
                </Text>
              </div>
            ) : (
              <Chart
                id="balanceChart"
                options={this.state.options}
                series={[
                  {
                    name: "Balance: " + this.props.nativeSymbol,
                    data: this.props.data,
                  },
                ]}
                type="line"
                height={250}
                style={{ overflow: "hidden" }}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

export default ChartAccounts;
