import React from "react";
import "antd/dist/antd.min.css";
import { Layout } from "antd";
import COLOR from "../const/colors";
import Sidebar from "../components/sidebar/sidebar";
import Footer from "../components/footer/footer";
import Header from "../components/nav/nav";
const { Content } = Layout;
const LayoutAuthenticated = ({ children }) => (
  <Layout style={{ minHeight: "100vh" }}>
    <Sidebar />
    <Layout>
      <Header />
      <Content
        style={{
          background: COLOR.GRAY,
          paddingTop: 80,
          paddingRight: 8,
          marginRight: 0,
          width: "100%",
        }}
      >
        {children}
      </Content>
      <Footer />
    </Layout>
  </Layout>
);
export default LayoutAuthenticated;
