import React, { Component } from "react";
import {
  Row,
  Card,
  Space,
  Typography,
  List,
  Avatar,
  Tag,
  Modal,
  Divider,
  message,
  Skeleton,
  Button,
  Popconfirm,
} from "antd";
import COLOR from "../../const/colors";
import { asset } from "../../common/assets";
import { withTranslation } from "react-i18next";
import { selectedAsset } from "../../redux/actions/navigateActions";
import { currentToken } from "../../redux/actions/tokenActions";
import { getTimeline } from "../../redux/actions/timeline";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import {
  getAllTransactionsOrders,
  transactionCancelation,
} from "../../redux/actions/transactionsActions";
import moment from "moment";
import "./accounts.css";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  SwapOutlined,
  CloseOutlined,
  CopyOutlined,
  CheckOutlined,
  StopOutlined,
} from "@ant-design/icons";
const { Title, Text, Paragraph, Link } = Typography;
const URL_ETH =
  process.env.REACT_APP_ENV === "production"
    ? "https://etherscan.io/tx/"
    : "https://sepolia.etherscan.io/tx/";
const URL_BTC =
  process.env.REACT_APP_ENV === "production"
    ? "https://live.blockcypher.com/btc/address/tx/"
    : "https://live.blockcypher.com/btc-testnet/tx/";
const URL_TRON =
  process.env.REACT_APP_ENV === "production"
    ? "https://tronscan.org/#/transaction/"
    : "https://shasta.tronscan.org/#/transaction/";
class RecentsTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asset: null,
      modalDetailTx: false,
      transactionSelected: "",
      loading: false,
      transactionsRecent: [],
      nativeCurrency: "",
      nativeSymbol: "",
      selectedTransactionId: "",
    };
  }
  componentDidMount() {
    this.getTransactions();
  }
  componentDidUpdate(prevProps) {
    if (this.props.respTransactions !== prevProps.respTransactions) {
      if (this.props.respTransactions.data !== undefined) {
        let info = this.props.respTransactions.data.data;
        if (info !== undefined) {
          this.getTransactions();
        }
      }
    }
  }
  async getTransactions() {
    try {
      this.setState({ loading: true });
      await this.props.getAllTransactionsOrders(
        this.props.token.clientId,
        this.props.token.current
      );
      let response = this.props.respTransactionsOrders;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 500);
      } else if (
        response.status === 400 ||
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404 ||
        response.status === 422
      ) {
        message.error(this.props.t("transactions.error.queryError"));
        this.setState({ loading: false });
      } else if (response.status === 500) {
        message.error(this.props.t("transactions.error.errorServer"));
        this.setState({ loading: false });
      } else {
        message.error(this.props.t("transactions.error.errorServer"));
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }
  formatTransactions(data) {
    let array = [];
    for (let transaction of data) {
      let objTransaction = {};
      Object.entries(transaction).forEach(([key, value]) => {
        objTransaction[key] = value;
        if (key === "account") {
          let data = asset.assets;
          let filterAccounts = data.find(
            (element) => value.currency === element.value
          );
          if (filterAccounts !== undefined) {
            objTransaction.currency = filterAccounts;
          } else {
            let assetInfo = { value: value, name: value };
            objTransaction.currency = assetInfo;
          }
        }
        if (key === "exchange_in_transaction") {
          objTransaction[key] = value;
        }
        if (key === "exchange_out_transaction") {
          objTransaction[key] = value;
        }

        if (key === "created_date") {
          objTransaction.date = this.formatDateView(value);
          objTransaction.dateFormat = this.formatDate(value);
        }

        if (key === "destination") {
          let objDestination = {};
          Object.entries(value).forEach(([innerKey, innerValue]) => {
            objDestination[innerKey] = innerValue;
          });
          objTransaction.destination = objDestination;
        }
        if (key === "source") {
          let objSource = {};
          Object.entries(value).forEach(([innerKey, innerValue]) => {
            objSource[innerKey] = innerValue;
          });
          objTransaction.source = objSource;
        }
      });
      array.push(objTransaction);
    }
    let arrayFinal = [];
    if (array.length >= 10) {
      arrayFinal = array.slice(0, 9);
    } else {
      arrayFinal = array;
    }
    //console.log(arrayFinal);
    return arrayFinal;
  }
  formatDate(dateTransaction) {
    let dateMoment = moment(dateTransaction).format("YYYY/MM/DD HH:mm:ss");
    let date = new Date(Date.parse(dateMoment + " UTC"));
    let dateSplit = date.toString().split(" ");
    let dateCreated = dateSplit[1] + " " + dateSplit[2] + ", " + dateSplit[3];
    return dateCreated;
  }
  formatDateView(dateTransaction) {
    let dateMoment = moment(dateTransaction).format("YYYY/MM/DD HH:mm:ss");
    let date = new Date(dateMoment + " UTC");
    let dateCreated =
      moment(date).format("DD-MM-YYYY") + " " + this.formatAmPm(date);
    return dateCreated;
  }
  formatAmPm(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  openModalDetail(transaction) {
    this.setState({ modalDetailTx: true, transactionSelected: transaction });
  }
  closeModalDetail() {
    this.setState({ modalDetailTx: false, transactionSelected: "" });
  }
  async closeTransaction() {
    // console.log("transaction selected", this.state.selectedTransactionId);
    try {
      this.setState({ loading: true });

      await this.props.transactionCancelation(
        this.props.token.clientId,
        this.props.token.current,
        this.state.selectedTransactionId
      );
      let response = this.props.respCancelTransaction;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        message.success(this.props.t("transactions.messageCancelSuccess"));
        this.getTransactions();
        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 500);
      } else if (
        response.status === 400 ||
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404 ||
        response.status === 422
      ) {
        message.error(this.props.t("transactions.error.queryError"));
        this.setState({ loading: false });
      } else if (response.status === 500) {
        message.error(this.props.t("transactions.error.errorServer"));
        this.setState({ loading: false });
      } else {
        message.error(this.props.t("transactions.error.errorServer"));
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log("error", error);
      this.setState({ loading: false });
    }
  }
  showUrl() {
    let tx = this.state.transactionSelected;
    let url = "";
    if (tx.account.currency === "BTC") {
      url = URL_BTC + tx.transaction_hash;
    } else if (tx.account.currency === "ETH") {
      url = URL_ETH + tx.transaction_hash;
    } else {
      if (
        tx.destination?.network === "ERC20" ||
        tx.source?.network === "ERC20"
      ) {
        url = URL_ETH + tx.transaction_hash;
      } else {
        url = URL_TRON + tx.transaction_hash;
      }
    }
    return url;
  }

  render() {
    let response = this.props.respTransactionsOrders;
    let dataUser = this.props.dataUser.data?.data?.user;
    let feeType = dataUser?.trading_fee_type;
    let userType = dataUser.type;
    let transactionsRecent = [];
    if (response) {
      if (response.status === 200) {
        let info = response.data.data;
        transactionsRecent = this.formatTransactions(info.transactions);
      }
    }
    return (
      <>
        <Card
          title={
            <Title
              level={4}
              style={{
                color: COLOR.PRIMARY_TEXT,
                textAlign: "left",
              }}
            >
              {this.props.t("transactions.title")}
            </Title>
          }
          bordered={false}
          size="small"
          style={{
            width: "100%",
            borderRadius: "5px",
            background: COLOR.PRIMARY,
            color: COLOR.SECONDARY_TEXT,
            border: "1px solid #000",
          }}
        >
          {this.state.loading && <Skeleton active />}
          {!this.state.loading && (
            <div
              id="transactions-accounts"
              className="ant-list-split ant-list-item ant-list-item-meta-description ant-list-item-meta-title"
            >
              <List
                locale={{
                  emptyText: (
                    <Text
                      style={{
                        color: COLOR.GREEN,
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      {this.props.t("transactions.noTransactions")}
                    </Text>
                  ),
                }}
                dataSource={transactionsRecent}
                style={{
                  background: COLOR.PRIMARY,
                  color: COLOR.SECONDARY_TEXT,
                }}
                renderItem={(item) => (
                  <List.Item key={item.id} style={{ cursor: "pointer" }}>
                    <List.Item.Meta
                      style={{ alignItems: "center" }}
                      avatar={
                        <>
                          {(item.type === "TRANSFER_OUT" ||
                            item.type === "WITHDRAWAL") && (
                            <Avatar
                              onClick={() => this.openModalDetail(item)}
                              size="large"
                              src={
                                <ArrowUpOutlined
                                  style={{
                                    stroke: "#fff",
                                    strokeWidth: "50px",
                                  }}
                                />
                              }
                              className="circle-icon-transactions"
                            />
                          )}
                          {(item.type === "TRANSFER_IN" ||
                            item.type === "DEPOSIT") && (
                            <Avatar
                              onClick={() => this.openModalDetail(item)}
                              size="large"
                              src={
                                <ArrowDownOutlined
                                  style={{
                                    stroke: "#fff",
                                    strokeWidth: "50px",
                                  }}
                                />
                              }
                              className="circle-icon-transactions"
                            />
                          )}
                          {(item.type === "EXCHANGE" ||
                            item.type.includes("REBALANCE")) && (
                            <Avatar
                              onClick={() => this.openModalDetail(item)}
                              size="large"
                              src={
                                <SwapOutlined
                                  style={{
                                    stroke: "#fff",
                                    strokeWidth: "50px",
                                  }}
                                />
                              }
                              className="circle-icon-transactions"
                            />
                          )}
                        </>
                      }
                      title={
                        <Text
                          onClick={() => this.openModalDetail(item)}
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        >
                          {item.type.includes("REBALANCE") && (
                            <>
                              {this.props.t("transactions.type." + item.type) +
                                " " +
                                item.currency.value}
                            </>
                          )}
                          {item.type === "EXCHANGE" && (
                            <>
                              {this.props.t("transactions.exchange", {
                                base: item.base_currency,
                                quote: item.quote_currency,
                              })}
                            </>
                          )}
                          {item.account !== undefined && (
                            <>
                              {item.account.type === "FIAT" && (
                                <>
                                  {(item.type === "WITHDRAWAL" ||
                                    item.type === "TRANSFER_OUT") && (
                                    <>
                                      {Object.keys(item.destination).length >
                                        0 && (
                                        <>
                                          {item.currency.value +
                                            " " +
                                            this.props.t("transactions.sent") +
                                            " "}
                                          {item.destination.type.includes(
                                            "INTERNAL"
                                          )
                                            ? item.destination.account_holder
                                            : item.destination.description}
                                        </>
                                      )}
                                    </>
                                  )}
                                  {(item.type === "DEPOSIT" ||
                                    item.type === "TRANSFER_IN") && (
                                    <>
                                      {Object.keys(item.source).length > 0 && (
                                        <>
                                          {item.currency.value + " "}
                                          {this.props.t(
                                            "transactions.received"
                                          ) + " "}
                                          {item.source.type ===
                                          "EXTERNAL_CIRCLE"
                                            ? item.source.billing_name +
                                              " - " +
                                              item.source.bank_name +
                                              " " +
                                              item.source.account_number
                                            : item.source.type.includes(
                                                "INTERNAL"
                                              )
                                            ? item.source.account_holder
                                            : item.source.description}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              {item.account.type === "CRYPTO" && (
                                <>
                                  {(item.type === "WITHDRAWAL" ||
                                    item.type === "TRANSFER_OUT") && (
                                    <>
                                      {item.currency.value +
                                        " " +
                                        this.props.t("transactions.sent") +
                                        " "}
                                      {item.destination !== undefined &&
                                      item.destination.type ===
                                        "EXTERNAL_CIRCLE"
                                        ? item.destination.billing_name +
                                          " - " +
                                          item.destination.bank_name +
                                          " " +
                                          item.destination.account_number
                                        : item.destination.description}
                                    </>
                                  )}
                                  {(item.type === "DEPOSIT" ||
                                    item.type === "TRANSFER_IN") && (
                                    <>
                                      {item.source !== undefined && (
                                        <>
                                          {item.currency.value + " "}
                                          {this.props.t(
                                            "transactions.received"
                                          ) + " "}
                                          {item.source.type ===
                                            "EXTERNAL_CIRCLE" &&
                                            item.source.billing_name +
                                              " - " +
                                              item.source.bank_name +
                                              " " +
                                              item.source.account_number}
                                          {item.source.type === "EXTERNAL" &&
                                            item.currency.value !== "BTC" && (
                                              <>
                                                {item.source.address !==
                                                  undefined && (
                                                  <>
                                                    {item.source.address
                                                      .length > 24
                                                      ? item.source.address.substr(
                                                          0,
                                                          8
                                                        ) + "..."
                                                      : item.source.address}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          {item.source.type !==
                                            "EXTERNAL_CIRCLE" &&
                                            item.source.type !== "EXTERNAL" &&
                                            item.source.description}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </Text>
                      }
                      description={
                        <Text
                          onClick={() => this.openModalDetail(item)}
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            fontSize: 14,
                            fontWeight: "normal",
                          }}
                        >
                          {item.type === "EXCHANGE" && (
                            <>
                              <NumberFormat
                                allowNegative={true}
                                thousandSeparator={true}
                                displayType="text"
                                decimalScale={
                                  item.quote_currency_type === "CRYPTO" ? 8 : 2
                                }
                                value={
                                  item.exchange_in_transaction.total_amount
                                }
                              />
                              {" " +
                                item.quote_currency +
                                " on " +
                                item.dateFormat}
                            </>
                          )}
                          {item.account !== undefined && (
                            <>
                              <NumberFormat
                                prefix={
                                  item.type === "TRANSFER_OUT" ||
                                  item.type === "WITHDRAWAL"
                                    ? "-"
                                    : ""
                                }
                                allowNegative={true}
                                thousandSeparator={true}
                                displayType="text"
                                decimalScale={
                                  item.account.type === "CRYPTO" ? 8 : 2
                                }
                                value={
                                  item.type === "TRANSFER_OUT" ||
                                  item.type === "WITHDRAWAL"
                                    ? item.destination?.type ===
                                      "EXTERNAL_CIRCLE"
                                      ? item.total_amount
                                      : item.amount
                                    : item.total_amount
                                }
                              />
                              {" " +
                                item.account.currency +
                                " on " +
                                item.dateFormat}
                            </>
                          )}

                          <br />
                          {item.status === "COMPLETED" && (
                            <Tag
                              style={{
                                marginTop: 5,
                                color: "#389E0D",
                                background: "#F6FFED",
                                borderColor: "#B7EB8F",
                                fontSize: 12,
                              }}
                            >
                              {this.props.t(
                                "transactions.status." + item.status
                              )}
                            </Tag>
                          )}
                          {item.status === "PROCESSING" && (
                            <Tag
                              style={{
                                marginTop: 5,
                                color: "#08979C",
                                background: "#E6FFFB",
                                borderColor: "#87E8DE",
                                fontSize: 12,
                              }}
                            >
                              {this.props.t(
                                "transactions.status." + item.status
                              )}
                            </Tag>
                          )}
                          {item.status === "INITIATED" && (
                            <Tag
                              style={{
                                marginTop: 5,
                                color: "#096DD9",
                                background: "#E6F7FF",
                                borderColor: "#91D5FF",
                                fontSize: 12,
                              }}
                            >
                              {this.props.t(
                                "transactions.status." + item.status
                              )}
                            </Tag>
                          )}
                          {item.status === "PENDING" && (
                            <Tag
                              style={{
                                marginTop: 5,
                                color: "#531DAB",
                                background: "#F9F0FF",
                                borderColor: "#D3ADF7",
                                fontSize: 12,
                              }}
                            >
                              {this.props.t(
                                "transactions.status." + item.status
                              )}
                            </Tag>
                          )}
                          {item.status === "REJECTED" && (
                            <Tag
                              style={{
                                marginTop: 5,
                                color: "#D4380D",
                                background: "#FFF2E8",
                                borderColor: "#FFBB96",
                                fontSize: 12,
                              }}
                            >
                              {this.props.t(
                                "transactions.status." + item.status
                              )}
                            </Tag>
                          )}
                          {item.status === "FAILED" ||
                            item.status === "ERROR" ||
                            (item.status === "CANCELLED" && (
                              <Tag
                                style={{
                                  marginTop: 5,
                                  color: "#CF1322",
                                  background: "#FFF1F0",
                                  borderColor: "#FFA39E",
                                  fontSize: 12,
                                }}
                              >
                                {item.status !== "CANCELLED"
                                  ? this.props.t("transactions.status.FAILED")
                                  : this.props.t(
                                      "transactions.status." + item.status
                                    )}
                              </Tag>
                            ))}
                          {item.status === "CLIENT_APPROVAL_PENDING" && (
                            <Tag
                              color="gold"
                              style={{
                                fontSize: 14,
                              }}
                            >
                              {this.props.t(
                                "transactions.status.CLIENT_APPROVAL_PENDING"
                              )}
                            </Tag>
                          )}
                          {item.status === "APPROVAL_EXPIRED" && (
                            <Tag
                              color="magenta"
                              style={{
                                fontSize: 14,
                              }}
                            >
                              {this.props.t(
                                "transactions.status.APPROVAL_EXPIRED"
                              )}
                            </Tag>
                          )}
                          {item.status === "AML_HOLD" && (
                            <Tag
                              color="gold"
                              style={{
                                fontSize: 14,
                              }}
                            >
                              {this.props.t("transactions.status.AML_HOLD")}
                            </Tag>
                          )}
                          {item.status === "AML_FREEZE" && (
                            <Tag
                              color="geekblue"
                              style={{
                                fontSize: 14,
                              }}
                            >
                              {this.props.t("transactions.status.AML_FREEZE")}
                            </Tag>
                          )}
                          {item.status === "PENDING_SETTLEMENT" && (
                            <Tag
                              color="geekblue"
                              style={{
                                fontSize: 14,
                              }}
                            >
                              {this.props.t(
                                "transactions.status." + item.status
                              )}
                            </Tag>
                          )}
                        </Text>
                      }
                    />
                    {item.type === "WITHDRAWAL" &&
                      item.status === "PENDING_SETTLEMENT" && (
                        <>
                          <Popconfirm
                            onOpenChange={() => {
                              this.setState({
                                selectedTransactionId: item._id,
                              });
                              //setSelectedId(item._id);
                            }}
                            onConfirm={() => {
                              this.closeTransaction();
                            }}
                            onCancel={() => {
                              this.setState({ selectedTransactionId: "" });
                            }}
                            title={this.props.t(
                              "transactions.popUpMessage.confirmMessage"
                            )}
                            okText={this.props.t(
                              "transactions.popUpMessage.confirmButton"
                            )}
                            cancelText={this.props.t(
                              "transactions.popUpMessage.cancelButton"
                            )}
                            cancelButtonProps={{
                              className: "btn-cancel-pop",
                            }}
                          >
                            <Button
                              style={{ marginRight: 20 }}
                              type="text"
                              shape="circle"
                              icon={
                                <StopOutlined
                                  style={{ color: "red", fontSize: 20 }}
                                />
                              }
                            />
                          </Popconfirm>
                        </>
                      )}
                  </List.Item>
                )}
              ></List>{" "}
            </div>
          )}
        </Card>

        {this.state.modalDetailTx && (
          <Modal
            open={this.state.modalDetailTx}
            bodyStyle={{ background: COLOR.PRIMARY }}
            maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
            onOk={() => this.openModalDetail()}
            onCancel={() => this.closeModalDetail()}
            footer={null}
            closeIcon={<CloseOutlined style={{ color: COLOR.PRIMARY_TEXT }} />}
          >
            <Title level={4} style={{ color: COLOR.PRIMARY_TEXT }}>
              {this.props.t("transactions.modal.modalTitle")}{" "}
              {this.state.transactionSelected.type !== "EXCHANGE"
                ? this.state.transactionSelected._id
                : this.state.transactionSelected.order_id}
              <br />
              {this.props.t(
                "transactions.type." + this.state.transactionSelected.type
              )}
            </Title>
            <br />

            {this.state.transactionSelected.type !== "EXCHANGE" && (
              <>
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <Row justify="space-between">
                  <Space direction="vertical">
                    <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                      {this.props.t("transactions.modal.amount")}
                    </Text>
                    <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                      <NumberFormat
                        allowNegative={true}
                        thousandSeparator={true}
                        suffix={
                          " " + this.state.transactionSelected.account.currency
                        }
                        displayType="text"
                        style={{
                          color: COLOR.PRIMARY_TEXT,
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                        decimalScale={
                          this.state.transactionSelected.account.type ===
                          "CRYPTO"
                            ? 8
                            : 2
                        }
                        value={
                          this.state.transactionSelected.type ===
                            "TRANSFER_OUT" ||
                          this.state.transactionSelected.type === "WITHDRAWAL"
                            ? this.state.transactionSelected.destination
                                ?.type === "EXTERNAL_CIRCLE"
                              ? this.state.transactionSelected.total_amount
                              : this.state.transactionSelected.amount
                            : this.state.transactionSelected.total_amount
                        }
                      />
                    </Text>
                  </Space>
                  {this.state.transactionSelected.destination?.type !==
                    "EXTERNAL_CIRCLE" &&
                    this.state.transactionSelected.source?.type !==
                      "EXTERNAL_CIRCLE" && (
                      <Space direction="vertical">
                        <Text
                          style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}
                        >
                          {this.props.t("transactions.modal.fee")}
                        </Text>
                        <Text
                          style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}
                        >
                          <NumberFormat
                            allowNegative={true}
                            thousandSeparator={true}
                            suffix={
                              " " +
                              this.state.transactionSelected.account.currency
                            }
                            displayType="text"
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              fontSize: 16,
                              fontWeight: 400,
                            }}
                            decimalScale={
                              this.state.transactionSelected.account.type ===
                              "CRYPTO"
                                ? 8
                                : 2
                            }
                            value={this.state.transactionSelected.fees}
                          />
                        </Text>
                      </Space>
                    )}
                  <Space direction="vertical">
                    <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                      {this.props.t("transactions.modal.status")}
                    </Text>
                    {this.state.transactionSelected.status === "COMPLETED" && (
                      <Tag
                        style={{
                          color: "#389E0D",
                          background: "#F6FFED",
                          borderColor: "#B7EB8F",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.status === "PROCESSING" && (
                      <Tag
                        style={{
                          color: "#08979C",
                          background: "#E6FFFB",
                          borderColor: "#87E8DE",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.status === "INITIATED" && (
                      <Tag
                        style={{
                          color: "#096DD9",
                          background: "#E6F7FF",
                          borderColor: "#91D5FF",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.status === "PENDING" && (
                      <Tag
                        style={{
                          color: "#531DAB",
                          background: "#F9F0FF",
                          borderColor: "#D3ADF7",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.status === "REJECTED" && (
                      <Tag
                        style={{
                          color: "#D4380D",
                          background: "#FFF2E8",
                          borderColor: "#FFBB96",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.status === "FAILED" && (
                      <Tag
                        style={{
                          color: "#CF1322",
                          background: "#FFF1F0",
                          borderColor: "#FFA39E",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.status ===
                      "CLIENT_APPROVAL_PENDING" && (
                      <Tag
                        color="gold"
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status.CLIENT_APPROVAL_PENDING"
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.status ===
                      "APPROVAL_EXPIRED" && (
                      <Tag
                        color="magenta"
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {this.props.t("transactions.status.APPROVAL_EXPIRED")}
                      </Tag>
                    )}
                    {this.state.transactionSelected.status === "AML_HOLD" && (
                      <Tag
                        color="gold"
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {this.props.t("transactions.status.AML_HOLD")}
                      </Tag>
                    )}
                    {this.state.transactionSelected.status === "AML_FREEZE" && (
                      <Tag
                        color="geekblue"
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {this.props.t("transactions.status.AML_FREEZE")}
                      </Tag>
                    )}
                    {this.state.transactionSelected.status === "CANCELLED" && (
                      <Tag
                        style={{
                          marginTop: 5,
                          color: "#CF1322",
                          background: "#FFF1F0",
                          borderColor: "#FFA39E",
                          fontSize: 12,
                        }}
                      >
                        {this.props.t("transactions.status.CANCELLED")}
                      </Tag>
                    )}
                    {this.state.transactionSelected.status ===
                      "PENDING_SETTLEMENT" && (
                      <Tag
                        color="geekblue"
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected.status
                        )}
                      </Tag>
                    )}
                  </Space>
                </Row>
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <br />
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <Row justify="space-between">
                  <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                    {this.props.t("transactions.modal.type")}
                  </Text>
                  <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                    {this.props.t(
                      "transactions.type." + this.state.transactionSelected.type
                    )}
                  </Text>
                </Row>
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <Row justify="space-between">
                  <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                    Ref-ID
                  </Text>
                  <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                    <Paragraph
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        fontSize: 16,
                        marginBottom: 0,
                      }}
                      ellipsis={true}
                      copyable={{
                        text: this.state.transactionSelected._id,
                        icon: [
                          <CopyOutlined className="icon-copy-address" />,
                          <CheckOutlined className="icon-copied-address" />,
                        ],
                        tooltips: false,
                      }}
                    >
                      {this.state.transactionSelected._id}
                    </Paragraph>
                  </Text>
                </Row>{" "}
              </>
            )}
            {this.state.transactionSelected.type === "EXCHANGE" && (
              <>
                <Divider
                  orientation="left"
                  style={{
                    color: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                    borderTopColor: COLOR.SECONDARY_TEXT,
                  }}
                >
                  {this.props.t(
                    "transactions.type." +
                      this.state.transactionSelected.exchange_in_transaction
                        .type
                  )}
                </Divider>

                <Row justify="space-between">
                  <Space direction="vertical">
                    <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                      {this.props.t("transactions.modal.volume")}
                    </Text>
                    <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                      <NumberFormat
                        allowNegative={true}
                        thousandSeparator={true}
                        suffix={
                          " " +
                          this.state.transactionSelected.exchange_in_transaction
                            .account.currency
                        }
                        displayType="text"
                        style={{
                          color: COLOR.PRIMARY_TEXT,
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                        decimalScale={
                          this.state.transactionSelected.exchange_in_transaction
                            .account.type === "CRYPTO"
                            ? 8
                            : 2
                        }
                        value={this.state.transactionSelected.volume_received}
                      />
                    </Text>
                  </Space>
                  {feeType === "TIERS" && (
                    <Space direction="vertical">
                      <Text
                        style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}
                      >
                        {this.props.t("transactions.modal.fee")}
                      </Text>
                      <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                        <NumberFormat
                          allowNegative={true}
                          thousandSeparator={true}
                          suffix={
                            " " +
                            this.state.transactionSelected
                              .exchange_in_transaction.account.currency
                          }
                          displayType="text"
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                          decimalScale={
                            this.state.transactionSelected
                              .exchange_in_transaction.account.type === "CRYPTO"
                              ? 8
                              : 2
                          }
                          value={
                            this.state.transactionSelected
                              .exchange_in_transaction.fees
                          }
                        />
                      </Text>
                    </Space>
                  )}
                  <Space direction="vertical">
                    <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                      {this.props.t("transactions.modal.status")}
                    </Text>
                    {this.state.transactionSelected.exchange_in_transaction
                      .status === "COMPLETED" && (
                      <Tag
                        style={{
                          color: "#389E0D",
                          background: "#F6FFED",
                          borderColor: "#B7EB8F",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected
                              .exchange_in_transaction.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.exchange_in_transaction
                      .status === "PROCESSING" && (
                      <Tag
                        style={{
                          color: "#08979C",
                          background: "#E6FFFB",
                          borderColor: "#87E8DE",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected
                              .exchange_in_transaction.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.exchange_in_transaction
                      .status === "INITIATED" && (
                      <Tag
                        style={{
                          color: "#096DD9",
                          background: "#E6F7FF",
                          borderColor: "#91D5FF",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected
                              .exchange_in_transaction.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.exchange_in_transaction
                      .status === "PENDING" && (
                      <Tag
                        style={{
                          color: "#531DAB",
                          background: "#F9F0FF",
                          borderColor: "#D3ADF7",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected
                              .exchange_in_transaction.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.exchange_in_transaction
                      .status === "REJECTED" && (
                      <Tag
                        style={{
                          color: "#D4380D",
                          background: "#FFF2E8",
                          borderColor: "#FFBB96",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected
                              .exchange_in_transaction.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.exchange_in_transaction
                      .status === "FAILED" && (
                      <Tag
                        style={{
                          color: "#CF1322",
                          background: "#FFF1F0",
                          borderColor: "#FFA39E",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected
                              .exchange_in_transaction.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.exchange_in_transaction
                      .status === "CANCELLED" && (
                      <Tag
                        style={{
                          marginTop: 5,
                          color: "#CF1322",
                          background: "#FFF1F0",
                          borderColor: "#FFA39E",
                          fontSize: 12,
                        }}
                      >
                        {this.props.t("transactions.status.CANCELLED")}
                      </Tag>
                    )}
                    {this.state.transactionSelected.exchange_in_transaction
                      .status === "PENDING_SETTLEMENT" && (
                      <Tag
                        color="geekblue"
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected
                              .exchange_in_transaction.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.exchange_in_transaction
                      .status === "CLIENT_APPROVAL_PENDING" && (
                      <Tag
                        color="gold"
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status.CLIENT_APPROVAL_PENDING"
                        )}
                      </Tag>
                    )}
                  </Space>
                </Row>
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <br />

                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <Row justify="space-between">
                  <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                    Ref-ID
                  </Text>
                  <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                    <Paragraph
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        fontSize: 16,
                        marginBottom: 0,
                      }}
                      ellipsis={true}
                      copyable={{
                        text: this.state.transactionSelected
                          .exchange_in_transaction._id,
                        icon: [
                          <CopyOutlined className="icon-copy-address" />,
                          <CheckOutlined className="icon-copied-address" />,
                        ],
                        tooltips: false,
                      }}
                    >
                      {
                        this.state.transactionSelected.exchange_in_transaction
                          ._id
                      }
                    </Paragraph>
                  </Text>
                </Row>
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <Row justify="space-between">
                  <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                    {this.props.t("transactions.modal.asset")}
                  </Text>
                  <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                    {
                      this.state.transactionSelected.exchange_in_transaction
                        .account.currency
                    }
                  </Text>
                </Row>
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <Row justify="space-between">
                  <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                    {this.props.t("transactions.modal.date")}
                  </Text>
                  <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                    {this.formatDateView(
                      this.state.transactionSelected.exchange_in_transaction
                        .created_date
                    )}
                  </Text>
                </Row>
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <Row justify="space-between">
                  <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                    {this.props.t("transactions.modal.reference")}
                  </Text>
                  <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                    {
                      this.state.transactionSelected.exchange_in_transaction
                        .reference
                    }
                  </Text>
                </Row>
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <br />
                <Divider
                  orientation="left"
                  style={{
                    color: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                    borderTopColor: COLOR.SECONDARY_TEXT,
                  }}
                >
                  {this.props.t(
                    "transactions.type." +
                      this.state.transactionSelected.exchange_out_transaction
                        .type
                  )}
                </Divider>
                <Row justify="space-between">
                  <Space direction="vertical">
                    <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                      {this.props.t("transactions.modal.cost")}
                    </Text>
                    <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                      <NumberFormat
                        allowNegative={true}
                        thousandSeparator={true}
                        suffix={
                          " " +
                          this.state.transactionSelected
                            .exchange_out_transaction.account.currency
                        }
                        displayType="text"
                        style={{
                          color: COLOR.PRIMARY_TEXT,
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                        decimalScale={
                          this.state.transactionSelected
                            .exchange_out_transaction.account.type === "CRYPTO"
                            ? 8
                            : 2
                        }
                        value={this.state.transactionSelected.cost}
                      />
                    </Text>
                  </Space>
                  <Space direction="vertical">
                    <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                      {this.props.t("transactions.modal.price")}
                    </Text>
                    <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                      <NumberFormat
                        allowNegative={true}
                        thousandSeparator={true}
                        suffix={
                          " " +
                          this.state.transactionSelected.quote_currency +
                          " /"
                        }
                        displayType="text"
                        style={{
                          color: COLOR.PRIMARY_TEXT,
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                        decimalScale={
                          this.state.transactionSelected.quote_currency_type ===
                          "CRYPTO"
                            ? 8
                            : 2
                        }
                        value={this.state.transactionSelected.price}
                      />
                    </Text>
                    <Text
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        fontSize: 16,
                        fontWeight: 400,
                      }}
                    >
                      1 {this.state.transactionSelected.base_currency}
                    </Text>
                  </Space>
                  <Space direction="vertical">
                    <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                      {this.props.t("transactions.modal.status")}
                    </Text>
                    {this.state.transactionSelected.exchange_out_transaction
                      .status === "COMPLETED" && (
                      <Tag
                        style={{
                          color: "#389E0D",
                          background: "#F6FFED",
                          borderColor: "#B7EB8F",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected
                              .exchange_out_transaction.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.exchange_out_transaction
                      .status === "PROCESSING" && (
                      <Tag
                        style={{
                          color: "#08979C",
                          background: "#E6FFFB",
                          borderColor: "#87E8DE",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected
                              .exchange_out_transaction.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.exchange_out_transaction
                      .status === "INITIATED" && (
                      <Tag
                        style={{
                          color: "#096DD9",
                          background: "#E6F7FF",
                          borderColor: "#91D5FF",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected
                              .exchange_out_transaction.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.exchange_out_transaction
                      .status === "PENDING" && (
                      <Tag
                        style={{
                          color: "#531DAB",
                          background: "#F9F0FF",
                          borderColor: "#D3ADF7",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected
                              .exchange_out_transaction.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.exchange_out_transaction
                      .status === "REJECTED" && (
                      <Tag
                        style={{
                          color: "#D4380D",
                          background: "#FFF2E8",
                          borderColor: "#FFBB96",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected
                              .exchange_out_transaction.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.exchange_out_transaction
                      .status === "FAILED" && (
                      <Tag
                        style={{
                          color: "#CF1322",
                          background: "#FFF1F0",
                          borderColor: "#FFA39E",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected
                              .exchange_out_transaction.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.exchange_out_transaction
                      .status === "CANCELLED" && (
                      <Tag
                        style={{
                          marginTop: 5,
                          color: "#CF1322",
                          background: "#FFF1F0",
                          borderColor: "#FFA39E",
                          fontSize: 12,
                        }}
                      >
                        {this.props.t("transactions.status.CANCELLED")}
                      </Tag>
                    )}
                    {this.state.transactionSelected.exchange_out_transaction
                      .status === "PENDING_SETTLEMENT" && (
                      <Tag
                        color="geekblue"
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status." +
                            this.state.transactionSelected
                              .exchange_out_transaction.status
                        )}
                      </Tag>
                    )}
                    {this.state.transactionSelected.exchange_out_transaction
                      .status === "CLIENT_APPROVAL_PENDING" && (
                      <Tag
                        color="gold"
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {this.props.t(
                          "transactions.status.CLIENT_APPROVAL_PENDING"
                        )}
                      </Tag>
                    )}
                  </Space>
                </Row>
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <br />
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <Row justify="space-between">
                  <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                    Ref-ID
                  </Text>
                  <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                    <Paragraph
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        fontSize: 16,
                        marginBottom: 0,
                      }}
                      ellipsis={true}
                      copyable={{
                        text: this.state.transactionSelected
                          .exchange_out_transaction._id,
                        icon: [
                          <CopyOutlined className="icon-copy-address" />,
                          <CheckOutlined className="icon-copied-address" />,
                        ],
                        tooltips: false,
                      }}
                    >
                      {
                        this.state.transactionSelected.exchange_out_transaction
                          ._id
                      }
                    </Paragraph>
                  </Text>
                </Row>
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <Row justify="space-between">
                  <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                    {this.props.t("transactions.modal.asset")}
                  </Text>
                  <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                    {
                      this.state.transactionSelected.exchange_out_transaction
                        .account.currency
                    }
                  </Text>
                </Row>
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <Row justify="space-between">
                  <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                    {this.props.t("transactions.modal.date")}
                  </Text>
                  <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                    {this.formatDateView(
                      this.state.transactionSelected.exchange_out_transaction
                        .created_date
                    )}
                  </Text>
                </Row>
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <Row justify="space-between">
                  <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                    {this.props.t("transactions.modal.reference")}
                  </Text>
                  <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                    {
                      this.state.transactionSelected.exchange_out_transaction
                        .reference
                    }
                  </Text>
                </Row>
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
              </>
            )}
            {this.state.transactionSelected.type !== "EXCHANGE" && (
              <>
                {this.state.transactionSelected.account.type === "CRYPTO" &&
                  this.state.transactionSelected.transaction_hash !==
                    undefined &&
                  this.state.transactionSelected.transaction_hash !== "" && (
                    <div>
                      <Divider
                        style={{
                          background: COLOR.SECONDARY_TEXT,
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      />
                      <Row justify="space-between">
                        <Text
                          style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}
                        >
                          {this.props.t("transactions.modal.id")}
                        </Text>
                        <Paragraph
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            fontSize: 16,
                            marginBottom: 0,
                          }}
                          ellipsis={true}
                          copyable={{
                            text: this.state.transactionSelected
                              .transaction_hash,
                            icon: [
                              <CopyOutlined className="icon-copy-address" />,
                              <CheckOutlined className="icon-copied-address" />,
                            ],
                            tooltips: false,
                          }}
                        >
                          <Link
                            style={{ color: COLOR.GREEN }}
                            href={this.showUrl()}
                            target="_blank"
                          >
                            {this.state.transactionSelected.transaction_hash
                              .length > 24 &&
                              this.state.transactionSelected.transaction_hash.substr(
                                0,
                                12
                              ) +
                                "..." +
                                this.state.transactionSelected.transaction_hash.substr(
                                  this.state.transactionSelected
                                    .transaction_hash.length - 12,
                                  this.state.transactionSelected
                                    .transaction_hash.length
                                )}
                          </Link>
                        </Paragraph>
                      </Row>
                    </div>
                  )}
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <Row justify="space-between">
                  <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                    {this.props.t("transactions.modal.asset")}
                  </Text>
                  <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                    {this.state.transactionSelected.account.currency}
                  </Text>
                </Row>
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <Row justify="space-between">
                  <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                    {this.props.t("transactions.modal.date")}
                  </Text>
                  <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                    {this.state.transactionSelected.date}
                  </Text>
                </Row>
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <Row justify="space-between">
                  <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                    {this.props.t("transactions.modal.reference")}
                  </Text>
                  <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                    {this.state.transactionSelected.reference}{" "}
                    {this.state.transactionSelected.type.includes(
                      "REBALANCE"
                    ) && this.state.transactionSelected.account.currency}
                  </Text>
                </Row>
                {this.state.transactionSelected.account.type === "CRYPTO" && (
                  <div>
                    {this.state.transactionSelected.destination !==
                      undefined && (
                      <div>
                        {!this.state.transactionSelected.destination.type.includes(
                          "INTERNAL_BENEFICIARY"
                        ) &&
                          this.state.transactionSelected.destination.address !==
                            undefined &&
                          this.state.transactionSelected.destination.address !==
                            "" && (
                            <div>
                              <Divider
                                style={{
                                  background: COLOR.SECONDARY_TEXT,
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              />
                              <Row justify="space-between">
                                <Text
                                  style={{
                                    color: COLOR.SECONDARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  {this.props.t("transactions.modal.address")}
                                </Text>
                                <Paragraph
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    fontSize: 16,
                                    marginBottom: 0,
                                  }}
                                  ellipsis={true}
                                  copyable={{
                                    text: this.state.transactionSelected
                                      .destination.address,
                                    icon: [
                                      <CopyOutlined className="icon-copy-address" />,
                                      <CheckOutlined className="icon-copied-address" />,
                                    ],
                                    tooltips: false,
                                  }}
                                >
                                  {this.state.transactionSelected.destination
                                    .address !== undefined && (
                                    <>
                                      {this.state.transactionSelected
                                        .destination.address.length > 24 &&
                                        this.state.transactionSelected.destination.address.substr(
                                          0,
                                          12
                                        ) +
                                          "..." +
                                          this.state.transactionSelected.destination.address.substr(
                                            this.state.transactionSelected
                                              .destination.address.length - 12,
                                            this.state.transactionSelected
                                              .destination.address.length
                                          )}
                                    </>
                                  )}
                                </Paragraph>
                              </Row>
                            </div>
                          )}
                      </div>
                    )}
                    {this.state.transactionSelected.source !== undefined && (
                      <div>
                        {(this.state.transactionSelected.source.type ===
                          "INTERNAL" ||
                          this.state.transactionSelected.source.type ===
                            "EXTERNAL") &&
                          this.state.transactionSelected.account.currency !==
                            "BTC" &&
                          this.state.transactionSelected.source.address !==
                            undefined &&
                          this.state.transactionSelected.source.address !==
                            "" && (
                            <div>
                              <Divider
                                style={{
                                  background: COLOR.SECONDARY_TEXT,
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              />
                              <Row justify="space-between">
                                <Text
                                  style={{
                                    color: COLOR.SECONDARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  {this.props.t("transactions.modal.address")}
                                </Text>
                                <Paragraph
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    fontSize: 16,
                                    marginBottom: 0,
                                  }}
                                  ellipsis={true}
                                  copyable={{
                                    text:
                                      this.state.transactionSelected.source
                                        .address !== undefined
                                        ? this.state.transactionSelected.source
                                            .address
                                        : "",
                                    icon: [
                                      <CopyOutlined className="icon-copy-address" />,
                                      <CheckOutlined className="icon-copied-address" />,
                                    ],
                                    tooltips: false,
                                  }}
                                >
                                  {this.state.transactionSelected.source
                                    .address !== undefined && (
                                    <>
                                      {this.state.transactionSelected.source
                                        .address.length > 24 &&
                                        this.state.transactionSelected.source.address.substr(
                                          0,
                                          12
                                        ) +
                                          "..." +
                                          this.state.transactionSelected.source.address.substr(
                                            this.state.transactionSelected
                                              .source.address.length - 12,
                                            this.state.transactionSelected
                                              .source.address.length
                                          )}
                                    </>
                                  )}
                                </Paragraph>
                              </Row>
                            </div>
                          )}
                      </div>
                    )}

                    <Divider
                      style={{
                        background: COLOR.SECONDARY_TEXT,
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    />
                    <Row justify="space-between">
                      <Text
                        style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}
                      >
                        {this.props.t("transactions.modal.description")}
                      </Text>
                      {this.state.transactionSelected.destination !==
                        undefined && (
                        <Text
                          style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}
                        >
                          {this.state.transactionSelected.destination.type ===
                          "EXTERNAL_CIRCLE"
                            ? this.state.transactionSelected.destination
                                .billing_name
                            : this.state.transactionSelected.destination
                                .description !== undefined
                            ? this.state.transactionSelected.destination
                                .description
                            : ""}{" "}
                          <Tag
                            color={
                              this.state.transactionSelected.destination.type.includes(
                                "INTERNAL"
                              )
                                ? "#108ee9"
                                : "#f50"
                            }
                          >
                            <Text
                              style={{
                                color: COLOR.PRIMARY_TEXT,
                                fontSize: 16,
                                fontWeight: 400,
                                marginBottom: 0,
                              }}
                            >
                              {this.state.transactionSelected.destination.type.includes(
                                "INTERNAL"
                              )
                                ? this.props.t("beneficiaries.internal")
                                : this.props.t("beneficiaries.external")}
                            </Text>
                          </Tag>
                        </Text>
                      )}
                      {this.state.transactionSelected.source !== undefined && (
                        <Text
                          style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}
                        >
                          {this.state.transactionSelected.account.currency !==
                            "BTC" &&
                            this.state.transactionSelected.source.type !==
                              "EXTERNAL_CIRCLE" && (
                              <>
                                {this.state.transactionSelected.source.type ===
                                "EXTERNAL"
                                  ? this.state.transactionSelected.source
                                      .address !== undefined
                                    ? this.state.transactionSelected.source
                                        .address
                                    : ""
                                  : this.state.transactionSelected.source
                                      .description}{" "}
                              </>
                            )}
                          {this.state.transactionSelected.source.type ===
                            "EXTERNAL_CIRCLE" &&
                            this.state.transactionSelected.source.billing_name +
                              " "}
                          <Tag
                            color={
                              this.state.transactionSelected.source.type.includes(
                                "INTERNAL"
                              )
                                ? "#108ee9"
                                : "#f50"
                            }
                          >
                            <Text
                              style={{
                                color: COLOR.PRIMARY_TEXT,
                                fontSize: 16,
                                fontWeight: 400,
                                marginBottom: 0,
                              }}
                            >
                              {this.state.transactionSelected.source.type.includes(
                                "INTERNAL"
                              )
                                ? this.props.t("beneficiaries.internal")
                                : this.props.t("beneficiaries.external")}
                            </Text>
                          </Tag>
                        </Text>
                      )}
                    </Row>
                  </div>
                )}
                {this.state.transactionSelected.account.type === "FIAT" && (
                  <>
                    <Divider
                      style={{
                        background: COLOR.SECONDARY_TEXT,
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    />

                    <Row justify="space-between">
                      <Text
                        style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}
                      >
                        {this.props.t("transactions.modal.description")}
                      </Text>
                      <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                        {(this.state.transactionSelected.type ===
                          "TRANSFER_OUT" ||
                          this.state.transactionSelected.type ===
                            "WITHDRAWAL") && (
                          <>
                            {this.state.transactionSelected.destination !==
                              undefined && (
                              <div>
                                {this.state.transactionSelected.destination
                                  .description !== undefined
                                  ? this.state.transactionSelected.destination
                                      .description + " "
                                  : ""}
                                <Tag
                                  color={
                                    this.state.transactionSelected.destination.type.includes(
                                      "INTERNAL"
                                    )
                                      ? "#108ee9"
                                      : "#f50"
                                  }
                                >
                                  <Text
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                      fontWeight: 400,
                                      marginBottom: 0,
                                    }}
                                  >
                                    {this.state.transactionSelected.destination.type.includes(
                                      "INTERNAL"
                                    )
                                      ? this.props.t("beneficiaries.internal")
                                      : this.props.t("beneficiaries.external")}
                                  </Text>
                                </Tag>
                              </div>
                            )}
                          </>
                        )}
                        {this.state.transactionSelected.type.includes(
                          "REBALANCE"
                        ) && (
                          <>
                            {this.state.transactionSelected.source !==
                              undefined && (
                              <div>
                                {
                                  this.state.transactionSelected.source
                                    .description
                                }
                              </div>
                            )}
                            {this.state.transactionSelected.destination !==
                              undefined && (
                              <div>
                                {
                                  this.state.transactionSelected.destination
                                    .description
                                }
                              </div>
                            )}
                          </>
                        )}

                        {(this.state.transactionSelected.type ===
                          "TRANSFER_IN" ||
                          this.state.transactionSelected.type ===
                            "DEPOSIT") && (
                          <>
                            {" "}
                            {this.state.transactionSelected.source !==
                              undefined && (
                              <div>
                                {this.state.transactionSelected.source
                                  .description + " "}
                                <Tag
                                  color={
                                    this.state.transactionSelected.source.type.includes(
                                      "INTERNAL"
                                    )
                                      ? "#108ee9"
                                      : "#f50"
                                  }
                                >
                                  <Text
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                      fontWeight: 400,
                                      marginBottom: 0,
                                    }}
                                  >
                                    {this.state.transactionSelected.source.type.includes(
                                      "INTERNAL"
                                    )
                                      ? this.props.t("beneficiaries.internal")
                                      : this.props.t("beneficiaries.external")}
                                  </Text>
                                </Tag>
                              </div>
                            )}
                          </>
                        )}
                      </Text>
                    </Row>

                    <Divider
                      style={{
                        background: COLOR.SECONDARY_TEXT,
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    />
                    {this.state.transactionSelected.account.currency ===
                      "GBP" && (
                      <div>
                        {this.state.transactionSelected.destination !==
                          undefined && (
                          <div>
                            {this.state.transactionSelected.type.includes(
                              "REBALANCE"
                            ) && (
                              <>
                                <Row justify="space-between">
                                  <Text
                                    style={{
                                      color: COLOR.SECONDARY_TEXT,
                                      fontSize: 16,
                                    }}
                                  >
                                    Alias
                                  </Text>
                                  <Text
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                    }}
                                  >
                                    {
                                      this.state.transactionSelected.destination
                                        .alias
                                    }
                                  </Text>
                                </Row>

                                <Divider
                                  style={{
                                    background: COLOR.SECONDARY_TEXT,
                                    marginTop: 10,
                                    marginBottom: 10,
                                  }}
                                />
                              </>
                            )}
                            {this.state.transactionSelected.destination
                              .account_holder !== undefined && (
                              <>
                                <Row justify="space-between">
                                  <Text
                                    style={{
                                      color: COLOR.SECONDARY_TEXT,
                                      fontSize: 16,
                                    }}
                                  >
                                    {this.props.t(
                                      "transactions.modal.accountHolder"
                                    )}
                                  </Text>
                                  <Text
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                    }}
                                  >
                                    {userType.includes("LITE") &&
                                    this.state.transactionSelected.account
                                      .type === "FIAT"
                                      ? "Digital Asset Management LTD"
                                      : this.state.transactionSelected
                                          .destination.account_holder}
                                  </Text>
                                </Row>
                                <Divider
                                  style={{
                                    background: COLOR.SECONDARY_TEXT,
                                    marginTop: 10,
                                    marginBottom: 10,
                                  }}
                                />
                              </>
                            )}
                            <Row justify="space-between">
                              <Text
                                style={{
                                  color: COLOR.SECONDARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                {this.props.t(
                                  "transactions.modal.accountNumber"
                                )}
                              </Text>
                              <Text
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                {
                                  this.state.transactionSelected.destination
                                    .account_number
                                }
                              </Text>
                            </Row>
                            <Divider
                              style={{
                                background: COLOR.SECONDARY_TEXT,
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            />
                            <Row justify="space-between">
                              <Text
                                style={{
                                  color: COLOR.SECONDARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                Sort Code
                              </Text>
                              <Text
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                {
                                  this.state.transactionSelected.destination
                                    .sort_code
                                }
                              </Text>
                            </Row>
                          </div>
                        )}

                        {this.state.transactionSelected.source !==
                          undefined && (
                          <div>
                            {this.state.transactionSelected.type.includes(
                              "REBALANCE"
                            ) && (
                              <>
                                <Row justify="space-between">
                                  <Text
                                    style={{
                                      color: COLOR.SECONDARY_TEXT,
                                      fontSize: 16,
                                    }}
                                  >
                                    Alias
                                  </Text>
                                  <Text
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                    }}
                                  >
                                    {
                                      this.state.transactionSelected.source
                                        .alias
                                    }
                                  </Text>
                                </Row>

                                <Divider
                                  style={{
                                    background: COLOR.SECONDARY_TEXT,
                                    marginTop: 10,
                                    marginBottom: 10,
                                  }}
                                />
                              </>
                            )}
                            {this.state.transactionSelected.source
                              .account_holder !== undefined && (
                              <>
                                <Row justify="space-between">
                                  <Text
                                    style={{
                                      color: COLOR.SECONDARY_TEXT,
                                      fontSize: 16,
                                    }}
                                  >
                                    {this.props.t(
                                      "transactions.modal.accountHolder"
                                    )}
                                  </Text>
                                  <Text
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                    }}
                                  >
                                    {
                                      this.state.transactionSelected.source
                                        .account_holder
                                    }
                                  </Text>
                                </Row>

                                <Divider
                                  style={{
                                    background: COLOR.SECONDARY_TEXT,
                                    marginTop: 10,
                                    marginBottom: 10,
                                  }}
                                />
                              </>
                            )}
                            <Row justify="space-between">
                              <Text
                                style={{
                                  color: COLOR.SECONDARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                {this.props.t(
                                  "transactions.modal.accountNumber"
                                )}
                              </Text>
                              <Text
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                {
                                  this.state.transactionSelected.source
                                    .account_number
                                }
                              </Text>
                            </Row>
                            <Divider
                              style={{
                                background: COLOR.SECONDARY_TEXT,
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            />
                            <Row justify="space-between">
                              <Text
                                style={{
                                  color: COLOR.SECONDARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                Sort Code
                              </Text>
                              <Text
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                {
                                  this.state.transactionSelected.source
                                    .sort_code
                                }
                              </Text>
                            </Row>
                          </div>
                        )}
                      </div>
                    )}
                    {this.state.transactionSelected.account.currency ===
                      "EUR" && (
                      <div>
                        {this.state.transactionSelected.destination !==
                          undefined && (
                          <div>
                            {this.state.transactionSelected.type.includes(
                              "REBALANCE"
                            ) && (
                              <>
                                <Row justify="space-between">
                                  <Text
                                    style={{
                                      color: COLOR.SECONDARY_TEXT,
                                      fontSize: 16,
                                    }}
                                  >
                                    Alias
                                  </Text>
                                  <Text
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                    }}
                                  >
                                    {
                                      this.state.transactionSelected.destination
                                        .alias
                                    }
                                  </Text>
                                </Row>

                                <Divider
                                  style={{
                                    background: COLOR.SECONDARY_TEXT,
                                    marginTop: 10,
                                    marginBottom: 10,
                                  }}
                                />
                              </>
                            )}
                            {this.state.transactionSelected.destination
                              .account_holder !== undefined && (
                              <>
                                <Row justify="space-between">
                                  <Text
                                    style={{
                                      color: COLOR.SECONDARY_TEXT,
                                      fontSize: 16,
                                    }}
                                  >
                                    {this.props.t(
                                      "transactions.modal.accountHolder"
                                    )}
                                  </Text>
                                  <Text
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                    }}
                                  >
                                    {userType.includes("LITE") &&
                                    this.state.transactionSelected.account
                                      .type === "FIAT"
                                      ? "Digital Asset Management LTD"
                                      : this.state.transactionSelected
                                          .destination.account_holder}
                                  </Text>
                                </Row>

                                <Divider
                                  style={{
                                    background: COLOR.SECONDARY_TEXT,
                                    marginTop: 10,
                                    marginBottom: 10,
                                  }}
                                />
                              </>
                            )}
                            <Row justify="space-between">
                              <Text
                                style={{
                                  color: COLOR.SECONDARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                IBAN
                              </Text>
                              <Text
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                {
                                  this.state.transactionSelected.destination
                                    .iban
                                }
                              </Text>
                            </Row>
                            <Divider
                              style={{
                                background: COLOR.SECONDARY_TEXT,
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            />
                            <Row justify="space-between">
                              <Text
                                style={{
                                  color: COLOR.SECONDARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                BIC
                              </Text>
                              <Text
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                {this.state.transactionSelected.destination.bic}
                              </Text>
                            </Row>
                          </div>
                        )}

                        {this.state.transactionSelected.source !==
                          undefined && (
                          <div>
                            {this.state.transactionSelected.type.includes(
                              "REBALANCE"
                            ) && (
                              <>
                                <Row justify="space-between">
                                  <Text
                                    style={{
                                      color: COLOR.SECONDARY_TEXT,
                                      fontSize: 16,
                                    }}
                                  >
                                    Alias
                                  </Text>
                                  <Text
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                    }}
                                  >
                                    {
                                      this.state.transactionSelected.source
                                        .alias
                                    }
                                  </Text>
                                </Row>

                                <Divider
                                  style={{
                                    background: COLOR.SECONDARY_TEXT,
                                    marginTop: 10,
                                    marginBottom: 10,
                                  }}
                                />
                              </>
                            )}
                            <Row justify="space-between">
                              <Text
                                style={{
                                  color: COLOR.SECONDARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                {this.props.t(
                                  "transactions.modal.accountHolder"
                                )}
                              </Text>
                              <Text
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                {
                                  this.state.transactionSelected.source
                                    .account_holder
                                }
                              </Text>
                            </Row>
                            <Divider
                              style={{
                                background: COLOR.SECONDARY_TEXT,
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            />
                            <Row justify="space-between">
                              <Text
                                style={{
                                  color: COLOR.SECONDARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                IBAN
                              </Text>
                              <Text
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                {this.state.transactionSelected.source.iban}
                              </Text>
                            </Row>
                            <Divider
                              style={{
                                background: COLOR.SECONDARY_TEXT,
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            />
                            <Row justify="space-between">
                              <Text
                                style={{
                                  color: COLOR.SECONDARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                BIC
                              </Text>
                              <Text
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                {this.state.transactionSelected.source.bic}
                              </Text>
                            </Row>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
                <Divider
                  style={{
                    background: COLOR.SECONDARY_TEXT,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                {this.state.transactionSelected?.cancelled_by && (
                  <Row justify="space-between">
                    <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                      {this.props.t("transactions.modal.cancelledBy")}
                    </Text>
                    <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                      {this.state.transactionSelected?.cancelled_by}
                    </Text>
                  </Row>
                )}
              </>
            )}
          </Modal>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    accounts: state.banking.accounts,
    respTransactions: state.transactions.allResultTx,
    asset: state.siderNavigate.asset,
    token: state.tokenAccess,
    respTimeline: state.timeline.resultTimeline,
    respTransactionsOrders: state.transactions.transactionsAndOrders,
    dataUser: state.authUser.userData,
    respCancelTransaction: state.transactions.resultCancelTransactions,
  };
};

const mapDispatchToProps = {
  selectedAsset,
  currentToken,
  getTimeline,
  getAllTransactionsOrders,
  transactionCancelation,
};

const RecentsTransactionsTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(RecentsTransactions)
);

export default RecentsTransactionsTranslated;
