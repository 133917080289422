import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./containerTrade.css";
import { Tabs, message } from "antd";
// import Buy from "../buy/buy";
// import Sell from "../sell/sell";
import Trade from "../trade/trade";
import Fx from "../fx/fx";
import FiatCrypto from "../fiatCrypto/fiatCrypto";
import ConfirmTrade from "./confirmTrade";
import {
  currentFromAsset,
  limitsTrade,
  getRate,
  getExchangePairsSupported,
  quoteCurrency,
} from "../../redux/actions/exchangeActions";
import { connect } from "react-redux";
import { currentToken } from "../../redux/actions/tokenActions";
import { asset } from "../../common/assets";
const { TabPane } = Tabs;

class ContainerTrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: "0",
      fiat: false,
      current: 0,
      assetActiveTo: "",
      assetActiveFrom: "",
      assetActiveToFx: "",
      assetActiveFromFx: "",
      amountTrade: 0,
      amountTradeFloat: 0,
      amountFx: 0,
      amountFxFloat: 0,
      assetActiveBalance: "",
      labelActiveFrom: "",
      labelActiveTo: "",
      assetSelected: "",
      labelActive: "",
      amountBuy: 0,
      amountBuyFiat: 0,
      amountBuyFloat: 0,
      amountBuyFiatFloat: 0,
      listAssetTo: [],
      listAssetFrom: [],
      listAsset: [],
      assetActiveSell: "",
      listAssetFiat: [],
      listAssetCrypto: [],
      accounts: [],
      rateCurrent: "",
      loading: false,
      loadingMinExchange: false,
      minExchange: "",
      recommendedMinExchange: "",
      nativeCurrency: "",
      nativeSymbol: "",
      nativeBalance: "",
      pairsSupported: true,
    };
  }
  componentDidMount() {
    if (this.props.banking) {
      let accounts = this.props.banking.data?.data?.accounts;
      let findAccounts = accounts.find((element) => {
        return element.type === "FIAT";
      });

      if (findAccounts !== undefined) {
        this.setState({ fiat: true });
        let noCrypto = accounts.find((element) => {
          return element.type === "CRYPTO";
        });
        if (noCrypto === undefined) {
          this.setState({ tabActive: "3" });
        }
      } else {
        this.setState({ fiat: false, tabActive: "4" });
      }
    }
  }

  async getRate(baseCurrency, quoteCurrency, recalculate) {
    try {
      if (this.state.recommendedMinExchange === "") {
        this.setState({
          loadingMinExchange: true,
        });
      }
      if (!this.props.modalState) {
        this.props.limitsTrade(null);
        await this.props.currentFromAsset(baseCurrency);
      }
      await this.props.getRate(
        this.props.clientId,
        this.props.accessToken,
        baseCurrency,
        quoteCurrency
      );
      let response = this.props.rateResponse;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        let dataRate = response.data.data.quotation;
        
          this.props.limitsTrade(dataRate);
        //this.findVolumes("FX");
        this.setState({
          rateCurrent: dataRate,
          current: recalculate ? this.state.current : this.state.current + 1,
          loading: false,
          loadingMinExchange: false,
          minExchange: dataRate.min_exchange,
          maxExchange: dataRate.max_exchange,
        });
        if (this.state.recommendedMinExchange === "") {
          this.setState({
            recommendedMinExchange: dataRate.recommended_min_exchange,
          });
        }
        if (dataRate.min_exchange > dataRate.recommended_min_exchange) {
          this.setState({
            recommendedMinExchange: dataRate.min_exchange,
          });
        }
      } else if (response.status === 400) {
        this.setState({ loading: false });
        if (response.data.error_details.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;

          message.error(this.props.t("trade.error." + messageError), 10);
        } else {
          message.error(response.data.message);
        }
      } else if (response.status === 404) {
        this.setState({ loading: false });
        if (response.data.error_details.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;

          message.error(this.props.t("trade.error." + messageError), 10);
        } else {
          message.error(response.data.message);
        }
      } else if (response.status === 403) {
        message.error(this.props.t("trade.error.accessError"), 10);
        this.setState({ loading: false });
      } else if (
        response.status === 401 ||
        response.status === 422 ||
        response.status === 500
      ) {
        message.error(this.props.t("trade.error.errorServer"), 10);
        this.setState({ loading: false });
      }
    } catch (error) {
      message.error(this.props.t("trade.error.errorServer"), 10);
      this.setState({ loading: false });
    }
  }
  nextStep(event, action) {
    let validate = false;
    let messageWarning = "";
    let currency =
      event === "FX"
        ? this.state.assetActiveFromFx
        : this.state.assetActiveFrom;
    let volumes = this.findVolumes(event);
    let maxDay = this.state.rateCurrent.max_daily_exchange;
    let maxMonth = this.state.rateCurrent.max_monthly_exchange;
    let amount = "";
    if (event === "BUY" || event === "SELL") {
      amount = this.state.amountBuyFiatFloat;
    } else if (event === "FX") {
      amount = this.state.amountFxFloat;
    } else {
      amount = this.state.amountTradeFloat;
    }
    if (maxDay !== "-1") {
      let limitDaily =
        parseFloat(maxDay) - parseFloat(volumes.current_day_volume);

      if (amount > limitDaily) {
        messageWarning = this.props.t("limits.messages.limitDailySend", {
          currency: currency.value,
          max: maxDay,
          volume: volumes.current_day_volume,
        });
        this.setState({
          showMessageLimit: true,
          messageLimit: messageWarning,
        });
        setTimeout(() => {
          this.setState({ showMessageLimit: false, messageLimit: "" });
        }, 30000);
      } else {
        validate = true;
      }
    } else if (maxMonth !== "-1") {
      let limitMonthly =
        parseFloat(maxMonth) - parseFloat(volumes.current_month_volume);
      if (amount > limitMonthly) {
        messageWarning = this.props.t("limits.messages.limitMonthlySend", {
          currency: currency.value,
          max: maxMonth,
          volume: volumes.current_month_volume,
        });
        this.setState({
          showMessageLimit: true,
          messageLimit: messageWarning,
        });
        setTimeout(() => {
          this.setState({ showMessageLimit: false, messageLimit: "" });
        }, 30000);
      } else {
        validate = true;
      }
    } else {
      validate = true;
    }
    if (validate) {
      this.setState({ loading: true });
      let baseCurrency = "";
      let quoteCurrency = "";
      if (event === "FX") {
        baseCurrency = this.state.assetActiveFromFx.value;
        quoteCurrency = this.state.assetActiveToFx.value;
      } else {
        baseCurrency = this.state.assetActiveFrom.value;
        quoteCurrency = this.state.assetActiveTo.value;
      }
      this.getRate(baseCurrency, quoteCurrency, action);
    }
  }
  getLimits(asset) {
    let limitsResponse = this.props.respLimits.data.data.limits;
    let findLimit = limitsResponse.find(
      (element) => element.currency === asset
    );
    if (findLimit !== undefined) {
      this.setState({ minExchange: findLimit.min_exchange });
    }
  }
  prevStep() {
    this.setState({ current: this.state.current - 1 });
  }
  handleTab(key) {
    this.setState({ tabActive: key });
  }
  findVolumes(event) {
    let findVolumes = "";
    let baseCurrency =
      event === "FX"
        ? this.state.assetActiveFromFx
        : this.state.assetActiveFrom;
    // let quoteCurrency =
    //   event === "FX" ? this.state.assetActiveToFx : this.state.assetActiveTo;
    let accounts = this.props.banking.data.data.accounts;
    let findAccount = accounts.find((element) => {
      return element.currency === baseCurrency.value;
    });
    //   findVolumes = findAccount.volumes.find((element) => {
    //     return (
    //       element.type === "EXCHANGE_OUT" &&
    //       element.quote_currency === quoteCurrency.value
    //     );
    //   });
    findVolumes = findAccount.volumes.find((element) => {
      return element.type === "EXCHANGE_OUT";
    });
    return findVolumes;
  }
  infoCurrency(accounts) {
    let arrayCurrencies = [];
    let detail = this.props.banking.data.data.native_balances.currencies;
    for (let objCurrency of accounts) {
      let objCurrencies = {};
      let data = asset.assets.find(
        (element) => element.value === objCurrency.currency
      );
      objCurrencies.icon = data.icon;
      objCurrencies.symbol = data.symbol;
      objCurrencies.name = data.name;
      objCurrencies.value = objCurrency.currency;
      objCurrencies.type = objCurrency.type;
      objCurrencies.id = objCurrency._id;
      let findLengthAccounts = detail.find(
        (element) => element.currency === objCurrency.currency
      );
      objCurrencies.alias =
        findLengthAccounts.accounts !== undefined &&
        findLengthAccounts.accounts?.length > 1
          ? objCurrency.alias
          : null;

      objCurrencies.status = objCurrency.status;
      arrayCurrencies.push(objCurrencies);
    }
    return arrayCurrencies;
  }
  handleAssetFrom(value, id) {
    this.setState({
      amountTrade: 0,
      amountTradeFloat: 0,
      amountBuy: 0,
      amountBuyFiat: 0,
      amountBuyFiatFloat: 0,
      amountBuyFloat: 0,
    });
    let multiple = false;
    let data = asset.assets;
    let assetSelected = data.find((element) => {
      return element.value === value;
    });

    let accounts = this.props.banking.data.data.accounts;
    let nativeBalanceInfo = this.props.banking.data.data.native_balances;
    let findBalanceNative = nativeBalanceInfo.currencies.find(
      (element) => element.currency === assetSelected.value
    );
    if (findBalanceNative.accounts?.length > 1) {
      multiple = true;
    }
    if (findBalanceNative.accounts !== undefined) {
      findBalanceNative = findBalanceNative.accounts.find(
        (element) => element._id === id
      );
    }
    let findBalance = accounts.find((element) => {
      return element._id === id;
    });
    assetSelected.status = findBalance.status;
    let arrayCurrencies = this.infoCurrency(accounts);
    let fiat = arrayCurrencies.filter((element) => element.type === "FIAT");
    let crypto = arrayCurrencies.filter((element) => element.type === "CRYPTO");
    let filterAssets = [];
    if (assetSelected.type === "CRYPTO") {
      filterAssets = crypto.filter(
        (element) => element.value !== assetSelected.value
      );
    } else {
      filterAssets = fiat.filter((element) => element.id !== id);
    }
    this.getLimits(assetSelected.value);
    let balancePercentage = {
      twentyFive: parseFloat(findBalance.available_balance) * 0.25,
      fifty: parseFloat(findBalance.available_balance) * 0.5,
      all: parseFloat(findBalance.available_balance),
    };
    assetSelected.id = id;
    this.setState({
      listAssetFrom: filterAssets,
      assetActiveBalance: findBalance.available_balance,
      percentageForTrade: balancePercentage,
      assetActiveFrom: assetSelected,
      labelActiveFrom: multiple ? findBalance.alias : findBalance.currency_name,
      activeInput: assetSelected.value,
      nativeCurrency: nativeBalanceInfo.native_currency,
      nativeSymbol: nativeBalanceInfo.native_symbol,
      nativeBalance: findBalanceNative.native_balance,
    });
  }
  handleAssetTo(value, id) {
    this.setState({ amountTrade: 0 });
    let multiple = false;
    let data = asset.assets;
    let assetSelected = data.find((element) => {
      return element.value === value;
    });
    let accounts = this.props.banking.data.data.accounts;
    let detail = this.props.banking.data.data.native_balances.currencies;
    let findLengthAccounts = detail.find(
      (element) => element.currency === value
    );
    if (findLengthAccounts.accounts?.length > 1) {
      multiple = true;
    }
    let assetInfo = accounts.find((element) => element._id === id);
    assetSelected.id = id;
    assetSelected.status = assetInfo.status;
    let arrayCurrencies = this.infoCurrency(accounts);
    let fiat = arrayCurrencies.filter((element) => element.type === "FIAT");
    let crypto = arrayCurrencies.filter((element) => element.type === "CRYPTO");
    let filterAssets = [];
    if (assetSelected.type === "CRYPTO") {
      filterAssets = crypto.filter(
        (element) => element.value !== assetSelected.value
      );
    } else {
      filterAssets = fiat.filter((element) => element.id !== assetSelected.id);
    }
    this.props.quoteCurrency(assetSelected);
    this.setState({
      listAssetTo: filterAssets,
      assetActiveTo: assetSelected,
      labelActiveTo: multiple ? assetInfo.alias : assetInfo.currency_name,
    });
  }
  handleAssetFromTrade(value, listAssets) {
    this.setState({
      amountTrade: 0,
      amountTradeFloat: 0,
    });
    let data = asset.assets;
    let assetSelected = data.find((element) => {
      return element.value === value;
    });
    let accounts = this.props.banking.data.data.accounts;
    let nativeBalanceInfo = this.props.banking.data.data.native_balances;
    let findBalanceNative = nativeBalanceInfo.currencies.find(
      (element) => element.currency === assetSelected.value
    );
    let findBalance = accounts.find((element) => {
      return element.currency === assetSelected.value;
    });
    let filterAssets = listAssets.filter(
      (element) => element.value !== assetSelected.value
    );
    assetSelected.status = findBalance.status;
    this.getLimits(assetSelected.value);
    let balancePercentage = {
      twentyFive: parseFloat(findBalance.available_balance) * 0.25,
      fifty: parseFloat(findBalance.available_balance) * 0.5,
      all: parseFloat(findBalance.available_balance),
    };
    this.setState({
      listAssetFrom: filterAssets,
      assetActiveFrom: assetSelected,
      labelActiveFrom: assetSelected.name,
      assetActiveBalance: findBalance.available_balance,
      activeInput: assetSelected.value,
      nativeCurrency: nativeBalanceInfo.native_currency,
      nativeSymbol: nativeBalanceInfo.native_symbol,
      nativeBalance: findBalanceNative.native_balance,
      percentageForTrade: balancePercentage,
    });
  }
  handleAssetToTrade(value, listAssets) {
    this.setState({ amountTrade: 0 });
    let data = asset.assets;
    let accounts = this.props.banking.data.data.accounts;
    let assetSelected = data.find((element) => {
      return element.value === value;
    });
    let filterAssets = listAssets.filter(
      (element) => element.value !== assetSelected.value
    );
    let findAccount = accounts.find((element) => {
      return element.currency === assetSelected.value;
    });
    assetSelected.status = findAccount.status;
    this.props.quoteCurrency(assetSelected);
    this.setState({
      listAssetTo: filterAssets,
      assetActiveTo: assetSelected,
      labelActiveTo: assetSelected.name,
    });
  }
  handleAssetFromFx(currency, listAssets) {
    this.setState({
      amountFx: 0,
      amountFxFloat: 0,
    });
    let data = asset.assets;
    let multiple = false;
    let accounts = this.props.banking.data.data.accounts;
    let nativeBalanceInfo = this.props.banking.data.data.native_balances;
    let assetSelected = data.find((element) => {
      return element.value === currency.value;
    });
    let findBalance = accounts.find((element) => {
      return element._id === currency.id;
    });
    assetSelected.status = findBalance.status;
    assetSelected.id = findBalance._id;

    let findBalanceNative = nativeBalanceInfo.currencies.find(
      (element) => element.currency === assetSelected.value
    );
    if (findBalanceNative.accounts?.length > 1) {
      multiple = true;
    }
    if (findBalanceNative.accounts !== undefined) {
      findBalanceNative = findBalanceNative.accounts.find(
        (element) => element._id === findBalance._id
      );
    }
    let filterAssets = listAssets.filter(
      (element) => element.id !== assetSelected.id
    );
    this.getLimits(assetSelected.value);
    let balancePercentage = {
      twentyFive: parseFloat(findBalance.available_balance) * 0.25,
      fifty: parseFloat(findBalance.available_balance) * 0.5,
      all: parseFloat(findBalance.available_balance),
    };
    this.setState({
      listAssetFrom: filterAssets,
      assetActiveBalance: findBalance.available_balance,
      assetActiveFromFx: assetSelected,
      labelActiveFrom: multiple ? findBalance.alias : findBalance.currency_name,
      activeInput: assetSelected.value,
      nativeCurrency: nativeBalanceInfo.native_currency,
      nativeSymbol: nativeBalanceInfo.native_symbol,
      nativeBalance: findBalanceNative.native_balance,
      percentageForTrade: balancePercentage,
    });
  }
  handleAssetToFx(currency, listAssets) {
    this.setState({ amountFx: 0 });
    let multiple = false;
    let data = asset.assets;
    let detail = this.props.banking.data.data.native_balances.currencies;
    let accounts = this.props.banking.data.data.accounts;
    let findLengthAccounts = detail.find(
      (element) => element.currency === currency.value
    );
    if (findLengthAccounts.accounts?.length > 1) {
      multiple = true;
    }
    let assetSelected = data.find((element) => {
      return element.value === currency.value;
    });
    let findAccount = accounts.find((element) => {
      return element._id === currency.id;
    });
    assetSelected.status = findAccount.status;
    assetSelected.id = findAccount._id;

    let filterAssets = listAssets.filter(
      (element) => element.id !== assetSelected.id
    );

    this.props.quoteCurrency(assetSelected);
    this.setState({
      listAssetTo: filterAssets,
      assetActiveToFx: assetSelected,
      labelActiveTo: multiple
        ? findAccount.alias
        : findAccount.currency_name,
    });
  }

  handleAmountFiat = (values) => {
    this.setState({
      amountBuyFiat: values.formattedValue,
      amountBuyFiatFloat:
        values.floatValue === undefined ? 0 : values.floatValue,
    });
  };
  handleAmountTrading = (values) => {
    this.setState({
      amountTrade: values.formattedValue,
      amountTradeFloat: values.floatValue === undefined ? 0 : values.floatValue,
    });
  };
  handleAmountFx = (values) => {
    this.setState({
      amountFx: values.formattedValue,
      amountFxFloat: values.floatValue === undefined ? 0 : values.floatValue,
    });
  };

  resetCurrent() {
    this.setState({ current: 0 });
  }
  resetMinExchange() {
    this.setState({ loadingMinExchange: true, recommendedMinExchange: "" });
  }
  async getPairsSupported(baseCurrency, quoteCurrency) {
    try {
      await this.props.getExchangePairsSupported(
        this.props.clientId,
        this.props.accessToken,
        baseCurrency,
        quoteCurrency
      );
      let response = this.props.pairsSupported;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        let dataPairs = response.data.data.supported_pairs;
        if (dataPairs.length > 0) {
          let findPairs = dataPairs.find((element) => {
            return (
              element.base_currency === baseCurrency &&
              element.quote_currency === quoteCurrency
            );
          });
          if (findPairs !== undefined) {
            this.setState({
              pairsSupported: true,
            });
          } else {
            this.setState({
              pairsSupported: false,
            });
          }
        } else {
          this.setState({
            pairsSupported: false,
          });
        }
      } else if (response.status === 400) {
        this.setState({ loading: false });
        if (response.data.error_details.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;

          message.error(this.props.t("trade.error." + messageError), 10);
        } else {
          message.error(response.data.message);
        }
      } else if (response.status === 403) {
        message.error(this.props.t("trade.error.accessError"), 10);
        this.setState({ loading: false });
      } else if (response.status === 404) {
        message.error(this.props.t("trade.error.userBlocked"), 10);
        this.setState({ loading: false });
      } else if (
        response.status === 401 ||
        response.status === 404 ||
        response.status === 422 ||
        response.status === 500
      ) {
        message.error(this.props.t("trade.error.errorServer"), 10);
        this.setState({ loading: false });
      }
    } catch (error) {
      message.error(this.props.t("trade.error.errorServer"), 10);
      this.setState({ loading: false });
    }
  }
  render() {
    let stepConfirm = "";
    if (this.state.tabActive === "0") {
      stepConfirm = (
        <ConfirmTrade
          source={this.props.source}
          prev={this.prevStep.bind(this)}
          assetActive={this.state.assetActiveFrom}
          assetActiveReceive={this.state.assetActiveTo}
          labelActive={this.state.labelActive}
          amount={this.state.amountBuy}
          amountFiat={this.state.amountBuyFiatFloat}
          assetActiveBalance={this.state.assetActiveBalance}
          label={
            this.state.assetActiveFrom.type === "CRYPTO"
              ? this.props.t("sell.label")
              : this.props.t("buy.label")
          }
          name={this.state.assetActiveFrom.type === "CRYPTO" ? "SELL" : "BUY"}
          rateCurrent={this.state.rateCurrent}
          loading={this.state.loading}
          next={this.nextStep.bind(this)}
          resetCurrent={this.resetCurrent.bind(this)}
          nativeBalance={this.state.nativeBalance}
          nativeSymbol={this.state.nativeSymbol}
          nativeCurrency={this.state.nativeCurrency}
          minExchange={this.state.minExchange}
          recommendedMinExchange={this.state.recommendedMinExchange}
          maxExchange={this.state.maxExchange}
        />
      );
    }
    if (this.state.tabActive === "3") {
      stepConfirm = (
        <ConfirmTrade
          source={this.props.source}
          prev={this.prevStep.bind(this)}
          assetActive={this.state.assetActiveFromFx}
          assetActiveReceive={this.state.assetActiveToFx}
          labelActive={this.state.labelActive}
          amount={0}
          amountFiat={this.state.amountFxFloat}
          assetActiveBalance={this.state.assetActiveBalance}
          label={this.props.t("trade.label")}
          name={"FX"}
          rateCurrent={this.state.rateCurrent}
          loading={this.state.loading}
          next={this.nextStep.bind(this)}
          resetCurrent={this.resetCurrent.bind(this)}
          nativeBalance={this.state.nativeBalance}
          nativeSymbol={this.state.nativeSymbol}
          nativeCurrency={this.state.nativeCurrency}
          minExchange={this.state.minExchange}
          recommendedMinExchange={this.state.recommendedMinExchange}
          maxExchange={this.state.maxExchange}
        />
      );
    }
    if (this.state.tabActive === "4") {
      stepConfirm = (
        <ConfirmTrade
          source={this.props.source}
          prev={this.prevStep.bind(this)}
          assetActive={this.state.assetActiveFrom}
          assetActiveReceive={this.state.assetActiveTo}
          labelActive={this.state.labelActive}
          amount={0}
          amountFiat={this.state.amountTradeFloat}
          assetActiveBalance={this.state.assetActiveBalance}
          label={this.props.t("trade.label")}
          name={"TRADE"}
          rateCurrent={this.state.rateCurrent}
          loading={this.state.loading}
          next={this.nextStep.bind(this)}
          resetCurrent={this.resetCurrent.bind(this)}
          nativeBalance={this.state.nativeBalance}
          nativeSymbol={this.state.nativeSymbol}
          nativeCurrency={this.state.nativeCurrency}
          minExchange={this.state.minExchange}
          recommendedMinExchange={this.state.recommendedMinExchange}
          maxExchange={this.state.maxExchange}
        />
      );
    }
    let tabTrade = (
      <div
        id={
          this.props.source === "trade"
            ? "custom-tab-detail-trade"
            : "custom-tab"
        }
        className="ant-tabs ant-tabs-tab ant-tabs-nav ant-tabs-card ant-tabs-tab-active ant-tabs-tab-btn ant-tabs-top"
      >
        <Tabs
          type="card"
          centered
          size="large"
          tabBarGutter={2}
          tabBarStyle={{ width: "100%", color: "#ccc" }}
          activeKey={this.state.tabActive}
          onTabClick={(key) => this.handleTab(key)}
        >
          {this.props.onlyFiat === false && (
            <>
              {this.state.fiat && (
                <TabPane
                  forceRender={false}
                  style={{ width: "100%", textAlign: "center" }}
                  tab="Trade"
                  key="0"
                >
                  {this.state.tabActive === "0" && (
                    <FiatCrypto
                      next={this.nextStep.bind(this)}
                      source={this.props.source}
                      listAssetTo={this.state.listAssetTo}
                      listAssetFrom={this.state.listAssetFrom}
                      handleAssetFrom={this.handleAssetFrom.bind(this)}
                      handleAssetTo={this.handleAssetTo.bind(this)}
                      handleAmountFiat={this.handleAmountFiat.bind(this)}
                      assetActiveFrom={this.state.assetActiveFrom}
                      assetActiveTo={this.state.assetActiveTo}
                      labelActiveFrom={this.state.labelActiveFrom}
                      labelActiveTo={this.state.labelActiveTo}
                      assetActiveBalance={this.state.assetActiveBalance}
                      amountBuyFiat={this.state.amountBuyFiat}
                      amountBuy={this.state.amountBuy}
                      amountBuyFiatFloat={this.state.amountBuyFiatFloat}
                      loading={this.state.loading}
                      loadingMinExchange={this.state.loadingMinExchange}
                      rateCurrent={this.state.rateCurrent}
                      getLimits={this.getLimits.bind(this)}
                      getRate={this.getRate.bind(this)}
                      minExchange={this.state.minExchange}
                      recommendedMinExchange={this.state.recommendedMinExchange}
                      nativeBalance={this.state.nativeBalance}
                      nativeSymbol={this.state.nativeSymbol}
                      nativeCurrency={this.state.nativeCurrency}
                      resetMinExchange={this.resetMinExchange.bind(this)}
                      maxExchange={this.state.maxExchange}
                      showMessageLimit={this.state.showMessageLimit}
                      messageLimit={this.state.messageLimit}
                      getPairsSupported={this.getPairsSupported.bind(this)}
                      pairsSupported={this.state.pairsSupported}
                      percentageForTrade={this.state.percentageForTrade}
                    />
                  )}
                </TabPane>
              )}
            </>
          )}

          {this.state.fiat && (
            <TabPane
              forceRender={false}
              style={{ width: "100%", textAlign: "center" }}
              tab={"FX"}
              key="3"
            >
              {this.state.tabActive === "3" && (
                <Fx
                  next={this.nextStep.bind(this)}
                  source={this.props.source}
                  handleAssetFrom={this.handleAssetFromFx.bind(this)}
                  handleAssetTo={this.handleAssetToFx.bind(this)}
                  assetActiveFrom={this.state.assetActiveFromFx}
                  assetActiveTo={this.state.assetActiveToFx}
                  amountTrade={this.state.amountFx}
                  amountTradeFloat={this.state.amountFxFloat}
                  handleAmountTrade={this.handleAmountFx.bind(this)}
                  labelActiveFrom={this.state.labelActiveFrom}
                  labelActiveTo={this.state.labelActiveTo}
                  listAssetTo={this.state.listAssetTo}
                  listAssetFrom={this.state.listAssetFrom}
                  assetActiveBalance={this.state.assetActiveBalance}
                  loading={this.state.loading}
                  loadingMinExchange={this.state.loadingMinExchange}
                  rateCurrent={this.state.rateCurrent}
                  getLimits={this.getLimits.bind(this)}
                  getRate={this.getRate.bind(this)}
                  minExchange={this.state.minExchange}
                  recommendedMinExchange={this.state.recommendedMinExchange}
                  nativeBalance={this.state.nativeBalance}
                  nativeSymbol={this.state.nativeSymbol}
                  nativeCurrency={this.state.nativeCurrency}
                  resetMinExchange={this.resetMinExchange.bind(this)}
                  maxExchange={this.state.maxExchange}
                  showMessageLimit={this.state.showMessageLimit}
                  messageLimit={this.state.messageLimit}
                  getPairsSupported={this.getPairsSupported.bind(this)}
                  pairsSupported={this.state.pairsSupported}
                  percentageForTrade={this.state.percentageForTrade}
                />
              )}
            </TabPane>
          )}
          {this.props.onlyFiat === false && (
            <TabPane
              forceRender={false}
              style={{ width: "100%", textAlign: "center" }}
              tab={"Swap"}
              key="4"
            >
              {this.state.tabActive === "4" && (
                <Trade
                  next={this.nextStep.bind(this)}
                  source={this.props.source}
                  handleAssetFrom={this.handleAssetFromTrade.bind(this)}
                  handleAssetTo={this.handleAssetToTrade.bind(this)}
                  assetActiveFrom={this.state.assetActiveFrom}
                  assetActiveTo={this.state.assetActiveTo}
                  amountTrade={this.state.amountTrade}
                  amountTradeFloat={this.state.amountTradeFloat}
                  handleAmountTrade={this.handleAmountTrading.bind(this)}
                  labelActiveFrom={this.state.labelActiveFrom}
                  labelActiveTo={this.state.labelActiveTo}
                  listAssetTo={this.state.listAssetTo}
                  listAssetFrom={this.state.listAssetFrom}
                  assetActiveBalance={this.state.assetActiveBalance}
                  loading={this.state.loading}
                  loadingMinExchange={this.state.loadingMinExchange}
                  rateCurrent={this.state.rateCurrent}
                  getLimits={this.getLimits.bind(this)}
                  getRate={this.getRate.bind(this)}
                  minExchange={this.state.minExchange}
                  recommendedMinExchange={this.state.recommendedMinExchange}
                  nativeBalance={this.state.nativeBalance}
                  nativeSymbol={this.state.nativeSymbol}
                  nativeCurrency={this.state.nativeCurrency}
                  resetMinExchange={this.resetMinExchange.bind(this)}
                  maxExchange={this.state.maxExchange}
                  showMessageLimit={this.state.showMessageLimit}
                  messageLimit={this.state.messageLimit}
                  getPairsSupported={this.getPairsSupported.bind(this)}
                  pairsSupported={this.state.pairsSupported}
                  percentageForTrade={this.state.percentageForTrade}
                />
              )}
            </TabPane>
          )}
        </Tabs>
      </div>
    );
    const steps = [
      {
        content: tabTrade,
      },
      {
        content: stepConfirm,
      },
    ];
    return <div>{steps[this.state.current].content}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    banking: state.banking.accounts,
    rateResponse: state.exchange.resultRate,
    accessToken: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
    respLimits: state.banking.limits,
    modalState: state.siderNavigate.modal,
    pairsSupported: state.exchange.pairs,
    onlyFiat: state.authUser.isFiatOnly,
  };
};
const mapDispatchToProps = {
  getRate,
  currentToken,
  currentFromAsset,
  limitsTrade,
  getExchangePairsSupported,
  quoteCurrency,
};
const ContainerTradeTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ContainerTrade)
);

export default ContainerTradeTranslated;
