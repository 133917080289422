import React, { Component } from "react";
import COLOR from "../../const/colors";
import "./beneficiaries.css";
import { getBeneficiaries } from "../../redux/actions/beneficiariesActions";
import { currentToken } from "../../redux/actions/tokenActions";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Typography,
  Space,
  Row,
  Col,
  Table,
  Card,
  Button,
  Image,
  Tag,
  AutoComplete,
  Skeleton,
  message,
  Select,
} from "antd";
import ModalEditBeneficiary from "./modalEdit";
import ModalRemoveBeneficiary from "./modalRemove";
import AddBeneficiary from "./addBeneficiary";
import {
  openModalSend,
  infoBeneficiaryToSend,
} from "../../redux/actions/navigateActions";
import { asset } from "../../common/assets";
import CAN from "../../casl/can";
const { Option } = Select;
const { Text } = Typography;

class Beneficiaries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetActive: "",
      modalEditBeneficiary: false,
      modalRemoveBeneficiary: false,
      beneficiarySelect: {},
      listBeneficiaries: [],
      listToFilter: [],
      loading: false,
      beneficiaryRemove: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getInfoAsset();
    this.props.infoBeneficiaryToSend("");
    this.props.openModalSend(false);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.asset !== this.props.asset) {
      this.getInfoAsset();
    }
  }
  getInfoAsset() {
    let assetSelect = this.props.asset.key;
    let accountsList = this.props.accounts.data.data.accounts;
    let accountAsset = accountsList.find(
      (element) => element.currency === assetSelect
    );

    let assetData = {};
    if (accountAsset !== undefined) {
      assetData.accountData = accountAsset.account_data;
      assetData.currency = accountAsset.currency;
      assetData.id = accountAsset._id;
      assetData.type = accountAsset.type;
      let currency = asset.assets.find(
        (element) => assetSelect === element.value
      );
      if (currency !== undefined) {
        assetData.icon = currency.icon;
        assetData.name = currency.name;
        assetData.symbol = currency.symbol;
      }
    }
    this.getBeneficiaries(assetSelect);
    this.setState({
      assetActive: assetData,
    });
  }
  async getBeneficiaries(currency) {
    try {
      this.setState({ loading: true });
      await this.props.getBeneficiaries(
        this.props.token.clientId,
        this.props.token.current,
        currency
      );
      let resp = this.props.beneficiariesList;
      if (resp.headers !== undefined) {
        let newAccessToken = resp.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (resp.status === 200) {
        let beneficiariesResponse = resp.data.data.beneficiaries;
        this.formatBeneficiariesList(beneficiariesResponse);
      } else if (resp.status === 400) {
        this.setState({
          loading: false,
          listBeneficiaries: [],
          listToFilter: [],
        });
        if (resp.data.error_details.validation_error !== undefined) {
          let messageError = resp.data.error_details.validation_error;

          message.error(
            this.props.t("beneficiaries.messages." + messageError),
            10
          );
        } else {
          message.error(resp.data.message);
        }
      } else if (resp.status === 403) {
        this.setState({ loading: false });
        message.error(this.props.t("beneficiaries.messages.accessError"));
      } else if (
        resp.status === 401 ||
        resp.status === 422 ||
        resp.status === 404 ||
        resp.status === 500
      ) {
        this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  }
  formatBeneficiariesList(beneficiaries) {
    let array = [];
    for (let value of beneficiaries) {
      let objData = {};
      objData.id = value.beneficiary_id;
      objData.currency = value.currency;
      objData.description = value.description;
      objData.type = value.type;
      if (value.account_holder !== undefined) {
        objData.accountHolder = value.account_holder;
      }

      Object.entries(value.account_data).forEach(([key, val]) => {
        if (key === "networks") {
          objData[key] = val;
        }
        if (value.account_data.networks === undefined) {
          if (objData.address === undefined) {
            if (key === "address") {
              objData.address = val;
            }
          }
          if (objData.network === undefined) {
            if (key === "network") {
              objData.network = val;
            }
          }
        }
        if (key === "account_number") {
          objData.account = val;
        }
        if (key === "sort_code") {
          objData.sortCode = val;
        }
        if (key === "bic") {
          objData.bic = val;
        }
        if (key === "iban") {
          objData.iban = val;
        }
      });
      array.push(objData);
    }
    this.setState(
      {
        listBeneficiaries: array,
        listToFilter: array,
        loading: false,
      },
      () => this.hasNetworkColumn()
    );
  }
  openModalEditBeneficiary = (beneficiary, isExpand) => {
    let assetSelect = this.props.asset.key;
    let currency = asset.assets.find(
      (element) => assetSelect === element.value
    );
    this.setState({
      modalEditBeneficiary: true,
      beneficiarySelect: beneficiary,
      networkSelect: beneficiary.network,
      currencySelect: currency,
      beneficiaryRemove: isExpand ? this.state.beneficiaryRemove : beneficiary,
    });
  };
  closeModalEditBeneficiary = () => {
    this.setState({ modalEditBeneficiary: false });
  };
  openModalRemoveBeneficiary = (beneficiary, isExpand) => {
    let assetSelect = this.props.asset.key;
    let currency = asset.assets.find(
      (element) => assetSelect === element.value
    );
    this.setState({
      modalRemoveBeneficiary: true,
      beneficiarySelect: beneficiary,
      networkSelect: beneficiary.network,
      currencySelect: currency,
      beneficiaryRemove: isExpand ? this.state.beneficiaryRemove : beneficiary,
    });
  };
  closeModalRemoveBeneficiary = () => {
    this.setState({ modalRemoveBeneficiary: false });
  };
  openSend(beneficiary, expand) {
    if (!expand) {
      this.props.infoBeneficiaryToSend(beneficiary);
      this.props.openModalSend(true);
    } else {
      let dataBeneficiary = { ...beneficiary, ...this.state.beneficiaryRemove };
      if (dataBeneficiary.networks !== undefined) {
        delete dataBeneficiary.networks;
      }
      this.props.infoBeneficiaryToSend(dataBeneficiary);
      this.props.openModalSend(true);
    }
  }
  filterTable(nameSearch) {
    let search = nameSearch.toLowerCase();
    let filter = [];
    this.state.listToFilter.forEach((value) => {
      Object.entries(value).forEach(([key, val]) => {
        if (key !== "id" && key !== "type" && key !== "currency") {
          if (key !== "networks") {
            if (val.toLowerCase().includes(search)) {
              if (!filter.find((inerValue) => inerValue.id === value.id)) {
                filter.push(value);
              }
            }
          } else {
            for (let net of val) {
              if (net.address.toLowerCase().includes(search)) {
                if (!filter.find((inerValue) => inerValue.id === value.id)) {
                  filter.push(value);
                }
              }
            }
          }
        }
      });
    });
    this.setState({
      listBeneficiaries: filter,
    });
  }
  hasNetworkColumn() {
    let findColumn = this.state.listBeneficiaries.find(
      (element) => element.currency === "ETH" || element.currency === "BTC"
    );
    if (findColumn !== undefined) {
      return false;
    } else {
      return true;
    }
  }
  onExpand(expand, data, e) {
    expand(data, e);
    this.setState({ beneficiaryRemove: data });
  }
  render() {
    let userType = this.props.dataUser.data?.data?.user?.type;
    if (userType === undefined) {
      userType = "DAMPAY_USER_CHILDREN";
    }
    const columnsNetwork = [
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("beneficiaries.details")}
          </Text>
        ),
        dataIndex: "address",
        key: "address",
        className: "column-data",
        render: (record, originNode) => (
          <>
            <Space
              size={0}
              direction="vertical"
              style={{
                justifyContent: "left",

                display: "flex",
              }}
            >
              {(originNode.networks === undefined ||
                originNode.networks.length <= 1) && (
                <Text
                  style={{
                    color: COLOR.PRIMARY_TEXT,
                    fontSize: 18,
                    fontWeight: 400,
                    marginBottom: 0,
                    textAlign: "left",
                  }}
                >
                  {originNode.network === undefined
                    ? originNode.networks[0].address
                    : originNode.address}
                </Text>
              )}
              <Text
                style={{
                  color: COLOR.SECONDARY_TEXT,
                  fontSize: 16,
                  fontWeight: 400,
                  textAlign: "left",
                }}
              >
                {this.props.t("beneficiaries.description") +
                  ": " +
                  originNode.description}
              </Text>
            </Space>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("beneficiaries.network")}
          </Text>
        ),
        dataIndex: "network",
        key: "network",
        render: (record, originNode) => (
          <>
            {(originNode.networks === undefined ||
              originNode.networks.length <= 1) && (
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 16,
                  fontWeight: 400,
                  marginBottom: 0,
                }}
              >
                {originNode.network === undefined
                  ? originNode.networks[0].network
                  : originNode.network}
              </Text>
            )}
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("beneficiaries.type")}
          </Text>
        ),
        dataIndex: "type",
        key: "type",
        render: (type) => (
          <>
            <Tag color={type === "INTERNAL" ? "#108ee9" : "#f50"}>
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 16,
                  fontWeight: 400,
                  marginBottom: 0,
                }}
              >
                {type === "INTERNAL"
                  ? this.props.t("beneficiaries.internal")
                  : this.props.t("beneficiaries.external")}
              </Text>
            </Tag>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
              paddingRight: "5.5em",
            }}
          >
            {this.props.t("beneficiaries.actions")}
          </Text>
        ),
        key: "actions",
        align: "right",
        className: "column-actions",
        render: (record, originNode) => (
          <>
            {/* {(originNode.networks === undefined ||
              originNode.networks.length <= 1) && ( */}
            <Space direction="horizontal">
              {CAN("CREATE", "TRANSACTIONS") && (
                <Button
                  size="large"
                  block
                  type="primary"
                  onClick={() => this.openSend(originNode)}
                >
                  {this.props.t("beneficiaries.send")}
                </Button>
              )}
              {CAN("EDIT", "BENEFICIARIES") && (
                <Button
                  size="large"
                  style={{ borderColor: COLOR.SECONDARY }}
                  onClick={() =>
                    this.openModalEditBeneficiary(originNode, false)
                  }
                >
                  {this.props.t("beneficiaries.edit")}
                </Button>
              )}
              {CAN("DELETE", "BENEFICIARIES") && (
                <Button
                  size="large"
                  className="button-remove"
                  onClick={() =>
                    this.openModalRemoveBeneficiary(originNode, false)
                  }
                >
                  {this.props.t("beneficiaries.remove")}
                </Button>
              )}
            </Space>
            {/* )} */}
          </>
        ),
      },
    ];

    const columns = [
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("beneficiaries.details")}
          </Text>
        ),
        dataIndex: "address",
        key: "address",
        className: "column-data",
        render: (record, originNode) => (
          <>
            <Space
              size={0}
              direction="vertical"
              style={{ justifyContent: "left" }}
            >
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 18,
                  fontWeight: 400,
                  marginBottom: 0,
                  textAlign: "left",
                }}
              >
                {originNode.network === undefined
                  ? originNode.networks[0].address
                  : originNode.address}
              </Text>
              <Text
                style={{
                  color: COLOR.SECONDARY_TEXT,
                  fontSize: 16,
                  fontWeight: 400,
                  textAlign: "left",
                }}
              >
                {this.props.t("beneficiaries.description") +
                  ": " +
                  originNode.description}
              </Text>
            </Space>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("beneficiaries.type")}
          </Text>
        ),
        dataIndex: "type",
        key: "type",
        render: (type) => (
          <>
            <Tag color={type === "INTERNAL" ? "#108ee9" : "#f50"}>
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 16,
                  fontWeight: 400,
                  marginBottom: 0,
                }}
              >
                {type === "INTERNAL"
                  ? this.props.t("beneficiaries.internal")
                  : this.props.t("beneficiaries.external")}
              </Text>
            </Tag>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
              paddingRight: "5.5em",
            }}
          >
            {this.props.t("beneficiaries.actions")}
          </Text>
        ),
        key: "actions",
        align: "right",
        className: "column-actions",
        render: (record, originNode) => (
          <Space direction="horizontal">
            {CAN("CREATE", "TRANSACTIONS") && (
              <Button
                size="large"
                block
                type="primary"
                onClick={() => this.openSend(originNode)}
              >
                {this.props.t("beneficiaries.send")}
              </Button>
            )}
            {CAN("DELETE", "BENEFICIARIES") && (
              <Button
                size="large"
                style={{ borderColor: COLOR.SECONDARY }}
                onClick={() => this.openModalEditBeneficiary(originNode)}
              >
                {this.props.t("beneficiaries.edit")}
              </Button>
            )}
            {CAN("DELETE", "BENEFICIARIES") && (
              <Button
                size="large"
                className="button-remove"
                onClick={() => this.openModalRemoveBeneficiary(originNode)}
              >
                {this.props.t("beneficiaries.remove")}
              </Button>
            )}
          </Space>
        ),
      },
    ];

    const columnsFiat = [
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
              textAlign: "left",
            }}
          >
            {this.props.t("beneficiaries.details")}
          </Text>
        ),
        dataIndex: "description",
        key: "description",
        width: 250,
        render: (description) => (
          <>
            <Space
              size={0}
              direction="vertical"
              style={{ justifyContent: "left" }}
            >
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 18,
                  fontWeight: 400,
                  marginBottom: 0,
                  textAlign: "left",
                }}
              >
                {description}
              </Text>
            </Space>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("beneficiaries.type")}
          </Text>
        ),
        dataIndex: "type",
        key: "type",
        render: (type) => (
          <>
            <Tag color={type === "INTERNAL" ? "#108ee9" : "#f50"}>
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 16,
                  fontWeight: 400,
                  marginBottom: 0,
                }}
              >
                {type === "INTERNAL"
                  ? this.props.t("beneficiaries.internal")
                  : this.props.t("beneficiaries.external")}
              </Text>
            </Tag>
          </>
        ),
      },
      {
        title: !userType.includes("LITE") ? (
          ""
        ) : (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            Status
          </Text>
        ),
        dataIndex: "status",
        key: "status",
        render: (type) => (
          <>
            {userType.includes("LITE") && (
              <>
                {type === "APPROVED" && (
                  <Tag color="#389E0D">
                    <Text
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        fontSize: 16,
                        fontWeight: 400,
                        marginBottom: 0,
                      }}
                    >
                      {type}
                    </Text>
                  </Tag>
                )}
                {type === "PENDING" && (
                  <Tag color="#531DAB">
                    <Text
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        fontSize: 16,
                        fontWeight: 400,
                        marginBottom: 0,
                      }}
                    >
                      {type}
                    </Text>
                  </Tag>
                )}
                {type === "REJECTED" && (
                  <Tag color="#CF1322">
                    <Text
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        fontSize: 16,
                        fontWeight: 400,
                        marginBottom: 0,
                      }}
                    >
                      {type}
                    </Text>
                  </Tag>
                )}
              </>
            )}
          </>
        ),
      },
      {
        title: CAN("EDIT", "BENEFICIARIES") &&
          CAN("DELETE", "BENEFICIARIES") && (
            <Text
              style={{
                paddingRight: "5.5em",
                color: COLOR.SECONDARY_TEXT,
                fontSize: 18,
              }}
            >
              {this.props.t("beneficiaries.actions")}
            </Text>
          ),
        key: "actions",
        align: userType.includes("LITE") ? "" : "right",
        className: "column-actions",
        render: (record, originNode) => (
          <Space direction="horizontal">
            {CAN("CREATE", "TRANSACTIONS") && (
              <>
                <Button
                  size="large"
                  block
                  type="primary"
                  onClick={() => this.openSend(originNode)}
                >
                  {this.props.t("beneficiaries.send")}
                </Button>
              </>
            )}
            {CAN("EDIT", "BENEFICIARIES") && (
              <>
                {!userType.includes("LITE") && (
                  <Button
                    size="large"
                    style={{ borderColor: COLOR.SECONDARY }}
                    onClick={() => this.openModalEditBeneficiary(originNode)}
                  >
                    {this.props.t("beneficiaries.edit")}
                  </Button>
                )}
              </>
            )}
            {CAN("DELETE", "BENEFICIARIES") && (
              <Button
                size="large"
                className="button-remove"
                onClick={() => this.openModalRemoveBeneficiary(originNode)}
              >
                {this.props.t("beneficiaries.remove")}
              </Button>
            )}
          </Space>
        ),
      },
    ];
    const networksCrypto = [
      {
        dataIndex: "address",
        key: "address",
        className: "column-data-networks",
        render: (address) => (
          <>
            <Space
              size={0}
              direction="vertical"
              style={{ justifyContent: "left" }}
            >
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 18,
                  fontWeight: 400,
                  marginBottom: 0,
                  textAlign: "left",
                }}
              >
                {address}
              </Text>
            </Space>
          </>
        ),
      },
      {
        dataIndex: "network",
        key: "network",
        render: (network) => (
          <>
            <Text
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
                marginBottom: 0,
                textAlign: "center",
              }}
            >
              {network}
            </Text>
          </>
        ),
      },
      // {
      //   key: "actions",
      //   align: "right",
      //   render: (record, originNode) => (
      //     <>
      //       {CAN("CREATE", "TRANSACTIONS") && (
      //         <Space direction="horizontal">
      //           {originNode.network !== undefined && (
      //             <Button
      //               size="large"
      //               block
      //               type="primary"
      //               onClick={() => this.openSend(originNode, true)}
      //             >
      //               {this.props.t("beneficiaries.send")}
      //             </Button>
      //           )}
      //         </Space>
      //       )}
      //     </>
      //   ),
      // },
    ];
    return (
      <div>
        <Row gutter={[8, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: window.screen.width <= 1024 ? 24 : 16, offset: 0 }}
            xl={{ span: 16, offset: 0 }}
            xxl={{ span: 18, offset: 0 }}
          >
            <Card
              bordered={false}
              size="small"
              style={{
                borderRadius: "5px",
                background: COLOR.PRIMARY,
                color: COLOR.SECONDARY_TEXT,
                border: "1px solid #000",
                width: "98%",
              }}
              bodyStyle={{ padding: "0px" }}
            >
              {this.state.loading && <Skeleton active />}

              {!this.state.loading && (
                <div>
                  {this.state.listToFilter.length === 0 && (
                    <Row
                      gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
                      style={{ padding: "25px 25px" }}
                    >
                      <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        lg={{ span: 24, offset: 0 }}
                        xl={{ span: 24, offset: 0 }}
                        xxl={{ span: 24, offset: 0 }}
                        style={{
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <Space direction="vertical">
                          <Image
                            style={{ opacity: "0.6" }}
                            src={this.state.assetActive.icon}
                            preview={false}
                            width={100}
                          />
                          <Text
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              fontSize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            {userType.includes("LITE")
                              ? this.props.t("beneficiaries.noBankAccount")
                              : this.props.t("beneficiaries.noBeneficiaries")}
                          </Text>

                          <Text
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                          >
                            {userType.includes("LITE")
                              ? this.props.t("beneficiaries.msgAddAccount")
                              : this.props.t("beneficiaries.msgAddBeneficiary")}
                          </Text>
                        </Space>
                      </Col>
                    </Row>
                  )}

                  {this.state.listToFilter.length > 0 && (
                    <div>
                      <Row
                        gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
                        style={{ padding: "25px 25px" }}
                      >
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                          xxl={{ span: 12, offset: 0 }}
                          style={{
                            textAlign: "left",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          {this.state.listToFilter.length > 0 && (
                            <div
                              id="search-beneficiaries"
                              className="anticon ant-input-search"
                              style={{ width: "80%" }}
                            >
                              <AutoComplete
                                size="large"
                                placeholder={
                                  this.state.assetActive.type !== "CRYPTO"
                                    ? userType === "DAMPAY_INDIVIDUAL_LITE" ||
                                      userType === "DAMPAY_BUSINESS_LITE"
                                      ? this.props.t(
                                          "beneficiaries.searchBankAccount"
                                        )
                                      : this.props.t(
                                          "beneficiaries.searchBeneficiary"
                                        )
                                    : this.props.t(
                                        "beneficiaries.searchByDescription"
                                      )
                                }
                                dropdownStyle={{ display: "none" }}
                                style={{ width: "100%" }}
                                onChange={(nameSearch) =>
                                  this.filterTable(nameSearch)
                                }
                              >
                                {this.state.listToFilter.map((value) => (
                                  <Option
                                    key={value.description}
                                    value={value.description}
                                  >
                                    {value.description}
                                  </Option>
                                ))}
                              </AutoComplete>
                            </div>
                          )}
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 24, offset: 0 }}
                          lg={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                          xxl={{ span: 12, offset: 0 }}
                          style={{ textAlign: "right" }}
                        ></Col>
                      </Row>
                      {this.state.assetActive.type === "CRYPTO" && (
                        <div
                          id="table-beneficiaries"
                          className="ant-table ant-table-tbody ant-table-thead text-table-cell"
                        >
                          <Table
                            loading={this.state.loading}
                            locale={{
                              emptyText: (
                                <Space direction="vertical">
                                  <br />
                                  <Text
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {userType.includes("LITE")
                                      ? this.props.t(
                                          "beneficiaries.msgEmptyTableAccount"
                                        )
                                      : this.props.t(
                                          "beneficiaries.msgEmptyTable"
                                        )}
                                  </Text>
                                  <Text
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {this.props.t(
                                      "beneficiaries.msgEmptyTable1"
                                    )}
                                  </Text>
                                  <br />
                                </Space>
                              ),
                            }}
                            expandable={{
                              expandedRowRender: (record) =>
                                record.networks !== undefined && (
                                  <div
                                    id="table-networks"
                                    className="ant-table ant-table-tbody ant-table-thead text-table-cell"
                                  >
                                    <Table
                                      columns={networksCrypto}
                                      dataSource={record.networks}
                                      rowKey={(record) => record.asset_id}
                                      pagination={false}
                                    />
                                  </div>
                                ),
                              expandIcon: ({ expanded, onExpand, record }) =>
                                record.networks !== undefined &&
                                (expanded
                                  ? record.networks.length > 1 && (
                                      <MinusOutlined
                                        className="icon-expanded-beneficiaries"
                                        onClick={(e) => onExpand(record, e)}
                                      />
                                    )
                                  : record.networks.length > 1 && (
                                      <PlusOutlined
                                        className="icon-expanded-beneficiaries"
                                        onClick={(e) =>
                                          this.onExpand(onExpand, record, e)
                                        }
                                      />
                                    )),
                              rowExpandable: (record) =>
                                record.networks !== undefined &&
                                record.networks.length > 1,
                            }}
                            pagination={false}
                            rowKey={(record) => record.id}
                            columns={
                              this.hasNetworkColumn() ? columnsNetwork : columns
                            }
                            dataSource={this.state.listBeneficiaries}
                            style={{ overflow: "auto", paddingBottom: 20 }}
                          />
                        </div>
                      )}
                      {this.state.assetActive.type === "FIAT" && (
                        <div
                          id="table-beneficiaries-fiat"
                          className="ant-table ant-table-tbody ant-table-thead text-table-cell"
                        >
                          <Table
                            loading={this.state.loading}
                            pagination={false}
                            locale={{
                              emptyText: (
                                <Space direction="vertical">
                                  <br />
                                  <Text
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {this.props.t(
                                      "beneficiaries.msgEmptyTable"
                                    )}
                                  </Text>
                                  <Text
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                      fontWeight: "normal",
                                    }}
                                  >
                                    {this.props.t(
                                      "beneficiaries.msgEmptyTable1"
                                    )}
                                  </Text>
                                  <br />
                                </Space>
                              ),
                            }}
                            columns={columnsFiat}
                            dataSource={this.state.listBeneficiaries}
                            style={{ overflow: "auto", paddingBottom: 20 }}
                            rowKey={(record) => record.id}
                            expandable={{
                              expandedRowRender: (record) =>
                                record.currency === "EUR" ? (
                                  <Row
                                    style={{
                                      justifyContent: "left",
                                      background: COLOR.PRIMARY,
                                      paddingLeft: 70,
                                    }}
                                  >
                                    <Col span={24}>
                                      <Text
                                        style={{
                                          color: COLOR.SECONDARY_TEXT,
                                          fontSize: 16,
                                          fontWeight: 400,
                                          textAlign: "left",
                                        }}
                                      >
                                        {this.props.t(
                                          "beneficiaries.accountHolder"
                                        )}
                                        :
                                      </Text>
                                      <Text
                                        style={{
                                          color: COLOR.PRIMARY_TEXT,
                                          fontSize: 16,
                                          fontWeight: 400,
                                          textAlign: "left",
                                        }}
                                      >
                                        {" "}
                                        {record.accountHolder}
                                      </Text>
                                      <br />
                                      <Text
                                        style={{
                                          color: COLOR.SECONDARY_TEXT,
                                          fontSize: 16,
                                          fontWeight: 400,
                                          textAlign: "left",
                                        }}
                                      >
                                        IBAN:
                                      </Text>
                                      <Text
                                        style={{
                                          color: COLOR.PRIMARY_TEXT,
                                          fontSize: 16,
                                          fontWeight: 400,
                                          textAlign: "left",
                                        }}
                                      >
                                        {" "}
                                        {record.iban}
                                      </Text>
                                      <br />
                                      <Text
                                        style={{
                                          color: COLOR.SECONDARY_TEXT,
                                          fontSize: 16,
                                          fontWeight: 400,
                                          textAlign: "left",
                                        }}
                                      >
                                        BIC:
                                      </Text>
                                      <Text
                                        style={{
                                          color: COLOR.PRIMARY_TEXT,
                                          fontSize: 16,
                                          fontWeight: 400,
                                          textAlign: "left",
                                        }}
                                      >
                                        {" "}
                                        {record.bic}
                                      </Text>
                                    </Col>
                                  </Row>
                                ) : (
                                  <Row
                                    style={{
                                      justifyContent: "left",
                                      background: COLOR.PRIMARY,
                                      paddingLeft: 70,
                                    }}
                                  >
                                    <Col span={24}>
                                      <Text
                                        style={{
                                          color: COLOR.SECONDARY_TEXT,
                                          fontSize: 16,
                                          fontWeight: 400,
                                          textAlign: "left",
                                        }}
                                      >
                                        {this.props.t(
                                          "beneficiaries.accountHolder"
                                        )}
                                        :
                                      </Text>
                                      <Text
                                        style={{
                                          color: COLOR.PRIMARY_TEXT,
                                          fontSize: 16,
                                          fontWeight: 400,
                                          textAlign: "left",
                                        }}
                                      >
                                        {" "}
                                        {record.accountHolder}
                                      </Text>
                                      <br />
                                      <Text
                                        style={{
                                          color: COLOR.SECONDARY_TEXT,
                                          fontSize: 16,
                                          fontWeight: 400,
                                          textAlign: "left",
                                        }}
                                      >
                                        Sort code:
                                      </Text>
                                      <Text
                                        style={{
                                          color: COLOR.PRIMARY_TEXT,
                                          fontSize: 16,
                                          fontWeight: 400,
                                          textAlign: "left",
                                        }}
                                      >
                                        {" "}
                                        {record.sortCode}
                                      </Text>
                                      <br />
                                      <Text
                                        style={{
                                          color: COLOR.SECONDARY_TEXT,
                                          fontSize: 16,
                                          fontWeight: 400,
                                          textAlign: "left",
                                        }}
                                      >
                                        {this.props.t(
                                          "beneficiaries.accountNumber"
                                        )}
                                        :
                                      </Text>
                                      <Text
                                        style={{
                                          color: COLOR.PRIMARY_TEXT,
                                          fontSize: 16,
                                          fontWeight: 400,
                                          textAlign: "left",
                                        }}
                                      >
                                        {" "}
                                        {record.account}
                                      </Text>
                                    </Col>
                                  </Row>
                                ),
                              expandIcon: ({ expanded, onExpand, record }) =>
                                expanded ? (
                                  <MinusOutlined
                                    className="icon-expanded-beneficiaries"
                                    onClick={(e) => onExpand(record, e)}
                                  />
                                ) : (
                                  <PlusOutlined
                                    className="icon-expanded-beneficiaries"
                                    onClick={(e) => onExpand(record, e)}
                                  />
                                ),
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </Card>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: window.screen.width <= 1024 ? 24 : 8, offset: 0 }}
            xl={{ span: 8, offset: 0 }}
            xxl={{ span: 6, offset: 0 }}
          >
            {CAN("ADD", "BENEFICIARIES") && (
              <Card
                bordered={false}
                size="small"
                style={{
                  borderRadius: "5px",
                  background: COLOR.PRIMARY,
                  color: COLOR.SECONDARY_TEXT,
                  border: "1px solid #000",
                  width: "98%",
                }}
                bodyStyle={{ padding: "0px 0px 15px 0px" }}
              >
                <AddBeneficiary
                  getBeneficiaries={this.getBeneficiaries.bind(this)}
                />
              </Card>
            )}
          </Col>
        </Row>

        {this.state.modalEditBeneficiary && (
          <ModalEditBeneficiary
            modalEditBeneficiary={this.state.modalEditBeneficiary}
            openModalEditBeneficiary={this.openModalEditBeneficiary.bind(this)}
            closeModalEditBeneficiary={this.closeModalEditBeneficiary.bind(
              this
            )}
            currencySelect={this.state.currencySelect}
            beneficiary={this.state.beneficiarySelect}
            getBeneficiaries={this.getBeneficiaries.bind(this)}
            assetActive={this.state.assetActive}
            networkSelect={this.state.networkSelect}
            beneficiaryEdit={this.state.beneficiaryRemove}
          />
        )}
        {this.state.modalRemoveBeneficiary && (
          <ModalRemoveBeneficiary
            modalRemoveBeneficiary={this.state.modalRemoveBeneficiary}
            openModalRemoveBeneficiary={this.openModalRemoveBeneficiary.bind(
              this
            )}
            closeModalRemoveBeneficiary={this.closeModalRemoveBeneficiary.bind(
              this
            )}
            currencySelect={this.state.currencySelect}
            beneficiary={this.state.beneficiarySelect}
            getBeneficiaries={this.getBeneficiaries.bind(this)}
            networkSelect={this.state.networkSelect}
            beneficiaryRemove={this.state.beneficiaryRemove}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser.userData,
    asset: state.siderNavigate.asset,
    accounts: state.banking.accounts,
    beneficiariesList: state.beneficiaries.resultBeneficiaries,
    token: state.tokenAccess,
  };
};

const mapDispatchToProps = {
  getBeneficiaries,
  currentToken,
  openModalSend,
  infoBeneficiaryToSend,
};

const BeneficiariesTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Beneficiaries)
);

export default BeneficiariesTranslated;
