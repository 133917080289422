import React, { Component, Suspense } from "react";
import { Switch } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import "./App.css";
import "antd/dist/antd.min.css";
import COLOR from "./const/colors";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import Routes from "./routes/routes";
import i18n from "./i18n/i18n";
import { getNotifications } from "./redux/actions/notificationsActions";
import {
  getAllTransactions,
  getTransactionsCurrency,
} from "./redux/actions/transactionsActions";
import { getAllTransactionsByFilter } from "./redux/actions/approvalsActions";
import { getAccounts } from "./redux/actions/bankingActions";
import { getTimeline } from "./redux/actions/timeline";
import { currentToken } from "./redux/actions/tokenActions";
import { PersistGate } from "redux-persist/es/integration/react";
import { notification, Typography } from "antd";
import { firebase } from "./firebase";
//import "firebase/compat/messaging";
//import { firebase} from "../public/firebase-messaging-sw";
import { getToken, onMessage, getMessaging } from "firebase/messaging";
//import { getMessaging } from "firebase/messaging/sw";
const { Title, Text } = Typography;
let messaging = null;
messaging = getMessaging(firebase);
// if (firebase.firebase.messaging.isSupported()) {

// }
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { redirect: null };
  }
  componentDidMount() {
    this.getNotificationBackground();
    this.messageListener();
    this.getTokenMessaging();

    if (sessionStorage.getItem("lang") === "en-US") {
      i18n.changeLanguage("en-US");
    } else if (sessionStorage.getItem("lang") === "es-ES") {
      i18n.changeLanguage("es-ES");
    }
  }

  async getNotificationBackground() {
    try {
      if (messaging !== null) {
        const channel = new BroadcastChannel("sw-messages");
        channel.addEventListener("message", async (event) => {
          // console.log("event noti", event);
          let auth =
            Object.keys(store.getState().authUser.userData).length !== 0;
          if (auth && event.data.notification) {
            await this.getNotifications();
            await this.getAccounts();
            await this.getTransactions();
            await this.getTransactionsPerAccount();
            this.loadPendingTx();
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  openNotification = (body) => {
    notification.open({
      closeIcon: <CloseOutlined style={{ color: COLOR.PRIMARY_TEXT }} />,
      message: (
        <Title level={5} style={{ color: COLOR.PRIMARY_TEXT }}>
          {body.title}
        </Title>
      ),
      description: (
        <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 14 }}>
          {body.description}{" "}
        </Text>
      ),
      duration: 20,
      onClose: () => notification.destroy(),
      placement: "bottomRight",
      style: { background: COLOR.PRIMARY },
    });
  };
  openNotificationSession = () => {
    notification["warning"]({
      closeIcon: <CloseOutlined style={{ color: "black" }} />,
      top: 20,
      message:
        sessionStorage.getItem("lang") === "es-ES"
          ? "Lo sentimos, tu sesión ha expirado. Por favor, vuelve a iniciar sesión"
          : "Sorry, your session has expired. Please, sign in again",
      duration: 0,
      onClose: () => notification.destroy(),
    });
  };

  messageListener() {
    if (messaging !== null) {
      try {
        onMessage(messaging, async (payload) => {
          try {
            let auth =
              Object.keys(store.getState().authUser.userData).length !== 0;
            if (auth) {
              let body = {
                title: payload.notification.title,
                description: payload.notification.body,
              };
              let dataUser =
                store.getState().authUser.userData.data?.data?.user.profile;
              let nameUser = dataUser.name;
              let fullName =
                dataUser.last_name === undefined
                  ? nameUser
                  : nameUser + " " + dataUser.last_name;
              if (!payload.notification.body.includes(fullName)) {
                this.openNotification(body);
                await this.getNotifications();
                await this.getTransactions();
                await this.getTransactionsPerAccount();
                await this.getAccounts();
                this.loadPendingTx();
              }
            }
          } catch (error) {
            console.log("payload", error);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }
  async getTokenMessaging() {
    if (messaging !== null) {
      try {
        await navigator.serviceWorker.ready;
        getToken(messaging, {
          vapidKey:
            "BBfrd1YpRkxtiTR1sBAhilTDfpQCn6yIiFpNIi2Zx1nquhtppyMjEMYaZWk12KzL-X2-evNv9jdOKAqtSSDq788",
        })
          .then((currentToken) => {
            if (currentToken) {
              window.localStorage.setItem("token", currentToken);
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      } catch (error) {
        console.log("error", error);
      }
    }
  }
  async getNotifications() {
    try {
      await store.dispatch(
        getNotifications(
          store.getState().tokenAccess.clientId,
          store.getState().tokenAccess.current
        )
      );
      let resp = store.getState().loadNotifications.notifications;
      if (resp.headers !== undefined) {
        let newAccessToken = resp.headers["x-new-access-token"];
        await store.dispatch(currentToken(newAccessToken));
      }
      if (resp.status !== 200) {
        console.log("error", resp.status);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async getAccounts() {
    try {
      await store.dispatch(
        getAccounts(
          store.getState().tokenAccess.clientId,
          store.getState().tokenAccess.current
        )
      );
      let response = store.getState().banking.accounts;
      let newAccessToken = "";
      if (response.headers !== undefined) {
        newAccessToken = response.headers["x-new-access-token"];
        await store.dispatch(currentToken(newAccessToken));
      }
    } catch (error) {}
  }
  async getTimeline() {
    try {
      await store.dispatch(
        getTimeline(
          store.getState().tokenAccess.clientId,
          store.getState().tokenAccess.current
        )
      );
      let response = store.getState().timeline.resultTimeline;
      let newAccessToken = "";
      if (response.headers !== undefined) {
        newAccessToken = response.headers["x-new-access-token"];
        await store.dispatch(currentToken(newAccessToken));
      }
    } catch (error) {}
  }
  async getTransactionsPerAccount() {
    try {
      let assetSelect = store.getState().siderNavigate.asset;
      let accountSelect = "";
      if (assetSelect !== "") {
        let asset = "";
        let accountsList =
          store.getState().banking.accounts.data?.data?.accounts;

        if (assetSelect.id !== null && assetSelect.id !== undefined) {
          asset = assetSelect.id;
        } else {
          accountSelect = accountsList.find(
            (element) => element.currency === assetSelect.key
          );
          asset = accountSelect._id;
        }

        await store.dispatch(
          getTransactionsCurrency(
            store.getState().tokenAccess.clientId,
            store.getState().tokenAccess.current,
            asset
          )
        );
        let response = store.getState().transactions.resultTransactions;
        if (response.headers !== undefined) {
          let newAccessToken = response.headers["x-new-access-token"];
          await store.dispatch(currentToken(newAccessToken));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  async loadPendingTx() {
    try {
      await store.dispatch(
        getAllTransactionsByFilter(
          store.getState().tokenAccess.clientId,
          store.getState().tokenAccess.current,
          null,
          null,
          null,
          null,
          null
        )
      );
    } catch (error) {
      console.log(error);
    }
  }
  async getTransactions() {
    try {
      await store.dispatch(
        getAllTransactions(
          store.getState().tokenAccess.clientId,
          store.getState().tokenAccess.current
        )
      );

      let response = store.getState().transactions.allResultTx;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await store.dispatch(currentToken(newAccessToken));
      }
      if (response.status !== 200) {
        console.log("error", response.status);
      }
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    return (
      <Suspense fallback="loading">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Switch>
              <Routes
                openNotification={this.openNotificationSession.bind(this)}
              />
            </Switch>
          </PersistGate>
        </Provider>
      </Suspense>
    );
  }
}
const AppTranslated = withTranslation()(App);
export default AppTranslated;
