import React, { Component } from "react";
import "./requestAccount.css";
import iconUser from "../../images/user.png";
import iconBusiness from "../../images/business.png";
import id from "../../images/id.png";
import bank from "../../images/bank-line.png";
import registry from "../../images/Documents.png";
import structure from "../../images/organizationalStructure.png";
import personal from "../../images/personal-data.png";
import address from "../../images/address.png";
import selfie from "../../images/selfie.png";
import { countrys } from "../../common/countrysData";
import data from "country-region-data";
import { Link } from "react-router-dom";
import {
  Input,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Space,
  Card,
  Image,
  Select,
  DatePicker,
  Switch,
  Upload,
  InputNumber,
  Radio,
  Result,
  Divider,
  message,
  Spin,
} from "antd";
import COLOR from "../../const/colors";
import "antd/dist/antd.min.css";
import { connect } from "react-redux";
import {
  requestAccount,
  authUser,
  uploadDocuments,
  sendRequest,
  createShareholder,
  resetStore,
} from "../../redux/actions/userActions";
import { siderNavigate } from "../../redux/actions/navigateActions";
import { withTranslation } from "react-i18next";
import {
  MinusCircleOutlined,
  PlusOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
const { TextArea } = Input;
const { Option } = Select;
const { Title, Text } = Typography;
const formRef = React.createRef();
class RequestAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      typeUser: "",
      showAddress: false,
      isPep: false,
      isShareholder: false,
      listCountrys: [],
      arrayCountryRegion: data,
      countrySelected: "",
      countryBusinessSelected: "",
      listRegion: [],
      listRegionBusiness: [],
      regionSelected: null,
      regionBusinessSelected: null,
      shareholderArray: [],
      dateOfBirth: "",
      listCountryCode: [],
      loading: false,
      fileList: [],
      typePersonalHistory: "link",
      valueTypePersonalHistory: 1,
      idApplicant: "",
      token: "",
      linkedin: "",
      loadingDocument: false,
      // linkShareholder: {},
      indexShareholder: 0,
      showListFile: true,
      shareholderCurrent: [],
    };

    this.handleCountry = this.handleCountry.bind(this);
  }

  componentDidMount() {
    this.setState({ listCountrys: data });
    this.filterCodeCountry();
  }
  filterCodeCountry() {
    let arrayCode = [];
    let countrysArray = countrys.country;
    for (let i = 0; i < countrysArray.length; i++) {
      if (countrysArray[i].value !== "") {
        arrayCode.push(countrysArray[i].value);
      }
    }
    let uniqueCodes = [...new Set(arrayCode)];
    this.setState({ listCountryCode: uniqueCodes });
  }
  //-------Actions
  selectTypeUser(value) {
    if (value !== undefined) {
      this.setState({ typeUser: value });
    }
  }
  onChangeDate(date, dateString) {
    let age = this.calculateAge(date);
    if (age >= 18) {
      this.setState({ dateOfBirth: date, dateShow: dateString });
    } else {
      this.setState({ dateOfBirth: "", dateShow: "" });
      message.warning(this.props.t("requestAccount.messagesError.age"), 5);
    }
  }
  next = () => {
    this.setState({ current: this.state.current + 1 });
  };
  prev() {
    this.setState({ current: this.state.current - 1 });
  }
  prevTypeUser() {
    this.setState({ typeUser: "", current: 0 });
  }
  handleIsPep() {
    this.setState({ isPep: !this.state.isPep });
  }
  handleShareholder() {
    this.setState({ isShareholder: !this.state.isShareholder });
  }
  viewAddressPhysical() {
    this.setState({ showAddress: !this.state.showAddress });
  }
  handleCountry(value, type) {
    if (type === "physical") {
      this.setState({ listRegion: [], regionSelected: null });
      let arrayRegion = data.find((element) =>
        element.countryShortCode.includes(value)
      );
      let regionsResult = arrayRegion.regions;
      this.setState({ listRegion: regionsResult });
    } else if (type === "legal") {
      this.setState({ listRegionBusiness: [], regionBusinessSelected: null });
      let arrayRegion = data.find((element) =>
        element.countryShortCode.includes(value)
      );
      let regionsResult = arrayRegion.regions;
      this.setState({ listRegionBusiness: regionsResult });
    }
  }
  onChangeRegion(value, type) {
    if (type === "physical") {
      this.setState({ regionSelected: value });
    } else if (type === "legal") {
      this.setState({ regionBusinessSelected: value });
    }
  }
  onChangePersonalHistory = (e) => {
    if (e.target.value === 1) {
      this.setState({
        typePersonalHistory: "link",
        valueTypePersonalHistory: 1,
      });
    } else if (e.target.value === 2) {
      this.setState({
        typePersonalHistory: "file",
        valueTypePersonalHistory: 2,
      });
    }
  };
  onChangeHistoryShareholder(index, e) {
    let shareholders = this.state.shareholderArray;
    shareholders[index].valueTypePersonalHistory = e.target.value;
    if (e.target.value === 1) {
      shareholders[index].typePersonalHistory = "link";
    } else {
      shareholders[index].typePersonalHistory = "file";
    }
    let newArray = shareholders;
    this.setState({ shareholderArray: newArray });
  }
  onChangeLinkHistory(index, e) {
    let shareholders = this.state.shareholderArray;
    shareholders[index].valueLink = e.target.value;
    let newArray = shareholders;
    this.setState({
      indexShareholder: index,
      shareholderArray: newArray,
    });
  }
  //----Files upload
  beforeUpload(file) {
    let sizeFile = file.size / 1024 / 1024;
    if (sizeFile > 15) {
      message.error(this.props.t("requestAccount.messagesError.fileSize"));
      this.setState({ loadingDocument: false });
    }
    return false;
  }
  async uploadLink() {
    try {
      this.setState({ loadingDocument: true });
      this.props.resetStore();
      let dataDocument = new FormData();
      dataDocument.append("document_type", "PERSONAL_HISTORY");
      dataDocument.append("data_type", "TEXT");
      dataDocument.append("data", this.state.linkedin);
      await this.props.uploadDocuments(
        this.state.idApplicant,
        dataDocument,
        this.state.token
      );
      let response = this.props.request.document;
      if (response !== undefined) {
        if (response.success) {
          await this.sendRequest();
        } else {
          message.error(this.props.t("requestAccount.messagesError.formError"));
          this.setState({ loadingDocument: false });
        }
      } else {
        message.error(this.props.t("requestAccount.messagesError.formError"));
        this.setState({ loadingDocument: false });
      }
    } catch (error) {
      this.setState({
        fileList: [],
      });
      message.error(this.props.t("requestAccount.messagesError.formError"));
      this.setState({ loadingDocument: false });
    }
  }
  async uploadDocuments(key, info) {
    if (info.fileList.length > 0) {
      try {
        this.setState({ loadingDocument: true });
        this.props.resetStore();
        let dataDocument = new FormData();
        dataDocument.append("document_type", key);
        dataDocument.append("data_type", "FILE");
        dataDocument.append("file", info.file);

        await this.props.uploadDocuments(
          this.state.idApplicant,
          dataDocument,
          this.state.token
        );
        let response = this.props.request.document;
        //console.log(response);
        if (response !== undefined) {
          if (response.success) {
            let name = key.split("_")[0].toLowerCase();
            let newList = [];
            newList.push(info.file);
            this.setState({
              loadingDocument: false,
              [name]: newList,
            });
          } else {
            this.setState({
              fileList: [],
            });
            message.error(
              this.props.t("requestAccount.messagesError.formError")
            );
            this.setState({ loadingDocument: false });
          }
        } else {
          message.error(this.props.t("requestAccount.messagesError.formError"));
          this.setState({ loadingDocument: false });
        }
      } catch (error) {
        this.setState({
          fileList: [],
        });
        message.error(this.props.t("requestAccount.messagesError.formError"));
        this.setState({ loadingDocument: false });
      }
    }
  }
  async uploadDocumentsShareholder(key, idAssociate, info) {
    let sizeFile = info.file.size / 1024 / 1024;
    if (sizeFile > 15) {
      message.error(this.props.t("requestAccount.messagesError.fileSize"));
      this.setState({ loadingDocument: false });
    } else {
      try {
        if (info.fileList.length > 0) {
          this.setState({ loadingDocument: true });
          this.props.resetStore();
          let dataDocument = new FormData();
          dataDocument.append("document_type", key);
          dataDocument.append("file", info.file);

          await this.props.uploadDocuments(
            this.state.idApplicant,
            dataDocument,
            this.state.token,
            idAssociate
          );

          let response = this.props.request.document;
          if (response !== undefined) {
            if (response.success) {
              this.setState({
                loadingDocument: false,
                fileList: [...this.state.fileList, info.file],
              });
            } else {
              this.setState({
                fileList: [],
              });
              message.error(
                this.props.t("requestAccount.messagesError.formError")
              );
              this.setState({ loadingDocument: false });
            }
          } else {
            message.error(
              this.props.t("requestAccount.messagesError.formError")
            );
            this.setState({ loadingDocument: false });
          }
        }
      } catch (error) {
        this.setState({
          fileList: [],
        });
        message.error(this.props.t("requestAccount.messagesError.formError"));
        this.setState({ loadingDocument: true });
      }
    }
  }

  //---form functions
  async oneStepIndividual(values) {
    try {
      this.setState({ loading: true });
      this.props.resetStore();
      let body = {
        type: this.state.typeUser,
        email: values.email,
        name: values.name,
        last_name: values.lastname,
        date_of_birth: this.state.dateShow,
        phone_prefix: values.code,
        phone_number: String(values.phone),
        physical_address: {
          line_1: values.line1,
          line_2: values.line2,
          country: values.country,
          region: values.region,
          city: values.city,
          postal_code: values.postalCode,
        },
        pep: values.pep,
      };
      await this.props.requestAccount(body);
      let response = this.props.request.request;
      if (response !== undefined) {
        if (response.success) {
          let token = response.data.token;
          let id = response.data.applicant._id;
          this.setState({
            idApplicant: id,
            loading: false,
            current: this.state.current + 1,
            token: token,
          });
        } else if (
          response.message === "The email supplied is already in use"
        ) {
          message.error(
            this.props.t("requestAccount.messagesError.emailAlready")
          );
          this.setState({ loading: false });
        } else {
          message.error(this.props.t("requestAccount.messagesError.formError"));
          this.setState({ loading: false });
        }
      } else {
        message.error(this.props.t("requestAccount.messagesError.formError"));
        this.setState({ loading: false });
      }
    } catch (error) {
      message.error(this.props.t("requestAccount.messagesError.formError"));
      this.setState({ loading: false });
    }
  }
  async oneStepBusiness(values) {
    try {
      this.setState({ loading: true });
      this.props.resetStore();
      let physicalAddress = {};
      if (!values.sameAddress) {
        physicalAddress = {
          line_1: values.physicalLine1,
          line_2: values.physicalLine2,
          country: values.physicalCountry,
          region: values.physicalRegion,
          city: values.physicalCity,
          postal_code: values.physicalPostalCode,
        };
      } else {
        physicalAddress = {
          line_1: "",
          line_2: "",
          country: "",
          region: "",
          city: "",
          postal_code: "",
        };
      }
      let body = {
        type: "DAMPAY_BUSINESS",
        email: values.email,
        name: values.legalName,
        alternate_name: values.alternateName,
        shareholder_or_executive: values.shareholder,
        website: values.website,
        goods_or_services: values.goodAndServices,
        industry: values.industry,
        business_activities: values.activities,
        phone_prefix: values.code,
        phone_number: String(values.phone),
        legal_address: {
          line_1: values.line1,
          line_2: values.line2,
          country: values.country,
          region: values.region,
          city: values.city,
          postal_code: values.postalCode,
        },
        use_legal_as_physical_address: values.sameAddress,
        physical_address: physicalAddress,
        pep: values.pep,
      };
      await this.props.requestAccount(body);
      let response = this.props.request.request;

      if (response !== undefined) {
        if (response.success) {
          let token = response.data.token;
          let id = response.data.applicant._id;
          this.setState({
            idApplicant: id,
            loading: false,
            current: this.state.current + 1,
            token: token,
          });
        } else if (
          response.message === "The email supplied is already in use"
        ) {
          message.error(
            this.props.t("requestAccount.messagesError.emailAlready")
          );
          this.setState({ loading: false });
        } else {
          message.error(this.props.t("requestAccount.messagesError.formError"));
          this.setState({ loading: false });
        }
      } else {
        message.error(this.props.t("requestAccount.messagesError.formError"));
        this.setState({ loading: false });
      }
    } catch (error) {
      message.error(this.props.t("requestAccount.messagesError.formError"));
      this.setState({ loading: false });
    }
  }

  calculateAge(date) {
    var today = new Date();
    var birthday = new Date(date);
    var age = today.getFullYear() - birthday.getFullYear();
    var m = today.getMonth() - birthday.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }
    return age;
  }
  async sendRequest() {
    try {
      this.setState({ loading: true });
      this.props.resetStore();
      await this.props.sendRequest(this.state.idApplicant, this.state.token);
      let response = this.props.request.commit;
      if (response.success) {
        this.setState({ loading: false, current: this.state.current + 1 });
      } else {
        this.setState({ loading: false });
        message.error(this.props.t("requestAccount.messagesError.formError"));
      }
    } catch (error) {
      this.setState({ loading: false });
      message.error(this.props.t("requestAccount.messagesError.formError"));
    }
  }
  async sendRequestBusiness() {
    try {
      this.setState({ loading: true });
      this.props.resetStore();
      await this.props.sendRequest(this.state.idApplicant, this.state.token);
      let response = this.props.request.commit;
      if (response.success) {
        this.setState({ loading: false, current: this.state.current + 1 });
      } else {
        this.setState({ loading: false });
        message.error(this.props.t("requestAccount.messagesError.formError"));
      }
    } catch (error) {
      this.setState({ loading: false });
      message.error(this.props.t("requestAccount.messagesError.formError"));
    }
  }
  async validateShareholderHistory() {
    this.setState({ loading: true });
    let validLink = this.state.shareholderArray.filter(
      (element) => element.typePersonalHistory === "link"
    );
    //console.log(validLink);

    if (validLink !== undefined) {
      let validContent = validLink.filter(
        (element) => element.handleLink === true
      );

      if (validContent === undefined && validContent.length === 0) {
        this.setState({ loading: false });
        message.error(this.props.t("requestAccount.messagesError.linkError"));
      } else {
        if (validContent.length === validLink.length) {
          //console.log(validContent);
          await this.sendRequestBusiness();
        } else {
          this.setState({ loading: false });
          message.error(this.props.t("requestAccount.messagesError.linkError"));
        }
      }
    }
  }
  async createShareholder(values) {
    try {
      let dataShareholder = values.shareholders;
      this.setState({ loading: true });
      this.props.resetStore();
      let percentage = 0;
      let associatesArray = [];
      if (dataShareholder !== undefined) {
        for (let obj of dataShareholder) {
          let objAssociates = {};
          objAssociates.name = obj.name;
          if (obj.type === "CORPORATE_SHAREHOLDER") {
            objAssociates.alternate_name =
              obj.alternateName !== undefined ? obj.alternateName : "";
          } else {
            objAssociates.last_name = obj.lastname;
          }
          objAssociates.type = obj.type;
          objAssociates.ownership_percentage = obj.percentage;
          percentage = percentage + obj.percentage;
          associatesArray.push(objAssociates);
        }

        let body = {
          associates: associatesArray,
        };
        if (percentage <= 100) {
          await this.props.createShareholder(
            this.state.idApplicant,
            this.state.token,
            body,
            "post"
          );

          let response = this.props.request.shareholder;
          if (response !== undefined) {
            if (response.success) {
              let resultAssociate = response.data.associates;
              for (let val = 0; val < resultAssociate.length; val++) {
                let id = resultAssociate[val].associate_id;
                dataShareholder[val].idAssociate = id;
              }

              this.setState({
                loading: false,
                current: this.state.current + 1,
                shareholderArray: dataShareholder,
              });
            } else {
              this.setState({
                loading: false,
              });
              message.error(
                this.props.t("requestAccount.messagesError.formError")
              );
            }
          }
        } else {
          this.setState({
            loading: false,
          });
          message.error(
            this.props.t("requestAccount.messagesError.percentage")
          );
        }
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
      message.error(this.props.t("requestAccount.messagesError.formError"));
    }
  }
  async sendLinkProfile(values) {
    try {
      let arrayLinkedinProfile = [];
      Object.entries(values).forEach(([key, value]) => {
        let objLink = {};
        if (key.includes("linkedin")) {
          objLink.associate_id = key.split("_")[1];
          objLink.data = value;
          arrayLinkedinProfile.push(objLink);
        }
      });
      this.props.resetStore();
      if (arrayLinkedinProfile.length > 0) {
        this.setState({ loading: true });
        let body = {
          associates: arrayLinkedinProfile,
        };
        await this.props.createShareholder(
          this.state.idApplicant,
          this.state.token,
          body,
          "put"
        );
        let response = this.props.request.shareholder;
        if (response !== undefined) {
          if (response.success) {
            await this.sendRequestBusiness();
          } else {
            message.error(
              this.props.t("requestAccount.messagesError.formError")
            );
            this.setState({ loading: false });
          }
        } else {
          message.error(this.props.t("requestAccount.messagesError.formError"));
          this.setState({ loading: false });
        }
      } else {
        await this.sendRequestBusiness();
      }
    } catch (error) {
      message.error(this.props.t("requestAccount.messagesError.formError"));
      this.setState({ loading: false });
    }
  }
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  render() {
    let industryArray = [
      this.props.t("requestAccount.step2.industryList.0"),
      this.props.t("requestAccount.step2.industryList.1"),
      this.props.t("requestAccount.step2.industryList.2"),
      this.props.t("requestAccount.step2.industryList.3"),
      this.props.t("requestAccount.step2.industryList.4"),
      this.props.t("requestAccount.step2.industryList.5"),
      this.props.t("requestAccount.step2.industryList.6"),
      this.props.t("requestAccount.step2.industryList.7"),
      this.props.t("requestAccount.step2.industryList.8"),
      this.props.t("requestAccount.step2.industryList.9"),
      this.props.t("requestAccount.step2.industryList.10"),
      this.props.t("requestAccount.step2.industryList.11"),
      this.props.t("requestAccount.step2.industryList.12"),
      this.props.t("requestAccount.step2.industryList.13"),
      this.props.t("requestAccount.step2.industryList.14"),
    ];
    let stepFirst = (
      <Row
        gutter={[16, { xs: 8, sm: 16, md: 24, lg: 16 }]}
        style={{ marginTop: 10, marginBottom: 40 }}
      >
        <Space
          align="center"
          direction="vertical"
          style={{ padding: "10px 10px 30px 10px" }}
        >
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 20, offset: 2 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
          >
            <Text style={{ color: COLOR.GRAY }} level={5}>
              {this.props.t("requestAccount.step1.subtitle")}
            </Text>
          </Col>

          <Space align="center" direction="horizontal">
            <Row gutter={[8, { xs: 16, sm: 8, md: 8, lg: 8 }]} justify="center">
              <Col
                xs={{ span: 22, offset: 2 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Card
                  className="card-type-user"
                  onClick={() => this.selectTypeUser("DAMPAY_INDIVIDUAL")}
                >
                  <Card.Grid className="card-type-user">
                    <Image width={50} preview={false} src={iconUser} />
                    <Title
                      style={{ color: COLOR.GRAY, fontWeight: "bold" }}
                      level={5}
                    >
                      {this.props
                        .t("requestAccount.step1.individual")
                        .toUpperCase()}
                    </Title>
                  </Card.Grid>
                </Card>
              </Col>
              <Col
                xs={{ span: 22, offset: 2 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Card
                  className="card-type-user"
                  onClick={() => this.selectTypeUser("DAMPAY_BUSINESS")}
                >
                  <Card.Grid className="card-type-user">
                    <Image width={50} preview={false} src={iconBusiness} />
                    <Title
                      style={{ color: COLOR.GRAY, fontWeight: "bold" }}
                      level={5}
                    >
                      {this.props
                        .t("requestAccount.step1.business")
                        .toUpperCase()}
                    </Title>
                  </Card.Grid>
                </Card>
              </Col>
            </Row>
          </Space>
        </Space>
      </Row>
    );
    let stepTwo = (
      <Form
        preserve={false}
        labelCol={{ span: 24 }}
        wrapperCol={{
          xs: { span: 24, offset: 0 },
          lg: { span: 24, offset: 0 },
        }}
        layout="vertical"
        size="small"
        name="step_two"
        onFinish={
          this.state.typeUser === "DAMPAY_INDIVIDUAL"
            ? this.oneStepIndividual.bind(this)
            : this.oneStepBusiness.bind(this)
        }
        initialValues={{ remember: true }}
        style={{ marginBottom: 0 }}
      >
        {this.state.typeUser === "DAMPAY_INDIVIDUAL" && (
          <div>
            <Row gutter={[32, { xs: 8, sm: 8, md: 8, lg: 8 }]}>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: this.props.t(
                        "requestAccount.messagesError.input",
                        { key: this.props.t("requestAccount.step2.name") }
                      ),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={
                      "* " + this.props.t("requestAccount.step2.name")
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: this.props.t(
                        "requestAccount.messagesError.input",
                        { key: this.props.t("requestAccount.step2.lastname") }
                      ),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={
                      "* " + this.props.t("requestAccount.step2.lastname")
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: this.props.t(
                        "requestAccount.messagesError.input",
                        { key: this.props.t("requestAccount.step2.email") }
                      ),
                    },
                    {
                      type: "email",
                      message: this.props.t(
                        "requestAccount.messagesError.email"
                      ),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={
                      "* " + this.props.t("requestAccount.step2.email")
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item
                  valuePropName={this.state.dateOfBirth}
                  name="dateBirth"
                  rules={[
                    {
                      required: true,
                      message: this.props.t(
                        "requestAccount.messagesError.input",
                        {
                          key: this.props.t("requestAccount.step2.dateOfBirth"),
                        }
                      ),
                    },
                  ]}
                >
                  <DatePicker
                    value={this.state.dateOfBirth}
                    onChange={this.onChangeDate.bind(this)}
                    showToday={false}
                    size="large"
                    placeholder={
                      "* " + this.props.t("requestAccount.step2.dateOfBirth")
                    }
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item>
                  <Input.Group compact size="large">
                    <div
                      id="select-option"
                      className="ant-select-selector ant-select"
                      style={{ width: "30%" }}
                    >
                      <Form.Item
                        name="code"
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: this.props.t(
                              "requestAccount.messagesError.input",
                              { key: this.props.t("requestAccount.step2.code") }
                            ),
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          placeholder={
                            "* " + this.props.t("requestAccount.step2.code")
                          }
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          style={{ width: "100%" }}
                        >
                          {this.state.listCountryCode.map((item, i) => (
                            <Option value={"+" + item}>{"+" + item}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <Form.Item
                      name="phone"
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: this.props.t(
                            "requestAccount.messagesError.input",
                            { key: this.props.t("requestAccount.step2.phone") }
                          ),
                        },
                      ]}
                    >
                      <InputNumber
                        maxLength={14}
                        size="large"
                        style={{ width: "70%" }}
                        placeholder={
                          "* " + this.props.t("requestAccount.step2.phone")
                        }
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
              >
                <Text style={{ color: COLOR.GRAY }} level={5}>
                  {this.props.t("requestAccount.step2.physicalAddress")}
                </Text>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item
                  name="line1"
                  rules={[
                    {
                      required: true,
                      message: this.props.t(
                        "requestAccount.messagesError.input",
                        { key: this.props.t("requestAccount.step2.line1") }
                      ),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={
                      "* " + this.props.t("requestAccount.step2.line1")
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item name="line2" initialValue={""}>
                  <Input
                    size="large"
                    placeholder={this.props.t("requestAccount.step2.line2")}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item>
                  <div
                    id="select-option"
                    className="ant-select-selector ant-select"
                    style={{ width: "100%" }}
                  >
                    <Form.Item
                      name="country"
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: this.props.t(
                            "requestAccount.messagesError.input",
                            {
                              key: this.props.t("requestAccount.step2.country"),
                            }
                          ),
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        placeholder={
                          "* " + this.props.t("requestAccount.step2.country")
                        }
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) =>
                          this.handleCountry(value, "physical")
                        }
                        style={{ width: "100%" }}
                      >
                        {this.state.listCountrys.map((item, i) => (
                          <Option value={item.countryShortCode}>
                            {item.countryName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item>
                  <div
                    id="select-option"
                    className="ant-select-selector ant-select"
                    style={{ width: "100%" }}
                  >
                    <Form.Item
                      valuePropName="option"
                      noStyle
                      name="region"
                      rules={[
                        {
                          required: true,
                          message: this.props.t(
                            "requestAccount.messagesError.input",
                            { key: this.props.t("requestAccount.step2.region") }
                          ),
                        },
                      ]}
                    >
                      <Select
                        value={this.state.regionSelected}
                        size="large"
                        disabled={this.state.listRegion.length === 0}
                        placeholder={
                          "* " + this.props.t("requestAccount.step2.region")
                        }
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) =>
                          this.onChangeRegion(value, "physical")
                        }
                        style={{ width: "100%" }}
                      >
                        {this.state.listRegion.map((item, i) => (
                          <Option value={item.name}>{item.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: this.props.t(
                        "requestAccount.messagesError.input",
                        { key: this.props.t("requestAccount.step2.city") }
                      ),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={
                      "* " + this.props.t("requestAccount.step2.city")
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item
                  name="postalCode"
                  rules={[
                    {
                      required: true,
                      message: this.props.t(
                        "requestAccount.messagesError.input",
                        { key: this.props.t("requestAccount.step2.postalCode") }
                      ),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={
                      "* " + this.props.t("requestAccount.step2.postalCode")
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
              >
                <Form.Item
                  name="pep"
                  initialValue={false}
                  label={"* " + this.props.t("requestAccount.step2.isPep")}
                >
                  <Switch
                    className="switch-address"
                    size="default"
                    checkedChildren={this.props.t("requestAccount.switchYes")}
                    unCheckedChildren={this.props.t("requestAccount.switchNo")}
                    checked={this.state.isPep}
                    onChange={() => this.handleIsPep()}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, { xs: 8, sm: 8, md: 8, lg: 8 }]} justify="end">
              <Col span={24} offset={0}>
                <div className="steps-action">
                  <Form.Item
                    style={{ textAlign: "right", marginBottom: "0.5rem" }}
                  >
                    <Button
                      size="large"
                      className="btn-previous"
                      onClick={() => this.prevTypeUser()}
                    >
                      {this.props.t("requestAccount.buttonPrevious")}
                    </Button>
                  </Form.Item>
                  <Form.Item
                    style={{ textAlign: "right", marginBottom: "0.5rem" }}
                  >
                    <Button size="large" htmlType="submit" type="primary">
                      {this.props.t("requestAccount.buttonNext")}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        )}
        {this.state.typeUser === "DAMPAY_BUSINESS" && (
          <div>
            <Row gutter={[32, { xs: 8, sm: 8, md: 8, lg: 8 }]}>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item
                  name="legalName"
                  rules={[
                    {
                      required: true,
                      message: this.props.t(
                        "requestAccount.messagesError.input",
                        { key: this.props.t("requestAccount.step2.legalName") }
                      ),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={
                      "* " + this.props.t("requestAccount.step2.legalName")
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item name="alternateName" initialValue={""}>
                  <Input
                    size="large"
                    placeholder={this.props.t(
                      "requestAccount.step2.alternateName"
                    )}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: this.props.t(
                        "requestAccount.messagesError.input",
                        { key: this.props.t("requestAccount.step2.email") }
                      ),
                    },
                    {
                      type: "email",
                      message: this.props.t(
                        "requestAccount.messagesError.email"
                      ),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={
                      "* " + this.props.t("requestAccount.step2.email")
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item
                  name="website"
                  initialValue={""}
                  rules={[
                    {
                      type: "url",
                      message: this.props.t("requestAccount.messagesError.web"),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={this.props.t("requestAccount.step2.website")}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item>
                  <div
                    id="select-option"
                    className="ant-select-selector ant-select"
                    style={{ width: "100%" }}
                  >
                    <Form.Item
                      name="industry"
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: this.props.t(
                            "requestAccount.messagesError.input",
                            {
                              key: this.props.t(
                                "requestAccount.step2.industry"
                              ),
                            }
                          ),
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        placeholder={
                          "* " + this.props.t("requestAccount.step2.industry")
                        }
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: "100%" }}
                      >
                        {industryArray.map((item, i) => (
                          <Option value={item}>{item}</Option>
                        ))}
                      </Select>
                    </Form.Item>{" "}
                  </div>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item>
                  <div
                    id="select-option"
                    className="ant-select-selector ant-select"
                    style={{ width: "100%" }}
                  >
                    <Form.Item
                      name="goodAndServices"
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: this.props.t(
                            "requestAccount.messagesError.input",
                            {
                              key: this.props.t(
                                "requestAccount.step2.goodsAndServices.placeholder"
                              ),
                            }
                          ),
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        placeholder={
                          "* " +
                          this.props.t(
                            "requestAccount.step2.goodsAndServices.placeholder"
                          )
                        }
                        style={{ width: "100%" }}
                      >
                        <Option value="Goods">
                          {this.props.t(
                            "requestAccount.step2.goodsAndServices.goods"
                          )}
                        </Option>
                        <Option value="Services">
                          {this.props.t(
                            "requestAccount.step2.goodsAndServices.services"
                          )}
                        </Option>
                        <Option value="Goods and Services">
                          {this.props.t(
                            "requestAccount.step2.goodsAndServices.goodAndServices"
                          )}
                        </Option>
                      </Select>
                    </Form.Item>
                  </div>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item>
                  <Input.Group compact size="large">
                    <div
                      id="select-option"
                      className="ant-select-selector ant-select"
                      style={{ width: "30%" }}
                    >
                      <Form.Item
                        name="code"
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: this.props.t(
                              "requestAccount.messagesError.input",
                              { key: this.props.t("requestAccount.step2.code") }
                            ),
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          size="large"
                          placeholder={
                            "* " + this.props.t("requestAccount.step2.code")
                          }
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.state.listCountryCode.map((item, i) => (
                            <Option value={"+" + item}>{"+" + item}</Option>
                          ))}
                        </Select>
                      </Form.Item>{" "}
                    </div>
                    <Form.Item
                      name="phone"
                      initialValue={""}
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: this.props.t(
                            "requestAccount.messagesError.input",
                            { key: this.props.t("requestAccount.step2.phone") }
                          ),
                        },
                      ]}
                    >
                      <InputNumber
                        maxLength={14}
                        size="large"
                        style={{ width: "70%" }}
                        placeholder={
                          "* " + this.props.t("requestAccount.step2.phone")
                        }
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item
                  name="activities"
                  rules={[
                    {
                      required: true,
                      message: this.props.t(
                        "requestAccount.messagesError.input",
                        { key: this.props.t("requestAccount.step2.activities") }
                      ),
                    },
                  ]}
                >
                  <TextArea
                    size="large"
                    rows={3}
                    placeholder={
                      "* " + this.props.t("requestAccount.step2.activities")
                    }
                  />
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
              >
                <Form.Item
                  name="shareholder"
                  initialValue={false}
                  label={this.props.t("requestAccount.step2.shareholder")}
                >
                  <Switch
                    className="switch-address"
                    size="default"
                    checkedChildren={this.props.t("requestAccount.switchYes")}
                    unCheckedChildren={this.props.t("requestAccount.switchNo")}
                    checked={this.state.isShareholder}
                    onChange={() => this.handleShareholder()}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
              >
                <Form.Item
                  name="pep"
                  initialValue={false}
                  label={this.props.t("requestAccount.step2.isPepBusiness")}
                >
                  <Switch
                    className="switch-address"
                    size="default"
                    checkedChildren={this.props.t("requestAccount.switchYes")}
                    unCheckedChildren={this.props.t("requestAccount.switchNo")}
                    checked={this.state.isPep}
                    onChange={() => this.handleIsPep()}
                  />
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
              >
                <Text style={{ color: COLOR.GRAY }} level={5}>
                  {this.props.t("requestAccount.step2.legalAddress")}
                </Text>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item
                  name="line1"
                  rules={[
                    {
                      required: true,
                      message: this.props.t(
                        "requestAccount.messagesError.input",
                        { key: this.props.t("requestAccount.step2.line1") }
                      ),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={
                      "* " + this.props.t("requestAccount.step2.line1")
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item name="line2" initialValue={""}>
                  <Input
                    size="large"
                    placeholder={this.props.t("requestAccount.step2.line2")}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item>
                  <div
                    id="select-option"
                    className="ant-select-selector ant-select"
                    style={{ width: "100%" }}
                  >
                    <Form.Item
                      name="country"
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: this.props.t(
                            "requestAccount.messagesError.input",
                            {
                              key: this.props.t("requestAccount.step2.country"),
                            }
                          ),
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        size="large"
                        placeholder={
                          "* " + this.props.t("requestAccount.step2.country")
                        }
                        key="legal"
                        onSelect={(value) => this.handleCountry(value, "legal")}
                        style={{ width: "100%" }}
                      >
                        {this.state.listCountrys.map((item, i) => (
                          <Option value={item.countryShortCode}>
                            {item.countryName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item>
                  <div
                    id="select-option"
                    className="ant-select-selector ant-select"
                    style={{ width: "100%" }}
                  >
                    <Form.Item
                      noStyle
                      valuePropName="option"
                      name="region"
                      rules={[
                        {
                          required: true,
                          message: this.props.t(
                            "requestAccount.messagesError.input",
                            { key: this.props.t("requestAccount.step2.region") }
                          ),
                        },
                      ]}
                    >
                      <Select
                        value={this.state.regionBusinessSelected}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        size="large"
                        disabled={this.state.listRegionBusiness.length === 0}
                        placeholder={
                          "* " + this.props.t("requestAccount.step2.region")
                        }
                        onChange={(value) =>
                          this.onChangeRegion(value, "legal")
                        }
                        style={{ width: "100%" }}
                      >
                        {this.state.listRegionBusiness.map((item, i) => (
                          <Option value={item.name}>{item.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>{" "}
                  </div>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: this.props.t(
                        "requestAccount.messagesError.input",
                        { key: this.props.t("requestAccount.step2.city") }
                      ),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={
                      "* " + this.props.t("requestAccount.step2.city")
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
              >
                <Form.Item
                  name="postalCode"
                  rules={[
                    {
                      required: true,
                      message: this.props.t(
                        "requestAccount.messagesError.input",
                        { key: this.props.t("requestAccount.step2.postalCode") }
                      ),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={
                      "* " + this.props.t("requestAccount.step2.postalCode")
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
              >
                <Form.Item
                  name="sameAddress"
                  initialValue={true}
                  label={this.props.t("requestAccount.step2.sameAddress")}
                >
                  <Switch
                    className="switch-address"
                    size="default"
                    checkedChildren={this.props.t("requestAccount.switchYes")}
                    unCheckedChildren={this.props.t("requestAccount.switchNo")}
                    checked={!this.state.showAddress}
                    onChange={() => this.viewAddressPhysical()}
                  />
                </Form.Item>
              </Col>

              {this.state.showAddress && (
                <Row gutter={[32, { xs: 8, sm: 8, md: 8, lg: 8 }]}>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                  >
                    <Text style={{ color: COLOR.GRAY }} level={5}>
                      {this.props.t("requestAccount.step2.physicalAddress")}
                    </Text>
                  </Col>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                  >
                    <Form.Item
                      name="physicalLine1"
                      rules={[
                        {
                          required: true,
                          message: this.props.t(
                            "requestAccount.messagesError.input",
                            { key: this.props.t("requestAccount.step2.line1") }
                          ),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={
                          "* " + this.props.t("requestAccount.step2.line1")
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                  >
                    <Form.Item name="physicalLine2" initialValue={""}>
                      <Input
                        size="large"
                        placeholder={this.props.t("requestAccount.step2.line2")}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                  >
                    <Form.Item>
                      <div
                        id="select-option"
                        className="ant-select-selector ant-select"
                        style={{ width: "100%" }}
                      >
                        <Form.Item
                          name="physicalCountry"
                          noStyle
                          rules={[
                            {
                              required: true,
                              message: this.props.t(
                                "requestAccount.messagesError.input",
                                {
                                  key: this.props.t(
                                    "requestAccount.step2.country"
                                  ),
                                }
                              ),
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            size="large"
                            placeholder={
                              "* " +
                              this.props.t("requestAccount.step2.country")
                            }
                            key="physical"
                            onSelect={(value) =>
                              this.handleCountry(value, "physical")
                            }
                            style={{ width: "100%" }}
                          >
                            {this.state.listCountrys.map((item, i) => (
                              <Option value={item.countryShortCode}>
                                {item.countryName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                  >
                    <Form.Item>
                      <div
                        id="select-option"
                        className="ant-select-selector ant-select"
                        style={{ width: "100%" }}
                      >
                        <Form.Item
                          valuePropName="option"
                          noStyle
                          name="physicalRegion"
                          rules={[
                            {
                              required: true,
                              message: this.props.t(
                                "requestAccount.messagesError.input",
                                {
                                  key: this.props.t(
                                    "requestAccount.step2.region"
                                  ),
                                }
                              ),
                            },
                          ]}
                        >
                          <Select
                            value={this.state.regionSelected}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            size="large"
                            disabled={this.state.listRegion.length === 0}
                            placeholder={
                              "* " + this.props.t("requestAccount.step2.region")
                            }
                            onChange={(value) =>
                              this.onChangeRegion(value, "physical")
                            }
                            style={{ width: "100%" }}
                          >
                            {this.state.listRegion.map((item, i) => (
                              <Option value={item.name}>{item.name}</Option>
                            ))}
                          </Select>
                        </Form.Item>{" "}
                      </div>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                  >
                    <Form.Item
                      name="physicalCity"
                      rules={[
                        {
                          required: true,
                          message: this.props.t(
                            "requestAccount.messagesError.input",
                            { key: this.props.t("requestAccount.step2.city") }
                          ),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={
                          "* " + this.props.t("requestAccount.step2.city")
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 12, offset: 0 }}
                    lg={{ span: 12, offset: 0 }}
                  >
                    <Form.Item
                      name="physicalPostalCode"
                      rules={[
                        {
                          required: true,
                          message: this.props.t(
                            "requestAccount.messagesError.input",
                            {
                              key: this.props.t(
                                "requestAccount.step2.postalCode"
                              ),
                            }
                          ),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={
                          "* " + this.props.t("requestAccount.step2.postalCode")
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Row>
            <Row gutter={[32, { xs: 8, sm: 8, md: 8, lg: 8 }]} justify="end">
              <Col span={24} offset={0}>
                <div className="steps-action">
                  <Form.Item
                    style={{ textAlign: "right", marginBottom: "0.5rem" }}
                  >
                    <Button
                      size="large"
                      className="btn-previous"
                      onClick={() => this.prevTypeUser()}
                    >
                      {this.props.t("requestAccount.buttonPrevious")}
                    </Button>
                  </Form.Item>
                  <Form.Item
                    style={{ textAlign: "right", marginBottom: "-0.5rem" }}
                  >
                    <Button size="large" htmlType="submit" type="primary">
                      {this.props.t("requestAccount.buttonNext")}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Form>
    );
    let stepThree = (
      <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 16 }]} justify="center">
        {this.state.typeUser === "DAMPAY_INDIVIDUAL" && (
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              lg: { span: 24, offset: 0 },
            }}
            layout="vertical"
            size="small"
            style={{ width: "100%" }}
            onFinish={
              this.state.typePersonalHistory === "link"
                ? this.uploadLink.bind(this)
                : this.sendRequest.bind(this)
            }
          >
            <Row
              gutter={[8, { xs: 8, sm: 16, md: 24, lg: 16 }]}
              justify="center"
            >
              <Text
                style={{ color: COLOR.GRAY, textAlign: "center" }}
                level={5}
              >
                {this.props.t("requestAccount.step3.subtitle")}
              </Text>
            </Row>
            <br />

            <Row gutter={[16, { xs: 8, sm: 8, md: 8, lg: 8 }]}>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 6 }}
                lg={{ span: 12, offset: 6 }}
              >
                <Form.Item
                  name="indentity"
                  rules={[
                    {
                      required: true,
                      message: this.props.t("requestAccount.step3.file"),
                    },
                  ]}
                  style={{ textAlign: "center" }}
                  valuePropName="fileList"
                  getValueFromEvent={this.normFile.bind(this)}
                >
                  <Upload
                    fileList={this.state.identity}
                    beforeUpload={this.beforeUpload.bind(this)}
                    name="identity"
                    showUploadList={true}
                    maxCount={1}
                    style={{
                      width: "100%",
                    }}
                    onChange={this.uploadDocuments.bind(this, "IDENTITY")}
                    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.jfif,.pjpeg,.pjp"
                  >
                    <Button
                      className="button-file"
                      size="large"
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        width: "100%",
                        whiteSpace: "normal",
                      }}
                    >
                      <Image width={28} preview={false} src={id} />
                      <Text className="text-upload-documents">
                        {"* " + this.props.t("requestAccount.step3.identity")}
                      </Text>
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 6 }}
                lg={{ span: 12, offset: 6 }}
              >
                <Form.Item
                  name="selfie"
                  rules={[
                    {
                      required: true,
                      message: this.props.t("requestAccount.step3.file"),
                    },
                  ]}
                  style={{ textAlign: "center" }}
                  valuePropName="fileList"
                  getValueFromEvent={this.normFile.bind(this)}
                >
                  <Upload
                    fileList={this.state.selfie}
                    beforeUpload={this.beforeUpload.bind(this)}
                    name="selfie"
                    showUploadList={true}
                    maxCount={1}
                    style={{
                      width: "100%",
                    }}
                    onChange={this.uploadDocuments.bind(this, "SELFIE")}
                    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.jfif,.pjpeg,.pjp"
                  >
                    <Button
                      className="button-file"
                      size="large"
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        width: "100%",
                        whiteSpace: "normal",
                      }}
                    >
                      <Image width={40} preview={false} src={selfie} />
                      <Text
                        className="text-upload-documents"
                        style={{ textAlign: "left" }}
                      >
                        {"* " + this.props.t("requestAccount.step3.selfie")}
                      </Text>
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 6 }}
                lg={{ span: 12, offset: 6 }}
              >
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: this.props.t("requestAccount.step3.file"),
                    },
                  ]}
                  style={{ textAlign: "center" }}
                  valuePropName="fileList"
                  getValueFromEvent={this.normFile.bind(this)}
                >
                  <Upload
                    fileList={this.state.residential}
                    beforeUpload={this.beforeUpload.bind(this)}
                    name="address"
                    showUploadList={true}
                    maxCount={1}
                    style={{
                      width: "100%",
                    }}
                    onChange={this.uploadDocuments.bind(
                      this,
                      "RESIDENTIAL_ADDRESS"
                    )}
                    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.jfif,.pjpeg,.pjp"
                  >
                    <Button
                      className="button-file"
                      size="large"
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        width: "100%",
                        whiteSpace: "normal",
                      }}
                    >
                      <Image width={26} preview={false} src={address} />
                      <Text className="text-upload-documents">
                        {"* " + this.props.t("requestAccount.step3.address")}
                      </Text>
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 6 }}
                lg={{ span: 12, offset: 6 }}
              >
                <Form.Item
                  name="bank"
                  rules={[
                    {
                      required: true,
                      message: this.props.t("requestAccount.step3.file"),
                    },
                  ]}
                  style={{ textAlign: "center" }}
                  valuePropName="fileList"
                  getValueFromEvent={this.normFile.bind(this)}
                >
                  <Upload
                    fileList={this.state.bank}
                    beforeUpload={this.beforeUpload.bind(this)}
                    name="bankInd"
                    showUploadList={true}
                    maxCount={1}
                    style={{
                      width: "100%",
                    }}
                    onChange={this.uploadDocuments.bind(this, "BANK_STATEMENT")}
                    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.jfif,.pjpeg,.pjp"
                  >
                    <Button
                      className="button-file"
                      size="large"
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        width: "100%",
                        whiteSpace: "normal",
                      }}
                    >
                      <Image width={28} preview={false} src={bank} />
                      <Text className="text-upload-documents">
                        {"* " + this.props.t("requestAccount.step3.bank")}
                      </Text>
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 6 }}
                lg={{ span: 12, offset: 6 }}
              >
                <Form.Item
                  name="history"
                  style={{ textAlign: "center" }}
                  label={
                    "* " + this.props.t("requestAccount.step3.personalHistory")
                  }
                >
                  <Radio.Group
                    onChange={this.onChangePersonalHistory.bind(this)}
                    value={this.state.valueTypePersonalHistory}
                    style={{ marginBottom: 10 }}
                  >
                    <Radio value={1}>
                      {this.props.t("requestAccount.step3.link")}
                    </Radio>
                    <Radio value={2}>
                      {this.props.t("requestAccount.step3.curriculum")}
                    </Radio>
                  </Radio.Group>
                  <br />
                  {this.state.typePersonalHistory === "file" && (
                    <Form.Item
                      name="curriculum"
                      rules={[
                        {
                          required: true,
                          message: this.props.t(
                            "requestAccount.messagesError.input",
                            {
                              key: this.props.t(
                                "requestAccount.step3.curriculum"
                              ),
                            }
                          ),
                        },
                      ]}
                      style={{ textAlign: "center" }}
                      valuePropName="fileList"
                      getValueFromEvent={this.normFile.bind(this)}
                    >
                      <Upload
                        fileList={this.state.personal}
                        beforeUpload={this.beforeUpload.bind(this)}
                        name="cvInd"
                        showUploadList={true}
                        maxCount={1}
                        style={{
                          width: "100%",
                        }}
                        onChange={this.uploadDocuments.bind(
                          this,
                          "PERSONAL_HISTORY"
                        )}
                        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.jfif,.pjpeg,.pjp"
                      >
                        <Button
                          className="button-file"
                          size="large"
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            width: "100%",
                            whiteSpace: "normal",
                          }}
                        >
                          <Image width={26} preview={false} src={personal} />
                          <Text className="text-upload-documents">
                            {"* " +
                              this.props.t("requestAccount.step3.curriculum")}
                          </Text>
                        </Button>
                      </Upload>
                    </Form.Item>
                  )}
                  {this.state.typePersonalHistory === "link" && (
                    <Form.Item
                      name="linkedin"
                      rules={[
                        {
                          required: true,
                          message: this.props.t(
                            "requestAccount.messagesError.input",
                            {
                              key: this.props.t("requestAccount.step3.link"),
                            }
                          ),
                        },
                      ]}
                      style={{ textAlign: "center" }}
                    >
                      <Input
                        size="large"
                        placeholder={
                          "* " + this.props.t("requestAccount.step3.link")
                        }
                        onChange={(e) =>
                          this.setState({ linkedin: e.target.value })
                        }
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, { xs: 8, sm: 8, md: 8, lg: 8 }]} justify="end">
              <Col span={24} offset={0}>
                <div className="steps-action">
                  <Form.Item
                    style={{ textAlign: "right", marginBottom: "1rem" }}
                  >
                    <Button
                      size="large"
                      className="btn-previous"
                      onClick={() => this.prev()}
                    >
                      {this.props.t("requestAccount.buttonPrevious")}
                    </Button>
                  </Form.Item>

                  <Form.Item
                    style={{ textAlign: "right", marginBottom: "1rem" }}
                  >
                    <Button
                      htmlType="submit"
                      size="large"
                      type="primary"
                      loading={this.state.loadingDocument}
                    >
                      {this.props.t("requestAccount.buttonDone")}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        )}
        {this.state.typeUser === "DAMPAY_BUSINESS" && (
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            size="small"
            style={{ width: "100%" }}
            onFinish={this.next.bind(this)}
          >
            <Row
              gutter={[8, { xs: 8, sm: 16, md: 24, lg: 8 }]}
              justify="center"
              style={{ textAlign: "center" }}
            >
              <Text
                style={{
                  color: COLOR.GRAY,
                  textAlign: "center",
                  marginBottom: 15,
                }}
                level={5}
              >
                {this.props.t("requestAccount.step3.subtitleBusiness")}
              </Text>
            </Row>

            <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 8 }]} justify="start">
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 6 }}
                lg={{ span: 12, offset: 6 }}
              >
                <Form.Item
                  name="registry"
                  rules={[
                    {
                      required: true,
                      message: this.props.t("requestAccount.step3.file"),
                    },
                  ]}
                  style={{ textAlign: "center" }}
                  valuePropName="fileList"
                  getValueFromEvent={this.normFile.bind(this)}
                >
                  <Upload
                    fileList={this.state.registry}
                    beforeUpload={this.beforeUpload.bind(this)}
                    name="registryBus"
                    showUploadList={true}
                    maxCount={1}
                    style={{
                      width: "100%",
                    }}
                    onChange={this.uploadDocuments.bind(this, "REGISTRY")}
                    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.jfif,.pjpeg,.pjp"
                  >
                    <Button
                      className="button-file"
                      size="large"
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        width: "100%",
                        whiteSpace: "normal",
                      }}
                    >
                      <Image width={28} preview={false} src={registry} />
                      <Text className="text-upload-documents">
                        {"* " + this.props.t("requestAccount.step3.registry")}
                      </Text>
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 6 }}
                lg={{ span: 12, offset: 6 }}
              >
                <Form.Item
                  name="bankBusiness"
                  rules={[
                    {
                      required: true,
                      message: this.props.t("requestAccount.step3.file"),
                    },
                  ]}
                  style={{ textAlign: "center" }}
                  valuePropName="fileList"
                  getValueFromEvent={this.normFile.bind(this)}
                >
                  <Upload
                    fileList={this.state.bank}
                    beforeUpload={this.beforeUpload.bind(this)}
                    name="bankBus"
                    showUploadList={true}
                    maxCount={1}
                    style={{
                      width: "100%",
                    }}
                    onChange={this.uploadDocuments.bind(this, "BANK_STATEMENT")}
                    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.jfif,.pjpeg,.pjp"
                  >
                    <Button
                      className="button-file"
                      size="large"
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        width: "100%",
                        whiteSpace: "normal",
                      }}
                    >
                      <Image width={30} preview={false} src={bank} />
                      <Text
                        className="text-upload-documents"
                        style={{ textAlign: "left" }}
                      >
                        {"* " +
                          this.props.t("requestAccount.step3.bankStatement")}
                      </Text>
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 6 }}
                lg={{ span: 12, offset: 6 }}
              >
                <Form.Item
                  name="structure"
                  style={{ textAlign: "center" }}
                  valuePropName="fileList"
                  getValueFromEvent={this.normFile.bind(this)}
                >
                  <Upload
                    fileList={this.state.group}
                    beforeUpload={this.beforeUpload.bind(this)}
                    name="structureBus"
                    showUploadList={true}
                    maxCount={1}
                    style={{
                      width: "100%",
                    }}
                    onChange={this.uploadDocuments.bind(
                      this,
                      "GROUP_STRUCTURE_CHART"
                    )}
                    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.jfif,.pjpeg,.pjp"
                  >
                    <Button
                      className="button-file"
                      size="large"
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        width: "100%",
                        whiteSpace: "normal",
                      }}
                    >
                      <Image width={28} preview={false} src={structure} />
                      <Text className="text-upload-documents">
                        {this.props.t("requestAccount.step3.structure")}
                      </Text>
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 8 }]} justify="end">
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
              >
                <div className="steps-action">
                  <Form.Item
                    style={{ textAlign: "right", marginBottom: "0.5rem" }}
                  >
                    <Button
                      size="large"
                      className="btn-previous"
                      onClick={() => this.prev()}
                    >
                      {this.props.t("requestAccount.buttonPrevious")}
                    </Button>
                  </Form.Item>

                  <Form.Item
                    style={{ textAlign: "right", marginBottom: "0.5rem" }}
                  >
                    <Button
                      size="large"
                      htmlType="submit"
                      type="primary"
                      loading={this.state.loadingDocument}
                    >
                      {this.props.t("requestAccount.buttonNext")}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Row>
    );
    let stepFour = (
      <Form
        ref={formRef}
        labelCol={{ span: 24 }}
        wrapperCol={{
          xs: { span: 24, offset: 0 },
          lg: { span: 24, offset: 0 },
        }}
        initialValues={{ shareholders: [""] }}
        layout="vertical"
        size="small"
        onFinish={this.createShareholder.bind(this)}
        style={{ width: "100%", marginBottom: "-1.5rem", padding: 0 }}
      >
        <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 16 }]} justify="center">
          <Text style={{ color: COLOR.GRAY, textAlign: "center" }} level={5}>
            {this.props.t("requestAccount.step4.subtitle")}
          </Text>
        </Row>
        <br />

        <Form.List name="shareholders">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <Row
                  gutter={[8, { xs: 8, sm: 8, md: 8, lg: 16 }]}
                  justify="center"
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <Col
                    xs={{ span: 12, offset: 0 }}
                    sm={{ span: 6, offset: 1 }}
                    md={{ span: 6, offset: 1 }}
                    lg={{ span: 6, offset: 1 }}
                  >
                    <Form.Item>
                      <div
                        id="select-option"
                        className="ant-select-selector ant-select"
                        style={{ width: "100%" }}
                      >
                        <Form.Item
                          initialValue="INDIVIDUAL_SHAREHOLDER"
                          {...restField}
                          noStyle
                          name={[name, "type"]}
                          fieldKey={[fieldKey, "type"]}
                          rules={[
                            {
                              required: true,
                              message: this.props.t(
                                "requestAccount.messagesError.input",
                                {
                                  key: this.props.t(
                                    "requestAccount.step4.typeList.placeholder"
                                  ),
                                }
                              ),
                            },
                          ]}
                          onField
                        >
                          <Select
                            size="large"
                            placeholder={
                              "* " +
                              this.props.t(
                                "requestAccount.step4.typeList.placeholder"
                              )
                            }
                            style={{ width: "100%" }}
                            onChange={() => {
                              this.setState({
                                shareholderCurrent: formRef.current.getFieldValue(
                                  "shareholders"
                                ),
                              });
                            }}
                          >
                            <Option value="DIRECTOR">
                              {this.props.t(
                                "requestAccount.step4.typeList.director"
                              )}
                            </Option>
                            <Option value="INDIVIDUAL_SHAREHOLDER">
                              {this.props.t(
                                "requestAccount.step4.typeList.individual"
                              )}
                            </Option>
                            <Option value="CORPORATE_SHAREHOLDER">
                              {this.props.t(
                                "requestAccount.step4.typeList.corporate"
                              )}
                            </Option>
                          </Select>
                        </Form.Item>{" "}
                      </div>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 12, offset: 0 }}
                    sm={{ span: 6, offset: 0 }}
                    md={{ span: 6, offset: 0 }}
                    lg={{ span: 6, offset: 0 }}
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "name"]}
                      fieldKey={[fieldKey, "name"]}
                      rules={[
                        {
                          required: true,
                          message: this.props.t(
                            "requestAccount.messagesError.input",
                            { key: this.props.t("requestAccount.step4.name") }
                          ),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        style={{ width: "100%" }}
                        placeholder={
                          "* " + this.props.t("requestAccount.step4.name")
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 12, offset: 0 }}
                    sm={{ span: 6, offset: 0 }}
                    md={{ span: 6, offset: 0 }}
                    lg={{ span: 6, offset: 0 }}
                  >
                    {this.state.shareholderCurrent.length !== 0 &&
                    this.state.shareholderCurrent[index] !== undefined &&
                    this.state.shareholderCurrent[index].type ===
                      "CORPORATE_SHAREHOLDER" ? (
                      <div>
                        <Form.Item
                          {...restField}
                          initialValue=""
                          dependencies={["type"]}
                          name={[name, "alternateName"]}
                          fieldKey={[fieldKey, "alternateName"]}
                        >
                          <Input
                            size="large"
                            style={{ width: "100%" }}
                            placeholder={this.props.t(
                              "requestAccount.step4.alternateName"
                            )}
                          />
                        </Form.Item>
                      </div>
                    ) : (
                      <div>
                        <Form.Item
                          {...restField}
                          initialValue=""
                          dependencies={["type"]}
                          name={[name, "lastname"]}
                          fieldKey={[fieldKey, "lastname"]}
                          rules={[
                            {
                              required: true,
                              message: this.props.t(
                                "requestAccount.messagesError.input",
                                {
                                  key: this.props.t(
                                    "requestAccount.step4.lastname"
                                  ),
                                }
                              ),
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            style={{ width: "100%" }}
                            placeholder={
                              "* " +
                              this.props.t("requestAccount.step4.lastname")
                            }
                          />
                        </Form.Item>
                      </div>
                    )}
                  </Col>

                  <Col
                    xs={{ span: 10, offset: 0 }}
                    sm={{ span: 3, offset: 0 }}
                    md={{ span: 3, offset: 0 }}
                    lg={{ span: 3, offset: 0 }}
                  >
                    <Form.Item
                      {...restField}
                      initialValue={20}
                      name={[name, "percentage"]}
                      fieldKey={[fieldKey, "percentage"]}
                      rules={[
                        {
                          required: true,
                          message: this.props.t(
                            "requestAccount.messagesError.input",
                            {
                              key: this.props.t(
                                "requestAccount.step4.percentage"
                              ),
                            }
                          ),
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        size="large"
                        min={20}
                        max={100}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace("%", "")}
                      />
                    </Form.Item>
                  </Col>

                  <Form.Item
                    hidden={true}
                    {...restField}
                    name={[name, "valueTypePersonalHistory"]}
                    fieldKey={[fieldKey, "valueTypePersonalHistory"]}
                    initialValue={1}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    hidden={true}
                    {...restField}
                    name={[name, "typePersonalHistory"]}
                    fieldKey={[fieldKey, "typePersonalHistory"]}
                    initialValue={"link"}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    hidden={true}
                    {...restField}
                    name={[name, "handleLink"]}
                    fieldKey={[fieldKey, "handleLink"]}
                    initialValue={null}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    hidden={true}
                    {...restField}
                    name={[name, "valueLink"]}
                    fieldKey={[fieldKey, "valueLink"]}
                    initialValue={null}
                  >
                    <Input />
                  </Form.Item>
                  <Col
                    xs={{ span: 2, offset: 0 }}
                    sm={{ span: 1, offset: 0 }}
                    md={{ span: 1, offset: 0 }}
                    lg={{ span: 1, offset: 0 }}
                    style={{ justifyContent: "center", display: "flex" }}
                  >
                    {fields.length > 1 && (
                      <MinusCircleOutlined
                        style={{ marginTop: 12 }}
                        onClick={() => remove(name)}
                      />
                    )}
                  </Col>
                </Row>
              ))}
              {fields.length < 5 && (
                <Row
                  gutter={[8, { xs: 8, sm: 16, md: 24, lg: 16 }]}
                  justify="center"
                >
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 8, offset: 8 }}
                    lg={{ span: 8, offset: 8 }}
                    style={{ justifyContent: "center", display: "contents" }}
                  >
                    <Form.Item>
                      <Button
                        style={{ width: "100%" }}
                        type="primary"
                        onClick={() => add()}
                        block
                        size="middle"
                        icon={<PlusOutlined />}
                      >
                        {this.props.t("requestAccount.step4.buttonshareholder")}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Form.List>

        <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 8 }]} justify="end">
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
          >
            <div className="steps-action">
              <Form.Item style={{ textAlign: "right", marginBottom: "2.5rem" }}>
                <Button
                  size="large"
                  className="btn-previous"
                  onClick={() => this.prev()}
                >
                  {this.props.t("requestAccount.buttonPrevious")}
                </Button>
              </Form.Item>

              <Form.Item style={{ textAlign: "right", marginBottom: "2.5rem" }}>
                <Button size="large" htmlType="submit" type="primary">
                  {this.props.t("requestAccount.buttonNext")}
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    );
    let stepFive = (
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        size="small"
        style={{ width: "100%" }}
        onFinish={this.sendLinkProfile.bind(this)}
      >
        <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 8 }]} justify="center">
          <Text
            style={{
              color: COLOR.PRIMARY,
              textAlign: "justify",
            }}
          >
            {this.props.t("requestAccount.step3.subtitle")}
          </Text>
        </Row>
        <br/>
        <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 8 }]} justify="center">
          <Text
            style={{
              color: COLOR.PRIMARY,
              textAlign: "justify",
            }}
          >
            {this.props.t("requestAccount.step4.message")}
          </Text>
        </Row>
        <br />

        {this.state.shareholderArray.length !== 0 && (
          <div>
            {this.state.shareholderArray.map(
              (
                {
                  name,
                  lastname,
                  idAssociate,
                  type,
                  valueTypePersonalHistory,
                  typePersonalHistory,
                },
                i
              ) => (
                <div key={idAssociate}>
                  {type === "CORPORATE_SHAREHOLDER" && (
                    <div>
                      <Row
                        gutter={[8, { xs: 8, sm: 16, md: 24, lg: 8 }]}
                        justify="center"
                        style={{ textAlign: "center" }}
                      ></Row>
                      <Row
                        gutter={[8, { xs: 8, sm: 16, md: 24, lg: 8 }]}
                        justify="start"
                      >
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 12, offset: 6 }}
                          lg={{ span: 12, offset: 6 }}
                        >
                          <Divider orientation="left">
                            <Title
                              style={{
                                color: COLOR.GRAY,
                              }}
                              level={5}
                            >
                              {name}
                            </Title>
                          </Divider>

                          <Form.Item
                            name={"registry" + i}
                            rules={[
                              {
                                required: true,
                                message: this.props.t(
                                  "requestAccount.step3.file"
                                ),
                              },
                            ]}
                            style={{ textAlign: "center" }}
                            valuePropName="fileList"
                            getValueFromEvent={this.normFile.bind(this)}
                          >
                            <Upload
                              beforeUpload={this.beforeUpload.bind(this)}
                              name={"registry" + name}
                              showUploadList={true}
                              maxCount={1}
                              style={{
                                width: "100%",
                              }}
                              onChange={this.uploadDocumentsShareholder.bind(
                                this,
                                "REGISTRY",
                                idAssociate
                              )}
                              accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.jfif,.pjpeg,.pjp"
                            >
                              <Button
                                className="button-file"
                                size="large"
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                  width: "100%",
                                  whiteSpace: "normal",
                                }}
                              >
                                <Image
                                  width={28}
                                  preview={false}
                                  src={registry}
                                />
                                <Text className="text-upload-documents">
                                  {"* " +
                                    this.props.t(
                                      "requestAccount.step3.registry"
                                    )}
                                </Text>
                              </Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {type !== "CORPORATE_SHAREHOLDER" && (
                    <div>
                      <Row
                        gutter={[8, { xs: 8, sm: 16, md: 24, lg: 8 }]}
                        justify="center"
                        style={{ textAlign: "center" }}
                      ></Row>
                      <Row
                        gutter={[8, { xs: 8, sm: 16, md: 24, lg: 8 }]}
                        justify="start"
                      >
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 12, offset: 6 }}
                          lg={{ span: 12, offset: 6 }}
                        >
                          <Divider orientation="left">
                            <Title
                              style={{
                                color: COLOR.GRAY,
                              }}
                              level={5}
                            >
                              {name} {lastname}
                            </Title>
                          </Divider>
                          <Form.Item
                            name={"identityshareholder" + i}
                            rules={[
                              {
                                required: true,
                                message: this.props.t(
                                  "requestAccount.step3.file"
                                ),
                              },
                            ]}
                            style={{ textAlign: "center" }}
                            valuePropName="fileList"
                            getValueFromEvent={this.normFile.bind(this)}
                          >
                            <Upload
                              beforeUpload={this.beforeUpload.bind(this)}
                              name={"id" + name + lastname}
                              showUploadList={true}
                              maxCount={1}
                              style={{
                                width: "100%",
                              }}
                              onChange={this.uploadDocumentsShareholder.bind(
                                this,
                                "IDENTITY",
                                idAssociate
                              )}
                              accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.jfif,.pjpeg,.pjp"
                            >
                              <Button
                                className="button-file"
                                size="large"
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                  width: "100%",
                                  whiteSpace: "normal",
                                }}
                              >
                                <Image width={30} preview={false} src={id} />
                                <Text className="text-upload-documents">
                                  {"* " +
                                    this.props.t(
                                      "requestAccount.step3.identity"
                                    )}
                                </Text>
                              </Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 12, offset: 6 }}
                          lg={{ span: 12, offset: 6 }}
                        >
                          <Form.Item
                            name={"selfieshareholder" + i}
                            rules={[
                              {
                                required: true,
                                message: this.props.t(
                                  "requestAccount.step3.file"
                                ),
                              },
                            ]}
                            style={{ textAlign: "center" }}
                            valuePropName="fileList"
                            getValueFromEvent={this.normFile.bind(this)}
                          >
                            <Upload
                              beforeUpload={this.beforeUpload.bind(this)}
                              name={"selfie" + name + lastname}
                              showUploadList={true}
                              maxCount={1}
                              style={{
                                width: "100%",
                              }}
                              onChange={this.uploadDocumentsShareholder.bind(
                                this,
                                "SELFIE",
                                idAssociate
                              )}
                              accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.jfif,.pjpeg,.pjp"
                            >
                              <Button
                                className="button-file"
                                size="large"
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                  width: "100%",
                                  whiteSpace: "normal",
                                }}
                              >
                                <Image
                                  width={45}
                                  preview={false}
                                  src={selfie}
                                />
                                <Text
                                  className="text-upload-documents"
                                  style={{ textAlign: "left" }}
                                >
                                  {"* " +
                                    this.props.t("requestAccount.step3.selfie")}
                                </Text>
                              </Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 12, offset: 6 }}
                          lg={{ span: 12, offset: 6 }}
                        >
                          <Form.Item
                            name={"addressshareholder" + i}
                            rules={[
                              {
                                required: true,
                                message: this.props.t(
                                  "requestAccount.step3.file"
                                ),
                              },
                            ]}
                            style={{ textAlign: "center" }}
                            valuePropName="fileList"
                            getValueFromEvent={this.normFile.bind(this)}
                          >
                            <Upload
                              name={"address" + name + lastname}
                              showUploadList={true}
                              maxCount={1}
                              style={{
                                width: "100%",
                              }}
                              beforeUpload={this.beforeUpload.bind(this)}
                              onChange={this.uploadDocumentsShareholder.bind(
                                this,
                                "RESIDENTIAL_ADDRESS",
                                idAssociate
                              )}
                              accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.jfif,.pjpeg,.pjp"
                            >
                              <Button
                                className="button-file"
                                size="large"
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                  width: "100%",
                                  whiteSpace: "normal",
                                }}
                              >
                                <Image
                                  width={30}
                                  preview={false}
                                  src={address}
                                />
                                <Text className="text-upload-documents">
                                  {"* " +
                                    this.props.t(
                                      "requestAccount.step3.address"
                                    )}
                                </Text>
                              </Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 12, offset: 6 }}
                          lg={{ span: 12, offset: 6 }}
                        >
                          <Form.Item
                            style={{ textAlign: "center" }}
                            label={
                              "* " +
                              this.props.t(
                                "requestAccount.step3.personalHistory"
                              )
                            }
                          >
                            <Radio.Group
                              onChange={this.onChangeHistoryShareholder.bind(
                                this,
                                i
                              )}
                              value={valueTypePersonalHistory}
                              style={{ marginBottom: 10 }}
                            >
                              <Radio value={1}>
                                {this.props.t("requestAccount.step3.link")}
                              </Radio>
                              <Radio value={2}>
                                {this.props.t(
                                  "requestAccount.step3.curriculum"
                                )}
                              </Radio>
                            </Radio.Group>
                            <br />
                            {typePersonalHistory === "file" && (
                              <Form.Item
                                name={"curriculumShareholder" + i}
                                rules={[
                                  {
                                    required: true,
                                    message: this.props.t(
                                      "requestAccount.messagesError.input",
                                      {
                                        key: this.props.t(
                                          "requestAccount.step3.curriculum"
                                        ),
                                      }
                                    ),
                                  },
                                ]}
                                style={{ textAlign: "center" }}
                                valuePropName="fileList"
                                getValueFromEvent={this.normFile.bind(this)}
                              >
                                <Upload
                                  name={"cv" + name + lastname}
                                  showUploadList={true}
                                  maxCount={1}
                                  style={{
                                    width: "100%",
                                  }}
                                  beforeUpload={this.beforeUpload.bind(this)}
                                  onChange={this.uploadDocumentsShareholder.bind(
                                    this,
                                    "PERSONAL_HISTORY",
                                    idAssociate
                                  )}
                                  accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.jfif,.pjpeg,.pjp"
                                >
                                  <Button
                                    className="button-file"
                                    size="large"
                                    style={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                      width: "100%",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                    <Image
                                      width={26}
                                      preview={false}
                                      src={personal}
                                    />
                                    <Text className="text-upload-documents">
                                      {"* " +
                                        this.props.t(
                                          "requestAccount.step3.curriculum"
                                        )}
                                    </Text>
                                  </Button>
                                </Upload>
                              </Form.Item>
                            )}
                            {typePersonalHistory === "link" && (
                              <div>
                                <Form.Item
                                  //valuePropName
                                  name={"linkedin_" + idAssociate}
                                  rules={[
                                    {
                                      required: true,
                                      message: this.props.t(
                                        "requestAccount.messagesError.input",
                                        {
                                          key: this.props.t(
                                            "requestAccount.step3.link"
                                          ),
                                        }
                                      ),
                                    },
                                  ]}
                                  style={{ textAlign: "center" }}
                                >
                                  <Input
                                    size="large"
                                    placeholder={
                                      "* " +
                                      this.props.t("requestAccount.step3.link")
                                    }
                                    onChange={this.onChangeLinkHistory.bind(
                                      this,
                                      i
                                    )}
                                  />
                                </Form.Item>
                              </div>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        )}
        <Row gutter={[32, { xs: 8, sm: 16, md: 24, lg: 8 }]} justify="end">
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
          >
            <div className="steps-action">
              <Form.Item style={{ textAlign: "right", marginBottom: "1rem" }}>
                <Button
                  size="large"
                  className="btn-previous"
                  onClick={() => this.prev()}
                >
                  {this.props.t("requestAccount.buttonPrevious")}
                </Button>
              </Form.Item>

              <Form.Item style={{ textAlign: "right", marginBottom: "1rem" }}>
                <Button
                  htmlType="submit"
                  size="large"
                  type="primary"
                  loading={this.state.loadingDocument}
                >
                  {this.props.t("requestAccount.buttonDone")}
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    );
    let stepFinish = (
      <Result
        icon={<CheckCircleOutlined style={{ color: COLOR.SECONDARY }} />}
        status="success"
        title={this.props.t("requestAccount.done.messageSuccess")}
        subTitle={this.props.t("requestAccount.done.messageNotice")}
        extra={[
          <Link to="/signin">
            <Button
              key="signin"
              size="large"
              type="primary"
              onClick={() => {
                this.props.siderNavigate("signin");
              }}
            >
              {this.props.t("requestAccount.buttonFinish")}
            </Button>
          </Link>,
        ]}
      />
    );
    const steps = [
      {
        content: stepTwo,
      },
      {
        content: stepThree,
      },
      {
        content: stepFinish,
      },
    ];
    const stepsBusiness = [
      {
        content: stepTwo,
      },
      {
        content: stepThree,
      },
      {
        content: stepFour,
      },
      {
        content: stepFive,
      },
      {
        content: stepFinish,
      },
    ];
    return (
      <div>
        <Row
          gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
          style={{ marginTop: 40, marginBottom: 40 }}
        >
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: 12, offset: 6 }}
            xl={{ span: 12, offset: 6 }}
          >
            <Space direction="vertical" className="container-open-account">
              {this.state.current !== steps.length - 1 &&
                this.state.current !== stepsBusiness.length - 1 && (
                  <Title
                    level={4}
                    style={{
                      color: COLOR.PRIMARY,
                      textAlign: "center",
                      fontWeight: "bold",
                      marginTop: 20,
                    }}
                  >
                    {this.props.t("requestAccount.title")}
                  </Title>
                )}

              {this.state.typeUser === "" && (
                <div className="foo fade-in">{stepFirst}</div>
              )}
              <Spin
                spinning={this.state.loading}
                size="large"
                indicator={<LoadingOutlined style={{ color: "#00ff6a" }} />}
              >
                {this.state.typeUser === "DAMPAY_INDIVIDUAL" &&
                  steps.map(({ content }, i) => (
                    <div
                      className={
                        i === this.state.current ? "foo fade-in" : "foo"
                      }
                      key={i}
                    >
                      {content}
                    </div>
                  ))}
                {this.state.typeUser === "DAMPAY_BUSINESS" &&
                  stepsBusiness.map(({ content }, i) => (
                    <div
                      className={
                        i === this.state.current ? "foo fade-in" : "foo"
                      }
                      key={i}
                    >
                      {content}
                    </div>
                  ))}
              </Spin>
            </Space>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    request: state.requestAccount,
    result: state.siderNavigate,
    signinResult: state.authUser,
    store: state,
    document: state.uploadDocuments,
    commit: state.sendRequest,
    shareholder: state.createShareholder,
  };
};

const mapDispatchToProps = {
  authUser,
  siderNavigate,
  requestAccount,
  uploadDocuments,
  sendRequest,
  createShareholder,
  resetStore,
};
const OpenAccount = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(RequestAccount)
);
export default OpenAccount;
