import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Button, Typography, Space } from "antd";
const { Title } = Typography;

class ModalSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetSelected: "",
    };
  }
  render() {
    return (
      <Modal
        closable={false}
        footer={null}
        width={400}
        bodyStyle={{
          margin: 0,
          padding: "20px",
          background: "#00001e",
          color: "#fff",
          width: "100%",
          textAlign: "center",
          top: 80,
        }}
        open={this.props.showModal}
        maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
      >
        <Space
          direction="vertical"
          align="center"
          size="large"
          style={{ justifyContent: "center" }}
        >
            <Title level={5} style={{ color: "#fff" }}>
            {" "}
            {this.props.t("session.title")}
          </Title>
          <Button size="large" type="primary" onClick={()=>this.props.continueSession()}> {this.props.t("session.continue")}</Button>
        </Space>
      </Modal>
    );
  }
}
const ModalSessionTranslated = withTranslation()(ModalSession);

export default ModalSessionTranslated;
