import {
  GET_TRANSACTIONS_PENDINGS,
  SET_LAST_ID_PENDING_TX,
} from "../actions/approvalsActions";
const initialState = {
  resultTransactionsPendings: [],
  current_last_id: "",
};

export function approvals(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSACTIONS_PENDINGS:
      return Object.assign({}, state, {
        resultTransactionsPendings: action.payload,
      });
    case SET_LAST_ID_PENDING_TX:
      return Object.assign({}, state, {
        current_last_id: action.payload,
      });

    default:
      return state;
  }
}
