import {
  GET_TRANSACTIONS,
  GET_ALL_TRANSACTIONS,
  GET_ALL_TRANSACTIONS_FILTER,
  CURRENT_FILTERS,
  GET_TRANSACTIONS_EXCHANGE_ORDERS,
  CANCEL_TRANSACTION_ACTION,
} from "../actions/transactionsActions";
const initialState = {
  resultTransactions: "",
  allResultTx: "",
  resultCurrency: "",
  currentFilter: "",
  transactionsAndOrders: "",
  resultCancelTransactions: null,
};

export function transactions(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSACTIONS:
      return Object.assign({}, state, { resultTransactions: action.payload });
    case GET_ALL_TRANSACTIONS:
      return Object.assign({}, state, { allResultTx: action.payload });
    case GET_ALL_TRANSACTIONS_FILTER:
      return Object.assign({}, state, { resultCurrency: action.payload });
    case CURRENT_FILTERS:
      return Object.assign({}, state, { currentFilter: action.payload });
    case GET_TRANSACTIONS_EXCHANGE_ORDERS:
      return Object.assign({}, state, {
        transactionsAndOrders: action.payload,
      });
    case CANCEL_TRANSACTION_ACTION:
      return Object.assign({}, state, {
        resultCancelTransactions: action.payload,
      });
    default:
      return state;
  }
}
