import React, { Component } from "react";
import "./audit.css";
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  Table,
  Space,
  DatePicker,
  Skeleton,
  Select,
  message,
  Divider,
} from "antd";
import COLOR from "../../const/colors";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { currentToken } from "../../redux/actions/tokenActions";
import { getAuditLogs } from "../../redux/actions/auditActions";
import { currentFilters } from "../../redux/actions/transactionsActions";
import { FileExcelOutlined } from "@ant-design/icons";
import { getUsersChildren } from "../../redux/actions/usersChildrenActions";
import { CSVLink } from "react-csv";
import moment from "moment";
const { Text } = Typography;
const { Option } = Select;
class Audit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: "1",
      loading: false,
      typeList: [
        {
          key: "TRADE",
          value: "TRADE",
          text: this.props.t("audit.types.TRADE"),
        },
        {
          key: "WITHDRAWAL",
          value: "WITHDRAWAL",
          text: this.props.t("audit.types.WITHDRAWAL"),
        },
        {
          key: "TRANSFER_OUT",
          value: "TRANSFER_OUT",
          text: this.props.t("audit.types.TRANSFER_OUT"),
        },
        {
          key: "LOGIN",
          value: "LOGIN",
          text: this.props.t("audit.types.LOGIN"),
        },
        {
          key: "USER_MANAGEMENT",
          value: "USER_MANAGEMENT",
          text: this.props.t("audit.types.USER_MANAGEMENT"),
        },
        { key: "ALL", value: "ALL", text: this.props.t("audit.types.ALL") },
      ],
      auditLogsList: [],
      loadingFilter: false,
      lastId: null,
      lastIdCsv: null,
      previousId: null,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      modalManageUser: false,
      typeFilter: null,
      userFilter: null,
      minDateFilter: null,
      maxDateFilter: null,
      dateStartFilter: "",
      dateEndFilter: "",
      loadingCsv: false,
      transactionsToPrint: [],
      loadingUsers: false,
      lastIdUsers: null,
      previousIdUsers: null,
      usersList: [],
      currentSizeUsers: 0,
    };
  }
  componentDidMount() {
    let role = this.props.dataUser.userData.data?.data?.user?.role;
    this.getAuditLogs();
    let filters = {
      user: null,
      type: null,
      minDate: null,
      maxDateFilter: null,
    };
    this.props.currentFilters(filters);
    if (role === "MASTER" || role === "ADMIN") {
      this.getUsersChildrenList();
    }
  }
  async getUsersChildrenList() {
    try {
      let lastID = null;
      let array = [];
      let role = this.props.dataUser.userData.data?.data?.user?.role;
      let currentUser = {
        email: this.props.dataUser.userData.data?.data?.user?.email,
        _id: "0001",
      };
      if (this.state.lastIdUsers === null) {
        lastID = undefined;
      } else {
        lastID = this.state.lastIdUsers;
      }
      this.setState({ loadingUsers: true });
      await this.props.getUsersChildren(
        this.props.clientId,
        this.props.accessToken,
        lastID
      );
      let response = this.props.usersChildrenList;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        let data = response.data.data;
        this.setState({
          lastIdUsers:
            data.current_last_id !== null ? data.current_last_id : null,
          previousIdUsers: data.previous_id !== null ? data.previous_id : null,
          currentSizeUsers:
            this.state.currentSizeUsers + data.current_page_size,
        });

        if (lastID !== undefined) {
          array = [...this.state.usersList, ...data.users_children];
        } else {
          if (role === "MASTER") {
            array.push(currentUser);
          }
          array = [...array, ...data.users_children];
        }
        let finalList = this.sortUserList(array);
        finalList.push({ _id: "ALL", email: this.props.t("audit.types.ALL") });
        this.setState({
          usersList: finalList,
          loadingUsers: false,
        });
      } else if (response.status === 400) {
        this.setState({ loadingUsers: false });
        if (response.data.error_details.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;
          message.error(
            this.props.t("usersChildren.error." + messageError),
            10
          );
        } else if (response.data.error_details.payload_error !== undefined) {
          let messageError = response.data.error_details.payload_error;
          message.error(
            this.props.t("usersChildren.error." + messageError),
            10
          );
        } else {
          message.error(response.data.message);
        }
      } else if (response.status === 403) {
        message.error(this.props.t("usersChildren.error.accessError"), 10);
        this.setState({ loadingUsers: false });
      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404 ||
        response.status === 422 ||
        response.status === 500
      ) {
        this.setState({ loadingUsers: false });
        if (response.data.error_details?.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;
          message.error(
            this.props.t("usersChildren.error." + messageError),
            10
          );
        } else {
          message.error(this.props.t("usersChildren.error.errorServer"), 10);
        }
      }
    } catch (error) {
      this.setState({ loadingUsers: false });
      console.log(error);
    }
  }
  async getAuditLogs() {
    try {
      this.setState({ loading: true });
      let type = this.state.typeFilter === "ALL" ? null : this.state.typeFilter;
      let user =
        this.state.userFilter !== null && this.state.userFilter.includes("@")
          ? this.state.userFilter
          : null;
      let lastID = this.state.lastId === null ? undefined : this.state.lastId;

      await this.props.getAuditLogs(
        this.props.clientId,
        this.props.accessToken,
        lastID,
        type,
        user,
        this.state.minDateFilter,
        this.state.maxDateFilter
      );

      let resp = this.props.auditLogs;
      if (resp.headers !== undefined) {
        let newAccessToken = resp.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      let dataLogs = resp.data.data;
      if (resp.status === 200) {
        this.setState({
          lastId:
            dataLogs.current_last_id !== null ? dataLogs.current_last_id : null,
          previousId:
            dataLogs.previous_id !== null ? dataLogs.previous_id : null,
        });
        this.formatLogs(dataLogs.audit_logs);
      } else if (resp.status === 400) {
        this.setState({ loading: false, loadingFilter: false });
        if (resp.data.error_details.validation_error !== undefined) {
          let messageError = resp.data.error_details.validation_error;

          message.error(this.props.t("audit.error." + messageError), 10);
        } else if (resp.data.error_details.payload_error !== undefined) {
          let messageError = resp.data.error_details.payload_error;
          message.error(this.props.t("audit.error." + messageError), 10);
        } else {
          message.error(resp.data.message);
        }
      } else if (
        resp.status === 401 ||
        resp.status === 404 ||
        resp.status === 422
      ) {
        message.error(this.props.t("audit.error.queryError"));
        this.setState({ loading: false, loadingFilter: false });
      } else if (resp.status === 403) {
        message.error(this.props.t("audit.error.accessError"));
        this.setState({ loading: false, loadingFilter: false });
      } else if (resp.status === 500) {
        message.error(this.props.t("audit.error.errorServer"));
        this.setState({ loading: false, loadingFilter: false });
      } else {
        message.error(this.props.t("audit.error.errorServer"));
        this.setState({ loading: false, loadingFilter: false });
      }
    } catch (error) {
      console.log(error);
    }
  }
  formatLogs(data) {
    let array = [];
    for (let log of data) {
      let objLogs = {};
      Object.entries(log).forEach(([key, value]) => {
        objLogs[key] = value;
        if (key === "event_date") {
          objLogs.date = this.formatDateView(value);
        }
      });
      array.push(objLogs);
    }
    let page = {
      current: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      total: this.state.auditLogsList.length + array.length,
    };
    this.setState({
      auditLogsList: [...this.state.auditLogsList, ...array],
      pagination: page,
      loading: false,
      loadingFilter: false,
      typeFilter: null,
      userFilter: null,
      minDateFilter: null,
      maxDateFilter: null,
      dateStartFilter: "",
      dateEndFilter: "",
    });
  }
  formatDateView(dateLog) {
    let dateMoment = moment(dateLog).format("YYYY/MM/DD HH:mm:ss");
    let date = new Date(dateMoment + " UTC");
    let dateEvent =
      moment(date).format("DD/MM/YYYY") + " " + this.formatAmPm(date);
    return dateEvent;
  }
  formatAmPm(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  handleTableChange = (pagination) => {
    let newPagination = {};
    if (this.state.pagination.current < pagination.current) {
      let filterSaved = this.props.filters;
      this.setState({
        userFilter: filterSaved.user,
        typeFilter: filterSaved.type,
        minDateFilter: filterSaved.minDate,
        maxDateFilter: filterSaved.maxDateFilter,
      });
      newPagination = {
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total + 1,
      };
      if (pagination.current % 2 !== 0) {
        this.getAuditLogs();
      }
    } else {
      newPagination = {
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
      };
    }
    this.setState({ pagination: newPagination });
  };
  handleDateStart = (date) => {
    let minDate = date.format("YYYY-MM-DD");
    this.setState({
      dateStartFilter: date,
      minDateFilter: minDate,
    });
    if (this.state.maxDateFilter !== "" && this.state.maxDateFilter !== null) {
      if (date !== null) {
        if (minDate > this.state.maxDateFilter) {
          message.error(this.props.t("audit.error.dateError"));
          this.setState({ dateStartFilter: "", minDateFilter: null });
        }
      }
    }
  };
  handleDateEnd = (date) => {
    let maxDate = date.format("YYYY-MM-DD");
    this.setState({
      dateEndFilter: date,
      maxDateFilter: maxDate,
    });
    if (this.state.minDateFilter !== "" && this.state.minDateFilter !== null) {
      if (date !== null) {
        if (this.state.minDateFilter > maxDate) {
          message.error(this.props.t("audit.error.dateError"));
          this.setState({ dateEndFilter: "", maxDateFilter: null });
        }
      }
    }
  };
  async filterData() {
    let page = {
      current: 1,
      pageSize: 10,
      total: 0,
    };
    await this.setState({
      auditLogsList: [],
      loadingFilter: true,
      lastId: null,
      pagination: page,
    });
    let filters = {
      user: this.state.userFilter,
      type: this.state.typeFilter,
      minDate: this.state.minDateFilter,
      maxDateFilter: this.state.maxDateFilter,
    };
    this.props.currentFilters(filters);
    await this.getAuditLogs();
  }
  async generateCsvDocument() {
    this.setState({ loadingCsv: true, transactionsToPrint: [] });
    let emptyData = false;
    let filterSaved = this.props.filters;
    let userFilter = filterSaved.user;
    let typeFilter = filterSaved.type;
    let minDateFilter = filterSaved.minDate;
    let maxDateFilter = filterSaved.maxDateFilter;
    do {
      try {
        let user = userFilter?.includes("@") ? userFilter : null;
        let type = typeFilter === "ALL" ? null : typeFilter;
        let lastID =
          this.state.lastIdCsv === null ? undefined : this.state.lastIdCsv;
        await this.props.getAuditLogs(
          this.props.clientId,
          this.props.accessToken,
          lastID,
          type,
          user,
          minDateFilter,
          maxDateFilter
        );

        let response = this.props.auditLogs;
        if (response.headers !== undefined) {
          let newAccessToken = response.headers["x-new-access-token"];
          await this.props.currentToken(newAccessToken);
        }
        if (response.status === 200) {
          let info = response.data.data;
          this.setState({
            lastIdCsv:
              info.current_last_id !== null ? info.current_last_id : null,
          });
          let array = [];
          for (let log of info.audit_logs) {
            let objLogs = {};
            Object.entries(log).forEach(([key, value]) => {
              objLogs[key] = value;
              if (key === "event_date") {
                objLogs.date = this.formatDateView(value);
              }
              if (key === "type") {
                objLogs.type = this.props.t("audit.types." + value);
              }
            });
            array.push(objLogs);
          }

          if (info.audit_logs.length > 0) {
            emptyData = false;
          } else {
            this.csvLink.link.click();
            this.setState({ loadingCsv: false });
            emptyData = true;
          }
          this.setState({
            transactionsToPrint: [...this.state.transactionsToPrint, ...array],
          });
        } else if (response.status === 400) {
          this.setState({ loadingCsv: false });
          emptyData = true;
          if (response.data.error_details.validation_error !== undefined) {
            let messageError = response.data.error_details.validation_error;

            message.error(this.props.t("audit.error." + messageError), 10);
          } else if (response.data.error_details.payload_error !== undefined) {
            let messageError = response.data.error_details.payload_error;
            message.error(this.props.t("audit.error." + messageError), 10);
          } else {
            message.error(response.data.message);
          }
        } else if (
          response.status === 401 ||
          response.status === 403 ||
          response.status === 404 ||
          response.status === 422
        ) {
          message.error(this.props.t("audit.error.queryError"));
          this.setState({ loadingCsv: false });
          emptyData = true;
        } else if (response.status === 500) {
          message.error(this.props.t("audit.error.errorServer"));
          this.setState({ loadingCsv: false });
          emptyData = true;
        } else {
          message.error(this.props.t("audit.error.errorServer"));
          this.setState({ loadingCsv: false });
          emptyData = true;
        }
      } catch (error) {
        console.log(error);
        emptyData = true;
        this.setState({ loadingCsv: false });
      }
    } while (!emptyData);
  }
  sortUserList(array) {
    return array.sort((a, b) => a.email.localeCompare(b.email));
  }
  render() {
    let role = this.props.dataUser.userData.data?.data?.user?.role;
    let dateDownload = new Date()
      .toLocaleString()
      .replaceAll("/", "-")
      .replace(" ", "T");
    const columnsLogs = [
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("audit.date")}
          </Text>
        ),
        dataIndex: "date",
        key: "date",
        width: 200,
        render: (date) => (
          <>
            <Text
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {date.split(" ")[0]}
            </Text>
            <br />
            <Text
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {date.split(" ")[1] + " " + date.split(" ")[2]}
            </Text>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("audit.user")}
          </Text>
        ),
        dataIndex: "user_email",
        key: "user_email",
        width: 300,
        render: (user_email) => (
          <>
            <Text
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {user_email}
            </Text>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("audit.type")}
          </Text>
        ),
        dataIndex: "type",
        key: "type",
        width: 150,
        render: (type) => (
          <>
            <Text
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {this.props.t("audit.types." + type)}
            </Text>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("audit.description")}
          </Text>
        ),
        dataIndex: "description",
        key: "description",
        render: (description) => (
          <>
            <Text
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {description}
            </Text>
          </>
        ),
      },
    ];
    const headers = [
      { label: this.props.t("audit.date"), key: "date" },
      { label: this.props.t("audit.user"), key: "user_email" },
      { label: this.props.t("audit.type"), key: "type" },
      { label: this.props.t("audit.description"), key: "description" },
    ];
    return (
      <>
        <Row
          gutter={[16, { xs: 8, sm: 16, md: 8, lg: 8, xl: 24 }]}
          style={{ marginBottom: 10 }}
        >
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: 22, offset: 1 }}
            xl={{ span: 22, offset: 1 }}
          >
            <Card
              bordered={false}
              size="default"
              style={{
                width: "100%",
                borderRadius: "5px",
                background: COLOR.PRIMARY,
                color: COLOR.SECONDARY_TEXT,
              }}
              bodyStyle={{ padding: "10px 0px 10px 0px" }}
            >
              <Row
                gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
                style={{ padding: "15px 15px" }}
              >
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 5, offset: 0 }}
                  xl={{ span: 5, offset: 0 }}
                  xxl={{ span: 5, offset: 0 }}
                  style={{
                    textAlign: "left",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <DatePicker
                    placeholder={this.props.t("audit.startDate")}
                    size="large"
                    format="YYYY-MM-DD"
                    style={{ width: "100%" }}
                    autoFocus={false}
                    showTime={false}
                    value={this.state.dateStartFilter}
                    onChange={(date) => this.handleDateStart(date)}
                  />
                </Col>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 5, offset: 0 }}
                  xl={{ span: 5, offset: 0 }}
                  xxl={{ span: 5, offset: 0 }}
                  style={{
                    textAlign: "left",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <DatePicker
                    placeholder={this.props.t("audit.endDate")}
                    size="large"
                    format="YYYY-MM-DD"
                    style={{ width: "100%" }}
                    autoFocus={false}
                    showTime={false}
                    value={this.state.dateEndFilter}
                    onChange={(date) => this.handleDateEnd(date)}
                  />
                </Col>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 5, offset: 0 }}
                  xl={{ span: 5, offset: 0 }}
                  xxl={{ span: 5, offset: 0 }}
                  style={{
                    textAlign: "left",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div
                    id="select-trade"
                    className="ant-select-selector ant-select"
                    style={{ width: "100%" }}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder={this.props.t("audit.type")}
                      value={this.state.typeFilter}
                      onChange={(value) => this.setState({ typeFilter: value })}
                    >
                      {this.state.typeList.map((item, i) => {
                        return (
                          <Option value={item.value} key={i}>
                            {item.text}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Col>

                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 5, offset: 0 }}
                  xl={{ span: 5, offset: 0 }}
                  xxl={{ span: 5, offset: 0 }}
                  style={{
                    textAlign: "left",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {(role === "MASTER" || role === "ADMIN") && (
                    <div
                      id="select-trade"
                      className="ant-select-selector ant-select"
                      style={{ width: "100%" }}
                    >
                      <Select
                        style={{ width: "100%" }}
                        size="large"
                        showSearch
                        value={this.state.userFilter}
                        filterOption={(input, option) => {
                          return (
                            option.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                        loading={this.state.loadingUsers}
                        placeholder={this.props.t("audit.user") + "s"}
                        onChange={(value) => {
                          this.setState({ userFilter: value });
                        }}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            {this.state.currentSizeUsers >= 20 && (
                              <>
                                <Divider style={{ margin: "8px 0" }} />
                                <Space style={{ padding: "0 8px 4px" }}>
                                  <Button
                                    type="link"
                                    onClick={() => this.getUsersChildrenList()}
                                  >
                                    See more
                                  </Button>
                                </Space>
                              </>
                            )}
                          </>
                        )}
                      >
                        {this.state.usersList.map((item, i) => {
                          return (
                            <Option key={item._id} value={item.email}>
                              {item.email}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  )}
                </Col>

                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 4, offset: 0 }}
                  xl={{ span: 4, offset: 0 }}
                  xxl={{ span: 4, offset: 0 }}
                  style={{
                    textAlign: "left",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    type="primary"
                    size="large"
                    style={{ width: "100%" }}
                    loading={this.state.loadingFilter}
                    disabled={
                      this.state.userFilter === null &&
                      this.state.typeFilter === null &&
                      (this.state.minDateFilter === null ||
                        this.state.maxDateFilter === null)
                    }
                    onClick={() => this.filterData()}
                  >
                    {this.props.t("audit.filter")}
                  </Button>
                </Col>
              </Row>

              {(this.state.loading || this.state.loadingFilter) && (
                <Skeleton active />
              )}
              {!this.state.loading && !this.state.loadingFilter && (
                <>
                  <div
                    id="table-history"
                    className="ant-table ant-table-tbody ant-table-thead text-table-cell ant-table-cell"
                    style={{ marginTop: 10 }}
                  >
                    <Table
                      rowKey={"_id"}
                      locale={{
                        emptyText: (
                          <Space direction="vertical">
                            <br />
                            <Text
                              style={{
                                color: COLOR.PRIMARY_TEXT,
                                fontSize: 16,
                                fontWeight: "bold",
                              }}
                            >
                              {this.props.t("audit.msgEmpty")}
                            </Text>
                            <Text
                              style={{
                                color: COLOR.PRIMARY_TEXT,
                                fontSize: 16,
                                fontWeight: "normal",
                              }}
                            >
                              {this.props.t("audit.msgEmpty1")}
                            </Text>
                            <br />
                          </Space>
                        ),
                      }}
                      loading={this.state.loading}
                      columns={columnsLogs}
                      dataSource={this.state.auditLogsList}
                      pagination={this.state.pagination}
                      onChange={this.handleTableChange}
                      footer={() => (
                        <Space direction="horizontal">
                          <Button
                            type="primary"
                            size="large"
                            loading={this.state.loadingCsv}
                            onClick={() => this.generateCsvDocument()}
                          >
                            {this.props.t("transactions.history.csv")}
                            <FileExcelOutlined style={{ fontSize: 20 }} />
                          </Button>
                          <CSVLink
                            data={this.state.transactionsToPrint}
                            headers={headers}
                            filename={"Audit_logs_" + dateDownload + ".csv"}
                            ref={(r) => (this.csvLink = r)}
                          ></CSVLink>
                        </Space>
                      )}
                    />
                  </div>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser,
    accessToken: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
    auditLogs: state.audit.respAuditLogs,
    filters: state.transactions.currentFilter,
    usersChildrenList: state.userChildren.resultUsers,
  };
};

const mapDispatchToProps = {
  currentToken,
  getAuditLogs,
  currentFilters,
  getUsersChildren,
};

const AuditTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Audit)
);

export default AuditTranslated;
