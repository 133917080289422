import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;
const beneficiaries = {
  async getBeneficiaries(idToken, accessToken, currency, network) {
    let url = BASE_URL + config.beneficiaries + "?currency_code=" + currency;
    if (network) {
      url = url + "&network=" + network;
    }
    const resp = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },

  async addBeneficiary(idToken, accessToken, body) {
    const resp = await axios.post(BASE_URL + config.beneficiaries, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },

  async deleteBeneficiary(idToken, accessToken, idBeneficiary) {
    const resp = await axios.delete(
      BASE_URL + config.beneficiaries + "/" + idBeneficiary,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CLIENT-ID": idToken,
        },
      }
    );
    return resp;
  },
  async editBeneficiary(idToken, accessToken, idBeneficiary, body) {
    const resp = await axios.patch(
      BASE_URL + config.beneficiaries + "/" + idBeneficiary,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CLIENT-ID": idToken,
        },
      }
    );
    return resp;
  },
};
export default beneficiaries;
