import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
  pdf
} from "@react-pdf/renderer";
import moment from 'moment';
import source from "../../fonts/sofia-pro/Sofia Pro Light Az.otf";
import source1 from "../../fonts/sofia-pro/Sofia Pro Bold Az.otf";
import { asset } from "../../common/assets";

Font.register({ family: "sofia-pro", src: source });
Font.register({ family: "sofia-pro-bold", src: source1 });

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    padding: 40,
  },
  logoContainer: {
    alignItems: 'center',
    marginBottom: 40,
  },
  logo: {
    width: 200,
    height: 40,
  },
  topSection: {
    marginBottom: 20,
    position: 'relative',
    minHeight: 100,
  },
  accountInfo: {
    marginBottom: 15,
  },
  infoSection: {
    marginBottom: 10,
  },
  label: {
    fontSize: 10,
    fontFamily: "sofia-pro-bold",
    marginBottom: 2,
  },
  value: {
    fontSize: 10,
    fontFamily: "sofia-pro",
    marginBottom: 2,
    lineHeight: 1.2,
  },
  companyInfo: {
    position: 'absolute',
    right: 0,
    top: '50%', 
    transform: 'translateY(-50%)',
    textAlign: 'right',
    fontSize: 10,
    lineHeight: 1.2,
  },
  companyAddress: {
    fontFamily: "sofia-pro",
    marginBottom: 1,
  },
  table: {
    display: "table",
    width: "100%",
    marginBottom: 20,
  },
  tableHeader: {
    flexDirection: "row",
    marginBottom: 5,
  },
  tableRow: {
    flexDirection: "row",
  },
  dateCol: {
    width: "15%",
  },
  // interestDateCol: {
  //   width: "15%",
  // },
  counterpartyCol: {
    width: "35%",
		paddingRight: 8
  },
  descriptionCol: {
    width: "35%",
		paddingRight: 8
  },
  amountCol: {
    width: "15%",
    textAlign: 'right',
  },
  tableHeaderCell: {
    fontSize: 10,
    fontFamily: "sofia-pro",
    color: '#666',
  },
  tableCell: {
    fontSize: 10,
    fontFamily: "sofia-pro",
  },
	footer: {
		marginTop: 100,
		borderTopWidth: 1,
		borderTopColor: "#E0E0E0",
		paddingTop: 10,
  },
  downloadDate: {
    fontSize: 10,
    fontFamily: "sofia-pro",
  },
});

const companyInfo = {
  GIB: {
    name: "Digital Asset Management Ltd",
    address: [
      "Suite 2.4 & 2.5",
      "Waterport Place",
      "Gibraltar, P.O Box 48",
      "Gx11 1AA"
    ]
  },
  UAB: {
    name: "Damex.io UAB",
    address: [
      "Žygimanto Liauksmino g. 3",
      "Vilnius",
      "Lithuania",
      "LT-01101"
    ]
  }
};

export const WithdrawalPDFDocument = ({ transaction, user }) => {
  const userType = user.profile.entity;
  const company = companyInfo[userType];
  const downloadDate = moment().format('YYYY-MM-DD');
    const currencyAsset = asset.assets.find(
    (element) => element.value === transaction.account.currency
  );
  const currencySymbol = currencyAsset?.symbol || transaction.account.currency;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.logoContainer}>
          <Image style={styles.logo} src="/logo-text.png" />
        </View>

        <View style={styles.topSection}>
          {/* Account Holder */}
          <View style={styles.infoSection}>
            <Text style={styles.label}>Account holder</Text>
            <Text style={styles.value}>
              {user.business_name ?? user.profile.name}
            </Text>
          </View>

          {/* Account Details */}
          <View style={styles.infoSection}>
            <Text style={styles.label}>Account details</Text>
            {transaction.sourceAccount?.iban ? (
              <>
                <Text style={styles.value}>
                  IBAN: {transaction.sourceAccount.iban}
                </Text>
                {transaction.sourceAccount.bic && (
                  <Text style={styles.value}>
                    BIC: {transaction.sourceAccount.bic}
                  </Text>
                )}
              </>
            ) : (
              <>
                {transaction.sourceAccount?.account_number && (
                  <Text style={styles.value}>
                    Account Number: {transaction.sourceAccount.account_number}
                  </Text>
                )}
                {transaction.sourceAccount?.sort_code && (
                  <Text style={styles.value}>
                    Sort Code: {transaction.sourceAccount.sort_code}
                  </Text>
                )}
              </>
            )}
          </View>

          {/* Bank Info*/}
          {/* <View style={styles.infoSection}>
            <Text style={styles.label}>Bank information</Text>
            <Text style={styles.value}>bunq B.V.</Text>
            <Text style={styles.value}>Chamber of Commerce: 54992060</Text>
            <Text style={styles.value}>Naritaweg 131-133</Text>
            <Text style={styles.value}>1043BS Amsterdam</Text>
            <Text style={styles.value}>Netherlands</Text>
          </View> */}

          {/* Company Info */}
          <View style={styles.companyInfo}>
            <Text style={{ fontFamily: "sofia-pro-bold" }}>{company.name}</Text>
            {company.address.map((line, index) => (
              <Text key={index} style={styles.companyAddress}>
                {line}
              </Text>
            ))}
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.dateCol}>
              <Text style={styles.tableHeaderCell}>Date</Text>
            </View>
            {/* <View style={styles.interestDateCol}>
              <Text style={styles.tableHeaderCell}>Interest Date</Text>
            </View> */}
            <View style={styles.counterpartyCol}>
              <Text style={styles.tableHeaderCell}>Counterparty</Text>
            </View>
            <View style={styles.descriptionCol}>
              <Text style={styles.tableHeaderCell}>Description</Text>
            </View>
            <View style={styles.amountCol}>
              <Text style={styles.tableHeaderCell}>Amount</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            {/* Transaction date */}
            <View style={styles.dateCol}>
              <Text style={styles.tableCell}>
                {moment(transaction.created_date).format("YYYY-MM-DD")}
              </Text>
            </View>
            {/* Interest date*/}
            {/* <View style={styles.interestDateCol}>
              <Text style={styles.tableCell}>
                {moment(transaction.created_date).format('YYYY-MM-DD')}
              </Text>
            </View> */}
            {/* Counterparty */}
            <View style={styles.counterpartyCol}>
              <Text style={styles.tableCell}>
                {transaction.destination.account_holder}
                {transaction.destination.iban ? (
                  <>
                    {transaction.destination.iban &&
                      `\nIBAN: ${transaction.destination.iban}`}
                    {transaction.destination.bic &&
                      `\nBIC: ${transaction.destination.bic}`}
                  </>
                ) : (
                  <>
                    {transaction.destination.account_number &&
                      `\nAccount Number: ${transaction.destination.account_number}`}
                    {transaction.destination.sort_code &&
                      `\nSort Code: ${transaction.destination.sort_code}`}
                  </>
                )}
              </Text>
            </View>
            {/* Transaction ID */}
            <View style={styles.descriptionCol}>
              <Text style={styles.tableCell}>
                Transaction ID:
                {"\n"}
                {transaction._id}
              </Text>
            </View>
            {/* Amount */}
            <View style={styles.amountCol}>
              <Text style={styles.tableCell}>
                {currencySymbol} {transaction.total_amount}
              </Text>
            </View>
          </View>
        </View>

        {/* Download date */}
        <View style={styles.footer}>
          <Text style={styles.downloadDate}>Download date: {downloadDate}</Text>
        </View>
      </Page>
    </Document>
  );
};

export const generateWithdrawalPDF = async (transaction, user) => {
  const blob = await pdf(<WithdrawalPDFDocument transaction={transaction} user={user} />).toBlob();
  return URL.createObjectURL(blob);
};
