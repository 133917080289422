const colors = {
  PRIMARY: "#00001E",
  SECONDARY: "#00FF6A",
  BUTTON: "#00FF6A",
  PRIMARY_TEXT: "#fff",
  SECONDARY_TEXT: "#ccc",
  GRAY: "#25253A",
  GREEN: "#53ff84",
  GREEN_1: "#42CC70",
  GREEN_2: "#3EC06A",
  RED: "#CF202F",
  ERROR:"#e74e67",
  SECONDARY_GRAY:"#141420"
};
export default colors;
