import React, { Component } from "react";
import {
  Button,
  Space,
  Typography,
  Row,
  Col,
  Select,
  Input,
  Form,
  Tabs,
  Image,
  message,
} from "antd";
import "./beneficiaries.css";
import { withTranslation } from "react-i18next";
import COLOR from "../../const/colors";
import { RightOutlined } from "@ant-design/icons";
import { asset } from "../../common/assets";
import { getAccounts } from "../../redux/actions/bankingActions";
import { selectedAsset } from "../../redux/actions/navigateActions";
import { connect } from "react-redux";
import { addBeneficiary } from "../../redux/actions/beneficiariesActions";
import { currentToken } from "../../redux/actions/tokenActions";
import * as WAValidator from "multicoin-address-validator";
import * as WAValidator2 from "wallet-validator";
const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

class AddBeneficiary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetActive: "",
      description: "",
      address: "",
      holder: "",
      iban: "",
      bic: "",
      accountNumber: "",
      sortCode: "",
      loading: false,
      network: "",
    };
  }
  componentDidMount() {
    this.getInfoAsset();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.asset !== this.props.asset) {
      this.getInfoAsset();
    }
  }
  getInfoAsset() {
    let assetSelect = this.props.asset.key;
    let accountsList = this.props.banking.data.data.accounts;
    let userType = this.props.dataUser.data?.data?.user?.type;
    if (userType === undefined) {
      userType = "DAMPAY_USER_CHILDREN";
    }
    let accountAsset = accountsList.find(
      (element) => element.currency === assetSelect
    );
    let assetData = {};
    if (accountAsset !== undefined) {
      if (userType.includes("LITE") && accountAsset.type === "FIAT") {
        let holderName = this.props.banking.data.data.account_holder;
        let name = holderName.name;
        let lastName =
          holderName.last_name !== undefined ? holderName.last_name : "";
        this.setState({
          holder: name + " " + lastName,
        });
      }
      assetData.accountData = accountAsset.account_data;
      assetData.currency = accountAsset.currency;
      assetData.id = accountAsset._id;
      assetData.type = accountAsset.type;
      let currency = asset.assets.find(
        (element) => assetSelect === element.value
      );
      if (currency !== undefined) {
        assetData.icon = currency.icon;
        assetData.name = currency.name;
        assetData.symbol = currency.symbol;
      }
    }
    this.setState({
      assetActive: assetData,
      network:
        assetData.accountData.networks !== undefined
          ? assetData.accountData.networks[0].network
          : "",
    });
  }
  fieldsEmpty() {
    let assetSelect = this.props.asset.key;
    if (assetSelect === "EUR") {
      if (
        this.state.iban === "" ||
        this.state.bic === "" ||
        this.state.description === "" ||
        this.state.holder === ""
      ) {
        return true;
      } else {
        return false;
      }
    } else if (assetSelect === "GBP") {
      if (
        this.state.sortCode === "" ||
        this.state.accountNumber === "" ||
        this.state.description === "" ||
        this.state.holder === "" ||
        this.state.accountNumber.length !== 8 ||
        this.state.sortCode.length < 6
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.state.address === "" || this.state.description === "") {
        return true;
      } else {
        return false;
      }
    }
  }
  async addBeneficiary() {
    try {
      let userType = this.props.dataUser.data?.data?.user?.type;
      if (userType === undefined) {
        userType = "DAMPAY_USER_CHILDREN";
      }
      this.setState({ loading: true });
      let currentCurrency = this.state.assetActive.currency;
      let body = {};
      if (this.state.assetActive.type === "CRYPTO") {
        let valid = "";
        if (currentCurrency === "BTC") {
          valid = WAValidator2.validate(this.state.address, currentCurrency);
        } else if (this.state.network === "TRC20") {
          valid = WAValidator.validate(this.state.address, "TRX");
        } else {
          valid = WAValidator.validate(this.state.address, "ETH");
        }
        if (valid) {
          let networkSelected = "";
          if (currentCurrency !== "BTC" && currentCurrency !== "ETH") {
            networkSelected = this.state.network;
          } else {
            networkSelected = "BASE_ASSET";
          }
          body = {
            description: this.state.description,
            currency_code: currentCurrency,
            network: networkSelected,
            address: this.state.address,
          };
        } else {
          message.error(this.props.t("beneficiaries.messages.invalidAddress"));
          this.setState({ loading: false, address: "" });
        }
      } else if (this.state.assetActive.type === "FIAT") {
        if (currentCurrency === "GBP") {
          const regSortNumber = /^[0-9-]+$/;
          const regSort = /^(?:\d{6}|\d\d-\d\d-\d\d)$/;
          if (
            !regSortNumber.test(this.state.sortCode) ||
            !regSort.test(this.state.sortCode)
          ) {
            message.error(this.props.t("beneficiaries.messages.errorSortCode"));
            this.setState({ loading: false });
          } else {
            body = {
              account_holder: this.state.holder,
              description: this.state.description,
              currency_code: currentCurrency,
              account_number: this.state.accountNumber.replace(/\s+/g, ""),
              sort_code: this.state.sortCode.replace(/[\s-]+/g, ""),
            };
          }
        } else if (currentCurrency === "EUR") {
          body = {
            account_holder: this.state.holder,
            description: this.state.description,
            currency_code: currentCurrency,
            iban: this.state.iban.replace(/\s+/g, ""),
            bic: this.state.bic.replace(/\s+/g, ""),
          };
        }
      }
      if (Object.keys(body).length !== 0) {
        await this.props.addBeneficiary(
          this.props.token.clientId,
          this.props.token.current,
          body
        );
        let resp = this.props.beneficiariesResp;
        if (resp.headers !== undefined) {
          let newAccessToken = resp.headers["x-new-access-token"];
          if (newAccessToken !== undefined) {
            await this.props.currentToken(newAccessToken);
          }
        }
        if (resp.status === 200) {
          this.setState({ loading: false });
          if (resp.data.data.new === true) {
            message.success(
              userType.includes("LITE")
                ? this.props.t("beneficiaries.messages.successAddedBankAccount")
                : this.props.t("beneficiaries.messages.successAddedBeneficiary")
            );
            let network = "";
            if (currentCurrency !== "BTC" && currentCurrency !== "ETH") {
              network = this.state.network;
            } else {
              network = "BASE_ASSET";
            }
            this.props.getBeneficiaries(currentCurrency, network);
          } else {
            let descriptionBeneficiary = resp.data.data.beneficiary.description;
            message.warning(
              userType.includes("LITE")
                ? this.props.t("beneficiaries.messages.AccountAlreadyExists") +
                    descriptionBeneficiary
                : this.props.t(
                    "beneficiaries.messages.beneficiaryAlreadyExists"
                  ) + descriptionBeneficiary
            );
          }
          this.cleanFields();
        } else if (resp.status === 400) {
          this.setState({ loading: false });
          if (resp.data.error_details.validation_error !== undefined) {
            let messageError = resp.data.error_details.validation_error;
            if (
              resp.data.error_details.validation_error ===
              "THIRD_PARTY_VALIDATION_ERROR"
            ) {
              if (resp.data.message.includes("Invalid iban")) {
                message.error(
                  this.props.t("beneficiaries.messages.INVALID_VALUE_IBAN"),
                  10
                );
              } else if (resp.data.message.includes("Invalid bic")) {
                message.error(
                  this.props.t("beneficiaries.messages.INVALID_VALUE_BIC"),
                  10
                );
              } else if (resp.data.message.includes("Invalid sort code")) {
                message.error(
                  this.props.t(
                    "beneficiaries.messages.INVALID_VALUE_SORT_CODE"
                  ),
                  10
                );
              } else if (resp.data.message.includes("Invalid account number")) {
                message.error(
                  this.props.t(
                    "beneficiaries.messages.INVALID_VALUE_ACCOUNT_NUMBER"
                  ),
                  10
                );
              } else {
                message.error(this.props.t(resp.data.message), 10);
              }
            } else if (
              resp.data.error_details.validation_error === "AML_CHECK_REJECTED"
            ) {
              message.error(
                this.props.t("beneficiaries.messages.AML_CHECK_REJECTED"),
                10
              );
            } else {
              message.error(
                this.props.t("beneficiaries.messages." + messageError),
                10
              );
            }
          } else if (resp.data.error_details.payload_error !== undefined) {
            let messageError = resp.data.error_details.payload_error;
            message.error(
              this.props.t("beneficiaries.messages." + messageError),
              10
            );
          } else {
            message.error(resp.data.message);
          }
        } else if (resp.status === 403) {
          this.setState({ loading: false });
          message.error(this.props.t("beneficiaries.messages.accessError"));
        } else if (
          resp.status === 401 ||
          resp.status === 422 ||
          resp.status === 404 ||
          resp.status === 500
        ) {
          this.setState({ loading: false });
          message.error(this.props.t("beneficiaries.messages.errorServer"));
        }
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  }
  handleSortCode(e) {
    const reg = /^[0-9-]+$/;
    if (reg.test(e.target.value) || e.target.value === "") {
      this.setState({
        sortCode: e.target.value,
      });
    }
  }

  handleAccountNumber(e) {
    const reg = /^[0-9]+$/;
    if (reg.test(e.target.value) || e.target.value === "") {
      this.setState({
        accountNumber: e.target.value,
      });
    }
  }
  cleanFields() {
    this.setState({
      description: "",
      address: "",
      holder: "",
      iban: "",
      bic: "",
      accountNumber: "",
      sortCode: "",
    });
  }
  handleNetwork = (value, option) => {
    this.setState({ network: value });
  };
  render() {
    let userType = this.props.dataUser.data?.data?.user?.type;
    if (userType === undefined) {
      userType = "DAMPAY_USER_CHILDREN";
    }
    return (
      <div>
        <div
          id={"custom-tab"}
          className="ant-tabs ant-tabs-tab ant-tabs-nav ant-tabs-card ant-tabs-tab-active ant-tabs-tab-btn ant-tabs-top"
        >
          <Tabs
            type="card"
            centered
            size="large"
            tabBarGutter={2}
            tabBarStyle={{ width: "100%", color: "#ccc" }}
            activeKey={this.state.tabActive}
            onTabClick={(key) => this.handleTab(key)}
          >
            <TabPane
              style={{ width: "100%", textAlign: "center" }}
              tab={
                userType.includes("LITE")
                  ? this.props.t("beneficiaries.addBankAccount")
                  : this.props.t("beneficiaries.addBeneficiary")
              }
              key="1"
            >
              <Row
                justify="center"
                gutter={[48, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}
              >
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                >
                  <Space
                    direction="vertical"
                    style={{ width: "100%" }}
                    size="middle"
                  >
                    <Row justify="center">
                      {this.state.assetActive.type === "CRYPTO" && (
                        <Form
                          labelCol={{
                            xs: { span: 24 },
                            sm: { span: 24 },
                            md: { span: 24 },
                            lg: { span: 24 },
                            xl: { span: 24 },
                            xxl: { span: 24 },
                          }}
                          wrapperCol={{
                            xs: { span: 24 },
                            sm: { span: 24 },
                            md: { span: 24 },
                            lg: { span: 24 },
                            xl: { span: 24 },
                            xxl: { span: 24 },
                          }}
                          layout="vertical"
                          style={{ width: "100%" }}
                        >
                          <Form.Item
                            label={
                              <Text className="label-dark">
                                * {this.props.t("beneficiaries.description")}
                              </Text>
                            }
                          >
                            <Input
                              size="large"
                              placeholder={this.props.t(
                                "beneficiaries.description"
                              )}
                              value={this.state.description}
                              onChange={(e) =>
                                this.setState({ description: e.target.value })
                              }
                              style={{
                                color: COLOR.PRIMARY_TEXT,
                                background: COLOR.PRIMARY,
                              }}
                            />
                          </Form.Item>
                          {this.state.assetActive.currency !== "BTC" &&
                            this.state.assetActive.currency !== "ETH" && (
                              <Form.Item
                                label={
                                  <Text className="label-dark">
                                    *{" "}
                                    {this.props.t(
                                      "beneficiaries.withdrawalNetwork"
                                    )}
                                  </Text>
                                }
                              >
                                <div
                                  id="select-dark-add"
                                  className="ant-select-selector ant-select"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Select
                                    suffixIcon={
                                      <RightOutlined
                                        style={{
                                          color: COLOR.PRIMARY_TEXT,
                                        }}
                                      />
                                    }
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      background: COLOR.PRIMARY,
                                    }}
                                    size="large"
                                    disabled={
                                      this.state.assetActive.accountData
                                        .networks === undefined ||
                                      this.state.assetActive.accountData
                                        .networks.length <= 1
                                    }
                                    value={this.state.network}
                                    onChange={(value, option) =>
                                      this.handleNetwork(value, option)
                                    }
                                  >
                                    {this.state.assetActive.accountData
                                      .networks !== undefined &&
                                      this.state.assetActive.accountData
                                        .networks.length > 1 &&
                                      this.state.assetActive.accountData.networks.map(
                                        (item, i) => {
                                          return (
                                            <Option
                                              key={item.asset_id}
                                              value={item.network}
                                            >
                                              {item.network}
                                            </Option>
                                          );
                                        }
                                      )}
                                  </Select>
                                </div>
                              </Form.Item>
                            )}
                          <Form.Item
                            label={
                              <Text className="label-dark">
                                * {this.props.t("beneficiaries.address")}
                              </Text>
                            }
                          >
                            <Input
                              id="input-prefix-address"
                              prefix={
                                <Image
                                  src={this.state.assetActive.icon}
                                  width={20}
                                  preview={false}
                                />
                              }
                              size="large"
                              placeholder={this.props.t(
                                "beneficiaries.address"
                              )}
                              value={this.state.address}
                              onChange={(e) =>
                                this.setState({ address: e.target.value })
                              }
                              style={{
                                color: COLOR.PRIMARY_TEXT,
                                background: COLOR.PRIMARY,
                              }}
                            />
                          </Form.Item>
                        </Form>
                      )}
                      {this.state.assetActive.type === "FIAT" && (
                        <div style={{ width: "100%" }}>
                          {this.state.assetActive.currency === "EUR" && (
                            <Form
                              labelCol={{
                                xs: { span: 24 },
                                sm: { span: 24 },
                                md: { span: 24 },
                                lg: { span: 24 },
                                xl: { span: 24 },
                                xxl: { span: 24 },
                              }}
                              wrapperCol={{
                                xs: { span: 24 },
                                sm: { span: 24 },
                                md: { span: 24 },
                                lg: { span: 24 },
                                xl: { span: 24 },
                                xxl: { span: 24 },
                              }}
                              layout="vertical"
                              style={{ width: "100%" }}
                            >
                              <Form.Item
                                label={
                                  <Text className="label-dark">
                                    *{" "}
                                    {this.props.t("beneficiaries.description")}
                                  </Text>
                                }
                              >
                                <Input
                                  size="large"
                                  placeholder={this.props.t(
                                    "beneficiaries.description"
                                  )}
                                  value={this.state.description}
                                  onChange={(e) =>
                                    this.setState({
                                      description: e.target.value,
                                    })
                                  }
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    background: COLOR.PRIMARY,
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                label={
                                  <Text className="label-dark">
                                    *{" "}
                                    {this.props.t(
                                      "beneficiaries.accountHolder"
                                    )}
                                  </Text>
                                }
                              >
                                <Input
                                  size="large"
                                  placeholder={this.props.t(
                                    "beneficiaries.accountHolder"
                                  )}
                                  value={this.state.holder}
                                  onChange={(e) =>
                                    this.setState({ holder: e.target.value })
                                  }
                                  disabled={userType.includes("LITE")}
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    background: COLOR.PRIMARY,
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                label={
                                  <Text className="label-dark">* IBAN</Text>
                                }
                              >
                                <Input
                                  size="large"
                                  placeholder="IBAN"
                                  value={this.state.iban}
                                  onChange={(e) =>
                                    this.setState({ iban: e.target.value })
                                  }
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    background: COLOR.PRIMARY,
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                label={
                                  <Text className="label-dark">* BIC</Text>
                                }
                              >
                                <Input
                                  size="large"
                                  placeholder="BIC"
                                  value={this.state.bic}
                                  onChange={(e) =>
                                    this.setState({ bic: e.target.value })
                                  }
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    background: COLOR.PRIMARY,
                                  }}
                                />
                              </Form.Item>
                            </Form>
                          )}
                          {this.state.assetActive.currency === "GBP" && (
                            <Form
                              labelCol={{
                                xs: { span: 24 },
                                sm: { span: 24 },
                                md: { span: 24 },
                                lg: { span: 24 },
                                xl: { span: 24 },
                                xxl: { span: 24 },
                              }}
                              wrapperCol={{
                                xs: { span: 24 },
                                sm: { span: 24 },
                                md: { span: 24 },
                                lg: { span: 24 },
                                xl: { span: 24 },
                                xxl: { span: 24 },
                              }}
                              layout="vertical"
                              style={{ width: "100%" }}
                            >
                              <Form.Item
                                label={
                                  <Text className="label-dark">
                                    *{" "}
                                    {this.props.t("beneficiaries.description")}
                                  </Text>
                                }
                              >
                                <Input
                                  size="large"
                                  placeholder={this.props.t(
                                    "beneficiaries.description"
                                  )}
                                  value={this.state.description}
                                  onChange={(e) =>
                                    this.setState({
                                      description: e.target.value,
                                    })
                                  }
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    background: COLOR.PRIMARY,
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                label={
                                  <Text className="label-dark">
                                    *{" "}
                                    {this.props.t(
                                      "beneficiaries.accountHolder"
                                    )}
                                  </Text>
                                }
                              >
                                <Input
                                  size="large"
                                  placeholder={this.props.t(
                                    "beneficiaries.accountHolder"
                                  )}
                                  value={this.state.holder}
                                  onChange={(e) =>
                                    this.setState({ holder: e.target.value })
                                  }
                                  disabled={userType.includes("LITE")}
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    background: COLOR.PRIMARY,
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                label={
                                  <Text className="label-dark">
                                    * Sort Code
                                  </Text>
                                }
                                rules={[
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (value !== "") {
                                        if (value.length === 8) {
                                          return Promise.resolve();
                                        } else {
                                          return Promise.reject(
                                            new Error(
                                              "Account number must contain 6 digits"
                                            )
                                          );
                                        }
                                      }
                                    },
                                  }),
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Sort Code"
                                  value={this.state.sortCode}
                                  maxLength={8}
                                  onChange={(e) => this.handleSortCode(e)}
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    background: COLOR.PRIMARY,
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                label={
                                  <Text className="label-dark">
                                    *{" "}
                                    {this.props.t(
                                      "beneficiaries.accountNumber"
                                    )}
                                  </Text>
                                }
                                rules={[
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (value !== "") {
                                        if (value.length === 8) {
                                          return Promise.resolve();
                                        } else {
                                          return Promise.reject(
                                            new Error(
                                              "Account number must contain 8 digits"
                                            )
                                          );
                                        }
                                      }
                                    },
                                  }),
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder={this.props.t(
                                    "beneficiaries.accountNumber"
                                  )}
                                  value={this.state.accountNumber}
                                  onChange={(e) => this.handleAccountNumber(e)}
                                  maxLength={8}
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    background: COLOR.PRIMARY,
                                  }}
                                />
                              </Form.Item>
                            </Form>
                          )}
                        </div>
                      )}
                    </Row>
                    <Row justify="center">
                      <Button
                        loading={this.state.loading}
                        block
                        type="primary"
                        style={{
                          height: "60px",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                        disabled={this.fieldsEmpty()}
                        onClick={() => this.addBeneficiary()}
                      >
                        {this.props.t("beneficiaries.add")}
                      </Button>
                    </Row>
                    {this.state.assetActive.type === "CRYPTO" && (
                      <Row style={{ textAlign: "justify" }}>
                        <Text
                          style={{
                            color: COLOR.SECONDARY_TEXT,
                            fontSize: 14,
                          }}
                        >
                          {this.props.t("beneficiaries.note", {
                            key:
                              this.state.assetActive.currency !== "BTC" &&
                              this.state.assetActive.currency !== "ETH"
                                ? this.state.assetActive.currency +
                                  " (" +
                                  this.state.network +
                                  ")"
                                : this.state.assetActive.currency,
                          })}
                        </Text>
                      </Row>
                    )}
                  </Space>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    banking: state.banking.accounts,
    asset: state.siderNavigate.asset,
    beneficiariesResp: state.beneficiaries.addedBeneficiary,
    beneficiariesList: state.beneficiaries.resultBeneficiaries,
    token: state.tokenAccess,
    dataUser: state.authUser.userData,
  };
};

const mapDispatchToProps = {
  getAccounts,
  selectedAsset,
  addBeneficiary,
  currentToken,
};
const AddBeneficiaryTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AddBeneficiary)
);

export default AddBeneficiaryTranslated;
