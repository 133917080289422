import {
  GET_ACCOUNTS,
  GET_FEES,
  GET_LIMITS,
  SEND_PAYMENT,
  CONFIRM_PAY,
  REBALANCE,
} from "../actions/bankingActions";
const initialState = {
  accounts: "",
  fees: "",
  limits: "",
  sendResp: "",
  confirmPayResponse: "",
  rebalanceResp: "",
};

export function banking(state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNTS:
      return Object.assign({}, state, { accounts: action.payload });
    case GET_FEES:
      return Object.assign({}, state, { fees: action.payload });
    case GET_LIMITS:
      return Object.assign({}, state, { limits: action.payload });
    case SEND_PAYMENT:
      return Object.assign({}, state, { sendResp: action.payload });
    case CONFIRM_PAY:
      return Object.assign({}, state, { confirmPayResponse: action.payload });
    case REBALANCE:
      return Object.assign({}, state, { rebalanceResp: action.payload });
    default:
      return state;
  }
}
