import React, { Component } from "react";
import COLOR from "../../const/colors";
import "./tradeDetail.css";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Image,
  Typography,
  Card,
  Space,
  Row,
  Col,
  Table,
  Select,
  AutoComplete,
  Skeleton,
  message,
  Radio,
  Progress,
} from "antd";
import ContainerTrade from "../containerTrade/containerTrade";
import { getAccounts } from "../../redux/actions/bankingActions";
import { asset } from "../../common/assets";
import { isMobileOnly } from "react-device-detect";
import { getMarket } from "../../redux/actions/exchangeActions";
import { currentToken } from "../../redux/actions/tokenActions";
import compactFormat from "cldr-compact-number";
import NumberFormat from "react-number-format";
import CAN from "../../casl/can";
const { Option } = Select;
const { Text } = Typography;
const interval = [
  { text: "1H", value: "1h" },
  { text: "24H", value: "24h" },
  { text: "1W", value: "7d" },
  { text: "1M", value: "30d" },
  { text: "1Y", value: "1y" },
  { text: "ALL", value: "all" },
];
class TradeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataTofilter: [],
      listAssets: [],
      loading: false,
      intervalActive: "1h",
      dataMarket: [],
      nativeCurrency: "",
      nativeSymbol: "",
      limitType: "daily",
      limitExternal: "",
      limitInternal: "",
      currentVolume: "",
      loadingLimits: false,
      percentage: 0,
    };
  }
  componentDidMount() {
    this.getDataMarket("1h");
  }
  componentDidUpdate(prevProps) {
    if (prevProps.currentLimit !== this.props.currentLimit) {
      if (!this.props.modalState) {
        this.setState({ loadingLimits: true });
        if (this.props.currentLimit !== null) {
          this.getAccounts();
        }
      }
    }
    if (this.props.responsePreferences !== prevProps.responsePreferences) {
      this.getDataMarket("1h");
    }
  }
  async getAccounts() {
    try {
      let assetSelected = this.props.currentCurrency;
      //let quoteCurrency = this.props.quoteCurrencySelected;
      await this.props.getAccounts(
        this.props.token.clientId,
        this.props.token.current
      );
      let response = this.props.accounts;
      let newAccessToken = "";
      if (response.headers !== undefined) {
        newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        let dataAccounts = response.data.data.accounts;
        let findAccount = dataAccounts.find(
          (element) => element.currency === assetSelected
        );
        let findCurrentVolume = findAccount.volumes.find(
          (element) =>
            element.type === "EXCHANGE_OUT"
        );
        // let findCurrentVolume = findAccount.volumes.find(
        //   (element) =>
        //     element.type === "EXCHANGE_OUT" &&
        //     element.quote_currency === quoteCurrency.value
        // );
        let currentVolume =
          this.state.limitType === "daily"
            ? findCurrentVolume.current_day_volume
            : findCurrentVolume.current_month_volume;

        let maxLimit =
          this.state.limitType === "daily"
            ? this.props.currentLimit.max_daily_exchange
            : this.props.currentLimit.max_monthly_exchange;
        let percentageCalculate =
          (Number(currentVolume) / Number(maxLimit)) * 100;
        this.setState(
          {
            currentVolume: findCurrentVolume,
            percentage: percentageCalculate,
          },
          () => {
            this.setState({ loadingLimits: false });
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }
  filterTable(nameSearch) {
    let search = nameSearch.toLowerCase();
    let filter = this.state.dataTofilter.filter((value) =>
      value.name.toLowerCase().includes(search)
    );

    this.setState({
      dataMarket: filter,
    });
  }
  async getDataMarket(value) {
    try {
      this.setState({ intervalActive: value, loading: true });
      await this.props.getMarket(
        this.props.token.clientId,
        this.props.token.current,
        value
      );
      let response = this.props.respMarket;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        let info = response.data.data;
        let dataMarket = info.markets;
        let array = [];
        for (let market of dataMarket) {
          let newObj = {};
          Object.entries(market).forEach(([key, value]) => {
            newObj[key] = value;
            if (key === "currency") {
              let currency = asset.assets.find(
                (element) => value === element.value
              );
              if (currency !== undefined) {
                newObj.icon = currency.icon;
                newObj.type = currency.type;
              }
            }
          });
          array.push(newObj);
        }
        if (this.props.onlyFiat) {
          array = array.filter((element) => element.type === "FIAT");
        }
        this.setState({
          loading: false,
          dataTofilter: array,
          dataMarket: array,
          nativeCurrency: info.native_currency,
          nativeSymbol: info.native_symbol,
        });
      } else if (response.status === 400) {
        message.error(this.props.t("detailTrade.error.queryError"));
        this.setState({ loading: false });
      } else if (response.status === 403) {
        message.error(this.props.t("detailTrade.error.accessError"));
        this.setState({ loading: false });
      } else {
        message.error(this.props.t("detailTrade.error.errorServer"));
        this.setState({ loading: false });
      }
    } catch (error) {
      // console.log(error);
      this.setState({ loading: false });
    }
  }
  handleChangeLimit = (e) => {
    this.setState({ limitType: e.target.value });
  };
  render() {
    //let percentage = 0;
    let roleCurrent = this.props.dataUser.data?.data?.user.role;
    let maxLimit = "";
    let limit = this.props.currentLimit;
    if (limit !== null) {
      maxLimit =
        this.state.limitType === "daily"
          ? limit.max_daily_exchange
          : limit.max_monthly_exchange;
      // percentage = (Number(currentVolume) / Number(maxLimit)) * 100;
    }
    let columns = [
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("detailTrade.name")}
          </Text>
        ),
        dataIndex: "name",
        key: "name",
        width: 50,
        className: "column-asset",
        sorter: (a, b) => a.name.localeCompare(b.name.length),
        render: (record, originNode) => (
          <>
            <Space align="center" direction="horizontal" size="small">
              <Image
                style={{ alignItems: "center", display: "inline-flex" }}
                preview={false}
                width={35}
                src={originNode.icon}
              />
              <Space direction="vertical" size={0}>
                <Text
                  style={{
                    color: COLOR.PRIMARY_TEXT,
                    fontSize: 18,
                  }}
                >
                  {originNode.name}
                </Text>
                <Text
                  style={{
                    color: COLOR.SECONDARY_TEXT,
                    fontSize: 16,
                  }}
                >
                  {originNode.currency}
                </Text>
              </Space>
            </Space>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("detailTrade.price")}
          </Text>
        ),
        dataIndex: "current_price",
        key: "current_price",
        width: 30,
        sorter: (a, b) => a.current_price - b.current_price,
        render: (current_price) => (
          <>
            <Text
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 20,
                fontWeight: 400,
              }}
            >
              <NumberFormat
                thousandSeparator={true}
                displayType="text"
                prefix={this.state.nativeSymbol}
                decimalScale={8}
                value={current_price}
              />
            </Text>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("detailTrade.change")}
          </Text>
        ),
        dataIndex: "change_percentage",
        key: "change_percentage",
        sorter: (a, b) => a.change_percentage - b.change_percentage,
        width: 30,
        render: (change_percentage) => (
          <>
            <Text
              style={{
                color: change_percentage > 0 ? "#098551" : "#CF202F",
                fontSize: 20,
                fontWeight: 400,
              }}
            >
              <NumberFormat
                thousandSeparator={true}
                displayType="text"
                suffix={"%"}
                decimalScale={2}
                value={change_percentage}
              />
            </Text>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("detailTrade.marketCap")}
          </Text>
        ),
        dataIndex: "market_cap",
        key: "market_cap",
        width: 30,
        sorter: (a, b) => a.market_cap - b.market_cap,
        defaultSortOrder: "descend",
        render: (record, originNode) => (
          <>
            {originNode.type === "FIAT" && (
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 20,
                  fontWeight: 400,
                }}
              >
                -
              </Text>
            )}
            {originNode.type === "CRYPTO" && (
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 20,
                  fontWeight: 400,
                }}
              >
                {this.state.nativeSymbol +
                  compactFormat(
                    originNode.market_cap,
                    "en",
                    window.sessionStorage.getItem("lang"),
                    {
                      significantDigits: 1,
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 2,
                    }
                  )}
              </Text>
            )}
          </>
        ),
      },
    ];
    if (this.props.onlyFiat) {
      columns = columns.filter((column) => column.key !== "market_cap" && column.key !== "change_percentage");
    }

    return (
      <>
        {CAN("EXCHANGE", "SELL") &&
          CAN("EXCHANGE", "BUY") &&
          CAN("EXCHANGE", "SWAP") &&
          CAN("EXCHANGE", "FX") && (
            <Row
              gutter={[16, { xs: 8, sm: 16, md: 8, lg: 8, xl: 24 }]}
              style={{ marginBottom: 10 }}
            >
              <Col
                xs={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                lg={{ span: 16, offset: 1 }}
                xl={{ span: 8, offset: 1 }}
              >
                <Card
                  title={
                    <Row
                      gutter={[8, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}
                      align="middle"
                      justify="space-between"
                    >
                      <Space size="middle" align="center">
                        <Text
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            fontSize: 16,
                          }}
                        >
                          {this.props.t("limits.titleTrade")}
                        </Text>
                      </Space>
                      <Space size="middle" align="center">
                        <Radio.Group
                          value={this.state.limitType}
                          onChange={this.handleChangeLimit}
                        >
                          <Radio.Button
                            style={{
                              borderColor: COLOR.GREEN,
                              background:
                                this.state.limitType === "daily"
                                  ? COLOR.GREEN
                                  : COLOR.SECONDARY_GRAY,
                              color:
                                this.state.limitType === "daily"
                                  ? COLOR.SECONDARY_GRAY
                                  : COLOR.PRIMARY_TEXT,
                              fontSize: 14,
                            }}
                            value="daily"
                          >
                            {this.props.t("limits.daily")}
                          </Radio.Button>
                          <Radio.Button
                            style={{
                              borderColor: COLOR.GREEN,
                              background:
                                this.state.limitType === "monthly"
                                  ? COLOR.GREEN
                                  : COLOR.SECONDARY_GRAY,
                              color:
                                this.state.limitType === "monthly"
                                  ? COLOR.SECONDARY_GRAY
                                  : COLOR.PRIMARY_TEXT,
                              fontSize: 14,
                            }}
                            value="monthly"
                          >
                            {this.props.t("limits.monthly")}
                          </Radio.Button>
                        </Radio.Group>
                      </Space>
                    </Row>
                  }
                  bordered={false}
                  size="default"
                  style={{
                    borderRadius: "5px",
                    background: COLOR.SECONDARY_GRAY,
                    color: COLOR.PRIMARY_TEXT,
                    border: "1px solid #000",
                    width: "98%",
                  }}
                  bodyStyle={{
                    color: COLOR.PRIMARY_TEXT,
                    fontSize: 14,
                    height: 90,
                  }}
                >
                  <Skeleton
                    active
                    size={"small"}
                    style={{ marginTop: 15 }}
                    paragraph={{ rows: 2 }}
                    title={false}
                    loading={this.state.loadingLimits}
                  />

                  {!this.state.loadingLimits && (
                    <div>
                      <Row
                        gutter={[8, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}
                        align="middle"
                        justify="space-between"
                        style={{ marginTop: 15 }}
                      >
                        <Text
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            textAlign: "left",
                            fontWeight: "normal",
                          }}
                        >
                          {this.props.t("limits.tradedFunds")}
                        </Text>

                        {maxLimit !== "" && (
                          <Text
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              fontSize: 14,
                              fontWeight: "normal",
                            }}
                          >
                            {this.props.currentCurrency + " "}
                            {this.state.limitType === "daily"
                              ? this.state.currentVolume.current_day_volume
                              : this.state.currentVolume.current_month_volume}
                            {" of " + this.props.currentCurrency + " "}
                            {maxLimit === "-1"
                              ? this.props.t("limits.unlimited")
                              : maxLimit}
                          </Text>
                        )}
                      </Row>
                      {maxLimit !== "-1" && (
                        <Row
                          gutter={[8, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}
                          align="middle"
                          justify="space-between"
                        >
                          <Progress
                            trailColor="#808080"
                            strokeColor={COLOR.GREEN}
                            percent={this.state.percentage}
                            showInfo={false}
                          />
                        </Row>
                      )}

                      <Row
                        gutter={[8, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}
                        align="middle"
                        justify="space-between"
                      >
                        <Text
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            textAlign: "left",
                            fontWeight: "normal",
                          }}
                        >
                          {this.props.t("limits.tradingLimit", {
                            key: this.props.t("limits." + this.state.limitType),
                          })}
                        </Text>
                        {maxLimit !== "" && (
                          <Text
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              textAlign: "left",
                              fontWeight: "normal",
                            }}
                          >
                            {this.props.currentCurrency + " "}
                            {maxLimit === "-1"
                              ? this.props.t("limits.unlimited")
                              : maxLimit}
                          </Text>
                        )}
                      </Row>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          )}
        <Row
          gutter={[16, { xs: 8, sm: 16, md: 8, lg: 8, xl: 24 }]}
          style={{ marginBottom: 10 }}
        >
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: roleCurrent === "VIEWER" ? 22 : 16, offset: 1 }}
            xl={{ span: roleCurrent === "VIEWER" ? 22 : 16, offset: 1 }}
            xxl={{ span: roleCurrent === "VIEWER" ? 22 : 16, offset: 1 }}
          >
            <Card
              key="cardTrade"
              bordered={false}
              size="small"
              style={{
                borderRadius: "5px",
                background: COLOR.PRIMARY,
                color: COLOR.SECONDARY_TEXT,
                border: "1px solid #000",
                width: "100%",
              }}
              bodyStyle={{ padding: "0px 0px 20px 0px" }}
            >
              <Row
                gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
                style={{ padding: "25px 35px" }}
              >
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  xl={{ span: 12, offset: 0 }}
                  xxl={{ span: 12, offset: 0 }}
                  style={{
                    textAlign: "left",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {this.state.dataTofilter.length > 0 && (
                    <div
                      id="search-trade"
                      className="ant-select ant-select-selector"
                      style={{ width: isMobileOnly ? "100%" : "70%" }}
                    >
                      <AutoComplete
                        size="large"
                        placeholder={this.props.t("detailTrade.search")}
                        dropdownStyle={{ display: "none" }}
                        style={{ width: "100%" }}
                        onChange={(nameSearch) => this.filterTable(nameSearch)}
                      >
                        {this.state.dataTofilter.map((value) => (
                          <Option key={value.name} value={value.name}>
                            {value.name}
                          </Option>
                        ))}
                      </AutoComplete>
                    </div>
                  )}
                </Col>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  xl={{ span: 12, offset: 0 }}
                  xxl={{ span: 12, offset: 0 }}
                >
                  <Row
                    gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
                    style={{
                      justifyContent: "flex-end",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Col
                      xs={{ span: 12, offset: 0 }}
                      sm={{ span: 12, offset: 0 }}
                      md={{ span: 12, offset: 0 }}
                      lg={{ span: 12, offset: 0 }}
                      xl={{ span: 12, offset: 0 }}
                      xxl={{ span: 10, offset: 0 }}
                    >
                      {/* <div
                      id="select-trade"
                      className="ant-select-selector ant-select"
                    >
                      <Select
                        size="large"
                        defaultValue={this.props.t("detailTrade.tradable")}
                        style={{ width: "100%", textAlign: "left" }}
                      >
                        <Option key="1" value="1">
                          {this.props.t("detailTrade.tradable")}
                        </Option>
                        <Option key="2" value="2">
                          {this.props.t("detailTrade.all")}
                        </Option>
                      </Select>
                    </div> */}
                    </Col>
                    <Col
                      xs={{ span: 12, offset: 0 }}
                      sm={{ span: 12, offset: 0 }}
                      md={{ span: 12, offset: 0 }}
                      lg={{ span: 6, offset: 0 }}
                      xl={{ span: 6, offset: 0 }}
                      xxl={{ span: 6, offset: 0 }}
                    >
                      {this.state.dataMarket.length > 0 && (
                        <div
                          id="select-trade"
                          className="ant-select-selector ant-select"
                          style={{ width: "100%" }}
                        >
                          <Select
                            size="large"
                            value={this.state.intervalActive}
                            onChange={(value) => this.getDataMarket(value)}
                          >
                            {interval.map((item, i) => {
                              return (
                                <Option key={item.value + i} value={item.value}>
                                  {item.text}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {this.state.loading && <Skeleton />}
              {!this.state.loading && (
                <div
                  id="table-trade"
                  className="ant-table ant-table-tbody ant-table-thead text-table-cell ant-table-cell"
                >
                  <Table
                    key="trade"
                    rowKey={"name"}
                    locale={{
                      emptyText: (
                        <Space direction="vertical">
                          <br />
                          <Text
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                          >
                            {this.props.t("detailTrade.msgEmpty")}
                          </Text>
                          <Text
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              fontSize: 16,
                              fontWeight: "normal",
                            }}
                          >
                            {this.props.t("detailTrade.msgEmpty1")}
                          </Text>
                          <br />
                        </Space>
                      ),
                    }}
                    pagination={false}
                    columns={columns}
                    dataSource={this.state.dataMarket}
                    style={{ overflow: "auto" }}
                  />
                </div>
              )}
            </Card>
          </Col>
          {CAN("EXCHANGE", "SELL") &&
            CAN("EXCHANGE", "BUY") &&
            CAN("EXCHANGE", "SWAP") &&
            CAN("EXCHANGE", "FX") && (
              <Col
                xs={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                lg={{ span: 6, offset: 0 }}
                xl={{ span: 6, offset: 0 }}
                xxl={{ span: 6, offset: 0 }}
              >
                <Card
                  key="cardContainer"
                  bordered={false}
                  size="small"
                  style={{
                    borderRadius: "5px",
                    background: COLOR.PRIMARY,
                    color: COLOR.SECONDARY_TEXT,
                    border: "1px solid #000",
                    width: "100%",
                  }}
                  bodyStyle={{ padding: "0px 0px 15px 0px" }}
                >
                  <ContainerTrade source={"trade"} />
                </Card>
              </Col>
            )}
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    asset: state.siderNavigate.asset,
    accounts: state.banking.accounts,
    respMarket: state.exchange.market,
    token: state.tokenAccess,
    respLimits: state.banking.limits,
    currentCurrency: state.exchange.currentCurrencyFrom,
    currentLimit: state.exchange.currentLimits,
    modalState: state.siderNavigate.modal,
    dataUser: state.authUser.userData,
    responsePreferences: state.preferences.resultPreferences,
    quoteCurrencySelected: state.exchange.quoteCurrency,
    onlyFiat: state.authUser.isFiatOnly,
  };
};

const mapDispatchToProps = { getMarket, currentToken, getAccounts };

const TradeDetailTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(TradeDetail)
);

export default TradeDetailTranslated;
