import React, { Component } from "react";
import COLOR from "../../const/colors";
import "./wallet.css";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Row, Col, Card } from "antd";
import ContainerSendOrReceive from "../containerSendOrReceive/containerSendOrReceive";
import TransactionsWallet from "./transactionsWallet";

class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Row gutter={[8, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: window.screen.width <= 1024 ? 24 : 16, offset: 0 }}
            xl={{ span: 16, offset: 0 }}
            xxl={{ span: 18, offset: 0 }}
          >
            <TransactionsWallet
              source={"wallet"}
              tabActive={this.props.tabActive}
              handleTab={this.props.handleTab.bind(this)}
              accountSelected={this.props.accountSelected}
            />
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: window.screen.width <= 1024 ? 24 : 8, offset: 0 }}
            xl={{ span: 8, offset: 0 }}
            xxl={{ span: 6, offset: 0 }}
          >
            <Card
              bordered={false}
              size="small"
              style={{
                borderRadius: "5px",
                background: COLOR.PRIMARY,
                color: COLOR.SECONDARY_TEXT,
                border: "1px solid #000",
                width: "98%",
              }}
              bodyStyle={{ padding: "0px 0px 15px 0px" }}
            >
              <ContainerSendOrReceive
                source={"wallet"}
                handleTab={(key) => this.props.handleTab(key)}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

const WalletTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Wallet)
);

export default WalletTranslated;
