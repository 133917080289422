import {
  GET_BENEFICIARY,
  ADD_BENEFICIARY,
  DELETE_BENEFICIARY,
  EDIT_BENEFICIARY,
  TYPE_BENEFICIARY,
} from "../actions/beneficiariesActions";
const initialState = {
  resultBeneficiaries: "",
  addedBeneficiary: "",
  beneficiaryRemoved: "",
  editedBeneficiary: "",
  beneficiarySelected: "",
};

export function beneficiaries(state = initialState, action) {
  switch (action.type) {
    case GET_BENEFICIARY:
      return Object.assign({}, state, { resultBeneficiaries: action.payload });
    case ADD_BENEFICIARY:
      return Object.assign({}, state, { addedBeneficiary: action.payload });
    case DELETE_BENEFICIARY:
      return Object.assign({}, state, { beneficiaryRemoved: action.payload });
    case EDIT_BENEFICIARY:
      return Object.assign({}, state, { editedBeneficiary: action.payload });
    case TYPE_BENEFICIARY:
      return Object.assign({}, state, { beneficiarySelected: action.payload });
    default:
      return state;
  }
}
