import servicesUser from "../../services/user";
export const SEND_EMAIL = "SEND_EMAIL";
export const CONFIRM_EMAIL = "CONFIRM_EMAIL";
export const NEW_PASSWORD = "NEW_PASSWORD";
export function sendEmailRecovery(id, accessToken) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesUser.sendEmailRecovery(id, accessToken);
      dispatch({
        type: SEND_EMAIL,
        payload: response.data,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: SEND_EMAIL,
          payload: error.response,
        });
      }
    }
  };
}
export function confirmEmail(id,body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesUser.confirmEmail(id,body);
      dispatch({
        type: CONFIRM_EMAIL,
        payload: response.data,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: CONFIRM_EMAIL,
          payload: error.response,
        });
      }
    }
  };
}
export function setNewPassword(id, accessToken, body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesUser.setNewPassword(id, accessToken, body);
      dispatch({
        type: NEW_PASSWORD,
        payload: response.data,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: NEW_PASSWORD,
          payload: error.response,
        });
      }
    }
  };
}
