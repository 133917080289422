import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;
const usersChildren = {
  async createUsersChildren(idToken, accessToken, body) {
    const resp = await axios.post(BASE_URL + config.userChildren, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async getUsersChildren(idToken, accessToken, lastId) {
    let url = "";
    if (lastId !== undefined) {
      url = BASE_URL + config.userChildren + "?last_id=" + lastId;
    } else {
      url = BASE_URL + config.userChildren;
    }
    const resp = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async updateUsersChildren(idToken, accessToken, userId, body) {
    const resp = await axios.put(
      BASE_URL + config.userChildren + "/" + userId,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CLIENT-ID": idToken,
        },
      }
    );
    return resp;
  },
  async changeStatusUsersChildren(idToken, accessToken, userId, status) {
    const resp = await axios.put(
      BASE_URL + config.userChildren + "/" + userId + "/" + status,{},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CLIENT-ID": idToken,
        },
      }
    );
    return resp;
  },
  async deleteUsersChildren(idToken, accessToken, userId) {
    const resp = await axios.delete(
      BASE_URL + config.userChildren + "/" + userId,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-CLIENT-ID": idToken,
        },
      }
    );
    return resp;
  },
};
export default usersChildren;
