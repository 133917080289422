import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
  pdf,
  Link
} from "@react-pdf/renderer";
import source from "../../fonts/sofia-pro/Sofia Pro Light Az.otf";
import source1 from "../../fonts/sofia-pro/Sofia Pro Bold Az.otf";

Font.register({ family: "sofia-pro", src: source });
Font.register({ family: "sofia-pro-bold", src: source1 });

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    padding: 40,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
    fontSize: 10,
  },
  logoContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },
  logo: {
    width: 250,
    height: 50,
  },
  title: {
    fontSize: 16,
    fontFamily: "sofia-pro-bold",
    marginBottom: 20,
    textAlign: "center",
  },
  table: {
    display: "table",
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#E0E0E0",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#E0E0E0",
  },
  tableCol: {
    width: "50%",
    borderRightWidth: 1,
    borderRightColor: "#E0E0E0",
    padding: 5,
  },
  tableCell: {
    fontSize: 10,
    fontFamily: "sofia-pro",
  },
  tableCellRight: {
    fontSize: 10,
    fontFamily: "sofia-pro",
    textAlign: 'right',
  },
  footer: {
		marginTop: 40,
    fontSize: 10,
    fontFamily: "sofia-pro",
    textAlign: "center",
  },
  footerContact: {
		marginTop: 40,
		fontSize: 10,
		borderTopWidth: 1,
		borderTopColor: "#E0E0E0",
		paddingTop: 10,
		fontFamily: "sofia-pro-bold",
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  link: {
    color: 'blue',
    textDecoration: 'underline',
  },
});

const companyInfo = {
  GIB: {
    name: "Digital Asset Management Ltd",
    address: [
      "Suite 2.4 & 2.5",
      "Waterport Place",
      "Gibraltar, P.O Box 48",
      "Gx11 1AA"
    ],
    legalText: "Digital Asset Management Ltd (Company Number: 116618) is registered in Gibraltar and is authorised as a licensed Distributed Ledger Technology Provider to provide digital asset custody and broker/dealer services by the Gibraltar Financial Services Commission (licence number 24738). Please see ",
    privacyUrl: "www.damex.io/privacy/",
    legalTextEnd: " for details of how we collect and use your personal data."
  },
  UAB: {
    name: "Damex.io UAB",
    address: [
      "Žygimanto Liauksmino g. 3",
      "Vilnius",
      "Lithuania",
      "LT-01101"
    ],
    legalText: "Damex.io UAB is a joint stock company registered in the Republic of Lithuania (under company number 306148560) and is registered by the State Enterprise Centre of Registers of the Republic of Lithuania as a provider of virtual currency exchange and virtual currency wallet provider. Please see ",
    privacyUrl: "www.damex.io/privacy/",
    legalTextEnd: " for details of how we collect and use your personal data."
  }
};

export const PDFDocument = ({ order, user }) => {
  const userType = user.profile.entity
  const company = companyInfo[userType];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.logoContainer}>
          <Image style={styles.logo} src="/logo-text.png" />
        </View>
        <View style={styles.header}>
          <Text style={{ marginTop: "20px" }}>
            <Text style={{ fontFamily: "sofia-pro-bold" }}>
              {user.business_name ?? user.profile.name}
              {"\n"}
            </Text>
            {user.profile.physical_address.line_1}
            {"\n"}
            {user.profile.physical_address.line_2 &&
              `${user.profile.physical_address.line_2}\n`}
            {user.profile.physical_address.city},{" "}
            {user.profile.physical_address.region}
            {"\n"}
            {user.profile.physical_address.postal_code},{" "}
            {user.profile.physical_address.country}
          </Text>
          <Text style={{ textAlign: "right" }}>
            <Text style={{ fontFamily: "sofia-pro-bold" }}>
              {company.name}
              {"\n"}
            </Text>
            {company.address.map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {"\n"}
              </React.Fragment>
            ))}
            {"\n"}
            {"\n"}
            <Link src="mailto:trade@damex.io" style={styles.link}>
              trade@damex.io
            </Link>
          </Text>
        </View>
        <Text style={styles.title}>
          Digital Asset Deliverable Transaction Trade Confirmation
        </Text>
        <View style={styles.table}>
          {[
            ["Trade Identifier", order._id],
            ["Pair", order.pair],
            ["Trade Time", order.date],
            ["Direction", order.side],
            [
              "Base Quantity",
              `${order.volume_executed} ${order.base_currency}`,
            ],
            ["Price", order.price],
            ["Quote Quantity", `${order.cost} ${order.quote_currency}`],
          ].map(([label, value]) => (
            <View style={styles.tableRow} key={label}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{label}</Text>
              </View>
              <View style={[styles.tableCol, { borderRightWidth: 0 }]}>
                <Text style={styles.tableCellRight}>{value}</Text>
              </View>
            </View>
          ))}
        </View>
        <View style={styles.footer}>
          <Text style={{ marginBottom: 15 }}>
            This confirmation was generated for your convenience based on our
            internal records.{"\n"}
            IF THIS TRADE CONFIRMATION DOES NOT MATCH YOUR RECORDS, PLEASE
            PROMPTLY NOTIFY US.
          </Text>
          <Text>
            {company.legalText}
            <Link src={`https://${company.privacyUrl}`} style={styles.link}>
              {company.privacyUrl}
            </Link>
            {company.legalTextEnd}
          </Text>
          <View style={styles.footerContact}>
            <Text>Damex.io</Text>
            <Text>+350 200 73337</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export const generateOrderPDF = async (order, user) => {
  const blob = await pdf(<PDFDocument order={order} user={user} />).toBlob();
  return URL.createObjectURL(blob);
};
