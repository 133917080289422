import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;
const preferences = {
  async getPreferences(idToken, accessToken) {
    const resp = await axios.get(BASE_URL + config.preferences, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
  async updatePreferences(idToken, accessToken,body) {
    const resp = await axios.patch(BASE_URL + config.preferences,body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
};
export default preferences;
