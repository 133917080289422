import React, { Component } from "react";
import "./history.css";
import Ledger from "../ledger/ledger";
import Orders from "../orders/orders";
import { Row, Col, Typography, Tabs } from "antd";
import COLOR from "../../const/colors";
import { withTranslation } from "react-i18next";
const { Text } = Typography;

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: "1",
      notificationsListActivity: [],
      notificationsListGeneral: [],
      loading: false,
    };
  }
  handleTab(key) {
    this.setState({ tabActive: key });
  }
  render() {
    let items = [
      {
        label: (
          <Text
            style={{
              fontSize: 18,
              color: COLOR.PRIMARY_TEXT,
            }}
          >
            {this.props.t("orders.title")}
          </Text>
        ),
        key: "1",
        children: <Orders />,
      },
      {
        label: (
          <Text
            style={{
              fontSize: 18,
              color: COLOR.PRIMARY_TEXT,
            }}
          >
            {this.props.t("transactions.history.title")}
          </Text>
        ),
        key: "2",
        children: <Ledger />,
      },
    ];
    return (
      <>
        <Row gutter={[16, { xs: 8, sm: 16, md: 8, lg: 8, xl: 24 }]}>
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: 22, offset: 1 }}
            xl={{ span: 22, offset: 1 }}
          >
            <div
              id="custom-tab-detail"
              className="ant-tabs ant-tabs-tab ant-tabs-tab-active ant-tabs-tab-btn ant-tabs-nav ant-tabs-nav-list"
            >
              <Tabs
                type="line"
                onTabClick={(key) => this.handleTab(key)}
                activeKey={this.state.tabActive}
                items={items}
                tabBarStyle={{
                  color: COLOR.PRIMARY_TEXT,
                  width: "100%",
                  paddingLeft: "10px",
                  textAlign: "left"
                }}
              >
                {/* <TabPane
                  style={{ width: "100%", textAlign: "left" }}
                  tab={
                    <Text
                      style={{
                        fontSize: 18,
                        color: COLOR.PRIMARY_TEXT,
                      }}
                    >
                      {this.props.t("orders.title")}
                    </Text>
                  }
                  key="1"
                >
                  <Orders />
                </TabPane>
                <TabPane
                  style={{ width: "100%", textAlign: "left" }}
                  tab={
                    <Text
                      style={{
                        fontSize: 18,
                        color: COLOR.PRIMARY_TEXT,
                      }}
                    >
                      {this.props.t("transactions.history.title")}
                    </Text>
                  }
                  key="2"
                >
                  <Ledger />
                </TabPane> */}
              </Tabs>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

const HistoryTranslated = withTranslation()(History);

export default HistoryTranslated;
