import servicesTwoFactor from "../../services/twoFactor";
export const ACTIVATE_TWO_FACTOR = "ACTIVATE_TWO_FACTOR";
export const CONFIRM_TWO_FACTOR = "CONFIRM_TWO_FACTOR";
export const LOGIN_TWO_FACTOR = "LOGIN_TWO_FACTOR";
export function activateTwoFactor(id, accessToken, body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesTwoFactor.activateTwoFactor(
        id,
        accessToken,
        body
      );
      dispatch({
        type: ACTIVATE_TWO_FACTOR,
        payload: response,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: ACTIVATE_TWO_FACTOR,
          payload: error.response,
        });
      }
    }
  };
}
export function confirmTwoFactor(id, accessToken, body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesTwoFactor.confirmTwoFactor(
        id,
        accessToken,
        body
      );
      dispatch({
        type: CONFIRM_TWO_FACTOR,
        payload: response,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: CONFIRM_TWO_FACTOR,
          payload: error.response,
        });
      }
    }
  };
}
export function loginTwoFactor(id, body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesTwoFactor.loginTwoFactor(id, body);
      dispatch({
        type: LOGIN_TWO_FACTOR,
        payload: response,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: LOGIN_TWO_FACTOR,
          payload: error.response,
        });
      }
    }
  };
}
