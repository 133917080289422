import React, { Component } from "react";
import "./security.css";
import { Row, Col, Space, Typography, Button, Modal } from "antd";
import COLOR from "../../const/colors";
import { withTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
const { Text, Title } = Typography;
class ModalTransactionValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Modal
          title={
            <Title level={4} style={{ color: COLOR.PRIMARY_TEXT }}>
              {this.props.t("twofactor.confirmChanges")}
            </Title>
          }
          footer={null}
          className="container-modal-security"
          bodyStyle={{
            background: COLOR.PRIMARY,
            color: COLOR.PRIMARY_TEXT,
            textAlign: "center",
            padding: "24px 0px 24px 0px",
          }}
          maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
          open={this.props.modalActivate}
          onOk={() => this.props.openModal()}
          onCancel={() => this.props.closeModal()}
          centered={true}
          closeIcon={<CloseOutlined style={{ color: COLOR.GRAY }} />}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <Row
              gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}
              justify="center"
            >
              <Space direction="vertical">
                <Text
                  style={{
                    color: COLOR.PRIMARY_TEXT,
                    fontSize: 16,
                    padding: "0px 24px",
                  }}
                >
                  Are you sure you want to{" "}
                  {this.props.transactionValidation ? "disable" : "enable"}{" "}
                  approval for each withdrawal?
                </Text>
              </Space>
            </Row>
            <br />
            <Row gutter={[32, { xs: 8, sm: 16, md: 24, lg: 32 }]} justify="end">
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
              >
                <Button
                  block
                  size="large"
                  className="btn-previous-security"
                  onClick={() => this.props.closeModal()}
                >
                  {this.props.t("twofactor.buttonCancel")}
                </Button>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
              >
                <Button
                  block
                  size="large"
                  type="primary"
                  loading={this.props.loading}
                  onClick={() => this.props.activateValidation()}
                  style={{
                    height: 60,
                    fontSize: 18,
                    fontWeight: 600,
                    borderRadius: "5px",
                  }}
                >
                  {this.props.t("twofactor.buttonConfirm")}
                </Button>
              </Col>
            </Row>
          </Space>
        </Modal>
      </div>
    );
  }
}

const ModalTransactionValidationTranslated = withTranslation()(
  ModalTransactionValidation
);

export default ModalTransactionValidationTranslated;
