import React, { Component } from "react";
import COLOR from "../../const/colors";
import "./beneficiaries.css";
import { withTranslation } from "react-i18next";
import { Modal } from "antd";
import { connect } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import { Typography, Row, Col, Button, Space, message, Divider } from "antd";
import { deleteBeneficiary } from "../../redux/actions/beneficiariesActions";
import { currentToken } from "../../redux/actions/tokenActions";
const { Text } = Typography;
class ModalRemoveBeneficiary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: "1",
      fiat: true,
      loading: false,
    };
  }
  async removeBeneficiary() {
    try {
      this.setState({ loading: true });
      let id = this.props.beneficiaryRemove.id;
      let userType = this.props.dataUser.data?.data?.user?.type;
      await this.props.deleteBeneficiary(
        this.props.token.clientId,
        this.props.token.current,
        id
      );
      let resp = this.props.removeResp;
      if (resp.headers !== undefined) {
        let newAccessToken = resp.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (resp.status === 200) {
        message.success(
          userType.includes("LITE")
            ? this.props.t("beneficiaries.messages.successRemovedAccount")
            : this.props.t("beneficiaries.messages.successRemoved")
        );
        this.setState({ loading: false });
        this.props.closeModalRemoveBeneficiary();
        let currency = this.props.beneficiaryRemove.currency;
        let network =
          this.props.beneficiary.network !== undefined
            ? this.props.beneficiary.network
            : "";
        this.props.getBeneficiaries(currency, network);
      } else if (resp.status === 400) {
        this.setState({ loading: false });
        message.error(
          userType.includes("LITE")
            ? this.props.t("beneficiaries.messages.invalidAccount")
            : this.props.t("beneficiaries.messages.invalidBeneficiary"),
          10
        );
      } else if (resp.status === 403) {
        this.setState({ loading: false });
        message.error(this.props.t("beneficiaries.messages.accessError"));
      } else if (
        resp.status === 401 ||
        resp.status === 422 ||
        resp.status === 404 ||
        resp.status === 500
      ) {
        this.setState({ loading: false });
        message.error(this.props.t("beneficiaries.messages.errorServer"));
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }

  render() {
    let title = "";
    if (this.props.currencySelect.type === "FIAT") {
      title = this.props.t("beneficiaries.removeButtonAccount");
    } else {
      title = this.props.t("beneficiaries.titleRemove", {
        name: this.props.currencySelect.name,
      });
    }
    return (
      <Modal
        title={
          <Text
            style={{
              color: COLOR.PRIMARY_TEXT,
              fontSize: 18,
              fontWeight: 400,
              textAlign: "left",
            }}
          >
            {title}
          </Text>
        }
        closable={true}
        footer={null}
        width={this.props.currencySelect.type === "FIAT" ? 500 : 600}
        bodyStyle={{
          margin: 0,
          padding: "20px 4px",
          background: "#00001e",
          width: "100%",
          top: 80,
          textAlign: "left",
        }}
        maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
        closeIcon={<CloseOutlined style={{ color: COLOR.PRIMARY_TEXT }} />}
        open={this.props.modalRemoveBeneficiary}
        onOk={() => this.props.openModalRemoveBeneficiary()}
        onCancel={() => this.props.closeModalRemoveBeneficiary()}
      >
        <Row
          gutter={[48, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}
          style={{ textAlign: "left" }}
        >
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            style={{ textAlign: "left" }}
          >
            <Text
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 18,
                fontWeight: 400,
                textAlign: "left",
              }}
            >
              {this.props.currencySelect.type === "FIAT"
                ? this.props.t("beneficiaries.confirmRemoveFiat")
                : this.props.t("beneficiaries.confirmRemoveCrypto")}
            </Text>
            <br />
            <br />
            {this.props.currencySelect.type === "FIAT" && (
              <div>
                {this.props.currencySelect.value === "EUR" && (
                  <div style={{ width: "100%" }}>
                    <Text
                      style={{
                        color: COLOR.SECONDARY_TEXT,
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                        marginRight: 5,
                      }}
                    >
                      {this.props.t("beneficiaries.description")}:
                    </Text>
                    <Text
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                      }}
                    >
                      {this.props.beneficiary.description}
                    </Text>
                    <br />
                    <Text
                      style={{
                        color: COLOR.SECONDARY_TEXT,
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                        marginRight: 5,
                      }}
                    >
                      {this.props.t("beneficiaries.accountHolder")}:
                    </Text>
                    <Text
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                      }}
                    >
                      {this.props.beneficiary.accountHolder}
                    </Text>
                    <br />
                    <Text
                      style={{
                        color: COLOR.SECONDARY_TEXT,
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                        marginRight: 5,
                      }}
                    >
                      IBAN:
                    </Text>
                    <Text
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                      }}
                    >
                      {this.props.beneficiary.iban}
                    </Text>
                    <br />
                    <Text
                      style={{
                        color: COLOR.SECONDARY_TEXT,
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                        marginRight: 5,
                      }}
                    >
                      BIC:
                    </Text>
                    <Text
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                      }}
                    >
                      {this.props.beneficiary.bic}
                    </Text>
                  </div>
                )}
                {this.props.currencySelect.value === "GBP" && (
                  <div style={{ width: "100%" }}>
                    <Text
                      style={{
                        color: COLOR.SECONDARY_TEXT,
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                        marginRight: 5,
                      }}
                    >
                      {this.props.t("beneficiaries.description")}:
                    </Text>
                    <Text
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                      }}
                    >
                      {this.props.beneficiary.description}
                    </Text>
                    <br />
                    <Text
                      style={{
                        color: COLOR.SECONDARY_TEXT,
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                        marginRight: 5,
                      }}
                    >
                      {this.props.t("beneficiaries.accountHolder")}:
                    </Text>
                    <Text
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                      }}
                    >
                      {this.props.beneficiary.accountHolder}
                    </Text>
                    <br />
                    <Text
                      style={{
                        color: COLOR.SECONDARY_TEXT,
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                        marginRight: 5,
                      }}
                    >
                      Sort code:
                    </Text>
                    <Text
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                      }}
                    >
                      {this.props.beneficiary.sortCode}
                    </Text>
                    <br />
                    <Text
                      style={{
                        color: COLOR.SECONDARY_TEXT,
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                        marginRight: 5,
                      }}
                    >
                      {this.props.t("beneficiaries.accountNumber")}:
                    </Text>
                    <Text
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                      }}
                    >
                      {this.props.beneficiary.account}
                    </Text>
                  </div>
                )}
              </div>
            )}
            {this.props.currencySelect.type === "CRYPTO" && (
              <Space direction="vertical" size={0}>
                <div style={{ display: "inline-flex" }}>
                  <Text
                    style={{
                      color: COLOR.SECONDARY_TEXT,
                      fontSize: 16,
                      fontWeight: 400,
                      textAlign: "left",
                      marginRight: 5,
                    }}
                  >
                    {this.props.t("beneficiaries.description")}:
                  </Text>
                  <Text
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      fontSize: 16,
                      fontWeight: 400,
                      textAlign: "left",
                    }}
                  >
                    {this.props.beneficiaryRemove.description}
                  </Text>
                </div>
                {this.props.beneficiary.networks !== undefined && (
                  <>
                    {this.props.beneficiary.networks.length > 1 ? (
                      <>
                        {this.props.beneficiary.networks.map((field, i) => {
                          return (
                            <>
                              <Divider
                                style={{
                                  background: COLOR.SECONDARY_TEXT,
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              />
                              <div style={{ display: "inline-flex" }}>
                                <Text
                                  style={{
                                    color: COLOR.SECONDARY_TEXT,
                                    fontSize: 16,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    marginRight: 5,
                                    whiteSpace: "nowrap",
                                  }}
                                  id={field.address + "_" + i}
                                >
                                  {this.props.t(
                                    "beneficiaries.withdrawalAddress"
                                  )}
                                  :
                                </Text>
                                <Text
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    fontSize: 16,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    overflowWrap: "anywhere",
                                  }}
                                >
                                  {field.address}
                                </Text>
                              </div>
                              <div style={{ display: "inline-flex" }}>
                                <Text
                                  style={{
                                    color: COLOR.SECONDARY_TEXT,
                                    fontSize: 16,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    marginRight: 5,
                                    whiteSpace: "nowrap",
                                  }}
                                  id={field.network + "_" + i}
                                >
                                  {this.props.t("beneficiaries.network")}:
                                </Text>
                                <Text
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    fontSize: 16,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    overflowWrap: "anywhere",
                                  }}
                                >
                                  {field.network}
                                </Text>
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <div style={{ display: "inline-flex" }}>
                        <Text
                          style={{
                            color: COLOR.SECONDARY_TEXT,
                            fontSize: 16,
                            fontWeight: 400,
                            textAlign: "left",
                            marginRight: 5,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {this.props.t("beneficiaries.withdrawalAddress")}:
                        </Text>
                        <Text
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            fontSize: 16,
                            fontWeight: 400,
                            textAlign: "left",
                            overflowWrap: "anywhere",
                          }}
                        >
                          {this.props.beneficiary.address === undefined
                            ? this.props.beneficiary.networks[0].address
                            : this.props.beneficiary.address}
                        </Text>
                      </div>
                    )}
                  </>
                )}
                {this.props.beneficiary.network !== undefined && (
                  <div style={{ display: "inline-flex" }}>
                    <Text
                      style={{
                        color: COLOR.SECONDARY_TEXT,
                        fontSize: 16,
                        fontWeight: 400,
                        textAlign: "left",
                        marginRight: 5,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {this.props.t("beneficiaries.withdrawalAddress")}:
                    </Text>
                    <Text
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        fontSize: 16,
                        fontWeight: 400,
                        textAlign: "left",
                        overflowWrap: "anywhere",
                      }}
                    >
                      {this.props.beneficiary.address === undefined
                        ? this.props.beneficiary.networks[0].address
                        : this.props.beneficiary.address}
                    </Text>
                  </div>
                )}
              </Space>
            )}
          </Col>
        </Row>
        <br />
        <Row gutter={[48, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}>
          <Col
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xl={{ span: 12, offset: 0 }}
            style={{ textAlign: "left" }}
          >
            <Button
              block
              className="btn-cancel-remove"
              style={{
                height: "60px",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              onClick={() => this.props.closeModalRemoveBeneficiary()}
            >
              {this.props.t("beneficiaries.buttonCancel")}
            </Button>
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xl={{ span: 12, offset: 0 }}
            style={{ textAlign: "left" }}
          >
            <Button
              block
              className="button-remove"
              style={{
                height: "60px",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              loading={this.state.loading}
              onClick={() => this.removeBeneficiary()}
            >
              {this.props.t("beneficiaries.remove")}
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    beneficiariesList: state.beneficiaries.resultBeneficiaries,
    removeResp: state.beneficiaries.beneficiaryRemoved,
    token: state.tokenAccess,
    dataUser: state.authUser.userData,
  };
};
const mapDispatchToProps = {
  deleteBeneficiary,
  currentToken,
};
const ModalRemoveBeneficiaryTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ModalRemoveBeneficiary)
);

export default ModalRemoveBeneficiaryTranslated;
