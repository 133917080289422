import {
  GET_RATE,
  SELL_ASSET,
  BUY_ASSET,
  EXCHANGE_ASSET,
  EXCHANGE_FX,
  GET_GENERAL_RATE,
  GET_MARKET,
  GET_ORDERS,
  GET_ORDERS_FILTER,
  CURRENT_FILTERS_ORDERS,
  CURRENCY_FROM,
  LIMITS,
  GET_PAIRS_SUPPORTED,
  GET_SPREAD,
  QUOTE_CURRENCY,
} from "../actions/exchangeActions";
const initialState = {
  resultRate: "",
  resultSell: "",
  resultBuy: "",
  resultExchange: "",
  resultFx: "",
  resultGeneralRate: "",
  resultOrders: "",
  resultOrdersFilter: "",
  market: "",
  currentFilterOrder: "",
  currentCurrencyFrom: "",
  currentLimits: "",
  pairs: "",
  spread: "",
  quoteCurrency: "",
};
export function exchange(state = initialState, action) {
  switch (action.type) {
    case GET_RATE:
      return Object.assign({}, state, { resultRate: action.payload });
    case SELL_ASSET:
      return Object.assign({}, state, { resultSell: action.payload });
    case BUY_ASSET:
      return Object.assign({}, state, { resultBuy: action.payload });
    case EXCHANGE_ASSET:
      return Object.assign({}, state, { resultExchange: action.payload });
    case EXCHANGE_FX:
      return Object.assign({}, state, { resultFx: action.payload });
    case GET_GENERAL_RATE:
      return Object.assign({}, state, { resultGeneralRate: action.payload });
    case GET_MARKET:
      return Object.assign({}, state, { market: action.payload });
    case GET_ORDERS:
      return Object.assign({}, state, { resultOrders: action.payload });
    case CURRENT_FILTERS_ORDERS:
      return Object.assign({}, state, { currentFilterOrder: action.payload });
    case GET_ORDERS_FILTER:
      return Object.assign({}, state, { resultOrdersFilter: action.payload });
    case CURRENCY_FROM:
      return Object.assign({}, state, { currentCurrencyFrom: action.payload });
    case LIMITS:
      return Object.assign({}, state, { currentLimits: action.payload });
    case GET_PAIRS_SUPPORTED:
      return Object.assign({}, state, { pairs: action.payload });
    case GET_SPREAD:
      return Object.assign({}, state, { spread: action.payload });
    case QUOTE_CURRENCY:
      return Object.assign({}, state, { quoteCurrency: action.payload });
    default:
      return state;
  }
}
