import { GET_AUDIT_LOGS } from "../actions/auditActions";
const initialState = {
    respAuditLogs: "",
  };
  
  export function audit(state = initialState, action) {
    switch (action.type) {
      case GET_AUDIT_LOGS:
        return Object.assign({}, state, { respAuditLogs: action.payload });
      default:
        return state;
    }
  }