import React, { Component } from "react";
import "./nav.css";
import COLOR from "../../const/colors";
import {
  Layout,
  Typography,
  Button,
  Space,
  Avatar,
  Row,
  Col,
  Modal,
  List,
  Divider,
  Select,
  Image,
  Form,
  message,
} from "antd";
import { UserOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { isMobileOnly } from "react-device-detect";
import { withTranslation } from "react-i18next";
import {
  resetStore,
  logoutUser,
  authUser,
} from "../../redux/actions/userActions";
import { store } from "../../redux/store";
import { connect } from "react-redux";
import {
  siderNavigate,
  selectedAsset,
  openModal,
  openModalSend,
  infoBeneficiaryToSend,
} from "../../redux/actions/navigateActions";
import { signinSuccess } from "../../redux/actions/userActions";
import { currentToken } from "../../redux/actions/tokenActions";
import { getLimits, getAccounts } from "../../redux/actions/bankingActions";
import {
  getPreferences,
  updatePreferences,
} from "../../redux/actions/preferencesActions";
import { Link, Redirect } from "react-router-dom";
import ModalSendOrReceive from "../../common/modalSendOrReceive/modalSendOrReceive";
import ModalTrade from "../../common/modalTrade/modalTrade";
import CAN from "../../casl/can";
import { asset } from "../../common/assets";
const { Title, Text } = Typography;
const { Header } = Layout;
const { Option } = Select;
const localCurrencyList = ["GBP", "EUR", "USD"];
class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      current: "",
      modalOptionUser: false,
      dataUser: {},
      modalSendOrReceive: false,
      modalTrade: false,
      limitByCurrency: "",
      listCurrency: [],
      localCurrency: "",
    };
  }
  componentDidMount() {
    this.props.openModalSend(false);
    let data = this.props.dataUser;
    this.setState({ dataUser: data });
    this.getLimits();
    this.loadAssets();
    this.getPreferences();
  }
  componentDidUpdate(prevProps) {
    if (this.props.result !== prevProps.result) {
      if (this.props.result.modalSend === true) {
        this.setState({ modalSendOrReceive: true });
      }
    }
  }
  loadAssets() {
    let arrayAssets = [];
    for (let value of localCurrencyList) {
      let assetData = {};

      let currency = asset.assets.find((element) => value === element.value);
      if (currency !== undefined) {
        assetData.icon = currency.icon;
        assetData.name = currency.name;
        assetData.value = currency.value;
      }
      arrayAssets.push(assetData);
    }

    this.setState({
      listCurrency: arrayAssets,
    });
  }
  async getPreferences() {
    try {
      this.setState({
        loading: true,
      });
      await this.props.getPreferences(
        this.props.token.clientId,
        this.props.token.current
      );
      let response = this.props.responsePreferences;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        this.setState({
          localCurrency: response.data.data?.preferences?.local_currency,
          loading: false,
        });
      } else {
        this.setState({
          localCurrency: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  async updatePreferences(currency) {
    try {
      this.setState({ loading: true });
      let body = {
        local_currency: currency,
      };
      await this.props.updatePreferences(
        this.props.token.clientId,
        this.props.token.current,
        body
      );
      let response = this.props.updatedPreferences;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        message.success(
          this.props.t("preferences.messages.successLocalCurrency")
        );
        this.setState({ loading: false });
        this.getPreferences();
        this.getAccounts();
      } else if (response.status === 400) {
        this.setState({ loading: false });
        if (response.data.error_details.payload_error !== undefined) {
          let messageError = response.data.error_details.payload_error;
          message.error(
            this.props.t("preferences.messages." + messageError),
            10
          );
        }
        if (response.data.error_details.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;
          message.error(
            this.props.t("preferences.messages." + messageError),
            10
          );
        }
      } else {
        this.setState({ loading: false });
        message.error(this.props.t("preferences.messages.errorServer"), 10);
      }
    } catch (error) {
      this.setState({ loading: false });
      message.error(this.props.t("preferences.messages.errorServer"), 10);
    }
  }
  async getAccounts() {
    try {
      await this.props.getAccounts(
        this.props.token.clientId,
        this.props.token.current
      );
      let response = this.props.accounts;
      let newAccessToken = "";
      if (response.headers !== undefined) {
        newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
    } catch (error) {}
  }
  openModalUser() {
    this.setState({ modalOptionUser: true });
  }
  closeModalUser() {
    this.setState({ modalOptionUser: false });
  }
  openModalSendOrReceive() {
    this.props.openModalSend(true);
    this.setState({ modalSendOrReceive: true });
  }
  closeModalSendOrReceive() {
    this.props.openModalSend(false);
    this.props.infoBeneficiaryToSend("");
    this.setState({ modalSendOrReceive: false });
  }
  openModalTrade() {
    this.props.openModal(true);
    this.setState({ modalTrade: true });
  }
  closeModalTrade() {
    this.props.openModal(false);
    this.setState({ modalTrade: false });
  }
  navigateSettings() {
    this.closeModalUser();
    this.props.siderNavigate("settings");
  }
  async getUser() {
    try {
      await this.props.signinSuccess(
        this.props.token.clientId,
        this.props.token.current
      );
      let response = this.props.dataUser.userData;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (
        response.status === 401 ||
        response.status === 422 ||
        response.status === 403 ||
        response.status === 404
      ) {
        this.logout();
      }
    } catch (error) {
      this.logout();
    }
  }

  async logout() {
    try {
      let clientId = this.props.token.clientId;
      let tokenAccess = this.props.token.current;
      await this.props.logoutUser(clientId, tokenAccess);
      let response = this.props.dataUser.logout;
      if (response.status === 200) {
        this.props.resetStore();
        this.setState({ redirect: true });
      } else {
        this.props.resetStore();
        this.setState({ redirect: true });
      }
    } catch (error) {
      this.props.resetStore();
      this.setState({ redirect: true });
    }
  }
  async getLimits() {
    try {
      await this.props.getLimits(
        this.props.token.clientId,
        this.props.token.current
      );
      let resp = this.props.respLimits;
      if (resp.headers !== undefined) {
        let newAccessToken = resp.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (resp.status === 200) {
      } else if (
        resp.status === 401 ||
        resp.status === 422 ||
        resp.status === 403 ||
        resp.status === 404 ||
        resp.status === 500
      ) {
      }
    } catch (error) {}
  }

  handleLocalCurrency(value) {
    if (this.state.localCurrency !== value) {
      this.updatePreferences(value);
    }
    this.setState({ localCurrency: value });
  }
  render() {
    let userInfo = "";
    let username = "";
    let avatar = "";
    let email = "";
    let role = "";
    let roleInitial = "";
    let businessName = "";
    if (this.state.redirect) {
      return <Redirect to="/signin" />;
    }
    if (this.props.dataUser.userData.status !== undefined) {
      if (
        this.props.dataUser.userData.status !== 401 &&
        this.props.dataUser.userData.status !== 422 &&
        this.props.dataUser.userData.status !== 403 &&
        this.props.dataUser.userData.status !== 404
      ) {
        userInfo =
          this.props.dataUser.userData.data !== undefined
            ? this.props.dataUser.userData.data.data
            : "";
        avatar =
          userInfo.user.profile?.avatar !== undefined
            ? userInfo.user.profile.avatar
            : "";
        email = userInfo.user.email;
        roleInitial = userInfo.user?.role.toLowerCase();
        role = roleInitial.charAt(0).toUpperCase() + roleInitial.slice(1);
        if (userInfo.user.business_name !== undefined) {
          businessName = userInfo.user.business_name;
        }
        if (userInfo.user.profile !== undefined) {
          let lastName =
            userInfo.user.profile.last_name === undefined
              ? ""
              : userInfo.user.profile.last_name;
          username = userInfo.user.profile.name + " " + lastName;
        } else {
          username =
            userInfo.user.name !== undefined &&
            userInfo.user.last_name !== undefined
              ? userInfo.user.name + " " + userInfo.user.last_name
              : userInfo.user.email;
        }

        if (businessName.length > 6 && window.screen.width <= 425) {
          businessName = businessName.slice(0, 5);
        }

        if (username.length > 6 && window.screen.width <= 425) {
          username = username.slice(0, 5);
        }
      } else {
        return <Redirect to="/signin" />;
      }
    }
    return (
      <div>
        <Header
          style={{
            background: COLOR.PRIMARY,
            padding: 0,
            position: "fixed",
            zIndex: 2,
            width: "100%",
            maxWidth: "100%",
          }}
        >
          <Row gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8 }]}>
            <Col
              xs={{
                span: window.screen.width <= 320 ? 6 : 4,
                offset: window.screen.width <= 320 ? 3 : 2,
              }}
              sm={{ span: 4, offset: 2 }}
              md={{ span: 7, offset: 2 }}
              lg={{ span: 9, offset: 1 }}
              xl={{ span: 10, offset: 1 }}
            >
              <Space align="center" direction="vertical">
                <Title
                  level={isMobileOnly ? 5 : 3}
                  style={{
                    color: COLOR.PRIMARY_TEXT,
                    lineHeight: "none",
                    marginBottom: "0em",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {this.props.result.result === "accounts" &&
                    this.props.result.asset !== "" && (
                      <Link to="/account">
                        <Avatar
                          size="default"
                          src={
                            <ArrowLeftOutlined
                              style={{ stroke: "#fff", strokeWidth: "50px" }}
                              onClick={() => this.props.selectedAsset("")}
                            />
                          }
                          className="circle-icon-back"
                        />
                      </Link>
                    )}
                  {this.props.result.result === "accounts" &&
                  this.props.result.asset !== "" &&
                  isMobileOnly
                    ? ""
                    : this.props.t(
                        "menu." +
                          store.getState().siderNavigate.result.toLowerCase()
                      )}
                </Title>
              </Space>
            </Col>
            <Col
              xs={{ span: window.screen.width <= 320 ? 6 : 8, offset: 0 }}
              sm={{ span: 8, offset: 0 }}
              md={{ span: 6, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              xl={{ span: 6, offset: 0 }}
              style={{
                justifyContent: "flex-end",
                display: "flex",
                marginRight: isMobileOnly ? 2 : 10,
              }}
            >
              <Space>
                {CAN("EXCHANGE", "SELL") &&
                  CAN("EXCHANGE", "BUY") &&
                  CAN("EXCHANGE", "SWAP") &&
                  CAN("EXCHANGE", "FX") && (
                    <>
                      {window.screen.width > 425 && (
                        <Button
                          size="large"
                          onClick={() => this.openModalTrade()}
                          style={{ borderColor: COLOR.SECONDARY }}
                        >
                          {this.props.t("nav.trade")}
                        </Button>
                      )}
                    </>
                  )}
                <Button
                  size="large"
                  onClick={() => this.openModalSendOrReceive()}
                  style={{ padding: isMobileOnly ? "4px 5px" : "" }}
                  type="primary"
                >
                  {userInfo.user?.role === "VIEWER"
                    ? this.props.t("nav.receive")
                    : this.props.t("nav.sendReceive")}
                </Button>
              </Space>
            </Col>

            <Col
              xs={{ span: window.screen.width <= 320 ? 6 : 8, offset: 0 }}
              sm={{ span: 8, offset: 0 }}
              md={{ span: 7, offset: 0 }}
              lg={{ span: 7, offset: 0 }}
              xl={{ span: 6, offset: 0 }}
            >
              <Divider
                style={{ background: COLOR.PRIMARY_TEXT, height: 45 }}
                type="vertical"
              />
              <Space>
                <div
                  style={{ marginLeft: isMobileOnly ? 5 : 15 }}
                  onClick={() => this.openModalUser()}
                >
                  <Avatar src={avatar} icon={<UserOutlined />} />

                  <Button type="link" className="link-username" size="large">
                    <Text
                      ellipsis={true}
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        marginBottom: 0,
                        maxWidth: isMobileOnly ? 50 : 200,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {window.screen.width > 320 &&
                      userInfo.user?.role === "MASTER"
                        ? username
                        : businessName}
                    </Text>
                  </Button>
                </div>
              </Space>
            </Col>
          </Row>
        </Header>

        <Modal
          closable={false}
          footer={null}
          width={350}
          className="modal-position"
          bodyStyle={{ background: COLOR.PRIMARY }}
          maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
          open={this.state.modalOptionUser}
          onOk={() => this.openModalUser()}
          onCancel={() => this.closeModalUser()}
          centered={true}
        >
          <Space direction="vertical" className="modal-user">
            {userInfo.user?.role !== "MASTER" && (
              <Title
                level={4}
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  marginBottom: 0,
                }}
              >
                {userInfo.user?.business_name !== undefined &&
                  userInfo.user?.business_name}
              </Title>
            )}
            <Avatar src={avatar} size={65} icon={<UserOutlined />} />
            <Title
              level={4}
              style={{
                color: COLOR.PRIMARY_TEXT,
                marginBottom: 0,
              }}
            >
              {username}
            </Title>
            <Title
              level={5}
              style={{
                color: COLOR.SECONDARY_TEXT,
                marginTop: -10,
              }}
            >
              {email}
            </Title>
            {userInfo.user?.role !== "MASTER" && (
              <Title
                level={5}
                style={{
                  color: COLOR.SECONDARY_TEXT,
                  marginTop: -10,
                }}
              >
                {role}
              </Title>
            )}
          </Space>

          <List
            itemLayout="horizontal"
            style={{
              marginTop: 20,
            }}
          >
            <List.Item className="item-option">
              <List.Item.Meta
                title={
                  <Button
                    type="link"
                    className="link-settings"
                    onClick={() => this.navigateSettings()}
                  >
                    <Link to="/settings">
                      {" "}
                      {this.props.t("menuUsername.settings")}
                    </Link>
                  </Button>
                }
              />
            </List.Item>
            <List.Item className="item-option">
              <List.Item.Meta
                title={
                  <Button type="link" className="link-settings">
                    {this.props.t("menuUsername.help")}
                  </Button>
                }
              />
            </List.Item>
            <List.Item className="item-option">
              <List.Item.Meta
                title={
                  <Form
                    name="preferences"
                    labelAlign="left"
                    labelCol={{ span: 22, offset: 1 }}
                    wrapperCol={{ span: 22, offset: 1 }}
                    layout="vertical"
                  >
                    <Form.Item
                      label={
                        <Text
                          style={{
                            color: "#fff",
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                        >
                          {this.props.t("preferences.currency")}
                        </Text>
                      }
                    >
                      <div
                        id="select-option-setting"
                        className="ant-select-selector ant-select"
                        style={{ width: "100%" }}
                      >
                        <Select
                          showSearch
                          filterOption={(input, option) => {
                            let value = option.key;
                            return (
                              value
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                          style={{ width: "100%" }}
                          size="large"
                          value={this.state.localCurrency}
                          onChange={(value) => this.handleLocalCurrency(value)}
                          loading={this.state.loading}
                        >
                          {this.state.listCurrency.map((item, i) => {
                            return (
                              <Option
                                value={item.value}
                                key={item.value + "_" + item.name}
                              >
                                <div
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Image
                                    style={{ paddingRight: 5 }}
                                    src={item.icon}
                                    width={30}
                                    preview={false}
                                  />
                                  {item.name + " (" + item.value + ")"}
                                </div>
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </Form.Item>
                  </Form>
                }
              />
            </List.Item>
            <List.Item className="item-option" onClick={() => this.logout()}>
              <List.Item.Meta
                title={
                  <Button type="link" className="link-signout">
                    {this.props.t("menuUsername.signOut")}
                  </Button>
                }
              />
            </List.Item>
          </List>
        </Modal>
        {this.state.modalSendOrReceive && (
          <ModalSendOrReceive
            modalSendOrReceive={this.state.modalSendOrReceive}
            open={this.openModalSendOrReceive.bind(this)}
            close={this.closeModalSendOrReceive.bind(this)}
          />
        )}
        {this.state.modalTrade && (
          <ModalTrade
            modalTrade={this.state.modalTrade}
            openModalTrade={this.openModalTrade.bind(this)}
            closeModalTrade={this.closeModalTrade.bind(this)}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    store: state,
    result: state.siderNavigate,
    dataUser: state.authUser,
    token: state.tokenAccess,
    respLimits: state.banking.limits,
    responsePreferences: state.preferences.resultPreferences,
    updatedPreferences: state.preferences.resultUpdate,
    accounts: state.banking.accounts,
  };
};

const mapDispatchToProps = {
  resetStore,
  siderNavigate,
  logoutUser,
  authUser,
  signinSuccess,
  currentToken,
  selectedAsset,
  getLimits,
  openModal,
  getPreferences,
  updatePreferences,
  getAccounts,
  infoBeneficiaryToSend,
  openModalSend
};

const NavTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Nav)
);

export default NavTranslated;
