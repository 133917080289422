import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./modalTerms.css";
import { Modal, Button, Typography, Space, Checkbox, Row } from "antd";
const { Text } = Typography;

class ModalTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetSelected: "",
    };
  }

  render() {
    return (
      <Modal
        closable={false}
        footer={null}
        width={400}
        bodyStyle={{
          margin: 0,
          padding: "20px",
          background: "#00001e",
          color: "#fff",
          width: "100%",
          textAlign: "center",
          top: 80,
        }}
        open={this.props.showModal}
        maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
        onCancel={() => this.props.onCancel()}
      >
        <Space
          direction="vertical"
          align="center"
          size="large"
          style={{ marginTop: 10 }}
        >
          <Checkbox onChange={(e) => this.props.onChangeTerms(e)}>
            <Row
              // gutter={[48, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}
              style={{ textAlign: "left", paddingLeft: 10 }}
            >
              <Text style={{ color: "#fff", fontWeight: 300 }}>
                {this.props.t("signin.terms")}

                <a
                  href={"https://assets.damex.io/direct-t&cs"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text className="link-terms">
                    {this.props.t("signin.terms1")}
                  </Text>
                </a>
              </Text>
            </Row>
          </Checkbox>
          <Button
            size="large"
            type="primary"
            style={{ fontWeight: "bold", fontSize: 16 }}
            disabled={this.props.buttonAgree}
            loading={this.props.loading}
            onClick={() => this.props.acceptTerms()}
          >
            {this.props.t("signin.buttonAgree")}
          </Button>
        </Space>
      </Modal>
    );
  }
}
const ModalTermsTranslated = withTranslation()(ModalTerms);

export default ModalTermsTranslated;
