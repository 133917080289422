import fb from "firebase/compat/app";
//import "firebase/compat/messaging";
import { initializeApp } from "firebase/app";
//import { getMessaging } from "firebase/messaging/sw";
//import { onBackgroundMessage } from "firebase/messaging/sw";
var firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
// Initialize Firebase
export const firebase = !fb.apps.length
  ? initializeApp(firebaseConfig)
  : fb.app();
//firebase.initializeApp(firebaseConfig);
//firebase.analytics();
