import React, { Component } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import { store } from "../../redux/store";
import source from "../../fonts/sofia-pro/Sofia Pro Light Az.otf";
import source1 from "../../fonts/sofia-pro/Sofia Pro Bold Az.otf";
import logo from "../../images/logoDam.png";
import { withTranslation } from "react-i18next";
// Register font
Font.register({ family: "sofia-pro", src: source });
Font.register({ family: "sofia-pro-bold", src: source1 });
// Create styles
const styles = StyleSheet.create({
  body: {
    padding: 20,
    marginTop: 10,
    fontFamily: "sofia-pro",
  },
  table: {
    display: "table",
    width: "auto",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol1Header: {
    width: "40%",
    borderBottomColor: "#A8AAAC",
    borderBottomWidth: 1,
    marginRight: 10,
  },
  tableColHeaderID: {
    width: "15%",
    borderBottomColor: "#A8AAAC",
    borderBottomWidth: 1,
  },
  tableColHeader: {
    width: "9%",
    borderBottomColor: "#A8AAAC",
    borderBottomWidth: 1,
  },
  tableDateHeader: {
    width: "23%",
    borderBottomColor: "#A8AAAC",
    borderBottomWidth: 1,
  },
  tableCostumerHeader: {
    width: "25%",
    borderBottomColor: "#A8AAAC",
    borderBottomWidth: 1,
  },
  tableCol1: {
    width: "40%",
    marginRight: 15,
  },
  tableColID: {
    width: "15%",
    flexDirection: "row",
    flexWrap: "wrap",
    display: "flex",
    wordBreak: "break-all",
  },
  tableCol: {
    width: "9%",
  },
  tableDate: {
    width: "23%",
  },
  tableCostumer: {
    width: "25%",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 8,
    fontFamily: "sofia-pro-bold",
  },
  tableCell: {
    margin: 5,
    fontSize: 8,
    fontWeight: "normal",
  },
  tableCellID: {
    marginLeft: 2,
    marginTop: 5,
    marginBottom: 5,
    marginRight: 8,
    fontSize: 8,
    fontWeight: "normal",
  },
  space: { margin: 20 },
  image: {
    width: 50,
    marginBottom: 10,
  },
  colSource: {
    width: "100%",
    textAlign: "left",
    flexDirection: "column",
  },
  rowSource: {
    marginLeft: 15,
    textAlign: "left",
    flexDirection: "row",
  },
});
class PDFHistory extends Component {
  render() {
    let userInfo = store.getState().authUser.userData.data.data.user.profile;
    let nameUser = userInfo.name;
    let lastName = userInfo.last_name !== undefined ? userInfo.last_name : "";
    let username = store.getState().authUser.userData.data.data.user.email;
    return (
      <Document>
        <Page size="A4" style={styles.body}>
          <View>
            <View>
              <Image style={styles.image} src={logo} />
            </View>

            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>
                    {" "}
                    Transaction report for {nameUser + lastName}
                  </Text>
                </View>
                <View style={styles.tableDateHeader}>
                  <Text style={styles.tableCellHeader}>Date range</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Filter</Text>
                </View>
                <View style={styles.tableCostumerHeader}>
                  <Text style={styles.tableCellHeader}>Customer</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>
                    You can use this transaction report to inform your likely
                    tax obligations.{" "}
                  </Text>
                </View>
                <View style={styles.tableDate}>
                  <Text style={styles.tableCell}>
                    From: {this.props.dateStart}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    Type: {this.props.type === null ? "ALL" : this.props.type}
                  </Text>
                </View>
                <View style={styles.tableCostumer}>
                  <Text style={styles.tableCell}>{username}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableDate}>
                  <Text style={styles.tableCell}>To: {this.props.dateEnd}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    Asset:{" "}
                    {this.props.currency === null ? "ALL" : this.props.currency}
                  </Text>
                </View>
                <View style={styles.tableCostumer}>
                  <Text style={styles.tableCell}></Text>
                </View>
              </View>
            </View>
            <Text style={styles.space}></Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderID}>
                  <Text style={styles.tableCellHeader}>ID</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Date</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Type</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Currency</Text>
                </View>
                {/* <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Source</Text>
              </View> */}
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Reference</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Price</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Amount</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Fee</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Amount+Fee</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Balance</Text>
                </View>
              </View>

              {this.props.arrayHistory.length !== 0 &&
                this.props.arrayHistory.map((item, i) => {
                  return (
                    <>
                      <View style={styles.tableRow}>
                        <View style={styles.tableColID} break>
                          <Text style={styles.tableCellID}>
                            {item.id_transaction}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{item.date}</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {this.props.t("transactions.type." + item.type)}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{item.currency}</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {item.type === "WITHDRAWAL" ||
                            item.type === "TRANSFER_OUT" ||
                            item.type === "EXCHANGE_OUT"
                              ? item.reference
                              : ""}
                            {(item.type === "REBALANCE_IN" ||
                              item.type === "REBALANCE_OUT") &&
                              item.reference + " " + item.currency}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {item.type === "EXCHANGE_OUT" &&
                              (item.account.type === "FIAT"
                                ? parseFloat(item.exchange_rate).toFixed(8)
                                : parseFloat(item.exchange_rate).toFixed(2))}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {item.type === "WITHDRAWAL" ||
                            item.type === "TRANSFER_OUT" ||
                            item.type === "EXCHANGE_OUT"
                              ? item.amount
                              : item.total_amount}
                          </Text>
                        </View>
                        {item.source?.type !== "EXTERNAL_CIRCLE" &&
                        item.destination?.type !== "EXTERNAL_CIRCLE" ? (
                          <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{item.fees}</Text>
                          </View>
                        ) : (
                          <View style={styles.tableCol}>
                            <Text style={styles.tableCell}> </Text>
                          </View>
                        )}
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {/* {item.amountTotal
                              .toFixed(item.account.type === "FIAT" ? 2 : 8)
                              .replace(
                                /([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,
                                "$1"
                              )} */}
                            {item.amountTotal}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{item.balance}</Text>
                        </View>
                      </View>
                      <View style={styles.tableRow}>
                        <View style={styles.colSource}>
                          {item.type.includes("REBALANCE") && (
                            <>
                              {item.source !== undefined && (
                                <View style={styles.rowSource}>
                                  <Text style={styles.tableCellHeader}>
                                    Source/Destination:
                                  </Text>
                                  <Text style={styles.tableCell}>
                                    {" " + item.source.alias}
                                  </Text>
                                </View>
                              )}
                              {item.destination !== undefined && (
                                <View style={styles.rowSource}>
                                  <Text style={styles.tableCellHeader}>
                                    Source/Destination:
                                  </Text>
                                  <Text style={styles.tableCell}>
                                    {" " + item.destination.alias}
                                  </Text>
                                </View>
                              )}
                            </>
                          )}
                          {(item.type === "DEPOSIT" ||
                            item.type === "TRANSFER_IN") && (
                            <View style={styles.rowSource}>
                              <Text style={styles.tableCell}>
                                <>
                                  {item.source.type !== undefined && (
                                    <>
                                      {item.source.type.includes(
                                        "INTERNAL"
                                      ) && (
                                        <>
                                          <Text style={styles.tableCellHeader}>
                                            Deposit source:
                                          </Text>
                                          {" " + item.source.description}
                                        </>
                                      )}
                                      {item.source.type.includes(
                                        "EXTERNAL"
                                      ) && (
                                        <>
                                          {item.type_currency === "CRYPTO" &&
                                            item.source.address !==
                                              undefined && (
                                              <>
                                                <Text
                                                  style={styles.tableCellHeader}
                                                >
                                                  Deposit source:
                                                </Text>
                                                {" " + item.source.address}
                                              </>
                                            )}
                                          {item.type_currency === "FIAT" && (
                                            <>
                                              {item.currency === "GBP" && (
                                                <>
                                                  <View style={styles.tableCol}>
                                                    <View
                                                      style={styles.tableRow}
                                                    >
                                                      <Text
                                                        style={
                                                          styles.tableCellHeader
                                                        }
                                                      >
                                                        Deposit source:{" "}
                                                      </Text>
                                                      <Text
                                                        style={styles.tableCell}
                                                      >
                                                        Account Holder:{" "}
                                                        {
                                                          item.source
                                                            .account_holder
                                                        }
                                                      </Text>
                                                      <Text>{" / "}</Text>
                                                      <Text
                                                        style={styles.tableCell}
                                                      >
                                                        Account number:{" "}
                                                        {
                                                          item.source
                                                            .account_number
                                                        }
                                                      </Text>
                                                    </View>
                                                    <Text>{" / "}</Text>
                                                    <View
                                                      style={styles.tableRow}
                                                    >
                                                      <Text
                                                        style={styles.tableCell}
                                                      >
                                                        Sort code:{" "}
                                                        {item.source.sort_code}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                </>
                                              )}
                                              {item.currency === "EUR" && (
                                                <>
                                                  <View style={styles.tableCol}>
                                                    <View
                                                      style={styles.tableRow}
                                                    >
                                                      <Text
                                                        style={
                                                          styles.tableCellHeader
                                                        }
                                                      >
                                                        Deposit source:{" "}
                                                      </Text>
                                                      <Text
                                                        style={styles.tableCell}
                                                      >
                                                        Account Holder:{" "}
                                                        {
                                                          item.source
                                                            .account_holder
                                                        }
                                                      </Text>
                                                      <Text>{" / "}</Text>
                                                      <Text
                                                        style={styles.tableCell}
                                                      >
                                                        IBAN: {item.source.iban}
                                                      </Text>
                                                    </View>
                                                    <Text>{" / "}</Text>

                                                    <View
                                                      style={styles.tableRow}
                                                    >
                                                      <Text
                                                        style={styles.tableCell}
                                                      >
                                                        BIC: {item.source.bic}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              </Text>
                            </View>
                          )}
                          {(item.type === "WITHDRAWAL" ||
                            item.type === "TRANSFER_OUT") && (
                            <View style={styles.rowSource}>
                              <Text style={styles.tableCell}>
                                <View style={styles.tableCol}>
                                  {item.beneficiary !== "" && (
                                    <View style={styles.tableRow}>
                                      <Text style={styles.tableCellHeader}>
                                        Beneficiary:{" "}
                                      </Text>
                                      <Text style={styles.tableCell}>
                                        {item.beneficiary}
                                      </Text>
                                    </View>
                                  )}
                                </View>
                              </Text>
                            </View>
                          )}
                          {item.type === "WITHDRAWAL" && (
                            <View style={styles.rowSource}>
                              <Text style={styles.tableCell}>
                                {item.type_currency === "CRYPTO" && (
                                  <View style={styles.tableCol}>
                                    <View style={styles.tableRow}>
                                      <Text style={styles.tableCellHeader}>
                                        Destination:{" "}
                                      </Text>
                                      {item.destination.address !==
                                        undefined && (
                                        <Text style={styles.tableCell}>
                                          {item.destination.address === ""
                                            ? " " + item.destination.description
                                            : " " + item.destination.address}
                                        </Text>
                                      )}
                                      {item.destination.billing_name !==
                                        undefined && (
                                        <Text style={styles.tableCell}>
                                          {" " +
                                            item.destination.billing_name +
                                            " "}
                                        </Text>
                                      )}
                                      {item.destination.type.includes(
                                        "EXTERNAL_CIRCLE"
                                      ) && (
                                        <Text style={styles.tableCell}>
                                          Account number:{" "}
                                          {item.destination.account_number}
                                          {" / "}
                                          Bank name:{" "}
                                          {item.destination.bank_name}
                                          {" / "}
                                          Billing name:{" "}
                                          {item.destination.billing_name}
                                        </Text>
                                      )}
                                    </View>
                                  </View>
                                )}
                                {item.type_currency === "FIAT" && (
                                  <>
                                    {item.currency === "GBP" && (
                                      <View style={styles.tableCol}>
                                        <View style={styles.tableRow}>
                                          <Text style={styles.tableCellHeader}>
                                            Destination:{" "}
                                          </Text>
                                          <Text style={styles.tableCell}>
                                            Account Holder:{" "}
                                            {item.destination.account_holder}
                                            {" / "}
                                            Account number:{" "}
                                            {item.destination.account_number}
                                            {" / "}
                                            Sort code:{" "}
                                            {item.destination.sort_code}
                                          </Text>
                                        </View>
                                      </View>
                                    )}
                                    {item.currency === "EUR" && (
                                      <View style={styles.tableCol}>
                                        <View style={styles.tableRow}>
                                          <Text style={styles.tableCellHeader}>
                                            Destination:{" "}
                                          </Text>
                                          <Text style={styles.tableCell}>
                                            Account Holder:{" "}
                                            {item.destination.account_holder}
                                            {" / "} IBAN:{" "}
                                            {item.destination.iban}
                                            {" / "}
                                            BIC: {item.destination.bic}
                                          </Text>
                                        </View>
                                      </View>
                                    )}
                                  </>
                                )}
                              </Text>
                            </View>
                          )}
                          {item.accountAlias !== undefined && (
                            <>
                              {item.accountAlias !== "" && (
                                <View style={styles.rowSource}>
                                  <Text style={styles.tableCellHeader}>
                                    Account:{" "}
                                  </Text>
                                  <Text style={styles.tableCell}>
                                    {item.accountAlias}
                                  </Text>
                                </View>
                              )}
                            </>
                          )}
                        </View>
                      </View>
                    </>
                  );
                })}
            </View>
          </View>
        </Page>
      </Document>
    );
  }
}
const PDFHistoryTranslated = withTranslation()(PDFHistory);
export default PDFHistoryTranslated;
