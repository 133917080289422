import React, { Component } from "react";
import {
  Button,
  Space,
  Typography,
  Row,
  Col,
  Card,
  Divider,
  Form,
  Select,
  Image,
  Avatar,
} from "antd";
import "./rebalance.css";
import { withTranslation } from "react-i18next";
import COLOR from "../../const/colors";
import { RightOutlined } from "@ant-design/icons";
import SwapVertical from "@2fd/ant-design-icons/lib/SwapVertical";
import { asset } from "../../common/assets";
import { getAccounts } from "../../redux/actions/bankingActions";
import { connect } from "react-redux";
import { isMobileOnly } from "react-device-detect";
import NumberFormat from "react-number-format";
const { Option } = Select;
const { Text } = Typography;
const formRef = React.createRef();
const inputNumber = React.createRef();

class Rebalance extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      assetActiveTo: "",
      assetActiveFrom: "",
      assetSelected: "",
      activeInput: "",
      listAssets: [],
      accounts: [],
      accountSelected: "",
      assetActiveBalance: "",
      labelActiveTo: "",
      listAssetFrom: [],
      listAssetTo: [],
      loading: false,
    };
  }
  componentDidMount() {
    document.getElementById("resize-number-trade").focus();
    this.filterCurrencyAvailable();
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.amount !== 0) {
      this.adjustFontSize();
    }
    if (prevProps.banking !== this.props.banking) {
      this.props.handleAssetFrom(
        this.props.assetActiveFrom,
        this.state.listAssets
      );
    }
  }
  async handleAssetFrom(value) {
    let id = value.split("_")[1];
    let account = this.findInfoAccount(id);
    formRef.current.resetFields();
    await this.props.handleAssetFrom(account, this.state.listAssets);
    let filterSelected = this.state.listAssets.filter(
      (element) => element.id !== id
    );
    if (id === this.props.assetActiveTo.id) {
      await this.props.handleAssetTo(filterSelected[0], this.state.listAssets);
    }
  }
  async handleAssetTo(value) {
    let id = value.split("_")[1];
    let account = this.findInfoAccount(id);
    formRef.current.resetFields();
    this.props.handleAssetTo(account, this.state.listAssets);
    let filterSelected = this.state.listAssets.filter(
      (element) => element.id !== id
    );
    if (id === this.props.assetActiveFrom.id) {
      this.props.handleAssetFrom(filterSelected[0], this.state.listAssets);
    }
  }
  findInfoAccount(account) {
    let objAccount = {};
    let accountsInfo = this.props.banking.data.data.accounts;
    let data = asset.assets;
    let findAccount = accountsInfo.find((element) => {
      return element._id === account;
    });
    let assetSelected = data.find((element) => {
      return element.value === findAccount.currency;
    });
    objAccount = assetSelected;
    objAccount.name = findAccount.currency_name;
    objAccount.value = findAccount.currency;
    objAccount.type = findAccount.type;
    objAccount.id = findAccount._id;
    objAccount.alias =
      findAccount.alias !== undefined ? findAccount.alias : null;
    objAccount.status = findAccount.status;
    return objAccount;
  }
  changeAssets = async () => {
    formRef.current.resetFields();
    this.props.handleAssetFrom(this.props.assetActiveTo, this.state.listAssets);
    this.props.handleAssetTo(this.props.assetActiveFrom, this.state.listAssets);
  };

  filterCurrencyAvailable() {
    let accountsInfo = this.props.banking.data.data.accounts;
    let arrayCurrencies = [];
    for (let objCurrency of accountsInfo) {
      let objCurrencies = {};
      objCurrencies.symbol = objCurrency.symbol;
      objCurrencies.name = objCurrency.currency_name;
      objCurrencies.value = objCurrency.currency;
      objCurrencies.type = objCurrency.type;
      objCurrencies.id = objCurrency._id;
      objCurrencies.alias =
        objCurrency.alias !== undefined ? objCurrency.alias : null;
      arrayCurrencies.push(objCurrencies);
    }
    let fiat = arrayCurrencies.filter(
      (element) =>
        element.type === "FIAT" && element.value === this.props.asset.key
    );
    let findFrom = fiat.findIndex(
      (element) => element.id === this.props.asset.id
    );
    let findTo = fiat.findIndex(
      (element) => element.id !== this.props.asset.id
    );
    this.setState(
      {
        listAssets: fiat,
      },
      async () => {
        this.props.handleAssetFrom(fiat[findFrom], this.state.listAssets);
        this.props.handleAssetTo(fiat[findTo], this.state.listAssets);
      }
    );
  }
  findIcon(currency) {
    let icon = "";
    if (currency !== undefined) {
      let data = asset.assets.find((element) => element.value === currency);
      icon = data.icon;
    }
    return icon;
  }
  adjustFontSize = () => {
    if (this.props.amount.length !== undefined) {
      if (isMobileOnly) {
        const fontSize = 10 - this.props.amount.length * 0.2;
        document.getElementById("resize-number-trade").style.fontSize =
          fontSize + "vw";
      } else {
        const fontSize = 2.8 - this.props.amount.length * 0.06;
        document.getElementById("resize-number-trade").style.fontSize =
          fontSize + "vw";
      }
    }
  };
  disabledButton() {
    let balance = parseFloat(this.props.assetActiveBalance);
    if (
      this.props.amountFloat === 0 ||
      this.props.amountFloat === undefined ||
      balance < this.props.amountFloat ||
      this.props.assetActiveFrom.status !== "ACTIVE" ||
      this.props.assetActiveTo.status !== "ACTIVE"
    ) {
      return true;
    } else {
      return false;
    }
  }
  fieldsValidator = (rule, value, callback) => {
    let balance = parseFloat(this.props.assetActiveBalance);
    let currencyBalance = this.props.assetActiveFrom.value;
    let messageBalance = this.props.t("trade.message.insufficientBalance", {
      key: currencyBalance,
    });
    var amount = this.props.amountFloat;
    if (value !== "") {
      if (amount > balance) {
        return Promise.reject(new Error(messageBalance));
      }
      return Promise.resolve();
    }
  };
  fastTrade(value) {
    let values = {};
    let amount = "";
    if (value === "25") {
      amount = this.props.percentageForTrade.twentyFive.toFixed(2);
    }
    if (value === "50") {
      amount = this.props.percentageForTrade.fifty.toFixed(2);
    }
    if (value === "100") {
      amount = this.props.percentageForTrade.all.toFixed(2);
    }

    values = {
      floatValue: parseFloat(amount),
      formattedValue: this.props.assetActiveFrom.symbol + amount.toString(),
      value: amount.toString(),
    };
    inputNumber.current.props.onValueChange(values);

    formRef.current.setFieldsValue({
      amount: this.props.assetActiveFrom.symbol + amount.toString(),
    });
  }

  render() {
    let balance = this.props.assetActiveBalance;
    return (
      <div>
        <Row
          justify="center"
          gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
        >
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 24, offset: 0 }}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Row
              justify="center"
              align="middle"
              gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
            >
              <Form
                name="trade"
                ref={formRef}
                autoComplete="off"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Form.Item
                  name="amount"
                  style={{
                    top: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    marginBottom: 0,
                  }}
                  rules={[{ validator: this.fieldsValidator }]}
                >
                  <NumberFormat
                    allowNegative={false}
                    thousandSeparator={true}
                    prefix={this.props.assetActiveFrom.symbol}
                    className="input-amount"
                    id={"resize-number-trade"}
                    placeholder={
                      this.props.assetActiveFrom.symbol
                        ? this.props.assetActiveFrom.symbol +
                          this.props.amountFloat
                        : ""
                    }
                    decimalScale={2}
                    style={{
                      fontSize: isMobileOnly ? "13vw" : "2.75vw",
                    }}
                    ref={inputNumber}
                    onValueChange={(values) => this.props.handleAmount(values)}
                  />
                </Form.Item>
              </Form>
            </Row>
          </Col>
        </Row>

        <Row
          justify="center"
          gutter={[48, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}
        >
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
          >
            <Space direction="vertical" style={{ width: "100%" }} size="middle">
              <Row justify="center">
                {(this.props.assetActiveFrom.status === "ACTIVE" ||
                  this.props.assetActiveTo.status === "ACTIVE") && (
                  <>
                    <Space
                      align="center"
                      direction="horizontal"
                      style={{ width: "100%", justifyContent: "center" }}
                      size="middle"
                    >
                      <Button
                        className="disabledStyle"
                        disabled={balance === "0"}
                        style={{ borderColor: COLOR.SECONDARY, marginTop: 16 }}
                        onClick={() => this.fastTrade("25")}
                      >
                        25%
                      </Button>
                      <Button
                        className="disabledStyle"
                        disabled={balance === "0"}
                        style={{ borderColor: COLOR.SECONDARY, marginTop: 16 }}
                        onClick={() => this.fastTrade("50")}
                      >
                        50%
                      </Button>
                      <Button
                        className="disabledStyle"
                        disabled={balance === "0"}
                        style={{ borderColor: COLOR.SECONDARY, marginTop: 16 }}
                        onClick={() => this.fastTrade("100")}
                      >
                        100%
                      </Button>
                    </Space>
                  </>
                )}
              </Row>
              <Row justify="center">
                {(this.props.assetActiveFrom.status === "ACTIVE" ||
                  this.props.assetActiveTo.status === "ACTIVE") && (
                  <>
                    <Text style={{ color: "#898996", marginTop: 16 }}>
                      {parseFloat(balance) > 0 && (
                        <div>
                          {this.props.t("fx.upTo") + " "}
                          <NumberFormat
                            thousandSeparator={true}
                            displayType="text"
                            prefix={this.props.assetActiveFrom.symbol + " "}
                            decimalScale={2}
                            value={parseFloat(balance)}
                          />
                        </div>
                      )}
                      {parseFloat(balance) === 0 &&
                        this.props.t("fx.notBalance", {
                          key: this.props.assetActiveFrom.name,
                        })}
                    </Text>
                  </>
                )}
                {this.props.assetActiveFrom.status !== "ACTIVE" && (
                  <Text
                    style={{
                      color: COLOR.ERROR,
                      textAlign: "center",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("wallet.messageStatusAccount", {
                      currency: this.props.assetActiveFrom.value,
                      status: this.props.t(
                        "wallet.status." + this.props.assetActiveFrom.status
                      ),
                    })}
                  </Text>
                )}
                {this.props.assetActiveTo.status !== "ACTIVE" && (
                  <Text
                    style={{
                      color: COLOR.ERROR,
                      textAlign: "center",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("wallet.messageStatusAccount", {
                      currency: this.props.assetActiveTo.value,
                      status: this.props.t(
                        "wallet.status." + this.props.assetActiveTo.status
                      ),
                    })}
                  </Text>
                )}
              </Row>
              <Row justify="center">
                <Card className="card-form" bodyStyle={{ padding: 0 }}>
                  <Form
                    labelAlign="left"
                    labelCol={{
                      xs: { span: 24 },
                      sm: { span: 24 },
                      md: { span: 8 },
                      lg: { span: 6 },
                      xl: { span: 6 },
                      xxl: { span: 6 },
                    }}
                    wrapperCol={{
                      xs: { span: 24 },
                      sm: { span: 24 },
                      md: { span: 16 },
                      lg: { span: 18 },
                      xl: { span: 18 },
                      xxl: { span: 18 },
                    }}
                    layout="horizontal"
                  >
                    <Form.Item
                      className="item-select-dark"
                      name="sell"
                      label={
                        <div
                          style={{
                            display: "flex",
                            marginTop: "5px",
                          }}
                        >
                          <Text className="label-dark">
                            {this.props.t("trade.from")}
                          </Text>
                        </div>
                      }
                    >
                      <div
                        style={{
                          display: "inline-flex",
                          float: "left",
                          alignItems: "center",
                          width: "110%",
                        }}
                      >
                        <Image
                          src={this.findIcon(this.props.assetActiveFrom.value)}
                          width={30}
                          preview={false}
                        />
                        <div
                          id="select-dark-sell"
                          className="ant-select-selector ant-select"
                        >
                          <Select
                            showSearch
                            filterOption={(input, option) =>
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            suffixIcon={
                              <RightOutlined style={{ color: "#ccc" }} />
                            }
                            size="large"
                            bordered={false}
                            style={{ color: "#ccc" }}
                            value={this.props.labelActiveFrom}
                            onChange={(value) => this.handleAssetFrom(value)}
                            optionLabelProp="label"
                          >
                            {this.props.listAssetFrom.length !== 0 &&
                              this.props.listAssetFrom.map((item, i) => {
                                return (
                                  <Option
                                    key={item.id}
                                    value={
                                      item.value +
                                      "_" +
                                      item.id +
                                      "_" +
                                      item.alias
                                    }
                                  >
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Image
                                        style={{ paddingRight: 5 }}
                                        src={this.findIcon(item.value)}
                                        width={30}
                                        preview={false}
                                      />
                                      {item.alias}
                                    </div>
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                    </Form.Item>
                    <div
                      id="divider-trade"
                      className="ant-divider-inner-text ant-divider-horizontal ant-divider-with-text"
                    >
                      <Divider
                        orientation="left"
                        style={{
                          background: COLOR.PRIMARY,
                          borderTopColor: COLOR.PRIMARY_TEXT,
                        }}
                      >
                        <Avatar
                          className="circle-icon-trade"
                          size={40}
                          src={
                            <SwapVertical
                              onClick={() => this.changeAssets()}
                              style={{
                                marginRight: 0,
                              }}
                            />
                          }
                        />
                      </Divider>
                    </div>

                    <Form.Item
                      className="item-select-dark"
                      label={
                        <div
                          style={{
                            display: "flex",
                            marginTop: "5px",
                          }}
                        >
                          <Text className="label-dark">
                            {this.props.t("trade.to")}
                          </Text>
                        </div>
                      }
                    >
                      <div
                        style={{
                          display: "inline-flex",
                          float: "left",
                          alignItems: "center",
                          width: "110%",
                        }}
                      >
                        <Image
                          src={this.findIcon(this.props.assetActiveTo.value)}
                          width={30}
                          preview={false}
                        />
                        <div
                          id="select-dark-sell"
                          className="ant-select-selector ant-select"
                        >
                          <Select
                            showSearch
                            filterOption={(input, option) =>
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            suffixIcon={
                              <RightOutlined style={{ color: "#ccc" }} />
                            }
                            size="large"
                            bordered={false}
                            style={{ color: "#ccc" }}
                            value={this.props.labelActiveTo}
                            optionLabelProp="label"
                            onChange={(value) => this.handleAssetTo(value)}
                          >
                            {this.props.listAssetTo.length !== 0 &&
                              this.props.listAssetTo.map((item, i) => {
                                return (
                                  <Option
                                    key={item.id}
                                    value={
                                      item.value +
                                      "_" +
                                      item.id +
                                      "_" +
                                      item.alias
                                    }
                                  >
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Image
                                        style={{ paddingRight: 5 }}
                                        src={this.findIcon(item.value)}
                                        width={30}
                                        preview={false}
                                      />
                                      {item.alias}
                                    </div>
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                    </Form.Item>
                  </Form>
                </Card>
              </Row>
              <Row justify="center">
                <Button
                  block
                  type="primary"
                  style={{
                    height: "60px",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  disabled={this.disabledButton()}
                  loading={this.props.loading}
                  onClick={() => this.props.next()}
                >
                  {"Rebalance "}
                  {this.props.assetActiveFrom.value}
                </Button>
              </Row>

              <Row>
                <Col span={12} style={{ textAlign: "left" }}>
                  <Text
                    style={{
                      color: "#898996",
                      fontSize: 14,
                      textAlign: "left",
                    }}
                  >
                    {this.props.assetActiveFrom.value !== undefined
                      ? this.props.assetActiveFrom.value
                      : ""}{" "}
                    balance
                  </Text>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Text style={{ color: "#898996", fontSize: 14 }}>
                    {this.props.assetActiveFrom.type === "CRYPTO" ? (
                      <NumberFormat
                        thousandSeparator={true}
                        displayType="text"
                        suffix={" " + this.props.assetActiveFrom.symbol}
                        decimalScale={8}
                        value={this.props.assetActiveBalance}
                      />
                    ) : (
                      <NumberFormat
                        thousandSeparator={true}
                        displayType="text"
                        prefix={this.props.assetActiveFrom.symbol + " "}
                        decimalScale={2}
                        value={this.props.assetActiveBalance}
                      />
                    )}{" "}
                    {this.props.assetActiveFrom.value !==
                      this.props.nativeCurrency && (
                      <NumberFormat
                        thousandSeparator={true}
                        displayType="text"
                        prefix={"≈ " + this.props.nativeSymbol + " "}
                        decimalScale={2}
                        value={parseFloat(this.props.nativeBalance)}
                      />
                    )}
                  </Text>
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    banking: state.banking.accounts,
    asset: state.siderNavigate.asset,
    currentLimit: state.exchange.currentLimits,
  };
};

const mapDispatchToProps = { getAccounts };
const RebalanceTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Rebalance)
);

export default RebalanceTranslated;
