import React, { Component } from "react";
import {
  Button,
  Space,
  Typography,
  Row,
  Col,
  Card,
  Input,
  Divider,
  Form,
  Image,
  message,
} from "antd";
import "./rebalance.css";
import { withTranslation } from "react-i18next";
import COLOR from "../../const/colors";
import KeyboardBackspace from "@2fd/ant-design-icons/lib/KeyboardBackspace";
import {
  getTransactionsCurrency,
  getAllTransactions,
  getAllTransactionsOrders,
} from "../../redux/actions/transactionsActions";
import { getAccounts, rebalance } from "../../redux/actions/bankingActions";
import { selectedAsset } from "../../redux/actions/navigateActions";
import { currentToken } from "../../redux/actions/tokenActions";
import { asset } from "../../common/assets";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
const { Text, Title } = Typography;
class ConfirmRebalance extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      disabledButton: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  async rebalance() {
    try {
      this.setState({ loading: true });
      let response = "";
      let body = {
        source_account_id: this.props.assetActive.id,
        destination_account_id: this.props.assetActiveReceive.id,
        amount: this.props.amountFloat.toString(),
        reference: "Rebalance",
      };
      await this.props.rebalance(
        this.props.clientId,
        this.props.accessToken,
        body
      );
      response = this.props.respRebalance;

      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        this.setState({ loading: false });
        message.success(this.props.t("trade.message.success"), 12);
        await this.getRecentTransactions();
        await this.getRecentTransactionsOrders();
        await this.getAccounts();
        this.props.resetCurrent();
        
      }
      if (response.status === 202) {
        setTimeout(() => {
          this.getAccounts().finally(() => {
            this.getRecentTransactions().finally(() => {
              this.getRecentTransactionsOrders().then((resp) => {
                this.setState({ loading: false });
                this.props.resetCurrent();
              });
            });
          });
        }, 5000);

        message.success(this.props.t("trade.message.complete"), 12);

      } else if (response.status === 400) {
        this.setState({ loading: false });
        if (response.data.error_details.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;
          message.error(this.props.t("trade.error." + messageError), 10);
        } else if (response.data.error_details.payload_error !== undefined) {
          let messageError = response.data.error_details.payload_error;
          message.error(this.props.t("trade.error." + messageError), 10);
        } else {
          message.error(response.data.message);
        }
      } else if (response.status === 403) {
        message.error(this.props.t("trade.error.accessErrorExchange"), 10);
        this.setState({ loading: false });
      } else if (response.status === 404) {
        this.setState({ loading: false });
        if (response.data.error_details.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;

          message.error(this.props.t("trade.error." + messageError), 10);
        } else {
          message.error(response.data.message);
        }
      } else if (
        response.status === 401 ||
        response.status === 422 ||
        response.status === 500
      ) {
        message.error(this.props.t("trade.error.errorServer"), 10);
        this.setState({ loading: false });
      }
    } catch (error) {
      message.error(this.props.t("trade.error.errorServer"), 10);
      this.setState({ loading: false });
    }
  }

  async getRecentTransactions() {
    try {
      this.setState({ loading: true });
      await this.props.getAllTransactions(
        this.props.clientId,
        this.props.accessToken
      );
      let response = this.props.respAllTransactions;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async getRecentTransactionsOrders() {
    try {
      this.setState({ loading: true });
      await this.props.getAllTransactionsOrders(
        this.props.clientId,
        this.props.accessToken
      );
      let response = this.props.respTransactionsOrders;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  async getAccounts() {
    try {
      await this.props.getAccounts(this.props.clientId, this.props.accessToken);
      let response = this.props.banking.accounts;
      let newAccessToken = "";
      if (response.headers !== undefined) {
        newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
    } catch (error) {}
  }
  findIcon(currency) {
    let icon = "";
    if (currency !== undefined) {
      let data = asset.assets.find((element) => element.value === currency);
      icon = data.icon;
    }
    return icon;
  }
  render() {
    return (
      <Row
        style={{ paddingTop: 20 }}
        justify="center"
        gutter={[48, { xs: 16, sm: 16, md: 16, lg: 24, xl: 24 }]}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
        >
          <Row
            justify="center"
            gutter={[8, { xs: 8, sm: 8, md: 8, lg: 48, xl: 8 }]}
          >
            <Col
              xs={{ span: 3, offset: 0 }}
              sm={{ span: 3, offset: 0 }}
              md={{ span: 3, offset: 0 }}
              lg={{ span: 3, offset: 0 }}
              xl={{ span: 3, offset: 0 }}
            >
              <KeyboardBackspace
                style={{ color: COLOR.PRIMARY_TEXT, fontSize: 30 }}
                onClick={() => this.props.prev()}
              />
            </Col>
            <Col
              xs={{ span: 18, offset: 0 }}
              sm={{ span: 18, offset: 0 }}
              md={{ span: 18, offset: 0 }}
              lg={{ span: 18, offset: 0 }}
              xl={{ span: 18, offset: 0 }}
              style={{ textAlign: "center" }}
            >
              <Title level={3} style={{ color: COLOR.PRIMARY_TEXT }}>
                Rebalancing
              </Title>
            </Col>
            <Col
              xs={{ span: 3, offset: 0 }}
              sm={{ span: 3, offset: 0 }}
              md={{ span: 3, offset: 0 }}
              lg={{ span: 3, offset: 0 }}
              xl={{ span: 3, offset: 0 }}
            ></Col>
          </Row>
          <br />
          <br />
          <Row
            justify="center"
            gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
            style={{ marginBottom: 24 }}
          >
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
              xxl={{ span: 24, offset: 0 }}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Row
                justify="center"
                align="middle"
                gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
              >
                <Form
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Form.Item style={{ width: "100%", marginBottom: 0 }}>
                    <Row gutter={8} justify="center">
                      <Form.Item style={{ marginBottom: 0 }}>
                        <NumberFormat
                          thousandSeparator={true}
                          displayType="text"
                          className="input-amount-dark"
                          style={{
                            fontSize:
                              1.8 -
                              this.props.amount.toString().length * 0.05 +
                              "vw",
                          }}
                          suffix={" " + this.props.assetActive.symbol}
                          decimalScale={2}
                          value={this.props.amountFloat}
                        />
                      </Form.Item>
                    </Row>
                  </Form.Item>
                </Form>
              </Row>
            </Col>
          </Row>
          <Row
            justify="center"
            gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
          >
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <Space
                direction="vertical"
                style={{ width: "100%", marginTop: 0 }}
                size="middle"
              >
                <Row justify="center">
                  <Card className="card-form" bodyStyle={{ padding: 0 }}>
                    <Form
                      labelAlign="left"
                      labelCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 8 },
                        lg: { span: 8 },
                        xl: { span: 8 },
                        xxl: { span: 10 },
                      }}
                      wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 16 },
                        lg: { span: 16 },
                        xl: { span: 16 },
                        xxl: { span: 14 },
                      }}
                      layout="horizontal"
                    >
                      <Form.Item
                        className="item-send-dark"
                        label={<Text className="label-dark">From</Text>}
                      >
                        <Input.Group>
                          <Row>
                            <Col span={24}>
                              <Text
                                style={{
                                  color: COLOR.PRIMARY_TEXT,

                                  fontSize: 16,
                                }}
                              >
                                {this.props.assetActive.alias}
                              </Text>
                            </Col>
                          </Row>
                        </Input.Group>
                      </Form.Item>

                      <Divider
                        style={{
                          background: COLOR.PRIMARY_TEXT,
                          margin: 0,
                        }}
                      />
                      <Form.Item
                        className="item-send-dark"
                        label={<Text className="label-dark">To</Text>}
                      >
                        <Input.Group>
                          <Row>
                            <Col span={24}>
                              <Text
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                {this.props.assetActiveReceive.alias}
                              </Text>
                            </Col>
                          </Row>
                        </Input.Group>
                      </Form.Item>

                      <Divider
                        style={{ background: COLOR.PRIMARY_TEXT, margin: 0 }}
                      />
                      <Form.Item
                        className="item-select-dark"
                        label={<Text className="label-dark">Asset</Text>}
                      >
                        <div
                          style={{
                            display: "inline-flex",
                            float: "left",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            src={this.findIcon(this.props.assetActive.value)}
                            width={25}
                            preview={false}
                          />
                          <Text
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              marginLeft: 10,
                              fontSize: 16,
                            }}
                          >
                            {this.props.assetActive.name}
                          </Text>
                        </div>
                      </Form.Item>
                    </Form>
                  </Card>
                </Row>

                <Row justify="center">
                  <Button
                    block
                    type="primary"
                    style={{
                      height: "60px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    disabled={this.state.disabledButton}
                    loading={this.state.loading || this.props.loading}
                    onClick={() => this.rebalance()}
                  >
                    Rebalance
                  </Button>
                </Row>
                <Row style={{ marginTop: "1rem" }}>
                  <Col span={12} style={{ textAlign: "left" }}>
                    <Text
                      style={{
                        color: COLOR.SECONDARY_TEXT,
                        fontSize: 14,
                        textAlign: "left",
                      }}
                    >
                      {this.props.assetActive.value !== undefined
                        ? this.props.assetActive.value
                        : ""}{" "}
                      balance
                    </Text>
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 14 }}>
                      <NumberFormat
                        thousandSeparator={true}
                        displayType="text"
                        prefix={this.props.assetActive.symbol + " "}
                        decimalScale={2}
                        value={this.props.assetActiveBalance}
                      />{" "}
                      {this.props.assetActive.value !==
                        this.props.nativeCurrency && (
                        <NumberFormat
                          thousandSeparator={true}
                          displayType="text"
                          prefix={"≈ " + this.props.nativeSymbol + " "}
                          decimalScale={2}
                          value={parseFloat(this.props.nativeBalance)}
                        />
                      )}
                    </Text>
                  </Col>
                </Row>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    banking: state.banking.accounts,
    asset: state.siderNavigate.asset,
    accessToken: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
    respTransactions: state.transactions.resultTransactions,
    respAllTransactions: state.transactions.allResultTx,
    respTransactionsOrders: state.transactions.transactionsAndOrders,
    dataUser: state.authUser.userData,
    respRebalance: state.banking.rebalanceResp,
  };
};

const mapDispatchToProps = {
  getAccounts,
  selectedAsset,
  currentToken,
  getTransactionsCurrency,
  getAllTransactions,
  getAllTransactionsOrders,
  rebalance,
};
const ConfirmRebalanceTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmRebalance)
);

export default ConfirmRebalanceTranslated;
