import servicesExchange from "../../services/exchange";
export const GET_RATE = "GET_RATE";
export const SELL_ASSET = "SELL_ASSET";
export const BUY_ASSET = "BUY_ASSET";
export const EXCHANGE_ASSET = "EXCHANGE_ASSET";
export const EXCHANGE_FX = "EXCHANGE_FX";
export const GET_GENERAL_RATE = "GET_GENERAL_RATE";
export const GET_MARKET = "GET_MARKET";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_FILTER = "GET_ORDERS_FILTER";
export const CURRENT_FILTERS_ORDERS = "CURRENT_FILTERS_ORDERS";
export const CURRENCY_FROM = "CURRENCY_FROM";
export const LIMITS = "LIMITS";
export const GET_PAIRS_SUPPORTED = "GET_PAIRS_SUPPORTED";
export const GET_SPREAD = "GET_SPREAD";
export const QUOTE_CURRENCY="QUOTE_CURRENCY"
export function getRate(id, token, baseCurrency, quoteCurrency) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesExchange.getRate(
        id,
        token,
        baseCurrency,
        quoteCurrency
      );
      dispatch({
        type: GET_RATE,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_RATE,
        payload: error.response,
      });
    }
  };
}
export function sellAsset(id, token, body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesExchange.sellAsset(id, token, body);
      dispatch({
        type: SELL_ASSET,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: SELL_ASSET,
        payload: error.response,
      });
    }
  };
}
export function buyAsset(id, token, body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesExchange.buyAsset(id, token, body);
      dispatch({
        type: BUY_ASSET,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: BUY_ASSET,
        payload: error.response,
      });
    }
  };
}
export function exchangeAsset(id, token, body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesExchange.exchangeAsset(id, token, body);
      dispatch({
        type: EXCHANGE_ASSET,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: EXCHANGE_ASSET,
        payload: error.response,
      });
    }
  };
}
export function exchangeFx(id, token, body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesExchange.exchangeFx(id, token, body);
      dispatch({
        type: EXCHANGE_FX,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: EXCHANGE_FX,
        payload: error.response,
      });
    }
  };
}
export function getGeneralRate(id, token, currency) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesExchange.getGeneralRate(
        id,
        token,
        currency
      );
      dispatch({
        type: GET_GENERAL_RATE,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_GENERAL_RATE,
        payload: error.response,
      });
    }
  };
}
export function getMarket(id, token, period) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesExchange.getMarket(id, token, period);
      dispatch({
        type: GET_MARKET,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_MARKET,
        payload: error.response,
      });
    }
  };
}
export function getOrders(id, token, lastId) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesExchange.getOrdersExchange(
        id,
        token,
        lastId
      );
      dispatch({
        type: GET_ORDERS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_ORDERS,
        payload: error.response,
      });
    }
  };
}
export function getOrdersByFilters(
  id,
  token,
  status,
  minDate,
  maxDate,
  lastId
) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesExchange.getOrdersByFilters(
        id,
        token,
        status,
        minDate,
        maxDate,
        lastId
      );
      dispatch({
        type: GET_ORDERS_FILTER,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_ORDERS_FILTER,
        payload: error.response,
      });
    }
  };
}
export function currentFiltersOrders(filters) {
  return (dispatch, getState) => {
    dispatch({
      type: CURRENT_FILTERS_ORDERS,
      payload: filters,
    });
  };
}
export function currentFromAsset(currency) {
  return (dispatch, getState) => {
    dispatch({
      type: CURRENCY_FROM,
      payload: currency,
    });
  };
}
export function limitsTrade(limits) {
  return (dispatch, getState) => {
    dispatch({
      type: LIMITS,
      payload: limits,
    });
  };
}
export function getExchangePairsSupported(
  id,
  token,
  baseCurrency,
  quoteCurrency
) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesExchange.getExchangePairs(
        id,
        token,
        baseCurrency,
        quoteCurrency
      );
      dispatch({
        type: GET_PAIRS_SUPPORTED,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_PAIRS_SUPPORTED,
        payload: error.response,
      });
    }
  };
}
export function getSpreads(id, token) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesExchange.getSpreads(id, token);
      dispatch({
        type: GET_SPREAD,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SPREAD,
        payload: error.response,
      });
    }
  };
}
export function quoteCurrency(currency) {
  return (dispatch, getState) => {
    dispatch({
      type: QUOTE_CURRENCY,
      payload: currency,
    });
  };
}
