import React, { Component } from "react";
import {
  Button,
  Space,
  Typography,
  Row,
  Col,
  Card,
  Divider,
  Form,
  Select,
  Image,
  Avatar,
} from "antd";
import "./fiatCrypto.css";
import { withTranslation } from "react-i18next";
import COLOR from "../../const/colors";
import { RightOutlined, LoadingOutlined } from "@ant-design/icons";
import SwapVertical from "@2fd/ant-design-icons/lib/SwapVertical";
import { getAccounts } from "../../redux/actions/bankingActions";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { isMobileOnly } from "react-device-detect";
const { Option } = Select;
const { Text } = Typography;
const formRef = React.createRef();
const inputNumber = React.createRef();
class FiatCrypto extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      assetActiveTo: "",
      assetActiveFrom: "",
      assetSelected: "",
      activeInput: "",
      listAssetFrom: [],
      listAssetTo: [],
      accounts: [],
      accountSelected: "",
      amountBuy: 0,
      amountBuyFiat: 0,
      amountBuyFloat: 0,
      assetActiveBalance: "",
      time: "",
      seconds: 30,
    };
    this.timer = 0;
    this.countDown = this.countDown.bind(this);
  }
  componentDidMount() {
    if (this.props.source === "trade") {
      if (document.getElementById("resize-number-pay-trade") !== null) {
        document.getElementById("resize-number-pay-trade").focus();
      }
    } else {
      if (document.getElementById("resize-number-pay") !== null) {
        document.getElementById("resize-number-pay").focus();
      }
    }

    this.filterCurrencyAvailable();
    this._isMounted = true;
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  secondsToTime(secs) {
    let divisor_for_minutes = secs % (60 * 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let timeSeconds = seconds;

    return timeSeconds;
  }
  countDown() {
    if (this._isMounted) {
      // Remove one second, set state so a re-render happens.
      let seconds = this.state.seconds - 1;
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
      });

      // Check if we're at zero.
      if (seconds === 0) {
        clearInterval(this.timer);
        this.setState({ seconds: 30 }, async () => {
          let timeLeftVar = this.secondsToTime(this.state.seconds);
          this.setState({ time: timeLeftVar });
          if (this.state.seconds > 0) {
            await this.props.getPairsSupported(
              this.props.assetActiveFrom.value,
              this.props.assetActiveTo.value
            );
            this.props.getRate(
              this.props.assetActiveFrom.value,
              this.props.assetActiveTo.value,
              true
            );
            this.timer = setInterval(this.countDown, 1000);
          }
        });
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.amountBuy !== 0) {
      this.adjustFontSize();
    }
    if (this.props.amountBuyFiat !== 0) {
      this.adjustFontSizeFiat();
    }
    if (prevProps.banking !== this.props.banking) {
      this.props.handleAssetFrom(
        this.props.assetActiveFrom.value,
        this.props.assetActiveFrom.id
      );
    }
  }
  handleTypeInput() {
    this.setState({ amountBuy: 0, amountBuyFiat: 0 });
    if (this.state.activeInput === this.state.assetActiveFrom.value) {
      this.setState({ activeInput: this.state.assetActiveTo.value });
    } else {
      this.setState({ activeInput: this.state.assetActiveFrom.value });
    }
  }
  changeAssets = async () => {
    formRef.current.resetFields();
    this.props.handleAssetFrom(
      this.props.assetActiveTo.value,
      this.props.assetActiveTo.id
    );
    this.props.handleAssetTo(
      this.props.assetActiveFrom.value,
      this.props.assetActiveFrom.id
    );
    await this.props.resetMinExchange();
    await this.props.getPairsSupported(
      this.props.assetActiveFrom.value,
      this.props.assetActiveTo.value
    );
    this.props.getRate(
      this.props.assetActiveFrom.value,
      this.props.assetActiveTo.value,
      true
    );
  };
  async filterCurrencyAvailable() {
    let accountsInfo = this.props.banking.data.data.accounts;
    accountsInfo = accountsInfo.filter((account) => {
      return account.status === "ACTIVE";
    });
    let arrayCurrencies = [];
    for (let objCurrency of accountsInfo) {
      let objCurrencies = {};
      objCurrencies.value = objCurrency.currency;
      objCurrencies.type = objCurrency.type;
      objCurrencies.id = objCurrency._id;
      arrayCurrencies.push(objCurrencies);
    }
    let fiat = arrayCurrencies.filter((element) => element.type === "FIAT");
    let crypto = arrayCurrencies.filter((element) => element.type === "CRYPTO");
    if (fiat.length !== 0) {
      this.props.handleAssetFrom(fiat[0].value, fiat[0].id);
      this.props.getLimits(fiat[0].value);
    }
    if (crypto.length !== 0) {
      this.props.handleAssetTo(crypto[0].value, crypto[0].id);
    }
    await this.props.resetMinExchange();
    await this.props.getRate(fiat[0].value, crypto[0].value, true);
    await this.props.getPairsSupported(fiat[0].value, crypto[0].value);
  }
  updateQuote() {
    if (this._isMounted) {
      // Remove one second, set state so a re-render happens.
      let seconds = this.state.seconds - 1;
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
      });

      // Check if we're at zero.
      if (seconds === 0) {
        clearInterval(this.timer);
        this.setState({ seconds: 30 }, async () => {
          let timeLeftVar = this.secondsToTime(this.state.seconds);
          this.setState({ time: timeLeftVar });
          if (this.state.seconds > 0) {
            await this.props.next(this.props.name, true);
            this.timer = setInterval(this.countDown, 1000);
          }
        });
      }
    }
  }
  adjustFontSize = () => {
    if (this.props.source === "trade") {
      if (this.props.amountBuy.length !== undefined) {
        const fontSize = 2.2 - this.props.amountBuy.length * 0.05;
        document.getElementById("resize-number-buy-trade").style.fontSize =
          fontSize + "vw";
      }
    } else if (this.props.source === "header") {
      if (this.props.amountBuy.length !== undefined) {
        const fontSize = 2.8 - this.props.amountBuy.length * 0.06;
        document.getElementById("resize-number-buy").style.fontSize =
          fontSize + "vw";
      }
    }
  };
  adjustFontSizeFiat = () => {
    if (this.props.source === "trade") {
      if (this.props.amountBuyFiat.length !== undefined) {
        if (isMobileOnly) {
          const fontSize = 10 - this.props.amountBuyFiat.length * 0.2;
          document.getElementById("resize-number-pay-trade").style.fontSize =
            fontSize + "vw";
        } else {
          const fontSize = 2.5 - this.props.amountBuyFiat.length * 0.05;
          document.getElementById("resize-number-pay-trade").style.fontSize =
            fontSize + "vw";
        }
      }
    } else {
      if (this.props.amountBuyFiat.length !== undefined) {
        const fontSize = 3 - this.props.amountBuyFiat.length * 0.06;
        document.getElementById("resize-number-pay").style.fontSize =
          fontSize + "vw";
      }
    }
  };
  disabledButton() {
    let balance = parseFloat(this.props.assetActiveBalance);
    if (
      this.props.amountBuyFiatFloat === 0 ||
      this.props.amountBuyFiatFloat === undefined ||
      balance < this.props.amountBuyFiatFloat ||
      this.props.amountBuyFiatFloat < this.props.recommendedMinExchange ||
      this.props.assetActiveFrom.status !== "ACTIVE" ||
      this.props.assetActiveTo.status !== "ACTIVE" ||
      this.props.pairsSupported === false
    ) {
      return true;
    } else {
      return false;
    }
  }
  async handleFrom(value) {
    let currency = value.split("_")[0];
    let id = value.split("_")[1];
    formRef.current.resetFields();
    await this.props.resetMinExchange();
    await this.props.handleAssetFrom(currency, id);
    this.props.getLimits(currency);
    await this.props.resetMinExchange();
    await this.props.getPairsSupported(
      currency,
      this.props.assetActiveTo.value
    );
    this.props.getRate(currency, this.props.assetActiveTo.value, true);
  }
  async handleTo(value) {
    let currency = value.split("_")[0];
    let id = value.split("_")[1];
    formRef.current.resetFields();
    await this.props.handleAssetTo(currency, id);
    await this.props.resetMinExchange();
    await this.props.getPairsSupported(
      this.props.assetActiveFrom.value,
      currency
    );
    this.props.getRate(this.props.assetActiveFrom.value, currency, true);
  }
  fieldsValidator = (rule, value, callback) => {
    let balance = parseFloat(this.props.assetActiveBalance);
    let currencyBalance = this.props.assetActiveFrom.value;
    let minExchange = parseFloat(this.props.recommendedMinExchange);
    let maxTrade = parseFloat(this.props.maxExchange);
    let messageBalance = this.props.t("trade.message.insufficientBalance", {
      key: currencyBalance,
    });
    let messageMin = this.props.t("limits.messages.errorMinimunTrade");
    let messageMax = this.props.t("limits.messages.errorMax");
    var amount = this.props.amountBuyFiatFloat;
    if (value !== "") {
      if (amount > balance) {
        return Promise.reject(new Error(messageBalance));
      }
      if (amount < minExchange) {
        return Promise.reject(new Error(messageMin));
      }
      if (maxTrade !== -1 && this.props.assetActiveFrom.type !== "CRYPTO") {
        if (amount > maxTrade) {
          return Promise.reject(new Error(messageMax));
        }
      }

      return Promise.resolve();
    }
  };
  fastTrade(value) {
    let values = {};
    let amount = "";
    if (value === "25") {
      amount =
        this.props.assetActiveFrom.type === "FIAT"
          ? this.props.percentageForTrade.twentyFive.toFixed(2)
          : this.props.percentageForTrade.twentyFive.toFixed(8);
    }
    if (value === "50") {
      amount =
        this.props.assetActiveFrom.type === "FIAT"
          ? this.props.percentageForTrade.fifty.toFixed(2)
          : this.props.percentageForTrade.fifty.toFixed(8);
    }
    if (value === "100") {
      amount =
        this.props.assetActiveFrom.type === "FIAT"
          ? this.props.percentageForTrade.all.toFixed(2)
          : this.props.percentageForTrade.all.toFixed(8);
    }
    let formatted =
      this.props.assetActiveFrom.type === "FIAT"
        ? this.props.assetActiveFrom.symbol + amount.toString()
        : amount.toString() + " " + this.props.assetActiveFrom.symbol;
    values = {
      formattedValue: formatted,
      value: amount.toString(),
      floatValue: parseFloat(amount),
    };
    inputNumber.current.props.onValueChange(values);
    formRef.current.setFieldsValue({
      amountPay: formatted,
    });
  }
  findVolumes() {
    let baseCurrency = this.props.assetActiveFrom;
    let accounts = this.props.banking.data.data.accounts;
    let findVolumes = "";
    let findAccount = accounts.find((element) => {
      return element.currency === baseCurrency.value;
    });
    if (findAccount !== undefined) {
      findVolumes = findAccount.volumes.find((element) => {
        return element.type === "EXCHANGE_OUT";
      });
    }
    return findVolumes;
  }
  render() {
    let limitDaily = "";
    let limitMonthly = "";
    if (this.props.currentLimit !== null) {
      limitDaily = this.props.currentLimit.max_daily_exchange;
      limitMonthly = this.props.currentLimit.max_monthly_exchange;
    }
    let limitAmount = "";
    let balance = this.props.assetActiveBalance;
    let minExchange = this.props.recommendedMinExchange;
    let maxTrade = this.props.maxExchange;
    if (maxTrade !== "-1") {
      if (balance <= maxTrade) {
        limitAmount = balance;
      } else {
        limitAmount = maxTrade;
      }
    } else {
      let volumes = this.findVolumes() === undefined ? "0" : this.findVolumes();

      if (balance > limitDaily && limitDaily !== "-1") {
        limitAmount = limitDaily - volumes.current_day_volume;
      } else if (balance > limitMonthly && limitMonthly !== "-1") {
        limitAmount = limitMonthly - volumes.current_month_volume;
      } else {
        limitAmount = balance;
      }
    }
    return (
      <div>
        <Row
          justify="center"
          gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
        >
          <Col
            xs={{ span: 3, offset: 0 }}
            sm={{ span: 3, offset: 0 }}
            md={{ span: 3, offset: 0 }}
            lg={{ span: 3, offset: 0 }}
            xl={{ span: 3, offset: 0 }}
            xxl={{ span: 3, offset: 0 }}
          ></Col>
          <Col
            xs={{ span: 18, offset: 0 }}
            sm={{ span: 18, offset: 0 }}
            md={{ span: 18, offset: 0 }}
            lg={{ span: 18, offset: 0 }}
            xl={{ span: 18, offset: 0 }}
            xxl={{ span: 18, offset: 0 }}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Row
              justify="center"
              align="middle"
              gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
            >
              <Form
                name="buy"
                ref={formRef}
                autoComplete="off"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Form.Item
                  name="amountPay"
                  style={{
                    top: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    marginBottom: 0,
                  }}
                  rules={[
                    {
                      validator: this.fieldsValidator,
                    },
                  ]}
                >
                  {this.props.assetActiveFrom.type === "FIAT" ? (
                    <NumberFormat
                      allowNegative={false}
                      thousandSeparator={true}
                      prefix={this.props.assetActiveFrom.symbol}
                      className="input-amount"
                      id={
                        this.props.source === "trade"
                          ? "resize-number-pay-trade"
                          : "resize-number-pay"
                      }
                      placeholder={
                        this.props.assetActiveFrom.symbol !== undefined
                          ? this.props.assetActiveFrom.symbol +
                            this.props.amountBuyFiatFloat
                          : ""
                      }
                      decimalScale={2}
                      style={{
                        fontSize: isMobileOnly ? "13vw" : "2.77vw",
                      }}
                      ref={inputNumber}
                      onValueChange={(values) =>
                        this.props.handleAmountFiat(values)
                      }
                    />
                  ) : (
                    <NumberFormat
                      allowNegative={false}
                      thousandSeparator={true}
                      suffix={this.props.assetActiveFrom.symbol + " "}
                      className="input-amount"
                      id={
                        this.props.source === "trade"
                          ? "resize-number-pay-trade"
                          : "resize-number-pay"
                      }
                      placeholder={
                        this.props.assetActiveFrom.symbol !== undefined
                          ? this.props.amountBuyFiatFloat +
                            " " +
                            this.props.assetActiveFrom.symbol
                          : ""
                      }
                      decimalScale={8}
                      style={{
                        fontSize: isMobileOnly ? "13vw" : "2.77vw",
                      }}
                      ref={inputNumber}
                      onValueChange={(values) =>
                        this.props.handleAmountFiat(values)
                      }
                    />
                  )}
                </Form.Item>
              </Form>
            </Row>
          </Col>

          <Col
            xs={{ span: 3, offset: 0 }}
            sm={{ span: 3, offset: 0 }}
            md={{ span: 3, offset: 0 }}
            lg={{ span: 3, offset: 0 }}
            xl={{ span: 3, offset: 0 }}
            xxl={{ span: 3, offset: 0 }}
            style={{
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          ></Col>
        </Row>

        <Row
          justify="center"
          gutter={[48, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}
        >
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
          >
            <Space
              direction="vertical"
              style={{ width: "100%", flex: "none" }}
              size="middle"
            >
              <Row justify="center">
                {(this.props.assetActiveFrom.status === "ACTIVE" ||
                  this.props.assetActiveTo.status === "ACTIVE") && (
                  <>
                    <Space
                      align="center"
                      direction="horizontal"
                      style={{ width: "100%", justifyContent: "center" }}
                      size="middle"
                    >
                      <Button
                        className="disabledStyle"
                        disabled={
                          this.props.assetActiveBalance === "0" ||
                          this.props.percentageForTrade.twentyFive < minExchange
                        }
                        style={{ borderColor: COLOR.SECONDARY, marginTop: 16 }}
                        onClick={() => this.fastTrade("25")}
                      >
                        25%
                      </Button>
                      <Button
                        className="disabledStyle"
                        disabled={
                          this.props.assetActiveBalance === "0" ||
                          this.props.percentageForTrade.fifty < minExchange
                        }
                        style={{ borderColor: COLOR.SECONDARY, marginTop: 16 }}
                        onClick={() => this.fastTrade("50")}
                      >
                        50%
                      </Button>
                      <Button
                        className="disabledStyle"
                        disabled={
                          this.props.assetActiveBalance === "0" ||
                          this.props.percentageForTrade.all < minExchange
                        }
                        style={{ borderColor: COLOR.SECONDARY, marginTop: 16 }}
                        onClick={() => this.fastTrade("100")}
                      >
                        100%
                      </Button>
                    </Space>
                  </>
                )}
              </Row>
              <Row justify="center">
                {(this.props.assetActiveFrom.status === "ACTIVE" ||
                  this.props.assetActiveTo.status === "ACTIVE") && (
                  <>
                    <Text style={{ color: "#898996", marginTop: 16 }}>
                      {parseFloat(balance) > 0 &&
                        parseFloat(balance) >= parseFloat(minExchange) && (
                          <div>
                            {this.props.assetActiveFrom.type === "FIAT" && (
                              <>
                                {this.props.t("buy.message.upTo") + " "}

                                <NumberFormat
                                  thousandSeparator={true}
                                  displayType="text"
                                  prefix={
                                    this.props.assetActiveFrom.symbol + " "
                                  }
                                  decimalScale={2}
                                  value={parseFloat(limitAmount)}
                                />
                              </>
                            )}
                            {this.props.assetActiveFrom.type === "CRYPTO" && (
                              <>
                                {this.props.t("sell.message.upTo") + " "}
                                <NumberFormat
                                  thousandSeparator={true}
                                  displayType="text"
                                  suffix={
                                    " " + this.props.assetActiveFrom.symbol
                                  }
                                  decimalScale={8}
                                  value={limitAmount}
                                />
                                {parseFloat(balance) >= parseFloat(maxTrade)
                                  ? this.props.t("sell.message.instant")
                                  : ""}
                              </>
                            )}
                          </div>
                        )}
                      {(parseFloat(balance) === 0 ||
                        parseFloat(balance) < parseFloat(minExchange)) &&
                        this.props.t("fiatCrypto.notBalance", {
                          key: this.props.assetActiveFrom.name,
                        })}
                    </Text>
                    {this.props.pairsSupported === false && (
                      <Text
                        style={{
                          color: COLOR.ERROR,
                          textAlign: "center",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t("trade.message.pairSupported", {
                          base: this.props.assetActiveFrom.name,
                          quote: this.props.assetActiveTo.name,
                        })}
                      </Text>
                    )}
                    {this.props.showMessageLimit && (
                      <Text
                        style={{
                          color: COLOR.ERROR,
                          textAlign: "center",
                          fontSize: 14,
                        }}
                      >
                        {this.props.messageLimit}
                      </Text>
                    )}
                  </>
                )}
                {this.props.assetActiveFrom.status !== "ACTIVE" && (
                  <Text
                    style={{
                      color: COLOR.ERROR,
                      textAlign: "center",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("wallet.messageStatusAccount", {
                      currency: this.props.assetActiveFrom.value,
                      status: this.props.t(
                        "wallet.status." + this.props.assetActiveFrom.status
                      ),
                    })}
                  </Text>
                )}
                {this.props.assetActiveTo.status !== "ACTIVE" && (
                  <Text
                    style={{
                      color: COLOR.ERROR,
                      textAlign: "center",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("wallet.messageStatusAccount", {
                      currency: this.props.assetActiveTo.value,
                      status: this.props.t(
                        "wallet.status." + this.props.assetActiveTo.status
                      ),
                    })}
                  </Text>
                )}
              </Row>
              <Row justify="center">
                <Card className="card-form" bodyStyle={{ padding: 0 }}>
                  <Form
                    labelAlign="left"
                    labelCol={{
                      xs: { span: 24 },
                      sm: { span: 24 },
                      md: { span: 8 },
                      lg: { span: 6 },
                      xl: { span: 6 },
                      xxl: { span: 6 },
                    }}
                    wrapperCol={{
                      xs: { span: 24 },
                      sm: { span: 24 },
                      md: { span: 16 },
                      lg: { span: 18 },
                      xl: { span: 18 },
                      xxl: { span: 18 },
                    }}
                    layout="horizontal"
                    style={{ verticalAlign: "middle" }}
                  >
                    <Form.Item
                      className="item-select-dark"
                      name="buy"
                      label={
                        <div
                          style={{
                            display: "flex",
                            marginTop: "4px",
                          }}
                        >
                          <Text className="label-dark">
                            {this.props.t("fiatCrypto.from")}
                          </Text>
                        </div>
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          src={this.props.assetActiveFrom.icon}
                          width={32}
                          preview={false}
                        />

                        <div
                          id="select-dark-buy"
                          className="ant-select-selector ant-select"
                        >
                          <Select
                            showSearch
                            filterOption={(input, option) => {
                              if (option.value) {
                                let val = option.value.split("_")[2];
                                return (
                                  val
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                );
                              }
                            }}
                            suffixIcon={
                              <RightOutlined style={{ color: "#ccc" }} />
                            }
                            size="large"
                            bordered={false}
                            style={{ color: "#ccc" }}
                            value={this.props.labelActiveFrom}
                            onChange={(value) => this.handleFrom(value)}
                            optionLabelProp="label"
                          >
                            {this.props.listAssetFrom.length !== 0 &&
                              this.props.listAssetFrom.map((item, i) => {
                                return (
                                  <Option
                                    key={item.id}
                                    value={
                                      item.alias !== null
                                        ? item.value +
                                          "_" +
                                          item.id +
                                          "_" +
                                          item.alias
                                        : item.value +
                                          "_" +
                                          item.id +
                                          "_" +
                                          item.name
                                    }
                                  >
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Image
                                        style={{ paddingRight: 5 }}
                                        src={item.icon}
                                        width={30}
                                        preview={false}
                                      />
                                      {item.alias !== null
                                        ? item.alias
                                        : item.name}
                                    </div>
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                    </Form.Item>
                    <div
                      id="divider-trade"
                      className="ant-divider-inner-text ant-divider-horizontal ant-divider-with-text"
                    >
                      <Divider
                        orientation="left"
                        style={{
                          background: COLOR.PRIMARY,
                          borderTopColor: COLOR.PRIMARY_TEXT,
                        }}
                      >
                        <Avatar
                          className="circle-icon-trade"
                          size={40}
                          src={
                            <SwapVertical
                              onClick={() => this.changeAssets()}
                              style={{
                                marginRight: 0,
                              }}
                            />
                          }
                        />
                      </Divider>
                    </div>
                    <Form.Item
                      className="item-select-dark"
                      label={
                        <div
                          style={{
                            display: "flex",
                            marginTop: "5px",
                          }}
                        >
                          <Text className="label-dark">
                            {this.props.t("fiatCrypto.to")}
                          </Text>
                        </div>
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          //float: "left",
                          alignItems: "center",
                          // width:
                          //   this.props.source !== "header" ? "110%" : "105%",
                        }}
                      >
                        <Image
                          src={this.props.assetActiveTo.icon}
                          width={32}
                          preview={false}
                        />
                        <div
                          id="select-dark-buy"
                          className="ant-select-selector ant-select"
                        >
                          <Select
                            showSearch
                            filterOption={(input, option) => {
                              if (option.value) {
                                let val = option.value.split("_")[2];
                                return (
                                  val
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                );
                              }
                            }}
                            suffixIcon={
                              <RightOutlined style={{ color: "#ccc" }} />
                            }
                            size="large"
                            bordered={false}
                            style={{ color: "#ccc" }}
                            value={this.props.labelActiveTo}
                            onChange={(value) => this.handleTo(value)}
                            optionLabelProp="label"
                          >
                            {this.props.listAssetTo.length !== 0 &&
                              this.props.listAssetTo.map((item, i) => {
                                return (
                                  <Option
                                    key={item.id}
                                    value={
                                      item.alias !== null
                                        ? item.value +
                                          "_" +
                                          item.id +
                                          "_" +
                                          item.alias
                                        : item.value +
                                          "_" +
                                          item.id +
                                          "_" +
                                          item.name
                                    }
                                  >
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Image
                                        style={{ paddingRight: 5 }}
                                        src={item.icon}
                                        width={30}
                                        preview={false}
                                      />
                                      {item.alias !== null
                                        ? item.alias
                                        : item.name}
                                    </div>
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                    </Form.Item>
                  </Form>
                </Card>
              </Row>
              <Row justify="center">
                <Button
                  disabled={this.disabledButton()}
                  loading={this.props.loading}
                  block
                  type="primary"
                  style={{
                    height: "60px",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  onClick={() =>
                    this.props.next(
                      this.props.assetActiveFrom.type === "FIAT"
                        ? "BUY"
                        : "SELL"
                    )
                  }
                >
                  {this.props.assetActiveFrom.type === "FIAT"
                    ? this.props.t("buy.title") +
                      " " +
                      this.props.assetActiveTo.name
                    : this.props.t("sell.title") +
                      " " +
                      this.props.assetActiveFrom.name}
                </Button>
              </Row>

              <Row justify="space-between" style={{ marginTop: "1rem" }}>
                <Text
                  style={{
                    color: "#898996",
                    fontSize: 14,
                  }}
                >
                  {this.props.assetActiveFrom.type === "CRYPTO"
                    ? this.props.t("sell.minSell")
                    : this.props.t("buy.minBuy")}
                </Text>
                {this.props.loadingMinExchange && (
                  <LoadingOutlined
                    style={{ fontSize: 14, color: "#ccc" }}
                    spin
                  />
                )}
                {!this.props.loadingMinExchange && (
                  <Text
                    style={{
                      color: "#898996",
                      fontSize: 14,
                    }}
                  >
                    <NumberFormat
                      thousandSeparator={true}
                      displayType="text"
                      prefix={this.props.assetActiveFrom.symbol + " "}
                      decimalScale={
                        this.props.assetActiveFrom.type === "CRYPTO" ? 8 : 2
                      }
                      value={this.props.recommendedMinExchange}
                    />
                  </Text>
                )}
              </Row>
              {maxTrade !== undefined && maxTrade !== "-1" && (
                <Row justify="space-between">
                  <Text
                    style={{
                      color: "#898996",
                      fontSize: 14,
                    }}
                  >
                    {this.props.assetActiveFrom.type === "CRYPTO"
                      ? this.props.t("sell.maxSell")
                      : this.props.t("buy.maxBuy")}
                  </Text>
                  {this.props.loadingMinExchange && (
                    <LoadingOutlined
                      style={{ fontSize: 14, color: "#ccc" }}
                      spin
                    />
                  )}
                  {!this.props.loadingMinExchange && (
                    <Text
                      style={{
                        color: "#898996",
                        fontSize: 14,
                      }}
                    >
                      <NumberFormat
                        thousandSeparator={true}
                        displayType="text"
                        prefix={this.props.assetActiveFrom.symbol + " "}
                        decimalScale={
                          this.props.assetActiveFrom.type === "CRYPTO" ? 8 : 2
                        }
                        value={maxTrade}
                      />
                    </Text>
                  )}
                </Row>
              )}
              <Row style={{ height: "100%" }}>
                <Col span={12} style={{ textAlign: "left" }}>
                  <Text
                    style={{
                      color: "#898996",
                      fontSize: 14,
                      textAlign: "left",
                    }}
                  >
                    {this.props.assetActiveFrom.value !== undefined
                      ? this.props.assetActiveFrom.value
                      : ""}{" "}
                    balance
                  </Text>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Text style={{ color: "#898996", fontSize: 14 }}>
                    <NumberFormat
                      thousandSeparator={true}
                      displayType="text"
                      prefix={this.props.assetActiveFrom.symbol + " "}
                      decimalScale={
                        this.props.assetActiveFrom.type === "CRYPTO" ? 8 : 2
                      }
                      value={parseFloat(this.props.assetActiveBalance)}
                    />{" "}
                    {this.props.assetActiveFrom.value !==
                      this.props.nativeCurrency && (
                      <NumberFormat
                        thousandSeparator={true}
                        displayType="text"
                        prefix={" ≈ " + this.props.nativeSymbol + " "}
                        decimalScale={2}
                        value={parseFloat(this.props.nativeBalance)}
                      />
                    )}
                  </Text>
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    banking: state.banking.accounts,
    asset: state.siderNavigate.asset,
    currentLimit: state.exchange.currentLimits,
  };
};

const mapDispatchToProps = { getAccounts };
const FiatCryptoTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(FiatCrypto)
);

export default FiatCryptoTranslated;
