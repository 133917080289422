import React, { Component } from "react";
import "./send.css";
import {
  Row,
  Col,
  Space,
  Typography,
  Button,
  Divider,
  Input,
  message,
} from "antd";
import COLOR from "../../const/colors";
import Email from "@2fd/ant-design-icons/lib/Email";
import KeyboardBackspace from "@2fd/ant-design-icons/lib/KeyboardBackspace";
import { currentToken } from "../../redux/actions/tokenActions";
import { confirmPay, getAccounts } from "../../redux/actions/bankingActions";
import { getAllTransactionsOrders } from "../../redux/actions/transactionsActions";
import { getTimeline } from "../../redux/actions/timeline";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
const { Text, Title } = Typography;
class TwoFactorSend extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      codeVerification: "",
      loading: false,
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleCode(e) {
    const reg = /^[0-9]+$/;
    if (reg.test(e.target.value)) {
      this.setState({ codeVerification: e.target.value });
    }
  }
  async verificationCode() {
    try {
      this.setState({ loading: true });
      let clientId = this.props.token.clientId;
      let accessToken = this.props.token.current;
      let body = {
        otp: this.state.codeVerification.toString(),
      };
      await this.props.confirmPay(clientId, accessToken, body);
      let response = this.props.responseConfirm;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }

      if (response.status === 200) {
        if (this._isMounted) {
          this.setState({ loading: false });
          message.success(this.props.t("send.messages.successPay"), 15);
          if (this.props.source === "header") {
            this.props.closeModal();
          }
          this.props.resetCurrent();
          await this.getRecentTransactionsOrders();
          await this.getAccounts();
        }
      } else if (response.status === 202) {
        if (this._isMounted) {
          this.setState({ loading: false });
          message.success(this.props.t("send.messages.successPayAccepted"), 15);
          if (this.props.source === "header") {
            this.props.closeModal();
          }
          this.props.resetCurrent();
          await this.getRecentTransactionsOrders();
          await this.getAccounts();
          await this.getTimelineBalance();
        }
      } else {
        this.setState({ loading: false });
        if (response.status === 403 || response.status === 400) {
          if (response.data.error_details.payload_error !== undefined) {
            let messageError = response.data.error_details.payload_error;
            message.error(this.props.t("send.messages." + messageError), 10);
          }
          if (response.data.error_details.validation_error !== undefined) {
            let messageError = response.data.error_details.validation_error;
            message.error(this.props.t("send.messages." + messageError), 10);
          }
        } else if (
          response.status === 401 ||
          response.status === 404 ||
          response.status === 422 ||
          response.status === 500
        ) {
          message.error(this.props.t("send.messages.errorServer"));
        }
      }
    } catch (error) {
      this.setState({ loading: false });
      message.error(this.props.t("send.messages.errorServer"));
    }
  }
  async getRecentTransactionsOrders() {
    try {
      this.setState({ loading: true });
      await this.props.getAllTransactionsOrders(
        this.props.token.clientId,
        this.props.token.current
      );
      let response = this.props.respTransactionsOrders;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async getAccounts() {
    try {
      await this.props.getAccounts(
        this.props.token.clientId,
        this.props.token.current
      );
      let response = this.props.banking.accounts;
      let newAccessToken = "";
      if (response.headers !== undefined) {
        newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
    } catch (error) {}
  }
  async getTimelineBalance() {
    try {
      await this.props.getTimeline(
        this.props.token.clientId,
        this.props.token.current,
        "1d"
      );
      let response = this.props.respTimeline;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
    } catch (error) {}
  }
  render() {
    return (
      <Row
        style={{ paddingTop: 20 }}
        justify="center"
        gutter={[48, { xs: 16, sm: 16, md: 16, lg: 24, xl: 24 }]}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          style={{ textAlign: "center" }}
        >
          <Row
            justify="center"
            gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
          >
            <Col
              xs={{ span: 3, offset: 0 }}
              sm={{ span: 3, offset: 0 }}
              md={{ span: 3, offset: 0 }}
              lg={{ span: 3, offset: 0 }}
              xl={{ span: 3, offset: 0 }}
            >
              <KeyboardBackspace
                style={{ color: COLOR.PRIMARY_TEXT, fontSize: 30 }}
                onClick={() => this.props.prev()}
              />
            </Col>
            <Col
              xs={{ span: 18, offset: 0 }}
              sm={{ span: 18, offset: 0 }}
              md={{ span: 18, offset: 0 }}
              lg={{ span: 18, offset: 0 }}
              xl={{ span: 18, offset: 0 }}
              style={{ textAlign: "center" }}
            >
              <Title level={3} style={{ color: COLOR.PRIMARY_TEXT }}>
                {this.props.t("send.twoFactor.title")}
              </Title>
            </Col>
            <Col
              xs={{ span: 3, offset: 0 }}
              sm={{ span: 3, offset: 0 }}
              md={{ span: 3, offset: 0 }}
              lg={{ span: 3, offset: 0 }}
              xl={{ span: 3, offset: 0 }}
            ></Col>
          </Row>
          <br />
          <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]} justify="center">
            <Space direction="vertical" style={{ justifyContent: "center" }}>
              <Title level={4} style={{ color: COLOR.PRIMARY_TEXT }}>
                {this.props.t("twofactor.titleSecurity")}
              </Title>
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 16,
                }}
              >
                {this.props.t("send.twoFactor.text")}
              </Text>
            </Space>
          </Row>
          <Divider
            style={{
              background: COLOR.PRIMARY_TEXT,
            }}
          ></Divider>
          <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]} justify="center">
            <Space direction="vertical">
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 16,
                  padding: "0px 24px",
                }}
              >
                {this.props.t("twofactor.enterCode")}
              </Text>

              <Space direction="horizontal" size="small">
                <Email
                  style={{
                    color: COLOR.GREEN,
                    fontSize: 40,
                    width: "20%",
                    float: "right",
                  }}
                />
                <Input
                  value={this.state.codeVerification}
                  maxLength={6}
                  size="large"
                  style={{ width: "50%", color: "black" }}
                  placeholder="000000"
                  onChange={(e) => this.handleCode(e)}
                />
              </Space>

              <div>
                <Text
                  style={{
                    color: COLOR.PRIMARY_TEXT,
                    padding: "0px 10px 0px 0px",
                  }}
                >
                  {this.props.t("twofactor.noReceived")}
                </Text>
                <Button
                  type="link"
                  className="button-twofactor-send"
                  disabled={this.props.disableResend}
                  style={{
                    color: COLOR.GREEN_1,
                    fontWeight: "bold",
                    padding: 0,
                  }}
                  onClick={() => this.props.sendPayment(true)}
                >
                  {this.props.t("twofactor.resend")}
                </Button>
              </div>
            </Space>
          </Row>
          <Divider
            style={{
              background: COLOR.PRIMARY_TEXT,
            }}
          ></Divider>
          <Row gutter={[32, { xs: 8, sm: 16, md: 24, lg: 32 }]} justify="end">
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <Button
                block
                size="large"
                type="primary"
                loading={this.state.loading}
                disabled={
                  this.state.codeVerification === "" ||
                  this.state.codeVerification.toString().length !== 6
                }
                onClick={() => this.verificationCode()}
                style={{
                  height: 60,
                  fontSize: 18,
                  fontWeight: 600,
                  borderRadius: "5px",
                }}
              >
                {this.props.t("twofactor.buttonConfirm")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.tokenAccess,
    responseConfirm: state.banking.confirmPayResponse,
    respTransactionsOrders: state.transactions.transactionsAndOrders,
    banking: state.banking.accounts,
    respTimeline: state.timeline.resultTimeline,
  };
};

const mapDispatchToProps = {
  currentToken,
  confirmPay,
  getAccounts,
  getAllTransactionsOrders,
  getTimeline,
};

const TwoFactorSendInTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(TwoFactorSend)
);

export default TwoFactorSendInTranslated;
