import React, { Component } from "react";
import {
  Button,
  Space,
  Typography,
  Row,
  Col,
  Card,
  Input,
  Divider,
  Form,
  Image,
} from "antd";
import "./send.css";
import { withTranslation } from "react-i18next";
import COLOR from "../../const/colors";
import { WalletFilled, EditFilled } from "@ant-design/icons";
import KeyboardBackspace from "@2fd/ant-design-icons/lib/KeyboardBackspace";
import { getAccounts, sendPay } from "../../redux/actions/bankingActions";
import { currentToken } from "../../redux/actions/tokenActions";
import { selectedAsset } from "../../redux/actions/navigateActions";
import { connect } from "react-redux";
import { asset } from "../../common/assets";
import NumberFormat from "react-number-format";
const { Text, Title } = Typography;
class ConfirmSend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  isCrypto(currency) {
    let data = asset.assets;
    let typeCurrency = data.find((element) => {
      return element.value === currency;
    });
    if (typeCurrency.type === "FIAT") {
      return false;
    } else {
      return true;
    }
  }
  findFee() {
    let fee = "";
    let accountsInfo = this.props.banking.data.data.accounts;
    let dataUser = this.props.dataUser.data?.data?.user;
    let infoFee = this.props.fees;
    let feeType = dataUser?.trading_fee_type;
    // let amount =
    //   this.props.amountSendFloat !== 0
    //     ? this.props.amountSendFloat
    //     : this.props.amountSendFiatFloat;
    let sourceAccount = accountsInfo.find(
      (element) => element.currency === this.props.assetActive.value
    );
    if (feeType === "TIERS" && infoFee.value_type === "PERCENTAGE") {
      fee = parseFloat(infoFee.withdrawal_amount);
      // fee =
      //   (amount * parseFloat(infoFee.withdrawal_amount)) /
      //   (100 - parseFloat(infoFee.withdrawal_amount));
      // if (fee <= infoFee.min_withdrawal_amount) {
      //   fee = infoFee.min_withdrawal_amount;
      // }
    } else if (feeType === "FIXED" || infoFee.value_type === "FIXED") {
      if (this.props.assetActive.type === "CRYPTO") {
        let accounts = sourceAccount.account_data.networks;
        let network = "";
        if (
          this.props.assetActive.value === "ETH" ||
          this.props.assetActive.value === "BTC"
        ) {
          network = "BASE_ASSET";
        } else {
          network = this.props.selectedNetwork;
        }
        let findNetwork = accounts.find(
          (element) => element.network === network
        );
        if (infoFee.type === "INTERNAL") {
          fee = infoFee.withdrawal_amount;
        } else {
          let findFee = infoFee.networks.find(
            (element) => element.network === findNetwork.network
          );
          fee = parseFloat(findFee.withdrawal_amount);
        }
      } else {
        fee = parseFloat(infoFee.withdrawal_amount);
      }
    }
    return fee;
  }

  render() {
    let dataUser = this.props.dataUser.data?.data?.user;
    let infoFee = this.props.fees;
    let feeType = dataUser?.trading_fee_type;
    let amountSend = 0;
    let total = 0;
    let totalLocalCurrency = 0;
    let amountLocalCurrency = 0;
    let fee = parseFloat(this.findFee());
    let feeNative = 0;
    let calculatedFee = 0;
    let remainingBalance = 0;
    let finalGbpBalance = 0;
    let finalBalanceLocalCurrency = 0;
    let amountFiat = this.props.amountSendFiatFloat;
    if (this.props.amountSendFloat !== 0) {
      amountSend = this.props.amountSendFloat - fee;
      if (this.props.assetActive.value !== this.props.nativeCurrency) {
        amountLocalCurrency = amountSend * this.props.rateCurrent.rate;
        totalLocalCurrency = amountLocalCurrency.toFixed(8);
      } else {
        totalLocalCurrency = amountSend.toFixed(8);
      }
    }

    if (amountFiat !== 0) {
      calculatedFee = ((amountFiat * fee) / (100 - fee)).toFixed(2);
      if (feeType === "TIERS" && infoFee.value_type === "PERCENTAGE") {
        if (calculatedFee <= infoFee.min_withdrawal_amount) {
          calculatedFee = infoFee.min_withdrawal_amount;
        }
      }
      remainingBalance = parseFloat(this.props.assetActiveBalance) - amountFiat;
      amountLocalCurrency = amountFiat.toFixed(2);
      amountSend = amountFiat / this.props.rateCurrent.rate;
      total = amountFiat + parseFloat(calculatedFee);
      finalGbpBalance = remainingBalance - parseFloat(calculatedFee);
      if (this.props.assetActive.value !== this.props.nativeCurrency) {
        amountSend = amountFiat;
        amountLocalCurrency = (
          amountFiat * this.props.rateCurrent.rate
        ).toFixed(2);
        let minLocal =
          infoFee.min_withdrawal_amount * this.props.rateCurrent.rate;
        feeNative = ((amountLocalCurrency * fee) / (100 - fee)).toFixed(2);
        if (feeType === "TIERS" && infoFee.value_type === "PERCENTAGE") {
          if (feeNative <= minLocal) {
            feeNative = minLocal;
          }
        }
        totalLocalCurrency = amountLocalCurrency + parseFloat(feeNative);
        finalBalanceLocalCurrency =
          parseFloat(this.props.nativeBalance) - totalLocalCurrency;
      } else {
        amountSend = amountFiat;
      }
    }

    return (
      <Row
        style={{ paddingTop: 20 }}
        justify="center"
        gutter={[48, { xs: 16, sm: 16, md: 16, lg: 24, xl: 24 }]}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
        >
          <Row
            justify="center"
            gutter={[8, { xs: 8, sm: 8, md: 8, lg: 48, xl: 8 }]}
          >
            <Col
              xs={{ span: 3, offset: 0 }}
              sm={{ span: 3, offset: 0 }}
              md={{ span: 3, offset: 0 }}
              lg={{ span: 3, offset: 0 }}
              xl={{ span: 3, offset: 0 }}
            >
              <KeyboardBackspace
                style={{ color: COLOR.PRIMARY_TEXT, fontSize: 30 }}
                onClick={() => this.props.prev()}
              />
            </Col>
            <Col
              xs={{ span: 18, offset: 0 }}
              sm={{ span: 18, offset: 0 }}
              md={{ span: 18, offset: 0 }}
              lg={{ span: 18, offset: 0 }}
              xl={{ span: 18, offset: 0 }}
              style={{ textAlign: "center" }}
            >
              <Title level={3} style={{ color: COLOR.PRIMARY_TEXT }}>
                {this.props.t("send.confirmSend.title")}
              </Title>
            </Col>
            <Col
              xs={{ span: 3, offset: 0 }}
              sm={{ span: 3, offset: 0 }}
              md={{ span: 3, offset: 0 }}
              lg={{ span: 3, offset: 0 }}
              xl={{ span: 3, offset: 0 }}
            ></Col>
          </Row>
          <br />
          <br />
          <Row
            justify="center"
            gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
            style={{ marginBottom: 24 }}
          >
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
              xxl={{ span: 24, offset: 0 }}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Row
                justify="center"
                align="middle"
                gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
              >
                <Form
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Form.Item style={{ width: "100%", marginBottom: 0 }}>
                    <Row gutter={8} justify="center">
                      <Form.Item style={{ marginBottom: 0, marginTop: 5 }}>
                        <NumberFormat
                          prefix={this.props.assetActive.symbol + " "}
                          thousandSeparator={true}
                          displayType="text"
                          className="input-amount-dark"
                          id={
                            this.props.source === "wallet"
                              ? "resize-number"
                              : "resize-number-header"
                          }
                          style={{ fontSize: 40 }}
                          decimalScale={
                            this.props.assetActive.type === "CRYPTO" ? 8 : 2
                          }
                          value={amountSend}
                        />
                      </Form.Item>
                    </Row>
                    {this.props.assetActive.value !==
                      this.props.nativeCurrency && (
                      <Row gutter={8} justify="center">
                        <Form.Item style={{ marginBottom: 0 }}>
                          <NumberFormat
                            prefix={this.props.nativeSymbol}
                            thousandSeparator={true}
                            displayType="text"
                            className="input-amount-secondary"
                            decimalScale={
                              this.isCrypto(this.props.nativeCurrency) ? 8 : 2
                            }
                            style={{ color: COLOR.SECONDARY_TEXT }}
                            value={amountLocalCurrency.toString()}
                          />
                        </Form.Item>
                      </Row>
                    )}
                  </Form.Item>
                </Form>
              </Row>
            </Col>
          </Row>
          <Row
            justify="center"
            gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
          >
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <Space
                direction="vertical"
                style={{ width: "100%", marginTop: 0 }}
                size="middle"
              >
                <Row justify="center">
                  <Card className="card-form" bodyStyle={{ padding: 0 }}>
                    <Form
                      labelAlign="left"
                      labelCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 8 },
                        lg: { span: 8 },
                        xl: { span: 8 },
                        xxl: { span: 10 },
                      }}
                      wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 16 },
                        lg: { span: 16 },
                        xl: { span: 16 },
                        xxl: { span: 14 },
                      }}
                      layout="horizontal"
                    >
                      <Form.Item
                        className="item-send-dark"
                        label={
                          <Text className="label-dark">
                            {this.props.t("send.to")}
                          </Text>
                        }
                      >
                        <Input.Group>
                          <Row>
                            <Col
                              span={2}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <WalletFilled
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                }}
                              />
                            </Col>
                            <Col span={22} style={{ paddingLeft: 10 }}>
                              <Text
                                style={{
                                  color: COLOR.PRIMARY_TEXT,

                                  fontSize: 16,
                                }}
                              >
                                {this.props.beneficiary.value}
                              </Text>
                            </Col>
                          </Row>
                        </Input.Group>
                      </Form.Item>
                      {this.props.note !== "" && (
                        <div>
                          <Divider
                            style={{
                              background: COLOR.PRIMARY_TEXT,
                              margin: 0,
                            }}
                          />
                          <Form.Item
                            className="item-send-dark"
                            label={
                              <Text className="label-dark">
                                {this.props.t("send.note")}
                              </Text>
                            }
                          >
                            <Input.Group>
                              <Row>
                                <Col
                                  span={2}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <EditFilled
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                    }}
                                  />
                                </Col>
                                <Col span={22} style={{ paddingLeft: 10 }}>
                                  <Text
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                    }}
                                  >
                                    {this.props.note}
                                  </Text>
                                </Col>
                              </Row>
                            </Input.Group>
                          </Form.Item>
                        </div>
                      )}

                      <Divider
                        style={{ background: COLOR.PRIMARY_TEXT, margin: 0 }}
                      />
                      <Form.Item
                        className="item-select-dark"
                        label={
                          <Text className="label-dark">
                            {this.props.t("send.payWith")}
                          </Text>
                        }
                      >
                        <div
                          style={{
                            display: "inline-flex",
                            float: "left",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            src={this.props.assetActive.icon}
                            width={25}
                            preview={false}
                          />
                          <Text
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              marginLeft: 10,
                              fontSize: 16,
                            }}
                          >
                            {this.props.assetActive.name}
                          </Text>
                        </div>
                      </Form.Item>
                    </Form>
                  </Card>
                </Row>
                {!this.isCrypto(this.props.assetActive.value) && (
                  <Row justify="space-between">
                    <Text
                      style={{
                        color: COLOR.SECONDARY_TEXT,
                        marginLeft: 10,
                        fontSize: 16,
                      }}
                    >
                      {this.props.t("send.confirmSend.remainingBalance")}
                    </Text>
                    <Text
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        marginLeft: 10,
                        fontSize: 16,
                      }}
                    >
                      <NumberFormat
                        prefix={this.props.assetActive.symbol + " "}
                        thousandSeparator={true}
                        displayType="text"
                        decimalScale={2}
                        value={remainingBalance}
                      />
                    </Text>
                  </Row>
                )}
                <Row justify="space-between">
                  <Text
                    style={{
                      color: COLOR.SECONDARY_TEXT,
                      marginLeft: 10,
                      fontSize: 16,
                    }}
                  >
                    {this.props.t("send.confirmSend.fee")}
                  </Text>
                  <Text
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      marginLeft: 10,
                      fontSize: 16,
                    }}
                  >
                    {this.isCrypto(this.props.assetActive.value) ? (
                      <NumberFormat
                        suffix={" " + this.props.assetActive.value}
                        thousandSeparator={true}
                        displayType="text"
                        decimalScale={8}
                        value={fee}
                      />
                    ) : (
                      <NumberFormat
                        prefix={this.props.assetActive.symbol + " "}
                        thousandSeparator={true}
                        displayType="text"
                        decimalScale={2}
                        value={calculatedFee}
                      />
                    )}
                  </Text>
                </Row>
                {/* {this.props.assetActive.value !== this.props.nativeCurrency && (
                  <Row justify="space-between">
                    <Text
                      style={{
                        color: COLOR.SECONDARY_TEXT,
                        marginLeft: 10,
                        fontSize: 16,
                      }}
                    >
                      Total
                    </Text>
                    <Text
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        marginLeft: 10,
                        fontSize: 16,
                      }}
                    >
                      <NumberFormat
                        prefix={this.props.nativeSymbol + " "}
                        thousandSeparator={true}
                        displayType="text"
                        decimalScale={
                          this.isCrypto(this.props.nativeCurrency) ? 8 : 2
                        }
                        value={totalLocalCurrency}
                      />
                    </Text>
                  </Row>
                )} */}
                {/* <Row justify="space-between">
                  <Text
                    style={{
                      color: COLOR.SECONDARY_TEXT,
                      marginLeft: 10,
                      fontSize: 16,
                    }}
                  >
                    {this.props.t("send.confirmSend.paymentReceive")}
                  </Text>
                  <Text
                    style={{
                      color: COLOR.SECONDARY_TEXT,
                      marginLeft: 10,
                      fontSize: 16,
                    }}
                  >
                    {this.props.t("send.confirmSend.est")}
                  </Text>
                </Row> */}
                <Row justify="center">
                  {this.props.showMessageLimit && (
                    <Text
                      style={{
                        color: COLOR.ERROR,
                        textAlign: "center",
                        fontSize: 14,
                        marginBottom: 10,
                      }}
                    >
                      {this.props.messageLimit}
                    </Text>
                  )}
                  <Button
                    block
                    type="primary"
                    style={{
                      height: "60px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    loading={this.props.loading}
                    onClick={() => this.props.sendPayment(false)}
                    disabled={
                      this.props.assetActive.type === "FIAT"
                        ? parseFloat(total) >
                          parseFloat(this.props.assetActiveBalance)
                        : false
                    }
                  >
                    {this.props.t("send.confirmSend.sendNow")}
                  </Button>
                </Row>
                {this.props.assetActive.type === "CRYPTO" && (
                  <Row style={{ marginTop: "1rem" }}>
                    <Col span={12} style={{ textAlign: "left" }}>
                      <Text
                        style={{
                          color: COLOR.SECONDARY_TEXT,
                          fontSize: 14,
                          textAlign: "left",
                        }}
                      >
                        {this.props.assetActive.value !== undefined
                          ? this.props.assetActive.value
                          : ""}{" "}
                        balance
                      </Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                      <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 14 }}>
                        {this.props.assetActive.type === "CRYPTO" ? (
                          <NumberFormat
                            thousandSeparator={true}
                            displayType="text"
                            suffix={" " + this.props.assetActive.symbol}
                            decimalScale={8}
                            value={this.props.assetActiveBalance}
                          />
                        ) : (
                          <NumberFormat
                            thousandSeparator={true}
                            displayType="text"
                            prefix={this.props.assetActive.symbol + " "}
                            decimalScale={2}
                            value={this.props.assetActiveBalance}
                          />
                        )}{" "}
                        {this.props.assetActive.value !==
                          this.props.nativeCurrency && (
                          <NumberFormat
                            thousandSeparator={true}
                            displayType="text"
                            prefix={"≈ " + this.props.nativeSymbol + " "}
                            decimalScale={2}
                            value={parseFloat(this.props.nativeBalance)}
                          />
                        )}
                      </Text>
                    </Col>
                  </Row>
                )}
                {this.props.assetActive.type === "FIAT" && (
                  <>
                    {finalGbpBalance > 0 && (
                      <Row style={{ marginTop: "1rem" }}>
                        <Col span={12} style={{ textAlign: "left" }}>
                          <Text
                            style={{
                              color: COLOR.SECONDARY_TEXT,
                              fontSize: 14,
                              textAlign: "left",
                            }}
                          >
                            Final{" "}
                            {this.props.assetActive.value !== undefined
                              ? this.props.assetActive.value
                              : ""}{" "}
                            balance
                          </Text>
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <Text
                            style={{ color: COLOR.PRIMARY_TEXT, fontSize: 14 }}
                          >
                            <NumberFormat
                              thousandSeparator={true}
                              displayType="text"
                              prefix={this.props.assetActive.symbol + " "}
                              decimalScale={2}
                              value={finalGbpBalance}
                            />

                            {this.props.assetActive.value !==
                              this.props.nativeCurrency && (
                              <NumberFormat
                                thousandSeparator={true}
                                displayType="text"
                                prefix={" ≈ " + this.props.nativeSymbol + " "}
                                decimalScale={2}
                                value={finalBalanceLocalCurrency}
                              />
                            )}
                          </Text>
                        </Col>
                      </Row>
                    )}
                    {parseFloat(total) >
                      parseFloat(this.props.assetActiveBalance) && (
                      <Row justify="center">
                        <Text
                          style={{
                            color: COLOR.ERROR,
                            textAlign: "center",
                            fontSize: 14,
                            marginBottom: 5,
                          }}
                        >
                          Insufficient balance to cover fees
                        </Text>
                      </Row>
                    )}
                  </>
                )}
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    banking: state.banking.accounts,
    asset: state.siderNavigate.asset,
    send: state.banking.sendResp,
    respFees: state.banking.fees,
    token: state.tokenAccess,
    dataUser: state.authUser.userData,
  };
};

const mapDispatchToProps = {
  getAccounts,
  selectedAsset,
  sendPay,
  currentToken,
};
const ConfirmSendTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmSend)
);

export default ConfirmSendTranslated;
