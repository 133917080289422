import React, { Component } from "react";
import COLOR from "../../const/colors";
import "./wallet.css";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Typography,
  Space,
  Row,
  Col,
  Table,
  Card,
  Avatar,
  Image,
  Tag,
  Modal,
  Divider,
  Skeleton,
  message,
  Button,
  Popconfirm,
} from "antd";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  SwapOutlined,
  CopyOutlined,
  CheckOutlined,
  CloseOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { asset } from "../../common/assets";
import NumberFormat from "react-number-format";
import {
  getTransactionsCurrency,
  transactionCancelation,
} from "../../redux/actions/transactionsActions";
import { currentToken } from "../../redux/actions/tokenActions";
import moment from "moment";
const { Text, Title, Paragraph, Link } = Typography;
const URL_ETH =
  process.env.REACT_APP_ENV === "production"
    ? "https://etherscan.io/tx/"
    : "https://sepolia.etherscan.io/tx/";
const URL_BTC =
  process.env.REACT_APP_ENV === "production"
    ? "https://live.blockcypher.com/btc/tx/"
    : "https://live.blockcypher.com/btc-testnet/tx/";
const URL_TRON =
  process.env.REACT_APP_ENV === "production"
    ? "https://tronscan.org/#/transaction/"
    : "https://shasta.tronscan.org/#/transaction/";
class TransactionsWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountActive: "",
      balance: 0,
      modalDetailTx: false,
      transactionSelected: "",
      lastId: null,
      previousId: null,
      transactionList: [],
      status: "",
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      selectedTransactionId: "",
      lockedBalance: null,
    };
  }
  componentDidMount() {
    this.setState({ lastId: null, previousId: null });
    window.scrollTo(0, 0);
    this.balanceAccount();
    this.getTransactions();
  }
  getNoEqualsTxStatus(newTx, prevTx) {
    let statusget = false;
    //  console.log(newTx, prevTx);
    for (let ntx of newTx) {
      const { _id, status } = ntx;
      let findPrevTx = prevTx.find((tx) => {
        return tx._id === _id;
      });
      if (findPrevTx) {
        if (status !== findPrevTx.status) {
          statusget = true;
          break;
        }
      }
    }
    return statusget;
  }
  async componentDidUpdate(prevProps) {
    let newData = this.props.respTransactions.data?.data.transactions;
    let prevData = prevProps.respTransactions.data?.data.transactions;
    let resultGetStatusTx = this.getNoEqualsTxStatus(newData, prevData);
    if (
      this.props.buyResponse !== prevProps.buyResponse ||
      this.props.sellResponse !== prevProps.sellResponse ||
      this.props.tradeResponse !== prevProps.tradeResponse ||
      this.props.respTransactionsOrders !== prevProps.respTransactionsOrders ||
      resultGetStatusTx ||
      (prevProps.accountSelected !== "" &&
        prevProps.accountSelected !== this.props.accountSelected)
    ) {
      let page = {
        current: 1,
        pageSize: 10,
        total: 0,
      };
      await this.setState(
        {
          transactionList: [],
          pagination: page,
          lastId: null,
        },
        () => {
          this.balanceAccount();
          this.getTransactions();
        }
      );
    }
  }

  balanceAccount() {
    let dataAsset = asset.assets;
    let data = this.props.accounts.data.data.accounts;
    let nativeBalances = this.props.accounts.data.data.native_balances;
    let assetSelect = this.props.asset.key;
    let find = "";
    let findLocalBalance = nativeBalances.currencies.find(
      (element) => element.currency === assetSelect
    );
    // console.log(findLocalBalance);
    if (this.props.asset.id) {
      find = data.find((element) => element._id === this.props.asset.id);
      if (findLocalBalance.accounts) {
        let native = findLocalBalance.accounts.find(
          (element) => element._id === this.props.asset.id
        );
        findLocalBalance = native;
      }
    } else {
      find = data.find((element) => element.currency === assetSelect);
    }
    if (find !== undefined) {
      this.setState({
        balance: find.available_balance,
        status: find.status,
      });
      if (find.pending_settlement_balance) {
        if (Number(find.pending_settlement_balance) > 0) {
          this.setState({
            lockedBalance: find.pending_settlement_balance,
          });
        }
      }
    }
    let assetActual = dataAsset.find(
      (element) => element.value === assetSelect
    );

    if (assetActual !== undefined && findLocalBalance !== undefined) {
      this.setState({
        accountActive: assetActual,
        nativeBalance: findLocalBalance.native_balance,
        nativeCurrency: nativeBalances.native_currency,
        nativeSymbol: nativeBalances.native_symbol,
      });
    }
  }
  propertiesCurrency(currency) {
    let dataAsset = asset.assets;
    let assetActual = dataAsset.find((element) => element.value === currency);
    return assetActual.type;
  }
  openModalDetail(transaction) {
    this.setState({ modalDetailTx: true, transactionSelected: transaction });
  }
  closeModalDetail() {
    this.setState({ modalDetailTx: false, transactionSelected: "" });
  }
  async getTransactions() {
    try {
      this.setState({ loading: true });
      let assetSelect = this.props.asset;
      let accountsList = this.props.accounts.data.data.accounts;
      accountsList = accountsList.filter((acc) => {
        return acc.status === "ACTIVE";
      });
      let accountSelect = "";
      let asset = "";

      if (assetSelect.id) {
        accountSelect = accountsList.find(
          (element) => element._id === assetSelect.id
        );
      } else {
        accountSelect = accountsList.find(
          (element) => element.currency === assetSelect.key
        );
      }
      asset = accountSelect._id;
      if (this.state.lastId === null) {
        await this.props.getTransactionsCurrency(
          this.props.clientId,
          this.props.accessToken,
          asset
        );
      } else {
        await this.props.getTransactionsCurrency(
          this.props.clientId,
          this.props.accessToken,
          asset,
          this.state.lastId
        );
      }

      let response = this.props.respTransactions;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        let info = response.data.data;
        this.setState({
          lastId: info.current_last_id !== null ? info.current_last_id : null,
          previousId: info.previous_id !== null ? info.previous_id : null,
        });
        this.formatTransactions(info.transactions);
      } else if (response.status === 403) {
        message.error(this.props.t("transactions.error.accessError"));
        this.setState({ loading: false });
      } else if (
        response.status === 400 ||
        response.status === 401 ||
        response.status === 404 ||
        response.status === 422
      ) {
        message.error(this.props.t("transactions.error.queryError"));
        this.setState({ loading: false });
      } else if (response.status === 500) {
        message.error(this.props.t("transactions.error.errorServer"));
        this.setState({ loading: false });
      } else {
        message.error(this.props.t("transactions.error.errorServer"));
        this.setState({ loading: false });
      }
    } catch (error) {
      //console.log(error);
      this.setState({ loading: false });
    }
  }
  formatTransactions(data) {
    // console.log("data", data);
    let array = [];
    // console.log(data);
    for (let transaction of data) {
      let objTransaction = {};
      Object.entries(transaction).forEach(([key, value]) => {
        objTransaction[key] = value;

        if (key === "created_date") {
          objTransaction.date = this.formatDateView(value);
          objTransaction.dateFormat = this.formatDate(value);
        }

        if (key === "reference") {
          objTransaction.reference = value;
          if (value.includes("Exchange")) {
            let referenceExchange = value.split(" ")[1];
            if (referenceExchange !== undefined) {
              objTransaction.baseCurrency = referenceExchange.split("-")[0];
              objTransaction.quoteCurrency = referenceExchange.split("-")[1];
            }
          }
        }
        if (key === "_id") {
          objTransaction.id_transaction = value;
        }

        if (key === "destination") {
          let objDestination = {};
          Object.entries(value).forEach(([innerKey, innerValue]) => {
            objDestination[innerKey] = innerValue;
          });
          objTransaction.destination = objDestination;
        }
        if (key === "source") {
          let objSource = {};
          Object.entries(value).forEach(([innerKey, innerValue]) => {
            objSource[innerKey] = innerValue;
          });
          objTransaction.source = objSource;
        }
        if (key === "type" && value === "EXCHANGE_OUT") {
          if (transaction?.account) {
            const { currency } = transaction.account;
            objTransaction.baseCurrency = currency;
          }
          if (transaction?.destination) {
            const { currency } = transaction?.destination;
            objTransaction.quoteCurrency = currency;
          }
        }
        if (key === "type" && value === "EXCHANGE_IN") {
          if (transaction?.account) {
            const { currency } = transaction.account;
            objTransaction.quoteCurrency = currency;
          }
          if (transaction?.source) {
            const { currency } = transaction?.source;
            objTransaction.baseCurrency = currency;
          }
        }
      });
      array.push(objTransaction);
    }

    let page = {
      current: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      total: this.state.transactionList.length + array.length,
    };
    this.setState({
      transactionList: [...this.state.transactionList, ...array],
      pagination: page,
      loading: false,
    });
  }
  formatDate(dateTransaction) {
    let dateMoment = moment(dateTransaction).format("YYYY/MM/DD HH:mm:ss");
    let date = new Date(Date.parse(dateMoment + " UTC"));
    let dateCreated =
      date.toString().split(" ")[1] + " " + date.toString().split(" ")[2];
    return dateCreated;
  }
  formatDateView(dateTransaction) {
    let dateMoment = moment(dateTransaction).format("YYYY/MM/DD HH:mm:ss");
    let date = new Date(dateMoment + " UTC");
    let dateCreated =
      moment(date).format("DD-MM-YYYY") + " " + this.formatAmPm(date);
    return dateCreated;
  }

  formatAmPm(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  handleTableChange = (pagination) => {
    let newPagination = {};
    if (this.state.pagination.current < pagination.current) {
      newPagination = {
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total + 1,
      };
      if (pagination.current % 2 !== 0) {
        this.getTransactions();
      }
    } else {
      newPagination = {
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
      };
    }
    this.setState({ pagination: newPagination });
  };
  showUrl() {
    let tx = this.state.transactionSelected;
    let url = "";
    if (tx.account.currency === "BTC") {
      url = URL_BTC + tx.transaction_hash;
    } else if (tx.account.currency === "ETH") {
      url = URL_ETH + tx.transaction_hash;
    } else {
      if (
        tx.destination?.network === "ERC20" ||
        tx.source?.network === "ERC20"
      ) {
        url = URL_ETH + tx.transaction_hash;
      } else {
        url = URL_TRON + tx.transaction_hash;
      }
    }
    return url;
  }
  async closeTransaction() {
    try {
      this.setState({ loading: true });

      await this.props.transactionCancelation(
        this.props.token.clientId,
        this.props.token.current,
        this.state.selectedTransactionId
      );
      let response = this.props.respCancelTransaction;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        this.balanceAccount();
        this.getTransactions();
        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 500);
      } else if (
        response.status === 400 ||
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404 ||
        response.status === 422
      ) {
        message.error(this.props.t("transactions.error.queryError"));
        this.setState({ loading: false });
      } else if (response.status === 500) {
        message.error(this.props.t("transactions.error.errorServer"));
        this.setState({ loading: false });
      } else {
        message.error(this.props.t("transactions.error.errorServer"));
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }
  render() {
    let dataUser = this.props.dataUser.data?.data?.user;
    let feeType = dataUser?.trading_fee_type;
    let userType = dataUser.type;
    const columns = [
      {
        dataIndex: "dateFormat",
        key: "dateFormat",
        width: 55,
        className: "column-date",
        render: (dateFormat, originNode) => (
          <>
            <Space
              size={0}
              align="center"
              direction="vertical"
              onClick={() => {
                this.openModalDetail(originNode);
              }}
            >
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 16,
                  fontWeight: 400,
                  marginBottom: 0,
                }}
              >
                {dateFormat.split(" ")[0]}
              </Text>
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 18,
                  fontWeight: 400,
                }}
              >
                {dateFormat.split(" ")[1]}
              </Text>
            </Space>
          </>
        ),
      },
      {
        dataIndex: "icon",
        key: "icon",
        width: 30,
        className: "column-icon",
        render: (record, originNode) => (
          <>
            {(originNode.type === "TRANSFER_OUT" ||
              originNode.type === "WITHDRAWAL") && (
              <Avatar
                onClick={() => {
                  this.openModalDetail(originNode);
                }}
                size={36}
                className="circle-icon-detail"
                src={
                  <ArrowUpOutlined
                    style={{
                      marginRight: 0,
                    }}
                  />
                }
              />
            )}
            {(originNode.type === "TRANSFER_IN" ||
              originNode.type === "DEPOSIT") && (
              <Avatar
                onClick={() => {
                  this.openModalDetail(originNode);
                }}
                className="circle-icon-detail"
                size={36}
                src={
                  <ArrowDownOutlined
                    style={{
                      marginRight: 0,
                    }}
                  />
                }
              />
            )}
            {(originNode.type.includes("EXCHANGE") ||
              originNode.type.includes("REBALANCE")) && (
              <Avatar
                onClick={() => {
                  this.openModalDetail(originNode);
                }}
                className="circle-icon-detail"
                size={36}
                src={
                  <SwapOutlined
                    style={{
                      marginRight: 0,
                    }}
                  />
                }
              />
            )}
          </>
        ),
      },
      {
        dataIndex: "type",
        key: "type",
        render: (record, originNode) => (
          <div
            style={{
              flexDirection: "row",
              alignContent: "center",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <Space
              size={0}
              direction="vertical"
              onClick={() => {
                this.openModalDetail(originNode);
              }}
            >
              <Text
                style={{
                  flexDirection: "row",
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 18,
                  fontWeight: 400,
                }}
              >
                {originNode.type.includes("REBALANCE") && (
                  <>{this.props.t("transactions.type." + originNode.type)}</>
                )}
                {originNode.type.includes("EXCHANGE") && (
                  <>
                    {originNode.reference !== undefined &&
                      originNode.reference !== "" && (
                        <>
                          {this.props.t("transactions.exchange", {
                            base: originNode.baseCurrency,
                            quote: originNode.quoteCurrency,
                          })}
                        </>
                      )}
                  </>
                )}
                {(originNode.type === "TRANSFER_OUT" ||
                  originNode.type === "WITHDRAWAL") &&
                  this.state.accountActive.value +
                    " " +
                    this.props.t("transactions.sent") +
                    " "}

                {(originNode.type === "TRANSFER_IN" ||
                  originNode.type === "DEPOSIT") &&
                  this.state.accountActive.value +
                    " " +
                    this.props.t("transactions.received") +
                    " "}
                {this.state.accountActive.type === "FIAT" && (
                  <>
                    {(originNode.type === "WITHDRAWAL" ||
                      originNode.type === "TRANSFER_OUT") && (
                      <>
                        {originNode.destination !== undefined &&
                          Object.keys(originNode.destination).length > 0 && (
                            <>
                              {originNode.destination.type.includes("INTERNAL")
                                ? originNode.destination.account_holder
                                : originNode.destination.description}
                            </>
                          )}
                        {originNode.destination !== undefined &&
                          Object.keys(originNode.destination).length > 0 && (
                            <Tag
                              style={{ marginLeft: 5 }}
                              color={
                                originNode.destination.type.includes("INTERNAL")
                                  ? "#108ee9"
                                  : "#f50"
                              }
                            >
                              <Text
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  fontSize: 16,
                                  fontWeight: 400,
                                  marginBottom: 0,
                                }}
                              >
                                {originNode.destination.type.includes(
                                  "INTERNAL"
                                )
                                  ? this.props.t("transactions.internalAccount")
                                  : this.props.t(
                                      "transactions.externalAccount"
                                    )}
                              </Text>
                            </Tag>
                          )}
                      </>
                    )}
                    {(originNode.type === "DEPOSIT" ||
                      originNode.type === "TRANSFER_IN") && (
                      <>
                        {originNode.source !== undefined &&
                          Object.keys(originNode.source).length > 0 && (
                            <>
                              {originNode.source.type.includes("INTERNAL")
                                ? originNode.source.account_holder
                                : originNode.source.description}

                              <Tag
                                style={{ marginLeft: 5 }}
                                color={
                                  originNode.source.type.includes("INTERNAL")
                                    ? "#108ee9"
                                    : "#f50"
                                }
                              >
                                <Text
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    fontSize: 16,
                                    fontWeight: 400,
                                    marginBottom: 0,
                                  }}
                                >
                                  {originNode.source.type.includes("INTERNAL")
                                    ? this.props.t(
                                        "transactions.internalAccount"
                                      )
                                    : this.props.t(
                                        "transactions.externalAccount"
                                      )}
                                </Text>
                              </Tag>
                            </>
                          )}
                      </>
                    )}
                  </>
                )}
                {this.state.accountActive.type === "CRYPTO" && (
                  <>
                    {(originNode.type === "WITHDRAWAL" ||
                      originNode.type === "TRANSFER_OUT") && (
                      <>
                        {originNode.destination !== undefined &&
                          originNode.destination.description}
                        {originNode.destination !== undefined &&
                          originNode.destination.type === "EXTERNAL_CIRCLE" &&
                          originNode.destination.billing_name +
                            " - " +
                            originNode.destination.bank_name +
                            " " +
                            originNode.destination.account_number +
                            " "}
                        {originNode.destination !== undefined &&
                          Object.keys(originNode.destination).length > 0 && (
                            <Tag
                              style={{ marginLeft: 5 }}
                              color={
                                originNode.destination.type.includes("INTERNAL")
                                  ? "#108ee9"
                                  : "#f50"
                              }
                            >
                              <Text
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  fontSize: 16,
                                  fontWeight: 400,
                                  marginBottom: 0,
                                }}
                              >
                                {originNode.destination.type.includes(
                                  "INTERNAL"
                                )
                                  ? this.props.t("transactions.internalAccount")
                                  : this.props.t(
                                      "transactions.externalAccount"
                                    )}
                              </Text>
                            </Tag>
                          )}
                      </>
                    )}
                    {(originNode.type === "DEPOSIT" ||
                      originNode.type === "TRANSFER_IN") && (
                      <>
                        {originNode.source !== undefined && (
                          <>
                            {originNode.source.type === "EXTERNAL_CIRCLE" &&
                              originNode.source.billing_name +
                                " - " +
                                originNode.source.bank_name +
                                " " +
                                originNode.source.account_number}
                            {originNode.source.type === "EXTERNAL" &&
                              originNode.account.currency !== "BTC" && (
                                <>
                                  {originNode.source.address !== undefined && (
                                    <>
                                      {originNode.source.address.length > 24
                                        ? originNode.source.address.substr(
                                            0,
                                            8
                                          ) + "..."
                                        : originNode.source.address}
                                    </>
                                  )}
                                </>
                              )}
                            {originNode.source.type !== "EXTERNAL_CIRCLE" &&
                              originNode.source.type !== "EXTERNAL" &&
                              originNode.source.description}
                          </>
                        )}
                        <Tag
                          style={{ marginLeft: 5 }}
                          color={
                            originNode.source.type.includes("INTERNAL")
                              ? "#108ee9"
                              : "#f50"
                          }
                        >
                          <Text
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              fontSize: 16,
                              fontWeight: 400,
                              marginBottom: 0,
                            }}
                          >
                            {originNode.source.type.includes("INTERNAL")
                              ? this.props.t("transactions.internalAccount")
                              : this.props.t("transactions.externalAccount")}
                          </Text>
                        </Tag>
                      </>
                    )}
                  </>
                )}
              </Text>
              <Text
                style={{
                  color: COLOR.SECONDARY_TEXT,
                  fontSize: 16,
                  fontWeight: 400,
                }}
              >
                {originNode.status === "COMPLETED" && (
                  <Tag
                    style={{
                      color: "#389E0D",
                      background: "#F6FFED",
                      borderColor: "#B7EB8F",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("transactions.status.COMPLETED")}
                  </Tag>
                )}
                {originNode.status === "PROCESSING" && (
                  <Tag
                    style={{
                      color: "#08979C",
                      background: "#E6FFFB",
                      borderColor: "#87E8DE",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("transactions.status.PROCESSING")}
                  </Tag>
                )}
                {originNode.status === "INITIATED" && (
                  <Tag
                    style={{
                      color: "#096DD9",
                      background: "#E6F7FF",
                      borderColor: "#91D5FF",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("transactions.status.INITIATED")}
                  </Tag>
                )}
                {originNode.status === "PENDING" && (
                  <Tag
                    style={{
                      color: "#531DAB",
                      background: "#F9F0FF",
                      borderColor: "#D3ADF7",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("transactions.status.PENDING")}
                  </Tag>
                )}
                {originNode.status === "REJECTED" && (
                  <Tag
                    style={{
                      color: "#D4380D",
                      background: "#FFF2E8",
                      borderColor: "#FFBB96",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("transactions.status.REJECTED")}
                  </Tag>
                )}
                {originNode.status === "FAILED" && (
                  <Tag
                    style={{
                      color: "#CF1322",
                      background: "#FFF1F0",
                      borderColor: "#FFA39E",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("transactions.status.FAILED")}
                  </Tag>
                )}
                {originNode.status === "CANCELLED" && (
                  <Tag
                    style={{
                      marginTop: 5,
                      color: "#CF1322",
                      background: "#FFF1F0",
                      borderColor: "#FFA39E",
                      fontSize: 12,
                    }}
                  >
                    {this.props.t("transactions.status.CANCELLED")}
                  </Tag>
                )}
                {originNode.status === "CLIENT_APPROVAL_PENDING" && (
                  <Tag
                    color="gold"
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {this.props.t(
                      "transactions.status.CLIENT_APPROVAL_PENDING"
                    )}
                  </Tag>
                )}
                {originNode.status === "PENDING_SETTLEMENT" && (
                  <Tag
                    color="geekblue"
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("transactions.status.PENDING_SETTLEMENT")}
                  </Tag>
                )}
                {originNode.status === "APPROVAL_EXPIRED" && (
                  <Tag
                    color="magenta"
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("transactions.status.APPROVAL_EXPIRED")}
                  </Tag>
                )}
                {originNode.status === "AML_HOLD" && (
                  <Tag
                    color="gold"
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("transactions.status.AML_HOLD")}
                  </Tag>
                )}
                {originNode.status === "AML_FREEZE" && (
                  <Tag
                    color="geekblue"
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("transactions.status.AML_FREEZE")}
                  </Tag>
                )}
              </Text>
            </Space>
            {originNode.type === "WITHDRAWAL" &&
              originNode.status === "PENDING_SETTLEMENT" && (
                <Popconfirm
                  onOpenChange={() => {
                    this.setState({ selectedTransactionId: originNode._id });
                    //setSelectedId(item._id);
                  }}
                  onConfirm={() => {
                    this.closeTransaction();
                  }}
                  onCancel={() => {
                    this.setState({ selectedTransactionId: "" });
                  }}
                  title={this.props.t(
                    "transactions.popUpMessage.confirmMessage"
                  )}
                  okText={this.props.t(
                    "transactions.popUpMessage.confirmButton"
                  )}
                  cancelText={this.props.t(
                    "transactions.popUpMessage.cancelButton"
                  )}
                  cancelButtonProps={{
                    className: "btn-cancel-pop",
                  }}
                >
                  <Button
                    style={{ marginLeft: 5 }}
                    type="text"
                    shape="circle"
                    icon={
                      <StopOutlined
                        style={{ color: "red", fontSize: 20, marginTop: 10 }}
                      />
                    }
                  />
                </Popconfirm>
              )}
          </div>
        ),
      },
      {
        dataIndex: "total_amount",
        key: "total_amount",
        width: 200,
        className: "column-amount",
        responsive: ["lg", "xl"],
        render: (record, originNode) => (
          <>
            <Space
              size={0}
              align="end"
              direction="vertical"
              onClick={() => {
                this.openModalDetail(originNode);
              }}
            >
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 18,
                  fontWeight: 400,
                }}
              >
                {(originNode.type === "TRANSFER_OUT" ||
                  originNode.type === "WITHDRAWAL" ||
                  originNode.type === "EXCHANGE_OUT" ||
                  originNode.type === "REBALANCE_OUT") &&
                  "- "}
                {(originNode.type === "TRANSFER_IN" ||
                  originNode.type === "DEPOSIT" ||
                  originNode.type === "EXCHANGE_IN" ||
                  originNode.type === "REBALANCE_IN") &&
                  "+ "}
                <NumberFormat
                  thousandSeparator={true}
                  displayType="text"
                  suffix={" " + this.state.accountActive.value}
                  decimalScale={
                    this.state.accountActive.type === "CRYPTO" ? 8 : 2
                  }
                  value={
                    originNode.type === "TRANSFER_OUT" ||
                    originNode.type === "WITHDRAWAL" ||
                    originNode.type === "EXCHANGE_OUT"
                      ? originNode.destination?.type === "EXTERNAL_CIRCLE"
                        ? originNode.total_amount
                        : originNode.amount
                      : originNode.total_amount
                  }
                />
              </Text>
              {/* <Text
                style={{
                  color: COLOR.SECONDARY_TEXT,
                  fontSize: 16,
                  fontWeight: 400,
                  textAlign: "right",
                }}
              >
                {"$ 0.00"}
              </Text> */}
            </Space>
          </>
        ),
      },
    ];
    return (
      <div>
        <Card
          title={
            <>
              <Space
                direction="vertical"
                style={{
                  paddingLeft: 2,
                }}
              >
                <Text
                  style={{
                    color: COLOR.SECONDARY_TEXT,
                    textAlign: "left",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  {this.props.t("wallet.total")}
                </Text>
                <div style={{ display: "inline-flex" }}>
                  <Text
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      fontSize: 22,
                      textAlign: "left",
                      fontWeight: 600,
                      marginRight: 5,
                    }}
                  >
                    <NumberFormat
                      thousandSeparator={true}
                      displayType="text"
                      suffix={" " + this.state.accountActive.value}
                      decimalScale={
                        this.state.accountActive.type === "CRYPTO" ? 8 : 2
                      }
                      value={this.state.balance}
                    />
                  </Text>
                  {this.state.nativeCurrency !==
                    this.state.accountActive.value && (
                    <Text
                      style={{
                        color: COLOR.SECONDARY_TEXT,
                        textAlign: "left",
                        fontSize: 22,
                        fontWeight: 400,
                      }}
                    >
                      <NumberFormat
                        thousandSeparator={true}
                        displayType="text"
                        prefix={this.state.nativeSymbol}
                        decimalScale={2}
                        value={this.state.nativeBalance}
                      />
                    </Text>
                  )}
                </div>
                {this.state.status !== "ACTIVE" && (
                  <Text
                    style={{
                      color: COLOR.ERROR,
                      textAlign: "left",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("wallet.messageStatusAccount", {
                      currency: this.state.accountActive.value,
                      status: this.props.t(
                        "wallet.status." + this.state.status
                      ),
                    })}
                  </Text>
                )}

                {this.state.lockedBalance !== null && (
                  <>
                    <div
                      style={{
                        paddingLeft: 2,
                        alignContent: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginTop: 12,
                      }}
                    >
                      <div>
                        <Text
                          style={{
                            color: COLOR.SECONDARY_TEXT,
                            textAlign: "left",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginBottom: 10,
                          }}
                        >
                          {this.props.t("wallet.pendingBalance")}
                        </Text>
                      </div>
                      <div style={{ display: "inline-flex", marginTop: 10 }}>
                        <Text
                          style={{
                            color: COLOR.SECONDARY_TEXT,
                            fontSize: 22,
                            textAlign: "left",
                            fontWeight: 600,
                            marginRight: 5,
                          }}
                        >
                          <NumberFormat
                            thousandSeparator={true}
                            displayType="text"
                            suffix={" " + this.state.accountActive.value}
                            decimalScale={
                              this.state.accountActive.type === "CRYPTO" ? 8 : 2
                            }
                            value={this.state.lockedBalance}
                          />
                        </Text>
                      </div>
                    </div>
                  </>
                )}
                <br />
                <Text
                  style={{
                    color: COLOR.PRIMARY_TEXT,
                    textAlign: "left",
                    fontSize: 20,
                    fontWeight: 600,
                  }}
                >
                  {this.props.t("wallet.history")}
                </Text>
              </Space>
            </>
          }
          bordered={false}
          size="small"
          style={{
            borderRadius: "5px",
            background: COLOR.PRIMARY,
            color: COLOR.SECONDARY_TEXT,
            border: "1px solid #000",
            width: "98%",
          }}
        >
          {this.state.loading && <Skeleton active />}
          {!this.state.loading && (
            <div>
              {this.state.transactionList.length === 0 && (
                <Row
                  gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
                  style={{ padding: "25px 25px" }}
                >
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    xl={{ span: 24, offset: 0 }}
                    xxl={{ span: 24, offset: 0 }}
                    style={{
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <Space direction="vertical">
                      <Image
                        style={{ opacity: "0.6" }}
                        src={
                          this.state.accountActive.icon !== undefined
                            ? this.state.accountActive.icon
                            : ""
                        }
                        preview={false}
                        width={100}
                      />
                      <Text
                        style={{
                          color: COLOR.PRIMARY_TEXT,
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {this.props.t("wallet.noTransactions")}
                      </Text>

                      <Text
                        style={{
                          color: COLOR.PRIMARY_TEXT,
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                      >
                        {this.props.t("wallet.msgEmpty", {
                          key: this.state.accountActive.value,
                        })}
                      </Text>
                    </Space>
                  </Col>
                </Row>
              )}
              {this.state.transactionList.length > 0 && (
                <>
                  <div
                    id="table-transactions"
                    className="ant-table ant-table-tbody ant-table-thead text-table-cell"
                  >
                    <Table
                      locale={{
                        emptyText: (
                          <Text
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              fontSize: 20,
                              fontWeight: "bold",
                            }}
                          >
                            {this.props.t("wallet.noTransactions")}
                          </Text>
                        ),
                      }}
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: (event) => {
                            // this.openModalDetail(record);
                          }, // click row
                        };
                      }}
                      columns={columns}
                      dataSource={this.state.transactionList}
                      style={{ overflow: "auto" }}
                      pagination={this.state.pagination}
                      onChange={this.handleTableChange}
                      rowKey={"id_transaction"}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </Card>

        {this.state.modalDetailTx && (
          <Modal
            open={this.state.modalDetailTx}
            bodyStyle={{ background: COLOR.PRIMARY }}
            maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
            onOk={() => this.openModalDetail()}
            onCancel={() => this.closeModalDetail()}
            footer={null}
            closeIcon={<CloseOutlined style={{ color: COLOR.PRIMARY_TEXT }} />}
          >
            <Title level={4} style={{ color: COLOR.PRIMARY_TEXT }}>
              {this.props.t("transactions.modal.modalTitle")}{" "}
              {this.state.transactionSelected.id_transaction}
              <br />
              {this.props.t(
                "transactions.type." + this.state.transactionSelected.type
              )}
            </Title>
            <br />
            <Divider
              style={{
                background: COLOR.SECONDARY_TEXT,
                marginTop: 10,
                marginBottom: 10,
              }}
            />
            <Row justify="space-between">
              <Space direction="vertical">
                <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                  {this.props.t("transactions.modal.amount")}
                </Text>
                <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                  <NumberFormat
                    thousandSeparator={true}
                    displayType="text"
                    suffix={" " + this.state.accountActive.value}
                    decimalScale={
                      this.state.accountActive.type === "CRYPTO" ? 8 : 2
                    }
                    value={
                      this.state.transactionSelected.type === "TRANSFER_OUT" ||
                      this.state.transactionSelected.type === "WITHDRAWAL" ||
                      this.state.transactionSelected.type === "EXCHANGE_OUT"
                        ? this.state.transactionSelected.destination?.type ===
                          "EXTERNAL_CIRCLE"
                          ? this.state.transactionSelected.total_amount
                          : this.state.transactionSelected.amount
                        : this.state.transactionSelected.total_amount
                    }
                  />
                </Text>
              </Space>
              {this.state.transactionSelected.destination?.type !==
                "EXTERNAL_CIRCLE" &&
                this.state.transactionSelected.source?.type !==
                  "EXTERNAL_CIRCLE" && (
                  <>
                    {this.state.transactionSelected.type !== "EXCHANGE_IN" && (
                      <Space direction="vertical">
                        <Text
                          style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}
                        >
                          {this.state.transactionSelected.type ===
                          "EXCHANGE_OUT"
                            ? this.props.t("transactions.modal.price")
                            : this.props.t("transactions.modal.fee")}
                        </Text>
                        <Text
                          style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}
                        >
                          <NumberFormat
                            thousandSeparator={true}
                            displayType="text"
                            suffix={
                              this.state.transactionSelected.type ===
                              "EXCHANGE_OUT"
                                ? " " +
                                  this.state.transactionSelected.quoteCurrency +
                                  " /"
                                : " " +
                                  this.state.transactionSelected.account
                                    .currency
                            }
                            decimalScale={
                              this.state.transactionSelected.type ===
                              "EXCHANGE_OUT"
                                ? this.propertiesCurrency(
                                    this.state.transactionSelected.destination
                                      .currency
                                  ) === "CRYPTO"
                                  ? 8
                                  : 2
                                : this.state.transactionSelected.account
                                    .type === "CRYPTO"
                                ? 8
                                : 2
                            }
                            value={
                              this.state.transactionSelected.type ===
                              "EXCHANGE_OUT"
                                ? this.state.transactionSelected.exchange_rate
                                : this.state.transactionSelected.fees
                            }
                          />
                        </Text>
                        {this.state.transactionSelected.type ===
                          "EXCHANGE_OUT" && (
                          <Text
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              fontSize: 16,
                            }}
                          >
                            1 {this.state.transactionSelected.account.currency}
                          </Text>
                        )}
                      </Space>
                    )}
                    {this.state.transactionSelected.type === "EXCHANGE_IN" &&
                      feeType === "TIERS" && (
                        <Space direction="vertical">
                          <Text
                            style={{
                              color: COLOR.SECONDARY_TEXT,
                              fontSize: 16,
                            }}
                          >
                            {this.props.t("transactions.modal.fee")}
                          </Text>
                          <Text
                            style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}
                          >
                            <NumberFormat
                              allowNegative={true}
                              thousandSeparator={true}
                              suffix={
                                " " +
                                this.state.transactionSelected.account.currency
                              }
                              displayType="text"
                              style={{
                                color: COLOR.PRIMARY_TEXT,
                                fontSize: 16,
                                fontWeight: 400,
                              }}
                              decimalScale={
                                this.state.transactionSelected.account.type ===
                                "CRYPTO"
                                  ? 8
                                  : 2
                              }
                              value={this.state.transactionSelected.fees}
                            />
                          </Text>
                        </Space>
                      )}
                  </>
                )}
              <Space direction="vertical">
                <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                  {this.props.t("transactions.modal.status")}
                </Text>
                {this.state.transactionSelected.status === "COMPLETED" && (
                  <Tag
                    style={{
                      color: "#389E0D",
                      background: "#F6FFED",
                      borderColor: "#B7EB8F",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t(
                      "transactions.status." +
                        this.state.transactionSelected.status
                    )}
                  </Tag>
                )}
                {this.state.transactionSelected.status === "PROCESSING" && (
                  <Tag
                    style={{
                      color: "#08979C",
                      background: "#E6FFFB",
                      borderColor: "#87E8DE",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t(
                      "transactions.status." +
                        this.state.transactionSelected.status
                    )}
                  </Tag>
                )}
                {this.state.transactionSelected.status === "INITIATED" && (
                  <Tag
                    style={{
                      color: "#096DD9",
                      background: "#E6F7FF",
                      borderColor: "#91D5FF",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t(
                      "transactions.status." +
                        this.state.transactionSelected.status
                    )}
                  </Tag>
                )}
                {this.state.transactionSelected.status === "PENDING" && (
                  <Tag
                    style={{
                      color: "#531DAB",
                      background: "#F9F0FF",
                      borderColor: "#D3ADF7",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t(
                      "transactions.status." +
                        this.state.transactionSelected.status
                    )}
                  </Tag>
                )}
                {this.state.transactionSelected.status === "REJECTED" && (
                  <Tag
                    style={{
                      color: "#D4380D",
                      background: "#FFF2E8",
                      borderColor: "#FFBB96",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t(
                      "transactions.status." +
                        this.state.transactionSelected.status
                    )}
                  </Tag>
                )}
                {this.state.transactionSelected.status === "FAILED" && (
                  <Tag
                    style={{
                      color: "#CF1322",
                      background: "#FFF1F0",
                      borderColor: "#FFA39E",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t(
                      "transactions.status." +
                        this.state.transactionSelected.status
                    )}
                  </Tag>
                )}
                {this.state.transactionSelected.status ===
                  "CLIENT_APPROVAL_PENDING" && (
                  <Tag
                    color="gold"
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {this.props.t(
                      "transactions.status.CLIENT_APPROVAL_PENDING"
                    )}
                  </Tag>
                )}
                {this.state.transactionSelected.status ===
                  "APPROVAL_EXPIRED" && (
                  <Tag
                    color="magenta"
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("transactions.status.APPROVAL_EXPIRED")}
                  </Tag>
                )}
                {this.state.transactionSelected.status === "AML_HOLD" && (
                  <Tag
                    color="gold"
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("transactions.status.AML_HOLD")}
                  </Tag>
                )}
                {this.state.transactionSelected.status === "AML_FREEZE" && (
                  <Tag
                    color="geekblue"
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("transactions.status.AML_FREEZE")}
                  </Tag>
                )}
                {this.state.transactionSelected.status === "CANCELLED" && (
                  <Tag
                    style={{
                      marginTop: 5,
                      color: "#CF1322",
                      background: "#FFF1F0",
                      borderColor: "#FFA39E",
                      fontSize: 12,
                    }}
                  >
                    {this.props.t("transactions.status.CANCELLED")}
                  </Tag>
                )}
                {this.state.transactionSelected.status ===
                  "PENDING_SETTLEMENT" && (
                  <Tag
                    color="geekblue"
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("transactions.status.PENDING_SETTLEMENT")}
                  </Tag>
                )}
              </Space>
            </Row>
            <Divider
              style={{
                background: COLOR.SECONDARY_TEXT,
                marginTop: 10,
                marginBottom: 10,
              }}
            />
            <br />
            <Divider
              style={{
                background: COLOR.SECONDARY_TEXT,
                marginTop: 10,
                marginBottom: 10,
              }}
            />
            <Row justify="space-between">
              <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                {this.props.t("transactions.modal.type")}
              </Text>
              <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                {this.props.t(
                  "transactions.type." + this.state.transactionSelected.type
                )}
              </Text>
            </Row>
            <Divider
              style={{
                background: COLOR.SECONDARY_TEXT,
                marginTop: 10,
                marginBottom: 10,
              }}
            />
            <Row justify="space-between">
              <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                Ref-ID
              </Text>
              <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                <Paragraph
                  style={{
                    color: COLOR.PRIMARY_TEXT,
                    fontSize: 16,
                    marginBottom: 0,
                  }}
                  ellipsis={true}
                  copyable={{
                    text: this.state.transactionSelected.id_transaction,
                    icon: [
                      <CopyOutlined className="icon-copy-address" />,
                      <CheckOutlined className="icon-copied-address" />,
                    ],
                    tooltips: false,
                  }}
                >
                  {this.state.transactionSelected.id_transaction}
                </Paragraph>
              </Text>
            </Row>
            {this.state.accountActive.type === "CRYPTO" &&
              this.state.transactionSelected.transaction_hash !== undefined &&
              this.state.transactionSelected.transaction_hash !== "" && (
                <div>
                  <Divider
                    style={{
                      background: COLOR.SECONDARY_TEXT,
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  />
                  <Row justify="space-between">
                    <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                      {this.props.t("transactions.modal.id")}
                    </Text>
                    <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                      <Paragraph
                        style={{
                          color: COLOR.PRIMARY_TEXT,
                          fontSize: 16,
                          marginBottom: 0,
                        }}
                        ellipsis={true}
                        copyable={{
                          text: this.state.transactionSelected.transaction_hash,
                          icon: [
                            <CopyOutlined className="icon-copy-address" />,
                            <CheckOutlined className="icon-copied-address" />,
                          ],
                          tooltips: false,
                        }}
                      >
                        <Link
                          style={{ color: COLOR.GREEN }}
                          href={this.showUrl()}
                          target="_blank"
                        >
                          {this.state.transactionSelected.transaction_hash
                            .length > 22 && (
                            <>
                              {this.state.transactionSelected.transaction_hash.substr(
                                0,
                                12
                              ) +
                                "..." +
                                this.state.transactionSelected.transaction_hash.substr(
                                  this.state.transactionSelected
                                    .transaction_hash.length - 12,
                                  this.state.transactionSelected
                                    .transaction_hash.length
                                )}
                            </>
                          )}

                          {this.state.transactionSelected.transaction_hash
                            .length <= 22 &&
                            this.state.transactionSelected.transaction_hash}
                        </Link>
                      </Paragraph>
                    </Text>
                  </Row>
                </div>
              )}
            <Divider
              style={{
                background: COLOR.SECONDARY_TEXT,
                marginTop: 10,
                marginBottom: 10,
              }}
            />
            <Row justify="space-between">
              <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                {this.props.t("transactions.modal.asset")}
              </Text>
              <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                {this.state.accountActive.name}
              </Text>
            </Row>
            <Divider
              style={{
                background: COLOR.SECONDARY_TEXT,
                marginTop: 10,
                marginBottom: 10,
              }}
            />
            <Row justify="space-between">
              <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                {this.props.t("transactions.modal.date")}
              </Text>
              <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                {this.state.transactionSelected.date}
              </Text>
            </Row>
            <Divider
              style={{
                background: COLOR.SECONDARY_TEXT,
                marginTop: 10,
                marginBottom: 10,
              }}
            />
            <Row justify="space-between">
              <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                {this.props.t("transactions.modal.reference")}
              </Text>
              <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                {this.state.transactionSelected.reference}{" "}
                {this.state.transactionSelected.type.includes("REBALANCE") &&
                  this.state.transactionSelected.account.currency}
              </Text>
            </Row>
            {this.state.accountActive.type === "CRYPTO" && (
              <div>
                {this.state.transactionSelected.type !== "EXCHANGE_IN" &&
                  this.state.transactionSelected.type !== "EXCHANGE_OUT" && (
                    <>
                      {this.state.transactionSelected.destination !==
                        undefined && (
                        <>
                          {this.state.transactionSelected.destination
                            .address !== undefined &&
                            this.state.transactionSelected.destination
                              .address !== "" && (
                              <div>
                                {!this.state.transactionSelected.destination.type.includes(
                                  "INTERNAL_BENEFICIARY"
                                ) && (
                                  <div>
                                    <Divider
                                      style={{
                                        background: COLOR.SECONDARY_TEXT,
                                        marginTop: 10,
                                        marginBottom: 10,
                                      }}
                                    />
                                    <Row justify="space-between">
                                      <Text
                                        style={{
                                          color: COLOR.SECONDARY_TEXT,
                                          fontSize: 16,
                                        }}
                                      >
                                        {this.props.t(
                                          "transactions.modal.address"
                                        )}
                                      </Text>
                                      <Paragraph
                                        style={{
                                          color: COLOR.PRIMARY_TEXT,
                                          fontSize: 16,
                                          marginBottom: 0,
                                        }}
                                        ellipsis={true}
                                        copyable={{
                                          text: this.state.transactionSelected
                                            .destination.address,
                                          icon: [
                                            <CopyOutlined className="icon-copy-address" />,
                                            <CheckOutlined className="icon-copied-address" />,
                                          ],
                                          tooltips: false,
                                        }}
                                      >
                                        {this.state.transactionSelected
                                          .destination.address !== undefined &&
                                          this.state.transactionSelected
                                            .destination.address.length > 24 &&
                                          this.state.transactionSelected.destination.address.substr(
                                            0,
                                            12
                                          ) +
                                            "..." +
                                            this.state.transactionSelected.destination.address.substr(
                                              this.state.transactionSelected
                                                .destination.address.length -
                                                12,
                                              this.state.transactionSelected
                                                .destination.address.length
                                            )}
                                      </Paragraph>
                                    </Row>
                                  </div>
                                )}
                              </div>
                            )}
                        </>
                      )}
                      {this.state.transactionSelected.source !== undefined && (
                        <div>
                          {!this.state.transactionSelected.source.type.includes(
                            "INTERNAL_BENEFICIARY"
                          ) &&
                            this.state.transactionSelected.account.currency !==
                              "BTC" && (
                              <>
                                {this.state.transactionSelected.source
                                  .address !== undefined && (
                                  <div>
                                    <Divider
                                      style={{
                                        background: COLOR.SECONDARY_TEXT,
                                        marginTop: 10,
                                        marginBottom: 10,
                                      }}
                                    />

                                    <Row justify="space-between">
                                      <Text
                                        style={{
                                          color: COLOR.SECONDARY_TEXT,
                                          fontSize: 16,
                                        }}
                                      >
                                        {this.props.t(
                                          "transactions.modal.address"
                                        )}
                                      </Text>
                                      <Paragraph
                                        style={{
                                          color: COLOR.PRIMARY_TEXT,
                                          fontSize: 16,
                                          marginBottom: 0,
                                        }}
                                        ellipsis={true}
                                        copyable={{
                                          text: this.state.transactionSelected
                                            .source.address,
                                          icon: [
                                            <CopyOutlined className="icon-copy-address" />,
                                            <CheckOutlined className="icon-copied-address" />,
                                          ],
                                          tooltips: false,
                                        }}
                                      >
                                        {this.state.transactionSelected.source
                                          .address !== undefined && (
                                          <>
                                            {this.state.transactionSelected
                                              .source.address.length > 24 &&
                                              this.state.transactionSelected.source.address.substr(
                                                0,
                                                12
                                              ) +
                                                "..." +
                                                this.state.transactionSelected.source.address.substr(
                                                  this.state.transactionSelected
                                                    .source.address.length - 12,
                                                  this.state.transactionSelected
                                                    .source.address.length
                                                )}
                                          </>
                                        )}
                                      </Paragraph>
                                    </Row>
                                  </div>
                                )}
                              </>
                            )}
                        </div>
                      )}

                      <Divider
                        style={{
                          background: COLOR.SECONDARY_TEXT,
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      />
                      <Row justify="space-between">
                        <Text
                          style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}
                        >
                          {this.props.t("transactions.modal.description")}
                        </Text>
                        {this.state.transactionSelected.destination !==
                          undefined && (
                          <Text
                            style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}
                          >
                            {this.state.transactionSelected.destination.type ===
                            "EXTERNAL_CIRCLE"
                              ? this.state.transactionSelected.destination
                                  .billing_name
                              : this.state.transactionSelected.destination
                                  .description}{" "}
                            <Tag
                              color={
                                this.state.transactionSelected.destination.type.includes(
                                  "INTERNAL"
                                )
                                  ? "#108ee9"
                                  : "#f50"
                              }
                            >
                              <Text
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  fontSize: 16,
                                  fontWeight: 400,
                                  marginBottom: 0,
                                }}
                              >
                                {this.state.transactionSelected.destination.type.includes(
                                  "INTERNAL"
                                )
                                  ? this.props.t("beneficiaries.internal")
                                  : this.props.t("beneficiaries.external")}
                              </Text>
                            </Tag>
                          </Text>
                        )}
                        {this.state.transactionSelected.source !==
                          undefined && (
                          <Text
                            style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}
                          >
                            {this.state.transactionSelected.account.currency !==
                              "BTC" &&
                              this.state.transactionSelected.source.type !==
                                "EXTERNAL_CIRCLE" && (
                                <>
                                  {this.state.transactionSelected.source
                                    .type === "EXTERNAL"
                                    ? this.state.transactionSelected.source
                                        .address !== undefined
                                      ? this.state.transactionSelected.source
                                          .address
                                      : ""
                                    : this.state.transactionSelected.source
                                        .description}
                                </>
                              )}{" "}
                            {this.state.transactionSelected.source.type ===
                              "EXTERNAL_CIRCLE" &&
                              this.state.transactionSelected.source
                                .billing_name + " "}
                            <Tag
                              color={
                                this.state.transactionSelected.source.type.includes(
                                  "INTERNAL"
                                )
                                  ? "#108ee9"
                                  : "#f50"
                              }
                            >
                              <Text
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  fontSize: 16,
                                  fontWeight: 400,
                                  marginBottom: 0,
                                }}
                              >
                                {this.state.transactionSelected.source.type.includes(
                                  "INTERNAL"
                                )
                                  ? this.props.t("beneficiaries.internal")
                                  : this.props.t("beneficiaries.external")}
                              </Text>
                            </Tag>
                          </Text>
                        )}
                      </Row>
                    </>
                  )}
              </div>
            )}
            {this.state.accountActive.type === "FIAT" && (
              <div>
                {this.state.transactionSelected.type !== "EXCHANGE_IN" &&
                  this.state.transactionSelected.type !== "EXCHANGE_OUT" && (
                    <>
                      <Divider
                        style={{
                          background: COLOR.SECONDARY_TEXT,
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      />

                      <Row justify="space-between">
                        <Text
                          style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}
                        >
                          {this.props.t("transactions.modal.description")}
                        </Text>
                        <Text
                          style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}
                        >
                          {!this.state.transactionSelected.type.includes(
                            "REBALANCE"
                          ) &&
                            this.state.transactionSelected.destination !==
                              undefined && (
                              <div>
                                {this.state.transactionSelected.destination
                                  .description !== undefined
                                  ? this.state.transactionSelected.destination
                                      .description + " "
                                  : ""}
                                <Tag
                                  color={
                                    this.state.transactionSelected.destination.type.includes(
                                      "INTERNAL"
                                    )
                                      ? "#108ee9"
                                      : "#f50"
                                  }
                                >
                                  <Text
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                      fontWeight: 400,
                                      marginBottom: 0,
                                    }}
                                  >
                                    {this.state.transactionSelected.destination.type.includes(
                                      "INTERNAL"
                                    )
                                      ? this.props.t("beneficiaries.internal")
                                      : this.props.t("beneficiaries.external")}
                                  </Text>
                                </Tag>
                              </div>
                            )}
                          {!this.state.transactionSelected.type.includes(
                            "REBALANCE"
                          ) &&
                            this.state.transactionSelected.source !==
                              undefined && (
                              <div>
                                {this.state.transactionSelected.source
                                  .description + " "}
                                <Tag
                                  color={
                                    this.state.transactionSelected.source.type.includes(
                                      "INTERNAL"
                                    )
                                      ? "#108ee9"
                                      : "#f50"
                                  }
                                >
                                  <Text
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                      fontWeight: 400,
                                      marginBottom: 0,
                                    }}
                                  >
                                    {this.state.transactionSelected.source.type.includes(
                                      "INTERNAL"
                                    )
                                      ? this.props.t("beneficiaries.internal")
                                      : this.props.t("beneficiaries.external")}
                                  </Text>
                                </Tag>
                              </div>
                            )}
                          {this.state.transactionSelected.type.includes(
                            "REBALANCE"
                          ) && (
                            <>
                              {this.state.transactionSelected.source !==
                                undefined && (
                                <div>
                                  {
                                    this.state.transactionSelected.source
                                      .description
                                  }
                                </div>
                              )}
                              {this.state.transactionSelected.destination !==
                                undefined && (
                                <div>
                                  {
                                    this.state.transactionSelected.destination
                                      .description
                                  }
                                </div>
                              )}
                            </>
                          )}
                        </Text>
                      </Row>
                      <Divider
                        style={{
                          background: COLOR.SECONDARY_TEXT,
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      />
                      {this.state.accountActive.value === "GBP" && (
                        <div>
                          {this.state.transactionSelected.destination !==
                            undefined && (
                            <div>
                              {this.state.transactionSelected.type.includes(
                                "REBALANCE"
                              ) && (
                                <>
                                  <Row justify="space-between">
                                    <Text
                                      style={{
                                        color: COLOR.SECONDARY_TEXT,
                                        fontSize: 16,
                                      }}
                                    >
                                      Alias
                                    </Text>
                                    <Text
                                      style={{
                                        color: COLOR.PRIMARY_TEXT,
                                        fontSize: 16,
                                      }}
                                    >
                                      {
                                        this.state.transactionSelected
                                          .destination.alias
                                      }
                                    </Text>
                                  </Row>

                                  <Divider
                                    style={{
                                      background: COLOR.SECONDARY_TEXT,
                                      marginTop: 10,
                                      marginBottom: 10,
                                    }}
                                  />
                                </>
                              )}
                              {this.state.transactionSelected.destination
                                .account_holder !== undefined && (
                                <>
                                  <Row justify="space-between">
                                    <Text
                                      style={{
                                        color: COLOR.SECONDARY_TEXT,
                                        fontSize: 16,
                                      }}
                                    >
                                      {this.props.t(
                                        "transactions.modal.accountHolder"
                                      )}
                                    </Text>
                                    <Text
                                      style={{
                                        color: COLOR.PRIMARY_TEXT,
                                        fontSize: 16,
                                      }}
                                    >
                                      {userType.includes("LITE") &&
                                      this.state.accountActive.type === "FIAT"
                                        ? "Digital Asset Management LTD"
                                        : this.state.transactionSelected
                                            .destination.account_holder}
                                    </Text>
                                  </Row>
                                  <Divider
                                    style={{
                                      background: COLOR.SECONDARY_TEXT,
                                      marginTop: 10,
                                      marginBottom: 10,
                                    }}
                                  />
                                </>
                              )}
                              <Row justify="space-between">
                                <Text
                                  style={{
                                    color: COLOR.SECONDARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  {this.props.t(
                                    "transactions.modal.accountNumber"
                                  )}
                                </Text>
                                <Text
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  {
                                    this.state.transactionSelected.destination
                                      .account_number
                                  }
                                </Text>
                              </Row>
                              <Divider
                                style={{
                                  background: COLOR.SECONDARY_TEXT,
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              />
                              <Row justify="space-between">
                                <Text
                                  style={{
                                    color: COLOR.SECONDARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  Sort Code
                                </Text>
                                <Text
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  {
                                    this.state.transactionSelected.destination
                                      .sort_code
                                  }
                                </Text>
                              </Row>
                            </div>
                          )}
                          {this.state.transactionSelected.source !==
                            undefined && (
                            <div>
                              {this.state.transactionSelected.type.includes(
                                "REBALANCE"
                              ) && (
                                <>
                                  <Row justify="space-between">
                                    <Text
                                      style={{
                                        color: COLOR.SECONDARY_TEXT,
                                        fontSize: 16,
                                      }}
                                    >
                                      Alias
                                    </Text>
                                    <Text
                                      style={{
                                        color: COLOR.PRIMARY_TEXT,
                                        fontSize: 16,
                                      }}
                                    >
                                      {
                                        this.state.transactionSelected.source
                                          .alias
                                      }
                                    </Text>
                                  </Row>

                                  <Divider
                                    style={{
                                      background: COLOR.SECONDARY_TEXT,
                                      marginTop: 10,
                                      marginBottom: 10,
                                    }}
                                  />
                                </>
                              )}
                              {this.state.transactionSelected.source
                                .account_holder !== undefined && (
                                <>
                                  <Row justify="space-between">
                                    <Text
                                      style={{
                                        color: COLOR.SECONDARY_TEXT,
                                        fontSize: 16,
                                      }}
                                    >
                                      {this.props.t(
                                        "transactions.modal.accountHolder"
                                      )}
                                    </Text>
                                    <Text
                                      style={{
                                        color: COLOR.PRIMARY_TEXT,
                                        fontSize: 16,
                                      }}
                                    >
                                      {
                                        this.state.transactionSelected.source
                                          .account_holder
                                      }
                                    </Text>
                                  </Row>
                                  <Divider
                                    style={{
                                      background: COLOR.SECONDARY_TEXT,
                                      marginTop: 10,
                                      marginBottom: 10,
                                    }}
                                  />
                                </>
                              )}
                              <Row justify="space-between">
                                <Text
                                  style={{
                                    color: COLOR.SECONDARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  {this.props.t(
                                    "transactions.modal.accountNumber"
                                  )}
                                </Text>
                                <Text
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  {
                                    this.state.transactionSelected.source
                                      .account_number
                                  }
                                </Text>
                              </Row>
                              <Divider
                                style={{
                                  background: COLOR.SECONDARY_TEXT,
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              />
                              <Row justify="space-between">
                                <Text
                                  style={{
                                    color: COLOR.SECONDARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  Sort Code
                                </Text>
                                <Text
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  {
                                    this.state.transactionSelected.source
                                      .sort_code
                                  }
                                </Text>
                              </Row>
                            </div>
                          )}
                        </div>
                      )}
                      {this.state.accountActive.value === "EUR" && (
                        <div>
                          {this.state.transactionSelected.destination !==
                            undefined && (
                            <div>
                              {this.state.transactionSelected.type.includes(
                                "REBALANCE"
                              ) && (
                                <>
                                  <Row justify="space-between">
                                    <Text
                                      style={{
                                        color: COLOR.SECONDARY_TEXT,
                                        fontSize: 16,
                                      }}
                                    >
                                      Alias
                                    </Text>
                                    <Text
                                      style={{
                                        color: COLOR.PRIMARY_TEXT,
                                        fontSize: 16,
                                      }}
                                    >
                                      {
                                        this.state.transactionSelected
                                          .destination.alias
                                      }
                                    </Text>
                                  </Row>

                                  <Divider
                                    style={{
                                      background: COLOR.SECONDARY_TEXT,
                                      marginTop: 10,
                                      marginBottom: 10,
                                    }}
                                  />
                                </>
                              )}
                              <Row justify="space-between">
                                <Text
                                  style={{
                                    color: COLOR.SECONDARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  {this.props.t(
                                    "transactions.modal.accountHolder"
                                  )}
                                </Text>
                                <Text
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  {userType.includes("LITE") &&
                                  this.state.accountActive.type === "FIAT"
                                    ? "Digital Asset Management LTD"
                                    : this.state.transactionSelected.destination
                                        .account_holder !== undefined
                                    ? this.state.transactionSelected.destination
                                        .account_holder
                                    : ""}
                                </Text>
                              </Row>
                              <Divider
                                style={{
                                  background: COLOR.SECONDARY_TEXT,
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              />
                              <Row justify="space-between">
                                <Text
                                  style={{
                                    color: COLOR.SECONDARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  IBAN
                                </Text>
                                <Text
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  {
                                    this.state.transactionSelected.destination
                                      .iban
                                  }
                                </Text>
                              </Row>
                              <Divider
                                style={{
                                  background: COLOR.SECONDARY_TEXT,
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              />
                              <Row justify="space-between">
                                <Text
                                  style={{
                                    color: COLOR.SECONDARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  BIC
                                </Text>
                                <Text
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  {
                                    this.state.transactionSelected.destination
                                      .bic
                                  }
                                </Text>
                              </Row>
                            </div>
                          )}
                          {this.state.transactionSelected.source !==
                            undefined && (
                            <div>
                              {this.state.transactionSelected.type.includes(
                                "REBALANCE"
                              ) && (
                                <>
                                  <Row justify="space-between">
                                    <Text
                                      style={{
                                        color: COLOR.SECONDARY_TEXT,
                                        fontSize: 16,
                                      }}
                                    >
                                      Alias
                                    </Text>
                                    <Text
                                      style={{
                                        color: COLOR.PRIMARY_TEXT,
                                        fontSize: 16,
                                      }}
                                    >
                                      {
                                        this.state.transactionSelected.source
                                          .alias
                                      }
                                    </Text>
                                  </Row>

                                  <Divider
                                    style={{
                                      background: COLOR.SECONDARY_TEXT,
                                      marginTop: 10,
                                      marginBottom: 10,
                                    }}
                                  />
                                </>
                              )}
                              <Row justify="space-between">
                                <Text
                                  style={{
                                    color: COLOR.SECONDARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  {this.props.t(
                                    "transactions.modal.accountHolder"
                                  )}
                                </Text>
                                <Text
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  {userType.includes("LITE") &&
                                  this.state.accountActive.type === "FIAT"
                                    ? "Digital Asset Management LTD"
                                    : this.state.transactionSelected.source
                                        .account_holder}
                                </Text>
                              </Row>
                              <Divider
                                style={{
                                  background: COLOR.SECONDARY_TEXT,
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              />
                              <Row justify="space-between">
                                <Text
                                  style={{
                                    color: COLOR.SECONDARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  IBAN
                                </Text>
                                <Text
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  {this.state.transactionSelected.source.iban}
                                </Text>
                              </Row>
                              <Divider
                                style={{
                                  background: COLOR.SECONDARY_TEXT,
                                  marginTop: 10,
                                  marginBottom: 10,
                                }}
                              />
                              <Row justify="space-between">
                                <Text
                                  style={{
                                    color: COLOR.SECONDARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  BIC
                                </Text>
                                <Text
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    fontSize: 16,
                                  }}
                                >
                                  {this.state.transactionSelected.source.bic}
                                </Text>
                              </Row>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
              </div>
            )}
            <Divider
              style={{
                background: COLOR.SECONDARY_TEXT,
                marginTop: 10,
                marginBottom: 10,
              }}
            />
            {this.state.transactionSelected?.cancelled_by && (
              <Row justify="space-between">
                <Text style={{ color: COLOR.SECONDARY_TEXT, fontSize: 16 }}>
                  {this.props.t("transactions.modal.cancelledBy")}
                </Text>
                <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 16 }}>
                  {this.state.transactionSelected?.cancelled_by}
                </Text>
              </Row>
            )}
          </Modal>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    asset: state.siderNavigate.asset,
    accounts: state.banking.accounts,
    respTransactions: state.transactions.resultTransactions,
    respTransactionsOrders: state.transactions.transactionsAndOrders,
    accessToken: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
    sellResponse: state.exchange.resultSell,
    buyResponse: state.exchange.resultBuy,
    tradeResponse: state.exchange.resultExchange,
    dataUser: state.authUser.userData,
    respCancelTransaction: state.transactions.resultCancelTransactions,
  };
};

const mapDispatchToProps = {
  getTransactionsCurrency,
  currentToken,
  transactionCancelation,
};

const TransactionsWalletTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(TransactionsWallet)
);

export default TransactionsWalletTranslated;
