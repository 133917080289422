import {
  ACTIVATE_TWO_FACTOR,
  CONFIRM_TWO_FACTOR,
  LOGIN_TWO_FACTOR,
} from "../actions/twoFactorActions";
const initialState = {
  twoFactor: {},
  confirm: {},
  loginTwoFactor: {},
};

export function twoFactor(state = initialState, action) {
  switch (action.type) {
    case ACTIVATE_TWO_FACTOR:
      return Object.assign({}, state, { twoFactor: action.payload });
    case CONFIRM_TWO_FACTOR:
      return Object.assign({}, state, { confirm: action.payload });
    case LOGIN_TWO_FACTOR:
      return Object.assign({}, state, { loginTwoFactor: action.payload });
    default:
      return state;
  }
}
