import { GET_TIMELINE } from "../actions/timeline";
const initialState = {
  resultTimeline: "",
};

export function timeline(state = initialState, action) {
  switch (action.type) {
    case GET_TIMELINE:
      return Object.assign({}, state, { resultTimeline: action.payload });
    default:
      return state;
  }
}
