import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Modal } from "antd";
import { connect } from "react-redux";
import ContainerTrade from "../../components/containerTrade/containerTrade";
import { CloseOutlined } from "@ant-design/icons";
import "./modalTrade.css";
class ModalTrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: "1",
      fiat: true,
    };
  }
  componentDidMount() {
    let accounts = this.props.banking.data.data.accounts;
    let findAccounts = accounts.find((element) => {
      return element.type === "FIAT";
    });
    if (findAccounts !== undefined) {
      this.setState({ fiat: true });
    } else {
      this.setState({ fiat: false, tabActive: "3" });
    }
  }
  handleTab(key) {
    this.setState({ tabActive: key });
  }
  render() {
    return (
      <Modal
        closable={true}
        footer={null}
        closeIcon={
          <div
            id="icon-close-modal"
            className="anticon-close ant-modal-close"
          >
            <CloseOutlined />
          </div>
        }
        width={450}
        bodyStyle={{
          margin: 0,
          padding: "0px 0px 24px 0px",
          background: "#00001e",
          width: "100%",
          top: 80,
        }}
        open={this.props.modalTrade}
        onOk={() => this.props.openModalTrade()}
        onCancel={() => this.props.closeModalTrade()}
        maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
      >
        <ContainerTrade source={"header"} />
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    banking: state.banking.accounts,
  };
};

const ModalTradeTranslated = withTranslation()(
  connect(mapStateToProps)(ModalTrade)
);

export default ModalTradeTranslated;
