import {
  SIDER,
  ASSET,
  MODAL,
  INFO_TO_SEND,
  MODAL_SEND,
} from "../actions/navigateActions";
const initialState = {
  result: "Accounts",
  asset: "",
  modal: false,
  infoToSend: "",
  modalSend: false,
};

export function siderNavigate(state = initialState, action) {
  switch (action.type) {
    case SIDER:
      return Object.assign({}, state, { result: action.payload });
    case ASSET:
      return Object.assign({}, state, { asset: action.payload });
    case MODAL:
      return Object.assign({}, state, { modal: action.payload });
    case MODAL_SEND:
      return Object.assign({}, state, { modalSend: action.payload });
    case INFO_TO_SEND:
      return Object.assign({}, state, { infoToSend: action.payload });
    default:
      return state;
  }
}
