import React, { Component } from "react";
import "./twoFactorLoggedIn.css";
import {
  Row,
  Col,
  Space,
  Typography,
  Button,
  Divider,
  Modal,
  Input,
} from "antd";
import COLOR from "../../const/colors";
import Email from "@2fd/ant-design-icons/lib/Email";
import { withTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
const { Text, Title } = Typography;
class TwoFactorLoggedIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div>
        <Modal
          title={
            <Title level={4} style={{ color: COLOR.PRIMARY_TEXT }}>
              {this.props.t("twofactor.confirmChanges")}
            </Title>
          }
          footer={null}
          className="container-modal-security"
          bodyStyle={{
            background: COLOR.PRIMARY,
            color: COLOR.PRIMARY_TEXT,
            textAlign: "center",
            padding: "24px 0px 24px 0px",
          }}
          maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
          open={this.props.modalActivate}
          onOk={() => this.props.openModal()}
          onCancel={() => this.props.closeModal()}
          centered={true}
          closeIcon={<CloseOutlined style={{ color: COLOR.GRAY }} />}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <Row
              gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}
              justify="center"
            >
              <Space direction="vertical">
                <Title level={4} style={{ color: COLOR.PRIMARY_TEXT }}>
                  {this.props.t("twofactor.titleSecurity")}
                </Title>
                <Text
                  style={{
                    color: COLOR.PRIMARY_TEXT,
                    fontSize: 16,
                    padding: "0px 24px",
                  }}
                >
                  {this.props.t("twofactor.textConfirm")}
                </Text>
              </Space>
            </Row>
            <Divider
              style={{
                background: COLOR.PRIMARY_TEXT,
              }}
            ></Divider>
            <Row
              gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}
              justify="center"
            >
              <Space direction="vertical">
                <Text
                  style={{
                    color: COLOR.PRIMARY_TEXT,
                    fontSize: 16,
                    padding: "0px 24px",
                  }}
                >
                  {this.props.t("twofactor.enterCode")}
                </Text>

                <Space direction="horizontal" size="small">
                  <Email
                    style={{
                      color: COLOR.GREEN,
                      fontSize: 40,
                      width: "20%",
                      float: "right",
                    }}
                  />
                  <Input
                    value={this.props.verificationCode}
                    maxLength={6}
                    size="large"
                    style={{ width: "50%",  color:"black" }}
                    placeholder="000000"
                    onChange={(value) => this.props.handleCode(value)}
                  />
                </Space>

                <div>
                  <Text
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      padding: "0px 10px 0px 0px",
                    }}
                  >
                    {this.props.t("twofactor.noReceived")}
                  </Text>
                  <Button
                    type="link"
                    disabled={this.props.disableResend}
                    style={{
                      color: COLOR.GREEN_1,
                      fontWeight: "bold",
                      padding: 0,
                    }}
                    onClick={() => this.props.resend(true)}
                  >
                    {this.props.t("twofactor.resend")}
                  </Button>
                </div>
              </Space>
            </Row>
            <Divider
              style={{
                background: COLOR.PRIMARY_TEXT,
              }}
            ></Divider>
            <Row gutter={[32, { xs: 8, sm: 16, md: 24, lg: 32 }]} justify="end">
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
              >
                <Button
                  block
                  size="large"
                  className="btn-previous-security"
                  onClick={() => this.props.closeModal()}
                >
                  {this.props.t("twofactor.buttonCancel")}
                </Button>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
              >
                <Button
                  block
                  size="large"
                  type="primary"
                  loading={this.props.loading}
                  disabled={
                    this.props.verificationCode === "" ||
                    this.props.verificationCode.toString().length !== 6
                  }
                  onClick={() => this.props.verificationCodeReceived()}
                  style={{
                    height: 60,
                    fontSize: 18,
                    fontWeight: 600,
                    borderRadius: "5px",
                  }}
                >
                  {this.props.t("twofactor.buttonConfirm")}
                </Button>
              </Col>
            </Row>
          </Space>
        </Modal>
      </div>
    );
  }
}

const TwoFactorLoggedInTranslated = withTranslation()(TwoFactorLoggedIn);

export default TwoFactorLoggedInTranslated;
