import React, { Component } from "react";
import { Tabs, Row, Col, Card } from "antd";
import COLOR from "../../const/colors";
import "./settings.css";
import { withTranslation } from "react-i18next";
import Profile from "../profile/profile";
import Security from "../security/security";
import Preferences from "../preferences/preferences";
import More from "../more/more";
import { getPreferences } from "../../redux/actions/preferencesActions";
import { currentToken } from "../../redux/actions/tokenActions";
import { getSpreads, getRate } from "../../redux/actions/exchangeActions";
import { getUsersChildren } from "../../redux/actions/usersChildrenActions";
import { connect } from "react-redux";
const { TabPane } = Tabs;

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: "1",
      refreshForm: false,
      localCurrency: "",
      activeSave: true,
      spread: "",
      infoTiers: "",
      usersChildrenList: [],
    };
  }
  componentDidMount() {
    this.getPreferences();
    this.getSpread();
    this.getQuotation();
    this.getUsersChildrenList();
  }
  async getPreferences() {
    try {
      this.setState({
        localCurrency: "",
      });
      await this.props.getPreferences(
        this.props.clientId,
        this.props.accessToken
      );
      let response = this.props.responsePreferences;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        this.setState({
          localCurrency: response.data.data?.preferences?.local_currency,
        });
      } else {
        this.setState({
          localCurrency: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  async getSpread() {
    try {
      await this.props.getSpreads(this.props.clientId, this.props.accessToken);
      let response = this.props.responseSpread;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        let dataSpread = response.data?.data?.spreads;
        let findSpread = dataSpread.find((element) => {
          return (
            element.base_currency === "USDT" && element.quote_currency === "GBP"
          );
        });
        this.setState({ spread: findSpread });
      }
    } catch (error) {
      console.log(error);
    }
  }
  async getQuotation() {
    try {
      if (!this.props.onlyFiat) {
        await this.props.getRate(
          this.props.clientId,
          this.props.accessToken,
          "USDT",
          "GBP"
        );
      } else {
        await this.props.getRate(
          this.props.clientId,
          this.props.accessToken,
          "EUR",
          "GBP"
        );
      }
      let response = this.props.rateResponse;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        let dataRate = response.data.data.quotation;

        this.setState({
          infoTiers: dataRate,
        });
      } else {
        this.setState({
          infoTiers: {},
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  async getUsersChildrenList(fromManage) {
    try {
      await this.props.getUsersChildren(
        this.props.clientId,
        this.props.accessToken
      );
      let response = this.props.usersChildrenList;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        let data = response.data.data;
        this.setState({
          usersChildrenList: data.users_children,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  handleTab(key) {
    this.setState({ tabActive: key });

    if (key === "1") {
      this.setState({ refreshForm: true });
    } else {
      this.setState({ refreshForm: false });
    }
  }
  handleLocalCurrency(value) {
    this.setState({ localCurrency: value, activeSave: false });
  }
  render() {
    let info = "";
    if (this.props.dataUser.userData.status !== undefined) {
      if (
        this.props.dataUser.userData.status !== 401 &&
        this.props.dataUser.userData.status !== 422 &&
        this.props.dataUser.userData.status !== 403 &&
        this.props.dataUser.userData.status !== 404
      ) {
        info = this.props.dataUser.userData.data.data.user.two_factor;
      }
    }
    return (
      <Row
        gutter={[8, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}
        style={{ marginBottom: 10 }}
      >
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 1 }}
          md={{ span: 22, offset: 1 }}
          lg={{ span: 22, offset: 1 }}
          xl={{ span: 22, offset: 1 }}
        >
          <Card
            bordered={false}
            size="default"
            style={{
              borderRadius: "5px",
              background: COLOR.PRIMARY,
              color: COLOR.SECONDARY_TEXT,
              border: "1px solid #000",
            }}
            bodyStyle={{ textAlign: "left", width: "100%", padding: "0px" }}
          >
            <div
              id="custom-tab-setting"
              className="ant-tabs-tab ant-tabs-card  ant-tabs-tab-active"
            >
              <Tabs
                type="line"
                activeKey={this.state.tabActive}
                tabBarStyle={{
                  color: COLOR.PRIMARY_TEXT,
                  width: "100%",
                  paddingLeft: "10px",
                }}
                onTabClick={(key) => this.handleTab(key)}
              >
                <TabPane tab={this.props.t("menu.profile")} key="1">
                  {this.state.tabActive === "1" && (
                    <Profile
                      handleTab={this.handleTab.bind(this)}
                      refreshForm={this.state.refreshForm}
                    />
                  )}
                </TabPane>

                <TabPane tab={this.props.t("menu.preferences")} key="2">
                  {this.state.tabActive === "2" && (
                    <Preferences
                      localCurrency={this.state.localCurrency}
                      getPreferences={this.getPreferences.bind(this)}
                      handleLocalCurrency={this.handleLocalCurrency.bind(this)}
                      activeSave={this.state.activeSave}
                    />
                  )}
                </TabPane>

                <TabPane
                  tab={this.props.t("menu.security")}
                  key="3"
                  forceRender={true}
                >
                  {this.state.tabActive === "3" && <Security infoUser={info} usersChildren={this.state.usersChildrenList}/>}
                </TabPane>
                <TabPane
                  tab={this.props.t("menu.more")}
                  key="4"
                  forceRender={true}
                >
                  {this.state.tabActive === "4" && (
                    <More
                      spreads={this.state.spread}
                      infoTiers={this.state.infoTiers}
                    />
                  )}
                </TabPane>
              </Tabs>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser,
    accessToken: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
    responsePreferences: state.preferences.resultPreferences,
    responseSpread: state.exchange.spread,
    rateResponse: state.exchange.resultRate,
    onlyFiat: state.authUser.isFiatOnly,
    usersChildrenList: state.userChildren.resultUsers,
  };
};

const mapDispatchToProps = {
  getRate,
  getPreferences,
  getSpreads,
  currentToken,
  getUsersChildren,
};
const SettingsTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
);

export default SettingsTranslated;
