import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;
const timeline = {
  async getTimeline(idToken, accessToken, period) {
    let url = "";
    if (period !== "") {
      url = BASE_URL + config.timelineBalance + "period=" + period;
    } else {
      url = BASE_URL + config.timelineBalance;
    }

    const resp = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
};
export default timeline;
