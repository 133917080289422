import servicesBanking from "../../services/banking";
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const GET_FEES= "GET_FEES";
export const GET_LIMITS="GET_LIMITS";
export const SEND_PAYMENT="SEND_PAYMENT";
export const CONFIRM_PAY="CONFIRM_PAY";
export const REBALANCE="REBALANCE";
export function getAccounts(id, token) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesBanking.getAccounts(id, token);
      dispatch({
        type: GET_ACCOUNTS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_ACCOUNTS,
        payload: error.response,
      });
    }
  };
}
export function getFees(id, token,currency,network) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesBanking.getFees(id, token,currency,network);
      dispatch({
        type: GET_FEES,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_FEES,
        payload: error.response,
      });
    }
  };
}
export function getLimits(id, token,currency,network) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesBanking.getLimits(id, token,currency,network);
      dispatch({
        type: GET_LIMITS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_LIMITS,
        payload: error.response,
      });
    }
  };
}
export function sendPay(id, token,body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesBanking.sendPayments(id, token,body);
      dispatch({
        type: SEND_PAYMENT,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: SEND_PAYMENT,
        payload: error.response,
      });
    }
  };
}
export function confirmPay(id, token,body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesBanking.confirmPayment(id, token,body);
      dispatch({
        type: CONFIRM_PAY,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: CONFIRM_PAY,
        payload: error.response,
      });
    }
  };
}
export function rebalance(id, token,body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesBanking.confirmRebalance(id, token,body);
      dispatch({
        type: REBALANCE,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: REBALANCE,
        payload: error.response,
      });
    }
  };
}