import { combineReducers } from "redux";
import { siderNavigate } from "./navigate";
import storage from "redux-persist/lib/storage";
import { authUser, requestAccount } from "./user";
import { passwordRecovery } from "./passwordRecovery";
import { twoFactor } from "./twoFactor";
import { tokenAccess } from "./token";
import { updateProfile } from "./profile";
import { banking } from "./banking";
import { loadNotifications } from "./notifications";
import { beneficiaries } from "./beneficiaries";
import { transactions } from "./transactions";
import { exchange } from "./exchange";
import { preferences } from "./preferences";
import { timeline } from "./timeline";
import { audit } from "./audit";
import { userChildren } from "./userChildren";
import { approvals } from "./approvals";
import { LOGGED_OUT } from "../actions/userActions";

const appReducer = combineReducers({
  siderNavigate,
  authUser,
  requestAccount,
  passwordRecovery,
  twoFactor,
  tokenAccess,
  updateProfile,
  banking,
  loadNotifications,
  beneficiaries,
  transactions,
  exchange,
  preferences,
  timeline,
  audit,
  userChildren,
  approvals,
});

const rootReducer = (state, action) => {
  if (action.type === LOGGED_OUT) {
    state = undefined;
    storage.removeItem("persist:authUser");
    storage.removeItem("timeToken");
  }
  return appReducer(state, action);
};

export default rootReducer;
