import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./rebalance.css";
import { Tabs } from "antd";
import Rebalance from "./rebalance";
import ConfirmRebalance from "./confirmRebalance";
import { connect } from "react-redux";
import { currentToken } from "../../redux/actions/tokenActions";
const { TabPane } = Tabs;

class ContainerRebalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: "1",
      current: 0,
      assetActiveTo: "",
      assetActiveFrom: "",
      amount: 0,
      amountFloat: 0,
      listAssetTo: [],
      listAssetFrom: [],
      listAsset: [],
      accounts: [],
      loading: false,
      nativeCurrency: "",
      nativeSymbol: "",
      nativeBalance: "",
    };
  }
  componentDidMount() {}

  nextStep(event, action) {
    this.setState({ current: this.state.current + 1 });
  }

  prevStep() {
    this.setState({ current: this.state.current - 1 });
  }
  handleTab(key) {
    this.setState({ tabActive: key });
  }

  handleAssetFrom(currency, listAssets) {
    this.setState({
      amount: 0,
      amountFloat: 0,
    });
    let infoAssetSelected = this.findInfoAccount(currency.id);
    let nativeBalanceInfo = this.props.banking.data.data.native_balances;
    let findBalanceNative = nativeBalanceInfo.currencies.find(
      (element) => element.currency === currency.value
    );
    if (findBalanceNative.accounts !== undefined) {
      findBalanceNative = findBalanceNative.accounts.find(
        (element) => element._id === currency.id
      );
    }
    let filterAssets = listAssets.filter(
      (element) => element.id !== currency.id
    );
    let balancePercentage = {
      twentyFive: parseFloat(infoAssetSelected.balance) * 0.25,
      fifty: parseFloat(infoAssetSelected.balance) * 0.5,
      all: parseFloat(infoAssetSelected.balance),
    };
    this.setState({
      listAssetFrom: filterAssets,
      assetActiveBalance: infoAssetSelected.balance,
      assetActiveFrom: infoAssetSelected,
      labelActiveFrom: infoAssetSelected.alias,
      activeInput: infoAssetSelected.value,
      nativeCurrency: nativeBalanceInfo.native_currency,
      nativeSymbol: nativeBalanceInfo.native_symbol,
      nativeBalance: findBalanceNative.native_balance,
      percentageForTrade: balancePercentage,
    });
  }
  handleAssetTo(currency, listAssets) {
    this.setState({ amount: 0 });
    let infoAssetSelected = this.findInfoAccount(currency.id);

    let filterAssets = listAssets.filter(
      (element) => element.id !== currency.id
    );
    this.setState({
      listAssetTo: filterAssets,
      assetActiveTo: infoAssetSelected,
      labelActiveTo: infoAssetSelected.alias,
    });
  }
  findInfoAccount(account) {
    let accountsInfo = this.props.banking.data.data.accounts;
    let findAccount = accountsInfo.find((element) => {
      return element._id === account;
    });
    let objAccount = {};
    objAccount.symbol = findAccount.symbol;
    objAccount.name = findAccount.currency_name;
    objAccount.value = findAccount.currency;
    objAccount.type = findAccount.type;
    objAccount.id = findAccount._id;
    objAccount.balance = findAccount.available_balance;
    objAccount.alias =
      findAccount.alias !== undefined ? findAccount.alias : null;
    objAccount.status = findAccount.status;
    return objAccount;
  }
  handleAmount = (values) => {
    this.setState({
      amount: values.formattedValue,
      amountFloat: values.floatValue === undefined ? 0 : values.floatValue,
    });
  };

  resetCurrent() {
    this.setState({ current: 0 });
  }

  render() {
    let stepConfirm = "";
    if (this.state.tabActive === "1") {
      stepConfirm = (
        <ConfirmRebalance
          prev={this.prevStep.bind(this)}
          assetActive={this.state.assetActiveFrom}
          assetActiveReceive={this.state.assetActiveTo}
          labelActive={this.state.labelActive}
          amount={this.state.amount}
          amountFloat={this.state.amountFloat}
          assetActiveBalance={this.state.assetActiveBalance}
          loading={this.state.loading}
          next={this.nextStep.bind(this)}
          resetCurrent={this.resetCurrent.bind(this)}
          nativeBalance={this.state.nativeBalance}
          nativeSymbol={this.state.nativeSymbol}
          nativeCurrency={this.state.nativeCurrency}
        />
      );
    }

    let tabRebalance = (
      <div
        id={
          this.props.source === "trade"
            ? "custom-tab-detail-trade"
            : "custom-tab"
        }
        className="ant-tabs ant-tabs-tab ant-tabs-nav ant-tabs-card ant-tabs-tab-active ant-tabs-tab-btn ant-tabs-top"
      >
        <Tabs
          type="card"
          centered
          size="large"
          tabBarGutter={2}
          tabBarStyle={{ width: "100%", color: "#ccc" }}
          activeKey={this.state.tabActive}
          onTabClick={(key) => this.handleTab(key)}
        >
          <TabPane
            forceRender={false}
            style={{ width: "100%", textAlign: "center" }}
            tab={"Rebalance"}
            key="1"
          >
            {this.state.tabActive === "1" && (
              <Rebalance
                next={this.nextStep.bind(this)}
                source={this.props.source}
                handleAssetFrom={this.handleAssetFrom.bind(this)}
                handleAssetTo={this.handleAssetTo.bind(this)}
                assetActiveFrom={this.state.assetActiveFrom}
                assetActiveTo={this.state.assetActiveTo}
                amount={this.state.amount}
                amountFloat={this.state.amountFloat}
                handleAmount={this.handleAmount.bind(this)}
                labelActiveFrom={this.state.labelActiveFrom}
                labelActiveTo={this.state.labelActiveTo}
                listAssetTo={this.state.listAssetTo}
                listAssetFrom={this.state.listAssetFrom}
                assetActiveBalance={this.state.assetActiveBalance}
                loading={this.state.loading}
                nativeBalance={this.state.nativeBalance}
                nativeSymbol={this.state.nativeSymbol}
                nativeCurrency={this.state.nativeCurrency}
                showMessageLimit={this.state.showMessageLimit}
                messageLimit={this.state.messageLimit}
                percentageForTrade={this.state.percentageForTrade}
              />
            )}
          </TabPane>
        </Tabs>
      </div>
    );
    const steps = [
      {
        content: tabRebalance,
      },
      {
        content: stepConfirm,
      },
    ];
    return <div>{steps[this.state.current].content}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    banking: state.banking.accounts,
    accessToken: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
  };
};
const mapDispatchToProps = {
  currentToken,
};
const ContainerRebalanceTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ContainerRebalance)
);

export default ContainerRebalanceTranslated;
