import React, { Component } from "react";
import "./users.css";
import {
  Row,
  Typography,
  Button,
  Space,
  message,
  Modal,
  Select,
  Form,
  Input,
} from "antd";
import COLOR from "../../const/colors";
import { v4 as uuidv4 } from "uuid";
import config from "../../services/config";
import { withTranslation } from "react-i18next";
import { RightOutlined } from "@ant-design/icons";
import KeyboardBackspace from "@2fd/ant-design-icons/lib/KeyboardBackspace";
import { connect } from "react-redux";
import { currentToken } from "../../redux/actions/tokenActions";
import { createUser } from "../../redux/actions/usersChildrenActions";
const { Text } = Typography;
const { Option } = Select;
class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      roleList: [
        {
          key: "TRADER",
          value: "TRADER",
          text: this.props.t("usersChildren.rol.TRADER"),
        },
        {
          key: "VIEWER",
          value: "VIEWER",
          text: this.props.t("usersChildren.rol.VIEWER"),
        },
        {
          key: "ADMIN",
          value: "ADMIN",
          text: this.props.t("usersChildren.rol.ADMIN"),
        },
      ],
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      status: "",
      password: "",
      statusList: [
        {
          key: "ACTIVE",
          value: "ACTIVE",
          text: this.props.t("usersChildren.status.ACTIVE"),
        },
        {
          key: "INACTIVE",
          value: "INACTIVE",
          text: this.props.t("usersChildren.status.INACTIVE"),
        },
      ],
    };
  }
  componentDidMount() {}

  async createNewUser() {
    try {
      this.setState({ loading: true });
      let tokenId = uuidv4();
      let data = this.state.password;
      let enumData = config.ENUM;
      let pass = new Buffer(data + enumData + tokenId);
      let passwordToBase64 = pass.toString("base64");
      let body = {
        email: this.state.email,
        name: this.state.firstName,
        last_name: this.state.lastName,
        password: passwordToBase64,
        role: this.state.role,
      };
      await this.props.createUser(
        this.props.clientId,
        this.props.accessToken,
        body
      );
      let response = this.props.createUserResponse;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }

      if (response.status === 200 || response.status === 201) {
        this.setState({ loading: false });
        message.success(this.props.t("usersChildren.messages.success"));
        this.props.closeModalCreate();
        this.props.getUsers(true);
      } else if (response.status === 400) {
        this.setState({ loading: false });
        if (response.data.error_details.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;
          message.error(
            this.props.t("usersChildren.error." + messageError),
            10
          );
        } else if (response.data.error_details.payload_error !== undefined) {
          let messageError = response.data.error_details.payload_error;
          message.error(
            this.props.t("usersChildren.error." + messageError),
            10
          );
        } else {
          message.error(response.data.message);
        }
      } else if (response.status === 403) {
        message.error(this.props.t("usersChildren.error.accessError"), 10);
        this.setState({ loading: false });
      } else if (
        response.status === 401 ||
        response.status === 404 ||
        response.status === 422 ||
        response.status === 500
      ) {
        this.setState({ loading: false });
        if (response.data.error_details?.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;
          message.error(
            this.props.t("usersChildren.error." + messageError),
            10
          );
        } else {
          message.error(this.props.t("usersChildren.error.errorServer"), 10);
        }
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  }
  render() {
    return (
      <>
        <Modal
          open={this.props.modalCreate}
          bodyStyle={{ background: COLOR.PRIMARY }}
          maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
          onOk={() => this.props.createUser()}
          onCancel={() => this.props.closeModalCreate()}
          footer={null}
          title={
            <Text
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 20,
                fontWeight: 400,
                textAlign: "left",
                alignItems: "center",
                display: "inline-flex",
              }}
            >
              <KeyboardBackspace
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 30,
                  marginRight: 20,
                }}
                onClick={() => this.props.closeModalCreate()}
              />
              {this.props.t("usersChildren.create")}
            </Text>
          }
        >
          <Space direction="vertical" style={{ width: "100%" }} size="middle">
            <Row justify="center">
              <Form
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 24 },
                  xl: { span: 24 },
                  xxl: { span: 24 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 24 },
                  xl: { span: 24 },
                  xxl: { span: 24 },
                }}
                layout="vertical"
                style={{ width: "100%" }}
              >
                <Form.Item
                  label={
                    <Text className="label-dark">
                      * {this.props.t("usersChildren.firstName")}
                    </Text>
                  }
                  name="firstName"
                >
                  <Input
                    size="large"
                    placeholder={this.props.t("usersChildren.firstName")}
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      background: COLOR.PRIMARY,
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <Text className="label-dark">
                      * {this.props.t("usersChildren.lastName")}
                    </Text>
                  }
                  name="lastName"
                >
                  <Input
                    size="large"
                    placeholder={this.props.t("usersChildren.lastName")}
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      background: COLOR.PRIMARY,
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <Text className="label-dark">
                      * {this.props.t("usersChildren.emailAddress")}
                    </Text>
                  }
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: this.props.t("signin.messages.emailError"),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={this.props.t("usersChildren.emailAddress")}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      background: COLOR.PRIMARY,
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <Text className="label-dark">
                      * {this.props.t("usersChildren.password")}
                    </Text>
                  }
                  name="password"
                  rules={[
                    {
                      min: 8,
                      message: this.props.t(
                        "changePassword.messages.minimumCharacters"
                      ),
                    },
                  ]}
                >
                  <div
                    id="input-password"
                    className="ant-input ant-input-password-icon .ant-input-affix-wrapper .ant-input-affix-wrapper-lg"
                  >
                    <Input.Password
                      size="large"
                      type="password"
                      placeholder={this.props.t("usersChildren.password")}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        background: COLOR.PRIMARY,
                      }}
                    />
                  </div>
                </Form.Item>

                <Form.Item
                  label={
                    <Text className="label-dark">
                      * {this.props.t("usersChildren.role")}
                    </Text>
                  }
                  name="role"
                >
                  <div
                    id="select-dark-user"
                    className="ant-select-selector ant-select"
                  >
                    <Select
                      suffixIcon={
                        <RightOutlined
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                          }}
                        />
                      }
                      style={{
                        color: "#ccc",
                      }}
                      size="large"
                      placeholder={this.props.t("usersChildren.selectRole")}
                      onChange={(value, option) => {
                        this.setState({ role: option.value });
                      }}
                    >
                      {this.state.roleList.map((item, i) => {
                        return (
                          <Option key={item.key} value={item.value}>
                            {item.text}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Form.Item>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    block
                    type="primary"
                    style={{
                      height: "60px",
                      fontSize: "18px",
                      fontWeight: "bold",
                      width: "50%",
                    }}
                    loading={this.state.loading}
                    disabled={
                      this.state.firstName === "" ||
                      this.state.lastName === "" ||
                      this.state.email === "" ||
                      this.state.password === "" ||
                      this.state.role === ""
                    }
                    onClick={() => this.createNewUser()}
                  >
                    {this.props.t("usersChildren.createButton")}
                  </Button>
                </Form.Item>
              </Form>
            </Row>
          </Space>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser,
    accessToken: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
    createUserResponse: state.userChildren.respCreate,
  };
};

const mapDispatchToProps = {
  currentToken,
  createUser,
};

const CreateUserTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CreateUser)
);

export default CreateUserTranslated;
