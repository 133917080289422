import React, { Component } from "react";
import { Layout, Typography, Select, Space, Button } from "antd";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import COLOR from "../../const/colors";
import i18n from "../../i18n/i18n";
import "./footer.css";
const { Footer } = Layout;
const { Text } = Typography;
const { Option } = Select;

class Footerapp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: "",
    };
  }
  handleLanguage(value) {
    this.setState({ language: value });
    if (value === "en") {
      i18n.changeLanguage("en-US");
      sessionStorage.setItem("lang", "en-US");
    } else {
      i18n.changeLanguage("es-ES");
      sessionStorage.setItem("lang", "es-ES");
    }
  }
  render() {
    var currentTime = new Date();
    var year = currentTime.getFullYear();
    return (
      <div>
        <Footer
          className={isMobileOnly ? "footer-mobile" : "footer"}
          style={{
            background: COLOR.PRIMARY,
            color: COLOR.PRIMARY_TEXT,
          }}
        >
          <Space
            align="center"
            direction="horizontal"
            style={{ marginBottom: isMobileOnly ? 10 : 0 }}
          >
            <a
              href="https://www.damex.io/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text
                style={{
                  color: COLOR.GREEN,
                  textAlign: isMobileOnly ? "center" : "left",
                }}
              >
                {" Legal & Privacy"}
              </Text>
            </a>
            <Text
              style={{
                color: COLOR.SECONDARY_TEXT,
              }}
            >
              © {year} Digital Asset Management Limited & Damex.io UAB
            </Text>
          </Space>

          <Space align="center" direction="horizontal">
            <div id="select-option" className="ant-select-selector ant-select">
              <Select
                defaultValue={
                  sessionStorage.getItem("lang") === "es-ES"
                    ? "Spanish"
                    : "English"
                }
                size="small"
                style={{ width: 180 }}
                listHeight={100}
                onChange={(value) => this.handleLanguage(value)}
              >
                <Option value="en">English</Option>
                <Option value="es">Spanish</Option>
              </Select>
            </div>
            <Link to="#">
              <Button
                size="small"
                type="primary"
                onClick={(e) => {
                  window.location = "mailto:support@damex.io";
                  e.preventDefault();
                }}
              >
                {this.props.t("footer.help")}
              </Button>
            </Link>
          </Space>
        </Footer>
      </div>
    );
  }
}
const FooterApp = withTranslation()(Footerapp);

export default FooterApp;
