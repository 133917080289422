import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./modalChangeAvatar.css";
import { CloseOutlined } from "@ant-design/icons";
import userImage from "../../images/user-avatar.png";
import COLOR from "../../const/colors";
import { connect } from "react-redux";
import {
  authUser,
  updateProfile,
  confirmUpdate,
  signinSuccess,
} from "../../redux/actions/userActions";
import AvatarCrop from "react-avatar-edit";
import { currentToken } from "../../redux/actions/tokenActions";
import {
  Space,
  Typography,
  Button,
  message,
  Modal,
  Avatar,
  Row,
  Col,
} from "antd";
import { isMobileOnly } from "react-device-detect";

const { Text, Title } = Typography;
class ModalChangeAvatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      preview: null,
      src: null,
    };
    this.onCrop = this.onCrop.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this);
  }

  onClose() {
    this.setState({ preview: null, src: null });
  }
  closeModal = () => {
    this.props.closeModalChangeAvatar();
    this.setState({ preview: null, src: null });
  };
  async onCrop(preview) {
      let fileResult = await this.urltoFile(preview);
      this.setState({ preview: preview, src: fileResult });
   
  }
  urltoFile(url) {
    let name = this.state.src.name;
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        const fileResult = new File([buf], name, { type: "image/png" });
        return fileResult;
      });
  }
  onBeforeFileLoad(elem) {
    let sizeFile = elem.target.files[0].size / 1024 / 1024;
    if (sizeFile > 10) {
      message.error(this.props.t("requestAccount.messagesError.fileSize"));
    } else {
      this.setState({ src: elem.target.files[0] });
    }
  }
  async uploadAvatar() {
    try {
      this.setState({ loading: true });
      let dataAvatar = new FormData();
      dataAvatar.append("file", this.state.src);
      await this.props.updateProfile(
        this.props.token.clientId,
        this.props.token.current,
        dataAvatar
      );
      let response = this.props.result.update;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.data.success===true && response.data.success!==undefined) {
        this.setState({ loading: false });
        if (!response.data.data.confirmation_required) {
          await this.getUser();
          message.success(this.props.t("profile.messages.successAvatar"));
          this.setState({ loading: false });
          this.onClose();
          this.props.closeModalChangeAvatar();
        }
      } else {
        this.setState({ loading: false });
        if (response.status === 400) {
          message.error(this.props.t("profile.messages.dataError"));
        } else if (response.status === 404) {
          message.error(this.props.t("profile.messages.userInactive"));
        } else if (
          response.status === 401 ||
          response.status === 403 ||
          response.status === 500
        ) {
          message.error(this.props.t("profile.messages.errorServer"));
        }
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
      message.error(this.props.t("profile.messages.errorServer"));
    }
  }
  async getUser() {
    try {
      await this.props.signinSuccess(
        this.props.token.clientId,
        this.props.token.current
      );
      let response = this.props.dataUser.userData;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      this.setState({ loading: false });
      if (response.status === 401) {
        message.error(this.props.t("signin.messages.formError"));
      } else if (response.status === 403) {
        message.error(this.props.t("signin.messages.formError"));
      } else if (response.status === 404) {
        message.error(this.props.t("signin.messages.formError"));
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  }
  render() {
    return (
      <Modal
        title={
          <Title level={4} style={{ color: COLOR.PRIMARY_TEXT }}>
            {this.props.t("profile.changeAvatar")}
          </Title>
        }
        maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
        bodyStyle={{
          background: COLOR.PRIMARY,
          color: COLOR.PRIMARY_TEXT,
          textAlign: "center",
          padding: "24px 0px 0px 0px",
          justifyContent: "center",
        }}
        footer={null}
        className="container-change-avatar"
        open={this.props.changeAvatar}
        onOk={() => this.props.openModalChangeAvatar()}
        onCancel={this.closeModal}
        centered={true}
        closeIcon={<CloseOutlined style={{ color: COLOR.GRAY }} />}
        width={600}
      >
        <Space direction="vertical" align="center" size="large">
          <Space direction="horizontal" align="center" size="small">
            <Row
              gutter={[16, { xs: 8, sm: 8, md: 8, lg: 16, xl: 8 }]}
              align="middle"
            >
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 17, offset: 0 }}
                xl={{ span: 17, offset: 0 }}
              >
                <Space direction="horizontal" align="center" size="small">
                  <AvatarCrop
                    width={isMobileOnly ? 300 : 400}
                    imageWidth={isMobileOnly ? 300 : 350}
                    height={300}
                    imageHeight={300}
                    onCrop={this.onCrop}
                    onClose={this.onClose}
                    onBeforeFileLoad={this.onBeforeFileLoad}
                    src={null}
                    label={this.props.t("profile.file")}
                    labelStyle={{
                      color: COLOR.PRIMARY_TEXT,
                      fontSize: 18,
                      padding: isMobileOnly ? "80px" : "130px",
                      cursor: "pointer",
                    }}
                  />
                </Space>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 7, offset: 0 }}
                xl={{ span: 7, offset: 0 }}
              >
                {this.state.preview !== null && (
                  <Space direction="vertical" align="center" size="small">
                    <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 18 }}>
                      {this.props.t("profile.preview")}
                    </Text>
                    <Avatar
                      style={{ width: 150, height: 150 }}
                      size="large"
                      src={this.state.preview}
                    />
                  </Space>
                )}
                {this.state.preview === null && (
                  <Space direction="vertical" align="center" size="small">
                    <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 18 }}>
                      {this.props.t("profile.preview")}
                    </Text>
                    <Avatar
                      style={{ width: 150, height: 150 }}
                      size="large"
                      src={userImage}
                    />
                  </Space>
                )}
              </Col>
            </Row>
          </Space>
          <Space
            direction="horizontal"
            align="center"
            size="middle"
            style={{ marginBottom: 20 }}
          >
            <Button
              size="large"
              className="btn-cancel-avatar"
              onClick={this.closeModal}
            >
              {this.props.t("profile.buttonCancel")}
            </Button>
            <Button
              type="primary"
              size="large"
              disabled={this.state.preview === null}
              loading={this.state.loading}
              className="btn-upload"
              onClick={() => this.uploadAvatar()}
            >
              {this.props.t("profile.upload")}
            </Button>
          </Space>
        </Space>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser,
    result: state.updateProfile,
    token: state.tokenAccess,
  };
};

const mapDispatchToProps = {
  authUser,
  updateProfile,
  confirmUpdate,
  currentToken,
  signinSuccess,
};

const ModalChangeAvatarTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ModalChangeAvatar)
);

export default ModalChangeAvatarTranslated;
