import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./modalChangePassword.css";
import { CloseOutlined } from "@ant-design/icons";
import COLOR from "../../const/colors";
import { connect } from "react-redux";
import {
  authUser,
  updateProfile,
  confirmUpdate,
  signinSuccess,
} from "../../redux/actions/userActions";
import { currentToken } from "../../redux/actions/tokenActions";
import {
  Row,
  Col,
  Space,
  Typography,
  Button,
  Divider,
  message,
  Modal,
  Input,
  Form,
} from "antd";
const { Text, Title } = Typography;
class ModalChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalPassword: false,
      loading: false,
    };
  }
  openModalChangePassword() {
    this.setState({ modalPassword: true });
  }
  async requestChangePassword() {
    try {
      if(this.props.newPassword.length>=8 && this.props.confirmNewPassword.length>=8){
      this.setState({ loading: true });
      let body = {
        field: "PASSWORD",
        current_password: this.props.oldPasswordFormatted,
        new_password: this.props.newPasswordFormatted,
      };
      await this.props.updateProfile(
        this.props.token.clientId,
        this.props.token.current,
        body
      );
      let response = this.props.result.update;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }

      if (response.data.success) {
        if (!response.data.data.confirmation_required) {
          this.setState({ loading: false });
          message.success(
            this.props.t("changePassword.messages.successChange")
          );
          this.props.closeModalPassword();
        }
      } else {
        this.setState({ loading: false });
        if (response.status === 400) {
          message.error(
            this.props.t("changePassword.messages.passwordDifferent")
          );
        } else if (response.status === 401) {
          message.error(
            this.props.t("changePassword.messages.invalidPassword")
          );
        } else if (response.status === 403) {
          message.error(this.props.t("changePassword.messages.errorServer"));
        } else if (response.status === 404) {
          message.error(this.props.t("changePassword.messages.userInactive"));
        } else {
          message.error(this.props.t("changePassword.messages.errorServer"));
        }
      }
    }else{
      message.error(this.props.t(
        "changePassword.messages.minimumCharacters"
      ));
    }
    } catch (error) {
      console.log(error);
      message.error(this.props.t("changePassword.messages.errorServer"));
    }
  }
  render() {
    let passwordMatch = this.props.t("forgotPassword.messages.passwordMatch");
    return (
      <Modal
        title={
          <Title level={4} style={{ color: COLOR.PRIMARY_TEXT }}>
            {this.props.t("changePassword.title")}
          </Title>
        }
        bodyStyle={{
          background: COLOR.PRIMARY,
          color: COLOR.PRIMARY_TEXT,
          textAlign: "center",
          padding: "24px 0px 0px 0px",
        }}
        maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
        footer={null}
        className="container-change-password"
        open={this.props.modalPassword}
        onOk={() => this.props.openModalChangePassword()}
        onCancel={() => this.props.closeModalPassword()}
        centered={true}
        closeIcon={<CloseOutlined style={{ color: COLOR.GRAY }} />}
        width={400}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Row gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8 }]} justify="center">
            <Form
              name="change-password"
              initialValues={{oldPassword:"",newPassword:"",confirmNewPassword:""}}
              layout="vertical"
              labelCol={{ span: 20, offset: 2 }}
              wrapperCol={{ span: 20, offset: 2 }}
              style={{ width: "100%" }}
              onFinish={() => this.requestChangePassword()}
              requiredMark={false}
            >
              <Form.Item
                name="oldPassword"
                label={
                  <Text style={{ color: "#fff", fontSize: 16 }}>
                    {this.props.t("changePassword.oldPassword")}
                  </Text>
                }
                valuePropName="defaultValue"
              >
                <Input.Password
                  size="large"
                  placeholder={this.props.t("changePassword.oldPassword")}
                  value={this.props.oldPassword}
                  onChange={(e) => this.props.handleOldPassword(e)}
                />
              </Form.Item>
              <Form.Item
                name="newPassword"
                label={
                  <Text style={{ color: "#fff", fontSize: 16 }}>
                    {this.props.t("changePassword.newPassword")}
                  </Text>
                }
                valuePropName="defaultValue"
              >
                <Input.Password
                  type="text"
                  size="large"
                  placeholder={this.props.t("changePassword.newPassword")}
                  value={this.props.newPassword}
                  onChange={(e) => this.props.handleNewPassword(e)}
                />
              </Form.Item>
              <Form.Item
                name="confirmNewPassword"
                valuePropName="defaultValue"
                label={
                  <Text style={{ color: "#fff", fontSize: 16 }}>
                    {this.props.t("changePassword.confirmPassword")}
                  </Text>
                }
                dependencies={["newPassword"]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error(passwordMatch));
                    },
                  }),
                ]}
              >
                <Input.Password
                type="text"
                  size="large"
                  value={this.props.confirmNewPassword}
                  onChange={(e) => this.props.handleConfirmNewPassword(e)}
                  placeholder={this.props.t("changePassword.confirmPassword")}
                />
              </Form.Item>
              <Divider
                style={{
                  background: COLOR.PRIMARY,
                }}
              ></Divider>
              <Row
                gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8 }]}
                justify="center"
              >
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 12, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  xl={{ span: 12, offset: 0 }}
                >
                  <Form.Item style={{ textAlign: "center" }}>
                    <Button
                      size="large"
                      type="primary"
                      block
                      className="btn-previous-security"
                      onClick={() => this.props.closeModalPassword()}
                    >
                      {this.props.t("changePassword.cancel")}
                    </Button>
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 12, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  xl={{ span: 12, offset: 0 }}
                >
                  <Form.Item style={{ textAlign: "center" }}>
                    <Button
                      block
                      size="large"
                      type="primary"
                      htmlType="submit"
                      className="password-button"
                      loading={this.state.loading}
                      disabled={
                        this.props.oldPassword === "" ||
                        this.props.newPassword === "" ||
                        this.props.confirmNewPassword === "" ||
                        this.props.newPassword !== this.props.confirmNewPassword
                      }
                      style={{
                        height: 60,
                        fontSize: 18,
                        fontWeight: 600,
                        borderRadius: "5px",
                      }}
                    >
                      {this.props.t("changePassword.confirm")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Row>
        </Space>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser,
    result: state.updateProfile,
    token: state.tokenAccess,
  };
};

const mapDispatchToProps = {
  authUser,
  updateProfile,
  confirmUpdate,
  currentToken,
  signinSuccess,
};

const ModalChangePasswordTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ModalChangePassword)
);

export default ModalChangePasswordTranslated;
