export const SIDER = "SIDER";
export const ASSET = "ASSET";
export const MODAL = "MODAL";
export const MODAL_SEND = "MODAL_SEND";
export const INFO_TO_SEND = "INFO_TO_SEND";

export function siderNavigate(name) {
  return (dispatch, getState) => {
    dispatch({
      type: SIDER,
      payload: name,
    });
  };
}
export function selectedAsset(key) {
  return (dispatch, getState) => {
    dispatch({
      type: ASSET,
      payload: key,
    });
  };
}
export function openModal(value) {
  return (dispatch, getState) => {
    dispatch({
      type: MODAL,
      payload: value,
    });
  };
}
export function openModalSend(value) {
  return (dispatch, getState) => {
    dispatch({
      type: MODAL_SEND,
      payload: value,
    });
  };
}
export function infoBeneficiaryToSend(value) {
  return (dispatch, getState) => {
    dispatch({
      type: INFO_TO_SEND,
      payload: value,
    });
  };
}
