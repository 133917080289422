import React, { Component } from "react";
import "./changeEmail.css";
import {
  Row,
  Col,
  Space,
  Typography,
  Button,
  Divider,
  Modal,
  Input,
  message,
} from "antd";
import COLOR from "../../const/colors";
import Email from "@2fd/ant-design-icons/lib/Email";
import { withTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { confirmUpdate, signinSuccess } from "../../redux/actions/userActions";
import { currentToken } from "../../redux/actions/tokenActions";
const { Text, Title } = Typography;
class ChangeEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeNewEmail: "",
      codeOldEmail: "",
      loading:false
    };
  }
  closeModalChangeEmail() {
    
    this.props.closeModal();
    this.setState({ codeOldEmail: "", codeNewEmail: "" });
  }
  onChangeCodeOld (e) {
    const reg = /^[0-9]+$/;
    if ( reg.test(e.target.value)) {
     this.setState({ codeOldEmail: e.target.value });
    }
  };
  onChangeCodeNew (e) {
    const reg = /^[0-9]+$/;
    if ( reg.test(e.target.value)) {
      this.setState({ codeNewEmail: e.target.value });
    }
  };
  async confirmChangeEmail() {
    try {
      this.setState({loading:true})
      let body = {
        otp: this.state.codeOldEmail,
        new_otp: this.state.codeNewEmail,
      };
      await this.props.confirmUpdate(
        this.props.token.clientId,
        this.props.token.current,
        body
      );
      let response = this.props.update.confirm;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.data.success) {
        this.setState({loading:false})
        message.success(this.props.t("changeEmail.messages.successChange"))
        await this.getUser();
        this.closeModalChangeEmail()
      } else {
        this.setState({loading:false})
        if(response.status===400){
          message.error(this.props.t("changeEmail.messages.dataError"))
        }else if(response.status===401){
          message.error(this.props.t("changeEmail.messages.incorrectCode"))
        }else if(response.status===403){
          message.error(this.props.t("changeEmail.messages.codeExpired"))
          message.error(this.props.t("changeEmail.messages.accessError"))
        }else if(response.status===404){
          message.error(this.props.t("changeEmail.messages.userInactive"))
        }else{
          message.error(this.props.t("changeEmail.messages.errorServer"))
        }
      }
    } catch (error) {
      this.setState({loading:false})
      message.error(this.props.t("changeEmail.messages.errorServer"))
    }
  }
  async getUser() {
    try {
      await this.props.signinSuccess(
        this.props.token.clientId,
        this.props.token.current
      );
      let response = this.props.dataUser.userData;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 401) {
        this.setState({loading:false})
        message.error(this.props.t("signin.messages.formError"));
      } else if (response.status === 403) {
        this.setState({loading:false})
        message.error(this.props.t("signin.messages.formError"));
      } else if (response.status === 404) {
        this.setState({loading:false})
        message.error(this.props.t("signin.messages.formError"));
      }
    } catch (error) {
      this.setState({loading:false})
      console.log(error);
    }
  }
  render() {
    return (
      <div>
        <Modal
          title={
            <Title level={4} style={{ color: COLOR.PRIMARY_TEXT }}>
              {this.props.t("changeEmail.confirmChanges")}
            </Title>
          }
          footer={null}
          className="container-modal-security"
          bodyStyle={{
            background: COLOR.PRIMARY,
            color: COLOR.PRIMARY_TEXT,
            textAlign: "center",
            padding: "24px 0px 24px 0px",
          }}
          maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
          open={this.props.modalChangeEmail}
          onOk={() => this.props.openModal(false)}
          onCancel={() => this.props.closeModal()}
          centered={true}
          closeIcon={<CloseOutlined style={{ color: COLOR.GRAY }} />}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <Row
              gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}
              justify="center"
            >
              <Space direction="vertical">
                <Text
                  style={{
                    color: COLOR.PRIMARY_TEXT,
                    fontSize: 16,
                    padding: "0px 24px",
                  }}
                >
                  {this.props.t("changeEmail.instructions")}
                </Text>
              </Space>
            </Row>
            <Divider
              style={{
                background: COLOR.PRIMARY_TEXT,
              }}
            ></Divider>
            <Row
              gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}
              justify="center"
            >
              <Space direction="vertical">
                <Text
                  style={{
                    color: COLOR.PRIMARY_TEXT,
                    fontSize: 16,
                    padding: "0px 24px",
                  }}
                >
                  {this.props.t("changeEmail.enterCode") + this.props.oldEmail}
                </Text>

                <Space direction="horizontal" size="small">
                  <Email
                    style={{
                      color: COLOR.GREEN,
                      fontSize: 40,
                      width: "20%",
                      float: "right",
                    }}
                  />
                  <Input
                    value={this.state.codeOldEmail}
                    maxLength={6}
                    size="large"
                    style={{ width: "50%", color:"black" }}
                    placeholder="000000"
                    onChange={(e) => this.onChangeCodeOld(e)}
                  />
                </Space>
              </Space>
              <Space direction="vertical">
                <Text
                  style={{
                    color: COLOR.PRIMARY_TEXT,
                    fontSize: 16,
                    padding: "0px 24px",
                  }}
                >
                  {this.props.t("changeEmail.enterCode") + this.props.newEmail}
                </Text>

                <Space direction="horizontal" size="small">
                  <Email
                    style={{
                      color: COLOR.GREEN,
                      fontSize: 40,
                      width: "20%",
                      float: "right",
                    }}
                  />
                  <Input
                    value={this.state.codeNewEmail}
                    maxLength={6}
                    size="large"
                    style={{ width: "50%" , color:"black"}}
                    placeholder="000000"
                    onChange={(e) => this.onChangeCodeNew(e)}
                  />
                </Space>
                <br />
                <div>
                  <Text
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      padding: "0px 10px 0px 0px",
                    }}
                  >
                    {this.props.t("changeEmail.noReceived")}
                  </Text>
                  <Button
                    type="link"
                    disabled={this.props.disableResend}
                    style={{
                      color: COLOR.GREEN_1,
                      fontWeight: "bold",
                      padding: 0,
                    }}
                    onClick={() => this.props.openModal(true)}
                  >
                    {this.props.t("changeEmail.resend")}
                  </Button>
                </div>
              </Space>
            </Row>
            <Divider
              style={{
                background: COLOR.PRIMARY_TEXT,
              }}
            ></Divider>
            <Row gutter={[32, { xs: 8, sm: 16, md: 24, lg: 32 }]} justify="end">
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
              >
                <Button
                  block
                  size="large"
                  className="btn-previous-security"
                  onClick={() => this.props.closeModal()}
                >
                  {this.props.t("changeEmail.buttonCancel")}
                </Button>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 12, offset: 0 }}
              >
                <Button
                  block
                  size="large"
                  type="primary"
                  loading={this.state.loading}
                  disabled={
                    this.state.codeNewEmail === "" ||
                    this.state.codeOldEmail === "" ||
                    this.state.codeNewEmail.toString().length !== 6 ||
                    this.state.codeOldEmail.toString().length !== 6
                  }
                  onClick={() => this.confirmChangeEmail()}
                  style={{
                    height: 60,
                    fontSize: 18,
                    fontWeight: 600,
                    borderRadius: "5px",
                  }}
                >
                  {this.props.t("changeEmail.buttonConfirm")}
                </Button>
              </Col>
            </Row>
          </Space>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser,
    update: state.updateProfile,
    token: state.tokenAccess,
  };
};

const mapDispatchToProps = {
  confirmUpdate,
  signinSuccess,
  currentToken,
};
const ChangeEmailTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ChangeEmail)
);

export default ChangeEmailTranslated;
