import {
  GET_PREFERENCES,
  UPDATE_PREFERENCES,
} from "../actions/preferencesActions";
const initialState = {
  resultPreferences: "",
  resultUpdate: "",
};
export function preferences(state = initialState, action) {
  switch (action.type) {
    case GET_PREFERENCES:
      return Object.assign({}, state, { resultPreferences: action.payload });
    case UPDATE_PREFERENCES:
      return Object.assign({}, state, { resultUpdate: action.payload });
    default:
      return state;
  }
}
