import servicesNotifications from "../../services/notifications";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const MARK_READ_NOTIFICATIONS = "MARK_READ_NOTIFICATIONS";
export function getNotifications(id, token) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesNotifications.getNotifications(id, token);
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: error.response,
      });
    }
  };
}
export function markReadNotifications(id, token,ids) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesNotifications.markReadNotifications(id, token,ids);
      dispatch({
        type: MARK_READ_NOTIFICATIONS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: MARK_READ_NOTIFICATIONS,
        payload: error.response,
      });
    }
  };
}
