import React, { Component } from "react";
import "./users.css";
import {
  Row,
  Typography,
  Button,
  Space,
  message,
  Modal,
  Select,
  Form,
  Input,
  Popconfirm,
  Divider,
} from "antd";
import { v4 as uuidv4 } from "uuid";
import config from "../../services/config";
import COLOR from "../../const/colors";
import { withTranslation } from "react-i18next";
import { RightOutlined } from "@ant-design/icons";
import KeyboardBackspace from "@2fd/ant-design-icons/lib/KeyboardBackspace";
import { connect } from "react-redux";
import { currentToken } from "../../redux/actions/tokenActions";
import {
  updateUser,
  deleteUsersChildren,
  changeStatusUsersChildren,
} from "../../redux/actions/usersChildrenActions";
const { Text } = Typography;
const { Option } = Select;
class ManageUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingFilter: false,
      roleList: [
        {
          key: "TRADER",
          value: "TRADER",
          text: this.props.t("usersChildren.rol.TRADER"),
        },
        {
          key: "VIEWER",
          value: "VIEWER",
          text: this.props.t("usersChildren.rol.VIEWER"),
        },
        {
          key: "ADMIN",
          value: "ADMIN",
          text: this.props.t("usersChildren.rol.ADMIN"),
        },
      ],
      firstName: this.props.selectedUser.name,
      lastName: this.props.selectedUser.last_name,
      email: this.props.selectedUser.email,
      role: this.props.selectedUser.role,
      twoFactor: this.props.selectedUser.two_factor?.sign_in?.status,
      confirmPassword: "",
      password: "",
      status: this.props.selectedUser.status,
      editUser: false,
      statusList: [
        {
          key: "ACTIVE",
          value: "ACTIVE",
          text: this.props.t("usersChildren.status.ACTIVE"),
        },
        {
          key: "INACTIVE",
          value: "INACTIVE",
          text: this.props.t("usersChildren.status.INACTIVE"),
        },
      ],
    };
  }
  componentDidMount() {}
  async editFullUser() {
    await this.editUser();
    if (
      this.props.selectedUser.status !== this.state.status &&
      this.state.status !== ""
    ) {
      await this.changeStatus();
    }
  }
  async editUser() {
    try {
      this.setState({ loading: true });
      let id = this.props.selectedUser._id;
      let tokenId = uuidv4();
      let data = this.state.password;
      let enumData = config.ENUM;
      let pass = new Buffer(data + enumData + tokenId);
      let passwordToBase64 = pass.toString("base64");
      let body = {};
      if (
        this.props.selectedUser.name !== this.state.firstName &&
        this.state.firstName !== ""
      ) {
        body.name = this.state.firstName;
      }
      if (
        this.props.selectedUser.last_name !== this.state.lastName &&
        this.state.lastName !== ""
      ) {
        body.last_name = this.state.lastName;
      }
      if (
        this.props.selectedUser.email !== this.state.email &&
        this.state.email !== ""
      ) {
        body.email = this.state.email;
      }
      if (
        this.props.selectedUser.role !== this.state.role &&
        this.state.role !== ""
      ) {
        body.role = this.state.role;
      }
      if (
        this.props.selectedUser.two_factor?.sign_in.status !==
          this.state.twoFactor &&
        this.state.twoFactor !== ""
      ) {
        body.two_factor_sign_in = this.state.twoFactor;
      }

      if (this.state.password !== "") {
        body.password = passwordToBase64;
      }
      if (Object.keys(body).length > 0) {
        await this.props.updateUser(
          this.props.clientId,
          this.props.accessToken,
          id,
          body
        );
        let response = this.props.responseUpdate;
        if (response.headers !== undefined) {
          let newAccessToken = response.headers["x-new-access-token"];
          await this.props.currentToken(newAccessToken);
        }

        if (response.status === 200) {
          this.setState({ loading: false, editUser: true });
          message.success(this.props.t("usersChildren.messages.updated"));
          this.props.closeManageUser();
          this.props.getUsers(true);
        } else if (response.status === 400) {
          this.setState({ loading: false });
          if (response.data.error_details.validation_error !== undefined) {
            let messageError = response.data.error_details.validation_error;
            message.error(
              this.props.t("usersChildren.error." + messageError),
              10
            );
          } else if (response.data.error_details.payload_error !== undefined) {
            let messageError = response.data.error_details.payload_error;
            message.error(
              this.props.t("usersChildren.error." + messageError),
              10
            );
          } else {
            message.error(response.data.message);
          }
        } else if (response.status === 403) {
          message.error(this.props.t("usersChildren.error.accessError"), 10);
          this.setState({ loading: false });
        } else if (
          response.status === 401 ||
          response.status === 404 ||
          response.status === 422 ||
          response.status === 500
        ) {
          this.setState({ loading: false });
          if (response.data.error_details?.validation_error !== undefined) {
            let messageError = response.data.error_details.validation_error;
            message.error(
              this.props.t("usersChildren.error." + messageError),
              10
            );
          } else {
            message.error(this.props.t("usersChildren.error.errorServer"), 10);
          }
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }
  async removeUser() {
    try {
      this.setState({ loading: true });
      let id = this.props.selectedUser._id;
      await this.props.deleteUsersChildren(
        this.props.clientId,
        this.props.accessToken,
        id
      );
      let response = this.props.responseDelete;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }

      if (response.status === 200) {
        this.setState({ loading: false });
        message.success(this.props.t("usersChildren.messages.delete"));
        this.props.closeManageUser();
        this.props.getUsers(true);
      } else if (response.status === 400) {
        this.setState({ loading: false });
        if (response.data.error_details.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;
          message.error(
            this.props.t("usersChildren.error." + messageError),
            10
          );
        } else if (response.data.error_details.payload_error !== undefined) {
          let messageError = response.data.error_details.payload_error;
          message.error(
            this.props.t("usersChildren.error." + messageError),
            10
          );
        } else {
          message.error(response.data.message);
        }
      } else if (response.status === 403) {
        message.error(this.props.t("usersChildren.error.accessError"), 10);
        this.setState({ loading: false });
      } else if (
        response.status === 401 ||
        response.status === 404 ||
        response.status === 422 ||
        response.status === 500
      ) {
        this.setState({ loading: false });
        if (response.data.error_details?.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;
          message.error(
            this.props.t("usersChildren.error." + messageError),
            10
          );
        } else {
          message.error(this.props.t("usersChildren.error.errorServer"), 10);
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }
  async changeStatus() {
    try {
      let id = this.props.selectedUser._id;
      let newStatus =
        this.state.status === "INACTIVE" ? "inactivate" : "activate";
      await this.props.changeStatusUsersChildren(
        this.props.clientId,
        this.props.accessToken,
        id,
        newStatus
      );
      let response = this.props.resultChangeStatus;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }

      if (response.status === 200) {
        if (!this.state.editUser) {
          this.setState({ loading: false });
          message.success(this.props.t("usersChildren.messages.updated"));
          this.props.closeManageUser();
        }
        this.props.getUsers(true);
        setTimeout(() => {
          this.setState({ editUser: false });
        }, 5000);
      } else if (response.status === 400) {
        this.setState({ loading: false });
        if (response.data.error_details.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;
          message.error(
            this.props.t("usersChildren.error." + messageError),
            10
          );
        } else if (response.data.error_details.payload_error !== undefined) {
          let messageError = response.data.error_details.payload_error;
          message.error(
            this.props.t("usersChildren.error." + messageError),
            10
          );
        } else {
          message.error(response.data.message);
        }
      } else if (response.status === 403) {
        message.error(this.props.t("usersChildren.error.accessError"), 10);
        this.setState({ loading: false });
      } else if (
        response.status === 401 ||
        response.status === 404 ||
        response.status === 422 ||
        response.status === 500
      ) {
        this.setState({ loading: false });
        if (response.data.error_details?.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;
          message.error(
            this.props.t("usersChildren.error." + messageError),
            10
          );
        } else {
          message.error(this.props.t("usersChildren.error.errorServer"), 10);
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }
  disableButtonSave() {
    if (this.state.password !== "" || this.state.confirmPassword !== "") {
      if (
        this.state.password !== this.state.confirmPassword ||
        this.state.password.length < 8
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      this.props.selectedUser.name !== this.state.firstName ||
      this.props.selectedUser.last_name !== this.state.lastName ||
      this.props.selectedUser.email !== this.state.email ||
      this.props.selectedUser.role !== this.state.role ||
      this.props.selectedUser.status !== this.state.status ||
      this.props.selectedUser.two_factor?.sign_in.status !==
        this.state.twoFactor
    ) {
      return false;
    } else {
      return true;
    }
  }
  render() {
    let passwordMatch = this.props.t("forgotPassword.messages.passwordMatch");
    let roleCurrent = this.props.dataUser.userData.data?.data?.user.role;
    return (
      <>
        <Modal
          open={this.props.modalManageUser}
          bodyStyle={{ background: COLOR.PRIMARY }}
          maskStyle={{ backgroundColor: "rgba(0,0, 0, 0.90)" }}
          onOk={() => this.props.openManageUser()}
          onCancel={() => this.props.closeManageUser()}
          footer={null}
          title={
            <Text
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 20,
                fontWeight: 400,
                textAlign: "left",
                alignItems: "center",
                display: "inline-flex",
              }}
            >
              <KeyboardBackspace
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 30,
                  marginRight: 20,
                }}
                onClick={() => this.props.closeManageUser()}
              />{" "}
              {this.props.t("usersChildren.titleRemove")}
            </Text>
          }
        >
          <Space direction="vertical" style={{ width: "100%" }} size="middle">
            <Row justify="center">
              <Form
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 24 },
                  xl: { span: 24 },
                  xxl: { span: 24 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 24 },
                  xl: { span: 24 },
                  xxl: { span: 24 },
                }}
                layout="vertical"
                style={{ width: "100%" }}
              >
                <Form.Item
                  label={
                    <Text className="label-dark">
                      * {this.props.t("usersChildren.firstName")}
                    </Text>
                  }
                >
                  <Input
                    size="large"
                    placeholder={this.props.t("usersChildren.firstName")}
                    value={this.state.firstName}
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      background: COLOR.PRIMARY,
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <Text className="label-dark">
                      * {this.props.t("usersChildren.lastName")}
                    </Text>
                  }
                >
                  <Input
                    size="large"
                    placeholder={this.props.t("usersChildren.lastName")}
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                    value={this.state.lastName}
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      background: COLOR.PRIMARY,
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <Text className="label-dark">
                      * {this.props.t("usersChildren.emailAddress")}
                    </Text>
                  }
                >
                  <Input
                    size="large"
                    placeholder={this.props.t("usersChildren.emailAddress")}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    value={this.state.email}
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      background: COLOR.PRIMARY,
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <Text className="label-dark">
                      * {this.props.t("usersChildren.role")}
                    </Text>
                  }
                >
                  <div
                    id="select-dark-user"
                    className="ant-select-selector ant-select"
                  >
                    <Select
                      suffixIcon={
                        <RightOutlined
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                          }}
                        />
                      }
                      style={{
                        color: "#ccc",
                      }}
                      size="large"
                      placeholder={this.props.t("usersChildren.selectRole")}
                      disabled={
                        this.props.selectedUser.role === "ADMIN" &&
                        roleCurrent !== "MASTER"
                      }
                      value={this.state.role}
                      onChange={(value, option) => {
                        this.setState({ role: option.value });
                      }}
                    >
                      {this.state.roleList.map((item, i) => {
                        return (
                          <Option key={item.key} value={item.value}>
                            {item.text}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Form.Item>

                <Form.Item
                  label={
                    <Text className="label-dark">
                      * {this.props.t("usersChildren.twoFactor")}
                    </Text>
                  }
                >
                  <div
                    id="select-dark-user"
                    className="ant-select-selector ant-select"
                  >
                    <Select
                      suffixIcon={
                        <RightOutlined
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                          }}
                        />
                      }
                      style={{
                        color: "#ccc",
                      }}
                      size="large"
                      value={this.state.twoFactor}
                      onChange={(value) => {
                        this.setState({ twoFactor: value });
                      }}
                    >
                      {this.state.statusList.map((item, i) => {
                        return (
                          <Option key={item.key} value={item.value}>
                            {item.text}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Form.Item>
                <Form.Item
                  label={
                    <Text className="label-dark">
                      * {this.props.t("usersChildren.userStatus")}
                    </Text>
                  }
                >
                  <div
                    id="select-dark-user"
                    className="ant-select-selector ant-select"
                  >
                    <Select
                      suffixIcon={
                        <RightOutlined
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                          }}
                        />
                      }
                      style={{
                        color: "#ccc",
                      }}
                      size="large"
                      placeholder={this.props.t("usersChildren.selectStatus")}
                      disabled={
                        this.props.selectedUser.role === "ADMIN" &&
                        roleCurrent !== "MASTER"
                      }
                      value={this.state.status}
                      onChange={(value, option) => {
                        this.setState({ status: option.value });
                      }}
                    >
                      {this.state.statusList.map((item, i) => {
                        return (
                          <Option key={item.key} value={item.value}>
                            {item.text}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Form.Item>
                <Divider
                  orientation="left"
                  plain
                  style={{
                    color: COLOR.PRIMARY_TEXT,
                    margin: "10px 0",
                    borderTopColor: "#fff",
                  }}
                >
                  <Text style={{ color: "#fff", fontSize: 16 }}>
                    {this.props.t("usersChildren.changePassword")}
                  </Text>
                </Divider>
                <Form.Item
                  label={
                    <Text className="label-dark">
                      * {this.props.t("usersChildren.newPassword")}
                    </Text>
                  }
                  name="password"
                  rules={[
                    {
                      min: 8,
                      message: this.props.t(
                        "changePassword.messages.minimumCharacters"
                      ),
                    },
                  ]}
                >
                  <div
                    id="input-password"
                    className="ant-input .ant-input-password-icon .ant-input-affix-wrapper .ant-input-affix-wrapper-lg"
                  >
                    <Input.Password
                      size="large"
                      type="password"
                      placeholder={this.props.t("usersChildren.newPassword")}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        background: COLOR.PRIMARY,
                      }}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  label={
                    <Text className="label-dark">
                      * {this.props.t("usersChildren.confirmPassword")}
                    </Text>
                  }
                  name="newPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      message: this.props.t("forgotPassword.messages.input", {
                        key: this.props.t("forgotPassword.confirmPassword"),
                      }),
                    },
                    {
                      min: 8,
                      message: this.props.t(
                        "changePassword.messages.minimumCharacters"
                      ),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(new Error(passwordMatch));
                      },
                    }),
                  ]}
                >
                  <div
                    id="input-password"
                    className="ant-input .ant-input-password-icon .ant-input-affix-wrapper .ant-input-affix-wrapper-lg"
                  >
                    <Input.Password
                      size="large"
                      placeholder={this.props.t(
                        "usersChildren.confirmPassword"
                      )}
                      onChange={(e) =>
                        this.setState({ confirmPassword: e.target.value })
                      }
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        background: COLOR.PRIMARY,
                      }}
                    />
                  </div>
                </Form.Item>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    block
                    type="primary"
                    style={{
                      height: "60px",
                      fontSize: "18px",
                      fontWeight: "bold",
                      width: "50%",
                    }}
                    loading={this.state.loading}
                    disabled={this.disableButtonSave()}
                    onClick={() => this.editFullUser()}
                  >
                    {this.props.t("usersChildren.save")}
                  </Button>
                </Form.Item>
                {(roleCurrent === "MASTER" || this.state.role !== "ADMIN") && (
                  <Form.Item style={{ textAlign: "center" }}>
                    <Popconfirm
                      title="Are you sure you want to remove this user?"
                      onConfirm={() => this.removeUser()}
                      okText="Yes, remove"
                      cancelText="No, cancel"
                      icon={null}
                      cancelButtonProps={{ className: "cancel-button" }}
                      okButtonProps={{ className: "ok-button" }}
                    >
                      <Text
                        style={{
                          height: "60px",
                          fontSize: "16px",
                          fontWeight: "bold",
                          width: "50%",
                          color: "#cf202f",
                          cursor: "pointer",
                        }}
                      >
                        {this.props.t("usersChildren.remove")}
                      </Text>
                    </Popconfirm>
                  </Form.Item>
                )}
              </Form>
            </Row>
          </Space>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser,
    accessToken: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
    responseUpdate: state.userChildren.respUpdate,
    responseDelete: state.userChildren.respDelete,
    resultChangeStatus: state.userChildren.changeStatus,
  };
};

const mapDispatchToProps = {
  currentToken,
  updateUser,
  deleteUsersChildren,
  changeStatusUsersChildren,
};

const ManageUserTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ManageUser)
);

export default ManageUserTranslated;
