import servicesUser from "../../services/user";
export const TOKEN = "TOKEN";
export const CLIENT_ID = "CLIENT_ID";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export function currentToken(token) {
  return (dispatch, getState) => {
    dispatch({
      type: TOKEN,
      payload: token,
    });
  };
}

export function currentClientId(id) {
  return (dispatch, getState) => {
    dispatch({
      type: CLIENT_ID,
      payload: id,
    });
  };
}

export function refreshToken(id, accessToken) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesUser.refreshToken(id, accessToken);
      dispatch({
        type: REFRESH_TOKEN,
        payload: response,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: REFRESH_TOKEN,
          payload: error.response,
        });
      }
    }
  };
}
