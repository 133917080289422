import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;

const requestAccount = {
  async requestAccounts(body) {
    const resp = await axios.post(BASE_URL + config.requestAccount, body);
    return resp;
  },
  async uploadDocuments(id, formData, token, idAssociate) {
    let url = "";

    if (idAssociate !== undefined) {
      url =
        BASE_URL +
        config.requestAccount +
        "/" +
        id +
        "/associates/" +
        idAssociate +
        "/documents";
    } else {
      url = BASE_URL + config.requestAccount + "/" + id + "/documents";
    }
    const resp = await axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp;
  },

  async commit(applicantId, token) {
    const resp = await axios.get(
      BASE_URL + config.requestAccount + "/" + applicantId + "/commit",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp;
  },
  async createShareholder(applicantId, token, body, method) {
    let url = BASE_URL + config.requestAccount + "/" + applicantId + "/associates";
    if (method === "post") {
      const resp = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return resp;
    } else if(method==="put") {
      const resp = await axios.put(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return resp;
    }
  },
};
export default requestAccount;
