import axios from "axios";
import config from "./config";
const BASE_URL = config.URL;
const audit = {
  async getAuditLogs(
    idToken,
    accessToken,
    lastId,
    type,
    email,
    minDate,
    maxDate
  ) {
    let timezone = new Date().getTimezoneOffset();
    let baseUrl = BASE_URL + config.auditLogs;
    let url = "";
    if (
      email === null &&
      type === null &&
      minDate === null &&
      maxDate === null
    ) {
      url = baseUrl;
    }
    if (email !== null) {
      url = baseUrl + "?email=" + email;
    }
    if (type !== null) {
      url = baseUrl + "?type=" + type;
    }
    if (minDate !== null && maxDate !== null) {
      url =
        baseUrl +
        "?min_date=" +
        minDate +
        "&max_date=" +
        maxDate +
        "&timezone_offset=" +
        timezone;
    }
    if (email !== null && type !== null) {
      url = baseUrl + "?email=" + email + "&type=" + type;
    }
    if (email !== null && minDate !== null && maxDate !== null) {
      url =
        baseUrl +
        "?email=" +
        email +
        "&min_date=" +
        minDate +
        "&max_date=" +
        maxDate +
        "&timezone_offset=" +
        timezone;
    }
    if (type !== null && minDate !== null && maxDate !== null) {
      url =
        baseUrl +
        "?type=" +
        type +
        "&min_date=" +
        minDate +
        "&max_date=" +
        maxDate +
        "&timezone_offset=" +
        timezone;
    }
    if (
      email !== null &&
      type !== null &&
      minDate !== null &&
      maxDate !== null
    ) {
      url =
        baseUrl +
        "?email=" +
        email +
        "&type=" +
        type +
        "&min_date=" +
        minDate +
        "&max_date=" +
        maxDate;
    }
    if (
      lastId !== undefined &&
      email === null &&
      type === null &&
      minDate === null &&
      maxDate === null
    ) {
      url = baseUrl + "?last_id=" + lastId;
    }
    if (lastId !== null && lastId !== undefined) {
      url = url + "&last_id=" + lastId;
    }

    const resp = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "X-CLIENT-ID": idToken,
      },
    });
    return resp;
  },
};
export default audit;
