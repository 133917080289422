import React, { Component } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Form,
  Select,
  Image,
  message,
} from "antd";
import COLOR from "../../const/colors";
import { withTranslation } from "react-i18next";
import { asset } from "../../common/assets";
import { currentToken } from "../../redux/actions/tokenActions";
import { updatePreferences } from "../../redux/actions/preferencesActions";
import { getAccounts } from "../../redux/actions/bankingActions";
import { connect } from "react-redux";
const { Text, Title } = Typography;
const { Option } = Select;
const localCurrencyList = ["GBP", "EUR", "USD"];
class Preferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listCurrency: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.loadAssets();
  }
  loadAssets() {
    let arrayAssets = [];
    for (let value of localCurrencyList) {
      let assetData = {};

      let currency = asset.assets.find((element) => value === element.value);
      if (currency !== undefined) {
        assetData.icon = currency.icon;
        assetData.name = currency.name;
        assetData.value = currency.value;
      }
      arrayAssets.push(assetData);
    }

    this.setState({
      listCurrency: arrayAssets,
    });
  }
  async updatePreferences() {
    try {
      this.setState({ loading: true });
      let body = {
        local_currency: this.props.localCurrency,
      };
      await this.props.updatePreferences(
        this.props.clientId,
        this.props.accessToken,
        body
      );
      let response = this.props.updatedPreferences;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        message.success(this.props.t("preferences.messages.success"));
        this.setState({ loading: false });
        this.props.getPreferences();
        this.getAccounts();
      } else if (response.status === 400) {
        this.setState({ loading: false });
        if (response.data.error_details.payload_error !== undefined) {
          let messageError = response.data.error_details.payload_error;
          message.error(
            this.props.t("preferences.messages." + messageError),
            10
          );
        }
        if (response.data.error_details.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;
          message.error(
            this.props.t("preferences.messages." + messageError),
            10
          );
        }
      } else {
        this.setState({ loading: false });
        message.error(this.props.t("preferences.messages.errorServer"), 10);
      }
    } catch (error) {
      this.setState({ loading: false });
      message.error(this.props.t("preferences.messages.errorServer"), 10);
    }
  }

  async getAccounts() {
    try {
      await this.props.getAccounts(this.props.clientId, this.props.accessToken);
      let response = this.props.banking;
      let newAccessToken = "";
      if (response.headers !== undefined) {
        newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    let currentCurrency =
      this.props.responsePreferences.data?.data?.preferences.local_currency;

    return (
      <div>
        <Row gutter={[48, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
          >
            <Title level={4} style={{ color: COLOR.PRIMARY_TEXT }}>
              {this.props.t("preferences.title")}
            </Title>
            <Divider
              style={{
                background: COLOR.PRIMARY_TEXT,
                margin: "10px 0 30px 0",
              }}
            ></Divider>

            <Row
              gutter={[8, { xs: 8, sm: 16, md: 8, lg: 8, xl: 8 }]}
              align="middle"
            >
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 18, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 10, offset: 0 }}
              >
                <Form
                  name="preferences"
                  labelAlign="left"
                  labelCol={{ span: 24, offset: 0 }}
                  wrapperCol={{ span: 24, offset: 0 }}
                >
                  <Form.Item
                    label={
                      <Text style={{ color: "#fff", fontSize: 16 }}>
                        {this.props.t("preferences.currency")}
                      </Text>
                    }
                  >
                    <div
                      id="select-option-setting"
                      className="ant-select-selector ant-select"
                      style={{ width: "100%" }}
                    >
                      <Form.Item
                        noStyle
                        name="currency"
                        valuePropName="defaultValue"
                      >
                        <Select
                          showSearch
                          filterOption={(input, option) => {
                            let value = option.key;
                            return (
                              value
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                          style={{ width: "100%" }}
                          size="large"
                          value={this.props.localCurrency}
                          onChange={(value) =>
                            this.props.handleLocalCurrency(value)
                          }
                          loading={this.props.localCurrency === ""}
                        >
                          {this.state.listCurrency.map((item, i) => {
                            return (
                              <Option
                                value={item.value}
                                key={item.value + "_" + item.name}
                              >
                                <div
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Image
                                    style={{ paddingRight: 5 }}
                                    src={item.icon}
                                    width={30}
                                    preview={false}
                                  />
                                  {item.name + " (" + item.value + ")"}
                                </div>
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Form.Item>
                  {/* <Form.Item
                    label={
                      <Text style={{ color: "#fff", fontSize: 16 }}>
                        {this.props.t("preferences.timeZone")}
                      </Text>
                    }
                  >
                    <div
                      id="select-option-setting"
                      className="ant-select-selector ant-select"
                      style={{ width: "100%" }}
                    >
                      <Form.Item noStyle name="currency">
                        <Select style={{ width: "100%" }} size="large">
                          <Option value="value">Value</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Form.Item> */}
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      loading={this.state.loading}
                      disabled={
                        this.props.activeSave ||
                        this.props.localCurrency === currentCurrency
                      }
                      onClick={() => this.updatePreferences()}
                    >
                      {this.props.t("preferences.save")}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 6, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 14, offset: 0 }}
              ></Col>
            </Row>
            <Divider style={{ background: COLOR.PRIMARY }}></Divider>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    accessToken: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
    updatedPreferences: state.preferences.resultUpdate,
    responsePreferences: state.preferences.resultPreferences,
    banking: state.banking.accounts,
  };
};

const mapDispatchToProps = {
  updatePreferences,
  currentToken,
  getAccounts,
};
const PreferencesTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Preferences)
);

export default PreferencesTranslated;
