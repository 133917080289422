import btc from "../images/btc-icon.png";
import eth from "../images/eth-icon.png";
import usdc from "../images/usdcoin.png";
import gbp from "../images/gbp-icon.png";
import usdt from "../images/usdt-icon.png";
import eur from "../images/eur-icon.png";
import usd from "../images/icon-dollar.png";
import oneGbp from "../images/1gbp-icon.png"
export const asset = {
  assets: [
    { value: "BTC", name: "Bitcoin", icon: btc, symbol: "BTC", color:"#f7931a",type:"CRYPTO" },
    { value: "ETH", name: "Ethereum", icon: eth, symbol: "ETH",color:"#627eea",type:"CRYPTO" },
    { value: "USDC", name: "USD Coin", icon: usdc, symbol: "USDC",color:"#2673c7" ,type:"CRYPTO"},
    { value: "USDT", name: "Tether", icon: usdt, symbol: "USDT" ,color:"#50af95",type:"CRYPTO"},
    { value: "GBP", name: "Pound sterling", icon: gbp, symbol: "£" ,color:"#3e50b4",type:"FIAT"},
    { value: "EUR", name: "Euro", icon: eur, symbol: "€",color:"#0f8ff8",type:"FIAT" },
    { value: "USD", name: "US Dollar", icon: usd, symbol: "$",color:"#50af95",type:"FIAT" },
    { value: "1GBP", name: "1GBP", icon: oneGbp, symbol: "1GBP",color:"#835bc0",type:"CRYPTO" }
  ],
};
