import React, { Component } from "react";
import {
  Button,
  Space,
  Typography,
  Row,
  Col,
  Card,
  Divider,
  Form,
  Select,
  Image,
  Avatar,
} from "antd";
import "./fx.css";
import { withTranslation } from "react-i18next";
import COLOR from "../../const/colors";
import { RightOutlined, LoadingOutlined } from "@ant-design/icons";
import SwapVertical from "@2fd/ant-design-icons/lib/SwapVertical";
import { asset } from "../../common/assets";
import { getAccounts } from "../../redux/actions/bankingActions";
import { connect } from "react-redux";
import { isMobileOnly } from "react-device-detect";
import NumberFormat from "react-number-format";
const { Option } = Select;
const { Text } = Typography;
const formRef = React.createRef();
const inputNumber = React.createRef();

class Fx extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      assetActiveTo: "",
      assetActiveFrom: "",
      assetSelected: "",
      activeInput: "",
      listAssets: [],
      accounts: [],
      accountSelected: "",
      amountTrade: 0,
      assetActiveBalance: "",
      labelActiveTo: "",
      time: "",
      seconds: 30,
    };
    this.timer = 0;
    this.countDown = this.countDown.bind(this);
  }
  componentDidMount() {
    if (this.props.source === "trade") {
      document.getElementById("resize-number-trade").focus();
    } else {
      document.getElementById("resize-number-header").focus();
    }
    this.filterCurrencyAvailable();
    this._isMounted = true;
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  secondsToTime(secs) {
    let divisor_for_minutes = secs % (60 * 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let timeSeconds = seconds;

    return timeSeconds;
  }
  countDown() {
    if (this._isMounted) {
      // Remove one second, set state so a re-render happens.
      let seconds = this.state.seconds - 1;
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
      });

      // Check if we're at zero.
      if (seconds === 0) {
        clearInterval(this.timer);
        this.setState({ seconds: 30 }, async () => {
          let timeLeftVar = this.secondsToTime(this.state.seconds);
          this.setState({ time: timeLeftVar });
          if (this.state.seconds > 0) {
            await this.props.getPairsSupported(
              this.props.assetActiveFrom.value,
              this.props.assetActiveTo.value
            );
            this.props.getRate(
              this.props.assetActiveFrom.value,
              this.props.assetActiveTo.value,
              true
            );
            this.timer = setInterval(this.countDown, 1000);
          }
        });
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.amountTrade !== 0) {
      this.adjustFontSize();
    }
    if (prevProps.banking !== this.props.banking) {
      this.props.handleAssetFrom(
        this.props.assetActiveFrom,
        this.state.listAssets
      );
    }
  }
  async handleAssetFromTrade(value) {
    let id = value.split("_")[1];
    let account = this.findInfoAccount(id);
    formRef.current.resetFields();
    await this.props.handleAssetFrom(account, this.state.listAssets);
    let filterSelected = this.state.listAssets.filter(
      (element) => element.id !== id
    );
    if (id === this.props.assetActiveTo.id) {
      let findTo = filterSelected.findIndex(
        (element) => element.value !== account.value
      );
      await this.props.handleAssetTo(
        filterSelected[findTo],
        this.state.listAssets
      );
    }
    await this.props.resetMinExchange();
    await this.props.getPairsSupported(account.value, this.props.assetActiveTo.value);
    this.props.getRate(account.value, this.props.assetActiveTo.value, true);
  }
  async handleAssetToTrade(value) {
    let id = value.split("_")[1];
    let account = this.findInfoAccount(id);
    formRef.current.resetFields();
    this.props.handleAssetTo(account, this.state.listAssets);
    let filterSelected = this.state.listAssets.filter(
      (element) => element.id !== id
    );
    if (id === this.props.assetActiveFrom.id) {
      let findFrom = filterSelected.findIndex(
        (element) => element.value !== account.value
      );
      this.props.handleAssetFrom(
        filterSelected[findFrom],
        this.state.listAssets
      );
    }
    await this.props.resetMinExchange();
    await this.props.getPairsSupported(
      this.props.assetActiveFrom.value,
      account.value
    );
    this.props.getRate(this.props.assetActiveFrom.value, account.value, true);
  }
  findInfoAccount(account) {
    let accountsInfo = this.props.banking.data.data.accounts;
    let detail = this.props.banking.data.data.native_balances.currencies;
    let findAccount = accountsInfo.find((element) => {
      return element._id === account;
    });
    let objAccount = {};
    objAccount.name = findAccount.currency_name;
    objAccount.value = findAccount.currency;
    objAccount.type = findAccount.type;
    objAccount.id = findAccount._id;
    let findLengthAccounts = detail.find(
      (element) => element.currency === findAccount.currency
    );
    objAccount.alias =
      findLengthAccounts.accounts !== undefined &&
      findLengthAccounts.accounts?.length > 1
        ? findAccount.alias
        : null;
    objAccount.alias =
      findAccount.alias !== undefined ? findAccount.alias : null;
    objAccount.status = findAccount.status;
    return objAccount;
  }
  changeAssets = async () => {
    formRef.current.resetFields();
    this.props.handleAssetFrom(this.props.assetActiveTo, this.state.listAssets);
    this.props.handleAssetTo(this.props.assetActiveFrom, this.state.listAssets);
    await this.props.resetMinExchange();
    await this.props.getPairsSupported(
      this.props.assetActiveFrom.value,
      this.props.assetActiveTo.value
    );
    this.props.getRate(
      this.props.assetActiveFrom.value,
      this.props.assetActiveTo.value,
      true
    );
  };

  filterCurrencyAvailable() {
    let accountsInfo = this.props.banking.data.data.accounts;
    let detail = this.props.banking.data.data.native_balances.currencies;
    let arrayCurrencies = [];
    for (let objCurrency of accountsInfo) {
      let objCurrencies = {};
      let data = asset.assets.find(
        (element) => element.value === objCurrency.currency
      );
      objCurrencies.icon = data.icon;
      objCurrencies.symbol = data.symbol;
      objCurrencies.name = data.name;
      objCurrencies.value = objCurrency.currency;
      objCurrencies.type = objCurrency.type;
      objCurrencies.id = objCurrency._id;
      let findLengthAccounts = detail.find(
        (element) => element.currency === objCurrency.currency
      );
      objCurrencies.alias =
        findLengthAccounts.accounts !== undefined &&
        findLengthAccounts.accounts?.length > 1
          ? objCurrency.alias
          : null;
      arrayCurrencies.push(objCurrencies);
    }
    let fiat = arrayCurrencies.filter((element) => element.type === "FIAT");
    let findTo = fiat.findIndex((element) => element.value !== fiat[0].value);

    this.setState(
      {
        listAssets: fiat,
      },

      async () => {
        this.props.handleAssetFrom(fiat[0], this.state.listAssets);
        this.props.getLimits(fiat[0].value);
        this.props.handleAssetTo(fiat[findTo], this.state.listAssets);
        await this.props.resetMinExchange();
        await this.props.getPairsSupported(fiat[0].value, fiat[findTo].value);
        await this.props.getRate(fiat[0].value, fiat[findTo].value, true);
      }
    );
  }

  adjustFontSize = () => {
    if (this.props.source === "trade") {
      if (this.props.amountTrade.length !== undefined) {
        if (isMobileOnly) {
          const fontSize = 10 - this.props.amountTrade.length * 0.2;
          document.getElementById("resize-number-trade").style.fontSize =
            fontSize + "vw";
        } else {
          const fontSize = 2.8 - this.props.amountTrade.length * 0.06;
          document.getElementById("resize-number-trade").style.fontSize =
            fontSize + "vw";
        }
      }
    } else if (this.props.source === "header") {
      if (this.props.amountTrade.length !== undefined) {
        const fontSize = 2.8 - this.props.amountTrade.length * 0.06;
        document.getElementById("resize-number-header").style.fontSize =
          fontSize + "vw";
      }
    }
  };
  disabledButton() {
    let balance = parseFloat(this.props.assetActiveBalance);
    if (
      this.props.amountTradeFloat === 0 ||
      this.props.amountTradeFloat === undefined ||
      balance < this.props.amountTradeFloat ||
      this.props.amountTradeFloat < this.props.recommendedMinExchange ||
      this.props.assetActiveFrom.status !== "ACTIVE" ||
      this.props.assetActiveTo.status !== "ACTIVE" ||
      this.props.pairsSupported === false
    ) {
      return true;
    } else {
      return false;
    }
  }
  fieldsValidator = (rule, value, callback) => {
    let balance = parseFloat(this.props.assetActiveBalance);
    let currencyBalance = this.props.assetActiveFrom.value;
    let minExchange = parseFloat(this.props.recommendedMinExchange);
    let maxTrade = parseFloat(this.props.maxExchange);
    let messageBalance = this.props.t("trade.message.insufficientBalance", {
      key: currencyBalance,
    });
    let messageMin = this.props.t("limits.messages.errorMinimunTrade");
    let messageMax = this.props.t("limits.messages.errorMax");
    var amount = this.props.amountTradeFloat;
    if (value !== "") {
      if (amount > balance) {
        return Promise.reject(new Error(messageBalance));
      }
      if (amount < minExchange) {
        return Promise.reject(new Error(messageMin));
      }
      if (maxTrade !== -1) {
        if (amount > maxTrade) {
          return Promise.reject(new Error(messageMax));
        }
      }

      return Promise.resolve();
    }
  };
  fastTrade(value) {
    let values = {};
    let amount = "";
    if (value === "25") {
      amount = this.props.percentageForTrade.twentyFive.toFixed(2);
    }
    if (value === "50") {
      amount = this.props.percentageForTrade.fifty.toFixed(2);
    }
    if (value === "100") {
      amount = this.props.percentageForTrade.all.toFixed(2);
    }

    values = {
      floatValue: parseFloat(amount),
      formattedValue: this.props.assetActiveFrom.symbol + amount.toString(),
      value: amount.toString(),
    };
    inputNumber.current.props.onValueChange(values);

    formRef.current.setFieldsValue({
      amountTrade: this.props.assetActiveFrom.symbol + amount.toString(),
    });
  }
  findVolumes() {
    let baseCurrency = this.props.assetActiveFrom;
    //let quoteCurrency = this.props.assetActiveTo;
    let accounts = this.props.banking.data.data.accounts;
    let findVolumes = "";
    let findAccount = accounts.find((element) => {
      return element.currency === baseCurrency.value;
    });
    if (findAccount !== undefined) {
      // findVolumes = findAccount.volumes.find((element) => {
      //   return (
      //     element.type === "EXCHANGE_OUT" &&
      //     element.quote_currency === quoteCurrency.value
      //   );
      // });
      findVolumes = findAccount.volumes.find((element) => {
        return element.type === "EXCHANGE_OUT";
      });
    }
    return findVolumes;
  }
  render() {
    let limitDaily = "";
    let limitMonthly = "";
    if (this.props.currentLimit !== null) {
      limitDaily = this.props.currentLimit.max_daily_exchange;
      limitMonthly = this.props.currentLimit.max_monthly_exchange;
    }
    let limitAmount = "";
    let balance = this.props.assetActiveBalance;
    let minExchange = this.props.recommendedMinExchange;
    let maxTrade = this.props.maxExchange;
    if (maxTrade !== "-1") {
      if (balance <= maxTrade) {
        limitAmount = balance;
      } else {
        limitAmount = maxTrade;
      }
    } else {
      let volumes = this.findVolumes() === undefined ? "0" : this.findVolumes();
      if (balance > limitDaily && limitDaily !== "-1") {
        limitAmount = limitDaily - volumes.current_day_volume;
      } else if (balance > limitMonthly && limitMonthly !== "-1") {
        limitAmount = limitMonthly - volumes.current_month_volume;
      } else {
        limitAmount = balance;
      }
    }
    return (
      <div>
        <Row
          justify="center"
          gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
        >
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 24, offset: 0 }}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Row
              justify="center"
              align="middle"
              gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
            >
              <Form
                name="trade"
                ref={formRef}
                autoComplete="off"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Form.Item
                  name="amountTrade"
                  style={{
                    top: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    marginBottom: 0,
                  }}
                  rules={[{ validator: this.fieldsValidator }]}
                >
                  <NumberFormat
                    allowNegative={false}
                    thousandSeparator={true}
                    prefix={this.props.assetActiveFrom.symbol}
                    className="input-amount"
                    id={
                      this.props.source === "trade"
                        ? "resize-number-trade"
                        : "resize-number-header"
                    }
                    placeholder={
                      this.props.assetActiveFrom.symbol +
                      this.props.amountTradeFloat
                    }
                    decimalScale={2}
                    style={{
                      fontSize: isMobileOnly ? "13vw" : "2.75vw",
                    }}
                    ref={inputNumber}
                    onValueChange={(values) =>
                      this.props.handleAmountTrade(values)
                    }
                  />
                </Form.Item>
              </Form>
            </Row>
          </Col>
        </Row>

        <Row
          justify="center"
          gutter={[48, { xs: 8, sm: 16, md: 8, lg: 16, xl: 16 }]}
        >
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
          >
            <Space direction="vertical" style={{ width: "100%" }} size="middle">
              <Row justify="center">
                {(this.props.assetActiveFrom.status === "ACTIVE" ||
                  this.props.assetActiveTo.status === "ACTIVE") && (
                  <>
                    <Space
                      align="center"
                      direction="horizontal"
                      style={{ width: "100%", justifyContent: "center" }}
                      size="middle"
                    >
                      <Button
                        className="disabledStyle"
                        disabled={
                          this.props.assetActiveBalance === "0" ||
                          this.props.percentageForTrade.twentyFive < minExchange
                        }
                        style={{ borderColor: COLOR.SECONDARY, marginTop: 16 }}
                        onClick={() => this.fastTrade("25")}
                      >
                        25%
                      </Button>
                      <Button
                        className="disabledStyle"
                        disabled={
                          this.props.assetActiveBalance === "0" ||
                          this.props.percentageForTrade.fifty < minExchange
                        }
                        style={{ borderColor: COLOR.SECONDARY, marginTop: 16 }}
                        onClick={() => this.fastTrade("50")}
                      >
                        50%
                      </Button>
                      <Button
                        className="disabledStyle"
                        disabled={
                          this.props.assetActiveBalance === "0" ||
                          this.props.percentageForTrade.all < minExchange
                        }
                        style={{ borderColor: COLOR.SECONDARY, marginTop: 16 }}
                        onClick={() => this.fastTrade("100")}
                      >
                        100%
                      </Button>
                    </Space>
                  </>
                )}
              </Row>
              <Row justify="center">
                {(this.props.assetActiveFrom.status === "ACTIVE" ||
                  this.props.assetActiveTo.status === "ACTIVE") && (
                  <>
                    <Text style={{ color: "#898996", marginTop: 16 }}>
                      {parseFloat(balance) > 0 &&
                        parseFloat(balance) >= parseFloat(minExchange) && (
                          <div>
                            {this.props.t("fx.upTo") + " "}
                            <NumberFormat
                              thousandSeparator={true}
                              displayType="text"
                              prefix={this.props.assetActiveFrom.symbol + " "}
                              decimalScale={2}
                              value={parseFloat(limitAmount)}
                            />
                          </div>
                        )}
                      {(parseFloat(balance) === 0 ||
                        parseFloat(balance) < parseFloat(minExchange)) &&
                        this.props.t("fx.notBalance", {
                          key: this.props.assetActiveFrom.name,
                        })}
                    </Text>
                    {this.props.pairsSupported === false && (
                      <Text
                        style={{
                          color: COLOR.ERROR,
                          textAlign: "center",
                          fontSize: 14,
                        }}
                      >
                        {this.props.t("trade.message.pairSupported", {
                          base: this.props.assetActiveFrom.name,
                          quote: this.props.assetActiveTo.name,
                        })}
                      </Text>
                    )}
                    {this.props.showMessageLimit && (
                      <Text
                        style={{
                          color: COLOR.ERROR,
                          textAlign: "center",
                          fontSize: 14,
                        }}
                      >
                        {this.props.messageLimit}
                      </Text>
                    )}
                  </>
                )}
                {this.props.assetActiveFrom.status !== "ACTIVE" && (
                  <Text
                    style={{
                      color: COLOR.ERROR,
                      textAlign: "center",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("wallet.messageStatusAccount", {
                      currency: this.props.assetActiveFrom.value,
                      status: this.props.t(
                        "wallet.status." + this.props.assetActiveFrom.status
                      ),
                    })}
                  </Text>
                )}
                {this.props.assetActiveTo.status !== "ACTIVE" && (
                  <Text
                    style={{
                      color: COLOR.ERROR,
                      textAlign: "center",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("wallet.messageStatusAccount", {
                      currency: this.props.assetActiveTo.value,
                      status: this.props.t(
                        "wallet.status." + this.props.assetActiveTo.status
                      ),
                    })}
                  </Text>
                )}
              </Row>
              <Row justify="center">
                <Card className="card-form" bodyStyle={{ padding: 0 }}>
                  <Form
                    labelAlign="left"
                    labelCol={{
                      xs: { span: 24 },
                      sm: { span: 24 },
                      md: { span: 8 },
                      lg: { span: 6 },
                      xl: { span: 6 },
                      xxl: { span: 6 },
                    }}
                    wrapperCol={{
                      xs: { span: 24 },
                      sm: { span: 24 },
                      md: { span: 16 },
                      lg: { span: 18 },
                      xl: { span: 18 },
                      xxl: { span: 18 },
                    }}
                    layout="horizontal"
                  >
                    <Form.Item
                      className="item-select-dark"
                      name="sell"
                      label={
                        <div
                          style={{
                            display: "flex",
                            marginTop: "5px",
                          }}
                        >
                          <Text className="label-dark">
                            {this.props.t("trade.from")}
                          </Text>
                        </div>
                      }
                    >
                      <div
                        style={{
                          display: "inline-flex",
                          float: "left",
                          alignItems: "center",
                          width:
                            this.props.source !== "header" ? "110%" : "105%",
                        }}
                      >
                        <Image
                          src={this.props.assetActiveFrom.icon}
                          width={30}
                          preview={false}
                        />
                        <div
                          id="select-dark-sell"
                          className="ant-select-selector ant-select"
                        >
                          <Select
                            showSearch
                            filterOption={(input, option) =>
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            suffixIcon={
                              <RightOutlined style={{ color: "#ccc" }} />
                            }
                            size="large"
                            bordered={false}
                            style={{ color: "#ccc" }}
                            value={this.props.labelActiveFrom}
                            onChange={(value) =>
                              this.handleAssetFromTrade(value)
                            }
                            optionLabelProp="label"
                          >
                            {this.props.listAssetFrom.length !== 0 &&
                              this.props.listAssetFrom.map((item, i) => {
                                return (
                                  <Option
                                    key={item.id}
                                    value={
                                      item.alias !== null
                                        ? item.value +
                                          "_" +
                                          item.id +
                                          "_" +
                                          item.alias
                                        : item.value +
                                          "_" +
                                          item.id +
                                          "_" +
                                          item.name
                                    }
                                  >
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Image
                                        style={{ paddingRight: 5 }}
                                        src={item.icon}
                                        width={30}
                                        preview={false}
                                      />
                                      {item.alias !== null
                                        ? item.alias
                                        : item.name}
                                    </div>
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                    </Form.Item>
                    <div
                      id="divider-trade"
                      className="ant-divider-inner-text ant-divider-horizontal ant-divider-with-text"
                    >
                      <Divider
                        orientation="left"
                        style={{
                          background: COLOR.PRIMARY,
                          borderTopColor: COLOR.PRIMARY_TEXT,
                        }}
                      >
                        <Avatar
                          className="circle-icon-trade"
                          size={40}
                          src={
                            <SwapVertical
                              onClick={() => this.changeAssets()}
                              style={{
                                marginRight: 0,
                              }}
                            />
                          }
                        />
                      </Divider>
                    </div>

                    <Form.Item
                      className="item-select-dark"
                      label={
                        <div
                          style={{
                            display: "flex",
                            marginTop: "5px",
                          }}
                        >
                          <Text className="label-dark">
                            {this.props.t("trade.to")}
                          </Text>
                        </div>
                      }
                    >
                      <div
                        style={{
                          display: "inline-flex",
                          float: "left",
                          alignItems: "center",
                          width:
                            this.props.source !== "header" ? "110%" : "105%",
                        }}
                      >
                        <Image
                          src={this.props.assetActiveTo.icon}
                          width={30}
                          preview={false}
                        />
                        <div
                          id="select-dark-sell"
                          className="ant-select-selector ant-select"
                        >
                          <Select
                            showSearch
                            filterOption={(input, option) =>
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            suffixIcon={
                              <RightOutlined style={{ color: "#ccc" }} />
                            }
                            size="large"
                            bordered={false}
                            style={{ color: "#ccc" }}
                            value={this.props.labelActiveTo}
                            optionLabelProp="label"
                            onChange={(value) => this.handleAssetToTrade(value)}
                          >
                            {this.props.listAssetTo.length !== 0 &&
                              this.props.listAssetTo.map((item, i) => {
                                return (
                                  <Option
                                    key={item.id}
                                    value={
                                      item.alias !== null
                                        ? item.value +
                                          "_" +
                                          item.id +
                                          "_" +
                                          item.alias
                                        : item.value +
                                          "_" +
                                          item.id +
                                          "_" +
                                          item.name
                                    }
                                  >
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Image
                                        style={{ paddingRight: 5 }}
                                        src={item.icon}
                                        width={30}
                                        preview={false}
                                      />
                                      {item.alias !== null
                                        ? item.alias
                                        : item.name}
                                    </div>
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                    </Form.Item>
                  </Form>
                </Card>
              </Row>
              <Row justify="center">
                <Button
                  block
                  type="primary"
                  style={{
                    height: "60px",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                  disabled={this.disabledButton()}
                  loading={this.props.loading}
                  onClick={() => this.props.next("FX")}
                >
                  {this.props.t("fx.buttonName")}{" "}
                  {this.props.assetActiveFrom.name}
                </Button>
              </Row>
              <Row justify="space-between" style={{ marginTop: "1rem" }}>
                <Text
                  style={{
                    color: "#898996",
                    fontSize: 14,
                  }}
                >
                  {this.props.t("trade.minExchange")}
                </Text>
                {this.props.loadingMinExchange && (
                  <LoadingOutlined
                    style={{ fontSize: 14, color: "#ccc" }}
                    spin
                  />
                )}
                {!this.props.loadingMinExchange && (
                  <Text
                    style={{
                      color: "#898996",
                      fontSize: 14,
                    }}
                  >
                    {this.props.assetActiveFrom.type === "CRYPTO" ? (
                      <NumberFormat
                        thousandSeparator={true}
                        displayType="text"
                        suffix={" " + this.props.assetActiveFrom.symbol}
                        decimalScale={8}
                        value={this.props.recommendedMinExchange}
                      />
                    ) : (
                      <NumberFormat
                        thousandSeparator={true}
                        displayType="text"
                        prefix={this.props.assetActiveFrom.symbol + " "}
                        decimalScale={2}
                        value={this.props.recommendedMinExchange}
                      />
                    )}
                  </Text>
                )}
              </Row>
              {maxTrade !== undefined && maxTrade !== "-1" && (
                <Row justify="space-between">
                  <Text
                    style={{
                      color: "#898996",
                      fontSize: 14,
                    }}
                  >
                    {this.props.t("trade.maxExchange")}
                  </Text>
                  {this.props.loadingMinExchange && (
                    <LoadingOutlined
                      style={{ fontSize: 14, color: "#ccc" }}
                      spin
                    />
                  )}
                  {!this.props.loadingMinExchange && (
                    <Text
                      style={{
                        color: "#898996",
                        fontSize: 14,
                      }}
                    >
                      <NumberFormat
                        thousandSeparator={true}
                        displayType="text"
                        prefix={this.props.assetActiveFrom.symbol + " "}
                        decimalScale={
                          this.props.assetActiveFrom.type === "CRYPTO" ? 8 : 2
                        }
                        value={maxTrade}
                      />
                    </Text>
                  )}
                </Row>
              )}
              <Row>
                <Col span={12} style={{ textAlign: "left" }}>
                  <Text
                    style={{
                      color: "#898996",
                      fontSize: 14,
                      textAlign: "left",
                    }}
                  >
                    {this.props.assetActiveFrom.value !== undefined
                      ? this.props.assetActiveFrom.value
                      : ""}{" "}
                    balance
                  </Text>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Text style={{ color: "#898996", fontSize: 14 }}>
                    {this.props.assetActiveFrom.type === "CRYPTO" ? (
                      <NumberFormat
                        thousandSeparator={true}
                        displayType="text"
                        suffix={" " + this.props.assetActiveFrom.symbol}
                        decimalScale={8}
                        value={this.props.assetActiveBalance}
                      />
                    ) : (
                      <NumberFormat
                        thousandSeparator={true}
                        displayType="text"
                        prefix={this.props.assetActiveFrom.symbol + " "}
                        decimalScale={2}
                        value={this.props.assetActiveBalance}
                      />
                    )}{" "}
                    {this.props.assetActiveFrom.value !==
                      this.props.nativeCurrency && (
                      <NumberFormat
                        thousandSeparator={true}
                        displayType="text"
                        prefix={"≈ " + this.props.nativeSymbol + " "}
                        decimalScale={2}
                        value={parseFloat(this.props.nativeBalance)}
                      />
                    )}
                  </Text>
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    banking: state.banking.accounts,
    asset: state.siderNavigate.asset,
    currentLimit: state.exchange.currentLimits,
  };
};

const mapDispatchToProps = { getAccounts };
const FxTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Fx)
);

export default FxTranslated;
