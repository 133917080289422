import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Space,
  Typography,
  Progress,
  Image,
  Menu,
  Skeleton,
  Divider,
} from "antd";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { Redirect } from "react-router-dom";
import COLOR from "../../const/colors";
import { asset } from "../../common/assets";
import ChartAccounts from "../../common/chartsAccounts/chartAccounts";
import { withTranslation } from "react-i18next";
import { selectedAsset } from "../../redux/actions/navigateActions";
import { currentToken } from "../../redux/actions/tokenActions";
import { getTimeline } from "../../redux/actions/timeline";
import { getAccounts } from "../../redux/actions/bankingActions";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import moment from "moment";
import "./accounts.css";
import RecentsTransactions from "./recentsTransactions";
import CAN from "../../casl/can";
const { Title, Text } = Typography;
class Accounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "1",
      asset: null,
      loading: false,
      dataCharts: [],
      loadingCharts: false,
      loadingBalances: false,
      differenceBalances: "",
      percentageBalance: "",
    };
  }
  componentDidMount() {
    this.getTimelineBalance("1d");
    this.getAccounts();
    this.props.selectedAsset("");
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.respAllTransactions !== prevProps.respAllTransactions ||
      this.props.responsePreferences !== prevProps.responsePreferences
    ) {
      if (
        this.props.respAllTransactions.data !== undefined ||
        this.props.responsePreferences !== undefined
      ) {
        this.getTimelineBalance("1d");
        this.setState({ current: "1" });
      }
    }
  }
  async getAccounts() {
    try {
      this.setState({ loadingBalances: true });
      await this.props.getAccounts(
        this.props.token.clientId,
        this.props.token.current
      );
      let response = this.props.accounts;
      let newAccessToken = "";
      if (response.headers !== undefined) {
        newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        setTimeout(() => {
          this.setState({
            loadingBalances: false,
          });
        }, 400);
      } else if (
        response.status === 404 ||
        response.status === 401 ||
        response.status === 422 ||
        response.status === 403
      ) {
        this.setState({
          loadingBalances: false,
        });
      }
    } catch (error) {}
  }
  customizeDataToRender(dataAccounts, accounts) {
    let data = asset.assets;
    let infoAccounts = dataAccounts.currencies;
    let array = [];

    if (infoAccounts !== undefined) {
      for (let assetAccounts of infoAccounts) {
        let objAsset = {};
        let filterAccounts = data.find(
          (element) => assetAccounts.currency === element.value
        );

        if (filterAccounts !== undefined) {
          objAsset.id = assetAccounts.currency;
          objAsset.key = assetAccounts.currency;
          objAsset.asset = filterAccounts.name;
          objAsset.icon = filterAccounts.icon;
          objAsset.symbol = filterAccounts.symbol;
          objAsset.type = assetAccounts.type;
          objAsset.color = filterAccounts.color;
          objAsset.balanceLocalCurrency = assetAccounts.native_balance;
          objAsset.balance = assetAccounts.balance;
          objAsset.allocation = assetAccounts.allocation;
          let filterAccountsActive;
          if (assetAccounts.accounts) {
            let activesAccount = [];
            for (let account of assetAccounts.accounts) {
              for (let acc of accounts) {
                if (acc._id === account._id && acc.status === "ACTIVE") {
                  activesAccount.push(account);
                }
              }
            }
            filterAccountsActive = activesAccount;
          } else {
            let activesAccount = [];
            for (let acc of accounts) {
              if (
                acc.currency === assetAccounts.currency &&
                acc.status === "ACTIVE"
              ) {
                activesAccount.push(acc);
              }
            }
            filterAccountsActive = activesAccount;
          }

          if (filterAccountsActive) {
            objAsset.accounts = filterAccountsActive;
          }
        }
        array.push(objAsset);
      }
    }
    //  console.log(array);
    return array;
  }
  renderNativeBalances(data) {
    let nativeBalances = {};
    if (data) {
      nativeBalances = {
        nativeCurrency: data.native_currency,
        nativeSymbol: data.native_symbol,
        total: data.total,
      };
    } else {
      nativeBalances = {
        nativeCurrency: "USD",
        nativeSymbol: "$",
        total: 0,
      };
    }
    return nativeBalances;
  }

  handleClick = (e) => {
    this.getTimelineBalance(e.key);
    this.setState({
      current: e.key,
    });
  };
  selectRowAsset = (record, item) => {
    let info = {};
    if (item !== undefined) {
      info.id = item._id;
      info.multiple = record.accounts.length > 1 ? true : false;
    }
    info.key = record.key;
    //  console.log(info);
    this.props.selectedAsset(info);
    this.setState({ asset: true });
  };

  async getTimelineBalance(period) {
    try {
      this.setState({ loadingCharts: true });
      await this.props.getTimeline(
        this.props.token.clientId,
        this.props.token.current,
        period
      );
      let response = this.props.respTimeline;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        let data = response.data.data;
        let array = [];
        let compareBalance = "";
        let comparedPercentage = "";

        for (let obj of data.timeline) {
          let newObj = {};
          let timestamp = new Date(obj.x + " UTC");
          let date = moment(timestamp).format("YYYY/MM/DD HH:mm");

          newObj.x = date;
          newObj.y = obj.y;
          array.push(newObj);
        }
        if (array.length > 0) {
          compareBalance = array[array.length - 1].y - array[0].y;
          comparedPercentage = (compareBalance * 100) / array[0].y;
        }
        this.setState({
          dataCharts: array,
          differenceBalances: compareBalance,
          percentageBalance: comparedPercentage,
          loadingCharts: false,
        });
      } else if (response.status === 404) {
        this.setState({
          loadingCharts: false,
          dataCharts: [],
        });
      } else if (response.status === 403) {
        this.setState({ loadingCharts: false, dataCharts: [] });
      } else {
        this.setState({ loadingCharts: false, dataCharts: [] });
      }
    } catch (error) {
      this.setState({ loadingCharts: false, loadingBalances: false });
    }
  }

  render() {
    let response = this.props.accounts;
    let tableDataFormat = [];
    let nativeBalances = {
      nativeCurrency: "USD",
      nativeSymbol: "$",
      total: 0,
    };
    if (response) {
      let data = response.data?.data;

      if (data) {
        tableDataFormat = this.customizeDataToRender(
          data.native_balances,
          data.accounts
        );
        nativeBalances = this.renderNativeBalances(
          data.native_balances,
          data.accounts
        );
      }
    }
    const columns = [
      {
        title: (
          <Text
            style={{
              color: COLOR.PRIMARY_TEXT,
              fontSize: 16,
            }}
          >
            {this.props.t("balance.asset")}
          </Text>
        ),
        dataIndex: "asset",
        key: "asset",
        sorter: (a, b) => a.asset.localeCompare(b.asset),
        render: (record, originNode) => (
          <>
            <Space align="center">
              <Image
                style={{ alignItems: "center", display: "inline-flex" }}
                preview={false}
                width={30}
                src={originNode.icon}
              />
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 18,
                }}
              >
                {originNode.asset}
              </Text>
            </Space>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.PRIMARY_TEXT,
              fontSize: 16,
            }}
          >
            {this.props.t("balance.balance")}
          </Text>
        ),
        dataIndex: "balance",
        key: "balance",
        render: (record, originNode) => (
          <>
            <Space align="center">
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 18,
                }}
              >
                <NumberFormat
                  thousandSeparator={true}
                  displayType="text"
                  prefix={nativeBalances.nativeSymbol + " "}
                  decimalScale={2}
                  value={originNode.balanceLocalCurrency}
                />
              </Text>
              {originNode.key !== nativeBalances.nativeCurrency && (
                <Text className="text-table-cell" style={{ display: "flex" }}>
                  {originNode.type === "CRYPTO" ? (
                    <NumberFormat
                      thousandSeparator={true}
                      displayType="text"
                      suffix={" " + originNode.symbol}
                      decimalScale={8}
                      value={originNode.balance}
                    />
                  ) : (
                    <NumberFormat
                      thousandSeparator={true}
                      displayType="text"
                      prefix={originNode.symbol + " "}
                      decimalScale={2}
                      value={originNode.balance}
                    />
                  )}
                </Text>
              )}
            </Space>
          </>
        ),
      },

      {
        title: "",
        width: window.screen.width < 1600 ? 130 : 160,
        dataIndex: "allocation",
        key: "allocation",
        align: "right",

        render: (record, originNode) => (
          <div style={{ flexDirection: "row", display: "flex" }}>
            <Progress
              className="allocation-style"
              percent={originNode.allocation}
              strokeColor={originNode.color}
              success={{ percent: 0.01 }}
              showInfo={false}
            />
          </div>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.PRIMARY_TEXT,
              fontSize: 16,
              marginRight: 5,
            }}
          >
            {this.props.t("balance.allocation")}
          </Text>
        ),
        dataIndex: "allocation",
        key: "allocation",
        align: "right",
        sorter: (a, b) => a.allocation - b.allocation,
        defaultSortOrder: "descend",
        width: 125,
        render: (allocation) => (
          <div>
            <NumberFormat
              className="allocation-style"
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 18,
              }}
              thousandSeparator={true}
              displayType="text"
              suffix={"%"}
              decimalScale={2}
              value={allocation}
            />
          </div>
        ),
      },
    ];
    if (this.state.asset) {
      return <Redirect to="/detail" />;
    }
    return (
      <>
        <Row gutter={[16, { xs: 8, sm: 16, md: 8, lg: 8, xl: 24 }]}>
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: 16, offset: 1 }}
            xl={{ span: 16, offset: 1 }}
          >
            <Row gutter={[16, { xs: 8, sm: 16, md: 8, lg: 8, xl: 24 }]}>
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                xl={{ span: 24, offset: 0 }}
              >
                {CAN("GET", "BALANCE_TIMELINE") && (
                  <Card
                    bordered={false}
                    size="default"
                    style={{
                      borderRadius: "5px",
                      background: COLOR.PRIMARY,
                      color: COLOR.SECONDARY_TEXT,
                      border: "1px solid #000",
                    }}
                  >
                    <Space
                      align="center"
                      direction="horizontal"
                      className="space-menu-chart "
                    >
                      <Space
                        align="start"
                        direction="vertical"
                        style={{
                          textAlign: "left",
                          display: "flex",
                          padding: "20px 0px 0px 14px",
                        }}
                      >
                        <Text
                          style={{
                            color: COLOR.SECONDARY_TEXT,
                            textAlign: "left",
                            fontSize: 18,
                          }}
                        >
                          {this.props.t("chart.title")}
                        </Text>
                        <Space align="center" direction="horizontal">
                          <Title
                            level={2}
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              textAlign: "left",
                            }}
                          >
                            <NumberFormat
                              thousandSeparator={true}
                              displayType="text"
                              prefix={nativeBalances.nativeSymbol + " "}
                              decimalScale={2}
                              value={nativeBalances.total}
                              style={{ marginRight: 20 }}
                            />
                          </Title>
                          {!this.state.loadingBalances &&
                            this.state.dataCharts.length > 0 &&
                            this.state.percentageBalance !== 0 && (
                              <>
                                <Title
                                  level={2}
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    textAlign: "left",
                                  }}
                                >
                                  <div
                                    style={{
                                      color:
                                        this.state.differenceBalances > 0
                                          ? "#42CC70"
                                          : "#CF202F",
                                    }}
                                  >
                                    {nativeBalances.nativeSymbol}
                                    {this.state.differenceBalances > 0
                                      ? " +"
                                      : " "}
                                    <NumberFormat
                                      thousandSeparator={true}
                                      displayType="text"
                                      decimalScale={2}
                                      value={this.state.differenceBalances}
                                      style={{
                                        marginRight: 2,
                                      }}
                                    />
                                  </div>
                                </Title>
                                <Title
                                  level={2}
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,
                                    textAlign: "left",
                                  }}
                                >
                                  <div
                                    style={{
                                      color:
                                        this.state.percentageBalance > 0
                                          ? "#42CC70"
                                          : "#CF202F",
                                    }}
                                  >
                                    {this.state.percentageBalance > 0
                                      ? "(+"
                                      : "("}
                                    <NumberFormat
                                      thousandSeparator={true}
                                      displayType="text"
                                      suffix="%"
                                      decimalScale={2}
                                      value={this.state.percentageBalance}
                                    />
                                    {")"}
                                  </div>
                                </Title>
                              </>
                            )}
                        </Space>
                      </Space>
                      <Space align="end" direction="vertical">
                        {this.state.dataCharts.length > 0 && (
                          <Menu
                            selectedKeys={[this.state.current]}
                            onClick={this.handleClick}
                            mode="horizontal"
                            style={{
                              background: COLOR.PRIMARY,
                              borderBottom: "none",
                            }}
                            items={[
                              {
                                label: "24H",
                                key: "1d",
                                style: {
                                  paddingLeft: 7,
                                  paddingRight: 7,
                                  margin: 8,
                                },
                                className: "menu-chart",
                              },
                              {
                                label: "7D",
                                key: "7d",
                                style: {
                                  paddingLeft: 7,
                                  paddingRight: 7,
                                  margin: 8,
                                },
                                className: "menu-chart",
                              },
                              {
                                label: "14D",
                                key: "14d",
                                style: {
                                  paddingLeft: 7,
                                  paddingRight: 7,
                                  margin: 8,
                                },
                                className: "menu-chart",
                              },
                              {
                                label: "30D",
                                key: "30d",
                                style: {
                                  paddingLeft: 7,
                                  paddingRight: 7,
                                  margin: 8,
                                },
                                className: "menu-chart",
                              },
                            ]}
                          />
                        )}
                      </Space>
                    </Space>

                    <ChartAccounts
                      data={this.state.dataCharts}
                      loading={this.state.loadingCharts}
                      nativeSymbol={nativeBalances.nativeSymbol}
                    />
                  </Card>
                )}
              </Col>
            </Row>
            <Row
              gutter={[16, { xs: 8, sm: 16, md: 8, lg: 8, xl: 24 }]}
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <Col
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
                xl={{ span: 24, offset: 0 }}
              >
                <Card
                  bordered={false}
                  size="default"
                  style={{
                    borderRadius: "5px",
                    background: COLOR.PRIMARY,
                    color: COLOR.SECONDARY_TEXT,
                    padding: 0,
                    border: "1px solid #000",
                  }}
                >
                  <Space
                    align="center"
                    style={{
                      textAlign: "left",
                      display: "flex",
                      padding: "20px 0px 0px 14px",
                    }}
                  >
                    <Title
                      level={4}
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        textAlign: "left",
                      }}
                    >
                      {this.props.t("balance.title")}
                    </Title>
                  </Space>
                  {this.state.loadingBalances && <Skeleton active />}
                  {!this.state.loadingBalances && (
                    <div
                      id="table-balances"
                      className="ant-table ant-table-tbody ant-table-thead text-table-cell"
                    >
                      <Table
                        rowKey={"asset"}
                        locale={{
                          emptyText: (
                            <Text
                              style={{
                                color: COLOR.GREEN,
                                fontSize: 16,
                                fontWeight: "bold",
                              }}
                            >
                              {this.props.t("balance.noBalance")}
                            </Text>
                          ),
                        }}
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: (event) => {
                              if (record.accounts.length === 1) {
                                this.selectRowAsset(record, record.accounts[0]);
                              }
                            }, // click row
                          };
                        }}
                        expandable={{
                          expandIcon: ({ expanded, onExpand, record }) => (
                            <>
                              {record.accounts !== undefined &&
                                record.accounts.length > 1 && (
                                  <>
                                    {expanded ? (
                                      <DownOutlined
                                        onClick={(e) => onExpand(record, e)}
                                      />
                                    ) : (
                                      <RightOutlined
                                        onClick={(e) => onExpand(record, e)}
                                      />
                                    )}
                                  </>
                                )}
                            </>
                          ),
                          expandedRowRender: (record) => (
                            <>
                              {record.accounts.map((item, i) => {
                                return (
                                  <>
                                    <Row
                                      key={item._id}
                                      gutter={[
                                        16,
                                        { xs: 8, sm: 16, md: 8, lg: 8, xl: 24 },
                                      ]}
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.selectRowAsset(record, item)
                                      }
                                    >
                                      <Col
                                        xs={{ span: 1, offset: 0 }}
                                        sm={{ span: 1, offset: 0 }}
                                        md={{ span: 1, offset: 0 }}
                                        lg={{ span: 1, offset: 0 }}
                                        xl={{ span: 1, offset: 0 }}
                                      ></Col>
                                      <Col
                                        xs={{ span: 11, offset: 0 }}
                                        sm={{ span: 11, offset: 0 }}
                                        md={{ span: 11, offset: 0 }}
                                        lg={{ span: 9, offset: 0 }}
                                        xl={{ span: 9, offset: 0 }}
                                        style={{ marginLeft: 10 }}
                                      >
                                        <Space align="center">
                                          <Image
                                            style={{
                                              alignItems: "center",
                                              display: "inline-flex",
                                            }}
                                            preview={false}
                                            width={30}
                                            src={record.icon}
                                          />
                                          <Text
                                            style={{
                                              color: COLOR.PRIMARY_TEXT,
                                              fontSize: 18,
                                            }}
                                          >
                                            {item.alias}
                                          </Text>
                                        </Space>
                                      </Col>
                                      <Col
                                        xs={{ span: 12, offset: 0 }}
                                        sm={{ span: 12, offset: 0 }}
                                        md={{ span: 12, offset: 0 }}
                                        lg={{ span: 10, offset: 0 }}
                                        xl={{ span: 10, offset: 0 }}
                                      >
                                        <Space align="center">
                                          <Text
                                            style={{
                                              color: COLOR.PRIMARY_TEXT,
                                              fontSize: 18,
                                            }}
                                          >
                                            <NumberFormat
                                              thousandSeparator={true}
                                              displayType="text"
                                              prefix={
                                                nativeBalances.nativeSymbol +
                                                " "
                                              }
                                              decimalScale={2}
                                              value={item.native_balance}
                                            />
                                          </Text>
                                          {record.key !==
                                            nativeBalances.nativeCurrency && (
                                            <Text
                                              className="text-table-cell"
                                              style={{ display: "flex" }}
                                            >
                                              <NumberFormat
                                                thousandSeparator={true}
                                                displayType="text"
                                                suffix={
                                                  record.type === "CRYPTO"
                                                    ? " " + record.symbol
                                                    : " "
                                                }
                                                prefix={
                                                  record.type === "CRYPTO"
                                                    ? ""
                                                    : record.symbol + " "
                                                }
                                                decimalScale={
                                                  record.type === "CRYPTO"
                                                    ? 8
                                                    : 2
                                                }
                                                value={item.balance}
                                              />
                                            </Text>
                                          )}
                                        </Space>
                                      </Col>
                                      <Col
                                        xs={{ span: 0, offset: 0 }}
                                        sm={{ span: 0, offset: 0 }}
                                        md={{ span: 0, offset: 0 }}
                                        lg={{ span: 3, offset: 0 }}
                                        xl={{ span: 3, offset: 0 }}
                                      ></Col>
                                    </Row>
                                    {record.accounts.length - 1 !== i && (
                                      <Divider
                                        style={{
                                          background: COLOR.SECONDARY_TEXT,
                                          marginTop: 10,
                                          marginBottom: 10,
                                        }}
                                      />
                                    )}
                                  </>
                                );
                              })}
                            </>
                          ),
                          rowExpandable: (record) =>
                            record.accounts !== undefined &&
                            record.accounts.length > 1,
                        }}
                        pagination={false}
                        columns={columns}
                        dataSource={tableDataFormat}
                      />
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          </Col>
          <Col
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 22, offset: 1 }}
            md={{ span: 22, offset: 1 }}
            lg={{ span: 6, offset: 0 }}
            xl={{ span: 6, offset: 0 }}
            style={{ marginBottom: 20 }}
          >
            {CAN("GET", "TRANSACTIONS_EXCHANGE_ORDERS") && (
              <RecentsTransactions />
            )}
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    accounts: state.banking.accounts,
    asset: state.siderNavigate.asset,
    token: state.tokenAccess,
    respTimeline: state.timeline.resultTimeline,
    respTransactionsOrders: state.transactions.transactionsAndOrders,
    respAllTransactions: state.transactions.allResultTx,
    responsePreferences: state.preferences.resultPreferences,
  };
};

const mapDispatchToProps = {
  selectedAsset,
  currentToken,
  getTimeline,
  getAccounts,
};

const AccountsTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Accounts)
);

export default AccountsTranslated;
