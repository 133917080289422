import servicesPreferences from "../../services/preferences";
export const GET_PREFERENCES = "GET_PREFERENCES";
export const UPDATE_PREFERENCES = "UPDATE_PREFERENCES";
export function getPreferences(id, token) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesPreferences.getPreferences(id, token);
      dispatch({
        type: GET_PREFERENCES,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_PREFERENCES,
        payload: error.response,
      });
    }
  };
}
export function updatePreferences(id, token, body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesPreferences.updatePreferences(
        id,
        token,
        body
      );
      dispatch({
        type: UPDATE_PREFERENCES,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PREFERENCES,
        payload: error.response,
      });
    }
  };
}
