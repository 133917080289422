import React, { Component } from "react";
import {
  Button,
  Space,
  Typography,
  Row,
  Col,
  Card,
  Input,
  Divider,
  Form,
  Image,
  message,
} from "antd";
import "./containerTrade.css";
import { withTranslation } from "react-i18next";
import COLOR from "../../const/colors";
import KeyboardBackspace from "@2fd/ant-design-icons/lib/KeyboardBackspace";
import {
  getTransactionsCurrency,
  getAllTransactions,
  getAllTransactionsOrders,
} from "../../redux/actions/transactionsActions";
import { getAccounts } from "../../redux/actions/bankingActions";
import { selectedAsset } from "../../redux/actions/navigateActions";
import {
  sellAsset,
  buyAsset,
  exchangeAsset,
  exchangeFx,
} from "../../redux/actions/exchangeActions";
import { currentToken } from "../../redux/actions/tokenActions";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
const { Text, Title } = Typography;
class ConfirmTrade extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      time: "",
      seconds: 25,
      loading: false,
      disabledButton: false,
    };
    this.timer = 0;
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.rateResponse !== prevProps.rateResponse) {
      if (this.props.amountFiat < parseFloat(this.props.minExchange)) {
        this.setState({ disabledButton: true });
        message.error(this.props.t("trade.error.minimunExchangeChanged"), 10);
      }
    }
  }
  secondsToTime(secs) {
    let divisor_for_minutes = secs % (60 * 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let timeSeconds = seconds;

    return timeSeconds;
  }
  countDown() {
    if (this._isMounted) {
      // Remove one second, set state so a re-render happens.
      let seconds = this.state.seconds - 1;
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
      });

      // Check if we're at zero.
      if (seconds === 0) {
        clearInterval(this.timer);
        this.setState({ seconds: 25 }, async () => {
          let timeLeftVar = this.secondsToTime(this.state.seconds);
          this.setState({ time: timeLeftVar });
          if (this.state.seconds > 0) {
            await this.props.next(this.props.name, true);
            this.timer = setInterval(this.countDown, 1000);
          }
        });
      }
    }
  }

  async confirmTrade() {
    try {
      this.setState({ loading: true });
      let response = "";
      let body = {};

      if (
        this.props.amountFiat >= parseFloat(this.props.minExchange) &&
        parseFloat(this.props.minExchange) <=
          parseFloat(this.props.recommendedMinExchange)
      ) {
        if (this.props.name === "SELL") {
          body = {
            base_currency: this.props.assetActive.value,
            quote_account_id: this.props.assetActiveReceive.id,
            amount: this.props.amountFiat.toString(),
          };
          await this.props.sellAsset(
            this.props.clientId,
            this.props.accessToken,
            body
          );
          response = this.props.sellResponse;
        }
        if (this.props.name === "BUY") {
          body = {
            base_account_id: this.props.assetActive.id,
            quote_currency: this.props.assetActiveReceive.value,
            amount: this.props.amountFiat.toString(),
          };
          await this.props.buyAsset(
            this.props.clientId,
            this.props.accessToken,
            body
          );
          response = this.props.buyResponse;
        }
        if (this.props.name === "TRADE") {
          body = {
            base_currency: this.props.assetActive.value,
            quote_currency: this.props.assetActiveReceive.value,
            amount: this.props.amountFiat.toString(),
          };

          await this.props.exchangeAsset(
            this.props.clientId,
            this.props.accessToken,
            body
          );
          response = this.props.tradeResponse;
        }
        if (this.props.name === "FX") {
          body = {
            base_account_id: this.props.assetActive.id,
            quote_account_id: this.props.assetActiveReceive.id,
            amount: this.props.amountFiat.toString(),
          };
          await this.props.exchangeFx(
            this.props.clientId,
            this.props.accessToken,
            body
          );
          response = this.props.fxResponse;
        }

        if (response.headers !== undefined) {
          let newAccessToken = response.headers["x-new-access-token"];
          await this.props.currentToken(newAccessToken);
        }
        if (response.status === 200) {
          this.setState({ loading: false });
          message.success(this.props.t("trade.message.success"), 12);
          await this.getRecentTransactions();
          await this.getRecentTransactionsOrders();
          await this.getAccounts();
          this.props.resetCurrent();
          clearInterval(this.timer);
        }
        if (response.status === 202) {
          setTimeout(() => {
            this.getAccounts().finally(() => {
              this.getRecentTransactions().finally(() => {
                this.getRecentTransactionsOrders().then((resp) => {
                  this.setState({ loading: false });
                  this.props.resetCurrent();
                });
              });
            });
          }, 5000);

          message.success(this.props.t("trade.message.complete"), 12);

          clearInterval(this.timer);
        } else if (response.status === 400) {
          this.setState({ loading: false });
          if (response.data.error_details.validation_error !== undefined) {
            let messageError = response.data.error_details.validation_error;
            message.error(this.props.t("trade.error." + messageError), 10);
          } else if (response.data.error_details.payload_error !== undefined) {
            let messageError = response.data.error_details.payload_error;
            message.error(this.props.t("trade.error." + messageError), 10);
          } else {
            message.error(response.data.message);
          }
        } else if (response.status === 403) {
          message.error(this.props.t("trade.error.accessErrorExchange"), 10);
          this.setState({ loading: false });
        } else if (response.status === 404) {
          this.setState({ loading: false });
          if (response.data.error_details.validation_error !== undefined) {
            let messageError = response.data.error_details.validation_error;

            message.error(this.props.t("trade.error." + messageError), 10);
          } else {
            message.error(response.data.message);
          }
        } else if (
          response.status === 401 ||
          response.status === 422 ||
          response.status === 500
        ) {
          message.error(this.props.t("trade.error.errorServer"), 10);
          this.setState({ loading: false });
        }
      } else {
        message.error(this.props.t("trade.error.minimunExchangeChanged"), 15);
        this.setState({ loading: false });
      }
    } catch (error) {
      message.error(this.props.t("trade.error.errorServer"), 10);
      this.setState({ loading: false });
    }
  }

  async getRecentTransactions() {
    try {
      this.setState({ loading: true });
      await this.props.getAllTransactions(
        this.props.clientId,
        this.props.accessToken
      );
      let response = this.props.respAllTransactions;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async getRecentTransactionsOrders() {
    try {
      this.setState({ loading: true });
      await this.props.getAllTransactionsOrders(
        this.props.clientId,
        this.props.accessToken
      );
      let response = this.props.respTransactionsOrders;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.timer);
  }
  async getAccounts() {
    try {
      await this.props.getAccounts(this.props.clientId, this.props.accessToken);
      let response = this.props.banking.accounts;
      let newAccessToken = "";
      if (response.headers !== undefined) {
        newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
    } catch (error) {}
  }
  calculateFee() {
    let dataUser = this.props.dataUser.data?.data?.user;
    let feeType = dataUser?.trading_fee_type;
    let quotation = this.props.rateCurrent;
    let amountReceive = this.props.amountFiat * quotation.rate;
    let totalFee = "";
    if (feeType === "TIERS") {
      if (
        this.props.name === "BUY" ||
        this.props.name === "SELL" ||
        this.props.name === "TRADE"
      ) {
        let monthlyVolume = quotation.current_month_volume_in_gbp;
        let rateBasetoGbp = quotation.base_currency_vs_gbp_rate;
        let amountInGbp = this.props.amountFiat * rateBasetoGbp;
        let feeRange = quotation.range;
        let amountFee = 0;
        let accumulatedTiers = 0;

        for (let range of feeRange) {
          let proportion = 0;
          let amountTierGbp = 0;
          let max = parseFloat(range.max);
          let min = parseFloat(range.min);
          if (!max || monthlyVolume < max) {
            if (max) {
              amountTierGbp = max - min;
            } else {
              amountTierGbp = amountInGbp - accumulatedTiers;
            }
            if (monthlyVolume > min) {
              amountTierGbp =
                max > monthlyVolume ? max - monthlyVolume : monthlyVolume - max;
            }
            if (amountInGbp - accumulatedTiers < amountTierGbp) {
              amountTierGbp = amountInGbp - accumulatedTiers;
            }

            if (amountTierGbp >= 0) {
              proportion = amountTierGbp / amountInGbp;
              let portionToReceive = amountReceive * proportion;
              amountFee =
                amountFee +
                portionToReceive * parseFloat(range.percentage / 100);
            }
          }
          accumulatedTiers = accumulatedTiers + amountTierGbp;
        }

        totalFee = amountFee;
      } else if (this.props.name === "FX") {
        totalFee =
          amountReceive * parseFloat(quotation.markup_percentage / 100);
      }
    } else {
      totalFee = amountReceive * parseFloat(quotation.markup_percentage / 100);
    }

    return totalFee;
  }
  render() {
    let dataUser = this.props.dataUser.data?.data?.user;
    let feeType = dataUser?.trading_fee_type;
    let fee = this.calculateFee();
    let amount = "";
    let amountReceive = "";
    amount = this.props.amountFiat * parseFloat(this.props.rateCurrent.rate);
    amountReceive = amount - fee;

    return (
      <Row
        style={{ paddingTop: 20 }}
        justify="center"
        gutter={[48, { xs: 16, sm: 16, md: 16, lg: 24, xl: 24 }]}
      >
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
        >
          <Row
            justify="center"
            gutter={[8, { xs: 8, sm: 8, md: 8, lg: 48, xl: 8 }]}
          >
            <Col
              xs={{ span: 3, offset: 0 }}
              sm={{ span: 3, offset: 0 }}
              md={{ span: 3, offset: 0 }}
              lg={{ span: 3, offset: 0 }}
              xl={{ span: 3, offset: 0 }}
            >
              <KeyboardBackspace
                style={{ color: COLOR.PRIMARY_TEXT, fontSize: 30 }}
                onClick={() => this.props.prev()}
              />
            </Col>
            <Col
              xs={{ span: 18, offset: 0 }}
              sm={{ span: 18, offset: 0 }}
              md={{ span: 18, offset: 0 }}
              lg={{ span: 18, offset: 0 }}
              xl={{ span: 18, offset: 0 }}
              style={{ textAlign: "center" }}
            >
              <Title level={3} style={{ color: COLOR.PRIMARY_TEXT }}>
                {this.props.name === "BUY" &&
                  this.props.t("buy.confirmBuy.title")}
                {this.props.name === "SELL" &&
                  this.props.t("sell.confirmSell.title")}
                {(this.props.name === "TRADE" || this.props.name === "FX") &&
                  this.props.t("trade.confirmTrade.title")}
              </Title>
            </Col>
            <Col
              xs={{ span: 3, offset: 0 }}
              sm={{ span: 3, offset: 0 }}
              md={{ span: 3, offset: 0 }}
              lg={{ span: 3, offset: 0 }}
              xl={{ span: 3, offset: 0 }}
            ></Col>
          </Row>
          <br />
          <br />
          <Row
            justify="center"
            gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
            style={{ marginBottom: 24 }}
          >
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
              xxl={{ span: 24, offset: 0 }}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Row
                justify="center"
                align="middle"
                gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
              >
                <Form
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Form.Item style={{ width: "100%", marginBottom: 0 }}>
                    <Row gutter={8} justify="center">
                      <Form.Item style={{ marginBottom: 0 }}>
                        {this.props.name === "SELL" && (
                          <NumberFormat
                            thousandSeparator={true}
                            displayType="text"
                            className="input-amount-dark"
                            style={{
                              fontSize:
                                2.5 -
                                this.props.amountFiat.toString().length * 0.05 +
                                "vw",
                            }}
                            suffix={" " + this.props.assetActive.symbol}
                            decimalScale={8}
                            value={this.props.amountFiat}
                          />
                        )}
                        {this.props.name === "BUY" && (
                          <NumberFormat
                            thousandSeparator={true}
                            displayType="text"
                            className="input-amount-dark"
                            style={{
                              fontSize:
                                1.8 -
                                this.props.amount.toString().length * 0.05 +
                                "vw",
                            }}
                            suffix={" " + this.props.assetActiveReceive.symbol}
                            decimalScale={8}
                            value={amountReceive}
                          />
                        )}
                        {(this.props.name === "TRADE" ||
                          this.props.name === "FX") && (
                          <NumberFormat
                            thousandSeparator={true}
                            displayType="text"
                            className="input-amount-dark"
                            style={{
                              fontSize:
                                1.8 -
                                this.props.amount.toString().length * 0.05 +
                                "vw",
                            }}
                            suffix={" " + this.props.assetActiveReceive.symbol}
                            decimalScale={this.props.name === "FX" ? 2 : 8}
                            value={amountReceive}
                          />
                        )}
                      </Form.Item>
                    </Row>
                  </Form.Item>
                </Form>
              </Row>
            </Col>
          </Row>
          <Row
            justify="center"
            gutter={[8, { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }]}
          >
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 24, offset: 0 }}
              xl={{ span: 24, offset: 0 }}
            >
              <Space
                direction="vertical"
                style={{ width: "100%", marginTop: 0 }}
                size="middle"
              >
                <Row justify="center">
                  <Card className="card-form" bodyStyle={{ padding: 0 }}>
                    <Form
                      labelAlign="left"
                      labelCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 8 },
                        lg: { span: 8 },
                        xl: { span: 8 },
                        xxl: { span: 10 },
                      }}
                      wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                        md: { span: 16 },
                        lg: { span: 16 },
                        xl: { span: 16 },
                        xxl: { span: 14 },
                      }}
                      layout="horizontal"
                    >
                      <Form.Item
                        className="item-send-dark"
                        label={
                          <Text className="label-dark">
                            {this.props.t("trade.confirmTrade.rate")}
                          </Text>
                        }
                      >
                        <Input.Group>
                          <Row>
                            <Col span={24}>
                              {this.props.name === "TRADE" ||
                              this.props.name === "FX" ||
                              this.props.name === "SELL" ? (
                                <Text
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,

                                    fontSize: 16,
                                  }}
                                >
                                  1 {this.props.assetActive.value} ={" "}
                                  <NumberFormat
                                    thousandSeparator={true}
                                    displayType="text"
                                    suffix={
                                      " " + this.props.assetActiveReceive.value
                                    }
                                    decimalScale={
                                      this.props.rateCurrent
                                        .quote_currency_type === "CRYPTO"
                                        ? 8
                                        : 2
                                    }
                                    value={this.props.rateCurrent.rate}
                                  />
                                </Text>
                              ) : (
                                <Text
                                  style={{
                                    color: COLOR.PRIMARY_TEXT,

                                    fontSize: 16,
                                  }}
                                >
                                  1 {this.props.assetActiveReceive.value} ={" "}
                                  <NumberFormat
                                    thousandSeparator={true}
                                    displayType="text"
                                    suffix={" " + this.props.assetActive.value}
                                    decimalScale={2}
                                    value={1 / this.props.rateCurrent.rate}
                                  />
                                </Text>
                              )}
                            </Col>
                          </Row>
                        </Input.Group>
                      </Form.Item>

                      <Divider
                        style={{
                          background: COLOR.PRIMARY_TEXT,
                          margin: 0,
                        }}
                      />
                      <Form.Item
                        className="item-send-dark"
                        label={
                          <Text className="label-dark">
                            {this.props.t("trade.confirmTrade.amount")}
                          </Text>
                        }
                      >
                        <Input.Group>
                          <Row>
                            <Col span={24}>
                              <Text
                                style={{
                                  color: COLOR.PRIMARY_TEXT,
                                  fontSize: 16,
                                }}
                              >
                                {this.props.name === "BUY" && (
                                  <NumberFormat
                                    thousandSeparator={true}
                                    displayType="text"
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                    }}
                                    prefix={this.props.assetActive.symbol + " "}
                                    decimalScale={2}
                                    value={this.props.amountFiat}
                                  />
                                )}
                                {this.props.name === "SELL" && (
                                  <NumberFormat
                                    thousandSeparator={true}
                                    displayType="text"
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                    }}
                                    prefix={
                                      this.props.assetActiveReceive.symbol + " "
                                    }
                                    decimalScale={2}
                                    value={amountReceive}
                                  />
                                )}
                                {(this.props.name === "TRADE" ||
                                  this.props.name === "FX") && (
                                  <NumberFormat
                                    thousandSeparator={true}
                                    displayType="text"
                                    style={{
                                      color: COLOR.PRIMARY_TEXT,
                                      fontSize: 16,
                                    }}
                                    suffix={" " + this.props.assetActive.symbol}
                                    decimalScale={
                                      this.props.name === "FX" ? 2 : 8
                                    }
                                    value={this.props.amountFiat}
                                  />
                                )}
                              </Text>
                            </Col>
                          </Row>
                        </Input.Group>
                      </Form.Item>

                      <Divider
                        style={{ background: COLOR.PRIMARY_TEXT, margin: 0 }}
                      />
                      <Form.Item
                        className="item-select-dark"
                        label={
                          <Text className="label-dark">{this.props.label}</Text>
                        }
                      >
                        <div
                          style={{
                            display: "inline-flex",
                            float: "left",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            src={
                              this.props.name === "SELL"
                                ? this.props.assetActiveReceive.icon
                                : this.props.assetActive.icon
                            }
                            width={25}
                            preview={false}
                          />
                          <Text
                            style={{
                              color: COLOR.PRIMARY_TEXT,
                              marginLeft: 10,
                              fontSize: 16,
                            }}
                          >
                            {this.props.name === "SELL"
                              ? this.props.assetActiveReceive.name
                              : this.props.assetActive.name}
                          </Text>
                        </div>
                      </Form.Item>
                    </Form>
                  </Card>
                </Row>
                {feeType === "TIERS" && (
                  <Row justify="space-between">
                    <Text
                      style={{
                        color: COLOR.SECONDARY_TEXT,
                        marginLeft: 10,
                        fontSize: 16,
                      }}
                    >
                      {this.props.t("send.confirmSend.fee")}
                    </Text>
                    <Text
                      style={{
                        color: COLOR.PRIMARY_TEXT,
                        marginLeft: 10,
                        fontSize: 16,
                      }}
                    >
                      {this.props.assetActiveReceive.type === "CRYPTO" ? (
                        <NumberFormat
                          thousandSeparator={true}
                          displayType="text"
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            fontSize: 16,
                          }}
                          suffix={" " + this.props.assetActiveReceive.symbol}
                          decimalScale={8}
                          value={fee}
                        />
                      ) : (
                        <NumberFormat
                          thousandSeparator={true}
                          displayType="text"
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            fontSize: 16,
                          }}
                          prefix={this.props.assetActiveReceive.symbol + " "}
                          decimalScale={2}
                          value={fee}
                        />
                      )}
                    </Text>
                  </Row>
                )}
                <Row justify="center">
                  <Text
                    style={{
                      color: COLOR.PRIMARY_TEXT,
                      //marginLeft: 10,
                      fontSize: 16,
                    }}
                  >
                    {this.props.t("trade.confirmTrade.rateChange", {
                      key: this.state.time,
                    })}
                  </Text>
                </Row>
                <Row justify="center">
                  <Button
                    block
                    type="primary"
                    style={{
                      height: "60px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    disabled={this.state.disabledButton}
                    loading={this.state.loading || this.props.loading}
                    onClick={() => this.confirmTrade()}
                  >
                    {this.props.t("trade.button." + this.props.name)}
                  </Button>
                </Row>
                <Row style={{ marginTop: "1rem" }}>
                  <Col span={12} style={{ textAlign: "left" }}>
                    <Text
                      style={{
                        color: COLOR.SECONDARY_TEXT,
                        fontSize: 14,
                        textAlign: "left",
                      }}
                    >
                      {this.props.assetActive.value !== undefined
                        ? this.props.assetActive.value
                        : ""}{" "}
                      balance
                    </Text>
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    <Text style={{ color: COLOR.PRIMARY_TEXT, fontSize: 14 }}>
                      {this.props.assetActive.type === "CRYPTO" ? (
                        <NumberFormat
                          thousandSeparator={true}
                          displayType="text"
                          suffix={" " + this.props.assetActive.symbol}
                          decimalScale={8}
                          value={this.props.assetActiveBalance}
                        />
                      ) : (
                        <NumberFormat
                          thousandSeparator={true}
                          displayType="text"
                          prefix={this.props.assetActive.symbol + " "}
                          decimalScale={2}
                          value={this.props.assetActiveBalance}
                        />
                      )}{" "}
                      {this.props.assetActive.value !==
                        this.props.nativeCurrency && (
                        <NumberFormat
                          thousandSeparator={true}
                          displayType="text"
                          prefix={"≈ " + this.props.nativeSymbol + " "}
                          decimalScale={2}
                          value={parseFloat(this.props.nativeBalance)}
                        />
                      )}
                    </Text>
                  </Col>
                </Row>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    banking: state.banking.accounts,
    asset: state.siderNavigate.asset,
    sellResponse: state.exchange.resultSell,
    buyResponse: state.exchange.resultBuy,
    tradeResponse: state.exchange.resultExchange,
    fxResponse: state.exchange.resultFx,
    accessToken: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
    respTransactions: state.transactions.resultTransactions,
    respAllTransactions: state.transactions.allResultTx,
    respTransactionsOrders: state.transactions.transactionsAndOrders,
    rateResponse: state.exchange.resultRate,
    dataUser: state.authUser.userData,
  };
};

const mapDispatchToProps = {
  getAccounts,
  selectedAsset,
  currentToken,
  sellAsset,
  buyAsset,
  exchangeAsset,
  getTransactionsCurrency,
  getAllTransactions,
  exchangeFx,
  getAllTransactionsOrders,
};
const ConfirmTradeTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmTrade)
);

export default ConfirmTradeTranslated;
