import servicesRequest from "../../services/requestAccount";
import servicesUser from "../../services/user";
export const AUTH_USER = "AUTH_USER";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const LOGGED_OUT = "LOGGED_OUT";
export const LOGOUT = "LOGOUT";
export const REQUEST_ACCOUNT = "REQUEST_ACCOUNT";
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const SEND_REQUEST_ACCOUNT = "SEND_REQUEST_ACCOUNT";
export const CREATE_SHAREHOLDER = "CREATE_SHAREHOLDER";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CONFIRM_UPDATE = "CONFIRM_UPDATE";
export const ACCEPT_TERMS = "ACCEPT_TERMS";
export const ONLY_FIAT = "ONLY_FIAT";

export function requestAccount(body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesRequest.requestAccounts(body);
      dispatch({
        type: REQUEST_ACCOUNT,
        payload: response.data,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: REQUEST_ACCOUNT,
          payload: error.response.data,
        });
      }
    }
  };
}
export function uploadDocuments(id, body, token, idAssociate) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesRequest.uploadDocuments(
        id,
        body,
        token,
        idAssociate
      );
      dispatch({
        type: UPLOAD_DOCUMENT,
        payload: response.data,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: UPLOAD_DOCUMENT,
          payload: error.response.data,
        });
      }
    }
  };
}
export function createShareholder(id, token, body, method) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesRequest.createShareholder(
        id,
        token,
        body,
        method
      );
      dispatch({
        type: CREATE_SHAREHOLDER,
        payload: response.data,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: CREATE_SHAREHOLDER,
          payload: error.response.data,
        });
      }
    }
  };
}
export function sendRequest(id, token) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesRequest.commit(id, token);
      dispatch({
        type: SEND_REQUEST_ACCOUNT,
        payload: response.data,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: SEND_REQUEST_ACCOUNT,
          payload: error.response.data,
        });
      }
    }
  };
}
export function authUser(body, id, os, browser, token) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesUser.userAuth(
        body,
        id,
        os,
        browser,
        token
      );
      dispatch({
        type: AUTH_USER,
        payload: response.data,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: AUTH_USER,
          payload: error.response,
        });
      }
    }
  };
}
export function logoutUser(id, accessToken) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesUser.userLogout(id, accessToken);
      dispatch({
        type: LOGOUT,
        payload: response,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: LOGOUT,
          payload: error.response,
        });
      }
    }
  };
}

export function signinSuccess(id, token) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesUser.getUser(id, token);
      dispatch({
        type: SIGN_IN_SUCCESS,
        payload: response,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: SIGN_IN_SUCCESS,
          payload: error.response,
        });
      }
    }
  };
}
export function updateProfile(id, token, body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesUser.updateUser(id, token, body);
      dispatch({
        type: UPDATE_PROFILE,
        payload: response,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: UPDATE_PROFILE,
          payload: error.response,
        });
      }
    }
  };
}
export function confirmUpdate(id, token, body) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesUser.confirmUpdate(id, token, body);
      dispatch({
        type: CONFIRM_UPDATE,
        payload: response,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: CONFIRM_UPDATE,
          payload: error.response,
        });
      }
    }
  };
}
export function acceptTerms(id, token) {
  return async (dispatch, getState) => {
    try {
      const response = await servicesUser.acceptTermsAndConditions(id, token);
      dispatch({
        type: ACCEPT_TERMS,
        payload: response,
      });
    } catch (error) {
      if (error.response !== undefined) {
        dispatch({
          type: ACCEPT_TERMS,
          payload: error.response,
        });
      }
    }
  };
}
export function onlyFiat(value) {
  let isOnlyFiat = false;
  if (value !== undefined) {
    isOnlyFiat = value;
  } else {
    isOnlyFiat = false;
  }
  return (dispatch, getState) => {
    dispatch({
      type: ONLY_FIAT,
      payload: isOnlyFiat,
    });
  };
}
export const resetStore = () => {
  return {
    type: LOGGED_OUT,
  };
};
