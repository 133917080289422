import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import COLOR from "../../const/colors";
import {
  Row,
  Col,
  Card,
  Typography,
  DatePicker,
  Select,
  Button,
  Table,
  Space,
  message,
  Skeleton,
  Tag,
} from "antd";
import moment from "moment";
import NumberFormat from "react-number-format";
import { currentToken } from "../../redux/actions/tokenActions";
import {
  getOrders,
  currentFiltersOrders,
  getOrdersByFilters,
} from "../../redux/actions/exchangeActions";
import { connect } from "react-redux";
import { FileTextOutlined } from "@ant-design/icons";
import { generateOrderPDF } from './receiptPDF';

const { Text } = Typography;
const { Option } = Select;
class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: "1",
      loading: false,
      ordersList: [],
      loadingFilter: false,
      dateStartFilter: "",
      dateEndFilter: "",
      status: null,
      minDateFilter: null,
      maxDateFilter: null,
      lastId: null,
      previousId: null,
      clear: true,
      loadingClean: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    };
  }
  componentDidMount() {
    let filters = {
      status: null,
      minDate: null,
      maxDateFilter: null,
    };
    this.props.currentFiltersOrders(filters);
    this.getOrdersHistory();
  }
  async getOrdersHistory() {
    try {
      this.setState({ loading: true });
      if (this.state.lastId === null) {
        await this.props.getOrders(
          this.props.clientId,
          this.props.accessToken,
          undefined
        );
      } else {
        await this.props.getOrders(
          this.props.clientId,
          this.props.accessToken,
          this.state.lastId
        );
      }
      let response = this.props.respOrders;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        let info = response.data.data;
        this.setState({
          lastId: info.current_last_id !== null ? info.current_last_id : null,
          previousId: info.previous_id !== null ? info.previous_id : null,
        });
        this.formatTransactions(info.exchange_orders);
      } else if (response.status === 403) {
        message.error(this.props.t("transactions.error.accessError"));
        this.setState({ loading: false });
      } else if (
        response.status === 400 ||
        response.status === 401 ||
        response.status === 404 ||
        response.status === 422
      ) {
        message.error(this.props.t("transactions.error.queryError"));
        this.setState({ loading: false });
      } else if (response.status === 500) {
        message.error(this.props.t("transactions.error.errorServer"));
        this.setState({ loading: false });
      } else {
        message.error(this.props.t("transactions.error.errorServer"));
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  }
  formatTransactions(data) {
    let array = [];
    for (let order of data) {
      let objExchangeOrder = {};
      objExchangeOrder.pair = order.quote_currency + "/" + order.base_currency;
      Object.entries(order).forEach(([key, value]) => {
        objExchangeOrder[key] = value;
        if (key === "date") {
          objExchangeOrder.date = this.formatDateView(value);
        }
      });
      array.push(objExchangeOrder);
    }
    let page = {
      current: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      total: this.state.ordersList.length + array.length,
    };
    this.setState({
      ordersList: [...this.state.ordersList, ...array],
      pagination: page,
      loading: false,
      loadingFilter: false,
      status: null,
      minDateFilter: null,
      maxDateFilter: null,
      dateStartFilter: "",
      dateEndFilter: "",
      loadingClean: false,
    });
  }
  formatDateView(dateOrder) {
    let dateMoment = moment(dateOrder).format("YYYY/MM/DD HH:mm:ss");
    let date = new Date(dateMoment + " UTC");
    let dateCreated =
      moment(date).format("DD/MM/YYYY") + " " + this.formatAmPm(date);
    return dateCreated;
  }
  formatAmPm(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  handleDateStart = (date) => {
    let minDate = date.format("YYYY-MM-DD");
    this.setState({
      dateStartFilter: date,
      minDateFilter: minDate,
    });
    if (this.state.maxDateFilter !== "" && this.state.maxDateFilter !== null) {
      if (date !== null) {
        if (minDate > this.state.maxDateFilter) {
          message.error(this.props.t("transactions.error.dateError"));
          this.setState({ dateStartFilter: "", minDateFilter: null });
        }
      }
    }
  };
  handleDateEnd = (date) => {
    let maxDate = date.format("YYYY-MM-DD");
    this.setState({
      dateEndFilter: date,
      maxDateFilter: maxDate,
    });
    if (this.state.minDateFilter !== "" && this.state.minDateFilter !== null) {
      if (date !== null) {
        if (this.state.minDateFilter > maxDate) {
          message.error(this.props.t("transactions.error.dateError"));
          this.setState({ dateEndFilter: "", maxDateFilter: null });
        }
      }
    }
  };
  handleStatus(value) {
    this.setState({ status: value, lastId: null });
  }
  handleTableChange = (pagination) => {
    let newPagination = {};
    if (this.state.pagination.current < pagination.current) {
      let filterSaved = this.props.filters;
      this.setState({
        status: filterSaved.status,
        minDateFilter: filterSaved.minDate,
        maxDateFilter: filterSaved.maxDateFilter,
      });
      newPagination = {
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total + 1,
      };
      if (pagination.current % 2 !== 0) {
        if (
          this.state.status === null &&
          this.state.minDateFilter === null &&
          this.state.maxDateFilter === null
        ) {
          this.getOrdersHistory();
        } else {
          this.getOrdersExchangeFiltered();
        }
      }
    } else {
      newPagination = {
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
      };
    }
    this.setState({ pagination: newPagination });
  };
  async getOrdersExchangeFiltered() {
    try {
      this.setState({ loadingFilter: true });
      let status = this.state.status === "ALL" ? null : this.state.status;
      await this.props.getOrdersByFilters(
        this.props.clientId,
        this.props.accessToken,
        status,
        this.state.minDateFilter,
        this.state.maxDateFilter,
        this.state.lastId
      );

      let response = this.props.respOrdersByFilter;
      if (response.headers !== undefined) {
        let newAccessToken = response.headers["x-new-access-token"];
        await this.props.currentToken(newAccessToken);
      }
      if (response.status === 200) {
        let info = response.data.data;
        this.setState({
          lastId: info.current_last_id !== null ? info.current_last_id : null,
          previousId: info.previous_id !== null ? info.previous_id : null,
        });
        this.formatTransactions(info.exchange_orders);
      } else if (response.status === 400) {
        this.setState({ loading: false, loadingFilter: false });
        if (response.data.error_details.validation_error !== undefined) {
          let messageError = response.data.error_details.validation_error;

          message.error(this.props.t("transactions.error." + messageError), 10);
        } else if (response.data.error_details.payload_error !== undefined) {
          let messageError = response.data.error_details.payload_error;
          message.error(this.props.t("transactions.error." + messageError), 10);
        } else {
          message.error(response.data.message);
        }
      } else if (response.status === 403) {
        message.error(this.props.t("transactions.error.accessError"));
        this.setState({ loading: false, loadingFilter: false });
      } else if (
        response.status === 401 ||
        response.status === 404 ||
        response.status === 422
      ) {
        message.error(this.props.t("transactions.error.queryError"));
        this.setState({ loading: false, loadingFilter: false });
      } else if (response.status === 500) {
        message.error(this.props.t("transactions.error.errorServer"));
        this.setState({ loading: false, loadingFilter: false });
      } else {
        message.error(this.props.t("transactions.error.errorServer"));
        this.setState({ loading: false, loadingFilter: false });
      }
    } catch (error) {
      this.setState({ loading: false, loadingFilter: false });
    }
  }
  async filterData() {
    await this.setState({ ordersList: [], lastId: null, clear: false });

    let filters = {
      status: this.state.status,
      minDate: this.state.minDateFilter,
      maxDateFilter: this.state.maxDateFilter,
    };
    this.props.currentFiltersOrders(filters);
    await this.getOrdersExchangeFiltered();
  }
  async cleanTable() {
    await this.setState({
      ordersList: [],
      lastId: null,
      loadingClean: true,
      clear: true,
    });
    let filters = {
      status: null,
      minDate: null,
      maxDateFilter: null,
    };
    this.props.currentFiltersOrders(filters);
    this.getOrdersHistory();
  }
  openPDFPreview = async (order) => {
    const { t, dataUser } = this.props;
    try {
      const user = dataUser.data?.data?.user;
      const pdfUrl = await generateOrderPDF(order, user);
      window.open(pdfUrl, '_blank', 'noopener,noreferrer');
    } catch (error) {
      console.error('Error generating PDF:', error);
      message.error(t('orders.pdfError'));
    }
  };
  render() {
    let dataUser = this.props.dataUser.data?.data?.user;
    let feeType = dataUser?.trading_fee_type;
    const columnsOrders = [
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            ID
          </Text>
        ),
        dataIndex: "_id",
        key: "_id",
        // width: 150,
        render: (_id) => (
          <>
            <Text
              style={{
                wordBreak: "break-all",
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {_id}
            </Text>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("orders.date")}
          </Text>
        ),
        dataIndex: "date",
        key: "date",
        // width: 30,
        render: (date) => (
          <>
            <div style={{ flexDirection: "column", display: "flex" }}>
              <Text
                style={{
                  color: COLOR.PRIMARY_TEXT,
                  fontSize: 16,
                  fontWeight: 400,
                }}
              >
                {date.split(" ")[0]}
              </Text>
              <Text
                style={{
                  color: COLOR.SECONDARY_TEXT,
                  fontSize: 16,
                  fontWeight: 400,
                }}
              >
                {date.split(" ")[1] + " " + date.split(" ")[2]}
              </Text>
            </div>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("orders.pair")}
          </Text>
        ),
        dataIndex: "pair",
        key: "pair",
        width: 110,
        render: (pair) => (
          <>
            <Text
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {pair}
            </Text>
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("orders.price")}
          </Text>
        ),
        dataIndex: "price",
        key: "price",
        align: "right",
        width: 200,
        render: (record, originNode) => (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <NumberFormat
              allowNegative={true}
              thousandSeparator={true}
              displayType="text"
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
              decimalScale={originNode.quote_currency_type === "FIAT" ? 2 : 8}
              value={originNode.price}
            />{" "}
            <Text
              style={{
                color: COLOR.SECONDARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {originNode.quote_currency}
            </Text>
          </div>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("orders.volumeExecuted")}
          </Text>
        ),
        dataIndex: "volume_executed",
        key: "volume_executed",
        align: "right",
        render: (record, originNode) => (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <NumberFormat
              allowNegative={true}
              thousandSeparator={true}
              displayType="text"
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
              decimalScale={originNode.quote_currency_type === "FIAT" ? 2 : 8}
              value={originNode.volume_executed}
            />{" "}
            <Text
              style={{
                color: COLOR.SECONDARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {originNode.quote_currency}
            </Text>
          </div>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("orders.cost")}
          </Text>
        ),
        dataIndex: "cost",
        key: "cost",
        width: 80,
        align: "right",
        render: (record, originNode) => (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <NumberFormat
              allowNegative={true}
              thousandSeparator={true}
              displayType="text"
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
              decimalScale={originNode.base_currency_type === "FIAT" ? 2 : 8}
              value={originNode.cost}
            />{" "}
            <Text
              style={{
                color: COLOR.SECONDARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {originNode.base_currency}
            </Text>
          </div>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {feeType === "TIERS" && this.props.t("orders.fee")}
          </Text>
        ),
        dataIndex: "fee",
        key: "fee",
        align: "right",
        width: feeType === "TIERS" ? 30 : "",
        render: (record, originNode) => (
          <>
            {feeType === "TIERS" && (
              <div style={{ flexDirection: "column", display: "flex" }}>
                <NumberFormat
                  allowNegative={true}
                  thousandSeparator={true}
                  displayType="text"
                  style={{
                    color: COLOR.PRIMARY_TEXT,
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                  decimalScale={
                    originNode.quote_currency_type === "FIAT" ? 2 : 8
                  }
                  value={originNode.fee}
                />{" "}
                <Text
                  style={{
                    color: COLOR.SECONDARY_TEXT,
                    fontSize: 16,
                    fontWeight: 400,
                  }}
                >
                  {originNode.quote_currency}
                </Text>
              </div>
            )}
          </>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("orders.volumeReceived")}
          </Text>
        ),
        dataIndex: "volume_received",
        key: "volume_received",
        align: "right",
        render: (record, originNode) => (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <NumberFormat
              allowNegative={true}
              thousandSeparator={true}
              displayType="text"
              style={{
                color: COLOR.PRIMARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
              decimalScale={originNode.quote_currency_type === "FIAT" ? 2 : 8}
              value={originNode.volume_received}
            />{" "}
            <Text
              style={{
                color: COLOR.SECONDARY_TEXT,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {originNode.quote_currency}
            </Text>
          </div>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("orders.status")}
          </Text>
        ),
        dataIndex: "status",
        key: "status",
        align: "right",
        width: 80,
        render: (status) => (
          <div>
            {status === "COMPLETED" && (
              <Tag
                style={{
                  marginTop: 5,
                  color: "#389E0D",
                  background: "#F6FFED",
                  borderColor: "#B7EB8F",
                  fontSize: 12,
                }}
              >
                {this.props.t("orders.statusOrders.complete")}
              </Tag>
            )}
            {status === "PENDING" && (
              <Tag
                style={{
                  marginTop: 5,
                  color: "#531DAB",
                  background: "#F9F0FF",
                  borderColor: "#D3ADF7",
                  fontSize: 12,
                }}
              >
                {this.props.t("orders.statusOrders.pending")}
              </Tag>
            )}
            {status === "ERROR" && (
              <Tag
                style={{
                  marginTop: 5,
                  color: "#CF1322",
                  background: "#FFF1F0",
                  borderColor: "#FFA39E",
                  fontSize: 12,
                }}
              >
                {this.props.t("orders.statusOrders.error")}
              </Tag>
            )}
          </div>
        ),
      },
      {
        title: (
          <Text
            style={{
              color: COLOR.SECONDARY_TEXT,
              fontSize: 18,
            }}
          >
            {this.props.t("orders.receipt")}
          </Text>
        ),
        key: "receipt",
        align: "center",
        render: (record) => (
          <FileTextOutlined 
            onClick={(e) => {
              e.stopPropagation();
              this.openPDFPreview(record);
            }}
						style={{
							color: COLOR.GREEN,
							fontSize: '18px',
							cursor: 'pointer'
						}}
          />
        ),
      },
    ];
    const status = [
      {
        key: "PENDING",
        value: "PENDING",
        text: this.props.t("orders.statusOrders.pending"),
      },
      {
        key: "COMPLETED",
        value: "COMPLETED",
        text: this.props.t("orders.statusOrders.complete"),
      },
      {
        key: "ERROR",
        value: "ERROR",
        text: this.props.t("orders.statusOrders.error"),
      },
      {
        key: "ALL",
        value: "ALL",
        text: this.props.t("orders.statusOrders.all"),
      },
    ];
    return (
      <div>
        <Card
          title={
            <Text
              style={{
                fontSize: 18,
                color: COLOR.PRIMARY_TEXT,
                fontWeight: "bold",
              }}
            >
              {this.props.t("orders.title")}
            </Text>
          }
          bordered={false}
          size="default"
          style={{
            width: "100%",
            borderRadius: "5px",
            background: COLOR.PRIMARY,
            color: COLOR.SECONDARY_TEXT,
            border: "1px solid #000",
          }}
          bodyStyle={{ padding: "10px 0px 10px 0px" }}
        >
          <Row
            gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16, xl: 16 }]}
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 7, offset: 0 }}
              xl={{ span: 7, offset: 0 }}
              xxl={{ span: 7, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <DatePicker
                placeholder={this.props.t("transactions.history.startDate")}
                size="large"
                format="YYYY-MM-DD"
                style={{ width: "100%" }}
                autoFocus={true}
                value={this.state.dateStartFilter}
                onChange={(date) => this.handleDateStart(date)}
                showTime={false}
                showToday={false}
              />
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 7, offset: 0 }}
              xl={{ span: 7, offset: 0 }}
              xxl={{ span: 7, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <DatePicker
                placeholder={this.props.t("transactions.history.endDate")}
                size="large"
                format="YYYY-MM-DD"
                style={{ width: "100%" }}
                autoFocus={true}
                value={this.state.dateEndFilter}
                onChange={(date) => this.handleDateEnd(date)}
                showTime={false}
              />
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 0 }}
              xl={{ span: 6, offset: 0 }}
              xxl={{ span: 6, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                id="select-trade"
                className="ant-select-selector ant-select"
                style={{ width: "100%" }}
              >
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  placeholder={this.props.t("orders.status")}
                  value={this.state.status}
                  onChange={(value) => this.handleStatus(value)}
                >
                  {status.map((item, i) => {
                    return (
                      <Option value={item.value} key={item.key}>
                        {item.text}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 2, offset: 0 }}
              xl={{ span: 2, offset: 0 }}
              xxl={{ span: 2, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Button
                type="primary"
                size="large"
                style={{ width: "100%" }}
                loading={this.state.loadingFilter}
                disabled={
                  this.state.status === null &&
                  (this.state.minDateFilter === null ||
                    this.state.maxDateFilter === null)
                }
                onClick={() => this.filterData()}
              >
                {this.props.t("transactions.history.filter")}
              </Button>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 2, offset: 0 }}
              xl={{ span: 2, offset: 0 }}
              xxl={{ span: 2, offset: 0 }}
              style={{
                textAlign: "left",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Button
                className="disabledStyle"
                style={{ borderColor: COLOR.SECONDARY, width: "100%" }}
                size="large"
                loading={this.state.loadingClean}
                disabled={this.state.clear || this.state.loadingFilter}
                onClick={() => this.cleanTable()}
              >
                {this.props.t("transactions.history.clear")}
              </Button>
            </Col>
          </Row>
          {(this.state.loading || this.state.loadingFilter) && (
            <Skeleton active />
          )}
          {!this.state.loading && !this.state.loadingFilter && (
            <>
              <div
                id="table-history"
                className="ant-table ant-table-tbody ant-table-thead text-table-cell ant-table-cell"
                style={{ marginTop: 10 }}
              >
                <Table
                  locale={{
                    emptyText: (
                      <Space direction="vertical">
                        <br />
                        <Text
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                        >
                          {this.props.t("orders.msgEmpty")}
                        </Text>
                        <Text
                          style={{
                            color: COLOR.PRIMARY_TEXT,
                            fontSize: 16,
                            fontWeight: "normal",
                          }}
                        >
                          {this.props.t("orders.msgEmpty1")}
                        </Text>
                        <br />
                      </Space>
                    ),
                  }}
                  loading={this.state.loading}
                  columns={columnsOrders}
                  dataSource={this.state.ordersList}
                  scroll={{ x: 1300 }}
                  onChange={this.handleTableChange}
                  pagination={this.state.pagination}
                  rowKey={"_id"}
                />
              </div>
            </>
          )}
        </Card>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataUser: state.authUser.userData,
    accounts: state.banking.accounts,
    accessToken: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
    respOrders: state.exchange.resultOrders,
    filters: state.exchange.currentFilterOrder,
    respOrdersByFilter: state.exchange.resultOrdersFilter,
  };
};

const mapDispatchToProps = {
  currentToken,
  getOrders,
  currentFiltersOrders,
  getOrdersByFilters,
};

const OrdersTranslated = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Orders)
);

export default OrdersTranslated;
